import React from 'react'

interface IForm extends React.FormHTMLAttributes<HTMLFormElement> {}

export const Form: React.FunctionComponent<IForm> = (props) => 
{
    return (
        <form {...props}>
            {props.children}
        </form>
    )
}
