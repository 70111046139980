import { AxiosResponse } from "axios"
import Request from "../../functions/Request"

export interface StageViewProps {
    id: string
    type: string
    data_id: string
    practice: string
    practice_information: string
    days_to_complete: string
    sequence: string
    module_id: string
}

export const StageView = async (
    id: string,
    onSuccess: ((e: StageViewProps) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.get(`/client/tutorial-stage/stage/${id}`).then(response => onSuccess(response.data.response)).catch(onError);
