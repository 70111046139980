import { AxiosResponse } from "axios"
import Request from "../../functions/Request"
import { CreateUpdateSubject, SubjectResponse } from "./ProtocolInterfaces";

export const UpdateSubject = async (
    id: string,
    data: CreateUpdateSubject,
    onSuccess: ((e: SubjectResponse) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.put(`/protocols/subject/${id}`, data).then(e => onSuccess(e.data)).catch(onError);
