import React, { useEffect, useState } from 'react'

export interface WarningPaymentProps {
    status: string
}

export const WarningPayment: React.FC<WarningPaymentProps> = (props) => 
{
    const [message, setMessage] = useState({
        message: '',
        show: false
    })

    useEffect(() => {
        switch (props.status) {
            case 'WITHOUT_PAYMENT':
                setMessage({
                    message: 'Verifique seu pagamento, seu sistema está com acesso limitado.',
                    show: true
                })
                break;
            default:
                break;
        }
    }, [props.status])

    return message.show ? (
        <>
            <div className="warning-payment">
                {message.message}    
            </div>    
        </>
    ) : null
}