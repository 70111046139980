import { AxiosResponse } from "axios"
import RequestWithoutLoading from "../../functions/RequestWithoutLoading";
import { NormalTask } from "./calendarTypes";

export interface ResponseGenerateDay {
    day: ResponseGenerateDayItem,
    message: string
}

export interface ResponseGenerateDayItem {
    number: string,
    date_filter: string
    date_formatted: string
    name: string
    name_number: string
    week: string
    tasks: Array<NormalTask>
}


export const GenerateDay = async (
    onSuccess: ((e: ResponseGenerateDay) => void),
    onError: ((e: AxiosResponse) => void)
) => await RequestWithoutLoading.get('/calendar/day').then(s => onSuccess(s.data)).catch(onError);
