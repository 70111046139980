import React, { useEffect, useState } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'
import Select from 'react-select'
import { EditorHTML } from '../../../../../../components/EditorHTML'
import Success from '../../../../../../functions/Modals/Success'
import { NotifySystemAPI } from '../../../../../../services/NotifySystem/NotifySystem'
import { ControllerProccess, ProccessItem } from '../../../../../../services/Proccess/ControllerProccess'
import { ControllerProccessStage, ProccessStageForm } from '../../../../../../services/Proccess/ControllerProccessStage'

export interface FormStageProccessProps {
    id?: string,
    proccess_id: string,
    onSuccess?: ((item: ProccessStageForm) => void)
}

export const FormStageProccess: React.FC<FormStageProccessProps> = (props) => 
{
    const baseForm: ProccessStageForm = {
        description: '',
        responsible: '',
        sla: '',
        proccess_id: '',
        micro_proccess_id: ''
    }

    const [form, setForm] = useState<ProccessStageForm>(baseForm)

    const [listProccess, setListProccess] = useState<Array<ProccessItem>>([])

    const [description, setdescription] = useState('')

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        if(props.id)
        {
            ControllerProccessStage.update({
                ...form,
                description,
            }, s => Success('Salvo com sucesso!', async () => {
                await NotifySystemAPI.save({information: 'Criou um novo MicroProcesso em: Processos>MicroProcesso'})

                if(props.onSuccess)
                {
                    props.onSuccess(form)
                }
            }))
        }else{
            ControllerProccessStage.create({
                ...form, 
                description,
            }, s => Success('Salvo com sucesso!', () => {
                if(props.onSuccess)
                {
                    props.onSuccess({
                        ...form, description
                    })
                }

                setForm({
                    ...form,
                    description: '',
                    sla: '',
                    micro_proccess_id: ''
                })

                setdescription('')
                setMicroProccess(null)
            }))
        }
    }

    const onDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        if(props.id)
        {
            ControllerProccessStage.delete(props.id, s => Success('Apagado com sucesso!', () => {
                if(props.onSuccess)
                {
                    props.onSuccess(form)
                }
            }))
        }
    }

    const [microProccess, setMicroProccess] = useState<any>();

    useEffect(() => {
        ControllerProccess.getAllWithoutProccess(data => {
            setListProccess(data)
        })
    }, []);
    
    useEffect(() => {
        if(props.id)
        {
            ControllerProccessStage.getById(props.id, data => {
                setForm({...data})

                setdescription(data.description)

                if(data.micro_proccess_id)
                {
                    let search = listProccess.findIndex(item => item.id === data.micro_proccess_id)

                    if(search >= 0)
                    {
                        let micro_process = listProccess[search]

                        setMicroProccess({
                            value: micro_process.id,
                            label: `${micro_process.title} - ${micro_process.title_macroproccess ?? ''}`
                        })
                    }
                }
            })
        }else{
            setForm({
                description: '',
                responsible: '',
                sla: '',
                proccess_id: props.proccess_id,
                micro_proccess_id: ''
            })
        }
    }, [props.id, props.proccess_id, listProccess])


    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                <div className="box-input">
                    <p>O que fazer?</p>
                    <EditorHTML defaultToolbar onChange={value => setdescription(value)} value={description} />
                </div>
                <div className="box-input">
                    <p>Responsável</p>
                    <input type="text" onChange={e => setForm({...form, responsible: e.target.value})} value={form.responsible} />
                </div>
                <div className="box-input">
                    <p>Tempo/Prazo para ação (SLA)</p>
                    <input type="text" onChange={e => setForm({...form, sla: e.target.value})} value={form.sla} />
                </div>
                <div className="box-input">
                    <p>Associar a outro microprocesso</p>
                    <Select
                        isClearable
                        placeholder="Selecione uma opção"
                        classNamePrefix="react_boxinput"
                        value={microProccess}
                        onChange={data => {
                            setMicroProccess({
                                value: data?.value,
                                label: data?.label
                            })

                            if(data?.value)
                            {
                                setForm({...form, micro_proccess_id: data.value})
                            }
                        }}
                        options={[
                            ...listProccess.sort().map((item) => {
                                return {
                                    value: item.id,
                                    label: `${item.title} - ${item.title_macroproccess ?? ''}`
                                }
                            })
                        ]}
                        
                     />
                </div>
                <div className="flex between">
                    <button type="submit" style={{marginRight: '10px'}}><MdAdd/> Salvar</button>
                    {
                        props.id ? (
                            <button type="button" onClick={onDelete}><MdDelete/> Apagar</button>
                        ) : null
                    }
                </div>
            </form>
        </>
    )
}