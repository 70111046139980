import React, { useEffect, useRef, useState } from 'react'
import { FaEnvelope, FaPhone, FaPlus } from 'react-icons/fa'
import { Content } from '../../components/Content'
import { ConfigModal, Modal } from '../../components/Modal'
import { Navbar } from '../../components/Navbar'
import Warning from '../../functions/Modals/Warning'
import { closeSession } from '../../functions/Session'
import { CompaniesAPI, CompaniesItemProps } from '../../services/Companies/CompaniesController'
import { FormCompanies } from './components/FormCompanies'

export interface CompaniesProps {}

export const Companies: React.FC<CompaniesProps> = (props) => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const loading = useRef(true)
    const [companies, setCompanies] = useState<CompaniesItemProps[]>([])

    useEffect(() => {
        CompaniesAPI.getAll(data => {
            setCompanies(data)

            loading.current = false
        }).catch(err => {
            loading.current = false
        })
    }, [])

    return (
        <div>
            <Modal {...modalConfig}/>
            <Navbar/>
            <Content>
                <div className="my-tasks-backlog">
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Minhas empresas</h2>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    title: (
                                        <>
                                            <h2>Criar empresa</h2>
                                        </>
                                    ),
                                    content: <FormCompanies />,
                                    onClose: () => setModalConfig({show: false})
                                })
                            }}><FaPlus/> Criar empresa</button>
                        </div>
                    </div>
                    <h3 className="title-divider">Lista de usuários</h3>
                    {
                        companies.length > 0 ?
                        (
                            <>
                                {
                                    companies.map((e, i) => (
                                        <div key={`${i}`} className="task-item-finish">
                                            <p className="label">
                                                <span className="span-link" style={{fontSize: '1em'}} onClick={() => {
                                                    setModalConfig({
                                                        show: true,
                                                        title: (
                                                            <>
                                                                <h2>Editar empresa</h2>
                                                            </>
                                                        ),
                                                        content: <FormCompanies id={e.id}/>,
                                                        onClose: () => setModalConfig({show: false})
                                                    })
                                                }}>
                                                    {e.company_name ?? "Minha empresa"}
                                                </span>
                                            </p>
                                            <p className="feedback">{e.email} <a href={`mailto:+${e.email}`}><FaEnvelope className="clr-theme"/></a></p>
                                            {e.phone ? <p className="date_finish">{e.phone} <a href={`tel:+${e.phone}`}><FaPhone className="clr-theme"/></a></p> : null}

                                            <button className="task-item-finish button-alter" onClick={() => {
                                                if(e.id)
                                                {
                                                    CompaniesAPI.changeCompany(e.id, response => {
                                                        Warning("Empresa alterada, agora refaça seu login por segurança iremos sincronizar o sistema.", () => {
                                                            closeSession([
                                                                {
                                                                    label: "user"
                                                                },
                                                                {
                                                                    label: "X-Request-Token"
                                                                },
                                                                {
                                                                    label: "X-User-Id"
                                                                },
                                                                {
                                                                    label: "X-Request-Type"
                                                                }
                                                            ])
                                                            
                                                            window.location.href = '/'
                                                        })
                                                    })
                                                }
                                            }}>
                                                Acessar empresa
                                            </button>
                                        </div>
                                    ))
                                }
                            </>
                        ) : <h4>Você ainda não possui contatos</h4>
                    }
                </div>
            </Content>
        </div>
    )
}