import { AxiosResponse } from "axios";
import ServerRequest from "../../../../functions/ServerRequest";
import DefaultErrorResponse from "../../../../functions/DefaultErrorResponse";

const route = '/users/controle-mudanca'


export interface ControleMudancaItemProps {
    id?: number
    title?: string
    date_inicial?: string
    motivo?: string
    date_acomp?: string
    descricao?: string
    justificativa?: string
    solicitante?: string
    date_solicitacao?: string
    acoes_mudanca?: string
    resultado_mudanca?: string
    quem_informado?: string
    docs_alterados?: string
    acoes_impactos?: string
    created_date?: string
    user_created?: {
        name?: string
    }
}

export interface ControleMudancaFormProps extends ControleMudancaItemProps {
    title: string
}

class ControleMudancaService
{
    async create(
        data: ControleMudancaFormProps,
        onSuccess: ((success: ControleMudancaItemProps) => void),
    )
    {
        return await ServerRequest.post(`${route}`, data).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        data: ControleMudancaFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.put(`${route}/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((proccesses: Array<ControleMudancaItemProps>) => void),
    )
    {
        return await ServerRequest.get(`${route}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((rnc: ControleMudancaItemProps) => void),
    )
    {
        return await ServerRequest.get(`${route}/${id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async pdf(
        id: string,
        onSuccess: ((file: Blob) => void),
        onError: ((err: any) => void)
    )
    {
        return await ServerRequest.post(`${route}/pdf/${id}`, {}, { responseType: 'blob'}).then(response => onSuccess(response.data)).catch(onError);
    }
}

export const controle_mudanca_api = new ControleMudancaService()