import { Markup } from 'interweave'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Content } from '../../components/Content'
import { LinkBackHistory } from '../../components/LinkBackHistory'
import { Navbar } from '../../components/Navbar'
import { FAQItem, ControllerFAQ } from '../../services/FAQ/ControllerFAQ'


export const FAQView = () => 
{
    const [faq, setFaq] = useState<FAQItem>()

    const { id, module_id } = useParams<{id: string, module_id: string}>()

    useEffect(() => {
        ControllerFAQ.getById(id, data => {
            setFaq(data)

            window.document.title = `Pergunta: ${data.title}`
        }, err => {})
    }, [id])

    return (
        <>
            <Navbar/>
            <Content title={
                <div className="title-button">
                    <div className="title">
                        <LinkBackHistory to={`/faq/${module_id}`} />
                        <h1 className="app-content-h1-destaque">FAQ</h1>
                    </div>
                </div>
            }>
                <div className="content-box">
                    <p className="faq-title">
                        {
                            faq?.title
                        }
                    </p>
                    <div className="faq-content">
                        <Markup content={faq?.content} />
                    </div>
                </div>
            </Content>  
        </>
    )
}
