import React, { useEffect, useState } from 'react'
import { FaArrowLeft, FaPlus } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { Content } from '../../../../components/Content'
import { ConfigModal } from '../../../../components/Modal'
import { ModalRight } from '../../../../components/ModalRight'
import { Navbar } from '../../../../components/Navbar'
import { SubjectItem } from '../../../../services/Protocol/ProtocolInterfaces'
import { ViewSubjects } from '../../../../services/Protocol/ViewSubjects'
import { CreateSubject } from './components/CreateSubject'
import { DuplicateSubject } from './components/DuplicateSubject'
import { UpdateSubject } from './components/UpdateSubject'

export interface SubjectProps {}

export const Subject: React.FC<SubjectProps> = () => 
{
    const history = useHistory()
    const [confModal, setConfModal] = useState<ConfigModal>({show: false})

    const [subjects, setSubjects] = useState<Array<SubjectItem>>([])

    useEffect(() => {
        ViewSubjects(s => {
            if(s.subjects)
            {
                setSubjects(s.subjects)
            }
        }, e => {})
    }, [])
    
    return (
        <>
            <ModalRight {...confModal} />
            <Navbar/>
            <Content title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <h1 className="app-content-h1-destaque">Assuntos: Ata e pauta</h1>
                        </div>
                        <div className="options">
                            <button onClick={() => history.push('/protocols')}><FaArrowLeft/>Voltar</button>
                            <button onClick={() => {
                                setConfModal({
                                    show: true,
                                    title: (
                                        <>
                                            <h2>Novo assunto</h2>
                                        </>
                                    ),
                                    content: <CreateSubject />,
                                    onClose: () => setConfModal({show: false})
                                })
                            }}><FaPlus/> Criar</button>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <h3 className="title-divider">Em aberto</h3>
                    {
                        subjects.length > 0 ?
                        (
                            subjects.map((e, i) => {
                                if(e.status === "1")
                                {
                                    return (
                                        <>
                                                <div key={`${i}`}className="box-data btn-box" onClick={() => {
                                                    setConfModal({
                                                        show: true,
                                                        title: (
                                                            <>
                                                                <h2>Atualizando assunto</h2>
                                                            </>
                                                        ),
                                                        content: <UpdateSubject id={e.id} />,
                                                        onClose: () => setConfModal({show: false})
                                                    })
                                                }}>
                                                    <p className="title">{e.title}</p>
                                                </div>
                                        </>
                                    )
                                }else{
                                    return null;
                                }
                            })
                        ) :
                        (
                            <h3>Sem informações para serem mostradas</h3>
                        )
                    }
                    <br/>
                    <h3 className="title-divider">Fechados</h3>
                    {
                        subjects.length > 0 ?
                        (
                            subjects.map((e, i) => {
                                if(e.status === "2")
                                {
                                    return (
                                        <>
                                                <div key={`${i}`}className="box-data btn-box" onClick={() => {
                                                    setConfModal({
                                                        show: true,
                                                        title: (
                                                            <>
                                                                <h2>Ver assunto</h2>
                                                            </>
                                                        ),
                                                        content: <DuplicateSubject id={e.id} />,
                                                        onClose: () => setConfModal({show: false})
                                                    })
                                                }}>
                                                    <p className="title">{e.title}</p>
                                                </div>
                                        </>
                                    )
                                }else{
                                    return null;
                                }
                            })
                        ) :
                        (
                            <h3>Sem informações para serem mostradas</h3>
                        )
                    }
                </div>
            </Content>
        </>
    )
}
