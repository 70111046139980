import { AxiosResponse } from "axios";
import ServerRequest from "../../../../functions/ServerRequest";
import DefaultErrorResponse from "../../../../functions/DefaultErrorResponse";

const route = '/users/analise-critica/analise-item'

export interface AnaliseCriticaItensItemProps {
    id?: number
    assunto_entrada_id?: number,
    acao?: string,
    resp?: string,
    prazo?: string,
    custo?: string,
    eficacia_saidas?: string,
    analise_critica_id?: string
    analise_critica_client_id?: string
}

export interface AnaliseCriticaItemFormProps {
    assunto_entrada_id?: string
    acao?: string
    resp?: string
    prazo?: string
    custo?: string
    eficacia_saidas?: string
    analise_critica_id?: string
    analise_critica_client_id?: string
}

class AnaliseCriticaItensService
{
    async create(
        data: AnaliseCriticaItemFormProps[],
        onSuccess: ((success: AnaliseCriticaItensItemProps) => void),
    )
    {
        return await ServerRequest.post(`${route}`, data).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        data: AnaliseCriticaItemFormProps[],
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.put(`${route}/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((proccesses: Array<AnaliseCriticaItensItemProps>) => void),
    )
    {
        return await ServerRequest.get(`${route}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async getById(
        analise_critica_id: string,
        assunto_entrada_id: string,
        onSuccess: ((rnc: AnaliseCriticaItensItemProps[]) => void),
        client?: string
    )
    {
        return await ServerRequest.get(client ? `${route}/${analise_critica_id}/${assunto_entrada_id}/${client}` : `${route}/${analise_critica_id}/${assunto_entrada_id}`).then(response => onSuccess(response.data)).catch(err => {
            if(err.status == 400)
            {
                return;    
            }else{
                return DefaultErrorResponse(err)
            }
        });
    }
}

export const analise_critica_itens_api = new AnaliseCriticaItensService()