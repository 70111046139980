import React, { useEffect, useRef, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { Content } from '../../../components/Content'
import { ConfigModal, ModalRight } from '../../../components/ModalRight'
import { Navbar } from '../../../components/Navbar'
import { NoWithContent } from '../../../components/NoWithContent'
import { IndicatorsAPI, IndicatorsItemProps } from '../../../services/Resultado/Indicators/Indicators'
import { DepartmentMission } from './components/DepartmentMission'
import 'react-toastify/dist/ReactToastify.css';
import { CreateIndicators } from '../ImportIndicators/components/CreateIndicators'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { FaChartLine, FaEdit, FaPlus, FaTachometerAlt, FaTrash } from 'react-icons/fa'
import { UpdateIndicators } from '../ImportIndicators/components/UpdateIndicators'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

export interface DepartmentIndicatorsProps {}
export interface DepartmentIndicatorsRouteProps {
    department_id: string
}

export const DepartmentIndicators: React.FC<DepartmentIndicatorsProps> = (props) => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({
        show: false
    })

    const { department_id } = useParams<DepartmentIndicatorsRouteProps>()

    const [indicadorsDepartment, setIndicadorsDepartment] = useState<Array<IndicatorsItemProps>>([])
    const [myIndicators, setMyIndicators] = useState<Array<IndicatorsItemProps>>([])
    const loading = useRef(true)
    const [details, setDetails] = useState({ title: '', mission: ''})

    useEffect(() => {
        IndicatorsAPI.getIndicatorsByDepartment(department_id, setIndicadorsDepartment)
        IndicatorsAPI.getMyIndicatorsByDepartment(department_id, setMyIndicators)
        IndicatorsAPI.departemntDetails(department_id, data => {
            setDetails({
                title: data.department_title,
                mission: data.department_mission
            })
        })

        loading.current = false
    }, [department_id])

    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
          return;
        }
    
        let items: any = reorder(
            myIndicators,
            result.source.index,
            result.destination.index
        );
    
        setMyIndicators([...items]);

        IndicatorsAPI.reorder({list: items}, () => {})
      }

    return (
        <>
            <ModalRight {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={
                <div className="title-button">
                    <div className="title">
                        <LinkBackHistory to="/indicadores" />
                        <h1 className="app-content-h1-destaque">{details.title}: Indicadores de Desempenho</h1>
                    </div>
                    <div className="options">
                        
                        <button onClick={() => {
                            setModalConfig({
                                show: true,
                                content: <DepartmentMission onSuccess={() => window.location.reload()} department_id={department_id} />,
                                onClose: () => setModalConfig({
                                    show: false
                                }),
                                title: <h2 className="title-form-modal">Missão do Departamento</h2>
                            })
                        }}>Missão do Departamento</button>
                        <Link to={`/indicadores/dashboard/${department_id}`}>Dashboard</Link>
                        <Link to={`/indicadores/dashboard/${department_id}/graphics`}><FaChartLine /></Link>
                        <Link to={`/indicadores/dashboard/${department_id}/graphics/meta`}><FaTachometerAlt /></Link>
                        <button onClick={() => {
                            setModalConfig({
                                show: true,
                                content: <CreateIndicators department_id={department_id}  />,
                                title: <h2 className="title-form-modal">Cadastrar indicador</h2>,
                                onClose: () => setModalConfig({show: false})
                            })
                        }}><FaPlus /></button>
                    </div>
                </div>
            }>
                <div className="content-box">
                    <h2 className="title-divider" style={{marginTop: '0'}}>Seus indicadores</h2>
                    <div className="list-indicators">
                        {
                            myIndicators.map((item, index) => {
                                return (
                                    <>
                                        <div className="indicator-item" key={`${index}`}>
                                            <p className="title">{item.title}</p>    
                                            <p className="item">Tipo de indicador: <span className="value">{item.type.title}</span></p>
                                            <p className="item">Para que serve: <span className="value">{item.obs}</span></p>
                                            <p className="item">Fórmula: <span className="value">{item.formula}</span></p>
                                            <p className="item">Unidade de medida: <span className="value">{item.unit.title}</span></p>
                                            <p className="item">Polaridade: <span className="value">{item.polarity.title}</span></p>
                                            <p className="item">Periodicidade: <span className="value">{item.frequency.title}</span></p>

                                            <div className="flex" style={{marginTop: '10px'}}>
                                                {
                                                    item.edit ?
                                                    (
                                                        <button className="btn-styled normal" style={{marginRight: '10px'}} onClick={() => {
                                                            if(item.edit)
                                                            {
                                                                setModalConfig({
                                                                    show: true,
                                                                    content: <UpdateIndicators id={item.id}  />,
                                                                    title: <h2 className="title-form-modal">Editar indicador</h2>,
                                                                    onClose: () => setModalConfig({show: false})
                                                                })
                                                            }
                                                        }}><FaEdit /> Editar</button>
                                                    ) : null
                                                }
                                                <button  className="btn-styled danger"  onClick={() => {
                                                    IndicatorsAPI.deleteMyIndicator(item.id, () => {
                                                        setMyIndicators([...myIndicators.filter(old_item => old_item.id !== item.id)])

                                                        toast.success('Apagado com sucesso!')
                                                    })
                                                }}><FaTrash /> Apagar</button>
                                            </div>
                                        </div> 
                                    </>
                                )
                            })
                        }
                        {
                            myIndicators.length === 0 ? 
                            (
                                <NoWithContent>
                                    <p>Você ainda não tem nenhum indicador.</p>
                                </NoWithContent>
                            ) : null
                        }
                    </div>
                </div>
                <div className="content-box">
                    <h2 className="title-divider" style={{marginTop: '0'}}>Ordenar indicadores</h2>
                    {
                        myIndicators.length > 0 ? 
                        (
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    >
                                    {myIndicators.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div className="proccess-item" ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            onClick={() => {}}>
                                                        <div className="title-with-button-options">
                                                            <div className="title">
                                                                <p><b>{item.title}</b></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                        )
                                            }
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>
                            </DragDropContext>
                        ) : (
                            <>
                                <h3>Crie agora mesmo seu Onboarding, clique em *Novo item</h3>
                            </>
                        )
                    }
                </div>
                <div className="content-box">
                    <h2 className="title-divider" style={{marginTop: '0'}}>Importe mais indicadores</h2>
                    <div className="list-indicators">
                        {
                            indicadorsDepartment.map((item, index) => {
                                return (
                                    <>
                                        <div className="indicator-item" key={`${index}`}>
                                            <p className="title">{item.title} - {item.department.label}</p>    
                                            <p className="item">Departamento: <span className="value">{item.department.label}</span></p>
                                            <p className="item">Tipo de indicador: <span className="value">{item.type.title}</span></p>
                                            <p className="item">Para que serve: <span className="value">{item.obs}</span></p>
                                            <p className="item">Fórmula: <span className="value">{item.formula}</span></p>
                                            <p className="item">Unidade de medida: <span className="value">{item.unit.title}</span></p>
                                            <p className="item">Polaridade: <span className="value">{item.polarity.title}</span></p>
                                            <p className="item">Periodicidade: <span className="value">{item.frequency.title}</span></p>
                                            
                                            <button className="btn-styled normal" style={{marginTop: '20px'}} onClick={() => {
                                                IndicatorsAPI.importIndicator({
                                                    indicator_id: item.id,
                                                    department_id
                                                }, () => {
                                                    setIndicadorsDepartment([...indicadorsDepartment.filter(old_item => old_item.id !== item.id)])

                                                    toast.success('Importado com sucesso!')

                                                    IndicatorsAPI.getMyIndicatorsByDepartment(department_id, setMyIndicators)
                                                })
                                            }}>Importar</button>
                                        </div> 
                                    </>
                                )
                            })
                        }
                        {
                            indicadorsDepartment.length === 0 ? 
                            (
                                <NoWithContent>
                                    <p>Não existem mais indicadores para importar.</p>
                                </NoWithContent>
                            ) : null
                        }
                    </div>
                </div>
            </Content>  
            <ToastContainer pauseOnFocusLoss={false}  position="bottom-right" pauseOnHover={false} autoClose={1500} />
        </>
    )
}