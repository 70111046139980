import React, { useEffect, useState } from 'react'
import { FaDownload, FaFileDownload, FaPlus, FaTrash } from 'react-icons/fa'
import { MdAdd, MdDelete } from 'react-icons/md'
import { EditorHTML } from '../../../../components/EditorHTML'
import { APP_CONFIG } from '../../../../constants'
import { ConfirmDelete } from '../../../../functions/Modals/Confirm'
import Success from '../../../../functions/Modals/Success'
import Warning from '../../../../functions/Modals/Warning'
import { AttachmentPopController, PopFileProps } from '../../../../services/Attachment/AttachmentPopController'
import { POPController, POPForm } from '../../../../services/POP/POPController'
import { UserForm, UsersController } from '../../../../services/Users/UsersController'

export interface FormPOPProps {
    id?: string,
    proccess_id: string,
    onSuccess?: (() => void)
}

export const FormPOP: React.FC<FormPOPProps> = (props) => 
{
    const baseForm = {
        id: '',
        client_id: '',
        objective: '',
        obs: '',
        docs_ref: '',
        checklist: '',
        process_polarity_indicators: '',
        result: '',
        obs_error: '',
        proccess_id: '',
        elaborate_by: '',
        approved_user_id: '',
        necessary_materials: '',
        necessary_systems: ''
    };

    const [objective, setobjective] = useState('')
    const [obs, setobs] = useState('')
    const [docs_ref, setdocs_ref] = useState('')
    const [checklist, setchecklist] = useState('')
    const [process_polarity_indicators, setprocess_polarity_indicators] = useState('')
    const [result, setresult] = useState('')
    const [obs_error, setobs_error] = useState('')
    const [improvement_point, setImprovement_point] = useState('')
    const [necessary_materials, setnecessary_materials] = useState('')
    const [necessary_systems, setnecessary_systems] = useState('')
    const [users, setUsers] = useState<UserForm[]>([])

    const [form, setForm] = useState<POPForm>(baseForm)

    const [files, setFiles] = useState<PopFileProps[]>([]);
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        POPController.update({
            ...form,
            objective,
            obs,
            docs_ref,
            checklist,
            process_polarity_indicators,
            result,
            obs_error,
            necessary_materials,
            necessary_systems,
            improvement_point
        }, response => Success('Salvo com sucesso!', () => {}))
    }

    const onDelete = () => {
        if(props.proccess_id)
        {
            POPController.delete(props.proccess_id, () => Success('Apagado com sucesso!', () => {
                if(props.onSuccess)
                {
                    props.onSuccess()
                }
            }))
        }
    }

    useEffect(() => {
        if(props.proccess_id)
        {
            AttachmentPopController.getAll(props.proccess_id, setFiles)

            POPController.getByProccess(props.proccess_id, data => {
                if(data)
                {
                    setForm({...data, proccess_id: props.proccess_id})

                    setobjective(data.objective)
                    setobs(data.obs)
                    setdocs_ref(data.docs_ref)
                    setchecklist(data.checklist)
                    setprocess_polarity_indicators(data.process_polarity_indicators)
                    setresult(data.result)
                    setobs_error(data.obs_error)
                    setnecessary_materials(data.necessary_materials)
                    setnecessary_systems(data.necessary_systems)
                    setImprovement_point(data.improvement_point ?? '')

                }else{
                    
                    setForm({
                        id: '',
                        client_id: '',
                        objective: '',
                        obs: '',
                        docs_ref: '',
                        checklist: '',
                        process_polarity_indicators: '',
                        result: '',
                        obs_error: '',
                        proccess_id: props.proccess_id,
                        elaborate_by: '',
                        approved_user_id: '',
                        necessary_materials: '',
                        necessary_systems: ''
                    })
                }
            })
        }else{
            setForm({
                id: '',
                client_id: '',
                objective: '',
                obs: '',
                docs_ref: '',
                checklist: '',
                process_polarity_indicators: '',
                result: '',
                obs_error: '',
                proccess_id: props.proccess_id,
                elaborate_by: '',
                approved_user_id: '',
                necessary_materials: '',
                necessary_systems: ''
            })
        }

        UsersController.getAll(setUsers)
    }, [props.id, props.proccess_id])

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                <div className="box-input">
                    <p>Objetivos e Oportunidades</p>
                    <EditorHTML onChange={value => setobjective(value)} value={objective} />
                </div>
                <div className="box-input">
                    <p>Siglas e abreviaturas</p>
                    <EditorHTML onChange={value => setobs(value)} value={obs} />
                </div>
                <div className="box-input">
                    <p>Documentos de referência</p>
                    <EditorHTML onChange={value => setdocs_ref(value)} value={docs_ref} />
                </div>
                <div className="box-input">
                    <p>Sistema necessários</p>
                    <EditorHTML onChange={value => setnecessary_systems(value)} value={necessary_systems} />
                </div>
                <div className="box-input">
                    <p>Materiais necessários</p>
                    <EditorHTML onChange={value => setnecessary_materials(value)} value={necessary_materials} />
                </div>
                <div className="box-input">
                    <p>Resultado esperado</p>
                    <EditorHTML onChange={value => setresult(value)} value={result} />
                </div>
                <div className="box-input">
                    <p>Riscos e Cuidados Especiais</p>
                    <EditorHTML onChange={value => setchecklist(value)} value={checklist} />
                </div>
                <div className="box-input">
                    <p>Ações em caso de não conformidade</p>
                    <EditorHTML onChange={value => setobs_error(value)} value={obs_error} />
                </div>
                <div className="box-input">
                    <p>Indicadores do Processo e Polaridade</p>
                    <EditorHTML onChange={value => setprocess_polarity_indicators(value)} value={process_polarity_indicators} />
                </div>
                <div className="box-input">
                    <p>Pontos de melhoria (opcional)</p>
                    <EditorHTML onChange={value => setImprovement_point(value)} value={improvement_point} />
                </div>
                <div className="box-input">
                    <p>Revisar em</p>
                    <input type="date" onChange={e => setForm({...form, date_revision: e.target.value})} value={form.date_revision} />
                </div>
                <hr />
                <div className="box-input">
                    <p>Elaborado por</p>
                    <input type="text" onChange={e => setForm({...form, elaborate_by: e.target.value})} value={form.elaborate_by} />
                </div>
                <div className="box-input">
                    <p>Aprovado Por</p>
                    <select onChange={e => setForm({...form, approved_user_id: e.target.value})} value={form.approved_user_id}>
                        <option value="">Selecione uma opção</option>
                        {
                            users.map((item) => {
                                return <option value={item.id}>{item.name}</option>
                            })
                        }
                    </select>
                </div>
                <hr />
                <div className="title-toolkit">
                    <div className="title">
                        <span>Arquivos</span>
                    </div>
                    <div className="toolkit">
                        <label htmlFor="file" className="toolkit-button default"><FaPlus/></label>
                    </div>
                </div>
                <input  style={{display: 'none'}} type="file" id="file" onChange={event => {
                    let formData = new FormData()

                    if(event.target.files && event.target.files[0])
                    {
                        formData.append('file', event.target.files[0])
        
                        if(props.proccess_id)
                        {
                            AttachmentPopController.sendFile(formData, props.proccess_id, () => {
                                Success('Arquivo enviado com sucesso', () => {
                                    AttachmentPopController.getAll(props.proccess_id, setFiles)
                                })
                            })
                        }
                    }else{
                        Warning('Você não escolheu o nenhum arquivo')
                    }
                }} />
                {files.map((item, index) => {
                    return (
                        <div className="pop-file">
                            <div className="title">{item.title}</div>
                            <div className="options">
                                <span className="clr-success" onClick={() => {
                                    window.open(`${process.env.REACT_APP_URL_ATTACHMENT}/${item.path}`, '_blank')
                                }}><FaDownload /></span>
                                <span className="clr-danger" onClick={() => {
                                    AttachmentPopController.delete(item.id, () => Success('Apagado com sucesso', () => {
                                        AttachmentPopController.getAll(props.proccess_id, setFiles)
                                    }))
                                }} ><FaTrash /></span>
                            </div>
                        </div>
                    )
                })}
                <div className="flex between">
                    <button type="submit" style={{marginRight: '10px'}}><MdAdd/> Salvar</button>
                    {
                        props.id ? (
                            <button type="button" onClick={onDelete}><MdDelete/> Apagar</button>
                        ) : null
                    }
                </div>
            </form>
        </>
    )
}