import React from 'react'
import { ConfigModal } from '../../../components/Modal';
import { TaskForm } from '../../../components/TaskForm';
import { BacklogItem } from '../../../services/Rotina/Macro/ConsultarBacklog';
import { TaskBoxBacklog } from './TaskBoxBacklog';

export interface BacklogProps {
    setModalConfig: ((data: ConfigModal) => void),
    tasks: Array<BacklogItem>
    onCreateTask?: (() => void)
    onSendTask?: (() => void)
    onDeleteTask?: (() => void)
}

export const Backlog: React.FC<BacklogProps> = (props) => 
{
    const {
        setModalConfig,
        tasks
     } = props;

    return (
        <div className="body-content">
            <div className="title">
                    <h2 onClick={() => {}}>Backlog</h2>
            </div>
            <h3 className="title-divider">Prioridades</h3>
            {
                tasks.filter(e => e.type === "3").map((e, i) => 
                    <TaskBoxBacklog 
                        key={`${i}`}
                        haveSubtitle
                        taskProps={e} 
                        onClick={() => {
                            setModalConfig({
                                show: true,
                                title: (
                                    <>
                                        <h2 className="title-modal">{e.label}</h2>
                                    </>
                                ),
                                content: <TaskForm 
                                    id={e.id}
                                    type="SEND_CALENDAR"
                                    onChange={type => {
                                        if(props.onSendTask)
                                        {
                                            props.onSendTask()
                                        }
                                    }} 
                                />,
                                onClose: () => setModalConfig({show: false})
                            })
                        }}  
                    />
                )
            }


            <h3 className="title-divider">Tarefas da Equipe Modum</h3>
            {
                tasks.filter(e => e.type === "2" && e.permission === "2").map((e, i) => 
                    <TaskBoxBacklog 
                        key={`${i}`}
                        taskProps={e} 
                        onClick={() => {
                            setModalConfig({
                                show: true,
                                title: <h2 className="title-modal">{e.label}</h2>,
                                content: <TaskForm 
                                    id={e.id}
                                    type="SEND_CALENDAR"
                                    onChange={type => {
                                        if(props.onSendTask)
                                        {
                                            props.onSendTask()
                                        }
                                    }} 
                                />,
                                onClose: () => setModalConfig({show: false})
                            })
                        }}  
                    />
                )
            }


            <h3 className="title-divider">Minhas tarefas</h3>
            {
                tasks.filter(e => e.type === "1" && e.permission === "1" && e.backlog_status === "1").map((e, i) => 
                    <TaskBoxBacklog 
                        key={`${i}`}
                        haveDateButton
                        haveSubtitle
                        taskProps={e} 
                        onDeleteTask={() => {
                            if(props.onDeleteTask)
                            {
                                props.onDeleteTask()
                            }
                        }}
                        onClick={() => {
                            setModalConfig({
                                show: true,
                                title: <h2 className="title-modal">{e.label}</h2>,
                                content: <TaskForm 
                                    id={e.id}
                                    type="SEND_CALENDAR"
                                    onChange={type => {
                                        if(props.onSendTask)
                                        {
                                            props.onSendTask()
                                        }
                                    }} 
                                />,
                                onClose: () => setModalConfig({show: false})
                            })
                        }}  
                    />
                )
            }
        </div>
    )
}
