import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import Success from '../../../../functions/Modals/Success'
import { NormasAPI, NormasItemFormProps } from '../../../../services/Normas/Normas'
import { Markup } from 'interweave';
import { EditorHTML } from '../../../../components/EditorHTML';

export interface FormNormasProps {
    id?: string,
    onSuccess?: (() => void)
}

export const FormNormas: React.FC<FormNormasProps> = (props) => 
{
    const [form, setForm] = useState<NormasItemFormProps>({
        topic: '',
        suggestions_explanations: '',
        description: '',
        link: ''
    })

    const [description, setDescription] = useState('')
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        if(props.id)
        {
            NormasAPI.update(props.id, {
                ...form, 
                description
            }, () => Success('Salvo com sucesso', () => window.location.reload()))
        }else{
            NormasAPI.save({
                ...form, 
                description
            }, () => Success('Salvo com sucesso', () => window.location.reload()))
        }
    }

    useEffect(() => {
        if(props.id)
        {
            NormasAPI.consultOne(props.id, data => {
                setForm(data)

                setDescription(data.description)
            })
        }
    }, [props.id])

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                <div className="box-input">
                    <p>Tópico</p>
                    <input type="text" onChange={e => setForm({...form, topic: e.target.value})} value={form.topic} />
                </div>
                {
                    form.suggestions_explanations !== "" ?
                    (
                        <div className="box-input">
                            <p><Markup content={form.suggestions_explanations.replaceAll('\n', '<br/>')} /></p>
                        </div>
                    ) : null
                }
                <div className="box-input">
                    <p>Descrição</p>
                    <EditorHTML defaultToolbar={true} value={description} onChange={data => setDescription(data)} />
                </div>
                <div className="box-input">
                    <p>Link</p>
                    <input type="text" onChange={e => setForm({...form, link: e.target.value})} value={form.link} />
                </div>
                <button type="submit"><MdAdd/> Salvar</button>
            </form>
        </>
    )
}