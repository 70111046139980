import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import Success from '../../../../functions/Modals/Success'
import { SwotFolderAPI, SwotFolderFormProps } from '../../../../services/PlanejamentoEstrategico/SwotFolder'
import { formatDate } from '../../../../functions/dataFormat'
import { format } from 'date-fns'

export interface FormSwotFolderProps {
    id?: string,
    onSuccess?: (() => void)
}

export const FormSwotFolder: React.FC<FormSwotFolderProps> = (props) => 
{
    const [form, setForm] = useState<SwotFolderFormProps>({
        title: ''
    })

    const [dateCreated, setDateCreated] = useState(true)
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        if(props.id)
        {
            SwotFolderAPI.update(props.id, form, s => Success('Salvo com sucesso!', async () => {
                window.location.reload()
            }))
        }else{
            SwotFolderAPI.create(form, s => Success('Salvo com sucesso!', async () => {
                window.location.reload()
            }))
        }
    }

    useEffect(() => {
        if(props.id)
        {
            SwotFolderAPI.getById(props.id, data => {
                setForm({
                    ...data,
                    date_created: data.date_created ? formatDate(data.date_created, 'yyyy-MM-dd') : '',
                    date_revision: data.date_revision ? formatDate(data.date_revision, 'yyyy-MM-dd') : ''  
                })

                if(!data.date_created)
                {
                    setDateCreated(false)
                }
            })
        }else{
            setForm({
                ...form,
                date_created: format(new Date(), 'yyyy-MM-dd')
            })
        }
    }, [props.id])

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                <div className="box-input">
                    <p>Titulo</p>
                    <input type="text" onChange={e => setForm({...form, title: e.target.value})} value={form.title} />
                </div>
                {
                    !dateCreated ? (
                        <div className="box-input">
                            <p>Data de criação</p>
                            <input type="date" onChange={e => setForm({...form, date_created: e.target.value})} value={form.date_created} />
                        </div>
                    ) : null 
                }
                <div className="box-input">
                    <p>Data de revisão</p>
                    <input type="date" onChange={e => setForm({...form, date_revision: e.target.value})} value={form.date_revision} />
                </div>
                <button type="submit"><MdAdd/> Salvar</button>
            </form>
        </>
    )
}