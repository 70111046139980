import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa';
import { StageView, StageViewProps } from '../../services/Tutorial/StageView';
import ModalStore from '../../stores/ModalStore';
import { Markup } from 'interweave';

export interface ModalJornadaProps {
    store: typeof ModalStore
}

export const ModalJornada: React.FC<ModalJornadaProps> = observer((props) => 
{
    const [stageDetails, setstageDetails] = useState<StageViewProps>({
        id: '',
        type: '',
        data_id: '',
        practice: '',
        practice_information: '',
        days_to_complete: '',
        sequence: '',
        module_id: ''
    })

    useEffect(() => {
        var dragMe: any = document.getElementById("modal-jornada"),
        /* o x inicial do drag*/
        dragOfX = 0,
        /* o y inicial do drag */
        dragOfY = 0;

        /* ao segurar o elemento */
        function dragStart(e: any) {
            /* define o x inicial do drag */
            dragOfX = e.pageX - dragMe.offsetLeft;
            /* define o y inicial do drag */
            dragOfY = e.pageY - dragMe.offsetTop;
            
            /* adiciona os eventos */
            dragMe.addEventListener("mousemove", dragMove);
            dragMe.addEventListener("mouseup", dragEnd);
        }
            
        /* ao ser arrastado */
        function dragMove(e: any) {
            /* atualiza a posição do elemento */
            dragMe.style.left = (e.pageX - dragOfX) + 'px';
            dragMe.style.top = (e.pageY - dragOfY) + 'px';
        }
            
        /* ao terminar o drag */
        function dragEnd() {
            /* remove os eventos */
            dragMe.removeEventListener("mousemove", dragMove);
            dragMe.removeEventListener("mouseup", dragEnd);
        }
            
        /* adiciona o evento que começa o drag */
        if(props.store.id && dragMe)
        {
            dragMe.addEventListener("mousedown", dragStart);

            StageView(props.store.id, setstageDetails, () => {});
        }
        
    }, [props.store.id])

    return (
        <>
            {props.store.show ? (
                <div id="modal-jornada" className="modal-jornada" style={{display: props.store.show ? 'block' : 'none'}}>
                    <div className="modal-close" >
                        <FaTimes onClick={() => {
                            props.store.setShow(false)
                        }}/>
                    </div>
                    <>
                        <Markup content={stageDetails.practice_information}/>
                    </>
                    <br/>
                    <span className="bkg-theme clr-white" style={{padding: '5px', borderRadius: '5px'}} onClick={() => {
                        window.location.href = `/jornada/${props.store.jornadaModule}/${props.store.jornadaTask}`
                    }}>Voltar a jornada</span>
                </div>
            ) : null}
        </>
    )
})