import { AxiosResponse } from "axios";
import Request from "../../functions/Request";

const route = '/client/manual-colaborador/introduction'

export interface ManualIntroductionFormProps {
    introduction: string
    client_id?: string
    id?: string
}

class ManualIntroduction
{
    async save(form: ManualIntroductionFormProps, success: ((response: AxiosResponse) => void))
    {
        return await Request.post(`${route}`, form).then(success);
    }

    async consult(success: ((response: ManualIntroductionFormProps) => void))
    {
        return await Request.get(`${route}`).then(resp => success(resp.data.response));
    }
}

export const ManualIntroductionAPI = new ManualIntroduction()