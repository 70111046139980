import React from 'react'
import { FaEdit, FaFolder, FaTrash } from 'react-icons/fa';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { ConfirmDelete } from '../../../../functions/Modals/Confirm';
import { FoldersAPI, FoldersItemProps } from '../../../../services/MyAttachments/Folders'

export interface FolderItemProps {
    folder: FoldersItemProps,
    onClickEdit: ((folder: FoldersItemProps) => void)
}

export const FolderItem: React.FC<FolderItemProps> = (props) => 
{
    const { folder } = props;
    const history = useHistory()
    const { addToast } = useToasts()

    return (
        <div className="box-button with-options">
            <div className="content"  onClick={() => {
                history.push(`/my-attachments/${folder.id}`)
            }}>
                <FaFolder/> {folder.name}
            </div>
            <div className="options">
                <FaEdit onClick={() => {
                    props.onClickEdit(folder)
                }}/>
                <FaTrash onClick={() => {
                    ConfirmDelete(() => {
                        FoldersAPI.delete(folder.id, () => {
                            addToast('Arquivo apagado com sucesso!', {appearance: "info", autoDismiss: true}, () => {
                                window.location.reload()
                            })
                        })
                    }, () => {})
                }}/>
            </div>
        </div>
    )
}