import { AxiosResponse } from "axios"
import Request from "../../functions/Request"
import { TaskProps } from "./ConsultarTask";

export interface ConsultarTasksFinishResponseItem {
    json_history: TaskProps
    calendar_id: string
    client_id: string
    date_finish: string
    feedback: string
    id: string
    label: string
}

export const ConsultTasksFinish = async (
    onSuccess: ((e: Array<ConsultarTasksFinishResponseItem>) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.get(`/calendar/macros-finish`).then(s => onSuccess(s.data.macros)).catch(onError);

export const TaskFinishReverse = async (
    id: string,
    onSuccess: ((e: AxiosResponse) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.post(`/calendar/macros-finish-reverse/${id}`).then(onSuccess).catch(onError);
