import React from 'react'
import { ConfirmDelete } from '../../../functions/Modals/ConfirmDelete';
import { ApagarRotina } from '../../../services/Rotina/ApagarRotina';
import { BacklogItem } from '../../../services/Rotina/Macro/ConsultarBacklog';

export interface TaskBoxBacklogProps {
    taskProps?: BacklogItem
    haveSubtitle?: boolean
    haveDateButton?: boolean,
    haveDateFinish?: boolean,
    onClick: (() => void)
    onDeleteTask?: (() => void)
}

export const TaskBoxBacklog: React.FC<TaskBoxBacklogProps> = (props) => {

    const { taskProps} = props;

    return (
        <>
            {
                
                <div className="task-item">
                    <div className="task-text">
                        <span className="title" onClick={props.onClick}>
                            {taskProps?.label}
                        </span>
                        {
                            props.haveSubtitle ? (
                                <span className="subtitle">{taskProps?.atividade_name}</span>
                            ) : null
                        }
                    </div>
                    {
                        props.haveDateButton ? (
                            taskProps?.permission === "1" ? (
                                <div className="task-date">
                                    <button onClick={() => ConfirmDelete(
                                                                            () => ApagarRotina(
                                                                                                taskProps.id, 
                                                                                                s => { 
                                                                                                    if(props.onDeleteTask)
                                                                                                    {
                                                                                                        console.log(props.onDeleteTask)
                                                                                                        props.onDeleteTask()
                                                                                                    }
                                                                                                }, 
                                                                                                e => Error('Houve um erro ao tentar apagar esta tarefa.')
                                                                                                ), () => {}, 'Tem certeza que deseja apagar esta tarefa.')} className="btn-styled danger small">Apagar</button>
                                </div>
                            ) : null
                        ) : null
                    }
                    
                </div>
            }
        </>
    )
}
