import React, { useEffect, useState } from 'react'
import { formatValue } from 'react-currency-input-field'
import InputMask from 'react-input-mask';
import { MdDeleteForever } from 'react-icons/md'
import { Link, useHistory } from 'react-router-dom'
import { Content } from '../../components/Content'
import { LoadingContent } from '../../components/LoadingContent'
import { ConfigModal, Modal } from '../../components/Modal'
import { Navbar } from '../../components/Navbar'
import { ConfirmDelete } from '../../functions/Modals/ConfirmDelete'
import Success from '../../functions/Modals/Success'
import Warning from '../../functions/Modals/Warning'
import { getMyPaymentsAPI, PaymentProps } from '../../services/Account/getMyPayments'
import { RegisterAPI } from '../../services/Register/Register';
import { UpdateAddressAPI } from '../../services/Account/UpdateAddress';
import { APP_CONFIG } from '../../constants';

export interface MyPlansProps {}

export const MyPlans: React.FC<MyPlansProps> = (props) => 
{
    const [payments, setPayments] = useState<Array<PaymentProps>>([])
    const history = useHistory()
    const [loadingPage, setLoadingPage] = useState(false)
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [haveAddress, setHaveAddress] = useState(false)
    
    useEffect(() => {
        UpdateAddressAPI.getStatus(response => {
            if(response.status)
            {
                getMyPaymentsAPI.payments(payments => {
                    setPayments(payments)
        
                    setHaveAddress(true)
                    setLoadingPage(true)
                })
            }else{
                setLoadingPage(true)
            }
        })
    }, [])

    const [cep, setCep] = useState('')

    const [address, setAddress] = useState({
        cep: '',
        bairro: '',
        complemento: '',
        ddd: '',
        localidade: '',
        logradouro: '',
        number: '',
        uf: '',
    })

    const handleChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => 
    {
        setAddress({...address, [e.target.name]: e.target.value})
    }

    if(!haveAddress)
    {
        return (
            <>
                <Navbar />
                <Content title={
                    <>
                        <div className="title-button">
                            <div className="title">
                                <h1 className="app-content-h1-destaque">Configure seu endereço</h1>
                            </div>
                        </div>
                    </>
                } >
                    {
                        !loadingPage ?
                        (
                            <LoadingContent/>
                        ) : (
                            <div className="content-box">
                                <p><i>Para acessar/compras os planos, precisamos que nos confirme seu endereço para eventuais cobranças, caso venha ter interesse em nossos planos e serviços no futuro**</i></p>
                                <br />
                                <h3 className="title-divider" style={{marginTop: 0}}>Preencha com seus dados abaixo</h3>
                                <form onSubmit={event => {
                                    event.preventDefault()

                                    console.log(address);

                                    UpdateAddressAPI.sendAddress(address, success => {
                                        Success('Endereço salvo com sucesso', () => {
                                            setHaveAddress(true)
                                            setLoadingPage(false)
                                            
                                            getMyPaymentsAPI.payments(payments => {
                                                setPayments(payments)
                                    
                                                setHaveAddress(true)
                                                setLoadingPage(true)
                                            })
                                        })
                                    })
                                }} method="post">
                                    <div className="row" style={{marginTop: '25px'}}>
                                        <div className="col-12 col-xl-2" style={{marginBottom: '20px'}}>
                                            <div className="input-box-v2">
                                                <label>Informe seu CEP</label>
                                                <InputMask placeholder="Seu cep" required mask={"99999999"} value={cep} maskPlaceholder="" onChange={async (event) => {
                                                    let cep = event.target.value.replace(/\D/g, "")

                                                    if(cep.length === 8)
                                                    {
                                                        await RegisterAPI.cep(cep, response => {
                                                            setAddress({
                                                                cep: response.data.cep ?? cep,
                                                                bairro: response.data.bairro,
                                                                complemento: '',
                                                                ddd: '',
                                                                localidade: response.data.localidade,
                                                                logradouro: response.data.logradouro,
                                                                number: '',
                                                                uf: response.data.uf,
                                                            })
                                                        }, console.error)
                                                    }
                                                    
                                                    setCep(event.target?.value ?? cep)
                                                } }  />
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-8" style={{marginBottom: '20px'}}>
                                            <div className="input-box-v2">
                                                <label>Rua</label>
                                                <input placeholder="Digite sua rua" type="text" name="logradouro" value={address.logradouro} onChange={handleChangeAddress} required autoComplete="off"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-2" style={{marginBottom: '20px'}}>
                                            <div className="input-box-v2">
                                                <label>Número</label>
                                                <input type="number" placeholder="Digite o número" name="number" value={address.number} onChange={handleChangeAddress} required autoComplete="off"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-12" style={{margin: '10px 0'}} />
                                        <div className="col-12 col-xl-3" style={{marginBottom: '20px'}}>
                                            <div className="input-box-v2">
                                                <label>Complemento</label>
                                                <input type="text" name="complemento" placeholder="Digite o complemento caso aja" value={address.complemento} onChange={handleChangeAddress} autoComplete="off"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-3" style={{marginBottom: '20px'}}>
                                            <div className="input-box-v2">
                                                <label>Cidade</label>
                                                <input type="text" name="localidade" placeholder="Sua cidade" value={address.localidade} onChange={handleChangeAddress} required autoComplete="off"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-3" style={{marginBottom: '20px'}}>
                                            <div className="input-box-v2">
                                                <label>Bairro</label>
                                                <input type="text" name="bairro" placeholder="Seu bairro" value={address.bairro} onChange={handleChangeAddress} required autoComplete="off"/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-3" style={{marginBottom: '20px'}}>
                                            <div className="input-box-v2">
                                                <label>Estado</label>
                                                <select name="uf" value={address.uf} onChange={event => setAddress({...address, uf: event.target.value})}>
                                                    <option></option>
                                                    <option value="AC">Acre</option>
                                                    <option value="AL">Alagoas</option>
                                                    <option value="AP">Amapá</option>
                                                    <option value="AM">Amazonas</option>
                                                    <option value="BA">Bahia</option>
                                                    <option value="CE">Ceará</option>
                                                    <option value="DF">Distrito Federal</option>
                                                    <option value="ES">Espírito Santo</option>
                                                    <option value="GO">Goiás</option>
                                                    <option value="MA">Maranhão</option>
                                                    <option value="MT">Mato Grosso</option>
                                                    <option value="MS">Mato Grosso do Sul</option>
                                                    <option value="MG">Minas Gerais</option>
                                                    <option value="PA">Pará</option>
                                                    <option value="PB">Paraíba</option>
                                                    <option value="PR">Paraná</option>
                                                    <option value="PE">Pernambuco</option>
                                                    <option value="PI">Piauí</option>
                                                    <option value="RJ">Rio de Janeiro</option>
                                                    <option value="RN">Rio Grande do Norte</option>
                                                    <option value="RS">Rio Grande do Sul</option>
                                                    <option value="RO">Rondônia</option>
                                                    <option value="RR">Roraima</option>
                                                    <option value="SC">Santa Catarina</option>
                                                    <option value="SP">São Paulo</option>
                                                    <option value="SE">Sergipe</option>
                                                    <option value="TO">Tocantins</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <br />
                                    <button className="btn bkg-theme clr-white">Salvar</button>
                                </form>
                            </div>
                        )
                    }
                </Content>
            </>
        )
    }

    return (
        <>
            <Navbar/>
            <Modal {...modalConfig}/>
            {
                !loadingPage ?
                (
                    <LoadingContent/>
                ) : (
                    <Content title={(
                        <>
                            <div className="title-button">
                                <div className="title">
                                    <h1 className="app-content-h1-destaque">Meus planos</h1>
                                </div>
                                <div className="options">
                                    <Link to="/plans/plans">Alterar plano</Link>
                                </div>
                            </div>
                        </>
                    )}>
                        <div className="content-box">
                            <a className="contract-active" target="_blank" rel="noopener noreferrer"  href={`${APP_CONFIG.localhost}/public/contrato_e_termos_de_privacidade.pdf`}>*Termos de uso e contrato ativo. Clique aqui ver</a>
                            <h3 className="title-divider" style={{marginTop: 0}}>Lista de contratos</h3>
                            {
                                payments && payments.length > 0 ?
                                (
                                    payments.map((item) => 
                                        {
                                            let payment_status = '';

                                            switch (item.status_code) {
                                                case 'OPEN':
                                                    payment_status = 'Aberto'
                                                    break;
                                                case 'PAID':
                                                    payment_status = 'Ativado'
                                                    break;
                                                case 'CANCELED':
                                                    payment_status = 'Cancelado'
                                                    break;
                                                default:
                                                    break;
                                            }

                                            return (
                                                <>
                                                    <div className="proccess-item" onClick={() => {}}>
                                                        <div className="title-with-button-options">
                                                            <div className="title" onClick={() => {
                                                                history.push(`/plans/plan/${item.id}`)
                                                            }}>
                                                                <p><b>Plano: </b>{item.plan_name}</p> 
                                                                <p><b>Modalidade: </b>{item.method === 'credit_card' ? `Cartão de Credíto - Mensal` : `Boleto - Mensal`}</p>
                                                                <p><b>Valor: </b> {formatValue({value: item.plan_price, intlConfig: {locale: 'pt-BR', currency: 'BRL'}})}</p>
                                                                <br />
                                                                <p><b>Status pagamento: </b>({`${item.payment_status}`})</p>
                                                                <p><b>Status plano: </b>{`${item.status_code === "CANCELED" ? "Cancelado" : "Ativo"}`}</p>
                                                            </div>
                                                            <div className="options">
                                                                {
                                                                    item.status_code !== "CANCELED" ?
                                                                    (
                                                                        <button className="delete-button" onClick={() => {
                                                                            ConfirmDelete(() => {
                                                                                if(item.id)
                                                                                {
                                                                                    getMyPaymentsAPI.cancelPayment(item.id, response => Success(response.message, () => window.location.reload()), err => Warning(err.message))
                                                                                }
                                                                            }, () => null, 'Você realmente quer cancelar este plano? Caso tenha tempo em aberto ele será tera seu acesso liberado até lá.', 'Sim, cancele.')
                                                                        }}><MdDeleteForever/>Cancelar</button>
                                                                    ) : null
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        }
                                    )
                                ) : (
                                    <>
                                        <h3>Não existe nenhum contrato</h3>
                                    </>
                                )
                            }
                        </div>
                    </Content>
                )
            }
        </>
    )
}