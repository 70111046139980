import axios, { AxiosResponse } from "axios";
import Request from "../../functions/Request";
import { ISignSuccess } from "../Sign";

export interface RegisterFormProps {
    email: string
    company_name?: string
    responsible_name: string
    fantasy?: string
    cpf_cnpj?: string
    whatsapp_number?: string
    phone?: string
    password: string
    cpf?: string
    birthday?: string
    account_created?: boolean
    cep?: string
    street?: string
    city?: string
    number?: string
    state?: string
    district?: string
    is_consultor?: boolean
}

export interface RegisteredFormProps {
    cpf: string
    birthday: string
}

export interface PlansProps {
    id: string
    name: string
    limit_people: string
    limit_gb: string
    price_with_discount: string
    price: string
}

export interface CardProps {
    card_number: string
    validate_month: string
    validate_year: string
    card_name: string
    card_security: string
    card_validated?: boolean
    cvv_size?: number
}

const route = '/register'

class Register
{
    async validate(form: RegisterFormProps, success: ((response: AxiosResponse) => void), err: ((response: AxiosResponse) => void))
    {
        return await Request.post(`${route}/validate`, form).then(success).catch(err);
    }

    async register(form: RegisterFormProps, success: ((response: ISignSuccess) => void), err: ((response: AxiosResponse) => void))
    {
        return await Request.post(`${route}`, form).then(response => success(response.data)).catch(err);
    }

    async plans(success: ((response: Array<PlansProps>) => void), type_user?: string, promotion_user?: string)
    {
        if(type_user && promotion_user)
        {
            return await Request.get(`${route}/plans/${type_user}/${promotion_user}`).then(response => success(response.data.response));
        }

        if(type_user)
        {
            return await Request.get(`${route}/plans/${type_user}`).then(response => success(response.data.response));
        }

        return await Request.get(`${route}/plans`).then(response => success(response.data.response));
    }

    async cep(cep: string, success: ((response: AxiosResponse) => void), err: ((response: AxiosResponse) => void))
    {
        return await axios.get(`https://viacep.com.br/ws/${cep}/json/`).then(success).catch(err);
    }
}

export const RegisterAPI = new Register()