import React from 'react'
import { MdArrowBack } from 'react-icons/md'
import { Link } from 'react-router-dom'

export interface LinkBackHistoryProps {
    to: string,
}

export const LinkBackHistory: React.FC<LinkBackHistoryProps> = (props) => 
{
    return (
        <>
            <Link className="history-back" to={props.to}><MdArrowBack /></Link>
        </>
    )
}