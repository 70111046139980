import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/v2/client/projects'

export interface ProjectProps {
    name?: string
    client_id?: string
    id?: string
}


class Project 
{
    async create(
        data: ProjectProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        protocol_id: string,
        data: ProjectProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/${protocol_id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((success: Array<ProjectProps>) => void),
    )
    {
        return await Request.get(`${route}`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: ProjectProps) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }
}

export const ProjectAPI = new Project()