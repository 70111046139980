import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { useParams } from 'react-router'
import { LoadingContent } from '../../../../../components/LoadingContent'
import Success from '../../../../../functions/Modals/Success'
import { CollaboratorFormProps, PDCCollaborator_API } from '../../../../../services/ProccessFeedbackCustom/Collaborator'

export interface FormCollaboratorProps {
    id?: string,
    onSuccess?: (() => void)
}

export interface FormCollaboratorRouteParams {
    department: string
}

export const FormCollaborator: React.FC<FormCollaboratorProps> = (props) => 
{
    const [form, setForm] = useState<CollaboratorFormProps>({
        name: '',
        cargo: '',
        department_id: ''
    })

    const [loading, setLoading] = useState(true)


    const { department } = useParams<FormCollaboratorRouteParams>()
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        if(props.id)
        {
            PDCCollaborator_API.update(props.id, {...form, department_id: department}, s => Success('Salvo com sucesso!', async () => {
                window.location.reload()
            }))
        }else{
            PDCCollaborator_API.create({...form, department_id: department}, s => Success('Salvo com sucesso!', () => window.location.reload()))
        }
    }

    useEffect(() => {
        if(props.id)
        {
            PDCCollaborator_API.getById(props.id, data => {
                setForm(data)
                setLoading(false)
            })
        }
    }, [props.id])

    return (
        <>
            {
                !props.id || !loading ?
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p>Nome</p>
                            <input type="text" onChange={e => setForm({...form, name: e.target.value})} value={form.name} />
                        </div>
                        <div className="box-input">
                            <p>Cargo atual</p>
                            <input type="text" onChange={e => setForm({...form, cargo: e.target.value})} value={form.cargo} />
                        </div>
                        <button type="submit"><MdAdd/> Salvar</button>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}