import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import Select from 'react-select'
import { TextareaAutoheight } from '../../../../components/TextareaAutoheight'
import { ConfirmDelete } from '../../../../functions/Modals/Confirm'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../../services/ClientDepartments/ClientDepartments'
import { PlanActionAPI, PlanActionItemProps } from '../../../../services/Resultado/PlanAction/PlanAction'

export interface FormPlanActionProps {
    id?: string
    onChange?: ((type: 'UPDATE' | 'DELETE' | 'CREATE') => void)
}

export const FormPlanAction: React.FC<FormPlanActionProps> = (props) => 
{
    const [form, setForm] = useState<PlanActionItemProps>({})
    const [departments, setDepartments] = useState<FormClientDepartment[]>([])
    const [departmentSelected, setDepartmentSelected] = useState<any>({})

    useEffect(() => {
        if(props.id)
        {
            PlanActionAPI.getById(props.id, success => {
                setForm(success)

            ClientDepartmentsAPI.getAll(data => {
                    if(success.department_id)
                    {
                        let findIndex = data.findIndex(item => item.id === success.department_id)

                        if(findIndex)
                        {
                            setDepartmentSelected({
                                value: data[findIndex].id,
                                label: data[findIndex].name,
                            })
                        }
                    }

                    setDepartments(data)
                })
            })
        }else{
            ClientDepartmentsAPI.getAll(s => setDepartments(s))
        }
    }, [props.id])

    const onSave = () => {
        if(props.id)
        {
            PlanActionAPI.update(props.id, {
                ...form,
                department: departmentSelected && departmentSelected.value ? departmentSelected.value : null
            }, reponse => {
                if(props.onChange)
                {
                    props.onChange('UPDATE')
                }
            })
        }else{
            PlanActionAPI.create({
                ...form,
                department: departmentSelected && departmentSelected.value ? departmentSelected.value : null
            }, reponse => {
                if(props.onChange)
                {
                    props.onChange('CREATE')
                }
            })
        }
    }

    const onDelete = () => {
        if(props.id)
        {
            ConfirmDelete(() => {
                if(props.id)
                {
                    PlanActionAPI.delete(props.id, reponse => {
                        if(props.onChange)
                        {
                            props.onChange(`DELETE`)
                        }
                    })
                }
            }, () => {})
        }
    }

    return (
        <>
            <div className="input-box-v2" style={{marginTop: '40px'}}>
                <label htmlFor="!#">Departamento(s)</label>
                <Select 
                    value={departmentSelected} 
                    placeholder="Selecione" 
                    classNamePrefix="styled-v2" 
                    // closeMenuOnSelect={false}
                    onChange={(e: any) => setDepartmentSelected(e)} 
                    options={
                        departments.map(item => {
                            return {
                                value: item.id,
                                label: item.name
                            }
                        })
                    }
                />
            </div>
            <div className="input-box-v2 mg-top">
                <label htmlFor="">Problema Priorizado</label>
                <TextareaAutoheight value={form.problem ?? ''} onChange={event => setForm({...form, problem: event.target.value})} />
            </div>
            <div className="input-box-v2 mg-top">
                <label htmlFor="">O Que Fazer?</label>
                <TextareaAutoheight value={form.to_do ?? ''} onChange={event => setForm({...form, to_do: event.target.value})} />
            </div>
            <div className="input-box-v2 mg-top">
                <label htmlFor="">Como Fazer?</label>
                <TextareaAutoheight value={form.to_make ?? ''} onChange={event => setForm({...form, to_make: event.target.value})} />
            </div>
            <div className="input-box-v2 mg-top">
                <label htmlFor="">Quem?</label>
                <input type="text" value={form.to_who ?? ''} onChange={event => setForm({...form, to_who: event.target.value})} />
            </div>
            <div className="input-box-v2 mg-top">
                <label htmlFor="">Onde?</label>
                <input type="text" value={form.to_where ?? ''} onChange={event => setForm({...form, to_where: event.target.value})} />
            </div>
            <div className="input-box-v2 mg-top">
                <label htmlFor="">Por que Fazer?</label>
                <input type="text" value={form.to_why_do ?? ''} onChange={event => setForm({...form, to_why_do: event.target.value})} />
            </div>
            <div className="input-box-v2 mg-top">
                <label htmlFor="">Quanto? R$</label>
                <CurrencyInput intlConfig={{locale: 'pt-BR', currency: 'BRL'}} value={form.to_much ?? ''} onValueChange={value => setForm({...form, to_much: value ?? ''})} />
            </div>
            <div className="row" style={{marginTop: '20px'}}>
                <div className="col-12 col-lg-3">
                    <div className="input-box-v2">
                        <label htmlFor="">Início (Previsto)</label>
                        <input type="date" value={form.espected_start ?? ''} onChange={event => setForm({...form, espected_start: event.target.value})} />
                    </div>
                </div>
                <div className="col-12 col-lg-3">
                    <div className="input-box-v2">
                        <label htmlFor="">Fim (Previsto)</label>
                        <input type="date" value={form.expected_end ?? ''} onChange={event => setForm({...form, expected_end: event.target.value})} />
                    </div>
                </div>
                <div className="col-12 col-lg-3">
                    <div className="input-box-v2">
                        <label htmlFor="">Início (Realizado)</label>
                        <input type="date" value={form.real_start ?? ''} onChange={event => setForm({...form, real_start: event.target.value})} />
                    </div>
                </div>
                <div className="col-12 col-lg-3">
                    <div className="input-box-v2">
                        <label htmlFor="">Fim (Realizado)</label>
                        <input type="date" value={form.real_end ?? ''} onChange={event => setForm({...form, real_end: event.target.value})} />
                    </div>
                </div>
            </div>
            <div className="input-box-v2 mg-top">
                <label htmlFor="">Observações</label>
                <TextareaAutoheight value={form.observation ?? ''} onChange={event => setForm({...form, observation: event.target.value})} />
            </div>
            <br /><br />
            <div className="flex between">
                <button className="btn-styled success" onClick={onSave}>Salvar</button>
                {
                    props.id ? 
                    (
                        <button className="btn-styled danger" onClick={onDelete}>Apagar</button>
                    ) : null
                }
            </div>
        </>
    )
}