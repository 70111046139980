export const formatMoneyString = (value: any) => {
    if(typeof value !== "string")
    {
        value = value.toString()
    }
    
    if(value && value.search(',') >= 0)
    {
        value = value.replace('.', '')
        value = value.replace(',', '.')

        return value;    
    }
    
    return value
}