import React, { useState } from 'react'
import { Form } from '../../components/Form'
import { Button } from '../../components/Form/Button'
import { Input } from '../../components/Form/Input'
import { setSession, setUserData } from '../../functions/Session'
import { ISign, Sign } from '../../services/Sign'
import { RecoverPassword } from './components/RecoverPassword'
import Logo from "../../components/Navbar/assets/logo-preto-2.png"
import Warning from '../../functions/Modals/Warning'
import { Link } from 'react-router-dom'

export const SignIn = () => 
{
    const [data, setData] = useState<ISign>({
        email: '',
        password: ''
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => 
    {
        setData({
            ...data,
            [e.target.id]: e.target.value
        })
    }

    const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => 
    {
        e.preventDefault()

        Sign(data, user => {
            let status = setSession([
                {
                    label: 'X-Request-Token',
                    value: user.token
                },
                {
                    label: 'X-Request-Type',
                    value: user.type
                },
                {
                    label: 'X-User-Id',
                    value: user.id
                }
            ]);

            setUserData(user)

            if(status)
            { 
                window.location.href = '/'
            }
        }, e => Warning(e.data.message));
    }

    return (
        <div className="body-sign">
            <Form method="POST" className="form-sign" onSubmit={handleSubmit}>
                <div style={{textAlign: 'center', marginBottom: '2em'}}>
                    <img width="255px" src={Logo} alt=""/>
                </div>
                <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1rem'}}>
                    <p style={{fontWeight: 600}}>Preencha os seus dados para acessar ao Modum!</p>
                </div>
                <Input label="Seu e-mail" id="email" onChange={handleChange} placeholder="Digite seu e-mail"/>
                <Input label="Sua senha" type="password" id="password" onChange={handleChange} placeholder="Digite sua senha"/>
                <RecoverPassword to="/recover-password"/>
                <Button>Entrar no sistema</Button>
                <br />
                <Link className="register-consultor-link" to="/register">Comece a usar agora mesmo! Cadastre-se</Link>
                <div style={{textAlign: 'center', marginTop: '1.5rem'}} className="register-link">
                    <Link to="/register/consultor">Consultor? Clique e se cadastre-se agora mesmo.</Link>
                </div>
                <br />
            </Form>
        </div>
    )
}
