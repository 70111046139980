import axios from 'axios'
import Cookies from 'universal-cookie'
import { APP_CONFIG } from '../../constants';

const RequestWithoutLoading = axios.create({
    baseURL: APP_CONFIG.onServer ? APP_CONFIG.server : APP_CONFIG.localhost,
})  

const cookies = new Cookies()

RequestWithoutLoading.interceptors.request.use((config) => {
    if(APP_CONFIG.tokenHeaderName)
    {
        if(cookies.get(APP_CONFIG.tokenHeaderName))
        {
            config.headers[APP_CONFIG.tokenHeaderName] = cookies.get(APP_CONFIG.tokenHeaderName)
        }
    }

    return config
});


RequestWithoutLoading.interceptors.response.use(response => {
    return response;
}, error => {
    return Promise.reject(error.response)
});

export default RequestWithoutLoading;