import React, { useEffect, useState } from 'react'
import { StructureItemProps, auditoriaQuestions_api } from '../services/auditoriaQuestions.services'
import Success from '../../../../functions/Modals/Success'
import { toast } from 'react-toastify'

export interface SubitemFormProps {
    item: StructureItemProps,
    subItemId: string,
    aiId: string
    onRefresh: (() => void)
}

export const SubitemForm: React.FC<SubitemFormProps> = (props) => 
{
    const [item, setItem] = useState(props.item)
    
    const [form, setform] = useState({
        status: props.item.status ?? '',
        evi_obj: props.item.evi_obj ?? '',
        ai_id: props.aiId,
        id: props.subItemId
    })
    
    useEffect(() => {
      
    }, [props.item])
    

    return (
        <>
            <div>
                <div className='box-input' style={{display: 'flex', flexDirection: 'column', marginTop: '.5rem'}}>
                    <p><strong>{item.codigo}</strong> {item.description}</p>
                    <p>{item.obs ? (
                        <>
                            <strong>O que verificar?:</strong> <i>{item.obs}</i>
                        </>
                    ) : null}</p>
                    <select value={form.status} onChange={event => {
                        setform({
                            ...form,
                            status: event.target.value
                        })

                        form.status = event.target.value

                        auditoriaQuestions_api.save(props.subItemId, form, () => {
                            toast.success('Atualizado', { delay: 1  })
                            props.onRefresh()
                        })
                    }} style={{width: '100%', marginTop: '.5rem'}}>
                        <option value="">Selecione um opção</option>
                        <option value="1">Conforme</option>
                        <option value="2">Não conforme</option>
                        <option value="3">Oportunidade de melhoria</option>
                        <option value="4">Não aplicável</option>
                    </select>
                    <input value={form.evi_obj} onChange={event => {
                        setform({
                            ...form,
                            evi_obj: event.target.value
                        })

                        form.evi_obj = event.target.value

                        auditoriaQuestions_api.save(props.subItemId, form, () => {
                            toast.success('Atualizado', { delay: 1  })
                            props.onRefresh()
                        })
                    }} style={{width: '100%'}} type="text" name="" id="" />
                </div>
            </div>
        </>
    )
}