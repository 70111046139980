import React, { useEffect, useState } from 'react'
import { FaFile } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { ConfigModal, Modal } from '../../../components/Modal'
import { Navbar } from '../../../components/Navbar'
import { FilesAPI, FilesItemProps } from '../../../services/MyAttachments/Files'
import { CreateFile } from './components/CreateFIle'
import { FileItem } from './components/FileItem'

export interface FilesProps {}
export interface FilesRouteProps { folder: string }

export const Files: React.FC<FilesProps> = (props) => 
{
    const [files, setFiles] = useState<Array<FilesItemProps>>([])
    
    const [modalConfig, setModalConfig] = useState<ConfigModal>({
        show: false
    })

    const { folder } = useParams<FilesRouteProps>()

    useEffect(() => {
        FilesAPI.getAll(setFiles, folder)
    }, [folder])

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content title={
                <div className="title-button">
                    <div className="title">
                        <LinkBackHistory to="/my-attachments" />
                        <h1 className="app-content-h1-destaque">Meus arquivos</h1>
                    </div>
                    <div className="options">
                        <button className="bkg-theme clr-white" onClick={() => {
                            setModalConfig({
                                show: true,
                                title: (
                                    <>
                                        <h2>Criar arquivo</h2>
                                    </>
                                ),
                                content: <CreateFile id={folder} onChange={() => {
                                    setModalConfig({show: false})
                                }} />,
                                onClose: () => setModalConfig({show: false})
                            })
                        }}><FaFile/></button>
                    </div>
                </div>
            }>
                <div className="content-box">
                    <h3 className="title-divider" style={{marginTop: '0px'}}>Arquivos</h3>
                    {
                        files.map((item, index) => {
                            return <FileItem file={item} /> 
                        })
                    }
                </div>
            </Content>
        </>
    )
}