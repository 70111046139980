import React, { useEffect, useState } from 'react'
import { ConfigModal, Modal } from '../../../components/Modal'
import { TaskProps } from '../../../services/Calendar/ConsultarTask'
import { TaskFinishReverse } from '../../../services/Calendar/ConsultTasksFinish'
import { UpdateProtocol } from '../../Protocol/Components/UpdateProtocol'

export interface ConfigTaskHistory {
    taskProps: TaskProps
}

export const TaskHistory: React.FC<ConfigTaskHistory> = (props) => 
{
    const [confModal, setConfModal] = useState<ConfigModal>({show: false})
    const { taskProps } = props;

    useEffect(() => {
        
    }, [props.taskProps])

    return (
        <>
            <Modal {...confModal}/>
            <div className="task-details">
                <p className="title-box">Titulo</p>
                <p>{taskProps.label}</p>
                <p className="title-box">Participantes</p>
                {
                    taskProps.contacts.map(e => {
                        return <p>{e.name} #{e.email}</p>
                    })
                }
                <p className="title-box">Departamento</p>
                {
                    taskProps.departments.map(e => {
                        return <p>{e.label}</p>
                    })
                }
                <p className="title-box">Etiqueta</p>
                <p>{taskProps.tags ? (
                    taskProps.tags.map((tag, index) => {
                        return <span style={{color: tag.color}}>{tag.label}</span>
                    })
                ) : null}</p>
                {
                    taskProps.label !== 'Reunião diária' && taskProps.protocol_id !== "" ? 
                    (
                        <>
                            <div className="title-box with-options">
                                <span>Pauta</span>
                                <div className="options">
                                    {
                                        <div className="option" onClick={() => {
                                            setConfModal({
                                                show: true,
                                                content: <UpdateProtocol id={taskProps.protocol_id} />,
                                                onClose: () => setConfModal({
                                                    show: false
                                                }),
                                                title: <h3 className="title-divider">Atualizar Atas</h3>
                                            })
                                        }}>Vizualizar</div>
                                    }
                                </div>
                            </div>
                        </>
                    ) : null
                }
                <p className="title-box">Checklist</p>
                {
                    taskProps.subjects.map(e => {
                        return (
                            <div className="task-item-finish">
                                <p className="label">Título: {e.title}</p>
                                <p className="feedback">Feedback: {e.feedback}</p>
                                <p className="date_finish">Status: {e.status === "1" ? "Aberto" : "Concluido"}</p>
                            </div>
                        )
                    })
                }
                <button style={{marginLeft: '0px'}} className="btn-link-solid" onClick={() => {
                    TaskFinishReverse(taskProps.id, () => window.location.reload(), err => {})
                }}>
                    Reverter
                </button>
            </div>
        </>
    )
}
