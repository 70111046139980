import Cookies from 'universal-cookie'

const cookies = new Cookies()

export interface ISession {
    label: string, 
    value?: string, 
    path?: string | '/'
}

export interface userData {
    company_id: string
    email: string
    id: string
    message: string
    name: string
    token: string
    type: string
}

const setSession = (session: Array<ISession>) => 
{
    session.map(e => {
        cookies.set(e.label, e.value, {
            path: e.path ?? '/'
        })

        return e;
    })

    return true;
}

const setUserData = (data: userData) => {
    cookies.set('user', JSON.stringify(data))
}

// { email: string
//     name: string
//     id: string
//     company_id: string
//     token: string
//     type: string
//     payment_status: boolean
//     message: string
// }

const getUserData = () => {
    return JSON.parse(cookies.get('user'))
}

const checkUserData = () => {
    return cookies.get('user')
}

const removeUserData = () => {
    return cookies.remove('user')
}

const userId = () => {
    return cookies.get('X-User-Id')
}

const getUserToken = () => {
    return cookies.get('X-Request-Token')
}

const closeSession = (session: Array<ISession>) => 
{
    session.map(e => {
        cookies.remove(e.label, {
            path: '/'
        })

        return e;
    })
}

const getSession = (session: Array<ISession>) => 
{
    let status_session = true;

    session.map(e => {
        if(!cookies.get(e.label))
        {
            status_session = false;
        }

        return e;
    })

    return status_session;
}

export {
    setSession,
    closeSession,
    getSession,
    userId,
    setUserData,
    getUserData,
    removeUserData,
    checkUserData,
    cookies,
    getUserToken
}