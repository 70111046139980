import React, { useEffect, useState } from 'react'
import Error from '../../../functions/Modals/Error';
import { ConsultMyTags, ConsultMyTagsItem } from '../../../services/Tags/ConsultMyTags';
import { DeleteMyTag } from '../../../services/Tags/DeleteMyTag';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

export interface ListTagsProps {
    onBack: (() => void)
}

export const ListTags: React.FC<ListTagsProps> = (props) => {

    useEffect(() => {
        ConsultMyTags(s => setListTags(s.tags), e => {})
    }, [])

    const [listTags, setListTags] = useState<Array<ConsultMyTagsItem>>([])
    
    return (
        <>
            <h3 className="title-divider">Etiquetas</h3>
            {
                listTags.map((e, i) => (
                    <div key={`${i}`} style={{background: `${e.color}`}} className="tag-box">
                        <div className="title"><p className="tag-title">{e.label}</p></div>
                        <div className="options">
                            <button className="btn-link" onClick={b => {
                                b.preventDefault()

                                DeleteMyTag(e.id, () =>  {
                                    toast.success('Etiqueta removida!')
                                    setListTags([...listTags.filter(tag => tag.id !== e.id)])
                                }, () => Error('Houve um erro ao apagar essa etiqueta.'))
                            }}>Apagar</button>
                        </div>
                    </div>
                ))
            }
            <div className="options-button">
                <button onClick={() => props.onBack()} type="button" className="input-box-button">
                    Voltar
                </button>
            </div>
            <ToastContainer pauseOnFocusLoss={false}  position="bottom-right" />
        </>
    )
}
