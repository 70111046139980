import React, { useEffect, useState } from 'react'
import { FaBolt, FaPlus } from 'react-icons/fa'
import { formatDate } from '../../../functions/dataFormat'
import { ConfirmDelete } from '../../../functions/Modals/Confirm'
import Success from '../../../functions/Modals/Success'
import { TimesheetAPI, TimesheetFormProps } from '../../../services/V2/Timesheet'

export interface TimesheetManualProps {
    calendar_id: string,
    fast_timesheet?: {
        date_default_to: string,
        date_default_from: string
    }
}

export const TimesheetManual: React.FC<TimesheetManualProps> = (props) => 
{
    const [timesheets, setTimesheets] = useState<TimesheetFormProps[]>([])

    const [dateTime, setDateTime] = useState({
        time_from: '',
        time_to: ''
    })

    const addNewItem = () => {
        if(dateTime.time_from && dateTime.time_to)
        {
            let times = timesheets;
            
            times.push(dateTime)

            setTimesheets([...times])

            setDateTime({
                time_from: '',
                time_to: ''
            })

            TimesheetAPI.saveTimesheet(props.calendar_id, dateTime, () => {
                Success('Salvo com succeso', () => {
                    TimesheetAPI.getByTaskId(props.calendar_id, setTimesheets)
                })
            })
        }
    }

    useEffect(() => {
        TimesheetAPI.getByTaskId(props.calendar_id, setTimesheets)
    }, [])

    return (
        <>
           <div className="title-toolkit">
                <div className="title">
                    <span>Timesheet?</span>
                </div>
                <div className="toolkit">
                    {
                        props.fast_timesheet ? (
                            <>
                                <span title="Usar horário da tarefa como timesheet" className="toolkit-button default" onClick={() => {
                                    ConfirmDelete(() => {
                                        TimesheetAPI.saveTimesheet(props.calendar_id, {
                                            time_to: props.fast_timesheet?.date_default_to,
                                            time_from: props.fast_timesheet?.date_default_from,
                                        }, () => {
                                            Success('Salvo com succeso', () => {
                                                TimesheetAPI.getByTaskId(props.calendar_id, setTimesheets)
                                            })
                                        })
                                    }, () => {}, 'Sim, configurar', 'Usar horário da tarefa como timesheet?')
                                }}><FaBolt /> <span className="title">Horário da tarefa</span></span>
                            </>
                        ) : null
                    }
                </div>
            </div>
            <div className="timesheet-entries">
                <div className="timesheet-new-entry" style={{marginBottom: '5px'}}>
                    <div className="entry-start">
                        <input type="datetime-local" value={dateTime.time_from} onChange={event => setDateTime({...dateTime, time_from: event.target.value})} />
                    </div>
                    <div className="entry-end">
                        <input type="datetime-local" value={dateTime.time_to} onChange={event => setDateTime({...dateTime, time_to: event.target.value})} />
                    </div>
                    <div className="entry-send" onClick={() => addNewItem()}>
                        <FaPlus/>
                    </div>
                </div>
                <>
                    {
                        timesheets.map((date, index) => {
                            return (
                                <>
                                    <div className="timesheet-item-box">
                                        <span className="time-from">
                                            {formatDate(date.time_from?.toString(), 'dd/MM/yyyy HH:mm')}
                                        </span>
                                        <span className="time-to">
                                            {formatDate(date.time_to?.toString(), 'dd/MM/yyyy HH:mm') ?? 'Sem entradas ainda'}
                                        </span>
                                        <span className="remove" onClick={() => {
                                            setTimesheets([
                                                ...timesheets.filter((item, f_index) => index !== f_index)
                                            ])

                                            if(date.id)
                                            {
                                                TimesheetAPI.deleteTimesheet(date.id, () => {
                                                    Success('Salvo com succeso', () => {
                                                        TimesheetAPI.getByTaskId(props.calendar_id, setTimesheets)
                                                    })
                                                })
                                            }
                                        }}>Remover</span>
                                    </div>
                                </>
                            )
                        })
                    }
                </>
            </div>
        </>
    )
}