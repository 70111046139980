import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/finance/dre'

export interface DREDataSubtopicProps {
    id: string
    name: string
    topic_id: string
    client_id: string
    year: DREYearDataExtends[]
}

export interface DREDataTopicProps {
    id: string
    name: string
    type: string
    client_id: string
    subtopic_id?: string
    show_percentage?: boolean
    year: DREYearDataExtends[]
    subtopics?: DREDataSubtopicProps[]
}

export interface DREYearData {
    "01": number,
    "02": number,
    "03": number,
    "04": number,
    "05": number,
    "06": number,
    "07": number,
    "08": number,
    "09": number,
    "10": number,
    "11": number,
    "12": number
}

export interface DREYearDataExtends {
    preview: string,
    realized: string,
    preview_percentage?: number
    realized_percentage?: number
}

export interface DREDataProps {
    id: string
    name: string
    type: string
    group_id: string
    sequence: string
    label_response?: string
    topics: Array<DREDataTopicProps>,
    year_result?: DREYearDataExtends[],
    year_result_final?: DREYearDataExtends[],
    total?: {
        preview: string
        media_preview: string
    }
    total_group?: {
        preview: string
        media_preview: string
    }
}

export interface DREDataPropsExtends {
    id: string
    name: string
    type: string
    group_id: string
    sequence: string
    label_response?: string
    base_calc?: string
    topics: Array<DREDataTopicProps>,
    year_result?: DREYearDataExtends[],
    year_result_final?: DREYearDataExtends[],
    show_percentage?: boolean
    total?: {
        preview: string
        realized: string
        media_preview: string
        media_realized: string
    }
    total_group?: {
        preview: string
        realized: string
        media_preview: string
        media_realized: string
    }
}

export interface DREConsolidadeItemProps {
    id: string;
    name: string;
    label_response?: any;
    type: string;
    group_id?: any;
    sequence: string;
    base_calc?: any;
    show_percentage?: boolean
    topics: Topic[];
    total_group: Totalgroup;
    project_result: Project[];
    projects_result_final: Project[];
    total: Totalgroup;
  }
  
interface Totalgroup {
    preview: number;
    realized: number;
    media_preview: number;
    media_realized: number;
}

export interface Topic {
    id: string;
    name: string;
    type: string;
    client_id: string;
    group_id: string;
    projects: Project[];
    show_percentage?: boolean
}

interface Project {
    id: string;
    company_id: string;
    name: string;
    entries: Entries;
}

interface Entries {
    preview: number;
    realized: number;
    preview_percentage?: number
    realized_percentage?: number
}


class DRE 
{
    async update(
        data: DREDataProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        year: string,
        project_id: string,
        onSuccess: ((success: Array<DREDataPropsExtends>) => void),
    )
    {
        return await Request.get(`${route}/${project_id}?year=${year}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }

    async getAllConsolidada(
        year: string,
        onSuccess: ((success: Array<DREConsolidadeItemProps>) => void),
    )
    {
        return await Request.get(`${route}/dre/consolidado?year=${year}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: DREDataProps) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }
}

export const DREAPI = new DRE()