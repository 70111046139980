import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Content } from '../../../../components/Content'
import { HomeButton } from '../../../../components/HomeButton'
import { LinkBackHistory } from '../../../../components/LinkBackHistory'
import { ConfigModal } from '../../../../components/Modal'
import { ModalRight } from '../../../../components/ModalRight'
import { Navbar } from '../../../../components/Navbar'
import { formatDate } from '../../../../functions/dataFormat'
import { ProtocolItemPropsV2, ProtocolV2API } from '../../../../services/ProtocolV2/ProtocolV2'
import { UpdateProtocol } from '../../Components/UpdateProtocol'

export interface ArchivedProps {}
export interface ArchivedRouteProps {
    folder_id?: string
}

export const Archived: React.FC<ArchivedProps> = () => 
{
    const [confModal, setConfModal] = useState<ConfigModal>({show: false})
    const [listProtocols, setListProtocols] = useState<Array<ProtocolItemPropsV2>>([])

    const { folder_id } = useParams<ArchivedRouteProps>()
    const loading = useRef(true)

    useEffect(() => {
        ProtocolV2API.consultarArchiveds(setListProtocols)

        loading.current = false
    },[folder_id])
    
    return (
        <>
            <ModalRight {...confModal} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            {
                                folder_id ? <LinkBackHistory to="/protocols"/> :  <HomeButton />
                            }
                            <h1 className="app-content-h1-destaque">Minhas ATAS</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="body-content" style={{marginTop: '20px'}}>
                    <div className="title">
                        <h2>Atas arquivadas</h2>
                    </div>
                    {
                        listProtocols.length >= 0 ?
                        (
                            listProtocols.map((e, i) => { 
                                return (
                                    <div key={`${i}`} className="box-button with-options" style={{marginBottom: "10px"}} onClick={() => {
                                        if(e.id)
                                        {
                                            setConfModal({
                                                show: true,
                                                content: <UpdateProtocol change_archived={true} id={e.id} />,
                                                onClose: () => setConfModal({show: false})
                                            })
                                        }
                                    }}>
                                        <p className="title">- {e.name}</p>
                                        <p className="date">{e.user_created_name ? `Criado por ${e.user_created_name}` : null} {e.date ? `em ${formatDate(e.date)}` : null} </p>
                                    </div>
                                )
                            })
                        ) :
                        (
                            <h3>Sem informações para serem mostradas</h3>
                        )
                    }
                </div>
            </Content>
        </>
    )
}
