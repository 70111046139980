import React, { useEffect, useState } from 'react'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { Navbar } from '../../../components/Navbar'
import { DashboardGraficItemProps, IndicatorsAPI } from '../../../services/Resultado/Indicators/Indicators'
import GaugeChart from 'react-gauge-chart'
import { formatValue } from 'react-currency-input-field'

export interface DashboardMetaProps {
    year: string
}
export interface DashboardMetaRouteProps {
    department_id: string
}

export const DashboardMeta: React.FC<DashboardMetaProps> = (props) => 
{
    const [list, setList] = useState<Array<DashboardGraficItemProps>>([])
    const { department_id } = useParams<DashboardMetaRouteProps>()
    const [year, setYear] = useState(new Date().getFullYear())

    useEffect(() => {
        IndicatorsAPI.mountGraphicDashboard(year.toString(), department_id, setList)
    }, [year])

    return (
        <>
            <Navbar/>
            <Content title={
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to={`/indicadores`}/>
                            <h1 className="app-content-h1-destaque">Dashboard Gráfico - ({year})</h1>
                        </div>
                        <div className="options">
                        <button className="bkg-theme clr-white" title="Voltar" onClick={() => {
                                setYear(year-1)
                            }}><FaArrowLeft/>Ano anterior</button>
                            <button className="bkg-theme clr-white" title="Avançar" onClick={() => {
                                setYear(year+1)
                            }}>Próximo ano <FaArrowRight/></button>
                        </div>
                    </div>
                </>
            }>
                <div className="content-box">
                    {
                        list.map((item: any, index) => {
                            console.log("# ------ Nova lista ------ #")
                            return (
                                <React.Fragment key={index}>
                                    <h2 className="title-divider" style={{marginTop: '0px'}}>Gráfico: {item.title}</h2>
                                    <div style={{padding: '30px', overflow: 'auto', display: 'flex', paddingBottom: '20px'}}>
                                        {
                                            item.graphic.map((subitem: any, subIndex: number) => {

                                                let meta = Number(subitem['Meta'])
                                                let total = Number(subitem['Ano atual'])
                                                let result =  total / meta * 100 / 100

                                                console.log(`Antes => ${result}`)
                                                if(item.polarity === "menor")
                                                {
                                                    result = ((total / meta) * 100)

                                                    if(result === 0)
                                                    {
                                                        result = 1
                                                    }else{
                                                        result = (100 - result) / 100
                                                    }
                                                }

                                                let meta_label = ''
                                                let total_label = ''
                                                
                                                if(item.unit === "porcentagem")
                                                {
                                                    meta_label = `${meta}%`
                                                    total_label = `${total}%`

                                                }else if(item.unit === "numero")
                                                {
                                                    meta_label = meta.toString()
                                                    total_label = total.toString()

                                                }else{
                                                    meta_label = formatValue({value: meta.toString(), intlConfig: { locale: 'pt-BR',  currency: 'BRL' }})
                                                    total_label = formatValue({value: total.toString(), intlConfig: { locale: 'pt-BR',  currency: 'BRL' }})
                                                }

                                                return (
                                                    <div key={subIndex} className="chart-item-speed">
                                                        <p>{subIndex === 12 ? "Anual" : subIndex === 13 ? "Média anual" :  subitem['month']}</p>
                                                        <GaugeChart id="gauge-chart4" 
                                                            nrOfLevels={10} 
                                                            arcPadding={0.10} 
                                                            textColor="#333"
                                                            colors={['#D82148', '#FFBD35', '#95CD41']}
                                                            cornerRadius={3} 
                                                            percent={isNaN(result) ? 0 : result} 
                                                            style={{width: '300px'}}
                                                        />
                                                        <p className="meta-title">Meta / Total</p>
                                                        <p className="meta-value">{meta_label} / {total_label}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    <br />
                                </React.Fragment>
                            )
                        })
                    }
                </div>
            </Content>
        </>
    )
}