import React, { useEffect, useRef, useState } from 'react'
import { FaRegFile } from 'react-icons/fa'
import { Content } from '../../components/Content'
import { Navbar } from '../../components/Navbar'
import { useParams } from 'react-router-dom'
import { AttachmentController, AttachmentFileProps } from '../../services/Attachment/AttachmentController'
import { APP_CONFIG } from '../../constants'
import { LinkBackHistory } from '../../components/LinkBackHistory'

export const AttachmentFiles = () => 
{
    const [files, setFiles] = useState<Array<AttachmentFileProps>>([])
    const loading = useRef(true)
    const { folder } = useParams<{folder: string}>()

    useEffect(() => {
        AttachmentController.getFiles(folder, setFiles)

        loading.current = false
    }, [folder])

    return (
        <>
            <Navbar/>
            <Content loading={loading.current} title={
                <div className="title-button">
                    <div className="title">
                        <LinkBackHistory to="/attachment-files" />
                        <h1 className="app-content-h1-destaque">Arquivos</h1>
                    </div>
                </div>
            }>
                <div className="content-box">
                    {
                        files.map(e => {
                            return (
                                <div className="box-module" onClick={() => {
                                    window.open(`${APP_CONFIG.localhost}/attachments_platform/${e.title}`, '_blank');
                                }}>
                                    <p className="title"><FaRegFile/> {e.title}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </Content>   
        </>
    )
}
