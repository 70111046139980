import React, { useEffect, useRef, useState } from 'react'
import { MdDeleteForever, MdLink, MdModeEdit } from 'react-icons/md'
import { Content } from '../../../components/Content'
import { ConfigModal, ModalRight } from '../../../components/ModalRight'
import { Navbar } from '../../../components/Navbar'
import { ConfirmDelete } from '../../../functions/Modals/ConfirmDelete'
import { OnboardingAPI, OnboardingItemProps } from '../../../services/Onboarding/Onboarding'
import { FormOnboarding } from './components/FormOnboarding'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { APP_CONFIG } from '../../../constants'
import { checkUserData } from '../../../functions/Session'

export interface OnboardingProps {}

export const Onboarding: React.FC<OnboardingProps> = (props) => 
{
    const [list, setList] = useState<Array<OnboardingItemProps>>([])
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const loading = useRef(true)

    useEffect(() => {
        OnboardingAPI.consult(setList)

        loading.current = false
    }, [])

    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
          return;
        }
    
        let items: any = reorder(
            list,
            result.source.index,
            result.destination.index
        );
    
        setList([...items]);

        OnboardingAPI.reorder({list: items}, () => {})
      }

    return (
        <>
            <ModalRight {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={
                <div className="title-button">
                    <div className="title">
                        <LinkBackHistory to="/" />
                        <h1 className="app-content-h1-destaque">Onboarding</h1>
                    </div>
                    <div className="options">
                        <button onClick={() => {
                            setModalConfig({
                                show: true,
                                content: <FormOnboarding />,
                                onClose: () => setModalConfig({
                                    show: false
                                }),
                                title: <h2>Novo item</h2>
                            })
                        }}>Novo item</button>
                        <a href={`${APP_CONFIG.localhost}/client/onboarding/pdf/${checkUserData().company_id}`} target="__blank">Gerar PDF</a>
                    </div>
                </div>
            }>
                <div className="content-box">
                    {
                        list.length > 0 ? 
                        (
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    >
                                    {list.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div className="proccess-item" ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                            onClick={() => {}}>
                                                        <div className="title-with-button-options">
                                                            <div className="title">
                                                                <p><b>{item.topic}</b></p>
                                                                <br/>
                                                                <div dangerouslySetInnerHTML={{__html: item.description ?? item.suggestions_explanations}}></div>
                                                            </div>
                                                            <div className="options">
                                                                {
                                                                    item.link_ref ?
                                                                    (
                                                                        <button className="update-button" onClick={() => {
                                                                            window.open(`${item.link_ref}`, '_blank');
                                                                        }}><MdLink/></button>
                                                                    ) : null
                                                                }
                                                                
                                                                <button className="update-button" onClick={() => {
                                                                    setModalConfig({
                                                                        show: true,
                                                                        content: <FormOnboarding id={item.id} onSuccess={() => {
                                                                            OnboardingAPI.consult(setList)

                                                                            setModalConfig({
                                                                                show: false
                                                                            })
                                                                        }} />,
                                                                        onClose: () => setModalConfig({
                                                                            show: false
                                                                        }),
                                                                        title: <h2>Atualizar</h2>
                                                                    })
                                                                }}><MdModeEdit/></button>
                                                                <button className="delete-button" onClick={() => {
                                                                    ConfirmDelete(() => {
                                                                        if(item.id)
                                                                        {
                                                                            OnboardingAPI.delete(item.id, () => {
                                                                                setList([...list.filter(i => i.id !== item.id)])
                                                                            })
                                                                        }
                                                                    }, () => null)
                                                                }}><MdDeleteForever/></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                        )
                                            }
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>
                            </DragDropContext>
                        ) : (
                            <>
                                <h3>Crie agora mesmo seu Onboarding, clique em *Novo item</h3>
                            </>
                        )
                    }
                </div>
            </Content>  
        </>
    )
}