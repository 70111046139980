import React, { useEffect, useState } from 'react'
import { ConfigModal, ModalRight } from '../../../components/ModalRight'
import { ControllerCustomerJourneyStage, CustomerJourneyStageItem } from '../../../services/Proccess/ControllerCustomerJourneyStage'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { FormCustomerJourneyStage } from './components/FormCustomerJourneyStage'
import { Navbar } from '../../../components/Navbar'
import { FormPOP } from './components/FormPOP'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useRef } from 'react'

export interface CustomerJourneyStageProps {}

export const CustomerJourneyStage: React.FC<CustomerJourneyStageProps> = () => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [listCustomerJourneyStages, setListCustomerJourneyStages] = useState<Array<CustomerJourneyStageItem>>([])
    const loading = useRef(true)

    useEffect(() => {
        ControllerCustomerJourneyStage.getAll(setListCustomerJourneyStages)
        loading.current = false
    }, [])

    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
          return;
        }
    
        let items: any = reorder(
            listCustomerJourneyStages,
            result.source.index,
            result.destination.index
        );
    
        setListCustomerJourneyStages([...items]);

        ControllerCustomerJourneyStage.reorder({list: items}, () => {})
    }

    return (
        <>
            <ModalRight {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/" />
                            <h1 className="app-content-h1-destaque">Processos: Jornada do Cliente</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2>Etapas</h2>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    content: <FormCustomerJourneyStage onSuccess={() => ControllerCustomerJourneyStage.getAll(setListCustomerJourneyStages)} />,
                                    onClose: () => setModalConfig({
                                        show: false
                                    }),
                                    title: <h2>Criar etapas</h2>
                                })
                            }}>Criar etapas</button>
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    content: <FormPOP />,
                                    onClose: () => setModalConfig({
                                        show: false
                                    }),
                                    title: <h2>Criar Procedimento Operacional Padrão</h2>
                                })
                            }}>Criar Procedimento Operacional Padrão</button>
                        </div>
                    </div>
                    <br/>
                    {
                        listCustomerJourneyStages.length > 0 ?
                        (
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    >
                                    {listCustomerJourneyStages.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {
                                            (provided, snapshot) => 
                                                (
                                                    <div className="stage-item" ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                      onClick={() => {
                                                        setModalConfig({
                                                            show: true,
                                                            content: <FormCustomerJourneyStage id={item.id} onSuccess={() => {
                                                                ControllerCustomerJourneyStage.getAll(setListCustomerJourneyStages)
                                                                setModalConfig({show: false})
                                                            }} />,
                                                            onClose: () => setModalConfig({
                                                                show: false
                                                            }),
                                                            title: <h2>Atualizar etapa</h2>
                                                        })
                                                    }}>
                                                        <p className="p-item"><span>O que fazer?:</span> {item.description}</p>
                                                    </div>
                                                )
                                        }
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>
                            </DragDropContext>
                        ) : null
                    }
                </div>
            </Content>
        </>
    )
}