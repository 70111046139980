import React, { useEffect, useState } from 'react'
import { Draggable, DropResult } from 'react-beautiful-dnd'
import { formatDate } from '../../../functions/dataFormat'
import Success from '../../../functions/Modals/Success'
import { ListItemProps } from '../../../services/ScrumCompany/ScrumCompanyLists'

export interface ScrumTaskProps extends ListItemProps {
  onCreate?: (() => void)
  onUpdate?: (() => void)
  onDelete?: (() => void)
  onClick?: ((task: ListItemProps) => void)
  onMove?: ((task: ListItemProps) => void)
  task_id: string
  index: number
}

export const ScrumTask: React.FC<ScrumTaskProps> = (props) => 
{
    // const [{}, drag] = useDrag(() => ({
    //     type: 'ScrumTask',
    //     item: { props },
    //     end: (item: any, monitor) => {
    //       const dropResult = monitor.getDropResult<{
    //         tab_id: string
    //       }>()
    //       if (item && dropResult) {
    //         if(props.id)
    //         {
    //           ScrumListAPI.moveTab(props.id, {
    //             tab: dropResult.tab_id
    //           }, () => {
    //             if(props.onMove)
    //             {
    //               props.onMove(props)
    //             }
    //           })
    //         }
    //       }
    //     },
    //     collect: (monitor) => ({
    //       isDragging: monitor.isDragging(),
    //       handlerId: monitor.getHandlerId(),
    //     }),
    //   }))

    useEffect(() => {

    }, [])

    return (
        <Draggable 
            draggableId={props.task_id} 
            index={props.index}
            key={props.task_id}
        >
            {(provided, snapshot) => (
                <div
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                >
                    <div className='scrum-task'>
                        <div className="flex" style={{flexWrap: 'wrap'}} >
                            {
                                props.tags && props.tags.length ? 
                                (
                                    <>
                                        {
                                            props.tags.map((tag, item) => {
                                                return (
                                                    <div className="tag-task" style={{background: `${tag.tag?.color}`}}>
                                                        {tag.tag?.label}
                                                    </div>
                                                )
                                            })
                                        }
                                    </>
                                ) : null
                            } 
                            {
                                props.departments && props.departments.length ? (
                                    props.departments.map(department => {
                                        return (
                                            <div className="tag-task bkg-blue-dark-light">{department.department?.name}</div>
                                        )
                                    })
                                ) : null
                            }
                        </div>
                        <p className="title" onClick={() => {
                            if(props.onClick)
                            {
                                props.onClick(props)
                            }
                        }}>{props.title}</p>
                        {props.date_finish ? <p className="date">{formatDate(props.date_finish, 'dd/MM/yyyy HH:mm')}</p>: null}
                    </div>
                
                </div>
            )}
        </Draggable>
        
    )
}