import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field'
import { FaArrowLeft, FaArrowRight, FaFileExport } from 'react-icons/fa'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { ConfigModal, Modal } from '../../../components/Modal'
import { Navbar } from '../../../components/Navbar'
import { NoWithContent } from '../../../components/NoWithContent'
import { APP_CONFIG } from '../../../constants'
import { calculatemeta } from '../../../functions/calculateMeta'
import { cookies } from '../../../functions/Session'
import { DREAPI, DREDataPropsExtends } from '../../../services/Financeiro/DRE'
import { ProjectAPI, ProjectProps } from '../../../services/V2/Project'
import { DREItem } from './components/DREItem'

export interface DREProps {}

export const DRE: React.FC<DREProps> = (props) => 
{
    const [modalConfig] = useState<ConfigModal>({show: false})
    const [topics, setTopics] = useState<Array<DREDataPropsExtends>>([])

    const [projectSelected, setProjectSelected] = useState<string | undefined>('')
    const [projects, setProjects] = useState<ProjectProps[]>([])

    const [year, setYear] = useState(new Date().getFullYear())
    
    const loading = useRef(true)

    useEffect(() => {
        ProjectAPI.getAll(setProjects)
        loading.current = false
    }, [])

    const onScrollCategoryItem = (event: any) => {
        let category_item = event.target;

        category_item.addEventListener('scroll', function() {

            let all_category_items = document.querySelectorAll('.category-item')

            if(all_category_items.length)
            {
                for (let index = 0; index < all_category_items.length; index++) {
                    const element = all_category_items[index];

                    element.scrollTo(category_item.scrollLeft, 0)
                }
            }
          
        });
    }

    const renderMonth = (index: number) => {
        switch (index) {
            case 0:
                return 'JANEIRO'
            case 1:
                return 'FEVEREIRO'
            case 2:
                return 'MARÇO'
            case 3:
                return 'ABRIL'
            case 4:
                return 'MAIO'
            case 5:
                return 'JUNHO'
            case 6:
                return 'JULHO'
            case 7:
                return 'AGOSTO'
            case 8:
                return 'SETEMBRO'
            case 9:
                return 'OUTUBRO'
            case 10:
                return 'NOVEMBRO'
            case 11:
                return 'DEZEMBRO'
        }
    }

    const inputsConfigs : CurrencyInputProps = {
        decimalsLimit: 2,
        decimalSeparator: ",",
        decimalScale: 2,
        allowNegativeValue: false,
        intlConfig: {
            locale: 'pt-BR', 
            currency: 'BRL'
        }
    }

    const renderComponent_year_result = (index: number, topic: DREDataPropsExtends) => {
        return (
            <div className="topic-detail">
                <p>TOTAL {renderMonth(index)} <span className="percentage">{calculatemeta(topic.year_result ? parseFloat(topic.year_result[index].preview) : 0, topic.year_result ? parseFloat(topic.year_result[index].realized) : 0)}%</span></p>
                <div className="values">
                    <div className="preview">
                        <p>Previsto</p>
                        <CurrencyInput {...inputsConfigs} value={topic.year_result ? topic.year_result[index].preview : 0} />
                        {
                            topic.show_percentage === true ? (
                                <div className="percentage">{topic.year_result && topic.year_result[index].preview_percentage ? `${topic.year_result[index].preview_percentage}%` : "0 %"} </div>
                            ) : null
                        }
                    </div>
                    <div className="preview">
                        <p>Realizado</p>
                        <CurrencyInput {...inputsConfigs} value={topic.year_result ? topic.year_result[index].realized : 0} />
                        {
                            topic.show_percentage === true ? (
                                <div className="percentage">{topic.year_result && topic.year_result[index].realized_percentage ? `${topic.year_result[index].realized_percentage}%` : "0 %"} </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        )
    }

    const renderComponent_year_result_final = (index: number, topic: DREDataPropsExtends) => {
        return (
            <div className="topic-detail">
                <p>TOTAL {renderMonth(index)} <span className="percentage">{calculatemeta(topic.year_result_final ? parseFloat(topic.year_result_final[index].preview) : 0, topic.year_result_final ? parseFloat(topic.year_result_final[index].realized) : 0)}%</span></p>
                <div className="values">
                    <div className="preview">
                        <p>Previsto</p>
                        <CurrencyInput {...inputsConfigs} value={topic.year_result_final ? topic.year_result_final[index].preview : 0} />
                        {
                            topic.show_percentage === true ? (
                                <div className="percentage">{topic.year_result_final && topic.year_result_final[index].preview_percentage ? `${topic.year_result_final[index].preview_percentage}%` : "0 %"} </div>
                            ) : null
                        }
                    </div>
                    <div className="preview">
                        <p>Realizado</p>
                        <CurrencyInput {...inputsConfigs} value={topic.year_result_final ? topic.year_result_final[index].realized : 0} />
                        {
                            topic.show_percentage === true ? (
                                <div className="percentage">{topic.year_result_final && topic.year_result_final[index].realized_percentage ? `${topic.year_result_final[index].realized_percentage}%` : "0 %"} </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/" />
                            <h1 className="app-content-h1-destaque">Financeiro: DRE ({year})</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" onLoad={() => {
                    
                }} style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Entradas</h2>
                        </div>
                        <div className="options">
                            {
                                projectSelected ? (
                                    <>
                                        <button className="btn" onClick={() => {
                                            if(projectSelected)
                                            {
                                                setTopics([])

                                                DREAPI.getAll((year-1).toString(), projectSelected, setTopics)

                                                setYear(year-1)
                                            }
                                        }}><FaArrowLeft /> Ano anterior</button>
                                        <button className="btn" onClick={() => {
                                            if(projectSelected)
                                            {
                                                setTopics([])

                                                DREAPI.getAll((year+1).toString(), projectSelected, setTopics)

                                                setYear(year+1)
                                            }
                                        }}>Próximo ano <FaArrowRight /></button>
                                        <button className="btn" onClick={() => {
                                            const token = cookies.get('X-Request-Token')

                                            window.open(`${APP_CONFIG.localhost}/client/finance/dre/${projectSelected}/csv?token=${token}&year=${year}`);
                                        }}><FaFileExport /> Exportar</button>
                                    </>
                                ) : null
                            }
                            <button className="btn" onClick={() => {
                                setProjectSelected('')
                                setTopics([])
                            }}>Alterar Projeto</button>
                        </div>
                    </div>
                    <br/>
                    {
                        projectSelected === "" ? (
                            <>
                                {
                                    projects.length > 0 ?
                                    (
                                        projects.map((item) => 
                                            {
                                                return (
                                                    <>
                                                        <div className="proccess-item" onClick={() => {}}>
                                                            <div className="title-with-button-options">
                                                                <div className="title link" onClick={() => {
                                                                    setProjectSelected(item.id)

                                                                    if(item.id)
                                                                    {
                                                                        DREAPI.getAll(year.toString(), item.id, setTopics)
                                                                    }
                                                                }}>{item.name}</div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        )
                                    ) : <NoWithContent/>
                                }
                            </>
                        ) : null
                    }
                    {
                        projectSelected !== "" && topics.length > 0 ? (
                            <>
                                {
                                    topics.map((topic, topic_index) => {

                                        return (
                                            <div className="topic-group">
                                                {/* <div className="topic-titles">
                                                    <div className="topic-title category">{topic.name}</div>
                                                    {
                                                        topic.topics.length > 0 ?
                                                        (
                                                            <>
                                                                {
                                                                    topic.topics.map((item, i) => {
                                                                        return <div className="topic-title" key={`${i}`}>{item.name}</div>
                                                                    })
                                                                }
                                                            </>
                                                        ) : null
                                                    }
                                                    {
                                                    topic.label_response && topic.group_id ? (
                                                    <div className="topic-title response">{topic.label_response}</div>
                                                    ) : null}
                                                </div> */}
                                                <div className="category-item" onScrollCapture={event => onScrollCategoryItem(event)}>
                                                    <div className="topic">
                                                        <div className="topic-title category">{topic.name}</div>
                                                        {
                                                            topic.year_result?.map((item, index) => {
                                                                return renderComponent_year_result(index, topic)
                                                            })
                                                        }
                                                        <div className="topic-detail">
                                                            <p>TOTAL MÉDIA ACUMULADA MÊS</p>
                                                            <div className="values">
                                                                <div className="preview">
                                                                    <CurrencyInput {...inputsConfigs} value={topic.total_group ? topic.total_group.media_preview : 0} />
                                                                </div>
                                                                <div className="preview">
                                                                    <CurrencyInput {...inputsConfigs} value={topic.total_group ? topic.total_group.realized : 0} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="topic-detail">
                                                            <p>TOTAL TOTAL ACUMULADO</p>
                                                            <div className="values">
                                                                <div className="preview">
                                                                    <p>Previsto</p>
                                                                    <CurrencyInput {...inputsConfigs} value={topic.total_group ? topic.total_group.preview : 0} />
                                                                </div>
                                                                <div className="preview">
                                                                    <p>Realizado</p>
                                                                    <CurrencyInput {...inputsConfigs} value={topic.total_group ? topic.total_group.realized : 0} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        topic.topics.length > 0 ?
                                                        (
                                                            <>
                                                                {/* {
                                                                    topic.topics.map((item, i) => {
                                                                        return <DREItem dre={item} key={`${i}`}  />
                                                                    })
                                                                } */}
                                                                {
                                                                    topic.topics.map((topic, i) => {
                                                                        return (
                                                                            <>
                                                                                <DREItem block_input is_topic dre={{
                                                                                    id: topic.id,
                                                                                    name: topic.name,
                                                                                    type: topic.type,
                                                                                    client_id: topic.client_id,
                                                                                    show_percentage: topic.show_percentage,
                                                                                    year: topic.year,
                                                                                }}  />
                                                                                {
                                                                                    topic.subtopics?.map((subtopic, i) => {
                                                                                        return (
                                                                                            <DREItem block_input is_subtopic dre={{
                                                                                                id: subtopic.id,
                                                                                                name: subtopic.name,
                                                                                                type: topic.type,
                                                                                                client_id: topic.client_id,
                                                                                                show_percentage: topic.show_percentage,
                                                                                                year: subtopic.year,
                                                                                            }}  />
                                                                                        )
                                                                                    })
                                                                                }
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                        ) : null
                                                    }
                                                    {
                                                        topic.label_response && topic.group_id ? (
                                                            <div className="topic">
                                                                <div className="topic-title response">{topic.label_response}</div>
                                                                {topic.year_result?.map((item, index) => renderComponent_year_result_final(index, topic))}
                                                                <div className="topic-detail">
                                                                    <p>TOTAL MÉDIA ACUMULADA MÊS</p>
                                                                    <div className="values">
                                                                        <div className="preview">
                                                                            <CurrencyInput {...inputsConfigs} value={topic.total ? topic.total.media_preview : 0} />
                                                                        </div>
                                                                        <div className="preview">
                                                                            <p>Realizado</p>
                                                                            <CurrencyInput {...inputsConfigs} value={topic.total ? topic.total.media_realized : 0} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="topic-detail">
                                                                    <p>TOTAL TOTAL ACUMULADO</p>
                                                                    <div className="values">
                                                                        <div className="preview">
                                                                            <CurrencyInput {...inputsConfigs} value={topic.total ? topic.total.preview : 0} />
                                                                        </div>
                                                                        <div className="preview">
                                                                            <p>Realizado</p>
                                                                            <CurrencyInput {...inputsConfigs} value={topic.total ? topic.total.realized : 0} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </>
                        ) : null
                    }
                </div>
            </Content>
        </>
    )
}