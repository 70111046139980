import { RouteProps } from "react-router-dom";
import { APP_CONFIG } from "../constants";
import { cookies, getSession } from "../functions/Session";
import { Account } from "../pages/Account";
import { AttachmentFiles } from "../pages/AttachmentFiles";
import { AttachmentFolders } from "../pages/AttachmentFolders";
import { CalendarMonth } from "../pages/CalendarMonth";
import { CalendarWeek } from "../pages/CalendarWeek";
import { Contact } from "../pages/Contacts";
import { DepartmentProccess } from "../pages/Processos/DepartmentProccess";
import { FAQ } from "../pages/FAQ";
import { FAQView } from "../pages/FAQView";
import { Home } from "../pages/Home";
import { IndicadorDepartment } from "../pages/Resultados/IndicadorDepartment";
import { Dashboard } from "../pages/Resultados/Dashboard";
import { HistoryData } from "../pages/Resultados/HistoryData";
import { ImportIndicators } from "../pages/Resultados/ImportIndicators";
import { Metas } from "../pages/Resultados/Metas";
import { MacroProccess } from "../pages/Processos/MacroProccess";
import { ModuleStages } from "../pages/ModuleStages";
import { MyDayFeedbacks } from "../pages/MyDayFeedbacks";
import { MyDiagnostic } from "../pages/MyDiagnostic";
import { MyDiagnosticResult } from "../pages/MyDiagnosticResult";
import { MyFeedbacks } from "../pages/MyFeedbacks";
import { POP } from "../pages/Processos/POP";
import { Proccess } from "../pages/Processos/Proccess";
import { ProccessStages } from "../pages/Processos/Proccess/pages/ProccessStages";
import { Protocol } from "../pages/Protocol";
import { Subject } from "../pages/Protocol/Pages/Subject";
import { RecoverPassword } from "../pages/RecoverPassword";
import { SignIn } from "../pages/SignIn";
import { SignUp } from "../pages/SignUp";
import { Tasks } from "../pages/Tasks";
import { TasksFinish } from "../pages/TasksFinish";
import { Tutorial } from "../pages/Tutorial";

// Pessoas
import { Anexos as PessoasAnexos } from '../pages/Pessoas/Anexos'
import { Cargos as PessoasCargos } from '../pages/Pessoas/Cargos'
import { Description } from "../pages/Pessoas/Description";
import { Normas } from "../pages/Pessoas/Normas";
import { Manual as PessoasManual } from "../pages/Pessoas/Manual";
import { Organograma as PessoasOrganograma } from "../pages/Pessoas/Organograma";
import { DepartmentPF } from "../pages/Pessoas/ProccessFeedback/pages/DepartmentsPF";
import { CollaboratorPF } from "../pages/Pessoas/ProccessFeedback/pages/CollaboratorPF";
import { FeedbacksPF } from "../pages/Pessoas/ProccessFeedback/pages/FeedbacksPF";
import { Onboarding } from "../pages/Pessoas/Onboarding";
import { OrganogramaDepartments } from "../pages/Pessoas/Organograma/pages/OrganogramaDepartments";
import { CustomerJourneyStage } from "../pages/Processos/CustomerJourneyStage";
import { AllPOPs } from "../pages/Processos/AllPOPs";
import { DepartmentIndicators } from "../pages/Resultados/DepartmentIndicators";
import { EasyDashboard } from "../pages/Resultados/EasyDashboard";
import { Users } from "../pages/Users";
import { Departments } from "../pages/Departments";
import { PrevisaoOrcamentaria } from "../pages/Financeiro/PrevisaoOrcamentaria";
import { DRE } from "../pages/Financeiro/DRE";
import { Lancamentos } from "../pages/Financeiro/Lancamentos";
import { PlanoDeContas } from "../pages/Financeiro/PlanoDeContas";
import { Subtopic } from "../pages/Financeiro/PlanoDeContas/view/Subtopics";
import { Register } from "../pages/Register";
import { Groups } from "../pages/Financeiro/Groups";
import { FluxoCaixa } from "../pages/Financeiro/FluxoCaixa";
import { MyPlans } from "../pages/MyPlans";
import { MyPlan } from "../pages/MyPlan";
import { Plans } from "../pages/Plans";
import { PlanRenew } from "../pages/PlanRenew";
import { Folders } from "../pages/MyAttachments/Folders";
import { Files } from "../pages/MyAttachments/Files";
import { IndicadorGraphic } from "../pages/Resultados/IndicadorGraphic";
import { FeedbackPFGraphics } from "../pages/Pessoas/ProccessFeedback/pages/FeedbackPFGraphics";
import { DashboardGraphic } from "../pages/Resultados/DashboardGraphic";
import { PlanAction } from "../pages/Resultados/PlanAction";
import { PlanActionFinish } from "../pages/Resultados/PlanActionFinish";
import { Archived } from "../pages/Protocol/Pages/Archived";
import { Projects } from "../pages/Projects";
import { Consolidado } from "../pages/Financeiro/Consolidado";
import { Diagnostics } from "../pages/Diagnostics";
import { NewDiagnostic } from "../pages/Diagnostics/pages/NewDiagnostic";
import { NewDiagnosticResult } from "../pages/Diagnostics/pages/NewDiagnosticResult";
import { TimesheetReport } from "../pages/Rotina/TimesheetReport";
import { Graphics } from "../pages/Financeiro/Graphics";
import { RegisterConsultor } from "../pages/RegisterConsultor";
import { Companies } from "../pages/Companies";
import { TimesheetReportAll } from "../pages/Rotina/TimesheetReportAll";
import { AccountGoogle } from "../pages/AccountGoogle";
import { HistoryPops } from "../pages/Processos/HistoryPops";
import { DashboardMeta } from "../pages/Resultados/DashboardMeta";
import { AuthorizePops } from "../pages/Processos/AuthorizePops";
import { Scrum } from "../pages/Rotina/Scrum";
import { Contracts } from "../pages/Contracts";
import { Swot } from "../pages/PlanejamentoEstrategico/Swot";
import { Canvas } from "../pages/PlanejamentoEstrategico/Canvas";
import { SwotView } from "../pages/PlanejamentoEstrategico/Swot/pages/SwotView";
import { CanvasView } from "../pages/PlanejamentoEstrategico/Canvas/pages/CanvasView";
import { FeedbacksHistory } from "../pages/Pessoas/ProccessFeedback/pages/FeedbacksHistory";
import { DescriptionHistory } from "../pages/Pessoas/DescriptionHistory";
import { DescriptionApprove } from "../pages/Pessoas/DescriptionApprove";
import { ScrumCompany } from "../pages/Rotina/ScrumCompany";
import { ScrumISO } from "../pages/Rotina/ScrumISO";
import { RNC } from "../pages/Processos/RNC";
import { AuditoriaInterna } from "../pages/Processos/AuditoriaInterna";
import { AuditoriaInternaRespostas } from "../pages/Processos/AuditoriaInternaRespostas";
import { FeedbackPFGraphic } from "../pages/Pessoas/ProccessFeedback/pages/FeedbackPFGraphic";
import { ManualQualidade } from "../pages/Processos/ManualQualidade";
import { PFC_Departments } from "../pages/Pessoas/ProccessFeedbackCustom/Departments";
import { PFC_Templates } from "../pages/Pessoas/ProccessFeedbackCustom/Templates";
import { PFC_TemplateQuestion } from "../pages/Pessoas/ProccessFeedbackCustom/TemplateQuestion";
import { PFC_Collaborator } from "../pages/Pessoas/ProccessFeedbackCustom/Collaborators";
import { PFC_Feedbacks } from "../pages/Pessoas/ProccessFeedbackCustom/Feedbacks";
import { PFC_FeedbacksHistory } from "../pages/Pessoas/ProccessFeedbackCustom/History";
import { PFC_FeedbackGraphic } from "../pages/Pessoas/ProccessFeedbackCustom/Graphic";
import { PFC_FeedbackGraphics } from "../pages/Pessoas/ProccessFeedbackCustom/Graphics";
import { AnaliseCritica } from "../pages/Processos/AnaliseCritica";
import { AnaliseCriticaItens } from "../pages/Processos/AnaliseCriticaItens";
import { ControleMudanca } from "../pages/Processos/ControleMudanca";

export const routes: (() => Array<RouteProps>) = () => 
{
    if(getSession(APP_CONFIG.sessionArgs))
    {
        // console.log(getUserData())
        if(cookies.get('user') && (cookies.get('user').payment_status === 'PERIOD_FREE' || cookies.get('user').payment_status === "PAYMENT_TRUE"))
        {
            return [
                {
                    path: "/",
                    component: CalendarWeek,
                    exact: true,
                },
                {
                    path: "/tasks",
                    component: Tasks,
                    exact: true,
                },
                {
                    path: "/tasks-finish",
                    component: TasksFinish,
                    exact: true,
                },
                {
                    path: "/departments",
                    component: Departments,
                    exact: true,
                },
                {
                    path: "/projects",
                    component: Projects,
                    exact: true,
                },
                {
                    path: "/calendar-month",
                    component: CalendarMonth,
                    exact: true,
                },
                {
                    path: "/calendar-week",
                    component: CalendarWeek,
                    exact: true,
                },
                {
                    path: '/my-diagnostic',
                    component: MyDiagnostic,
                    exact: true
                },
                {
                    path: '/new-diagnostic',
                    component: NewDiagnostic,
                    exact: true
                },
                {
                    path: '/new-diagnostic/result',
                    component: NewDiagnosticResult,
                    exact: true
                },
                {
                    path: '/my-diagnostic/result',
                    component: MyDiagnosticResult,
                    exact: true
                },
                {
                    path: '/account',
                    component: Account,
                    exact: true
                },
                {
                    path: '/all-pops',
                    component: AllPOPs,
                    exact: true
                },
                {
                    path: '/rnc',
                    component: RNC,
                    exact: true
                },
                {
                    path: '/manual-de-qualidade',
                    component: ManualQualidade,
                    exact: true
                },
                {
                    path: '/auditoria-interna',
                    component: AuditoriaInterna,
                    exact: true
                },
                {
                    path: '/analise-critica',
                    component: AnaliseCritica,
                    exact: true
                },
                {
                    path: '/controle-mudanca',
                    component: ControleMudanca,
                    exact: true 
                },
                {
                    path: '/analise-critica/:id',
                    component: AnaliseCriticaItens,
                    exact: true
                },
                {
                    path: '/auditoria-interna/:id',
                    component: AuditoriaInternaRespostas,
                    exact: true
                },
                {
                    path: '/history-pop/:pop_id',
                    component: HistoryPops,
                    exact: true
                },
                {
                    path: '/history-feedbacks/:id',
                    component: FeedbacksHistory,
                    exact: true
                },
                {
                    path: '/history-descriptions/:id',
                    component: DescriptionHistory,
                    exact: true
                },
                {
                    path: '/contacts',
                    component: Contact,
                    exact: true
                },
                {
                    path: '/users',
                    component: Users,
                    exact: true
                },
                {
                    path: '/companies',
                    component: Companies,
                    exact: true
                },
                {
                    path: '/myday-feedbacks',
                    component: MyDayFeedbacks,
                    exact: true
                },
                {
                    path: '/meus-feedbacks',
                    component: MyFeedbacks,
                    exact: true
                },
                {
                    path: '/protocols',
                    component: Protocol,
                    exact: true
                },
                {
                    path: '/protocols/archiveds',
                    component: Archived,
                    exact: true
                },
                {
                    path: '/protocols/:folder_id',
                    component: Protocol,
                    exact: true
                },
                {
                    path: '/jornada',
                    component: Tutorial,
                    exact: true
                },
                {
                    path: '/pessoas/cargos',
                    component: PessoasCargos,
                    exact: true
                },
                {
                    path: '/pessoas/cargos/approved',
                    component: DescriptionApprove,
                    exact: true
                },
                {
                    path: '/pessoas/normas',
                    component: Normas,
                    exact: true
                },
                {
                    path: '/pessoas/onboarding',
                    component: Onboarding,
                    exact: true
                },
                {
                    path: '/pessoas/cargos/:id',
                    component: Description,
                    exact: true
                },
                {
                    path: '/pessoas/organograma',
                    component: OrganogramaDepartments,
                    exact: true
                },
                {
                    path: '/organograma/:department',
                    component: PessoasOrganograma,
                    exact: true
                },
                {
                    path: '/pessoas/proccess-feedback',
                    component: DepartmentPF,
                    exact: true
                },
                {
                    path: '/pessoas/proccess-feedback/collaborator/:department',
                    component: CollaboratorPF,
                    exact: true
                },
                {
                    path: '/pessoas/proccess-feedback/collaborator/feedback/:collaborator',
                    component: FeedbacksPF,
                    exact: true
                },
                {
                    path: '/pessoas/proccess-feedback/collaborator/feedback/:collaborator/graphic',
                    component: FeedbackPFGraphics,
                    exact: true
                },
                {
                    path: '/pessoas/proccess-feedback/collaborator/feedback/:collaborator/graphic/:feedback',
                    component: FeedbackPFGraphic,
                    exact: true
                },
                // </ Processo de feedback: Personalizado
                {
                    path: '/pessoas/proccess-feedback-custom',
                    component: PFC_Departments,
                    exact: true
                },
                {
                    path: '/pessoas/proccess-feedback-custom/templates',
                    component: PFC_Templates,
                    exact: true
                },
                {
                    path: '/pessoas/proccess-feedback-custom/templates/:template',
                    component: PFC_TemplateQuestion,
                    exact: true
                },
                {
                    path: '/pessoas/proccess-feedback-custom/collaborator/:department',
                    component: PFC_Collaborator,
                    exact: true
                },
                {
                    path: '/pessoas/proccess-feedback-custom/collaborator/feedback/:collaborator',
                    component: PFC_Feedbacks,
                    exact: true
                },
                {
                    path: '/pessoas/proccess-feedback-custom/history-feedbacks/:id',
                    component: PFC_FeedbacksHistory,
                    exact: true
                },
                {
                    path: '/pessoas/proccess-feedback-custom/collaborator/feedback/:collaborator/graphic/:feedback',
                    component: PFC_FeedbackGraphic,
                    exact: true
                },
                {
                    path: '/pessoas/proccess-feedback-custom/collaborator/feedback/:collaborator/graphic',
                    component: PFC_FeedbackGraphics,
                    exact: true
                },
                // Processo de feedback \>
                {
                    path: '/pessoas/anexos',
                    component: PessoasAnexos,
                    exact: true
                },
                {
                    path: '/pessoas/manual',
                    component: PessoasManual,
                    exact: true
                },
                {
                    path: '/attachment-files',
                    component: AttachmentFolders,
                    exact: true
                },
                {
                    path: '/attachment-files/:folder',
                    component: AttachmentFiles,
                    exact: true
                },
                {
                    path: '/my-attachments',
                    component: Folders,
                    exact: true
                },
                {
                    path: '/my-attachments/:folder',
                    component: Files,
                    exact: true
                },
                {
                    path: '/planejamento-estrategico/swot',
                    component: Swot,
                    exact: true
                },
                {
                    path: '/planejamento-estrategico/swot/:folder_id',
                    component: SwotView,
                    exact: true
                },
                {
                    path: '/planejamento-estrategico/canvas',
                    component: Canvas,
                    exact: true
                },
                {
                    path: '/planejamento-estrategico/canvas/:folder_id',
                    component: CanvasView,
                    exact: true
                },
                {
                    path: '/jornada/:id',
                    component: ModuleStages,
                    exact: true
                },
                {
                    path: '/jornada/:id/:task?',
                    component: ModuleStages,
                    exact: true
                },
                {
                    path: '/faq/:module_id?',
                    component: FAQ,
                    exact: true
                },
                {
                    path: '/faq/:module_id/:id',
                    component: FAQView,
                    exact: true
                },
                {
                    path: '/proccess',
                    component: Proccess,
                    exact: true
                },
                {
                    path: '/proccess-custumer-journey',
                    component: CustomerJourneyStage,
                    exact: true
                },
                {
                    path: '/proccess/:id',
                    component: ProccessStages,
                    exact: true
                },
                {
                    path: '/department-proccess/:id',
                    component: DepartmentProccess,
                    exact: true
                },
                // {
                //     path: '/micro-proccess-attachment/:microproccess',
                //     component: MicroProccessAttachments,
                //     exact: true
                // },
                {
                    path: '/macroproccess/:macroproccess_id',
                    component: MacroProccess,
                    exact: true
                },
                {
                    path: '/pop/:proccess',
                    component: POP,
                    exact: true
                },
                {
                    path: '/plan-actions',
                    component: PlanAction,
                    exact: true
                },
                {
                    path: '/plan-actions/finish',
                    component: PlanActionFinish,
                    exact: true
                },
                {
                    path: '/indicadores',
                    component: IndicadorDepartment,
                    exact: true
                },
                {
                    path: '/indicadores',
                    component: IndicadorDepartment,
                    exact: true
                },
                {
                    path: '/indicadores/department/:department_id',
                    component: DepartmentIndicators,
                    exact: true
                },
                {
                    path: '/indicadores/import',
                    component: ImportIndicators,
                    exact: true
                },
                {
                    path: '/indicadores/metas',
                    component: Metas,
                    exact: true
                },
                {
                    path: '/scrum',
                    component: Scrum,
                    exact: true
                },
                {
                    path: '/scrum-company',
                    component: ScrumCompany,
                    exact: true
                },
                {
                    path: '/scrum-iso',
                    component: ScrumISO,
                    exact: true
                },
                {
                    path: '/contracts',
                    component: Contracts,
                    exact: true
                },
                {
                    path: '/authorize-pops',
                    component: AuthorizePops,
                    exact: true
                },
                {
                    path: '/indicadores/dashboard',
                    component: Dashboard,
                    exact: true
                },
                {
                    path: '/indicadores/easy-dashboard/:department_id',
                    component: EasyDashboard,
                    exact: true
                },
                {
                    path: '/indicadores/dashboard/:department_id',
                    component: EasyDashboard,
                    exact: true
                },
                {
                    path: '/indicadores/dashboard/:department_id/graphic',
                    component: IndicadorGraphic,
                    exact: true
                },
                {
                    path: '/indicadores/dashboard/:department_id/graphics',
                    component: DashboardGraphic,
                    exact: true
                },
                {
                    path: '/indicadores/dashboard/:department_id/graphics/meta',
                    component: DashboardMeta,
                    exact: true
                },
                {
                    path: '/indicadores/history/:id',
                    component: HistoryData,
                    exact: true
                },
                {
                    path: '/protocols-subject',
                    component: Subject,
                    exact: true
                },
                // FINANCEIRO
                {
                    path: '/financeiro/plano-de-contas',
                    component: Groups,
                    exact: true
                },
                {
                    path: '/financeiro/plano-de-contas/:group_id',
                    component: PlanoDeContas,
                    exact: true
                },
                {
                    path: '/financeiro/plano-de-contas/:group_id/:topic_id',
                    component: Subtopic,
                    exact: true
                },
                {
                    path: '/financeiro/lancamentos',
                    component: Lancamentos,
                    exact: true
                },
                {
                    path: '/financeiro/dre',
                    component: DRE,
                    exact: true
                },
                {
                    path: '/financeiro/graphics',
                    component: Graphics,
                    exact: true
                },
                {
                    path: '/financeiro/dre/consolidada',
                    component: Consolidado,
                    exact: true
                },
                {
                    path: '/financeiro/fluxo-caixa',
                    component: FluxoCaixa,
                    exact: true
                },
                {
                    path: '/financeiro/previsao-orcamentaria',
                    component: PrevisaoOrcamentaria,
                    exact: true
                },
                {
                    path: '/plans',
                    component: MyPlans,
                    exact: true
                },
                {
                    path: '/plans/plan/:id',
                    component: MyPlan,
                    exact: true
                },
                {
                    path: '/plans/plans',
                    component: Plans,
                    exact: true
                },
                {
                    path: "/account/google",
                    component: AccountGoogle,
                    exact: true
                },
                {
                    path: '/diagnostics',
                    component: Diagnostics,
                    exact: true
                },
                {
                    path: '/timesheet/report',
                    component: TimesheetReport,
                    exact: true
                },
                {
                    path: '/timesheet/report/all',
                    component: TimesheetReportAll,
                    exact: true
                },
                {
                    path: '/plans/renew/:plan_id',
                    component: PlanRenew,
                    exact: true
                },
                {
                    path: '/sair',
                    component: SignUp,
                    exact: true
                },
                {
                    path: '*',
                    component: Home,
                    exact: true
                }
            ]
        }else{
            if(!cookies.get('user'))
            {
                return [
                    {
                        component: SignUp,
                    },
                ]
            }else{
                return [
                    {
                        path: '/sair',
                        component: SignUp,
                        exact: true
                    },
                    {
                        path: '/plans',
                        component: MyPlans,
                        exact: true
                    },
                    {
                        path: '/plans/plan/:id',
                        component: MyPlan,
                        exact: true
                    },
                    {
                        path: '/plans/plans',
                        component: Plans,
                        exact: true
                    },
                    {
                        path: '/plans/renew/:plan_id',
                        component: PlanRenew,
                        exact: true
                    },
                    {
                        component: Plans
                    }
                ]
            }
        }
    }else{
        return [
            {
                path: "/",
                component: SignIn,
                exact: true
            },
            {
                path: "/login-after-register",
                component: SignIn,
                exact: true
            },
            {
                path: "/register",
                component: Register,
                exact: true
            },
            {
                path: "/register/consultor",
                component: RegisterConsultor,
                exact: true
            },
            {
                path: "/recover-password",
                component: RecoverPassword,
                exact: true
            }
        ]
    }
}

