import React, { useEffect, useState } from 'react'
import { MdDeleteForever, MdEdit } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { Content } from '../../../../../components/Content'
import { LinkBackHistory } from '../../../../../components/LinkBackHistory'
import { ConfigModal, Modal } from '../../../../../components/Modal'
import { Navbar } from '../../../../../components/Navbar'
import { NoWithContent } from '../../../../../components/NoWithContent'
import { ConfirmDelete } from '../../../../../functions/Modals/ConfirmDelete'
import { TopicItemProps, TopicItemsAPI } from '../../../../../services/Financeiro/TopicItems'
import { FormSubtopic } from './components/FormSubtopic'

export interface SubtopicProps {}
export interface SubtopicRouteProps {
    topic_id: string,
    group_id: string
}

export const Subtopic: React.FC<SubtopicProps> = (props) => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [list, setList] = useState<Array<TopicItemProps>>([])
    const { topic_id, group_id } = useParams<SubtopicRouteProps>()

    // const sync = () => {
    //     TopicItemsAPI.getAll(topic_id, setList)
    // }

    useEffect(() => {
        TopicItemsAPI.getAll(topic_id, setList)
    }, [topic_id])

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to={`/financeiro/plano-de-contas/${group_id}`} />
                            <h1 className="app-content-h1-destaque">Contas Contábeis</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Lista de Contas Contábeis</h2>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    content: <FormSubtopic topic_id={topic_id} onSuccess={() => TopicItemsAPI.getAll(topic_id, setList)} />,
                                    onClose: () => setModalConfig({
                                        show: false
                                    }),
                                    title: <h2>Criar contas contábeis</h2>
                                })
                            }}>Criar contas contábeis</button>
                        </div>
                    </div>
                    <br/>
                    {
                        list.length > 0 ?
                        (
                            list.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link">
                                                        <p>{item.name}</p>
                                                    </div>
                                                    <div className="options">
                                                        <button className="update-button" onClick={() => {
                                                            setModalConfig({
                                                                show: true,
                                                                content: <FormSubtopic topic_id={topic_id} id={item.id} onSuccess={() => TopicItemsAPI.getAll(topic_id, setList)} />,
                                                                onClose: () => setModalConfig({
                                                                    show: false
                                                                }),
                                                                title: <h2>Atualizar</h2>
                                                            })
                                                        }}><MdEdit/></button>
                                                        <button className="delete-button" onClick={() => {
                                                            ConfirmDelete(() => {
                                                                if(item.id)
                                                                {
                                                                    TopicItemsAPI.delete(item.id, () => {
                                                                        setList([...list.filter(i => i.id !== item.id)])
                                                                    })
                                                                }
                                                            }, () => null)
                                                        }}><MdDeleteForever/></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : <NoWithContent/>
                    }
                </div>
            </Content>
        </>
    )
}