import React, { useEffect, useState } from 'react'
import { MdAdd, MdPictureAsPdf } from 'react-icons/md'
import { APP_CONFIG } from '../../../../constants'
import Success from '../../../../functions/Modals/Success'
import { CustomerJourneyPOPAPI, CustomerJourneyPOPForm } from '../../../../services/Proccess/CustomerJourneyPOP'

export interface FormPOPProps {
    id?: string,
    onSuccess?: (() => void)
}

export const FormPOP: React.FC<FormPOPProps> = (props) => 
{
    const baseForm = {
        id: '',
        client_id: '',
        objective: '',
        obs: '',
        docs_ref: '',
        checklist: '',
        result: '',
        obs_error: '',
        proccess_id: '',
        elaborate_by: '',
        accredited_by: '',
        necessary_materials: '',
        necessary_systems: ''
    };

    const [form, setForm] = useState<CustomerJourneyPOPForm>(baseForm)

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        CustomerJourneyPOPAPI.create(form, s => Success('Salvo com sucesso!', () => {
            window.location.reload()
        }))
    }

    useEffect(() => {
        CustomerJourneyPOPAPI.consult(setForm)
    }, [])

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Objetivo</label>
                    <textarea onChange={e => setForm({...form, objective: e.target.value})} value={form.objective} />
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Siglas e abreviaturas</label>
                    <textarea onChange={e => setForm({...form, obs: e.target.value})} value={form.obs} />
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Documentos de referência</label>
                    <textarea onChange={e => setForm({...form, docs_ref: e.target.value})} value={form.docs_ref} />
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Sistema necessários</label>
                    <textarea onChange={e => setForm({...form, necessary_systems: e.target.value})} value={form.necessary_systems} />
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Materiais necessários</label>
                    <textarea onChange={e => setForm({...form, necessary_materials: e.target.value})} value={form.necessary_materials} />
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Resultado esperado</label>
                    <textarea onChange={e => setForm({...form, result: e.target.value})} value={form.result} />
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Cuidados especiais</label>
                    <textarea onChange={e => setForm({...form, checklist: e.target.value})} value={form.checklist} />
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Ações em caso de não conformidade</label>
                    <textarea onChange={e => setForm({...form, obs_error: e.target.value})} value={form.obs_error} />
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Elaborado por</label>
                    <textarea onChange={e => setForm({...form, elaborate_by: e.target.value})} value={form.elaborate_by} />
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Aprovado Por</label>
                    <textarea onChange={e => setForm({...form, accredited_by: e.target.value})} value={form.accredited_by} />
                </div>
                <div className="flex between">
                    <button type="submit" className="btn-styled success"  style={{marginRight: '10px'}}><MdAdd/> Salvar</button>
                    {
                        form.id ?
                        (
                            <button type="button" className="btn-styled normal" style={{marginRight: '10px'}} onClick={() => {
                                window.open(`${APP_CONFIG.localhost}/client/customer-journey/pdf/${form.id}`, '_blank');
                            }}><MdPictureAsPdf/> Gerar PDF</button>
                        ) : null
                    }
                </div>
            </form>
        </>
    )
}