import React, { useEffect, useState } from 'react'

export interface TimerProps {
    callQueuedTime?: number
}

export const Timer: React.FC<TimerProps> = (props) => 
{
    const [time, setTime] = useState(props.callQueuedTime ?? 0)
    
    useEffect(() => {
        if(props.callQueuedTime)
        {
            const intervalId = setInterval(function () {
                const old_time = time;

                setTime(Number(old_time)+1);
            }, 1000);

            return ()=>{
                clearInterval(intervalId);
            }
        }else{
            const intervalId = setInterval(function () {
                const old_time = time;

                setTime(Number(old_time)+1);
            }, 1000);

            return ()=>{
                clearInterval(intervalId);
            }
        }
    }, [props.callQueuedTime, time]);

    const secondsToTime = (secs: number) => 
    {
        secs = Math.round(secs);
        var hours = Math.floor(secs / (60 * 60));

        var divisor_for_minutes = secs % (60 * 60);
        var minutes = Math.floor(divisor_for_minutes / 60);

        var divisor_for_seconds = divisor_for_minutes % 60;
        var seconds = Math.ceil(divisor_for_seconds);

        return `${hours}:${minutes}:${seconds}`;
    }

    return <p>{secondsToTime(time)}</p>
}