import React, { useState } from 'react'
import { MdAdd, MdDelete, MdEdit } from "react-icons/md";
import Error from '../../../functions/Modals/Error';
import { ControllerMyFeedbacks } from '../../../services/MyFeedbacks/ControllerMyFeedbacks';
import ImageUploading, { ImageListType } from 'react-images-uploading';

interface SendFeedbackProps {
    onSuccess?: (() => void)
}

export const SendFeedback: React.FC<SendFeedbackProps> = (props) => 
{
    const [data, setData] = useState({
        feedback: ''
    })

    const [images, setImages] = React.useState<ImageListType>([]);

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => 
    {
        e.preventDefault()

        let formData = new FormData()

        formData.append('feedback', data.feedback)

        if(images[0] && images[0].file)
        {
            formData.append('image', images[0].file)
        }

        ControllerMyFeedbacks.save(formData, s => {
            if(props.onSuccess)
            {
                props.onSuccess()
            }
        } , e => Error('Houve um erro se persistir contacte o administrador.'))
    }

    const onChange = (imageList: ImageListType) => setImages(imageList as never[])

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                <div className="box-input">
                    <label htmlFor="!#">Escreva abaixo</label>
                    <textarea style={{width: '100%', padding: '10px'}} className="styled" onChange={e => setData({...data, feedback: e.target.value})} value={data.feedback} required autoComplete="off" autoFocus={true}/>
                </div>
                <div className="box-input">
                    <p>Anexar imagem?</p>
                    <ImageUploading
                        value={images}
                        onChange={onChange}
                        dataURLKey="data_url"
                    >
                        {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                        }) => (
                            <div className="box-image-upload">
                                <button
                                    type="button"
                                    className="btn-upload"
                                    style={isDragging ? { color: 'red' } : undefined}
                                    onClick={onImageUpload}
                                    {...dragProps}
                                >
                                    Clique ou solte aqui
                                </button>
                                &nbsp;
                                {
                                    imageList.length ?
                                    (
                                        <div className="images">
                                            {imageList.map((image, index) => (
                                            <div key={index} className="image">
                                                    <img src={image['data_url']} alt="" width="150" />
                                                    <div className="options">
                                                        <button type="button" onClick={() => onImageUpdate(index)}><MdEdit/></button>
                                                        <button type="button" onClick={() => onImageRemove(index)}><MdDelete/></button>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ) : null
                                }
                            </div>
                        )}
                    </ImageUploading>
                </div>
                <button type="submit"><MdAdd/> Enviar feedback</button>
            </form>
        </>
    )
}
