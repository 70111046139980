import React, { useEffect, useState } from 'react'
import { MdAdd, MdDelete } from "react-icons/md";
import { LoadingContent } from '../../../components/LoadingContent';
import Success from '../../../functions/Modals/Success';
import { CompaniesAPI, CompaniesItemProps } from '../../../services/Companies/CompaniesController';

interface FormCompaniesProps {
    id?: string
}

export const FormCompanies: React.FC<FormCompaniesProps> = (props) => 
{
    const [data, setData] = useState<CompaniesItemProps>({
        name: '',
        email: '',
        phone: ''
    })

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(props.id)
        {
            CompaniesAPI.getById(props.id, data => setData({
                ...data,
                name: data.company_name ?? ''
            }))
        }
    }, [props.id])

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        setLoading(true)
        
        if(props.id)
        {
            await CompaniesAPI.update(props.id, {
                ...data,
                id: props.id
            }, () => Success('Usuário atualizado com sucesso.', () => {
                window.location.reload()
            }))
        }else{
            await CompaniesAPI.create(data, () => Success('Usuário criado com sucesso, senha enviada por e-mail ao mesmo.', () => {
                window.location.reload()
            }))
        }

        setLoading(false)
    }

    return (
        <>
            {
                !loading ? 
                (
                    <form className="form-styleds-create" style={{marginTop: '40px'}} method="post" onSubmit={onSubmit}>
                        <div className="input-box-v2 mg-top">
                            <label htmlFor="!#">Nome</label>
                            <input className="styled" type="text" onChange={e => setData({...data, name: e.target.value})} value={data.name} placeholder="Digite aqui o nome." required autoComplete="off" autoFocus={true}/>
                        </div>
                        <div className="input-box-v2 mg-top">
                            <label htmlFor="!#">Telefone</label>
                            <input className="styled" type="text" onChange={e => setData({...data, phone: e.target.value})} value={data.phone} placeholder="Digite aqui o telefone." required autoComplete="off" autoFocus={true}/>
                        </div>
                        <div className="input-box-v2 mg-top">
                            <label htmlFor="!#">E-mail</label>
                            <input className="styled" type="email" onChange={e => setData({...data, email: e.target.value})} value={data.email} placeholder="Digite aqui o email." required autoComplete="off" autoFocus={true}/>
                        </div>
                        <br /><br />
                        <div className="flex between">
                            <button className="btn-styled success" type="submit"><MdAdd/> Salvar</button>
                            {
                                props.id ? 
                                ( 
                                    <button className="btn-styled danger" type="button" onClick={() => {
                                        if(props.id)
                                        {
                                            CompaniesAPI.delete(props.id, response => {
                                                window.location.reload()
                                            })
                                        }
                                    }}><MdDelete/> Apagar</button>
                                ) : null
                            }
                        </div>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}
