import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/v2/client/scrum/task/file'

export interface ScrumFileItemProps {
    id: string
    title: string
    attachment_url: string
    task_id: string
}

class ScrumFiles
{
    async getAll(
        task_id: string,
        onSuccess: ((success: Array<ScrumFileItemProps>) => void),
    )
    {
        return await Request.get(`${route}s/${task_id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async sendFile(
        form: FormData,
        pop: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}/${pop}`, form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(onSuccess).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }
}

export const ScrumFilesAPI = new ScrumFiles()