import React, { useEffect, useState } from 'react'
import { Form } from '../../components/Form'
import Logo from "../../components/Navbar/assets/logo-preto-2.png"
import { RegisterAPI, RegisterFormProps } from '../../services/Register/Register';
import Warning from '../../functions/Modals/Warning';
import Success from '../../functions/Modals/Success';
import InputMask from 'react-input-mask';
import { APP_CONFIG } from '../../constants';
import { setSession, setUserData } from '../../functions/Session';

export interface RegisterProps {}

export const RegisterConsultor: React.FC<RegisterProps> = (props) => 
{
    const [form, setForm] = useState<RegisterFormProps>({
        account_created: false,
        email: '',
        responsible_name: '',
        password: '',
    })

    useEffect(() => {
        // ReactPixel.init('287419683132694')
    }, [])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => 
    {
        setForm({...form, [e.target.name]: e.target.value})
    }

    return (
        <div className="body-sign">
            <Form method="POST" className="form-sign register-form form-tasks-create" onSubmit={event => {
                event.preventDefault()

                if(!form.account_created)
                {   
                    if(form.email)
                    {
                        if(form.responsible_name)
                        {
                            if(form.password)
                            {
                                if(form.whatsapp_number)
                                {
                                    RegisterAPI.register({
                                        ...form,
                                        is_consultor: true
                                    }, user => {
                                        Success('Conta criada com sucesso, você será redirecionado para a plataforma.', () => {
                                            if(user.token && user.type && user.id)
                                            {
                                                let status = setSession([
                                                    {
                                                        label: 'X-Request-Token',
                                                        value: user.token
                                                    },
                                                    {
                                                        label: 'X-Request-Type',
                                                        value: user.type
                                                    },
                                                    {
                                                        label: 'X-User-Id',
                                                        value: user.id
                                                    }
                                                ]);
                                    
                                                setUserData(user)
                                    
                                                if(status)
                                                { 
                                                    window.location.href = '/'
                                                }
                                            }
                                        })
                                    }, err => {
                                        if(err.status === 400)
                                        {
                                            Warning(err.data.response.message)
                                        }
                                    })
                                }else{
                                    Warning('Preencha seu whatsapp')
                                }
                            }else{
                                Warning('Preencha a senha.')
                            }
                        }else{
                            Warning('Preencha o nome do responsavel.')
                        }
                    }else{
                        Warning('Preencha seu e-mail.')
                    }
                }
            }}>
                <div style={{textAlign: 'center'}}>
                    <img width="255px" src={Logo} alt=""/>
                    <h2>Preencha os dados abaixo para se cadastrar.</h2>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-12" style={{marginTop: '20px'}}>
                        <div className="input-box-v2">
                            <label>E-mail*</label>
                            <input type="email" name="email" value={form.email} onChange={handleChange} required autoComplete="off" autoFocus={true}/>
                        </div>
                    </div>
                    <div className="col-12" style={{marginTop: '20px'}}>
                        <div className="input-box-v2">
                            <label>Nome do responsável*</label>
                            <input type="text" name="responsible_name" value={form.responsible_name} onChange={handleChange} required autoComplete="off"/>
                        </div>
                    </div>
                    <div className="col-12" style={{marginTop: '20px'}}>
                        <div className="input-box-v2">
                            <label>Senha*</label>
                            <input type="password" name="password" value={form.password} onChange={handleChange} required autoComplete="off"/>
                        </div>
                    </div>
                    <div className="col-12" style={{marginTop: '20px'}}>
                        <div className="input-box-v2">
                            <label>Whatsapp*</label>
                            <InputMask placeholder="(41) 9 9999-9999" required mask={"(99) 9 9999-9999"} maskPlaceholder="" value={form.whatsapp_number} onChange={event => setForm({...form, whatsapp_number: event.target.value})} />
                        </div>
                    </div>
                </div>
                <div className="period-free-button">
                    <button type="submit">Inicie sua demonstração</button>
                </div>
                <br />
                <a target="_blank" rel="noopener noreferrer"  href={`${APP_CONFIG.localhost}/public/contrato_e_termos_de_privacidade.pdf`}>*Ao continuar você estará aceitando os termos de uso e privacidade do contrato. Clique aqui ver</a>
            </Form>
            <div className="cookies-accept">

            </div>
        </div>
    )
}