import React, { useEffect, useRef, useState } from 'react'
import { FaDownload, FaPlus, FaTrash } from 'react-icons/fa'
import Select, { StylesConfig } from 'react-select'
import { EditorHTML } from '../../../../components/EditorHTML'
import { LoadingContent } from '../../../../components/LoadingContent'
import { ConfigModal, Modal } from '../../../../components/Modal'
import Success from '../../../../functions/Modals/Success'
import Warning from '../../../../functions/Modals/Warning'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../../services/ClientDepartments/ClientDepartments'
import { ConsultMyTags, ConsultMyTagsItem } from '../../../../services/Tags/ConsultMyTags'
import { ScrumChecklist } from './ScrumChecklist'
import { ScrumFileItemProps, ScrumISOFilesAPI } from '../../../../services/ScrumISO/ScrumISOFiles'
import { ScrumISOListAPI } from '../../../../services/ScrumISO/ScrumISOLists'

export interface ScrumFormTaskProps {
    id?: string
    taskArchived?: boolean
    // type: 'CREATE' | 'UPDATE' | 'SEND_CALENDAR' | 'CLOSE',
    onChange?: ((type: 'CREATE' | 'UPDATE' | 'SEND_CALENDAR' | 'CLOSE' | 'REMOVE' | 'REMOVE_ALL' | 'AUTO_SAVE') => void)
}

export interface ScrumFormProps {
    title?: string
    description?: string
    date_finish?: string
    tag_select?: string
    tab_id?: string
}

export const ScrumFormTask: React.FC<ScrumFormTaskProps> = (props) => 
{
    const loading = useRef(true)
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [form, setForm] = useState<ScrumFormProps>({})
    const [listTags, setListTags] = useState<ConsultMyTagsItem[]>([])
    const [listDepartments, setListDeparments] = useState<FormClientDepartment[]>([])
    const [description, setDescription] = useState('')
    const [comment, setComment] = useState('')
    const [tagsSelected, setTagsSelected] = useState<Array<any>>([])
    const [departmentsSelected, setDepartmentsSelected] = useState<Array<any>>([])
    const [files, setFiles] = useState<ScrumFileItemProps[]>([]);

    const colourStyles : StylesConfig<any, any> = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: data.color
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#fff",
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: data.color,
                color: "#fff"
            };
        },
    }

    const onSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()

        let data_form = {
            ...form,
            description,
            tags: tagsSelected ? tagsSelected.map(item => {
                return {
                    id: item.value
                }
            }) : [],
            departments: departmentsSelected ? departmentsSelected.map(item => {
                return {
                    id: item.value
                }
            }) : []
        }

        if(props.taskArchived && props.id)
        {
            ScrumISOListAPI.unarchiveTask(props.id, data_form, () => {
                if(props.onChange)
                {
                    props.onChange('UPDATE')
                }
            })

            return;
        }

        if(props.id)
        {
            ScrumISOListAPI.update(props.id, data_form, () => {
                if(props.onChange)
                {
                    props.onChange('UPDATE')
                }
            })
        }else{
            ScrumISOListAPI.create(data_form, () => {
                if(props.onChange)
                {
                    props.onChange('CREATE')
                }
            })
        }
    }

    useEffect(() => {
        let tmp_tags : any[] = []
        let tmp_departments : any[] = []
        
        ConsultMyTags(response => {
            setListTags(response.tags)
        }, e => {}) 

        ClientDepartmentsAPI.getAll(data => {
            setListDeparments(data)
        })
        
        if(props.id)
        {
            ScrumISOFilesAPI.getAll(props.id, setFiles)

            ScrumISOListAPI.findOne(props.id, async (response) => {
                setForm({
                    title: response.title,
                    date_finish: response.date_finish,
                    tab_id: response.tab_id,
                })

                setDescription(response.description ?? '')

                let tmpTagsSelected = response.tags?.map((item => {
                    return {
                        value: item.tag?.id,
                        label: item.tag?.label,
                        color: item.tag?.color
                    }
                }))

                let tmpTagsDepartments = response.departments?.map((item => {
                    return {
                        value: item.department?.id,
                        label: item.department?.name,
                    }
                }))

                setTagsSelected(tmpTagsSelected ?? [])
                setDepartmentsSelected(tmpTagsDepartments ?? [])
            })
        }

        loading.current = false;
    }, [props.id])
    

    return (
        <>
            {
                props.id && loading.current === true ? <LoadingContent /> : (
                    <>
                        <Modal {...modalConfig} />
                        <br />
                        <form method="post" onSubmit={onSubmit}>
                            <div className="input-box-v2">
                                <label htmlFor="!#">Título</label>
                                <input className="styled" type="text" onChange={e => setForm({...form, title: e.target.value})} value={form.title} placeholder="Titulo" required autoComplete="off" autoFocus={true}/>
                            </div>
                            <br />
                            <div className="input-box-v2">
                                <p>Descrição</p>
                                <EditorHTML defaultToolbar={true} onChange={value => setDescription(value)} value={description} />
                            </div>
                            <div className="input-box-v2" style={{marginTop: '20px', marginBottom: '20px'}}>
                                <label htmlFor="!#">Etiqueta(s)</label>
                                <Select 
                                    value={tagsSelected} 
                                    isMulti 
                                    styles={colourStyles}
                                    classNamePrefix="styled-v2"
                                    placeholder="Selecione" 
                                    onChange={(e: any) => setTagsSelected(e)} 
                                    options={
                                        listTags.map((e) => 
                                                { 
                                                    return {
                                                        value: e.id,
                                                        label: e.label,
                                                        color: e.color
                                                    }
                                                }
                                            )
                                        }
                                    />
                            </div>
                            <div className="input-box-v2" style={{marginTop: '20px'}}>
                                <label htmlFor="!#">Departamento(s)</label>
                                <Select 
                                    value={departmentsSelected} 
                                    isMulti 
                                    placeholder="Selecione" 
                                    classNamePrefix="styled-v2" 
                                    onChange={(e: any) => setDepartmentsSelected(e)} options={
                                        listDepartments.map((e) => 
                                            { 
                                                return {
                                                    value: e.id,
                                                    label: e.name
                                                }
                                            }
                                        )
                                    }
                                />
                            </div>
                            <br />
                            <div className="input-box-v2">
                                <label htmlFor="!#">Associar a um dia</label>
                                <input className="styled" type="date" onChange={e => setForm({...form, date_finish: e.target.value})} value={form.date_finish}/>
                            </div>
                            <div className="input-box-v2" style={{marginTop: '20px', marginBottom: '20px'}}>
                                <label htmlFor="!#">Aba</label>
                                <select onChange={e => setForm({...form, tab_id: e.target.value})} value={form.tab_id}>
                                    <option value="">Selecione</option>
                                    <option value="backlog">Backlog</option>
                                    <option value="to_realize">A fazer</option>
                                    <option value="in_progress">Em andamento</option>
                                    <option value="concluded">Concluídos</option>
                                </select>
                            </div>
                            {/* <ScrumChecklist task_id={props.id ?? ''} /> */}
                            {
                                props.id ? (
                                    <>
                                        <div className="title-toolkit">
                                            <div className="title">
                                                <span>Arquivos</span>
                                            </div>
                                            <div className="toolkit">
                                                <label htmlFor="file" className="toolkit-button default"><FaPlus/></label>
                                            </div>
                                        </div>
                                        <input  style={{display: 'none'}} type="file" id="file" onChange={event => {
                                            let formData = new FormData()

                                            if(event.target.files && event.target.files[0])
                                            {
                                                formData.append('file', event.target.files[0])
                                
                                                if(props.id)
                                                {
                                                    ScrumISOFilesAPI.sendFile(formData, props.id, () => {
                                                        Success('Arquivo enviado com sucesso', () => {
                                                            if(props.id)
                                                            {
                                                                ScrumISOFilesAPI.getAll(props.id, setFiles)
                                                            }
                                                        })
                                                    })
                                                }
                                            }else{
                                                Warning('Você não escolheu o nenhum arquivo')
                                            }
                                        }} />
                                        {files.map((item, index) => {
                                            return (
                                                <div className="pop-file">
                                                    <div className="title">{item.title}</div>
                                                    <div className="options">
                                                        <span className="clr-success" onClick={() => {
                                                            window.open(`${process.env.REACT_APP_URL_ATTACHMENT}/${item.attachment_url}`, '_blank')
                                                        }}><FaDownload /></span>
                                                        <span className="clr-danger" onClick={() => {
                                                            ScrumISOFilesAPI.delete(item.id, () => Success('Apagado com sucesso', () => {
                                                                if(props.id)
                                                                {
                                                                    ScrumISOFilesAPI.getAll(props.id, setFiles)
                                                                }
                                                            }))
                                                        }} ><FaTrash /></span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </>
                                ) : null
                            }
                            {/* <div className="title-toolkit">
                                <div className="title">
                                    <span>Comentários</span>
                                </div>
                            </div> */}
                            {/* <div className="input-box-v2">
                                <p>Faça seu comentário</p>
                                <EditorHTML defaultToolbar={true} onChange={value => setDescription(value)} value={description} />
                            </div>
                            <br />
                            <button className="btn-styled success">
                                Enviar comentário
                            </button> */}
                            <br />
                            <div className="flex" style={{justifyContent:  'space-between', marginTop: '20px'}}>
                                {
                                    props.taskArchived ? (
                                        <>
                                            <button className="btn-styled normal" type="submit" style={{marginRight: '10px'}}>
                                                Desarquivar e salvar 
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            <button className="btn-styled normal" type="submit" style={{marginRight: '10px'}}>
                                                Salvar
                                            </button>
                                            <button className="btn-styled danger" type="button" style={{marginRight: '10px'}} onClick={() => {
                                                if(props.id)
                                                {
                                                    ScrumISOListAPI.archiveTask(props.id, () => {
                                                        if(props.onChange)
                                                        {
                                                            props.onChange('UPDATE')
                                                        }
                                                    })
                                                }
                                            }}>
                                                Arquivar
                                            </button>
                                        </>
                                    )
                                }
                            </div>
                        </form>
                    </>
                )
            }    
        </>
    )
}