import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/finance/cash-flow'

export interface CashFlowItemProps {
    mes: string
    balance: string
    mes_number: string
}
class CashFlow 
{
    async getAll(
        year: string,
        onSuccess: ((success: Array<CashFlowItemProps>) => void),
    )
    {
        return await Request.get(`${route}?year=${year}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }
}

export const CashFlowAPI = new CashFlow()