import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { Content } from '../../components/Content'
import { LinkBackHistory } from '../../components/LinkBackHistory'
import { Navbar } from '../../components/Navbar'
import { formatDate } from '../../functions/dataFormat'
import { localization } from '../../functions/localization'
import { DiagnosticMount } from '../../services/DiagnosticGenerateResult'
import { DiagnosticsAPI, DiagnosticsItemProps } from '../../services/V2/Diagnostics'

export interface DiagnosticsProps {}

export const Diagnostics: React.FC<DiagnosticsProps> = (props) => 
{
    const [list, setList] = useState<DiagnosticsItemProps[]>([])
    const history = useHistory()

    useEffect(() => {
        DiagnosticsAPI.get(setList)
    }, [])

    return (
        <>
            {/* <Modal {...modalConfig} /> */}
            <Navbar/>
            <Content title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/" />
                            <h1 className="app-content-h1-destaque">Meus diagnósticos</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Últimos realizados</h2>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                history.push('/new-diagnostic')
                            }}>Novo diagnóstico</button>
                        </div>
                    </div>
                    <br/>
                    <MaterialTable 
                        title=""
                        localization={localization}
                        options={{
                            filtering: true,
                        }}
                        onRowClick={(event, rowData) => {
                            if(rowData && rowData.id)
                            {
                                DiagnosticMount(rowData.id.toString(), response => {
                                    history.push('/new-diagnostic/result', {
                                        points: response.points,
                                        questions: response.questions
                                    })
                                })
                            }
                        }}
                        data={list}
                        columns={[
                            {
                                title: 'Feito em',
                                field: 'date_create',
                                type: 'date',
                                render: rowData => formatDate(rowData.date_create)
                            },
                            {
                                title: 'Feito por',
                                field: 'name',
                            }
                        ]}
                    />
                </div>
            </Content>
        </>
    )
}