import { AxiosResponse } from "axios"
import Request from "../../functions/Request"
import { NormalTask } from "./calendarTypes";

export interface ResponseGenerateLate {
    late: ResponseGenerateLateItem,
    message: string
}

export interface ResponseGenerateLateItem {
    number: string,
    date_filter: string
    date_formatted: string
    name: string
    name_number: string
    week: string
    tasks: Array<NormalTask>
}


export const GenerateLate = async (
    onSuccess: ((e: ResponseGenerateLate) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.get('/calendar/late').then(s => onSuccess(s.data)).catch(onError);
