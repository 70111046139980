import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";
import { ControllerIndicatorsItemProps } from "./Indicators";

const route = '/client/indicador-department'

export interface IndicadorDepartmentForm {
    id?: string
    department_id: string,
    mission: string
}

export interface DepartmentItem {
    id: string
    label: string
}

export interface IndicadorDepartmentItem {
    id?: string
    department_id: string
    client_id: string
    title: string
}


class IndicadorDepartment 
{
    async create(
        data: IndicadorDepartmentForm,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        data: IndicadorDepartmentForm,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: IndicadorDepartmentItem) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: IndicadorDepartmentItem) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((proccesses: Array<IndicadorDepartmentItem>) => void),
    )
    {
        return await Request.get(`${route}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async getAllListDepartmentIndicadors(
        onSuccess: ((proccesses: Array<DepartmentItem>) => void),
    )
    {
        return await Request.get(`${route}/list-departament-indicadors`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async getAllListIndicadorsByDepartment(
        department: string,
        onSuccess: ((proccesses: Array<ControllerIndicatorsItemProps>) => void),
    )
    {
        return await Request.get(`${route}/list-indicadors/${department}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }
}

export const ControllerIndicadorDepartment = new IndicadorDepartment()