import React from 'react'
import { FaTimes } from 'react-icons/fa'

export interface ConfigModal 
{
    title?: JSX.Element,
    content?: JSX.Element,
    footer?: JSX.Element,
    show: boolean,
    onClose?: (() => void)
}

export const Modal: React.FC<ConfigModal> = (props) => {
    return (
        <div className="modal" style={{display: props.show ? 'block' : 'none'}}>
            <div className="modal-modal">
                <div className="modal-close" >
                    <FaTimes onClick={() => {
                        if(props.onClose)
                        {
                            props.onClose()
                        }
                    }}/>
                </div>
                <div className="modal-title">
                    {props.title}
                </div>
                <div className="modal-content">
                    {props.content}
                </div>
                <div className="modal-footer">
                    {props.footer}
                </div>
            </div>
        </div>
    )
}
