import React, { useEffect, useRef, useState } from 'react'
import { Content } from '../../components/Content'
import { LinkBackHistory } from '../../components/LinkBackHistory'
import { LoadingContent } from '../../components/LoadingContent'
import { ConfigModal, ModalRight } from '../../components/ModalRight'
import { Navbar } from '../../components/Navbar'
import { ConsultarTasksFinishResponseItem, ConsultTasksFinish } from '../../services/Calendar/ConsultTasksFinish'
import { TaskHistory } from './components/TaskHistory'

export interface ItemTask {
    id: string
    macro_id: string
    macro_default_id: string
    date_code: string
    date_task: string
    feedback: string
    client_id: string
    label_task: string
}

export const TasksFinish = () => 
{
    const [tasks, setTasks] = useState<Array<ConsultarTasksFinishResponseItem>>([])
    const loading = useRef(true)
    
    useEffect(() => {
        ConsultTasksFinish(macros => setTasks(macros), e => {})      
        loading.current = false  
    }, [])

    const [filter, setFilter] = useState('')
    const filteredItems = tasks.filter((item: any) => {
        let status = false;

        for(const [key] of Object.entries(item)) 
        {
            if(typeof item[key] === "string" && item[key].toLowerCase().includes(filter.toLowerCase()))
            {
                status = true
                break
            }
        }

        return status
    });

    const [confModal, setConfModal] = useState<ConfigModal>({show: false})

    return (
        <div>
            <ModalRight {...confModal}/>
            <Navbar/>
            {
                !loading.current ?
                (
                    <Content title={
                        <div className="title-button">
                            <div className="title">
                                <LinkBackHistory to="/tasks" />
                                <h1 className="app-content-h1-destaque">Tarefas concluídas</h1>
                            </div>
                        </div>
                    }>
                        <div className="my-tasks-active" style={{padding: '30px'}}>
                            <div className="title-button" style={{marginBottom: '20px'}}>
                                <div className="title">
                                    <h2 onClick={() => {}}>Atividades</h2>
                                </div>
                            </div>
                            <div className="thetable__search">
                                <span>Busque o que deseja </span>
                                <input type="search" placeholder="Digite aqui.." onChange={event => setFilter(event.target.value)}/>
                            </div>
                            {
                                filteredItems.map((e,i) => (
                                    e.label !== "" ? (
                                        <React.Fragment key={`${i}`}>
                                            <div className="task-item-finish" onClick={() => {
                                                setConfModal({
                                                    show: true,
                                                    title: (
                                                        <>
                                                            <h2 className="title-modal">{e.label}</h2>
                                                        </>
                                                    ),
                                                    content: <TaskHistory taskProps={e.json_history} />,
                                                    onClose: () => setConfModal({show: false})
                                                })
                                            }}>
                                                <div className="label">{e.label}</div>
                                                <div className="feedback"><i>Feedback sobre a tarefa: </i>{e.feedback}</div>
                                                <div className="date_finish"><span>Finalizado em</span>: {e.date_finish}</div>
                                            </div>
                                        </React.Fragment>
                                    ) : null
                                ))
                            }
                        </div>
                    </Content> 
                ) : <LoadingContent />
            }
        </div>
    )
}
