import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaSignInAlt } from 'react-icons/fa'
import { MdDeleteForever } from 'react-icons/md'
import { useHistory, useParams } from 'react-router-dom'
import { Content } from '../../../components/Content'
import { HomeButton } from '../../../components/HomeButton'
import { ConfigModal, Modal } from '../../../components/Modal'
import { Navbar } from '../../../components/Navbar'
import { NoWithContent } from '../../../components/NoWithContent'
import { ConfirmDelete } from '../../../functions/Modals/Confirm'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../services/ClientDepartments/ClientDepartments'
import { ControllerIndicadorDepartment } from '../../../services/Indicators/ControllerIndicadorDepartment'

export interface IndicadorDepartmentProps {}

export const IndicadorDepartment: React.FC<IndicadorDepartmentProps> = () => 
{
    const [modalConfig] = useState<ConfigModal>({show: false})
    const [list, setList] = useState<Array<FormClientDepartment>>([])
    const loading = useRef(true)
    const history = useHistory()
    const { id } = useParams<{id: string}>()

    useEffect(() => {
        ClientDepartmentsAPI.getAll(setList)

        loading.current = false
    }, [id])

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <HomeButton />
                            <h1 className="app-content-h1-destaque">Departamentos - Indicadores</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Acesse por departamento</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        list.length > 0 ?
                        (
                            list.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link" onClick={() => {
                                                        history.push(`/indicadores/department/${item.id}`, { ...item })
                                                    }}><FaSignInAlt/> {item.name}</div>
                                                    <div className="options">
                                                        <button className="delete-button" onClick={() => {
                                                            ConfirmDelete(() => {
                                                                if(item.id)
                                                                {
                                                                    ControllerIndicadorDepartment.delete(item.id, () => {
                                                                        setList([...list.filter(i => i.id !== item.id)])
                                                                    })
                                                                }
                                                            }, () => null)
                                                        }}><MdDeleteForever/></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : <NoWithContent/>
                    }
                </div>
            </Content>
        </>
    )
}