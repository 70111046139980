import { AxiosResponse } from "axios"
import Request from "../../functions/Request"
import { ChecklistListItem, SubjectResponse } from "./ProtocolInterfaces";

export const ViewSubjects = async (
    onSuccess: ((e: SubjectResponse) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.get(`/protocols/subjects`).then(e => onSuccess(e.data)).catch(onError);

export const ViewSubjectsOpened = async (
    id: string,
    onSuccess: ((e: Array<ChecklistListItem>) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.get(`/protocols/subjects-opened/${id}`).then(e => onSuccess(e.data.response)).catch(onError);


export interface subjectsOpenedProtocols
{
    id: string
    title: string
    client_id: string
    archived: string
}

export const ViewProtocolWithSubjectsOpened = async (
    onSuccess: ((e: Array<subjectsOpenedProtocols>) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.get(`/protocols/with-subjects-opened`).then(e => onSuccess(e.data.response)).catch(onError);
