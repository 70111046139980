import { AxiosResponse } from "axios"
import Request from "../../functions/Request"

export interface FeedbackCollaboratorsItem {
    id: string
    name: string
    cargo: string
    client_id: string
    department_id: string
}

export const ConsultFeedbackCollaborators = async (
    onSuccess: ((e: Array<FeedbackCollaboratorsItem>) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.get(`/calendar/feedbacks-collaborators`).then(response => onSuccess(response.data.response)).catch(onError);
