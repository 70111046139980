import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md';
import Success from '../../../../functions/Modals/Success';
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../../services/ClientDepartments/ClientDepartments';
import { ControllerIndicators, ControllerIndicatorsFormProps, ControllerIndicatorsStructProps } from '../../../../services/Indicators/Indicators';

interface UpdateIndicatorsProps {
    id: string
}

export const UpdateIndicators: React.FC<UpdateIndicatorsProps> = (props) => {
    
    const [form, setForm] = useState<ControllerIndicatorsFormProps>({})
    const [structure, setStructure] = useState<ControllerIndicatorsStructProps>({})
    const [list, setList] = useState<Array<FormClientDepartment>>([])

    useEffect(() => {
        ControllerIndicators.getStructure(e => setStructure(e.structure))
        ClientDepartmentsAPI.getAll(setList)

        ControllerIndicators.getById(props.id, e => {
            if(e.indicator)
            {
                setForm({
                    title: e.indicator.title,
                    type_id: e.indicator.type.id,
                    obs: e.indicator.obs,
                    formula: e.indicator.formula,
                    unit_id: e.indicator.unit.id,
                    polarity_id: e.indicator.polarity.id,
                    frequency_id: e.indicator.frequency.id,
                    id: props.id
                })
            }
        })
    }, [props.id])

    return (
        <>
            <form style={{marginTop: '40px'}} method="post" onSubmit={e => {
                e.preventDefault();

                ControllerIndicators.update(form, e => {
                    Success('Atualizado com sucesso!', () => window.location.reload())
                })
            }}>
                
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Titulo</label>
                    <input type="text" value={form.title}
                    onChange={e => {
                        setForm({...form, title: e.target.value})
                    }} className="styled"/>
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Para que serve</label>
                    <input type="text" value={form.obs}
                    onChange={e => {
                        setForm({...form, obs: e.target.value})
                    }} className="styled"/>
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Fórmula</label>
                    <input type="text" value={form.formula}
                    onChange={e => {
                        setForm({...form, formula: e.target.value})
                    }} className="styled"/>
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Tipo do indicador</label>
                    <select value={form.type_id}
                    onChange={e => {
                        setForm({...form, type_id: e.target.value})
                    }} className="styled">
                        <option value="">Selecione uma opção</option>
                        {
                            structure.types?.map((e, i) => <option key={`${i}`} value={e.id}>{e.title}</option>)
                        }
                    </select>
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Medida</label>
                    <select value={form.unit_id}
                    onChange={e => {
                        setForm({...form, unit_id: e.target.value})
                    }} className="styled">
                        <option value="">Selecione uma opção</option>
                        {
                            structure.units?.map((e, i) => <option key={`${i}`} value={e.id}>{e.title}</option>)
                        }
                    </select>
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Polaridade</label>
                    <select value={form.polarity_id}
                    onChange={e => {
                        setForm({...form, polarity_id: e.target.value})
                    }} className="styled">
                        <option value="">Selecione uma opção</option>
                        {
                            structure.polatiries?.map((e, i) => <option key={`${i}`} value={e.id}>{e.title}</option>)
                        }
                    </select>
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Frequencia</label>
                    <select value={form.frequency_id}
                    onChange={e => {
                        setForm({...form, frequency_id: e.target.value})
                    }} className="styled">
                        <option value="">Selecione uma opção</option>
                        {
                            structure.frequencies?.map((e, i) => <option key={`${i}`} value={e.id}>{e.title}</option>)
                        }
                    </select>
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Frequencia</label>
                    <select value={form.frequency_id}
                    onChange={e => {
                        setForm({...form, frequency_id: e.target.value})
                    }} className="styled">
                        <option value="">Selecione uma opção</option>
                        {
                            structure.frequencies?.map((e, i) => <option key={`${i}`} value={e.id}>{e.title}</option>)
                        }
                    </select>
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Alterar departamento</label>
                    <select value={form.department_id}
                    onChange={e => {
                        setForm({...form, department_id: e.target.value})
                    }} className="styled">
                        <option value="">Selecione uma opção</option>
                        {
                            list?.map((e, i) => <option key={`${i}`} value={e.id}>{e.name}</option>)
                        }
                    </select>
                </div>
                <button style={{marginTop: '20px'}} type="submit" className="btn-styled success"><MdAdd/> Salvar</button>
            </form>
        </>
    )
}