import { AxiosResponse } from "axios";
import Request from "../../functions/Request";

export interface IAtividadesItem {
    label: string,
    id: string
}

export interface UpdateRotinaProps {
    calendar_id?: string,
    contacts?: any
    departments?: any,
    subjects?: any
    tag_id?: string,
    protocol_id?: string,
    label: string
}

export const UpdateTask = async (
    data: FormData,
    success: ((success: Array<IAtividadesItem>) => void),
    error: ((error: AxiosResponse) => void)
) => {
    return Request.post('/calendar/task', data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }).then(e => success(e.data.atividades)).catch(error);
}