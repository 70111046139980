import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import ServerRequest from "../../functions/ServerRequest";

const route = '/users/proccess-feedback-custom/templates-questions'

export interface PDCTemplateQuestion_FormProps {
    question: string
    type_question: string
    template_id: string
}

export interface PDCTemplateQuestion_ItemProps {
    question: string
    type_question: string
    template_id: string
    id: string
}

class PDCTemplateQuestion
{
    async create(
        data: PDCTemplateQuestion_FormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.post(`${route}`, data).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        data: PDCTemplateQuestion_FormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.put(`${route}/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        template_id: string,
        onSuccess: ((success: PDCTemplateQuestion_ItemProps) => void),
    )
    {
        return await ServerRequest.get(`${route}/${template_id}/${id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        template_id: string,
        onSuccess: ((proccesses: Array<PDCTemplateQuestion_ItemProps>) => void),
    )
    {
        return await ServerRequest.get(`${route}/${template_id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }
}

export const PDCTemplateQuestion_API = new PDCTemplateQuestion()