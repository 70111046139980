import { AxiosResponse } from "axios"
import Request from "../../functions/Request"

export interface ISign {
    email: string
    password: string
}

export interface ISignSuccess {
    company_id: string
    email: string
    id: string
    message: string
    name: string
    token: string
    type: string
    actived: string
}

export const Sign = async (
    data: ISign,
    onSuccess: ((e: ISignSuccess) => void),
    onError: ((e: AxiosResponse) => void)
) => Request.post('/sign/client', data).then((success: AxiosResponse) => {
    onSuccess(success.data)
}, onError);