import { AxiosResponse } from "axios"
import Request from "../../functions/Request"

export interface ResponseConsultTagsColors {
    tags_colors: Array<ConsultTagsColorsItem>,
    message: string,
}

export interface ConsultTagsColorsItem {
    id: string,
    color: string
}

export const ConsultTagsColors = async (
    onSuccess: ((e: ResponseConsultTagsColors) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.get('/tags/colors').then(e => onSuccess(e.data)).catch(onError);
