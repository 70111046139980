import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { FeedbacksPFRouteParams } from '..'
import { LoadingContent } from '../../../../../components/LoadingContent'
import Success from '../../../../../functions/Modals/Success'
import { FeedbackHistoryItemProps, FeedbackItemProps, PDCFeedbackAPI } from '../../../../../services/ProccessFeedbackCustom/Feedback'
import { PDCTemplateQuestion_ItemProps } from '../../../../../services/ProccessFeedbackCustom/TemplateQuestion'
import { QuestionResponse } from '../../Feedbacks/components/FormFeedback'
import { PDCTemplate_ItemProps } from '../../../../../services/ProccessFeedbackCustom/Template'

export interface FormDepartmentProps {
    data: FeedbackHistoryItemProps
}

export const FormFeedback: React.FC<FormDepartmentProps> = (props) => 
{
    const [questions, setQuestions] = useState<QuestionResponse[]>([])
    const [templates, setTemplates] = useState<PDCTemplate_ItemProps[]>([])
    
    const [templateId, setTemplateId] = useState('')

    const OptionsComplete = (props: React.SelectHTMLAttributes<HTMLSelectElement>) => {
        return (
            <select {...props}>
                <option>Selecione uma opção</option>
                <option value="1">Sempre</option>
                <option value="2">Frequentemente</option>
                <option value="3">Às vezes</option>
                <option value="4">Raramente</option>
                <option value="5">Nunca</option>
            </select>
        )
    }

    const OptionsYesNo = (props: React.SelectHTMLAttributes<HTMLSelectElement>) => {
        return (
             <select {...props}>
                <option>Selecione uma opção</option>
                <option value="1">Sim</option>
                <option value="2">Não</option>
            </select>
        )
    }

    const OptionsResponse = (props: React.TextareaHTMLAttributes<HTMLTextAreaElement>) => {
        return <textarea rows={15} {...props} />
    }

    useEffect(() => {
        let dados = props.data.data_json
        let questions = JSON.parse(dados.questions)

        setQuestions([...questions])
        setTemplateId(props.data.data_json.template_id)

        PDCFeedbackAPI.getTemplates(setTemplates)
    }, [props.data])

    return (
        <>
            <form className="form-tasks-create" method="post">
                <div className="box-input">
                    <p>Modelo utilizado</p>
                    <select disabled value={templateId}>
                        <option>Selecione uma opção</option>
                        {
                            templates.map((item) => {
                                return <option value={item.id}>{item.name}</option>
                            })
                        }
                    </select>
                </div>
                {
                    questions.map((item, index) => {
                        if(item.type_question == "1"){
                            return (
                                <div className="box-input">
                                    <p>{item.question}</p>
                                    <OptionsComplete disabled value={item.value} />
                                </div>
                            )
                        }else if (item.type_question == "2")
                        {
                            return (
                                <div className="box-input">
                                    <p>{item.question}</p>
                                    <OptionsYesNo disabled value={item.value} />
                                </div>
                            )
                        }else if(item.type_question == "3") {
                            return (
                                <div className="box-input">
                                    <p>{item.question}</p>
                                    <OptionsResponse disabled value={item.value} />
                                </div>
                            )
                        }
                    })
                }
            </form>
        </>
    )
}