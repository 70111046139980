import { AxiosResponse } from "axios";
import Request from "../../functions/Request";

export interface FAQItem {
    id: string
    title: string
    content: string
}

export interface FAQModule {
    id: string
    name: string
}

class FAQ
{
    async getAll(
        module_id: string,
        onSuccess: ((e: Array<FAQItem>) => void),
        onError: ((e: AxiosResponse) => void)
    )
    {
      return await Request.get(`/client-faq/module/${module_id}`).then(e => onSuccess(e.data.faqs)).catch(onError);
    }

    async getAllModules(
        onSuccess: ((e: Array<FAQModule>) => void),
        onError: ((e: AxiosResponse) => void)
    )
    {
      return await Request.get('/client-faq/modules').then(e => onSuccess(e.data.response)).catch(onError);
    }

    async getById(
        id: string,
        onSuccess: ((e: FAQItem) => void),
        onError: ((e: AxiosResponse) => void)
    ) 
    {
        return await Request.get(`/client-faq/${id}`).then(e => onSuccess(e.data.faq)).catch(onError);
    }

    async getModuleById(
        id: string,
        onSuccess: ((e: {
            id: string,
            name: string
        }) => void),
        onError: ((e: AxiosResponse) => void)
    ) 
    {
        return await Request.get(`/client-faq/view/module/${id}`).then(e => onSuccess(e.data.faq)).catch(onError);
    }
}

export const ControllerFAQ = new FAQ()