import React, { useEffect, useRef, useState } from 'react'
import { FaSignInAlt } from 'react-icons/fa'
import { useHistory } from 'react-router'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { Navbar } from '../../../components/Navbar'
import { NoWithContent } from '../../../components/NoWithContent'
import { GroupAPI, GroupItemProps } from '../../../services/Financeiro/Group'

export interface GroupsProps {}

export const Groups: React.FC<GroupsProps> = (props) => 
{
    const [list, setList] = useState<Array<GroupItemProps>>([])
    const loading = useRef(true)

    const sync = () => {
        GroupAPI.getAll(setList)
    }

    const history = useHistory()

    useEffect(() => {
        sync()

        loading.current = false
    }, [])

    return (
        <>
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/" />
                            <h1 className="app-content-h1-destaque">Financeiro: Plano de Contas</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Estrutura DRE</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        list.length > 0 ?
                        (
                            list.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link" onClick={() => {
                                                        history.push(`/financeiro/plano-de-contas/${item.id}`)
                                                    }}>
                                                        <p><FaSignInAlt/> {item.name}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : <NoWithContent/>
                    }
                </div>
            </Content>
        </>
    )
}