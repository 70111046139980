import React, { useEffect, useState } from 'react'
import { useToasts } from 'react-toast-notifications'
import { FolderFormProps, FoldersAPI } from '../../../../services/MyAttachments/Folders'

import 'react-dropzone-uploader/dist/styles.css'
import Dropzone, { IFileWithMeta } from 'react-dropzone-uploader'
import { FilesAPI } from '../../../../services/MyAttachments/Files'

export interface CreateFileProps {
    id?: string
    onChange?: (() => void)
}

export const CreateFile: React.FC<CreateFileProps> = (props) => 
{
    const [form, setForm] = useState<FolderFormProps>({
        name: ''
    })

    useEffect(() => {
        if(props.id)
        {
            FoldersAPI.get(props.id, data => {
                setForm({
                    name: data.name
                })
            })
        }
    }, [props.id])

    const { addToast } = useToasts()

    const handleSubmit = (files: Array<IFileWithMeta>, allFiles: Array<IFileWithMeta>) => {
        let formFiles = new FormData()

        allFiles.forEach(f => formFiles.append('files[]', f.file))

        FilesAPI.insert(formFiles, () => {
            addToast('Arquivos enviados', {appearance: 'success', autoDismiss: true}, () => {
                window.location.reload()
            })
        }, props.id)
    }

    return (
        <>
            <form className="" method="post" onSubmit={event => {
                event.preventDefault()
            }}>
                <br />
                <Dropzone
                    onSubmit={handleSubmit}
                    inputContent="Selecione ou arraste um arquivo até aqui."
                />
            </form>
        </>
    )
}