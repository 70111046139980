import Error from "../../functions/Modals/Error";
import Request from "../../functions/Request"
import { DiagnosticStages } from "../DiagnosticMounted";

export interface DiagnosticGenerateResult {
    status?: Array<{
        label: string
        value: string,
        reference?: string
    }>,
    message: string
}

export interface DiagnosticGenerateResultData {
    status: number
    data: Array<DiagnosticStages>
}

export interface DiagnosticGenerateResultData {
    status: number
    data: Array<DiagnosticStages>
}

export const DiagnosticGenerateResult = async (
    success: ((data: DiagnosticGenerateResult) => void),
    data: DiagnosticGenerateResultData
) => {
    return Request.post('/diagnostic/status', data).then(response => {
        success(response.data)
    }).catch(err => Error('Houve um erro ao importar um diagnostico novo.'));
}

export const DiagnosticUpdateData = async (
    data: DiagnosticGenerateResultData,
    success: ((data: DiagnosticGenerateResult) => void)
) => {
    return Request.post('/diagnostic/status/update-data', data).then(response => {
        success(response.data)
    }).catch(err => Error('Houve um erro ao importar um diagnostico novo.'));
}

export const DiagnosticCreate = async (
    data: DiagnosticGenerateResultData,
    success: ((data: {
        message: string,
        id: string
        points: Array<DiagnosticStages>
    }) => void)
) => {
    return Request.post('/diagnostic/status', data).then(response => {
        success(response.data)
    }).catch(err => Error('Houve um erro ao importar um diagnostico novo.'));
}

export const DiagnosticMount = async (
    id: string,
    success: ((data: {
        points: Array<DiagnosticStages>,
        questions: Array<any>
    }) => void)
) => {
    return Request.get(`/diagnostic/status/${id}`).then(response => {
        success(response.data)
    }).catch(err => Error('Houve um erro ao importar um diagnostico novo.'));
}