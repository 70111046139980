import React, { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { useHistory, useParams } from 'react-router-dom'
import { ButtonBackHistory } from '../../../../components/ButtonBackHistory'
import { Content } from '../../../../components/Content'
import { ConfigModal, Modal } from '../../../../components/Modal'
import { Navbar } from '../../../../components/Navbar'
import { ConfirmDelete } from '../../../../functions/Modals/ConfirmDelete'
import { CanvasSubjectItemProps, CanvasSubjectsAPI } from '../../../../services/PlanejamentoEstrategico/CanvasSubjects'
import { CanvasSubject } from '../components/CanvasSubject'
import { FormCanvasSubject } from '../components/FormCanvasSubject'

export interface CanvasViewProps {}

export const CanvasView: React.FC<CanvasViewProps> = (props) => 
{
    const { folder_id } = useParams<{ folder_id: string }>()    
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [subjects, setSubjects] = useState<CanvasSubjectItemProps[]>([])

    useEffect(() => {
        CanvasSubjectsAPI.getAll(folder_id, setSubjects)
    }, [folder_id])

    const history = useHistory()


    const createSubject = (field_id: number) => {
        setModalConfig({
            show: true,
            content: <FormCanvasSubject field_id={field_id.toString()} folder_id={folder_id} onSuccess={() => {
                CanvasSubjectsAPI.getAll(folder_id, setSubjects)
                setModalConfig({show: false})
            }} />,
            onClose: () => setModalConfig({
                show: false
            }),
            title: <h2>Criar Assunto</h2>
        })
    }

    const onDelete = (item: CanvasSubjectItemProps) => {
        ConfirmDelete(() => {
            if(item.id)
            {
                CanvasSubjectsAPI.delete(item.id, () => {
                    setSubjects([...subjects.filter(i => i.id !== item.id)])
                })
            }
        }, () => null)
    }

    const onEdit = (item: CanvasSubjectItemProps, field_id: number) => {
        setModalConfig({
            show: true,
            content: <FormCanvasSubject field_id={field_id.toString()} id={item.id} folder_id={folder_id} onSuccess={() => {
                CanvasSubjectsAPI.getAll(folder_id, setSubjects)
                setModalConfig({show: false})
            }} />,
            onClose: () => setModalConfig({
                show: false
            }),
            title: <h2>Editar Assunto</h2>
        })
    }

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar /> 
            <Content title={(
                <div className="title-button">
                    <div className="title">
                        <ButtonBackHistory onClick={() => history.goBack() }/>
                        <h1 className="app-content-h1-destaque">Canvas</h1>
                    </div>
                    <div className="options">
                        <button onClick={() => {
                            CanvasSubjectsAPI.pdf(folder_id, (file) => {
                                const href = URL.createObjectURL(file);

                                // create "a" HTML element with href to file & click
                                const link = document.createElement('a');
                                link.href = href;
                                link.setAttribute('download', 'canvas.pdf'); //or any other extension
                                document.body.appendChild(link);
                                link.click();
                            
                                // clean up "a" element & remove ObjectURL
                                document.body.removeChild(link);
                                URL.revokeObjectURL(href);
                            }, err => {
                                console.log(err)
                            })
                        }}>Visualizar em PDF</button>
                    </div>
                </div>
            )}>
                <div className="grid-canvas">
                    <div className="header">
                        <div className="canvas-container">
                            <div className="canvas-title">Parcerias Chaves <span className='btn-plus' onClick={() => createSubject(1)}><FaPlus /></span></div>
                            <div className="subjects">
                                {subjects.filter(item => item.field_id.toString() === "1").map(item => <CanvasSubject onClickDelete={() => onDelete(item)} onClickEdit={() => onEdit(item, 1)} {...item} />)}
                            </div>
                        </div>
                        <div>
                            <div className="canvas-container">
                                <div className="canvas-title">Atividades chaves <span className='btn-plus' onClick={() => createSubject(2)}><FaPlus /></span></div>    
                                <div className="subjects">
                                {subjects.filter(item => item.field_id.toString() === "2").map(item => <CanvasSubject onClickDelete={() => onDelete(item)} onClickEdit={() => onEdit(item, 2)} {...item} />)}
                                </div>
                            </div>    
                            <div className="canvas-container">
                                <div className="canvas-title">Recursos chave <span className='btn-plus' onClick={() => createSubject(3)}><FaPlus /></span></div>    
                                <div className="subjects">
                                {subjects.filter(item => item.field_id.toString() === "3").map(item => <CanvasSubject onClickDelete={() => onDelete(item)} onClickEdit={() => onEdit(item, 3)} {...item} />)}
                                </div>
                            </div>    
                        </div> { /* col-2 */ }
                        <div className="canvas-container">
                            <div className="canvas-title">Oferta de valor <span className='btn-plus' onClick={() => createSubject(4)}><FaPlus /></span></div>
                            <div className="subjects">
                                {subjects.filter(item => item.field_id.toString() === "4").map(item => <CanvasSubject onClickDelete={() => onDelete(item)} onClickEdit={() => onEdit(item, 4)} {...item} />)}
                            </div>
                        </div> { /* col-1 */ }
                        <div>
                            <div className="canvas-container">
                                <div className="canvas-title">Relacionamento <span className='btn-plus' onClick={() => createSubject(5)}><FaPlus /></span></div>    
                                <div className="subjects">
                                {subjects.filter(item => item.field_id.toString() === "5").map(item => <CanvasSubject onClickDelete={() => onDelete(item)} onClickEdit={() => onEdit(item, 5)} {...item} />)}
                                </div>
                            </div>    
                            <div className="canvas-container">
                                <div className="canvas-title">Canais <span className='btn-plus' onClick={() => createSubject(6)}><FaPlus /></span></div>    
                                <div className="subjects">
                                {subjects.filter(item => item.field_id.toString() === "6").map(item => <CanvasSubject onClickDelete={() => onDelete(item)} onClickEdit={() => onEdit(item, 6)} {...item} />)}
                                </div>
                            </div>    
                        </div> { /* col-2 */ }
                        <div className="canvas-container">
                            <div className="canvas-title">Segmentos de Clientes <span className='btn-plus' onClick={() => createSubject(7)}><FaPlus /></span></div>    
                            <div className="subjects">
                                {subjects.filter(item => item.field_id.toString() === "7").map(item => <CanvasSubject onClickDelete={() => onDelete(item)} onClickEdit={() => onEdit(item, 7)} {...item} />)}
                            </div>
                        </div> { /* col-1 */ }
                    </div>
                    <div className="footer">
                        <div className="canvas-container">
                            <div className="canvas-title">Estrutura de custos <span className='btn-plus' onClick={() => createSubject(8)}><FaPlus /></span></div>    
                            <div className="subjects">
                                {subjects.filter(item => item.field_id.toString() === "8").map(item => <CanvasSubject onClickDelete={() => onDelete(item)} onClickEdit={() => onEdit(item, 8)} {...item} />)}
                            </div>
                        </div> { /* col-6 */ }
                        <div className="canvas-container">
                            <div className="canvas-title">Fontes de receita <span className='btn-plus' onClick={() => createSubject(9)}><FaPlus /></span></div>
                            <div className="subjects">
                                {subjects.filter(item => item.field_id.toString() === "9").map(item => <CanvasSubject onClickDelete={() => onDelete(item)} onClickEdit={() => onEdit(item, 9)} {...item} />)}
                            </div>
                        </div> { /* col-6 */ }
                    </div>
               </div>
            </Content>
        </>
    )
}