import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaHistory } from 'react-icons/fa'
import { MdArrowBack, MdModeEdit, MdDeleteForever, MdPageview } from 'react-icons/md'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Content } from '../../../../components/Content'
import { ConfigModal } from '../../../../components/Modal'
import { ModalRight } from '../../../../components/ModalRight'
import { Navbar } from '../../../../components/Navbar'
import { NoWithContent } from '../../../../components/NoWithContent'
import { formatDate } from '../../../../functions/dataFormat'
import { ConfirmDelete } from '../../../../functions/Modals/Confirm'
import { FormFeedback } from './components/FormFeedback'
import { FeedbackHistoryItemProps, PDCFeedbackAPI } from '../../../../services/ProccessFeedbackCustom/Feedback'

export interface PFC_FeedbacksHistoryProps {}

export interface FeedbacksPFRouteParams {
    id: string
}

export const PFC_FeedbacksHistory: React.FC<PFC_FeedbacksHistoryProps> = (props) => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [list, setList] = useState<Array<FeedbackHistoryItemProps>>([])
    const history = useHistory()
    const { id } = useParams<FeedbacksPFRouteParams>()
    const loading = useRef(true)

    useEffect(() => {
        PDCFeedbackAPI.getHistory(id, setList)
        loading.current = false
    }, [id])

    return (
        <>
            <ModalRight {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <button className="history-back" onClick={() => history.goBack()}><MdArrowBack /></button>
                            <h1 className="app-content-h1-destaque">Processo de Feedback: Feedbacks</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Lista de Feedbacks</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        list.length > 0 ?
                        (
                            list.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title">Feedback feito em: {formatDate(item.date_create)}</div>
                                                    <div className="options">
                                                        <button className="update-button" onClick={() => {
                                                            setModalConfig({
                                                                show: true,
                                                                content: <FormFeedback data={item} />,
                                                                onClose: () => setModalConfig({
                                                                    show: false
                                                                }),
                                                                title: <h2>Visualizar feedback antigo</h2>
                                                            })
                                                        }}><MdPageview /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : <NoWithContent/>
                    }
                </div>
            </Content>
        </>
    )
}