import React, { useEffect, useState } from 'react'
import { FaFileDownload, FaFilePdf, FaHistory } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { ConfigModal, Modal } from '../../../components/Modal'
import { Navbar } from '../../../components/Navbar'
import { APP_CONFIG } from '../../../constants'
import { POPController, PopListPdfProps, PopsStructureProps } from '../../../services/POP/POPController'
import { FormPOP } from '../POP/components/FormPOP'
import { Button } from '@material-ui/core'
import Loading from '../../../functions/Modals/Loading'
import Success from '../../../functions/Modals/Success'

export interface AllPOPsProps {}

export const AllPOPs: React.FC<AllPOPsProps> = () => 
{
    const [listPops, setListPops] = useState<PopsStructureProps[]>([])
    const [configModal, setConfigModal] = useState<ConfigModal>({show: false})
    const history = useHistory()

    useEffect(() => {
        POPController.getAllPopsStructure(setListPops)
    }, [])

    const handleGeneratePdf = () => {
        Loading()
        
        let pops : PopListPdfProps[] = []

        if(listPops.length > 0)
        {
            listPops.filter(item => item.pops).forEach((item) => {
                let structure : {
                    department: string,
                    pops: string[]
                } = {
                    department: item.name,
                    pops: []
                }

                item.pops.sort((a, b) => {
                    if(a.index_item && b.index_item)
                    {
                        return a.index_item.toString().localeCompare(b.index_item.toString(), undefined, { numeric: true})
                    }else{
                        return -1
                    }
                }).forEach((item, pop_index) => {
                    if(item.title)
                        structure.pops.push(item.title)
                })

                pops.push(structure)
            })
        }

        POPController.generatePdfListPops({list: pops}, file => {
            const href = URL.createObjectURL(file);
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', `pops.pdf`); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        }, err => {
            console.log(err)
        }).finally(() => Success("Download realizado com sucesso!"))
    }
        
    return (
        <>
            <Navbar/>
            <Modal {...configModal} />
            <Content title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/proccess" />
                            <h1 className="app-content-h1-destaque">Todos Procedimentos Operacionais Padrão</h1>
                        </div>
                        <div className="options">
                            <button onClick={handleGeneratePdf}>Gerar PDF</button>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog without-overflow" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>POP's Já criados</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        listPops.length > 0 ?
                        (
                            listPops.filter(item => item.pops).map((item) => 
                                {
                                    return (
                                        <React.Fragment key={item.id}>
                                            <div className="pop-department">
                                                {item.name}
                                            </div>
                                            {
                                                item.pops ? (
                                                    <>
                                                        {
                                                            item.pops.sort((a, b) => {
                                                                if(a.index_item && b.index_item)
                                                                {
                                                                    return a.index_item.toString().localeCompare(b.index_item.toString(), undefined, { numeric: true})
                                                                }else{
                                                                    return -1
                                                                }
                                                            }).map((item, pop_index) => {
                                                                return (
                                                                    <React.Fragment key={item.id}>
                                                                        <div className="proccess-item">
                                                                            <div className="title-with-button-options">
                                                                                <div className="title link" onClick={() => {
                                                                                    if(item.not_created === true)
                                                                                    {
                                                                                        history.push(`/proccess/${item.microproccess_id}`, {
                                                                                            department_title: item.department_title,
                                                                                            macro_proccess_title: item.macroproccess_title
                                                                                        })
                                                                                    }

                                                                                    setConfigModal({
                                                                                        show: true,
                                                                                        content: <FormPOP onSuccess={() => window.location.reload()} proccess_id={item.proccess_id} />,
                                                                                        onClose: () => setConfigModal({
                                                                                            show: false
                                                                                        }),
                                                                                        title: <h2>Editar Procedimento Operacional</h2>
                                                                                    })
                                                                                }}>{item.title}</div>
                                                                                {
                                                                                    !item.not_created ? (
                                                                                        <div className="options">
                                                                                            {
                                                                                                item.files?.length ? (
                                                                                                    <div className="btn-files">
                                                                                                        <button className="update-button" onClick={() => {
                                                                                                            let toggle_box = document.getElementById(`${item.proccess_id}_files_${pop_index}`)

                                                                                                            if(toggle_box)
                                                                                                            {
                                                                                                                toggle_box.classList.toggle('show')
                                                                                                            }
                                                                                                        }}><FaFileDownload/></button>
                                                                                                        <ul className="files" id={`${item.proccess_id}_files_${pop_index}`}>
                                                                                                            {
                                                                                                                item.files.map((item, index) => {
                                                                                                                    return (
                                                                                                                        <li key={index}><a href={`${process.env.REACT_APP_URL_ATTACHMENT}/${item.path}`} target="_blank" rel="noopener noreferrer" download>{item.title} <FaFileDownload /> </a></li>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </ul>
                                                                                                    </div>
                                                                                                ) : (
                                                                                                    item.url_attachment ? (
                                                                                                        <div className="btn-files">
                                                                                                            <button className="update-button" onClick={() => {
                                                                                                                let toggle_box = document.getElementById(`${item.proccess_id}_files_${pop_index}`)

                                                                                                                if(toggle_box)
                                                                                                                {
                                                                                                                    toggle_box.classList.toggle('show')
                                                                                                                }
                                                                                                            }}><FaFileDownload/></button>
                                                                                                            <ul className="files" id={`${item.proccess_id}_files_${pop_index}`}>
                                                                                                                <li><a href={`${process.env.REACT_APP_URL_ATTACHMENT}/${item.url_attachment}`} target="_blank" rel="noopener noreferrer" download>{item.url_attachment} <FaFileDownload /> </a></li>
                                                                                                            </ul>
                                                                                                        </div>
                                                                                                    ) : null
                                                                                                )                                                        
                                                                                            }
                                                                                            <button className="pdf-button" onClick={() => {
                                                                                                window.open(`${APP_CONFIG.localhost}/client/pop/pdf/${item.id}`, '_blank');
                                                                                            }}><FaFilePdf/></button>

                                                                                            <button className="comum-button" title="Verificar o histórico" onClick={() => {
                                                                                                history.push(`/history-pop/${item.id}`)
                                                                                            }}><FaHistory /></button>
                                                                                        </div>
                                                                                    ) : null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </React.Fragment>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                ) : null
                                            }
                                            <hr />
                                        </React.Fragment>
                                    )
                                }
                            )
                        ) :
                        (
                            <h3>Sem informações para serem mostradas</h3>
                        )
                    }
                </div>
            </Content>
        </>
    )
}