import { AxiosResponse } from "axios";
import Request from "../../functions/Request";

const route = '/client/my-attachments'

export interface FilesItemProps {
    id: string
    folder_id?: string
    path_file: string
    file_name: string
    client_id: string
    responsavel?: string
    date_created?: string
}

class Files
{
    async insert(form: FormData, success: ((response: AxiosResponse) => void), folder?: string)
    {
        if(folder)
        {
            await Request.post(`${route}/file/${folder}`, form, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }).then(response => success(response.data.response));
        }else{
            await Request.post(`${route}/file`, form, {
                headers: {
                    'content-type': 'multipart/form-data'
                }
            }).then(response => success(response.data.response));
        }
    }

    async getAll(success: ((response: Array<FilesItemProps>) => void), folder?: string)
    {
        if(folder)
        {
            return await Request.get(`${route}/files/${folder}`).then(response => success(response.data));
        }else{
            return await Request.get(`${route}/files`).then(response => success(response.data));
        }
    }

    async delete(id: string, success: ((response: AxiosResponse) => void))
    {
        return await Request.delete(`${route}/file/${id}`).then(success);
    }
}

export const FilesAPI = new Files()