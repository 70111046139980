import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FAQItem } from '../../../services/FAQ/ControllerFAQ'
import { NotifySystemAPI } from '../../../services/NotifySystem/NotifySystem'

export interface ListFaqsProps {
    list: Array<FAQItem>
    module_id: string
}

export const ListFaqs: React.FC<ListFaqsProps> = (props) => 
{
    const history = useHistory()
    const [list, setList] = useState<Array<FAQItem>>([])

    useEffect(() => {
        setList(props.list)
    }, [props.list])

    return (
        <>
            <div className="box-input" style={{marginBottom: '20px'}}>
                <p>Busque</p>
                <input type="search" onChange={event => { 
                    let list_search = list.filter(item => item.title.toLowerCase().search(event.target.value.toLowerCase()) >= 0)

                    setList([...list_search])

                    if(event.target.value === "")
                    {
                        setList([...props.list])
                    }
                }} />
            </div>
            {
                list.map(e => {
                    return (
                        <div className="faq-item" onClick={async () => {
                            await NotifySystemAPI.save({information: `Está vizualizando o FAQ na dúvida: ${e.title}`})

                            history.push(`/faq/${props.module_id}/${e.id}`)
                        }}>
                            <p className="title">{e.title}</p>
                        </div>
                    )
                })
            }   
        </>
    )
}
