import React, { useEffect, useState } from 'react'

export interface RevisionDocumentsProps extends React.SelectHTMLAttributes<HTMLSelectElement> {}

export const RevisionDocuments: React.FC<RevisionDocumentsProps> = (props) => 
{

    useEffect(() => {}, [])

    return (
        <>
            <div className="box-input">
                <p>Revisão em</p>
                <select {...props}>
                    <option value="">Selecione uma opção</option>
                    <option value="6">Semanalmente (7-7 dias)</option>
                    <option value="14">Quinzena (15-15 dias)</option>
                    <option value="29">Mensalmente (30-30 dias)</option>
                    <option value="89">Trimestre (90-90 dias)</option>
                    <option value="179">Semestral (180-180 dias)</option>
                    <option value="364">Anualmente (365-365 dias)</option>
                </select>
            </div>
        </>
    )
}