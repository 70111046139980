import React from 'react'
import { FaCheck, FaPlus, FaRedo } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';

export interface SaveFeedbackMyDayInterface {
    addNewItem: (() => void),
    taskId: string,
    list: Array<FeedbackMyDayItemList>,
    setList: ((list: Array<FeedbackMyDayItemList>) => void)
    onLoadingOldSubjects?: (() => void)
}

export interface FeedbackMyDayItemList {
    question_what: string,
    question_who: string,
    question_when: string,
    client_id: string
    task_id: string
    date: string
    status: string
    dateFormatted: string
    id?: string
}

export const FeedbackMyDay: React.FC<SaveFeedbackMyDayInterface> = (props) => 
{
    const {
        list,
        setList,
        addNewItem
    } = props;

    return (
        <>
            <div className="title-toolkit">
                <div className="title">
                    <span>O que você fez ontem? O que fará hoje? E o que está te impedindo para cumprir seus objetivos?</span>
                </div>
                <div className="toolkit">
                    <span title="Carregar assuntos não concluídos? Clique aqui." className="toolkit-button default" onClick={() => {
                        if(props.onLoadingOldSubjects)
                        {
                            props.onLoadingOldSubjects()
                        }
                    }}><FaRedo/></span>
                    <span className="toolkit-button default" onClick={() => addNewItem()}><FaPlus/></span>
                </div>
            </div>
            <>
                {
                    list.map((e, i) => {
                        return (
                            <>
                                <div className="questions-line" key={`${i}`}>
                                    <div className="box-checkbox">
                                        <input checked={e.status === "1" ? false : true} onChange={() => {
                                            let oldlist = list;
                                        
                                            if(oldlist[i].status === "1")
                                            {
                                                oldlist[i].status = "2"
                                            }else if(oldlist[i].status === "2")
                                            {
                                                oldlist[i].status = "1"
                                            }

                                            setList([...oldlist])
                                        }} id={`checkbox_${i}`} type="checkbox" />
                                        <label htmlFor={`checkbox_${i}`} className="checkmark">Concluído <FaCheck /></label>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <p>O quê?</p>
                                            <textarea value={list[i].question_what} onChange={e => {
                                                let old_questions = list;

                                                old_questions[i].question_what = e.target.value;

                                                setList([...old_questions])
                                            }} className="styled"></textarea>
                                        </div>
                                        <div className="col-6">
                                            <p>Quem?</p>
                                            <input type="text" value={list[i].question_who} onChange={e => {
                                                let old_questions = list;

                                                old_questions[i].question_who = e.target.value;

                                                setList([...old_questions])
                                            }} className="styled"/>
                                        </div>
                                        <div className="col-6">
                                            <p>Quando?</p>
                                            <input type="date" value={list[i].question_when} onChange={e => {
                                                let old_questions = list;

                                                old_questions[i].question_when = e.target.value;

                                                setList([...old_questions])
                                            }} className="styled"/>
                                        </div>
                                    </div>
                                    <span  onClick={() => {
                                        if(i === 0)
                                        {
                                            setList([])
                                        }else{
                                            let old_questions = list;

                                            old_questions = list.filter((e, index) => {
                                                return index !== i
                                            })

                                            setList([...old_questions])
                                        }
                                    }} className="btn-remove"><MdDelete /></span>
                                </div>
                            </>
                        )
                    })
                }
            </>
        </>
    )
}
