import DefaultErrorResponse from "../../../functions/DefaultErrorResponse";
import Request from "../../../functions/Request";

const route = '/client/department-mission'

export interface DepartmentMissionFormProps {
    id?: string
    client_id?: string
    department_id: string
    mission: string
}

class DepartmentMission
{
    async insertOrUpdate(
        data: DepartmentMissionFormProps,
        onSuccess: ((success: DepartmentMissionFormProps) => void),
    )
    {
        return await Request.post(`${route}`, data).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async get(
        department_id: string,
        onSuccess: ((success: DepartmentMissionFormProps) => void),
    )
    {
        return await Request.get(`${route}/${department_id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }
}

export const DepartmentMissionAPI = new DepartmentMission()