import React, { useEffect, useRef, useState } from 'react'
import { FaCheck, FaPlus, FaStopwatch, FaUserPlus } from 'react-icons/fa'
import { MdAdd, MdDelete, MdFileDownload } from 'react-icons/md'
import Select, { StylesConfig } from 'react-select'
import { useToasts } from 'react-toast-notifications'
import { APP_CONFIG } from '../../constants'
import Success from '../../functions/Modals/Success'
import { CreateContact } from '../../pages/Contacts/components/CreateContact'
import { FeedbackMyDay, FeedbackMyDayItemList } from '../../pages/Home/components/FeedbackMyDay'
import { CreateProtocol } from '../../pages/Protocol/Components/CreateProtocol'
import { UpdateProtocol } from '../../pages/Protocol/Components/UpdateProtocol'
import { ChecklistListItem } from '../../pages/Tasks/components/Checklist'
import { CloseTask } from '../../services/Calendar/CloseTask'
import { ConsultarTask, TaskProps } from '../../services/Calendar/ConsultarTask'
import { ControllerCalendar } from '../../services/Calendar/ControllerCalendar'
import { ControllerFeedbackMyDay } from '../../services/Calendar/ControllerFeedbackMyDay'
import { removeAllTaskCalendar, removeTaskCalendar } from '../../services/Calendar/removeTaskCalendar'
import { SaveTask } from '../../services/Calendar/SaveTask'
import { UpdateTask } from '../../services/Calendar/UpdateTask'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../services/ClientDepartments/ClientDepartments'
import { ConsultFeedbackCollaborators, FeedbackCollaboratorsItem } from '../../services/Contacts/ConsultFeedbackCollaborators'
import { ConsultMyContacts } from '../../services/Contacts/ConsultMyContacts'
import { ContactItem } from '../../services/Contacts/ContactModule'
import { NotifySystemAPI } from '../../services/NotifySystem/NotifySystem'
import { ProtocolItemPropsV2, ProtocolV2API } from '../../services/ProtocolV2/ProtocolV2'
import { CriarRotina } from '../../services/Rotina/CriarRotina'
import { ConsultMyTags, ConsultMyTagsItem } from '../../services/Tags/ConsultMyTags'
import { TimesheetAPI } from '../../services/V2/Timesheet'
import { LoadingContent } from '../LoadingContent'
import { ConfigModal, Modal } from '../Modal'
import { ChecklistFeedback } from '../Task/components/ChecklistFeedback'
import { Timer } from '../Timer'
import { LinkItem, ListLinks } from './components/ListLinks'
import { TimesheetManual } from './components/TimesheetManual'
import { addHours, format } from 'date-fns'

export interface TaskFormProps {
    id?: string
    type: 'CREATE' | 'UPDATE' | 'SEND_CALENDAR' | 'CLOSE',
    onChange?: ((type: 'CREATE' | 'UPDATE' | 'SEND_CALENDAR' | 'CLOSE' | 'REMOVE' | 'REMOVE_ALL' | 'AUTO_SAVE') => void)
}

export const TaskForm: React.FC<TaskFormProps> = (props) => 
{
    const toast = useToasts()
    const [list, setList] = useState<Array<ChecklistListItem>>([])
    const [feedback, setFeedback] = useState('')
    const [listFiles, setListFiles] = useState<Array<{
        backlog_id: string
        id: string
        name: string
        path: string
    }>>([])
    const [files, setFiles] = useState<any>([])
    
    const repeatDayOptions = [
        { value: '1', label: 'Segunda-feira' },
        { value: '2', label: 'Terça-feira' },
        { value: '3', label: 'Quarta-feira' },
        { value: '4', label: 'Quinta-feira' },
        { value: '5', label: 'Sexta-feira' },
        { value: '6', label: 'Sábado' },
        { value: '7', label: 'Domingo' },
    ]

    const repeatDayAdvanced = [
        { value: '14', label: 'Quinzenal' },
        { value: '29', label: 'Mensal' },
        { value: '59', label: 'Bimestral' },
        { value: '89', label: 'Trimestral' },
        { value: '179', label: 'Semestral' }
    ]

    const typesSaveTasks = [
        {
            label: 'Associar a um dia específico',
            value: 'dia'
        },
        {
            label: 'Repetir por dia da semana',
            value: 'semana'
        },
        {
            label: 'Quinzenal, Mensal, Bimestral, Trimestral, Semestral',
            value: 'avancado'
        },
        {
            label: 'Todos os dias',
            value: 'todos_dias'
        },
        {
            label: 'Segunda a sexta',
            value: 'segunda_sexta'
        }
    ]

    const [task, setTask] = useState<TaskProps>({
        id: '',
        backlog_id: '',
        date: '',
        client_id: '',
        hour: '',
        hour_finish: '',
        protocol_id: '',
        feedback_id: '',
        status: '',
        label: '',
        contacts: [],
        departments: [],
        subjects: [],
        tags: []
    })

    const colourStyles : StylesConfig<any, any> = {
        control: styles => ({ ...styles, backgroundColor: 'white' }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            return {
                ...styles,
                color: data.color
            };
        },
        multiValueLabel: (styles, { data }) => ({
            ...styles,
            color: "#fff",
        }),
        multiValue: (styles, { data }) => {
            return {
                ...styles,
                backgroundColor: data.color,
                color: "#fff"
            };
        },
    }

    const [listTags, setListTags] = useState<Array<ConsultMyTagsItem>>([])
    const [listDepartments, setListDeparments] = useState<Array<FormClientDepartment>>([])
    const [listContacts, setListContacts] = useState<Array<ContactItem>>([])
    const [listCollaborators, setListCollaborators] = useState<Array<FeedbackCollaboratorsItem>>([])
    const [listProtocols, setListProtocols] = useState<Array<ProtocolItemPropsV2>>([])
    const [listFeedbackMyDay, setListFeedbackMyDay] = useState<Array<FeedbackMyDayItemList>>([])
    const [listLinks, setListLinks] = useState<LinkItem[]>([])

    const [feedbackSelected, setFeedbackSelected] = useState<any>({})
    
    // Selects values
    const [contactsSelected, setContactsSelected] = useState<Array<any>>([])
    const [departmentsSelected, setDepartmentsSelected] = useState<Array<any>>([])
    const [protocolSelected, setProtocolSelected] = useState<any>()
    const [tagsSelected, setTagsSelected] = useState<Array<any>>([])

    const [emailNotify, setEmailNotify] = useState<any>({
        value: "1",
        label: "Não notificar"
    })

    const [typeSaveTask, setTypeSaveTask] = useState<'dia' | 'semana' | 'avancado' | ''>('')

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        if(props.type === "CREATE")
        {
            const formData = new FormData();

            if(files)
            {
                for (let i = 0; i < files.length; i++) {
                    formData.append(`files[${i}]`, files[i])
                }
            }

            formData.append('task', JSON.stringify({
                ...task,
                tags: tagsSelected && tagsSelected.length ? tagsSelected.map((e: {value: string}) => {
                    return {
                        id: e.value
                    }
                }) : [],
                contacts: contactsSelected && contactsSelected.length ? contactsSelected.map((e: { value: string }) => {
                    return {
                        id: e.value
                    }
                }) : [],
                departments: departmentsSelected && departmentsSelected.length ? departmentsSelected.map((e: { value: string }) => {
                    return {
                        id: e.value
                    }
                }) : [],
                subjects: list && list.length ? list.map((e: {label: string}) => {
                    return {
                        label: e.label
                    }
                }) : [],
                listLinks,
                send_notify: emailNotify ? emailNotify.value : '',
                protocol_id: protocolSelected ? protocolSelected.value : ''
            }))

            CriarRotina(formData, async (response) =>  {
                await NotifySystemAPI.save({information: 'Criou um nova tarefa'})

                if(props.onChange)
                {
                    props.onChange(props.type)
                }
            }, e => {
                Error(e.data.message)
            })
        }


        if(props.type === "SEND_CALENDAR")
        {
            const formData = new FormData();

            if(files)
            {
                for (let i = 0; i < files.length; i++) {
                    formData.append(`files[${i}]`, files[i])
                }
            }

            formData.append('task', JSON.stringify({
                date: task.date,
                repeat_day: task.repeat_day,
                repeat_day_advanced: task.repeat_day_advanced,
                label: task.label,
                time: task.time,
                time_finish: task.time_finish,
                protocol_id: protocolSelected ? protocolSelected.value : '',
                feedback_id: feedbackSelected ? feedbackSelected.value : '',
                listLinks,
                tags: tagsSelected && tagsSelected.length ? tagsSelected.map((e: {value: string}) => {
                    return {
                        id: e.value
                    }
                }) : [],
                contacts: contactsSelected && contactsSelected.length ? contactsSelected.map((e: { value: string }) => {
                    return {
                        id: e.value
                    }
                }) : [],
                departments: departmentsSelected && departmentsSelected.length ? departmentsSelected.map((e: { value: string }) => {
                    return {
                        id: e.value
                    }
                }) : [],
                subjects: list && list.length ? list.map((e: {label: string}) => {
                    return {
                        label: e.label
                    }
                }) : [],
                calendar_id: props.id,
                send_notify: emailNotify.value
            }))
            
            SaveTask(formData, () => {
                if(props.onChange) 
                {
                    props.onChange('SEND_CALENDAR')
                }
            }, err => Error(err.data.message))
        }
    }

    const [confModal, setConfModal] = useState<ConfigModal>({show: false})
    const loading = useRef(true)
    const [timesheet, setTimesheet] = useState<any>(false)

    useEffect(() => {
        ConsultMyTags(s => setListTags(s.tags), e => {}) 
        ClientDepartmentsAPI.getAll(s => setListDeparments(s))
        ConsultMyContacts(s => setListContacts(s.contacts), e => {})
    
        if(props.id)
        {
            TimesheetAPI.get(props.id, setTimesheet)
        }

        if(props.id && (props.type === "UPDATE" || props.type === "SEND_CALENDAR"))
        {
            ConsultarTask(props.id, data => {
                setTask({
                    ...data,
                    time: data.hour,
                    time_finish: data.hour_finish,
                })

                let subjects = data.subjects.map(e => {
                    return {
                        label: e.title,
                        status: e.status === "1" ? false : true,
                        feedback: e.feedback,
                        id: e.id
                    }
                })
    
                let departments = data.departments.map(e => {
                    return {
                        label: e.label,
                        value: e.department_id
                    }
                })
    
                let contacts = data.contacts.map(e => {
                    return {
                        value: e.id,
                        label: `${e.name} (${e.email})`
                    }
                })
    
                let tags = data.tags ? data.tags.map(e => {
                    return {
                        value: e.tag_id,
                        label: e.label,
                        color: e.color
                    }
                }) : []
    
                ProtocolV2API.getAll(protocols => {
                    setListProtocols(protocols)
                        
                    let search_selected = protocols.filter(protocol => protocol.id === data.protocol_id);
    
                    if(search_selected.length > 0)
                    {
                        setProtocolSelected({value: search_selected[0].id, label: search_selected[0].name})
                    }
                })

                if(props.id && props.type === "UPDATE")
                {
                    if(data.label === "Reunião Diária" || data.label === "Reunião diária")
                    {
                        ControllerFeedbackMyDay.byTaskId(props.id, feedbacks => {
                            let new_list = listFeedbackMyDay;
                
                            feedbacks.map(e => {
                                new_list.push(e)

                                return e;
                            })
                
                            setListFeedbackMyDay([...new_list])
                        }, () => {})
                    }
                }
    
                if(data.label === "Processo de Feedback" || data.label === "Processo de feedback")
                {
                    ConsultFeedbackCollaborators(collaborators => {
                        setListCollaborators(collaborators)
        
                        let search_selected = collaborators.filter(collaborator => collaborator.id === data.feedback_id);
        
                        if(search_selected.length > 0)
                        {
                            setFeedbackSelected({value: search_selected[0].id, label: `${search_selected[0].name} - #${search_selected[0].cargo}`})
                        }
                    }, () => {})
                }
    
                setList(subjects)
                setDepartmentsSelected(departments)
                setContactsSelected(contacts)
                setTagsSelected(tags)
                setListLinks(data.links ? data.links : [])
                setFeedback(data.feedback ?? '')

                loading.current = false
            })
    
            ControllerCalendar.ConsultFiles(props.id, e => setListFiles(e.data.files), console.warn);
        }else{
            ProtocolV2API.getAll(protocols => {
                setListProtocols(protocols)
            })
        }

        
    }, [])

    const toasts = useToasts()

    useEffect(() => {
        let autoSave : any = null;

        if(props.id)
        {
            autoSave = setInterval(() => {
                console.log('SALVANDO AUTOMATICAMENTE')

                const formData = new FormData();
    
                if(files)
                {
                    for (let i = 0; i < files.length; i++) {
                        formData.append(`files[${i}]`, files[i])
                    }
                }
    
                formData.append('task', JSON.stringify({
                    calendar_id: props.id,
                    contacts: contactsSelected.length ? contactsSelected.map((e: { value: string }) => {
                        return {
                            id: e.value
                        }
                    }) : [],
                    departments: departmentsSelected.length ? departmentsSelected.map((e: { value: string }) => {
                        return {
                            id: e.value
                        }
                    }) : [],
                    subjects: list.length ? list.map(e => {
                        return {
                            label: e.label,
                            feedback: e.feedback ?? '',
                            status: e.status ? "2" : "1"
                        }
                    }) : [],
                    tags: tagsSelected.length ? tagsSelected.map((e: {value: string}) => {
                        return {
                            id: e.value
                        }
                    }) : [],
                    listLinks,
                    protocol_id: protocolSelected ? protocolSelected.value : '',
                    feedback_id: feedbackSelected ? feedbackSelected.value : '',
                    label: task.label,
                    date: task.date,
                    hour: task.time,
                    hour_finish: task.time_finish,
                    feedback: feedback
                }))
    
                UpdateTask(formData, () => {}, () => {})
            }, 10000)
        }

        return () => {
            if(props.id)
            {
                if(autoSave)
                {
                    clearInterval(autoSave)
                }
            }
        }
    }, [
        props.id, 
        task, 
        feedback,
        contactsSelected,
        departmentsSelected,
        protocolSelected,
        list
    ])


    return (
        <>
            {
                props.id && loading.current === true ? <LoadingContent /> : (
                    <>
                        <Modal {...confModal} />
                        <div className="title-toolkit fast" style={{marginBottom: '20px', marginTop: '20px'}}>
                            <div className="title">
                                <span>Ações rápidas</span>
                            </div>
                            <div className="toolkit">
                                <span title="Carregar assuntos não concluídos? Clique aqui." className="toolkit-button success" onClick={() => {
                                    setConfModal({
                                        show: true,
                                        content: <CreateProtocol onSuccess={data => {
                                            ProtocolV2API.getAll(protocols => {
                                                setListProtocols(protocols)
                                                setConfModal({show: false})
                                                
                                                setProtocolSelected({
                                                    value: data.id,
                                                    label: `${data.name} #${data.folder_name ?? ''} - ${data.client_name_create}`
                                                })
                                            })
                                        }}  />,
                                        onClose: () => setConfModal({
                                            show: false
                                        })
                                    })
                                }}><FaPlus/><span>Pauta</span></span>
                                {/* <span className="toolkit-button success" onClick={() => {
                                    setConfModal({
                                        show: true,
                                        content: <CreateContact onSuccess={() => {
                                            ConsultMyContacts(s => setListContacts(s.contacts), e => {})
                                            setConfModal({
                                                show: false
                                            })
                                        }}  />,
                                        onClose: () => setConfModal({
                                            show: false
                                        }),
                                        title: <h2 className="title-form-modal">Criar contato</h2>
                                    })
                                }}><FaUserPlus/><span>Contato</span></span> */}
                                <span className={timesheet.status ? 'toolkit-button danger' : 'toolkit-button success'} onClick={async () => {
                                    if(props.id)
                                    {
                                        await TimesheetAPI.change(props.id, response => {
                                            if(response.status === "started")
                                            {
                                                Success("Iniciado")    
                                            }

                                            if(response.status === "stopped")
                                            {
                                                Success("Parado")    
                                            }
                                        })

                                        await TimesheetAPI.get(props.id, setTimesheet)
                                    }
                                }}><FaStopwatch/><span> {timesheet.status ? <Timer callQueuedTime={timesheet.status} /> : 'Timesheet iniciar'}</span></span>
                            </div>
                        </div>
                        <form method="post" onSubmit={onSubmit}>
                            <div className="input-box-v2">
                                <label htmlFor="!#">Título</label>
                                <input className="styled" type="text" onChange={e => setTask({...task, label: e.target.value})} value={task.label} placeholder="Descreva a tarefa" required autoComplete="off" autoFocus={true}/>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="input-box-v2" style={{marginTop: '20px'}}>
                                        <label htmlFor="!#">Departamento(s)</label>
                                        <Select 
                                            value={departmentsSelected} 
                                            isMulti 
                                            placeholder="Selecione" 
                                            classNamePrefix="styled-v2" 
                                            onChange={(e: any) => setDepartmentsSelected(e)} options={
                                                listDepartments.map((e,i) => 
                                                    { 
                                                        return {
                                                            value: e.id,
                                                            label: e.name
                                                        }
                                                    }
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                                {/* <div className="col-12">
                                    <div className="input-box-v2" style={{marginTop: '20px'}}>
                                        <label htmlFor="!#">Participante(s)</label>
                                        <Select classNamePrefix="styled-v2" value={contactsSelected} onChange={(e: any) => setContactsSelected(e)} closeMenuOnSelect={false} placeholder="Selecione" isMulti options={listContacts.map(e => { return {
                                            value: e.id,
                                            label: `${e.name} (${e.email})`
                                        }})}/>
                                    </div>
                                </div> */}
                                
                            </div>
                            <div className="input-box-v2" style={{marginTop: '20px', marginBottom: '20px'}}>
                                <label htmlFor="!#">Etiqueta(s)</label>
                                <Select 
                                    value={tagsSelected} 
                                    isMulti 
                                    styles={colourStyles}
                                    classNamePrefix="styled-v2"
                                    placeholder="Selecione" 
                                    onChange={(e: any) => setTagsSelected(e)} 
                                    options={
                                        listTags.map((e) => 
                                                { 
                                                    return {
                                                        value: e.id,
                                                        label: e.label,
                                                        color: e.color
                                                    }
                                                }
                                            )
                                        }
                                    />
                            </div>
                            {
                                props.type === "UPDATE" ? (
                                    <>
                                        <div className="input-box-v2" style={{marginTop: '20px'}}>
                                            <label htmlFor="!#">Pauta</label>
                                            {
                                                protocolSelected ? (
                                                    <div className="row">
                                                        <div className="col-10">
                                                            <Select classNamePrefix="styled-v2" isClearable={true} value={protocolSelected} onChange={(e: any) => setProtocolSelected(e)} placeholder="Selecione" options={listProtocols.map(e => { return {
                                                                value: e.id,
                                                                label: `${e.name} #${e.folder_name ?? ''} - ${e.client_name_create}`
                                                            }})}/>
                                                        </div>
                                                        <div className="col-auto">
                                                            <button className="btn-styled normal" onClick={() => {
                                                                setConfModal({
                                                                    show: true,
                                                                    content: <UpdateProtocol id={protocolSelected.value} />,
                                                                    onClose: () => setConfModal({show: false})
                                                                })
                                                            }}>
                                                                Editar
                                                            </button>
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <Select classNamePrefix="styled-v2" value={protocolSelected} onChange={(e: any) => setProtocolSelected(e)} placeholder="Selecione" options={listProtocols.map(e => { return {
                                                        value: e.id,
                                                        label: `${e.name} #${e.folder_name ?? ''} - ${e.client_name_create}`
                                                    }})}/>
                                                )
                                            }
                                        </div>
                                    </>
                                ) : null
                            }
                            <hr />
                            { 
                                props.type === "UPDATE" ?
                                (
                                    <div className="row" style={{margin: '20px 0'}}>
                                        <div className="col-12 col-lg-4">
                                            <div className="input-box-v2">
                                                <label htmlFor="!#">Alterar data</label>
                                                <input className="styled" type="date" onChange={e => setTask({...task, date: e.target.value})} value={task.date !== null ? task.date : ""}/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="input-box-v2">
                                                <label htmlFor="!#">Horário de início</label>
                                                <input className="styled" type="time" onChange={e => setTask({...task, time: e.target.value})} value={task.time}/>
                                            </div>
                                        </div>
                                        <div className="col-12 col-lg-4">
                                            <div className="input-box-v2">
                                                <label htmlFor="!#">Horário de fim</label>
                                                <input className="styled" type="time" onChange={e => setTask({...task, time_finish: e.target.value})} value={task.time_finish}/>
                                            </div>
                                        </div>
                                    </div>
                                ) : null
                            }
                            {
                                props.type === "CREATE" || props.type === "SEND_CALENDAR" ?
                                (
                                    <>
                                        <div className="input-box-v2" style={{marginTop: '20px', marginBottom: '5px'}}>
                                            <label htmlFor="!#">Configurar no cronograma</label>
                                            <select className="styled" onChange={(e: any) => {
                                                if(e.target.value === "todos_dias")
                                                {
                                                    setTask({...task, repeat_day: [
                                                        {
                                                            value: "8"
                                                        }
                                                    ]})
                                                }

                                                if(e.target.value === "segunda_sexta")
                                                {
                                                    setTask({...task, repeat_day: [
                                                        {
                                                            value: "9"
                                                        }
                                                    ]})
                                                }
                                                
                                                setTypeSaveTask(e.target.value)
                                            }} value={typeSaveTask} >
                                                <option value="">Selecione uma opção</option>
                                                {
                                                    typesSaveTasks.map(e => 
                                                        { 
                                                            return (
                                                                <option value={e.value}>{e.label}</option>
                                                            )
                                                        }
                                                    )
                                                }
                                            </select>
                                        </div>
                                        <div className="row">
                                            {
                                                typeSaveTask === "dia" || typeSaveTask === "semana" || typeSaveTask === "avancado" ?
                                                (
                                                    <>
                                                        <div className="col-12" style={{marginBottom: '20px'}}>
                                                            {
                                                                contactsSelected && contactsSelected.length ? (
                                                                    <>
                                                                        <div className="input-box-v2" style={{marginTop: '20px'}}>
                                                                            <label htmlFor="!#">Enviar notificações por email</label>
                                                                            <Select classNamePrefix="styled-v2" value={emailNotify} onChange={(e: any) => setEmailNotify(e)} placeholder="Notificar participantes?" options={[
                                                                                {
                                                                                    value: "1",
                                                                                    label: "Não notificar"
                                                                                },
                                                                                {
                                                                                    value: "2",
                                                                                    label: "Notificar"
                                                                                }
                                                                            ]}/>
                                                                        </div>
                                                                    </>
                                                                ) : null
                                                            }
                                                        </div>
                                                        <div className="col-12 col-lg-4">
                                                            {
                                                                // Por dia
                                                                typeSaveTask === "dia" ? (
                                                                    <div className="input-box-v2">
                                                                        <label htmlFor="!#">Associar a um dia</label>
                                                                        <input className="styled" type="date" onChange={e => setTask({...task, date: e.target.value})} value={task.date}/>
                                                                    </div>
                                                                ) : null
                                                            }
                                                            {
                                                                // Por semana
                                                                typeSaveTask === "semana" ? (
                                                                    <div className="input-box-v2">
                                                                        <label htmlFor="!#">Escolha os dias</label>
                                                                        <Select classNamePrefix="styled-v2" onChange={(e: any) => setTask({...task, repeat_day: e})} value={task.repeat_day} isMulti options={repeatDayOptions} placeholder="Selecione"/>
                                                                    </div>
                                                                ) : null
                                                            }
                                                            {
                                                                // Avancado
                                                                typeSaveTask === "avancado" ? (
                                                                    <>
                                                                        <div className="input-box-v2">
                                                                            <label htmlFor="!#">Método</label>
                                                                            <Select classNamePrefix="styled-v2" onChange={(e: any) => setTask({...task, repeat_day_advanced: e})} value={task.repeat_day_advanced} options={repeatDayAdvanced} placeholder="Selecione"/>
                                                                        </div>
                                                                    </>
                                                                ) : null
                                                            }
                                                        </div>
                                                    </>
                                                ) : null
                                            }
                                            {
                                            // Horario
                                                typeSaveTask !== "" ? (
                                                    <>
                                                        {
                                                            typeSaveTask === "avancado" ? (
                                                                <div className="input-box-v2">
                                                                    <label htmlFor="!#">A partir de:</label>
                                                                    <input className="styled" type="date" onChange={e => setTask({...task, date: e.target.value})} value={task.date}/>
                                                                </div>
                                                            ) : null
                                                        }
                                                        <div className="col-12 col-lg-2">
                                                            <div className="input-box-v2">
                                                                <label htmlFor="!#">HR Início:</label>
                                                                <input className="styled" type="time" onChange={e => setTask({...task, time: e.target.value})} value={task.time}/>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-lg-2">
                                                            <div className="input-box-v2">
                                                                <label htmlFor="!#">HR Fim:</label>
                                                                <input className="styled" type="time" onChange={e => setTask({...task, time_finish: e.target.value})} value={task.time_finish}/>
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : null
                                            }
                                            {
                                                typeSaveTask === "dia" ? (
                                                    <>
                                                        <button type="button" className="btn-styled normal"  onClick={() => {
                                                            let status = task?.timesheet_start == "yes";

                                                            setTask({...task, 
                                                                timesheet_start:  status ? "" : 'yes' 
                                                            })

                                                            toast.addToast(status ? "Horário da tarefa removido como horário do timesheet" : "Horário da tarefa aplicado como horário do timesheet");
                                                        }}>
                                                            Timesheet hora da Tarefa { task?.timesheet_start == "yes" ? <FaCheck style={{marginLeft: '5px'}} /> : null }
                                                        </button>
                                                    </>
                                                ) : null
                                            }
                                        </div>
                                    </>
                                ) : null
                            }
                            {
                                props.type === "UPDATE" && props.id ?
                                (
                                    <>
                                        {
                                            task.label === "Reunião diária" ?
                                            (
                                                <>
                                                    <FeedbackMyDay 
                                                        list={listFeedbackMyDay}
                                                        setList={setListFeedbackMyDay}
                                                        taskId={props.id} 
                                                        onLoadingOldSubjects={() => {
                                                            if(props.id)
                                                            {
                                                                ControllerFeedbackMyDay.oldsOpened(props.id, list => {
                                                                    let old_list = listFeedbackMyDay;

                                                                    list = list.filter((novo) => {
                                                                        let findIndex = old_list.filter(item => item.id === novo.id)

                                                                        if(findIndex.length)
                                                                        {
                                                                            return false;
                                                                        }else{
                                                                            return true;
                                                                        }
                                                                    }).map((novo) => {
                                                                        return {
                                                                            ...novo,
                                                                            old_id: novo.id
                                                                        }
                                                                    })

                                                                    setListFeedbackMyDay([...listFeedbackMyDay, ...list])
                                                                })
                                                            }
                                                        }}
                                                        addNewItem={() => {
                                                            setListFeedbackMyDay([
                                                                ...listFeedbackMyDay,
                                                                {
                                                                    question_what: '',
                                                                    question_who: '',
                                                                    question_when: '',
                                                                    client_id: '',
                                                                    task_id: '',
                                                                    date: '',
                                                                    status: "1",
                                                                    dateFormatted: ''
                                                                }
                                                            ])
                                                        }} 
                                                    />
                                                    {
                                                        listFeedbackMyDay.length > 0 ?
                                                        (
                                                            <button type="button" className="btn-styled normal" style={{marginTop: '10px'}} onClick={() => {
                                                                if(props.id)
                                                                {
                                                                    ControllerFeedbackMyDay.create({
                                                                        feedbacks: listFeedbackMyDay,
                                                                        task_id: props.id
                                                                    }, () => {
                                                                        Success('Salvo com sucesso')
                                                                    }, () => {})
                                                                }
                                                            }}>
                                                                Salvar
                                                            </button>
                                                        ) : null
                                                    }
                                                </>
                                            ) : null
                                        }
                                    </>
                                ) : null
                            }
                            {
                                props.type === "UPDATE" && props.id ?
                                (
                                    <>
                                        {/* <ChecklistFeedback calendarId={props.id} list={list} setList={setList}  /> */}
                                        <TimesheetManual fast_timesheet={{
                                            date_default_from: `${task.date} ${task.time}`,
                                            date_default_to: `${task.date} ${task.time_finish}`
                                        }} calendar_id={props.id} />
                                    </>
                                ) : null
                            }
                            {/* {
                                props.type === "UPDATE" || props.type === "SEND_CALENDAR" ?
                                (
                                    <ChecklistFeedback list={list} setList={setList} />
                                ) : null
                            } */}
                            {
                                props.type === "SEND_CALENDAR" || props.type === "UPDATE" ?
                                (
                                    <ListLinks list={listLinks} setList={setListLinks} />
                                ) : null
                            }
                            {
                                props.type === "UPDATE" ? (
                                    <>
                                        <p className="title-box">Anexos</p>
                                        <input type="file" onChange={e => {
                                            let files = e.target.files
                                            
                                            setFiles(files);

                                        }} multiple />
                                    </>
                                ) : null
                            }
                            {
                                props.type === "UPDATE" ? (
                                    <>
                                        <p className="title-box">Anexos já incluídos</p>
                                        {
                                            listFiles.length > 0 ? 
                                            (
                                                listFiles.map((e, i) => {
                                                    let url = e.path.replace('\\', '/');

                                                    return (
                                                        <React.Fragment key={`${i}`}>
                                                            <div className="file-item">
                                                                <p>{e.name}</p>
                                                                <div className="flex">
                                                                    <p onClick={() => {
                                                                        ControllerCalendar.DeleteFile(e.id, () => {
                                                                            setListFiles([...listFiles.filter(item => item.id !== e.id)])
                                                                        }, console.warn)
                                                                    }}><MdDelete/></p>
                                                                    <a href={`${APP_CONFIG.files_root}${url}`} target="_blank" rel="noopener noreferrer"><MdFileDownload/></a>
                                                                </div>
                                                            </div>
                                                        </React.Fragment>
                                                    )
                                                })
                                            ) : null
                                        }
                                    </>
                                ) : null
                            }
                            <br/>
                            {
                                props.type === "CREATE" ? 
                                (
                                    <button className="btn-styled success" style={{marginTop: '20px'}} type="submit"><MdAdd/> Criar tarefa</button>
                                ) : null
                            }
                            {
                                props.type === "SEND_CALENDAR" ? 
                                (
                                    <button className="btn-styled success" style={{marginTop: '20px'}} type="submit"><MdAdd/> Salvar tarefa</button>
                                ) : null
                            }
                            {
                                props.type === "UPDATE" ? 
                                (
                                    <>
                                        <p className="title-box">Concluir tarefa</p>
                                        <form style={{marginTop: '20px'}} onSubmit={e => e.preventDefault()} method="post">
                                            <div className="input-box-v2">
                                                <label htmlFor="!#">Feedback sobre a tarefa:</label>
                                                <input className="styled" placeholder="Preencha aqui seu comentário" type="text" onChange={e => setFeedback(e.target.value)} value={feedback}/>
                                            </div>
                                            <div className="flex" style={{justifyContent: 'space-between', marginTop: '20px'}}>
                                                <div className="flex">
                                                    <button className="btn-styled normal" type="button" style={{marginRight: '10px'}} onClick={() => {

                                                        const formData = new FormData();

                                                        if(files)
                                                        {
                                                            for (let i = 0; i < files.length; i++) {
                                                                formData.append(`files[${i}]`, files[i])
                                                            }
                                                        }

                                                        formData.append('task', JSON.stringify({
                                                            calendar_id: props.id,
                                                            contacts: contactsSelected.length ? contactsSelected.map((e: { value: string }) => {
                                                                return {
                                                                    id: e.value
                                                                }
                                                            }) : [],
                                                            departments: departmentsSelected.length ? departmentsSelected.map((e: { value: string }) => {
                                                                return {
                                                                    id: e.value
                                                                }
                                                            }) : [],
                                                            subjects: list.length ? list.map(e => {
                                                                return {
                                                                    label: e.label,
                                                                    feedback: e.feedback ?? '',
                                                                    status: e.status ? "2" : "1"
                                                                }
                                                            }) : [],
                                                            tags: tagsSelected.length ? tagsSelected.map((e: {value: string}) => {
                                                                return {
                                                                    id: e.value
                                                                }
                                                            }) : [],
                                                            listLinks,
                                                            protocol_id: protocolSelected ? protocolSelected.value : '',
                                                            feedback_id: feedbackSelected ? feedbackSelected.value : '',
                                                            label: task.label,
                                                            date: task.date,
                                                            hour: task.time,
                                                            hour_finish: task.time_finish,
                                                            feedback: feedback
                                                        }))

                                                        UpdateTask(formData, () => {
                                                            if(props.onChange)
                                                            {
                                                                props.onChange('UPDATE')
                                                            }
                                                        }, () => {})
                                                    }}>
                                                        Salvar alterações
                                                    </button>
                                                    <button className="btn-styled success" type="button" onClick={() => {
                                                        CloseTask({
                                                            feedback: feedback,
                                                            label: task.label,
                                                            calendar_id: props.id
                                                        },
                                                        () => {
                                                            if(props.onChange)
                                                            {
                                                                props.onChange('CLOSE')
                                                            }
                                                        },
                                                        () => Error('Houve um erro ao tentar concluir essa tarefa.'))
                                                    }}>
                                                        Concluir tarefa
                                                    </button>
                                                </div>
                                                <div className="flex">
                                                    <button className="btn-styled danger" onClick={() => {
                                                        if(props.id)
                                                        {
                                                            removeTaskCalendar(props.id, () => {
                                                                if(props.onChange)
                                                                {
                                                                    props.onChange('REMOVE')
                                                                }
                                                            }, () => {})
                                                        }
                                                    }} type="button" style={{marginLeft: '10px'}}>
                                                        Apagar
                                                    </button>
                                                    <button className="btn-styled danger" onClick={() => {
                                                        if(props.id)
                                                        {
                                                            removeAllTaskCalendar(props.id, () => {
                                                                if(props.onChange)
                                                                {
                                                                    props.onChange('REMOVE_ALL')
                                                                }
                                                            }, () => {})
                                                        }
                                                    }} type="button" style={{marginLeft: '10px'}}>
                                                        Remover completamente
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </>
                                ) : null
                            }
                    </form>
                    </>
                )
            }    
        </>
    )
}