import React, { useEffect, useState } from 'react'
import { toast, ToastContainer } from 'react-toastify'
import { Content } from '../../components/Content'
import { LinkBackHistory } from '../../components/LinkBackHistory'
import { ConfigModal, Modal } from '../../components/Modal'
import { Navbar } from '../../components/Navbar'
import { ControllerMyFeedbacks, MyFeedbackItem } from '../../services/MyFeedbacks/ControllerMyFeedbacks'
import { ListMyFeedbacks } from './components/ListMyFeedbacks'
import { SendFeedback } from './components/SendFeedback'
import { ViewFeedback } from './components/ViewFeedback'

export const MyFeedbacks = () => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [myFeedbacks, setMyFeedbacks] = useState<Array<MyFeedbackItem>>([])
    
    useEffect(() => {
        // ControllerFAQ.getAll(setFaqs, err => {})
        ControllerMyFeedbacks.getAll(setMyFeedbacks, err => {})
    }, [])

    return (
        <>
            <Modal {...modalConfig}/>
            <Navbar/>
            <Content title={
                <div className="title-button">
                    <div className="title">
                        <LinkBackHistory to="/faq"/>
                        <h1 className="app-content-h1-destaque">Feedbacks enviados</h1>
                    </div>
                    <div className="options">
                        <button onClick={() => {
                            setModalConfig({
                                show: true,
                                content: <SendFeedback onSuccess={() => {
                                    toast.success('Feedback enviado com sucesso!')

                                    ControllerMyFeedbacks.getAll(setMyFeedbacks, err => {})

                                    setModalConfig({show: false})
                                }} />,
                                onClose: () => setModalConfig({
                                    show: false
                                }),
                                title: <h2>Enviar feedback/sugestão</h2>
                            })
                        }}>Novo feedback</button>
                    </div>
                </div>
            }>
                <div className="content-box">
                    <h2 className="title-comment">Últimos feedbacks enviados</h2>
                    <ListMyFeedbacks list={myFeedbacks} onClickItem={e => {
                        setModalConfig({
                            show: true,
                            content: <ViewFeedback id={e.id} />,
                            onClose: () => setModalConfig({
                                show: false
                            }),
                            title: <h2>Enviar feedback/sugestão</h2>
                        })
                    }} />
                </div>
            </Content>  
            <ToastContainer pauseOnFocusLoss={false}  position="bottom-right" pauseOnHover={false} autoClose={1500} />
        </>
    )
}
