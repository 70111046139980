import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/finance/entries'

export interface EntryFormProps {
    month_competence: string
    classification_id: string
    scheme_account_id: string
    description: string
    payment_method_id: string
    value: string
    date: string
    date_finish: string
    project_id: string
}

export interface EntryProps {
    project_id?: string
    month_competence?: string
    classification_id?: string
    scheme_account_id?: string
    description?: string
    payment_method_id?: string
    sum_type?: string
    value?: string
    balance?: string
    date?: string
    date_filter?: string
    date_finish?: string
    balance_total?: string
    id?: string
}

class Entries 
{
    async create(
        data: EntryFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        data: EntryFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async updateDate(
        id: string,
        data: {
            date: string
        },
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/date/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((success: Array<EntryProps>) => void),
    )
    {
        return await Request.get(`${route}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: EntryProps) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }
}

export const EntriesAPI = new Entries()