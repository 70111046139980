import Modal from "../Modal"

const Error = ($text: string) => {
    Modal.fire({
        title: 'Erro!',
        text: $text,
        position: 'center',
        showConfirmButton: false,
        icon: 'error',
        timer: 1500
    })
}

export default Error