import React, { useEffect, useState } from 'react'
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import { calculatemeta } from '../../../../functions/calculateMeta';
import { DREDataTopicProps } from '../../../../services/Financeiro/DRE'

export interface DREItemProps {
    dre: DREDataTopicProps
    is_subtopic?: boolean
    is_topic?: boolean
    block_input?: boolean
}

export const DREItem: React.FC<DREItemProps> = React.memo((props) => 
{
    const { dre } = props;
    const [totalPreview, setTotalPreview] = useState(0)
    const [totalRealized, setTotalRealized] = useState(0)
    const [form, setForm] = useState<DREDataTopicProps>(dre)


    useEffect(() => {
        let calc_total_preview = 0;
        let calc_total_realized = 0;

        for (var [key, value] of Object.entries(dre.year)) {
            calc_total_preview = Number(calc_total_preview) + Number(value.preview);
            calc_total_realized = Number(calc_total_realized) + Number(value.realized);
        }

        setTotalPreview(calc_total_preview);
        setTotalRealized(calc_total_realized);
    }, [dre])

    const renderMonth = (index: number) => {
        switch (index) {
            case 0:
                return 'JANEIRO'
            case 1:
                return 'FEVEREIRO'
            case 2:
                return 'MARÇO'
            case 3:
                return 'ABRIL'
            case 4:
                return 'MAIO'
            case 5:
                return 'JUNHO'
            case 6:
                return 'JULHO'
            case 7:
                return 'AGOSTO'
            case 8:
                return 'SETEMBRO'
            case 9:
                return 'OUTUBRO'
            case 10:
                return 'NOVEMBRO'
            case 11:
                return 'DEZEMBRO'
        }
    }

    const inputsConfigs : CurrencyInputProps = {
        decimalsLimit: 2,
        decimalSeparator: ",",
        decimalScale: 2,
        intlConfig: {
            locale: 'pt-BR', 
            currency: 'BRL'
        }
    }

    const renderComponent = (index: number) => {
        return (
            <div className="topic-detail">
                <p>{renderMonth(index)} <span className="percentage">{calculatemeta(parseFloat(form.year[index].preview), parseFloat(form.year[index].realized))}%</span></p>
                <div className="values">
                    <div className="preview">
                        <p>Previsto</p>
                        <CurrencyInput readOnly={props.block_input} {...inputsConfigs} value={form.year[index].preview} />
                        {
                            dre.show_percentage === true ? (
                                <div className="percentage">{dre.year && dre.year[index].preview_percentage ? `${dre.year[index].preview_percentage}%` : "0 %"} </div>
                            ) : null
                        }
                    </div>
                    <div className="preview">
                        <p>Realizado</p>
                        <CurrencyInput readOnly={props.block_input} {...inputsConfigs} value={dre.year[index].realized} />
                        {
                            dre.show_percentage === true ? (
                                <div className="percentage">{dre.year && dre.year[index].realized_percentage ? `${dre.year[index].realized_percentage}%` : "0 %"} </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="topic">
            <div className={props.is_topic ? "topic-title is-topic" : "topic-title"}><span>{dre.name}</span></div>
            {
                form.year.map((item, index) => renderComponent(index))
            }
            <div className="topic-detail">
                <p>MÉDIA <span className="percentage">ACUMULADA MÊS {calculatemeta((totalPreview/12), (totalRealized/12))}%</span></p>
                <div className="values">
                    <div className="preview">
                        <p>Previsto</p>
                        <CurrencyInput {...inputsConfigs} value={(totalPreview/12).toFixed(2)} />
                    </div>
                    <div className="preview">
                        <p>Realizado</p>
                        <CurrencyInput {...inputsConfigs} value={(totalRealized/12).toFixed(2)} />
                    </div>
                </div>
            </div>
            <div className="topic-detail">
                <p>TOTAL <span className="percentage">{calculatemeta(totalPreview, totalRealized)}%</span></p>
                <div className="values">
                    <div className="preview">
                        <p>Previsto</p>
                        <CurrencyInput {...inputsConfigs} value={totalPreview} />
                    </div>
                    <div className="preview">
                        <p>Realizado</p>
                        <CurrencyInput {...inputsConfigs} value={totalRealized} />
                    </div>
                </div>
            </div>
        </div>
    )
})