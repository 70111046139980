import { AxiosResponse } from "axios";
import Request from "../../functions/Request";

const route = '/calendar'

class CalendarWeek
{
    async consult(
        success: ((success: AxiosResponse) => void),
        error?: ((error: AxiosResponse) => void)
    )
    {
        return Request.get(`${route}/week`).then(success).catch(error);
    }

    async consultPersonalize(
        date: string,
        options: 'last' | 'next' | string,
        success: ((success: AxiosResponse) => void),
        error?: ((error: AxiosResponse) => void)
    )
    {
        return Request.get(`${route}/week/${date}/${options}`).then(success).catch(error);
    }
}

export const ControllerCalendarWeek = new CalendarWeek()
