import React, { useEffect, useState } from 'react'
import { MdAdd } from "react-icons/md";
import Select from 'react-select';
import Success from '../../../../../functions/Modals/Success';
import { ConsultMyContacts } from '../../../../../services/Contacts/ConsultMyContacts';
import { ContactItem } from '../../../../../services/Contacts/ContactModule';
import { CreateUpdateSubject } from '../../../../../services/Protocol/ProtocolInterfaces';
import { SaveSubject } from '../../../../../services/Protocol/SaveSubject';

interface ICreateSubject {}

export const CreateSubject: React.FC<ICreateSubject> = (props) => 
{
    const [data, setData] = useState<CreateUpdateSubject>({
        title: '',
        date_estimate_finish: '',
    })

    const [dataTime, setDataTime] = useState({
        date: '',
        time: ''
    })

    const [listContacts, setListContacts] = useState<Array<ContactItem>>([])
    const [contactsSelected, setcontactsSelected] = useState<Array<any>>([])

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        

        SaveSubject({
            date_estimate_finish: `${dataTime.date} ${dataTime.time}`,
            title: data.title,
            contacts: contactsSelected.map(e => {
                return {
                    id: e.value
                }
            })
        }, s => Success('Assunto criado com sucesso', () => window.location.reload()), err => Error('Houve um erro ao tentar criar este assunto'))
    }

    useEffect(() => {
        ConsultMyContacts(s => setListContacts(s.contacts), e => {})
    }, [])

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                <div className="input-box-task">
                    <label htmlFor="!#">Nome</label>
                    <input className="styled" type="text" onChange={e => setData({...data, title: e.target.value})} value={data.title} placeholder="Descreva a tarefa." required autoComplete="off" autoFocus={true}/>
                </div>
                <h3 className="title-divider">Configure responsáveis pela tarefa</h3>
                <div className="input-box">
                    <p>Escolha abaixo</p>
                    <Select value={contactsSelected} onChange={(e: any) => setcontactsSelected(e)} placeholder="Selecione..." isMulti options={listContacts.map(e => { return {
                        value: e.id,
                        label: `${e.name} (${e.email})`
                    }})}/>
                </div>
                <h3 className="title-divider">Data de encerrar o assunto?</h3>
                <div className="input-box">
                    <p>Data</p>
                    <input type="date" value={dataTime.date}  onChange={e => setDataTime({...dataTime, date: e.target.value})} max="1979-12-31 " className="styled"/>
                    <p>Hora</p>
                    <input type="time" value={dataTime.time}  onChange={e => setDataTime({...dataTime, time: e.target.value})} className="styled"/>
                </div>
                <button type="submit"><MdAdd/> Criar</button>
            </form>
        </>
    )
}
