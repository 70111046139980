import axios from 'axios'
import Cookies from 'universal-cookie'
import { APP_CONFIG } from '../../constants';
import { closeSession } from '../Session';

const Request = axios.create({
    baseURL: APP_CONFIG.localhost,
})  

const cookies = new Cookies()

Request.interceptors.request.use((config) => {
    
    if(APP_CONFIG.tokenHeaderName)
    {
        if(cookies.get(APP_CONFIG.tokenHeaderName))
        {
            config.headers[APP_CONFIG.tokenHeaderName] = cookies.get(APP_CONFIG.tokenHeaderName)
        }
    }

    // Loading('Buscando informações')

    return config
});


Request.interceptors.response.use(response => {
    // Modal.close()

    return response;
}, error => {
    // Modal.close()
    console.log(error.response)
    if(error.response.status === 401)
    {
        closeSession([
            {
                label: "user"
            },
            {
                label: "X-Request-Token"
            },
            {
                label: "X-User-Id"
            },
            {
                label: "X-Request-Type"
            }
        ])

        window.location.href = '/'
    }

    return Promise.reject(error.response)
});

export default Request;