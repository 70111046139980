import { AxiosResponse } from "axios";
import Request from "../../../../functions/Request";
import DefaultErrorResponse from "../../../../functions/DefaultErrorResponse";
import ServerRequest from "../../../../functions/ServerRequest";

const route = '/client/rncs'
const routev2 = '/users/rnc'

export interface RNCSFormProps {
    id?: number
    tipsOccors?: string,
    dataRec?: string,
    nOccors?: string,
    title?: string
    avaliador?: string,
    recPor?: string,
    descOccors?: string,
    resp?: string,
    dataAbert?: string,
    anaCausa?: string,
    abrang?: string,
    respAn?: string,
    dataAn?: string,
    prazoAn?: string,
    histCorrAcc?: string,
    reclConfStatus?: string,
    verrEf?: string,
    commentsAdd?: string,
    respGer?: string,
    dataFe?: string,
    company_id?: number
    date_created?: string
    department_id?: string
    department?: {
        id: string
        name: string
        sigla: string
        company_id: string
    }
}

class RNCsService
{
    async create(
        data: RNCSFormProps,
        onSuccess: ((success: RNCSFormProps) => void),
    )
    {
        return await Request.post(`${route}`, data).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        data: RNCSFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((proccesses: Array<RNCSFormProps>) => void),
    )
    {
        return await Request.get(`${route}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((rnc: RNCSFormProps) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async pdf(
        id: string,
        onSuccess: ((file: Blob) => void),
        onError: ((err: any) => void)
    )
    {
        return await ServerRequest.post(`${routev2}/pdf/${id}`, {}, { responseType: 'blob'}).then(response => onSuccess(response.data)).catch(onError);
    }
}

export const RNCs_api = new RNCsService()