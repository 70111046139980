import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaEye, FaFilePdf, FaHistory } from 'react-icons/fa'
import { MdModeEdit, MdDeleteForever } from 'react-icons/md'
import { useHistory, useParams } from 'react-router-dom'
import { ButtonBackHistory } from '../../../components/ButtonBackHistory'
import { Content } from '../../../components/Content'
import { ConfigModal } from '../../../components/Modal'
import { ModalRight } from '../../../components/ModalRight'
import { Navbar } from '../../../components/Navbar'
import { NoWithContent } from '../../../components/NoWithContent'
import { APP_CONFIG } from '../../../constants'
import { ConfirmDelete } from '../../../functions/Modals/Confirm'
import { getUserToken } from '../../../functions/Session'
import { CargosAPI, DescriptionItemFormProps } from '../../../services/Cargos/Cargos'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../services/ClientDepartments/ClientDepartments'
import { FormDescriptionDescription } from './components/FormDescriptionDescription'

export interface DescriptionApproveProps {}
export interface DescriptionRouteProps {
    id: string
}

export const DescriptionApprove: React.FC<DescriptionApproveProps> = (props) => 
{
    const [list, setList] = useState<Array<DescriptionItemFormProps>>([])
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const { id } = useParams<DescriptionRouteProps>()
    const history = useHistory()
    const loading = useRef(true)

    useEffect(() => {
        CargosAPI.consultDescriptionToApprove(id, setList)

        loading.current = false
    }, [id])

    return (
        <>
            <ModalRight {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={
                <div className="title-button">
                    <div className="title">
                        <ButtonBackHistory onClick={() => {
                            history.push('/pessoas/cargos')
                        }} />
                        <h1 className="app-content-h1-destaque">Aprove estas Descrições de cargos</h1>
                    </div>
                </div>
            }>
                <div className="content-box">
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Lista</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        list.length > 0 ?
                        (
                            list.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link"  onClick={() => {
                                                        setModalConfig({
                                                            show: true,
                                                            content: <FormDescriptionDescription department={id} id={item.id} onSuccess={() => {
                                                                CargosAPI.consultDescriptionToApprove(id, setList)

                                                                setModalConfig({
                                                                    show: false
                                                                })
                                                            }} />,
                                                            onClose: () => setModalConfig({
                                                                show: false
                                                            }),
                                                            title: <h2>Atualizar cargo</h2>
                                                        })
                                                    }}>{item.office}</div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : <NoWithContent/>
                    }
                </div>
            </Content>  
        </>
    )
}