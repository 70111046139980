import React, { useEffect, useState } from 'react'
import { useImmer } from 'use-immer';
import { ChatMessageItemProps } from './typeChat'
import { io, Socket } from 'socket.io-client';
import Cookies from 'universal-cookie';
import { useChat } from '../../hooks/useChat';
import { formatDate } from '../../functions/dataFormat';

export interface ChatbotProps {}

export const Chatbot: React.FC<ChatbotProps> = (props) => 
{
    const [msg, setMsg] = useState("")
    const [messages, setMessages] = useImmer<ChatMessageItemProps[]>([])
    const [socket, setSocket] = useState<Socket|null>()

    const scrollNewMessage = () => {
        let messages_div = document.querySelector('.chat__messages')
    
        if(messages_div)
        {
            messages_div.scrollTo(0, messages_div.scrollHeight)
        }
    }

    useEffect(() => {
        // const cookies = new Cookies()
        // const token = cookies.get('X-Request-Token')

        // const serverChat = io("http://localhost:3001", {
        //     auth: {
        //         token: token
        //     }
        // })

        // if(serverChat)
        // {
        //     serverChat.emit('set_user', {
        //         load_messages: true
        //     })

        //     serverChat.on('receive_history', response => {
        //         setMessages(response.data)

        //         scrollNewMessage()
        //     })
    
        //     serverChat.on('new_message', data => {
        //         setMessages(msgs => {
        //             msgs.push(data)
        //         })

        //         scrollNewMessage()
        //     })
        // }

        // setSocket(serverChat)

        // return () => {
        //     serverChat.disconnect()
        // }
    }, [])

    return (
        <>
            <div className="chat__user">
                <div className="chat__body">
                    <div className="chat__messages">
                        {
                            messages.map((item, index) => {
                                if(item.message_client)
                                {
                                    return (
                                        <div className="chat__message_user" key={`${index}`}>
                                            <div className="chat__message">
                                                <p className="message">{item.message_client}</p>
                                                <div className="date">{formatDate(item.date_create, 'yyyy-MM-dd HH:mm')}</div>
                                            </div>
                                        </div>
                                    )
                                }
                                
                                if(item.message_consultor)
                                {
                                    return (
                                        <div className="chat__message_other" key={`${index}`}>
                                            <div className="chat__message">
                                                <p className="message">{item.message_consultor}</p>
                                                <div className="date">{formatDate(item.date_create)}</div>
                                            </div>
                                        </div>
                                    )
                                }
                            })
                        }
                    </div>
                    <div className="chat__sender">
                        <input onKeyUp={event => {
                            if(event.key === 'Enter')
                            {
                                if(socket)
                                {
                                    socket.emit('send_message', {
                                        message: msg
                                    })
                                }
                                

                                setMsg('')
                            }
                        }} type="text" placeholder="Aperte para Enter para enviar" value={msg} onChange={event => setMsg(event.target.value)} />
                    </div>
                </div>
            </div>
        </>
    )
}