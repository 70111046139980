import React, { useEffect, useState } from 'react'
import { ModalRight } from '../../../components/ModalRight'
import { Navbar } from '../../../components/Navbar'
import { Content } from '../../../components/Content'
import { ButtonBackHistory } from '../../../components/ButtonBackHistory'
import { ConfigModal } from '../../../components/Modal'
import { useHistory } from 'react-router-dom'
import { FaFilePdf, FaPlus } from 'react-icons/fa'
import { FormManualQualidade } from './components/FormManualQualidade'
import { ManualQualidadeFormProps, ManualQualidade_api } from './services/manualqualidade.services'
import { formatDate } from '../../../functions/dataFormat'

export interface ManualQualidadeProps {}

export const ManualQualidade: React.FC<ManualQualidadeProps> = (props) => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({
        show: false
    })

    const [rows, setRows] = useState<ManualQualidadeFormProps[]>([])

    const history = useHistory()

    useEffect(() => {
        ManualQualidade_api.getAll(setRows)
    }, [])

    return (
        <>
            <ModalRight {...modalConfig} />
            <Navbar/>
            <Content title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <ButtonBackHistory onClick={() => history.goBack() }/>
                            <h1 className="app-content-h1-destaque">Manual de Qualidade</h1>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    content: <FormManualQualidade onSuccess={() => window.location.reload()} />,
                                    onClose: () => setModalConfig({
                                        show: false
                                    }),
                                    title: <h2>Manual de Qualidade</h2>
                                })
                            }}>Novo <FaPlus /></button>
                        </div>
                    </div>
                    <p className="path-page"></p>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Já criados</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        rows.length > 0 ?
                        (
                            rows.map((item) => 
                                {
                                    

                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link" onClick={() => {
                                                        setModalConfig({
                                                            show: true,
                                                            content: <FormManualQualidade onSuccess={() => window.location.reload()} id={item.id?.toString() ?? undefined} />,
                                                            onClose: () => setModalConfig({
                                                                show: false
                                                            }),
                                                            title: <h2>Manual de Qualidade</h2>
                                                        })
                                                    }}>Manual de Qualidade:  {item.title} - {formatDate(item.created_date)} - Criado por {item.created_user_name}</div>
                                                    <div className="options">
                                                        <button className="pdf-button" onClick={() => {
                                                            if(item.id)
                                                            {
                                                                ManualQualidade_api.pdf(item.id.toString(), (file) => {
                                                                    const href = URL.createObjectURL(file);
        
                                                                    // create "a" HTML element with href to file & click
                                                                    const link = document.createElement('a');
                                                                    link.href = href;
                                                                    link.setAttribute('download', `${item.title}.pdf`); //or any other extension
                                                                    document.body.appendChild(link);
                                                                    link.click();
        
                                                                    // clean up "a" element & remove ObjectURL
                                                                    document.body.removeChild(link);
                                                                    URL.revokeObjectURL(href);
                                                                }, err => {
                                                                    console.log(err)
                                                                })
                                                            }
                                                        }}><FaFilePdf/></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) :
                        (
                            <h3>Sem informações para serem mostradas</h3>
                        )
                    }
                </div>
            </Content>
        </>
    )
}
