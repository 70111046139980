import React, { useEffect, useState } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'
import { EditorHTML } from '../../../../components/EditorHTML'
import { ManualQualidade_api } from '../services/manualqualidade.services'
import Success from '../../../../functions/Modals/Success'
import { formatDate } from '../../../../functions/dataFormat'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../../services/ClientDepartments/ClientDepartments'

export interface FormManualQualidadeProps {
    id?: string,
    onSuccess?: (() => void)
}

export const FormManualQualidade: React.FC<FormManualQualidadeProps> = (props) => 
{
    const onDelete = () => {
        if(props.id)
        {
            ManualQualidade_api.delete(props.id, () => Success('Apagado com sucesso'))
        }
    }

    const onSubmit = () => {

        let appendedForm = {
            ...form,
            contexto_org: input_contexto_org,
            esc_sis_ges_quali: input_esc_sis_ges_quali,
            part_inters: input_part_inters,
            ap_politicos: input_ap_politicos,
            ap_economicos: input_ap_economicos,
            ap_sociais: input_ap_sociais,
            ap_tecnologias: input_ap_tecnologias,
            ap_ambientais: input_ap_ambientais,
            ap_legais: input_ap_legais,
            camp_abert_novo_topicos: input_camp_abert_novo_topicos,
            objetivos_qualidade: input_objetivos_qualidade,
            politica_qualidade: input_politica_qualidade
        }
        
        if(props.id)
        {
            ManualQualidade_api.update(props.id, appendedForm, () => Success('Atualizado com sucesso', () => window.location.reload()))
        }else{
            ManualQualidade_api.create(appendedForm, () => Success('Criado com sucesso!', () => window.location.reload()))
        }
    }

    const [form, setForm] = useState<any>({
        contexto_org: '',
        esc_sis_ges_quali: '',
        part_inters: '',
        ap_politicos: '',
        ap_economicos: '',
        ap_sociais: '',
        ap_tecnologias: '',
        ap_ambientais: '',
        ap_legais: '',
        camp_abert_novo_topicos: '',
        revision_date: '',
        company_id: '',
        cliente_id: '',
        title: '',
        politica_qualidade: '',
        objetivos_qualidade: ''
    })

    const [input_contexto_org, setInput_contexto_org ] = useState('')
    const [input_politica_qualidade, setInput_politica_qualidade ] = useState('')
    const [input_esc_sis_ges_quali, setInput_esc_sis_ges_quali ] = useState('')
    const [input_part_inters, setInput_part_inters ] = useState('')
    const [input_ap_politicos, setInput_ap_politicos ] = useState('')
    const [input_ap_economicos, setInput_ap_economicos ] = useState('')
    const [input_ap_sociais, setInput_ap_sociais ] = useState('')
    const [input_ap_tecnologias, setInput_ap_tecnologias ] = useState('')
    const [input_ap_ambientais, setInput_ap_ambientais ] = useState('')
    const [input_ap_legais, setInput_ap_legais ] = useState('')
    const [input_camp_abert_novo_topicos, setInput_camp_abert_novo_topicos ] = useState('')
    const [input_objetivos_qualidade, setInput_objetivos_qualidade ] = useState('')

    useEffect(() => {
        if(props.id)
        {
            ManualQualidade_api.getById(props.id,  data => {
                setForm({
                    ...data,
                })

                setInput_contexto_org(data.contexto_org ?? '')
                setInput_esc_sis_ges_quali(data.esc_sis_ges_quali ?? '')
                setInput_part_inters(data.part_inters ?? '')
                setInput_ap_politicos(data.ap_politicos ?? '')
                setInput_ap_economicos(data.ap_economicos ?? '')
                setInput_ap_sociais(data.ap_sociais ?? '')
                setInput_ap_tecnologias(data.ap_tecnologias ?? '')
                setInput_ap_ambientais(data.ap_ambientais ?? '')
                setInput_ap_legais(data.ap_legais ?? '')
                setInput_camp_abert_novo_topicos(data.camp_abert_novo_topicos ?? '')
                setInput_politica_qualidade(data.politica_qualidade ?? '')
                setInput_objetivos_qualidade(data.objetivos_qualidade ?? '')
            })
        }
    }, [props.id])

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={(event) => {
                event.preventDefault()

                onSubmit()
            }}>
                <div className="box-input">
                    <p>Titulo</p>
                    <input type="text" onChange={e => setForm({...form, title: e.target.value})} value={form.title} />
                </div>
                <div className="box-input">
                    <p>Política da qualidade</p>
                    <EditorHTML onChange={value => setInput_politica_qualidade(value)} value={input_politica_qualidade} />
                </div>
                <div className="box-input">
                    <p>Contexto da organização</p>
                    <EditorHTML onChange={value => setInput_contexto_org(value)} value={input_contexto_org} />
                </div>
                <div className="box-input">
                    <p>Escopo do sistema de gestão da qualidade</p>
                    <EditorHTML onChange={value => setInput_esc_sis_ges_quali(value)} value={input_esc_sis_ges_quali} />
                </div>
                <div className="box-input">
                    <p>Partes interessadas</p>
                    <EditorHTML onChange={value => setInput_part_inters(value)} value={input_part_inters} />
                </div>
                <p style={{marginTop: '2rem', marginBottom: '2rem'}}><b>Análise PESTEL (fatores que impactam a empresa)</b></p>
                <div className="box-input">
                    <p>Políticos</p>
                    <EditorHTML onChange={value => setInput_ap_politicos(value)} value={input_ap_politicos} />
                </div>
                <div className="box-input">
                    <p>Econômicos</p>
                    <EditorHTML onChange={value => setInput_ap_economicos(value)} value={input_ap_economicos} />
                </div>
                <div className="box-input">
                    <p>Sociais</p>
                    <EditorHTML onChange={value => setInput_ap_sociais(value)} value={input_ap_sociais} />
                </div>
                <div className="box-input">
                    <p>Tecnológicos</p>
                    <EditorHTML onChange={value => setInput_ap_tecnologias(value)} value={input_ap_tecnologias} />
                </div>
                <div className="box-input">
                    <p>Ambientais</p>
                    <EditorHTML onChange={value => setInput_ap_ambientais(value)} value={input_ap_ambientais} />
                </div>
                <div className="box-input">
                    <p>Legais</p>
                    <EditorHTML onChange={value => setInput_ap_legais(value)} value={input_ap_legais} />
                </div>
                <div className="box-input">
                    <p>Objetivos da qualidade</p>
                    <EditorHTML onChange={value => setInput_objetivos_qualidade(value)} value={input_objetivos_qualidade} />
                </div>
                <div className="box-input">
                    <p>Campo aberto: (Crie quantos itens quiser, para separa os itens, use a flag ## na frente do paragrafo. Exemplo abaixo)</p>
                    <code style={{background: "#F6F7C4", marginTop: '5px', padding: '10px', display: 'flex', borderRadius: '10px', fontSize: '0.9rem'}}>
                        ## Item 2
                        <br />
                        <br />Lorem ipsum dolor sit amet consectetur
                        <br />
                        <br />
                        <br />## Item 3
                        <br />
                        <br />Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    </code>
                    <EditorHTML onChange={value => setInput_camp_abert_novo_topicos(value)} value={input_camp_abert_novo_topicos} />
                </div>
                <div className="box-input">
                    <p>Revisar em</p>
                    <input type="date" onChange={e => setForm({...form, revision_date: e.target.value})} value={form.revision_date} />
                </div>
                <hr />
                <div className="flex between">
                    <button type="submit" style={{marginRight: '10px'}}><MdAdd/> Salvar</button>
                    {
                        props.id ? (
                            <button type="button" onClick={onDelete}><MdDelete/> Apagar</button>
                        ) : null
                    }
                </div>
            </form>
        </>
    )
}