import React, { TextareaHTMLAttributes, useEffect, useRef } from 'react'

export interface TextareaAutoheightProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {}

export const TextareaAutoheight: React.FC<TextareaAutoheightProps> = (props) => 
{
    const textareaRef = useRef<any>(null);

    useEffect(() => {
        if(textareaRef.current)
        {
            textareaRef.current.style.height = "0px";
            const scrollHeight = textareaRef.current.scrollHeight;
            textareaRef.current.style.height = scrollHeight + "px";
        }
    }, [props.value])

    return (
        <>
            <textarea ref={textareaRef} {...props}></textarea>
        </>
    )
}