import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";
import RequestWithoutLoading from "../../functions/RequestWithoutLoading";

const route = '/client/customer-journey'

export interface CustomerJourneyStageForm {
    id?: string
    description: string
    department_id: string
    document: string
    sla: string
}

export interface CustomerJourneyStageItem extends CustomerJourneyStageForm {
    id: string
}

class CustomerJourneyStage
{
    async create(
        data: CustomerJourneyStageForm,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}/stage`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async reorder(
        data: {
            list: Array<CustomerJourneyStageForm>
        },
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await RequestWithoutLoading.post(`${route}/stages/reorder`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        data: CustomerJourneyStageForm,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/stage`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: CustomerJourneyStageItem) => void),
    )
    {
        return await Request.get(`${route}/stage/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/stage/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((CustomerJourneyes: Array<CustomerJourneyStageItem>) => void),
    )
    {
        return await Request.get(`${route}/stages`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }
}

export const ControllerCustomerJourneyStage = new CustomerJourneyStage()