import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaFilePdf, FaHistory } from 'react-icons/fa'
import { MdModeEdit, MdDeleteForever, MdSync, MdContentCopy } from 'react-icons/md'
import { useHistory, useParams } from 'react-router-dom'
import { ButtonBackHistory } from '../../../components/ButtonBackHistory'
import { Content } from '../../../components/Content'
import { ConfigModal } from '../../../components/Modal'
import { ModalRight } from '../../../components/ModalRight'
import { Navbar } from '../../../components/Navbar'
import { NoWithContent } from '../../../components/NoWithContent'
import { APP_CONFIG } from '../../../constants'
import { dataFormat, formatDate } from '../../../functions/dataFormat'
import { ConfirmDelete } from '../../../functions/Modals/Confirm'
import { getUserToken } from '../../../functions/Session'
import { CargosAPI, DescriptionItemFormProps } from '../../../services/Cargos/Cargos'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../services/ClientDepartments/ClientDepartments'
import { FormDescriptionDescription } from './components/FormDescriptionDescription'
import { FormDescriptionChangeDepartment } from './components/FormDescriptionChangeDepartment'

export interface DescriptionProps {}
export interface DescriptionRouteProps {
    id: string
}

export const Description: React.FC<DescriptionProps> = (props) => 
{
    const [list, setList] = useState<Array<DescriptionItemFormProps>>([])
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const { id } = useParams<DescriptionRouteProps>()
    const history = useHistory()
    const loading = useRef(true)
    const [department, setDepartment] = useState<FormClientDepartment>({})

    useEffect(() => {
        CargosAPI.consultDescription(id, setList)
        ClientDepartmentsAPI.getById(id, setDepartment)

        loading.current = false
    }, [id])

    return (
        <>
            <ModalRight {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={
                <div className="title-button">
                    <div className="title">
                        <ButtonBackHistory onClick={() => {
                            history.push('/pessoas/cargos')
                        }} />
                        <h1 className="app-content-h1-destaque">Cargos: {department.name}</h1>
                    </div>
                    <div className="options">
                        <button onClick={() => {
                            setModalConfig({
                                show: true,
                                content: <FormDescriptionDescription department={id} />,
                                onClose: () => setModalConfig({
                                    show: false
                                }),
                                title: <h2>Criar cargo</h2>
                            })
                        }}>Criar cargo</button>
                    </div>
                </div>
            }>
                <div className="content-box">
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Lista de cargos</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        list.length > 0 ?
                        (
                            list.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link"  onClick={() => {
                                                        setModalConfig({
                                                            show: true,
                                                            content: <FormDescriptionDescription department={id} id={item.id} onSuccess={() => {
                                                                CargosAPI.consultDescription(id, setList)

                                                                setModalConfig({
                                                                    show: false
                                                                })
                                                            }} />,
                                                            onClose: () => setModalConfig({
                                                                show: false
                                                            }),
                                                            title: <h2>Atualizar cargo</h2>
                                                        })
                                                    }}>{item.office} - Criado por {item.user_created?.name} {item.revision_date ? ` | Revisar em ${formatDate(item.revision_date, 'dd/MM/yyyy')}` : null} </div>
                                                    <div className="options">
                                                        <button className="pdf-button" onClick={() => {
                                                            let token = getUserToken()

                                                            window.open(`${APP_CONFIG.localhost_v2}/users/statics/jobs-descriptions/descriptions/pdf/${item.id}?token=${token}`, '_blank');
                                                        }}><FaFilePdf/></button>
                                                        <button className="update-button" onClick={() => {
                                                            setModalConfig({
                                                                show: true,
                                                                content: <FormDescriptionDescription department={id} id={item.id} onSuccess={() => {
                                                                    CargosAPI.consultDescription(id, setList)

                                                                    setModalConfig({
                                                                        show: false
                                                                    })
                                                                }} />,
                                                                onClose: () => setModalConfig({
                                                                    show: false
                                                                }),
                                                                title: <h2>Atualizar cargo</h2>
                                                            })
                                                        }}><MdModeEdit/></button>
                                                        <button title='Alterar departamento' className="update-button" onClick={() => {
                                                            setModalConfig({
                                                                show: true,
                                                                content: <FormDescriptionChangeDepartment id={item.id} onSuccess={() => {
                                                                    CargosAPI.consultDescription(id, setList)

                                                                    setModalConfig({
                                                                        show: false
                                                                    })
                                                                }} />,
                                                                onClose: () => setModalConfig({
                                                                    show: false
                                                                }),
                                                                title: <h2>Trocar departamento</h2>
                                                            })
                                                        }}><MdSync/></button>
                                                        <button title='Duplicar descrição de cargo' className="update-button" onClick={() => {
                                                            if(item.id)
                                                            {
                                                                CargosAPI.duplicateDescription(item.id, async function() {
                                                                    loading.current = true
                                                                    
                                                                    CargosAPI.consultDescription(id, setList)
                                                                    ClientDepartmentsAPI.getById(id, setDepartment)
    
                                                                    loading.current = false
                                                                })
                                                            }
                                                        }}><MdContentCopy/></button>
                                                        <button className="comum-button" title="Verificar o histórico" onClick={() => {
                                                            history.push(`/history-descriptions/${item.id}`)
                                                        }}><FaHistory /></button>
                                                        <button className="delete-button" onClick={() => {
                                                            ConfirmDelete(() => {
                                                                if(item.id)
                                                                {
                                                                    CargosAPI.deleteDescription(item.id, () => {
                                                                        setList([...list.filter(i => i.id !== item.id)])
                                                                    })
                                                                }
                                                            }, () => null)
                                                        }}><MdDeleteForever/></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : <NoWithContent/>
                    }
                </div>
            </Content>  
        </>
    )
}