import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ButtonBackHistory } from '../../../components/ButtonBackHistory'
import { Content } from '../../../components/Content'
import { Navbar } from '../../../components/Navbar'
import { ControllerIndicatorDataItemHistoryProps, ControllerIndicators, FormSaveData } from '../../../services/Indicators/Indicators'

export interface HistoryDataProps {}

export const HistoryData: React.FC<HistoryDataProps> = (props) => 
{
    const [list, setList] = useState<Array<ControllerIndicatorDataItemHistoryProps>>([])
    const { id } = useParams<{id: string}>()
    const history = useHistory()

    const [form, setForm] = useState<FormSaveData>({
        datetime: '',
        indicator_id: id,
        value: ''
    })

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        // ControllerIndicators.saveData({
        //     ...form,
        //     indicator_id: id
        // }, s => Success('Valores salvo com sucesso!', () => {
        //     setForm({
        //         datetime: '',
        //         indicator_id: id,
        //         value: ''
        //     })

        //     setList([...list, s.data.response])
        // }))
    }

    useEffect(() => {
        ControllerIndicators.getAllHistoryByIndicator(id, data => setList(data))
    }, [id])

    return (
        <>
            <Navbar/>
            <Content title={
                <div className="title-button">
                    <div className="title">
                        <ButtonBackHistory onClick={() => history.push('/indicadores')} />
                        <h1 className="app-content-h1-destaque">Histórico</h1>
                    </div>
                    <div className="options">
                        <button onClick={() => {
                            history.push('/indicadores')
                        }}>Importar indicador</button>
                    </div>
                </div>
            }>
                <div  className="content-box">
                    <p>Nova entrada</p>
                    <form method="post" onSubmit={onSubmit} className="form-inline">
                        <div className="inputs">
                            <div className="input-box">
                                <p>Data</p>
                                <input type="date" onChange={e => setForm({...form, datetime: e.target.value})} value={form.datetime} />
                            </div>
                            <div className="input-box">
                                <p>Entrada</p>
                                <input step="0.01" type="number" onChange={e => setForm({...form, value: e.target.value})} value={form.value} />
                            </div>
                        </div>
                        <button>Enviar</button>
                    </form>
                    <div className="table-divs-header">
                        <div>Indicador</div>
                        <div>Data</div>
                        <div>Valor</div>
                    </div>
                    {
                        list.map((e, i) => {
                            return (
                                <>
                                    <div className="table-divs-content">
                                        <div>{e.title}</div>
                                        <div>{new Date(e.date).toLocaleString('pt-BR')}</div>
                                        <div>{e.value}</div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </Content>  
            
        </>
    )
}