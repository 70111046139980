import { AxiosResponse } from "axios";
import Request from "../../functions/Request";
import { CardProps, RegisterFormProps } from "../Register/Register";

export interface PaymentSessionProps {
    sessionId: string
}

export interface PaymentBoletoResponseProps {
    paymentLink: string
}

const route = '/payment'

export interface PaymentBoletoProps extends RegisterFormProps {
    plans_id: string
}

export interface PaymentBoletoRegisteredProps {
    plans_id: string
    cpf: string
}

export interface PaymentCreditCardProps extends RegisterFormProps {
    plans_id: string
    card: CardProps,
    address: {
        cep: string,
        bairro: string,
        complemento: string,
        ddd: string,
        localidade: string,
        logradouro: string,
        uf: string,
    }
}

export interface PaymentCreditCardRegisteredProps {
    plans_id: string
    cpf: string
    birthday: string
    card: CardProps,
}

export interface getBrandProps {
    brand: {
        bin: 411111
        config: {
            hasCvv: boolean
            hasDueDate: boolean
            hasPassword: boolean
            securityFieldLength: number
        }
        cvvSize: number
        expirable: boolean
        international: boolean
        name: string
        validationAlgorithm: string
    }
}

export interface createCardTokenProps {
    card:{
        token: string
    }
}

class Payment
{ 
    async startSession(success: ((response: PaymentSessionProps) => void), err: ((response: AxiosResponse) => void))
    {
        return await Request.get(`${route}/start-session`).then(response => success(response.data.response)).catch(err);
    }

    async getPlanConfig(id: string, success: ((response: PaymentSessionProps) => void), err: ((response: AxiosResponse) => void))
    {
        return await Request.get(`${route}/plan-config/${id}`).then(response => success(response.data.response)).catch(err);
    }

    async boleto(form: PaymentBoletoProps, success: ((response: PaymentBoletoResponseProps) => void), err: ((response: AxiosResponse) => void))
    {
        return await Request.post(`${route}/generate-boleto`, form).then(response => success(response.data.response)).catch(err);
    }

    async creditCard(form: PaymentCreditCardProps, success: ((response: PaymentBoletoResponseProps) => void), err: ((response: AxiosResponse) => void))
    {
        return await Request.post(`${route}/generate-credit-card`, form).then(response => success(response.data.response)).catch(err);
    }

    async boletoRegistered(form: PaymentBoletoRegisteredProps, success: ((response: PaymentBoletoResponseProps) => void), err: ((response: AxiosResponse) => void))
    {
        return await Request.post(`${route}/registered-boleto`, form).then(response => success(response.data)).catch(err);
    }

    async boletoRenew(form: PaymentBoletoRegisteredProps, success: ((response: PaymentBoletoResponseProps) => void), err: ((response: AxiosResponse) => void))
    {
        return await Request.post(`${route}/registered-boleto-renew`, form).then(response => success(response.data.response)).catch(err);
    }

    async creditCardRegistered(form: PaymentCreditCardRegisteredProps, success: ((response: PaymentBoletoResponseProps) => void), err: ((response: AxiosResponse) => void))
    {
        return await Request.post(`${route}/registered-credit-card`, form).then(response => success(response.data.response)).catch(err);
    }
}

export const PaymentAPI = new Payment()