import React, { useEffect, useState } from 'react'
import { FaArrowRight } from 'react-icons/fa'
import { ConfigModal, Modal } from '../../../../components/Modal'
import { formatDate } from '../../../../functions/dataFormat'
import { ScrumFormTask } from './ScrumFormTask'
import { ListItemProps, ScrumISOListAPI } from '../../../../services/ScrumISO/ScrumISOLists'

export interface ScrumTasksArchivedProps {
    show?: boolean,
    onChange?: (() => void)
    onClose: (() => void)
}

export const ScrumTasksArchived: React.FC<ScrumTasksArchivedProps> = (props) => 
{
    const [tasks, setTasks] = useState<ListItemProps[]>([])
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})

    useEffect(() => {
        ScrumISOListAPI.findArchiveds(setTasks)
    }, [])

    if(props.show)
    {
        return (
            <>
                <Modal {...modalConfig} />
                <div className="scrum-tasks-archived">
                    <div className="close" onClick={() => props.onClose()}>
                        <FaArrowRight />     
                    </div>
                    <div className="scrum-tasks-archived-title">
                        Tarefas arquivadas    
                    </div>
                    <div className="tasks-archived">
                        {
                            tasks.map((task, index) => {
                                return (
                                    <div className="task-archived" key={index} onClick={() => {
                                        setModalConfig({
                                            show: true,
                                            title: (
                                                <>
                                                    <h2>Tarefa arquivada</h2>
                                                </>
                                            ),
                                            content: <ScrumFormTask 
                                                taskArchived
                                                onChange={type => {
                                                    if(type === "UPDATE")
                                                    {
                                                        if(props.onChange)
                                                        {
                                                            props.onChange()

                                                            ScrumISOListAPI.findArchiveds(setTasks)
                                                        }
                                                    }
                                
                                                    setModalConfig({show: false})
                                                }} 
                                                id={task.id} 
                                            />,
                                            onClose: () => setModalConfig({show: false})
                                        })
                                    }}>
                                        <p className="title">{task.title}</p>    
                                        <p className="date_finish">{task.date_finish ? formatDate(task.date_finish, "dd/MM/yyyy HH:mm") : ""}</p>
                                    </div>
                                )
                            })
                        }    
                    </div>
                </div>
            </>
        )
    }

    return null;
}