import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ButtonBackHistory } from '../../../components/ButtonBackHistory'
import { Content } from '../../../components/Content'
import { ConfigModal, ModalRight } from '../../../components/ModalRight'
import { Navbar } from '../../../components/Navbar'
import { OrganogramaAPI } from '../../../services/Organograma/Organograma'
import { FormOrganograma } from './components/FormOrganograma'
import ReactFlow, { Background, MiniMap } from 'react-flow-renderer';
import { OrganogramaDepartmentAPI } from '../../../services/Organograma/OrganogramaDepartment'
import { useRef } from 'react'

export interface OrganogramaProps {}
export interface FlowItem {
    id: any,
    data: { label: JSX.Element },
    position: {x : number, y: number}
    className?: string
}

export interface OrganogramaRouteProps {
    department: string
}

export const Organograma: React.FC<OrganogramaProps> = (props) => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [title, setTitle] = useState('')
    const history = useHistory()
    const loading = useRef(true)

    const [flowElements, setFlowElements] = useState<Array<any>>([])
    const { department } = useParams<OrganogramaRouteProps>()
    // const handleRLDDChange = (reorderedItems: Array<any>) => {
    //     setList([...reorderedItems])
    //     OrganogramaAPI.reorder({list: reorderedItems}, () => {})
    // }

    const getRandomColor = () => {
        var letters = '0123456789ABCDEF';
        var color = '#';
        for (var i = 0; i < 6; i++) {
          color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
      }

    const render = () => {
        OrganogramaAPI.consult(department, (list) => {

            let estrategica = 0;
            let tatica = 200;
            let operacional = 400;

            let x_estrategica = 20;
            let x_tatica = 20;
            let x_operacional = 20;

            var itens: Array<FlowItem> = []

            // Itens ajustados em 3 colunas
            itens = list.map((e, i) => {
                let data = {
                    id: e.id,
                    data: { label: <div>
                        <p>{e.position}</p>
                        <p>{e.cargo}</p>
                    </div> },
                    position: {x : 0, y: 0},
                    className: ""
                }

                if(e.graphic_position)
                {
                    let positions = JSON.parse(e.graphic_position)

                    data.position = {
                        x: positions.x,
                        y: positions.y,
                    }
                }

                if(e.position === "Estratégica")
                {
                    data.className = "border-estrategica"

                    if(!e.graphic_position)
                    {
                        data.position = {
                            x : list.findIndex((e, i) => e.position === "Estratégica") === i ? 0 : x_estrategica, 
                            y: estrategica
                        }
                    }
                    
                    x_estrategica += 180;
                }else if(e.position === "Tática")
                {
                    data.className = "border-tatica"

                    if(!e.graphic_position)
                    {
                        data.position = {
                            x : list.findIndex((e, i) => e.position === "Tática") === i ? 0 : x_tatica, 
                            y: tatica
                        }
                    }

                    x_tatica += 180;
                }else if(e.position === "Operacional")
                {
                    data.className = "border-operacional"

                    if(!e.graphic_position)
                    {
                        data.position = {
                            x : list.findIndex((e, i) => e.position === "Operacional") === i ? 0 : x_operacional, 
                            y: operacional
                        }
                    }

                    x_operacional += 180;
                }

                return {...data}
            })

            let edges = list.map((edge, i) => {
                return { 
                    id: `e${edge.id}-${edge.ref_id}`, 
                    source: `${edge.id}`, 
                    target: `${edge.ref_id}`,
                    style: { stroke: getRandomColor() }
                }
            })

            setFlowElements([
                ...itens,
                ...edges
            ])
        })
    }

    useEffect(() => {
        OrganogramaAPI.consult(department, (list) => {

            let estrategica = 0;
            let tatica = 200;
            let operacional = 400;

            let x_estrategica = 20;
            let x_tatica = 20;
            let x_operacional = 20;

            var itens: Array<FlowItem> = []

            // Itens ajustados em 3 colunas
            itens = list.map((e, i) => {
                let data = {
                    id: e.id,
                    data: { label: <div>
                        <p>{e.position}</p>
                        <p>{e.cargo}</p>
                    </div> },
                    position: {x : 0, y: 0},
                    className: ""
                }

                if(e.graphic_position)
                {
                    let positions = JSON.parse(e.graphic_position)

                    data.position = {
                        x: positions.x,
                        y: positions.y,
                    }
                }

                if(e.position === "Estratégica")
                {
                    data.className = "border-estrategica"

                    if(!e.graphic_position)
                    {
                        data.position = {
                            x : list.findIndex((e, i) => e.position === "Estratégica") === i ? 0 : x_estrategica, 
                            y: estrategica
                        }
                    }
                    
                    x_estrategica += 180;
                }else if(e.position === "Tática")
                {
                    data.className = "border-tatica"

                    if(!e.graphic_position)
                    {
                        data.position = {
                            x : list.findIndex((e, i) => e.position === "Tática") === i ? 0 : x_tatica, 
                            y: tatica
                        }
                    }

                    x_tatica += 180;
                }else if(e.position === "Operacional")
                {
                    data.className = "border-operacional"

                    if(!e.graphic_position)
                    {
                        data.position = {
                            x : list.findIndex((e, i) => e.position === "Operacional") === i ? 0 : x_operacional, 
                            y: operacional
                        }
                    }

                    x_operacional += 180;
                }

                return {...data}
            })

            let edges = list.map((edge, i) => {
                return { 
                    id: `e${edge.id}-${edge.ref_id}`, 
                    source: `${edge.id}`, 
                    target: `${edge.ref_id}`,
                    style: { stroke: getRandomColor() }
                }
            })

            setFlowElements([
                ...itens,
                ...edges
            ])
        })

        OrganogramaDepartmentAPI.getName(department, name => setTitle(name.name))

        loading.current = false
    }, [department])

    return (
        <>
            <ModalRight {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={
                <div className="title-button">
                    <div className="title">
                        <ButtonBackHistory onClick={() => {
                            history.push('/pessoas/organograma')
                        }} />
                        <h1 className="app-content-h1-destaque">Organograma do colaborador: {title}</h1>
                    </div>
                    <div className="options">
                        <button onClick={() => {
                            setModalConfig({
                                show: true,
                                content: <FormOrganograma onSuccess={() => {
                                    render()
                                }} />,
                                onClose: () => setModalConfig({
                                    show: false
                                }),
                                title: <h2>Nova função</h2>
                            })
                        }}>Nova função</button>
                    </div>
                </div>
            }>
                <div className="content-box">
                    <div className="organograma">
                        <ReactFlow elementsSelectable={false}   nodesConnectable={false} onElementClick={(item, element) => {
                            if(element.id && element.type === "default")
                            {
                                setModalConfig({
                                    show: true,
                                    content: <FormOrganograma id={element.id} onSuccess={() => {
                                        render()
                                    }} />,
                                    onClose: () => setModalConfig({
                                        show: false
                                    }),
                                    title: <h2>Nova linha</h2>
                                })
                            }
                        }} onNodeDragStop={(event, data) => {
                            OrganogramaAPI.changeGraphicPosition(data.id, {
                                position: JSON.stringify(data.position)
                            }, () => {})
                        }} elements={flowElements}>
                            <Background
                                gap={12}
                                size={1}
                            />
                            <MiniMap
                                nodeColor={(node) => {
                                    switch (node.type) {
                                    case 'input':
                                        return 'red';
                                    case 'default':
                                        return '#00ff00';
                                    case 'output':
                                        return 'rgb(0,0,255)';
                                    default:
                                        return '#eee';
                                    }
                                }}
                                nodeStrokeWidth={3}
                                />

                        </ReactFlow>
                    </div>
                </div>
            </Content>  
        </>
    )
}