import React, { useEffect, useState } from 'react'
import { FaDownload, FaFileDownload, FaPlus, FaTrash } from 'react-icons/fa'
import { MdAdd, MdDelete } from 'react-icons/md'
import { EditorHTML } from '../../../../components/EditorHTML'
import { APP_CONFIG } from '../../../../constants'
import { ConfirmDelete } from '../../../../functions/Modals/Confirm'
import Success from '../../../../functions/Modals/Success'
import Warning from '../../../../functions/Modals/Warning'
import { AttachmentPopController, PopFileProps } from '../../../../services/Attachment/AttachmentPopController'
import { POPController, POPForm } from '../../../../services/POP/POPController'
import { UserForm, UsersController } from '../../../../services/Users/UsersController'

export interface ChangeStatusPOPFormProps {
    id?: string,
    proccess_id: string,
    onSuccess?: (() => void)
}

export const ChangeStatusPOPForm: React.FC<ChangeStatusPOPFormProps> = (props) => 
{
    const [form, setForm] = useState<{status: string}>({
        status: ''
    })
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        POPController.changeStatusPOP({
            status: form.status,
            proccess_id: props.proccess_id
        }, () => {
            Success('Status alterado', () => window.location.reload())
        })
    }

    useEffect(() => {
        if(props.proccess_id)
        {

            POPController.getByProccess(props.proccess_id, data => {
                if(data)
                {
                    setForm({
                        status: data.approved_status ??  ''
                    })

                }
            })
        }
    }, [props.id, props.proccess_id])

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                <div className="box-input">
                    <p>Alterar status</p>
                    <select onChange={e => setForm({...form, status: e.target.value})} value={form.status}>
                        <option value="">Selecione uma opção</option>
                        <option value="open">Aberto</option>
                        <option value="approved">Aprovado</option>
                        <option value="refused">Recusado</option>
                    </select>
                </div>
                <div className="flex between">
                    <button type="submit" style={{marginRight: '10px'}}><MdAdd/> Alterar status</button>
                </div>
            </form>
        </>
    )
}