import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import { FaFileExport, FaPlus } from 'react-icons/fa'
import { useToasts } from 'react-toast-notifications'
import { Content } from '../../../components/Content'
import { HomeButton } from '../../../components/HomeButton'
import { ConfigModal, ModalRight } from '../../../components/ModalRight'
import { Navbar } from '../../../components/Navbar'
import { APP_CONFIG } from '../../../constants'
import { formatDate } from '../../../functions/dataFormat'
import { money } from '../../../functions/Format'
import { localization } from '../../../functions/localization'
import { cookies } from '../../../functions/Session'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../services/ClientDepartments/ClientDepartments'
import { PlanActionAPI, PlanActionItemProps } from '../../../services/Resultado/PlanAction/PlanAction'
import { FormPlanAction } from './components/FormPlanAction'

export interface PlanActionProps {}

export const PlanAction: React.FC<PlanActionProps> = (props) => 
{
    const [confModal, setConfModal] = useState<ConfigModal>({show: false})
    const [lines, setLines] = useState<PlanActionItemProps[]>([])
    const [departments, setDepartments] = useState<FormClientDepartment[]>([])
    const [departmentFilter, setDepartmentFilter] = useState("")

    useEffect(() => {
        PlanActionAPI.getAll(setLines)
        ClientDepartmentsAPI.getAll(data => setDepartments(data))
    }, [])

    const filtredList = lines.filter(item => departmentFilter !== "" ? item.department_id === departmentFilter : true)

    const { addToast } = useToasts()

    return (
        <>
            <ModalRight {...confModal} />
            <Navbar/>
            <Content title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <HomeButton />
                            <h1 className="app-content-h1-destaque">Plano de ação</h1>
                        </div>
                        <div className="options">
                            <button title="Nova pasta" className="bkg-theme clr-white" onClick={() => {
                                setConfModal({
                                    show: true,
                                    title: <h2 className="title-form-modal">Nova entrada</h2>,
                                    content: <FormPlanAction onChange={type => {
                                        let message = ''

                                        switch (type) {
                                            case 'CREATE':
                                                message = 'Criado com sucesso'
                                                break;
                                            case 'DELETE':
                                                message = 'Apagado com sucesso'
                                                break;
                                            case 'UPDATE':
                                                message = 'Atualizado com sucesso'
                                                break;
                                            default:
                                                break;
                                        }

                                        addToast(message, { autoDismiss: true, appearance: 'success' })
                                        setConfModal({show: false})
                                        PlanActionAPI.getAll(setLines)
                                    }} />,
                                    onClose: () => setConfModal({show: false})
                                })
                            }}><FaPlus/></button>
                            <button className="btn" onClick={() => {
                                const token = cookies.get('X-Request-Token')

                                window.open(`${APP_CONFIG.localhost}/client/action-plan/csv?token=${token}`);
                            }}><FaFileExport /> Exportar</button>
                        </div>
                    </div>
                </>
            )}>
                <div  className="body-content" style={{marginTop: '20px'}}>
                    <div className="title">
                        <h2>Meu plano de ação</h2>
                    </div>
                    <div className="input-box-v2">
                        <label htmlFor="">Escolha um departamento</label>
                        <select onChange={event => setDepartmentFilter(event.target.value)}>
                            <option value="">Selecione</option>
                            {departments.map((item, key) => <option value={item.id}>{item.name}</option>)}
                        </select>
                    </div>
                    <br />
                    <div style={{width: '100%', overflow: 'auto', height: 'max-content'}}>

                    <MaterialTable 
                        title=""
                        localization={localization}
                        style={{width: '150rem'}}
                        options={{
                            
                            headerStyle: {
                                background: '#2D46B9',
                                color: "#fff",
                                position: 'sticky', top: 0
                            },
                            maxBodyHeight: '400px',
                            pageSize: 30,
                            filtering: true,
                            searchFieldAlignment: 'left',
                            rowStyle: rowData => {
                                // let status_message = ''
                                let status_color = ''

                                switch (rowData.status) {
                                    case 'START':
                                        // status_message = 'A iniciar'
                                        status_color = '#A3F3EB'
                                        break;
                                    case 'START_YELLOW':
                                        // status_message = 'Iniciou atrasado'
                                        status_color = '#FFC93C'
                                        break;
                                    case 'START_LATE':
                                        // status_message = 'Atrasado'
                                        status_color = '#F05454'
                                        break;
                                    case 'LATE': 
                                        // status_message = 'Atrasado'
                                        status_color = '#F05454'
                                        break;
                                    case 'IN_PROCCESS': 
                                        // status_message = 'Em andamento'
                                        status_color = '#12CAD6'
                                        break;
                                    case 'IN_PROCESS_YELLOW':
                                        // status_message = 'Em andamento'
                                        status_color = '#FFC93C'
                                        break;
                                    case 'SUCCESS': 
                                        // status_message = 'Concluido'
                                        status_color = '#A2DE96'
                                        break;
                                    default:
                                        break;
                                }

                                return {
                                    borderBottom: `5px solid ${status_color}`,
                                    overflowWrap: 'break-word',
                                    background: '#f1f1f1'
                                }
                            }
                        }}
                        onRowClick={(event, rowData: any) => {
                            setConfModal({
                                show: true,
                                title: <h2 className="title-form-modal">Nova entrada</h2>,
                                content: <FormPlanAction id={rowData.id} onChange={type => {
                                    let message = ''

                                    switch (type) {
                                        case 'CREATE':
                                            message = 'Criado com sucesso'
                                            break;
                                        case 'DELETE':
                                            message = 'Apagado com sucesso'
                                            break;
                                        case 'UPDATE':
                                            message = 'Atualizado com sucesso'
                                            break;
                                        default:
                                            break;
                                    }

                                    addToast(message, { autoDismiss: true, appearance: 'success' })
                                    setConfModal({show: false})

                                    PlanActionAPI.getAll(setLines)
                                }} />,
                                onClose: () => setConfModal({show: false})
                            })
                        }}
                        data={filtredList}
                        columns={[
                            {
                                title: 'Problema Priorizado',
                                field: 'problem',
                                width:  350,
                            },
                            {
                                title: 'O Que Fazer?',
                                field: 'to_do',
                                width: 350
                            },
                            {
                                title: 'Como Fazer?',
                                field: 'to_make',
                                width: 350
                            },
                            {
                                title: 'Quem?',
                                field: 'to_who'
                            },
                            {
                                title: 'Porque Fazer?',
                                field: 'to_why_do',
                                width: 350
                            },
                            {
                                title: 'Quanto?',
                                field: 'to_much',
                                filtering: false,
                                render: rowData => money(rowData.to_much)
                            },
                            {
                                title: 'Início (Previsto)',
                                type: 'date',
                                field: 'espected_start',
                                filtering: false,
                                render: rowData => formatDate(rowData.espected_start)
                            },
                            {
                                title: 'Fim (Previsto)',
                                type: 'date',
                                field: 'expected_end',
                                filtering: false,
                                render: rowData => formatDate(rowData.expected_end)
                            },
                            {
                                title: 'Início (Realizado)',
                                type: 'date',
                                field: 'real_start',
                                filtering: false,
                                render: rowData => formatDate(rowData.real_start)
                            },
                            {
                                title: 'Fim (Realizado)',
                                type: 'date',
                                field: 'real_end',
                                filtering: false,
                                render: rowData => formatDate(rowData.real_end)
                            },
                            {
                                title: 'Status',
                                field: 'status',
                                lookup: {
                                    START: 'A iniciar',
                                    START_LATE: 'Atrasado',
                                    START_YELLOW: 'Inicio atrasado',
                                    LATE: 'Atrasado',
                                    IN_PROCCESS: 'Em andamento',
                                    IN_PROCESS_YELLOW: 'Em andamento',
                                    SUCCESS: 'Concluido',
                                }
                            }
                        ]}
                    />
                    </div>
                </div>
                
            </Content>
        </>
    )
}