import React, { useEffect, useState } from 'react'

export interface TabProps {
    title: JSX.Element | string
    path: string
    content?: JSX.Element
    actived?: boolean
}

export interface TabsProps {
    options: TabProps[]
    onChangePath: ((path: string) => void)
    path: string
}

export const Tabs: React.FC<TabsProps> = (props) => 
{
    const [tabIndex, setTabIndex] = useState(0)

    useEffect(() => {
        const index = props.options.findIndex(item => item.path === props.path)

        if(index >= 0)
        {
            setTabIndex(index)
        }
    }, [props.path])

    return (
        <>
            <div className="content__tabs">
                {
                    props.options.filter(item => item.actived !== false).map((item, index) => {
                        return (
                            <div onClick={() => {
                                props.onChangePath(item.path)
                            }} className={tabIndex === index ? "content__tab actived" : "content__tab"} style={tabIndex === index ? { borderBottom: '4px solid #ec6518' } : {}}>
                                {item.title}
                            </div>
                        )
                    })
                }
            </div>
            <>
                {
                    props.options[tabIndex].content
                }
            </>
        </>
    )
}