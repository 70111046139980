import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";
import { protocol } from "socket.io-client";

const route = '/v2/protocols'

export interface ProtocolSubjectItemPropsV2 {
    protocol_id?: string
    title: string
    description: string
    responsible: string
    time: string
    last_subject: string
    status: string
    feedback?: string
    id?: string
}

export interface ProtocolSubjectFormPropsV2 extends ProtocolSubjectItemPropsV2 {
    id: string
    protocol_id: string
}

export interface ProtocolItemPropsV2 {
    name: string
    date: string
    company_id?: string
    client_id?: string
    folder_name?: string
    client_name_create?: string,
    subjects?: Array<ProtocolSubjectItemPropsV2>
    folder_id?: string
    user_created_name?: string
    id?: string
}

export interface ProtocolFormPropsV2 extends ProtocolItemPropsV2 {
    subjects: Array<ProtocolSubjectItemPropsV2>
}

export interface ProtocolFormFileProps {
    id: number
    protocol_id: number
    client_id: number
    company_id: number
    file_name: string
    path_file: string
}

class ProtocolV2 
{
    async create(
        data: ProtocolFormPropsV2,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async createFile(
        form: FormData,
        protocol_id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}/${protocol_id}/file`, form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        protocol_id: string,
        data: ProtocolFormPropsV2,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/${protocol_id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async duplicate(
        protocol_id: string,
        data: ProtocolFormPropsV2,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/${protocol_id}/duplicate`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((success: Array<ProtocolItemPropsV2>) => void),
    )
    {
        return await Request.get(`${route}`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }
    

    async getAllWithFolders(
        onSuccess: ((success: Array<ProtocolItemPropsV2>) => void),
    )
    {
        return await Request.get(`${route}/with/folders`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }

    async getAllByFolder(
        folder_id: string,
        onSuccess: ((success: Array<ProtocolItemPropsV2>) => void),
    )
    {
        return await Request.get(`${route}/folder/${folder_id}`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }

    async getSubjectsOpened(
        protocol_id: string,
        onSuccess: ((success: Array<ProtocolSubjectItemPropsV2>) => void),
    )
    {
        return await Request.get(`${route}/subjects/opened/${protocol_id}`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: ProtocolItemPropsV2) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }

    async getFilesByProtocolId(
        protocol_id: string,
        onSuccess: ((success: ProtocolFormFileProps[]) => void),
    )
    {
        return await Request.get(`${route}/${protocol_id}/files`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async deleteFile(
        protocol_id: string,
        file_id: number,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${protocol_id}/${file_id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async archived(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/${id}/archived`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async consultarArchiveds(
        onSuccess: ((success: ProtocolItemPropsV2[]) => void),
    )
    {
        return await Request.get(`${route}/archiveds`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }


}

export const ProtocolV2API = new ProtocolV2()