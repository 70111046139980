import React, { useEffect, useState } from 'react'
import { FaBell, FaTimes } from 'react-icons/fa'
import { formatDate } from '../../functions/dataFormat'
import { ManagerNotifyAPI, NotifyMessageProps } from '../../services/ManagerNotify/ManagerNotify'

export interface NotificationProps {}

export const Notification: React.FC<NotificationProps> = (props) => 
{
    const [notifies, setNotifies] = useState<NotifyMessageProps[]>([])
    const [menu, setMenu] = useState<'no_visualized' | 'all'>('no_visualized')
    const [show, setShow] = useState(false)

    useEffect(() => {
        ManagerNotifyAPI.getAll(data => setNotifies(data))    
    }, [])

    return (
        <>
            <div className="manager-notify" onClick={() => {
                setShow(true)
            }}>
                <FaBell /> {menu === "no_visualized" ? <span className="budget-number">{notifies.length > 0 ? notifies.length > 99 ? 99 : notifies.length : 0}</span> : null}
            </div>
            <div className={show ? 'manager-notifies show' : 'manager-notifies'}>
                <div className='manager-close' onClick={() => {
                    setShow(false)
                }}>
                    <FaTimes />
                </div>
                <div className="manager-options">
                    <div className={menu === "no_visualized" ? "actived" : ""} onClick={() => {
                        ManagerNotifyAPI.getAll(data => setNotifies(data))    

                        setMenu('no_visualized')
                    }}>
                        Não visualizadas
                    </div>
                    <div className={menu === "all" ? "actived" : ""} onClick={() => {
                        ManagerNotifyAPI.getAllNotifications(data => setNotifies(data))    

                        setMenu('all')
                    }}>
                        Todas
                    </div>
                </div>
                {
                    notifies.length ? (
                        notifies.map((item) => {
                            return (
                                <div className="manager-notifies-item">
                                    <p className="title-notify">
                                        {item.title}
                                    </p>
                                    <div dangerouslySetInnerHTML={{__html: item.message}}></div>
                                    <p className="date-create">
                                        {formatDate(item.date_create, 'dd/MM/yyyy HH:mm')}
                                    </p>
                                    {
                                        menu === "no_visualized" ? (
                                            <button className="btn-visualized" onClick={() => {
                                                ManagerNotifyAPI.post(item.id, () => {
                                                    ManagerNotifyAPI.getAll(setNotifies)
                                                })
                                            }}>Marcar como visualizado</button>
                                        ) : null
                                    }
                                </div>
                            )
                        })
                    ) : (
                        <div className="without-notification">
                            <p>Sem notificações</p>
                        </div>
                    )
                }
            </div>
        </>
    )
}