import React from 'react'
import { ConfirmDelete } from '../../../functions/Modals/ConfirmDelete';
import Success from '../../../functions/Modals/Success';
import { NormalTask } from '../../../services/Calendar/calendarTypes';
import { ApagarRotina } from '../../../services/Rotina/ApagarRotina';

export interface TaskBox {
    taskProps: NormalTask
    haveSubtitle?: boolean
    haveDateButton?: boolean,
    haveDateFinish?: boolean,
    haveTags?: boolean
    haveTime?: boolean
    onClick: (() => void)
}

export const TaskBox: React.FC<TaskBox> = (props) => {

    const { taskProps} = props;

    return (
        <>
            {
                
                <div className="task-item">
                    <div className="task-text">
                        <span className="title" onClick={props.onClick}>
                            {
                                props.haveTags ? (
                                    <div className="flex" style={{flexDirection: 'column'}}>
                                        {
                                            taskProps.tags.length ? 
                                            (
                                                <>
                                                    {
                                                        taskProps.tags.map((tag, item) => {
                                                            return (
                                                                <div className="tag-task" style={{background: `${tag.color}`}}>
                                                                    {tag.label}
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </>
                                            ) : null
                                        } 
                                        {
                                            taskProps.departments.length ? 
                                            (
                                                taskProps.departments.map((e, i) => {
                                                    return (
                                                        <div key={`${i}`} style={{marginLeft: '10px', marginRight: '10px'}} className="tag-task bkg-blue-dark-light">
                                                            {e.label}
                                                        </div>
                                                    )
                                                })
                                            ) : null
                                        } 
                                    </div>
                                ) : null
                            }
                            {
                                props.haveTime ? (
                                    <span className="time-task">
                                        {taskProps?.hour} - {taskProps?.hour_finish}
                                    </span> 
                                ): null
                            }
                            {taskProps?.label}
                        </span>
                        {
                            props.haveSubtitle ? (
                                <span className="subtitle">{taskProps?.atividade_name}</span>
                            ) : null
                        }
                        
                    </div>
                    {
                        props.haveDateButton ? (
                            taskProps?.permission === "0" ? (
                                <div className="task-date">
                                    <button onClick={() => ConfirmDelete(
                                                                            () => ApagarRotina(
                                                                                                taskProps.id, 
                                                                                                s => Success('Apagado com sucesso!', () => window.location.reload()), 
                                                                                                e => Error('Houve um erro ao tentar apagar esta tarefa.')
                                                                                                ), () => {}, 'Tem certeza que deseja apagar esta tarefa.')} className="btn-link">Apagar</button>
                                </div>
                            ) : (
                                <div className="task-date">
                                    <span className="date-preview">{taskProps?.date_preview_f} <small>até</small></span>
                                    <span className="date-finish">{taskProps?.date_finish_f}</span>
                                </div>
                            )
                        ) : null
                    }
                    
                </div>
            }
        </>
    )
}
