import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";
import RequestWithoutLoading from "../../functions/RequestWithoutLoading";

const route = '/client/address'

export interface UpdateAddressStatusProps {
    status: string
}

export interface UpdateAddressFormProps {
    bairro: string
    cep: string
    localidade: string
    logradouro: string
    uf: string
    number: string
    complemento?: string
}

class UpdateAddress 
{
    async getStatus(onSuccess: ((success: UpdateAddressStatusProps) => void),
    )
    {
        return await RequestWithoutLoading.get(`${route}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async sendAddress(
        form: UpdateAddressFormProps,
        onSuccess: ((success: AxiosResponse) => void
    ),
    )
    {
        return await Request.post(`${route}`, form).then(onSuccess).catch(DefaultErrorResponse);
    }
}

export const UpdateAddressAPI = new UpdateAddress()