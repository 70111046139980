import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaSignInAlt } from 'react-icons/fa'
import { MdArrowBack, MdModeEdit, MdDeleteForever, MdArrowForward } from 'react-icons/md'
import { useHistory, useParams } from 'react-router-dom'
import { Content } from '../../../../components/Content'
import { ConfigModal, Modal } from '../../../../components/Modal'
import { Navbar } from '../../../../components/Navbar'
import { NoWithContent } from '../../../../components/NoWithContent'
import { ConfirmDelete } from '../../../../functions/Modals/Confirm'
import { CollaboratorAPI, CollaboratorItemProps } from '../../../../services/ProccessFeedback/Collaborator'
import { FormTemplate } from './components/FormTemplate'
import { PDCTemplate_API, PDCTemplate_ItemProps } from '../../../../services/ProccessFeedbackCustom/Template'

export interface PFC_TemplatesProps {}
export interface PFC_TemplatesRouteParams {
    department: string
}

export const PFC_Templates: React.FC<PFC_TemplatesProps> = (props) => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [list, setList] = useState<Array<PDCTemplate_ItemProps>>([])
    const history = useHistory()
    const loading = useRef(true)

    useEffect(() => {
        PDCTemplate_API.getAll(setList)

        loading.current = false
    }, [])

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <button className="history-back" onClick={() => history.push('/pessoas/proccess-feedback')}><MdArrowBack /></button>
                            <h1 className="app-content-h1-destaque">Processo de Feedback: Colaboradores</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Lista de modelos</h2>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    content: <FormTemplate onSuccess={() => PDCTemplate_API.getAll(setList)} />,
                                    onClose: () => setModalConfig({
                                        show: false
                                    }),
                                    title: <h2>Criar modelo</h2>
                                })
                            }}>Criar modelo</button>
                        </div>
                    </div>
                    <br/>
                    {
                        list.length > 0 ?
                        (
                            list.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link" onClick={() => history.goBack()}><FaSignInAlt/>{item.name}</div>
                                                    <div className="options">
                                                        <button className="update-button" onClick={() => {
                                                            history.push(`/pessoas/proccess-feedback-custom/templates/${item.id}`)
                                                        }}><MdArrowForward/></button>
                                                        <button className="update-button" onClick={() => {
                                                            setModalConfig({
                                                                show: true,
                                                                content: <FormTemplate id={item.id} onSuccess={() => {
                                                                    PDCTemplate_API.getAll(setList)

                                                                    setModalConfig({
                                                                        show: false
                                                                    })
                                                                }} />,
                                                                onClose: () => setModalConfig({
                                                                    show: false
                                                                }),
                                                                title: <h2>Atualizar modelo</h2>
                                                            })
                                                        }}><MdModeEdit/></button>
                                                        <button className="delete-button" onClick={() => {
                                                            ConfirmDelete(() => {
                                                                if(item.id)
                                                                {
                                                                    PDCTemplate_API.delete(item.id, () => {
                                                                        setList([...list.filter(i => i.id !== item.id)])
                                                                    })
                                                                }
                                                            }, () => null)
                                                        }}><MdDeleteForever/></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : <NoWithContent/>
                    }
                </div>
            </Content>
        </>
    )
}