import React from 'react'

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {}

export const Button: React.FunctionComponent<IButton> = (props) => 
{
    return (
        <div className="button-form">
            <button {...props}>{props.children}</button>
        </div>
    )
}
