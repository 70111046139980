import { AxiosResponse } from "axios"
import Request from "../../functions/Request"
import { NormalTask } from "./calendarTypes";

export interface ResponseGenerateMonth {
    month: Array<ResponseGenerateMonthItem>,
    message: string
}

export interface ResponseGenerateMonthItem {
    date_filter: string
    date_formatted: string
    name: string
    name_number: string
    month: string
    tasks: Array<NormalTask>
}

export const GenerateMonth = async (
    onSuccess: ((e: ResponseGenerateMonth) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.get('/calendar/month').then(s => onSuccess(s.data)).catch(onError);
