import React from 'react'
import { MyFeedbackItem } from '../../../services/MyFeedbacks/ControllerMyFeedbacks'

export interface ListMyFeedbacksProps {
    list: Array<MyFeedbackItem>,
    onClickItem: ((e: MyFeedbackItem) => void)
}

export const ListMyFeedbacks: React.FC<ListMyFeedbacksProps> = (props) => 
{
    const { list } = props;

    return (
        <div>
            {
                list.map(item => {
                    let date = new Date(`${item.date_create}`)

                    return (
                        <>
                            <div className="my-feedback-item" onClick={e => props.onClickItem(item)}>
                                <p className="feedback">{item.feedback}</p>
                                <div className="information">
                                    <p className="date">Enviado: {date.toLocaleDateString('pt-BR')}</p>
                                    <p className="status">Status: <span>{item.status === "1" ? "Aberto" : "Respondido"}</span></p>
                                </div>
                            </div>
                        </>
                    )
                })            
            }
        </div>
    )
}
