import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";
import RequestWithoutLoading from "../../functions/RequestWithoutLoading";

const route = '/client/finance'

export interface PaymentDetailProps {
    status: string,
    date_payment: string,
    boleto?: {
        url: string
        codigo: string
        expiration: string
    }
}

export interface PaymentProps {
    id: string
    code_payment: string
    company_id: string
    status: string
    date: string
    date_started: string
    date_finish: string
    plan_code: string
    plan_name: string
    plan_price: string
    date_payment: string
    method: string
    payment_status: string
    status_code: "OPEN" | "PAID" | "CANCELED"
    payments?: Array<PaymentDetailProps>
    payment_link?: string
}

class getMyPayments 
{
    async payments(onSuccess: ((success: Array<PaymentProps>) => void),
    )
    {
        return await RequestWithoutLoading.get(`${route}/payments`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async payment(
        id: string,
        onSuccess: ((success: PaymentProps) => void),
    )
    {
        return await Request.get(`${route}/payment/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async cancelPayment(
        id: string,
        onSuccess: ((success: {
            message: string
        }) => void),
        onError: ((err: {
            message: string
        }) => void),
    )
    {
        return await Request.delete(`${route}/payment/${id}`).then(response => onSuccess(response.data)).catch(response => onError(response.data));
    }
}

export const getMyPaymentsAPI = new getMyPayments()