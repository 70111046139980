import { AxiosResponse } from "axios";
import Request from "../../functions/Request";

const route = '/client/my-attachments'

export interface FoldersItemProps {
    id: string
    name: string
    client_id: string
}

export interface FolderFormProps {
    name: string
    id?: string
}

class Folders
{
    async insert(form: FolderFormProps, success: ((response: AxiosResponse) => void))
    {
        return await Request.post(`${route}/folder`, form).then(success);
    }

    async update(id: string, form: FolderFormProps, success: ((response: AxiosResponse) => void))
    {
        return await Request.put(`${route}/folder/${id}`, form).then(success);
    }

    async get(id: string, success: ((response: FoldersItemProps) => void))
    {
        return await Request.get(`${route}/folder/${id}`).then(response => success(response.data));
    }
    
    async getAll(success: ((response: Array<FoldersItemProps>) => void))
    {
        return await Request.get(`${route}/folders`).then(response => success(response.data));
    }

    async delete(id: string, success: ((response: FoldersItemProps) => void))
    {
        return await Request.delete(`${route}/folder/${id}`).then(response => success(response.data));
    }
}

export const FoldersAPI = new Folders()