import { AxiosResponse } from "axios";
import Request from "../../functions/Request";
import RequestWithoutLoading from "../../functions/RequestWithoutLoading";

const route = '/client/organograma'


export interface OrganogramaFormItemProps {
    id?: string
    cargo: string
    client_id?: string
    position: string
    ref_id: string,
    connected_with?: string
    graphic_position?: string
    department_id?: string
}

class Organograma
{
    async save(form: OrganogramaFormItemProps, success: ((response: AxiosResponse) => void))
    {
        return await Request.post(`${route}`, form).then(success);
    }

    async reorder(form: {
        list: Array<OrganogramaFormItemProps>
    }, success: ((response: AxiosResponse) => void))
    {
        return await RequestWithoutLoading.put(`${route}/reorder`, form).then(success);
    }

    async update(id: string, form: OrganogramaFormItemProps, success: ((response: AxiosResponse) => void))
    {
        return await Request.put(`${route}/${id}`, form).then(success);
    }

    async delete(id: string, success: ((response: AxiosResponse) => void))
    {
        return await Request.delete(`${route}/${id}`).then(success);
    }
    
    async consult(department: string, success: ((response: Array<OrganogramaFormItemProps>) => void))
    {
        return await Request.get(`${route}/by-department/${department}`).then(response => success(response.data.response));
    }

    async consultOne(id: string, success: ((response: OrganogramaFormItemProps) => void))
    {
        return await Request.get(`${route}/${id}`).then(response => success(response.data.response));
    }

    async changeGraphicPosition(id: string, data: {
        position: string
    }, success: ((response: OrganogramaFormItemProps) => void))
    {
        return await Request.put(`${route}/graphic-position/${id}`, data).then(response => success(response.data.response));
    }
}

export const OrganogramaAPI = new Organograma()