import { AxiosResponse } from "axios"
import Request from "../../functions/Request"

export interface ResponseConsultMyTags {
    tags: Array<ConsultMyTagsItem>,
    message: string,
}

export interface ConsultMyTagsItem {
    id: string
    label: string
    tag_id: string
    client_id: string
    color: string
}

export const ConsultMyTags = async (
    onSuccess: ((e: ResponseConsultMyTags) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.get('/tags/my').then(e => onSuccess(e.data)).catch(onError);
