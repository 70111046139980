import React, { useEffect, useState, useRef } from 'react'
import { MdAdd } from 'react-icons/md'
import { EditorHTML } from '../../../../components/EditorHTML'
import { LoadingContent } from '../../../../components/LoadingContent'
import Success from '../../../../functions/Modals/Success'
import { CargosAPI, DescriptionItemFormProps } from '../../../../services/Cargos/Cargos'

export interface FormDescriptionDescriptionProps {
    id?: string,
    onSuccess?: (() => void)
}

export const FormDescriptionDescription: React.FC<FormDescriptionDescriptionProps> = (props) => 
{
    const [form, setForm] = useState<DescriptionItemFormProps>({
        office: '',
        client_id: '',
        department_id: '',
        occupational_family: '',
        item: '',
        cargo_mission: '',
        detailed_assignments: '',
        basic_requirements_formation: '',
        performance_indicators: '',
        basic_requirements_experience: '',
        basic_requirements_behavioral_skills: '',
        basic_requirements_technical_skills: ''
    })

    const [occupational_family, setoccupational_family] = useState('')
    const [item, setitem] = useState('')
    const [cargo_mission, setcargo_mission] = useState('')
    const [detailed_assignments, setdetailed_assignments] = useState('')
    const [basic_requirements_formation, setbasic_requirements_formation] = useState('')
    const [performance_indicators, setperformance_indicators] = useState('')
    const [basic_requirements_experience, setbasic_requirements_experience] = useState('')
    const [basic_requirements_behavioral_skills, setbasic_requirements_behavioral_skills] = useState('')
    const [basic_requirements_technical_skills, setbasic_requirements_technical_skills] = useState('')
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
    }

    const loading = useRef(true)
    
    useEffect(() => {
        loading.current = false
    })

    useEffect(() => {
        if(props.id)
        {
            CargosAPI.consultDescriptionHistoryItem(props.id, data => {
                if(!data.data_json)
                    return false;

                setForm(data.data_json)
                
                setoccupational_family(data.data_json.occupational_family)
                setitem(data.data_json.item)
                setcargo_mission(data.data_json.cargo_mission)
                setdetailed_assignments(data.data_json.detailed_assignments)
                setbasic_requirements_formation(data.data_json.basic_requirements_formation)
                setperformance_indicators(data.data_json.performance_indicators)
                setbasic_requirements_experience(data.data_json.basic_requirements_experience)
                setbasic_requirements_behavioral_skills(data.data_json.basic_requirements_behavioral_skills)
                setbasic_requirements_technical_skills(data.data_json.basic_requirements_technical_skills)
                loading.current = false
            })
        }
    }, [props.id])

    return (
        <>
            {
                !loading.current || !props.id ? 
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p>Cargo</p>
                            <input type="text" onChange={e => setForm({...form, office: e.target.value})} value={form.office} />
                        </div>
                        <div className="box-input">
                            <p>Família Ocupacional (CBO - Código Brasileiro de Ocupações)</p>
                            <EditorHTML defaultToolbar onChange={setoccupational_family} value={occupational_family} />
                        </div>
                        <div className="box-input">
                            <p>Item (CBO - Código Brasileiro de Ocupações)</p>
                            <EditorHTML defaultToolbar onChange={setitem} value={item} />
                        </div>
                        <div className="box-input">
                            <p>Missão do cargo</p>
                            <EditorHTML defaultToolbar onChange={setcargo_mission} value={cargo_mission} />
                        </div>
                        <div className="box-input">
                            <p>Atribuições detalhadas</p>
                            <EditorHTML defaultToolbar onChange={setdetailed_assignments} value={detailed_assignments} />
                        </div>
                        <h2 className="h2-separate">Requisitos básicos</h2>
                        <div className="box-input">
                            <p>Formação</p>
                            <EditorHTML defaultToolbar onChange={setbasic_requirements_formation} value={basic_requirements_formation} />
                        </div>
                        <div className="box-input">
                            <p>Experiência</p>
                            <EditorHTML defaultToolbar onChange={setbasic_requirements_experience} value={basic_requirements_experience} />
                        </div>
                        <div className="box-input">
                            <p>Competências Comportamentais</p>
                            <EditorHTML defaultToolbar onChange={setbasic_requirements_behavioral_skills} value={basic_requirements_behavioral_skills} />
                        </div>
                        <div className="box-input">
                            <p>Competências Técnicas</p>
                            <EditorHTML defaultToolbar onChange={setbasic_requirements_technical_skills} value={basic_requirements_technical_skills} />
                        </div>
                        <div className="box-input">
                            <p>Indicadores de desempenho</p>
                            <EditorHTML defaultToolbar onChange={setperformance_indicators} value={performance_indicators} />
                        </div>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}