import React, { useEffect, useState } from 'react'
import { ConfigModal, ModalRight } from '../../../components/ModalRight'
import { useHistory, useParams } from 'react-router-dom'
import { Navbar } from '../../../components/Navbar'
import { Content } from '../../../components/Content'
import { ButtonBackHistory } from '../../../components/ButtonBackHistory'
import { FaArrowRight, FaCheck, FaEdit, FaPlus, FaTrash } from 'react-icons/fa'
import { AnaliseCriticaItensItemProps, analise_critica_itens_api } from './services/analise.critica-itens.services'
import { analise_critica_api, AnaliseCriticaTemplateItemProps } from '../AnaliseCritica/services/analise.critica.services'
import { FormAnaliseCriticaItens } from './components/AnaliseCriticaForm'
import { analise_critica_template_api, AnaliseCriticaTemplateClientItemProps } from './services/analise.critica-templates.services'
import { AnaliseCriticaTemplateForm } from './components/AnaliseCriticaTemplateForm'

export interface AnaliseCriticaItensProps {}

export const AnaliseCriticaItens: React.FC<AnaliseCriticaItensProps> = (props) => 
{
    const params = useParams<{id: string}>()
    
    const [modalConfig, setModalConfig] = useState<ConfigModal>({
        show: false
    })

    const [template, setTemplate] = useState<AnaliseCriticaTemplateItemProps[]>([])
    const [templateClient, setTemplateClient] = useState<AnaliseCriticaTemplateClientItemProps[]>([])

    const history = useHistory()

    useEffect(() => {
        if(params.id)
        {
            analise_critica_api.template(params.id, (data) => {
                setTemplate(data)
            })
        }

        analise_critica_template_api.getAll(params.id, (data) => setTemplateClient(data))
    }, [])

    return (
        <>
            <ModalRight {...modalConfig} />
            <Navbar/>
            <Content title={(
                <div className='title-button'>
                    <div className="title-button">
                        <div className="title">
                            <ButtonBackHistory onClick={() => history.goBack() }/>
                            <h1 className="app-content-h1-destaque">Analise Critica</h1>
                        </div>
                    </div>
                    <div className="options">
                        <button onClick={() => {
                            setModalConfig({
                                show: true,
                                content: <AnaliseCriticaTemplateForm onSuccess={() => window.location.reload()} />,
                                onClose: () => setModalConfig({
                                    show: false
                                }),
                                title: <h2>Preencher informações</h2>
                            })
                        }}>Novo</button>
                    </div>
                </div>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">Preencha os dados abaixo</div>
                    </div>
                    <br/>
                    <p style={{margin: ".5rem 0"}}><b>Itens padrão:</b></p>
                    <div>
                        {
                            template.length > 0 ?
                            (
                                template.map((item, index) => 
                                    {
                                        return (
                                            <>
                                                <div key={`${index}`} className="proccess-item" onClick={() => {}}>
                                                    <div className="title-with-button-options">
                                                        <div className="title">{item.assunto_entrada} {item.status ? <FaCheck color="green" /> : null}</div>
                                                        <div className="options">
                                                            <button className="update-button" onClick={() => {
                                                                setModalConfig({
                                                                    show: true,
                                                                    content: <FormAnaliseCriticaItens onSuccess={() => window.location.reload()} id={item.id as string} />,
                                                                    onClose: () => setModalConfig({
                                                                        show: false
                                                                    }),
                                                                    title: <h2>Preencher informações</h2>
                                                                })
                                                            }}><FaEdit /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                )
                            ) :
                            (
                                <h3>Sem informações para serem mostradas</h3>
                            )
                        }
                    </div>
                    <p style={{margin: ".5rem 0"}}><b>Itens particular:</b></p>
                    <div>
                        {
                            templateClient.length > 0 ?
                            (
                                templateClient.map((item, index) => 
                                    {
                                        return (
                                            <>
                                                <div key={`${index}`} className="proccess-item" onClick={() => {}}>
                                                    <div className="title-with-button-options">
                                                        <div className="title">{item.assunto_entrada} {item.status ? <FaCheck color="green" /> : null}</div>
                                                        <div className="options">
                                                            <button className="delete-button" onClick={() => {
                                                                if(item.id)
                                                                    analise_critica_template_api.delete(item.id.toString(), params.id, () => analise_critica_template_api.getAll(params.id,(data) => setTemplateClient(data))
                                                            )
                                                            }}><FaTrash /></button>
                                                            <button className="pdf-button" onClick={() => {
                                                                if(item.id)
                                                                    setModalConfig({
                                                                        show: true,
                                                                        content: <AnaliseCriticaTemplateForm onSuccess={() => window.location.reload()} id={item.id.toString()} />,
                                                                        onClose: () => setModalConfig({
                                                                            show: false
                                                                        }),
                                                                        title: <h2>Preencher informações</h2>
                                                                    })
                                                            }}><FaEdit /></button>
                                                            <button className="update-button" onClick={() => {
                                                                if(item.id)
                                                                    setModalConfig({
                                                                        show: true,
                                                                        content: <FormAnaliseCriticaItens particular onSuccess={() => window.location.reload()} id={item.id.toString()} />,
                                                                        onClose: () => setModalConfig({
                                                                            show: false
                                                                        }),
                                                                        title: <h2>Preencher informações</h2>
                                                                    })
                                                            }}><FaEdit /></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }
                                )
                            ) :
                            (
                                <h3>Sem informações para serem mostradas</h3>
                            )
                        }
                    </div>
                </div>
            </Content>
        </>
    )
}