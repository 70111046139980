import React, { useEffect, useState } from 'react'
import Success from '../../../functions/Modals/Success';
import { FaClone, FaDownload, FaFilePdf, FaFolder, FaPlus, FaSave, FaTrash } from 'react-icons/fa';
import { SubjectsProtocolFeedback, newItemTempProps } from './SubjectsProtocolFeedback';
import { ProtocolFormFileProps, ProtocolFormPropsV2, ProtocolItemPropsV2, ProtocolV2API } from '../../../services/ProtocolV2/ProtocolV2';
import { APP_CONFIG } from '../../../constants';
import { cookies } from '../../../functions/Session';
import { ProtocolFolderAPI, ProtocolFolderItemProps } from '../../../services/V2/ProtocolFolder';
import { ConfirmDelete } from '../../../functions/Modals/Confirm';
import { useToasts } from 'react-toast-notifications';
import Warning from '../../../functions/Modals/Warning';

interface IUpdateProtocol {
    id: string,
    change_archived?: boolean
}

export const UpdateProtocol: React.FC<IUpdateProtocol> = (props) => 
{
    const [form, setForm] = useState<ProtocolFormPropsV2>({
        name: '',
        date: '',
        subjects: []
    })

    const [newItemTemp, setNewItemTemp] = useState<newItemTempProps>({
        status: false,
        title: '',
    })

    const [folders, setFolders] = useState<Array<ProtocolFolderItemProps>>([])
    const [files, setFiles] = useState<ProtocolFormFileProps[]>([]);
    
    useEffect(() => {
        ProtocolFolderAPI.getAll(setFolders)

        ProtocolV2API.getById(props.id, response => {
            setForm({
                subjects: response.subjects?.reverse() ?? [], 
                name: response.name,
                date: response.date,
                folder_id: response.folder_id
            })
        })

        ProtocolV2API.getFilesByProtocolId(props.id, files => {
            setFiles(files)
        })
    }, [props.id])

    const prevSaveSubjects = () => {
        if(newItemTemp.status)
                            {
            if(newItemTemp.index_change !== undefined)
            {
                let old_list = form.subjects.length ? form.subjects : [];

                old_list[newItemTemp.index_change].title = newItemTemp.title
                old_list[newItemTemp.index_change].description = newItemTemp.description ?? ''
                old_list[newItemTemp.index_change].responsible = newItemTemp.responsible ?? ''
                old_list[newItemTemp.index_change].time = newItemTemp.time ?? ''

                setForm({...form, subjects: [...old_list]})
                setNewItemTemp({...newItemTemp, title: '', status: false, index_change: undefined})
                
            }else if(newItemTemp.title)
            {
                let old_list = form.subjects.length ? form.subjects : [];

                old_list.push({ ...newItemTemp, status: '1', last_subject: 'no', description: newItemTemp.description ?? '', responsible: newItemTemp.responsible ?? '', time: newItemTemp.time ?? ''})
            
                setForm({...form, subjects: [...old_list]})

                setNewItemTemp({...newItemTemp, title: '', responsible: '', time: '', description: ''})
                
            }else{
                Warning('Preencha o assunto.')
            }
        }else{
            setNewItemTemp({...newItemTemp, status: true})
        }
    }

    const onSubmit = () => {
        ConfirmDelete(() => {
            ProtocolV2API.update(props.id, form, () => Success('Atualizada com sucesso', () => window.location.reload()))
        }, () => {}, 'Sim, salvar', 'Pode haver assuntos em aberto')
    }

    const [searchSubjectsOpened, setsearchSubjectsOpened] = useState<Array<ProtocolItemPropsV2>>([])
    const [viewProtocolsWithSubjectsOpened, setviewProtocolsWithSubjectsOpened] = useState(false)
    const { addToast } = useToasts()

    return (
        <>
            <form className="form-styled-v2" method="post" onSubmit={(event) => {
                event.preventDefault()

                prevSaveSubjects()

                onSubmit()
            }}>
                <div className="row">
                    <div className="col-12 col-lg-4">
                        <div className="input-box-v2">
                            <label htmlFor="!#">Título</label>
                            <input className="input-styled-shadow" type="text" onChange={e => setForm({...form, name: e.target.value})} value={form.name} placeholder="Descreva o nome da pauta" required autoComplete="off" autoFocus={true}/>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="input-box-v2">
                            <label htmlFor="!#">Data</label>
                            <input className="styled" type="date" onChange={e => setForm({...form, date: e.target.value})} value={form.date} placeholder="Descreva o nome da pauta" required autoComplete="off" autoFocus={true}/>
                        </div>
                    </div>
                    <div className="col-12 col-lg-4">
                        <div className="input-box-v2">
                            <label htmlFor="!#">Pasta</label>
                            <select onChange={e => setForm({...form, folder_id: e.target.value})} value={form.folder_id} >
                                <option value="">Selecione uma opção</option>
                                {
                                    folders.map((item, index) => <option value={item.id}>{item.name}</option> )
                                }
                            </select>
                        </div>
                    </div>
                    {
                        setsearchSubjectsOpened.length > 0 && viewProtocolsWithSubjectsOpened ? 
                        (
                            <div className="col-12" style={{marginTop: '20px'}}>
                                <div className="input-box-v2">
                                    <label>Selecione a pauta anterior</label>
                                    <select onChange={e => {
                                        if(e.target.value !== "")
                                        {
                                            ProtocolV2API.getSubjectsOpened(e.target.value, data => setForm({...form, subjects: [...form.subjects, ...data.map((item, index) => {
                                                return {
                                                    ...item,
                                                    last_subject: 'yes'
                                                }
                                            })]}))
                                        }
                                    }}>
                                        <option value="">Selecione uma opção</option>
                                        {
                                            searchSubjectsOpened.map((e, i) => <option key={`${i}`} value={e.id}>{e.name}</option>)
                                        }
                                    </select>
                                </div>
                            </div>
                        ) : null
                    }
                </div>
                <SubjectsProtocolFeedback newItemTemp={newItemTemp} setNewItemTemp={setNewItemTemp} onLoadingOldSubjects={() => {
                    setviewProtocolsWithSubjectsOpened(true)
                    ProtocolV2API.getAll(setsearchSubjectsOpened)
                }} list={form.subjects} setList={list => setForm({...form, subjects: list})}/>
                <br />
                <div className="title-toolkit">
                    <div className="title">
                        <span>Arquivos</span>
                    </div>
                    <div className="toolkit">
                        <label htmlFor="file" className="toolkit-button default"><FaPlus/></label>
                    </div>
                </div>
                <input  style={{display: 'none'}} type="file" id="file" onChange={event => {
                    let formData = new FormData()

                    if(event.target.files && event.target.files[0])
                    {
                        formData.append('file', event.target.files[0])
        
                        if(props.id)
                        {
                            ProtocolV2API.createFile(formData, props.id, () => {
                                Success('Arquivo enviado com sucesso', () => {
                                    ProtocolV2API.getFilesByProtocolId(props.id, setFiles)
                                })
                            })
                        }
                    }else{
                        Warning('Você não escolheu o nenhum arquivo')
                    }
                }} />
                {files.map((item, index) => {
                    return (
                        <div className="pop-file">
                            <div className="title">{item.file_name}</div>
                            <div className="options">
                                <span className="clr-success" onClick={() => {
                                    window.open(`${process.env.REACT_APP_URL_ATTACHMENT}/${item.path_file}`, '_blank')
                                }}><FaDownload /></span>
                                <span className="clr-danger" onClick={() => {
                                    ProtocolV2API.deleteFile(props.id, item.id, () => Success('Apagado com sucesso', () => {
                                        ProtocolV2API.getFilesByProtocolId(props.id, files => {
                                            setFiles(files)
                                        })
                                    }))
                                }} ><FaTrash /></span>
                            </div>
                        </div>
                    )
                })}
                <br />
                <div className="flex" style={{justifyContent: 'space-between'}}>
                    <div className="flex">
                        <button className="btn-styled success" type="submit"><FaSave /> Salvar</button>
                        <button className="btn-styled normal" type="button" style={{marginLeft: '20px', marginRight: '20px'}} onClick={() => {
                            window.open(`${APP_CONFIG.localhost}/v2/protocols/pdf/${props.id}/${cookies.get('X-Request-Token')}`, '_targer')
                        }}><FaFilePdf/> Gerar PDF</button>
                    </div>
                    <div className="flex">
                        <button className="btn-styled normal" type="button" style={{marginRight: '10px'}} onClick={() => {
                            ProtocolV2API.duplicate(props.id, form,  () => Success('Duplicado com sucesso', () => window.location.reload()))
                        }}><FaClone />Duplicar</button>
                        <button className="btn-styled normal" type="button" style={{marginRight: '10px'}} onClick={() => {
                            ProtocolV2API.archived(props.id, () => Success('Arquivo com sucesso', () => window.location.reload()))
                        }}><FaFolder /> {props.change_archived ? 'Desarquivar' : 'Arquivar'} ATA</button>
                        <button className="btn-styled danger" type="button" onClick={() => {
                            ConfirmDelete(() => {
                                if(props.id)
                                {
                                    ProtocolV2API.delete(props.id, () => Success('Apagado com sucesso', () => window.location.reload()))
                                }
                            }, () => {}, "Sim, exclua!", 'Pode conter informações de outros usuários. Tem certeza que quer apagar?')
                        }}><FaTrash/> Apagar</button>
                    </div>
                </div>
            </form>
        </>
    )
}
