import { AxiosResponse } from "axios";
import Request from "../../functions/Request";
import RequestWithoutLoading from "../../functions/RequestWithoutLoading";

const route = '/client/onboarding'

export interface OnboardingItemFormProps {
    id?: string
    topic: string
    client_id?: string
    suggestions_explanations: any
    description: any
    link_ref: string
}

export interface OnboardingItemProps extends OnboardingItemFormProps {
    id: string
}

class Onboarding
{
    async reorder(form: {
        list: Array<OnboardingItemFormProps>
    }, success: ((response: AxiosResponse) => void))
    {
        return await RequestWithoutLoading.put(`${route}/reorder`, form).then(success);
    }
    
    async save(form: OnboardingItemFormProps, success: ((response: AxiosResponse) => void))
    {
        return await Request.post(`${route}`, form).then(success);
    }

    async update(id: string, form: OnboardingItemFormProps, success: ((response: AxiosResponse) => void))
    {
        return await Request.put(`${route}/${id}`, form).then(success);
    }

    async delete(id: string, success: ((response: AxiosResponse) => void))
    {
        return await Request.delete(`${route}/${id}`).then(success);
    }
    
    async consult(success: ((response: Array<OnboardingItemProps>) => void))
    {
        return await Request.get(`${route}`).then(response => success(response.data.response));
    }

    async consultOne(id: string, success: ((response: OnboardingItemProps) => void))
    {
        return await Request.get(`${route}/${id}`).then(response => success(response.data.response));
    }
}

export const OnboardingAPI = new Onboarding()