import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import ServerRequest from "../../functions/ServerRequest";

const route = '/users/process-feedback/collaborator'

export interface CollaboratorFormProps {
    name: string
    cargo: string
    department_id: string
}

export interface CollaboratorItemProps {
    name: string
    cargo: string
    department_id: string
    client_id: string
    id: string
}

class Collaborator
{
    async create(
        data: CollaboratorFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.post(`${route}`, data).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        data: CollaboratorFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.put(`${route}/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: CollaboratorItemProps) => void),
    )
    {
        return await ServerRequest.get(`${route}/${id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        department_id: string,
        onSuccess: ((proccesses: Array<CollaboratorItemProps>) => void),
    )
    {
        return await ServerRequest.get(`${route}/by-department/${department_id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }
}

export const CollaboratorAPI = new Collaborator()