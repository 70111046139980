import React, { useEffect, useState } from 'react'
import { FaSignInAlt } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { ButtonBackHistory } from '../../../components/ButtonBackHistory'
import { Content } from '../../../components/Content'
import { Modal } from '../../../components/Modal'
import { ConfigModal } from '../../../components/ModalRight'
import { Navbar } from '../../../components/Navbar'
import Success from '../../../functions/Modals/Success'
import { ControllerIndicadorDepartment, DepartmentItem } from '../../../services/Indicators/ControllerIndicadorDepartment'
import { ControllerIndicators } from '../../../services/Indicators/Indicators'
import { NotifySystemAPI } from '../../../services/NotifySystem/NotifySystem'
import { CreateIndicators } from './components/CreateIndicators'
import { ListIndicators } from './components/ListIndicators'
import { UpdateIndicators } from './components/UpdateIndicators'

export interface ImportIndicatorsProps {}

export const ImportIndicators: React.FC<ImportIndicatorsProps> = () => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({
        show: false
    })

    const [list, setList] = useState<Array<DepartmentItem>>([])
    const [departmentSelected, setDepartmentSelected] = useState('')
    
    const history = useHistory()

    useEffect(() => {
        ControllerIndicadorDepartment.getAllListDepartmentIndicadors(setList)
    }, [])

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content title={
                <div className="title-button">
                    <div className="title">
                        <ButtonBackHistory onClick={() => {
                            history.push('/indicadores')
                        }} />
                        <h1 className="app-content-h1-destaque">Indicadores</h1>
                    </div>
                    <div className="options">
                        {
                            departmentSelected !== "" ? (
                                <button onClick={() => {
                                    setDepartmentSelected('')
                                }}>Departamentos</button>
                            ) : null
                        }
                        <button onClick={() => {
                            setModalConfig({
                                show: true,
                                content: <CreateIndicators department_id=""  />,
                                title: <h2>Cadastrar indicador</h2>,
                                onClose: () => setModalConfig({show: false})
                            })
                        }}>Novo indicador</button>
                    </div>
                </div>
            }>
                <div className="content-box">
                    {
                        list.length > 0 && departmentSelected === "" ?
                        (
                            list.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link" onClick={() => {
                                                        setDepartmentSelected(item.id)
                                                    }}><FaSignInAlt/> {item.label}</div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : null
                    }
                    {
                        departmentSelected !== "" ?
                        (
                            <ListIndicators 
                                department={departmentSelected}
                                onClickItem={data => {
                                    ControllerIndicators.saveMyIndicator({
                                        indicator_id: data.id
                                    }, () => Success('Indicador importado com sucesso!', async () => {
                                        await NotifySystemAPI.save({information: `Acabou de importar um indicador: ${data.title}`})

                                        window.location.reload()
                                    }))
                                }}

                                onClickEditItem={data => {
                                    setModalConfig({
                                        show: true,
                                        content: <UpdateIndicators id={data.id} />,
                                        title: <h2>Atualizar indicador</h2>,
                                        onClose: () => setModalConfig({show: false})
                                    })
                                }}
                            />
                        ) : null
                    }
                </div>
            </Content>
        </>
    )
}