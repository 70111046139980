import { AxiosResponse } from "axios";
import Request from "../../functions/Request";
import { FeedbackMyDayItemList } from "../../pages/Home/components/FeedbackMyDay";

const route = '/my-day'

export interface FeedbackMyDayItemProps {
    client_id: string
    date: string
    dateFormatted: string
    id: string
    question_what: string
    question_when: string
    question_who: string
    task_id: string
    status: string
}

class FeedbackMyDay
{
    async all(
        success: ((success: AxiosResponse) => void),
        error?: ((error: AxiosResponse) => void)
    )
    {
        return await Request.get(`${route}/view-feedbacks`).then(success).catch(error);
    }

    async byTaskId(
        id: string,
        success: ((success: Array<FeedbackMyDayItemProps>) => void),
        error?: ((error: AxiosResponse) => void)
    ) 
    {
        return await Request.get(`${route}/view-feedbacks-task/${id}`).then(e => success(e.data.feedbacks)).catch(error);
    }

    async byId(
        id: string,
        success: ((success: AxiosResponse) => void),
        error?: ((error: AxiosResponse) => void)
    ) 
    {
        return await Request.get(`${route}/view-feedback/${id}`).then(success).catch(error);
    }

    async oldsOpened(
        id: string,
        success: ((success: Array<FeedbackMyDayItemProps>) => void),
        error?: ((error: AxiosResponse) => void)
    ) 
    {
        return await Request.get(`${route}/view-feedbacks/olds/${id}`).then(response => success(response.data.response)).catch(error);
    }

    async create(
        data: {
            feedbacks: Array<FeedbackMyDayItemList>,
            task_id: string
        },
        success: ((success: AxiosResponse) => void),
        error?: ((error: AxiosResponse) => void)
    )
    {
        return await Request.post(`${route}/feedback`, data).then(success).catch(error);
    }
}

export const ControllerFeedbackMyDay = new FeedbackMyDay()
