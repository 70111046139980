import React, { useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Chart from 'react-apexcharts'
import { ResponsiveRadar } from '@nivo/radar'

export const MyDiagnosticResult: React.FC = () => 
{
    const location = useLocation<any>()
    const history = useHistory()

    const [state] = useState<any>({
        
        series: location.state.map((e: {label: string, value: string}) => {
            return {
                name: e.label,
                data: [e.value]
            }
        }),
        // colors: location.state.map((e: {label: string, value: string}) => RandomColor()),
        options: {
            chart: {
                height: 350,
                type: 'bar',
                stackType: '100%'
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        position: 'top', // top, center, bottom,
                    },
                },
                column: {
                    colorByPoint: true
                }
            },
            dataLabels: {
                enabled: true,
                formatter: (val: string) => {
                    return val + "%";
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            
            xaxis: {
                categories: ['Diagnóstico inicial'],
                position: 'top',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                }
            },
            yaxis: {
                max: 100,
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: false,
                    formatter: (val: string) => {
                        return val;
                    }
                }
            },
        }
    })  

    const consultStages = () => {

        let stages = location.state.filter((e: any) => {
            if(e.label === "Rotina" || e.label === "Branding / MKT")
            {
                return false;
            }else{  
                return e;
            }
        })

        let stages_sort = [];

        for(let x = stages.length; x--; x === 0)
        {
            stages_sort.push(stages[x])
        }

        return stages_sort;
    }

    return (
        <div className="my-diagnostic-result">
            <div className="result">
                <div id="chart">
                    <Chart options={state.options} series={state.series} type="bar" height={350} />
                </div>
                <div className="row" style={{marginBottom: '40px'}}>
                    <div style={{height: '250px', width: '250px', textAlign: 'center'}} className="col-4">
                        <h4>Realizado</h4>
                        <ResponsiveRadar
                            data={[
                                {
                                    label: 'Rotina',
                                    total: location.state.filter((e: any) => e.label === "Rotina")[0].value
                                },
                                {
                                    label: 'Pessoas',
                                    total: location.state.filter((e: any) => e.label === "Pessoas")[0].value
                                },
                                {
                                    label: 'Processos',
                                    total: location.state.filter((e: any) => e.label === "Processos")[0].value
                                },
                                {
                                    label: 'Resultados',
                                    total: location.state.filter((e: any) => e.label === "Resultados")[0].value
                                }
                            ]}
                            keys={["total"]}
                            indexBy="label"
                            maxValue="auto"
                            margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
                            curve="linearClosed"
                            borderWidth={2}
                            borderColor={{ from: 'color' }}
                            gridLevels={5}
                            gridShape="linear"
                            gridLabelOffset={20}
                            enableDots={true}
                            dotSize={10}
                            dotColor={{ theme: 'background' }}
                            dotBorderWidth={2}
                            dotBorderColor={{ from: 'color' }}
                            enableDotLabel={true}
                            dotLabel="value"
                            dotLabelYOffset={-12}
                            colors={{ scheme: 'nivo' }}
                            fillOpacity={0.25}
                            blendMode="multiply"
                            animate={true}
                            isInteractive={true}
                            legends={[
                                {
                                    anchor: 'top-left',
                                    direction: 'column',
                                    translateX: -50,
                                    translateY: -40,
                                    itemWidth: 80,
                                    itemHeight: 20,
                                    itemTextColor: '#999',
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                    <div style={{height: '250px', width: '250px', textAlign: 'center'}} className="col-4">
                        <h4>Desejado</h4>
                        <ResponsiveRadar
                            data={[
                                {
                                    label: 'Rotina',
                                    total: 80,
                                },
                                {
                                    label: 'Pessoas',
                                    total: 90
                                },
                                {
                                    label: 'Processos',
                                    total: 80
                                },
                                {
                                    label: 'Resultados',
                                    total: 90
                                },
                            ]}
                            keys={[ 'total' ]}
                            indexBy="label"
                            maxValue="auto"
                            margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
                            curve="linearClosed"
                            borderWidth={2}
                            borderColor={{ from: 'color' }}
                            gridLevels={5}
                            gridShape="linear"
                            gridLabelOffset={36}
                            enableDots={true}
                            dotSize={10}
                            dotColor={{ theme: 'background' }}
                            dotBorderWidth={2}
                            dotBorderColor={{ from: 'color' }}
                            enableDotLabel={true}
                            dotLabel="value"
                            dotLabelYOffset={-12}
                            colors={{ scheme: 'nivo' }}
                            fillOpacity={0.25}
                            blendMode="multiply"
                            animate={true}
                            isInteractive={true}
                            legends={[
                                {
                                    anchor: 'top-left',
                                    direction: 'column',
                                    translateX: -50,
                                    translateY: -40,
                                    itemWidth: 80,
                                    itemHeight: 20,
                                    itemTextColor: '#999',
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                    <div style={{height: '250px', width: '250px', textAlign: 'center'}} className="col-4">
                        <h4>Resultado</h4>
                        <ResponsiveRadar
                            data={[
                                {
                                    label: 'Rotina',
                                    desejado: 80,
                                    realizado: location.state.filter((e: any) => e.label === "Rotina")[0].value
                                },
                                {
                                    label: 'Pessoas',
                                    desejado: 90,
                                    realizado: location.state.filter((e: any) => e.label === "Pessoas")[0].value
                                },
                                {
                                    label: 'Processos',
                                    desejado: 80,
                                    realizado: location.state.filter((e: any) => e.label === "Processos")[0].value
                                },
                                {
                                    label: 'Resultados',
                                    desejado: 90,
                                    realizado: location.state.filter((e: any) => e.label === "Resultados")[0].value
                                }
                            ]}
                            keys={[ 'desejado', 'realizado' ]}
                            indexBy="label"
                            maxValue="auto"
                            margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
                            curve="linearClosed"
                            borderWidth={2}
                            borderColor={{ from: 'color' }}
                            gridLevels={5}
                            gridShape="linear"
                            gridLabelOffset={20}
                            enableDots={true}
                            dotSize={10}
                            dotColor={{ theme: 'background' }}
                            dotBorderWidth={2}
                            dotBorderColor={{ from: 'color' }}
                            enableDotLabel={true}
                            dotLabel="value"
                            dotLabelYOffset={-12}
                            colors={{ scheme: 'nivo' }}
                            fillOpacity={0.25}
                            blendMode="multiply"
                            animate={true}
                            isInteractive={true}
                            legends={[
                                {
                                    anchor: 'top-left',
                                    direction: 'column',
                                    translateX: -50,
                                    translateY: -40,
                                    itemWidth: 80,
                                    itemHeight: 20,
                                    itemTextColor: '#999',
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                </div>
                <div className="congratulation">
                    <h2>Parabéns!</h2>
                    <p>Agora vamos começar a sua jornada no MODUM de forma personalizada com o seu diagnóstico inicial de maturidade em Gestão Empresarial.</p><br/>
                    <p>O primeiro módulo da trilha é <strong>ROTINA</strong>, pois todos os outros são vinculados ao que você estabelece no gerenciamento da rotina diária da sua empresa.</p>
                    <br/>
                    <p style={{marginBottom: '5px'}}>Após, o caminho a ser percorrido será:</p>
                    {
                        consultStages().map((e,i) => {
                            return (
                                <p><strong>{i+1}-{e.label}</strong></p>
                            )
                        })
                    }
                    <p style={{marginTop: '5px'}}>Ou seja, após a <strong>ROTINA</strong>, começaremos pelo pilar com maior lacuna de maturidade em gestão para o de menor lacuna.</p>
                    <br/>
                    <p>Como parâmetro, seguem os valores de referência de mercado para você já compreender os percentuais que almejamos que sua empresa alcance ao final da sua jornada no MODUM:</p>
                    <br/>
                    <p>Está no caminho certo: entre 70% e 80%</p>
                    <p>O ideal desejado: entre 80% e 90%</p>
                    <p>Sonho de vida de todos os empresários: entre 90% e 100%</p>
                    {/* <br/> */}
                    {/* <p>Agora, você deve estar se perguntando sobre o módulo de <strong>BRANDING / MKT</strong>. Este pilar é um bônus da Agência de Gestão para vc! Ou seja, faça quando achar necessário.</p>
                    <br/>
                    <p>Nós recomendamos que seja o último a ser feito, afinal é um módulo que pode alterar muitos conceitos após percorrer a <strong>PESSOAS</strong>, <strong>PROCESSOS</strong> e <strong>RESULTADOS</strong>.</p>
                    <br/>
                    <p>Outro módulo que não aparece no diagnóstico, mas está presente na Jornada é o Financeiro.</p>
                    <br/>
                    <p>O <strong>Financeiro</strong> está dentro de processos, pessoas e resultados, como todos os outros setores de uma empresa, mas resolvemos trazê-lo como um módulo separado com o intuito de esclarecer dúvidas de termos básicos financeiros e como são realizadas as análises de resultados para tomadas de decisões mais assertivas. Portanto, fique à vontade e aproveite!</p> */}
                    <br/>
                    <p>Para finalizar, é importante ressaltar que a mudança e a consolidação da Cultura Organizacional surge por meio de atitudes, exemplos e comunicação clara e eficaz. Portanto, o MODUM é a ferramenta que auxiliará na jornada a ser percorrida pela sua empresa, mas você e seus líderes serão a mudança efetiva!</p>
                    <br/>
                    <p>Lembre-se: a mudança ocorre a partir do momento que a GENTE simplesmente É.</p>
                    <br/>
                    <p>Se somos, expandimos naturalmente para os OUTROS!</p>
                    <br/>
                    <p>Então... simplesmente seja a mudança!</p>
                    <br/>
                    <button className="btn-link-solid" style={{display: `inline-block`, marginLeft: 0, marginTop: '20px', padding: '10px', textTransform: 'uppercase'}} onClick={() => history.push('/', {
                        started_jornada: true
                    })}>Iniciar jornada</button>
                </div>
            </div>
        </div>
    )
}
