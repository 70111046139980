import RequestWithoutLoading from "../../functions/RequestWithoutLoading";

const route = '/client/practice-notify'

export interface FormPracticenotify {
    practice_id: string
}

class PracticeNotify
{
    async save(form: FormPracticenotify)
    {
        return await RequestWithoutLoading.post(`${route}`, form);
    }
}

export const PracticeNotifyAPI = new PracticeNotify()