import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request"

export interface TaskProps {
    id: string
    backlog_id: string
    date: string
    client_id: string
    hour: string
    hour_finish: string
    protocol_id: string
    feedback_id: string
    status: string
    label: string
    timesheet_start?: string
    feedback?: string
    contacts: Array<
        {
            id: string
            name: string
            email: string
            phone: string
            client_id: string
        }
    >,
    departments: Array<
        {
            id: string
            department_id: string
            calendar_id: string
            label: string
        }
    >,
    subjects: Array<
        {
            id: string
            title: string
            feedback: string
            calendar_id: string
            client_id: string
            status: string
        }
    >,
    links?: {
        id: string
        calendar_id: string
        link: string
    }[],
    tags: Array<
        {
            id: string
            tag_id: string
            calendar_id: string
            label: string
            color: string
        }
    >
    repeat_day?: any,
    repeat_day_advanced?: any,
    time?: any,
    time_finish?: any,
    date_finish?: any,
    day_repeat_day?: string
}

export const ConsultarTask = async (
    id: string,
    onSuccess: ((e: TaskProps) => void)
) => await Request.get(`/calendar/task/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
