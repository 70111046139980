import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/planejamento-estrategico/swot/folders'

export interface ProccessForm {
    id?: string,
    title: string,
    macroproccess_id: string,
}

export interface SwotFolderFormProps {
    title: string,
    date_revision?: string,
    date_created?: string,
    created_by_id?: string
    created_by_name?: string
}

export interface SwotFolderItemProps extends SwotFolderFormProps {
    id: string
}

class SwotFolder
{
    async create(
        data: SwotFolderFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        data: SwotFolderFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: SwotFolderItemProps) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((proccesses: SwotFolderItemProps[]) => void),
    )
    {
        return await Request.get(`${route}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }
}

export const SwotFolderAPI = new SwotFolder()