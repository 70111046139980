import React, { useEffect, useState } from 'react'
import { FaBezierCurve, FaBookReader, FaBullseye, FaChartPie, FaHandHoldingUsd, FaHome, FaInfoCircle, FaLink, FaSignOutAlt, FaTasks, FaUserCog, FaUsers } from 'react-icons/fa'
import { NavLink } from 'react-router-dom'
import { cookies } from '../../functions/Session'
import { AccountAPI, PermissionItemProps } from '../../services/Account/Account'

export interface SidenavbarProps {}

export const Navbar: React.FC<SidenavbarProps> = (props) => 
{
    const [isConsultor, setIsConsultor] = useState(false)
    const [permissions, setPermissions] = useState<PermissionItemProps[]>([])

    const checkingPermission = (permission: string) => permissions.findIndex(item => item.permission === permission) >= 0 ? true : false
    
    useEffect(() => {
        let data_user = cookies.get('user')

        if(data_user.consultor)
        {
            setIsConsultor(true)
        }

        AccountAPI.getPermissions(response => {
            if(response.data)
            {
                setPermissions(response.data)
            }
        })
    }, [])

    return (
        <div className="side-navbar">
            <div className="side-navbar-content">
                <div className="side-navbar-item">
                    <NavLink exact className="title" to="/"><FaHome/><span>Início</span></NavLink>
                </div>
                <hr />
                {/* <div className="side-navbar-item">
                    <NavLink exact className="title" to="/jornada"><FaBookReader/> <span>Minha jornada</span></NavLink>
                </div> */}
                <hr />
                <div className="side-navbar-item">
                    <div className="title"><FaTasks/><span>Rotina</span></div>
                    <ul className="side-navbar-subitens">
                        {
                            [
                                {
                                    title: `Visão geral`,
                                    to: '/tasks'
                                },
                                {
                                    title: `Cronograma: Minha semana`,
                                    to: '/calendar-week'
                                },
                                {
                                    title: `Cronograma: Meu mês`,
                                    to: '/calendar-month'
                                },
                                {
                                    title: `Tarefas concluídas`,
                                    to: '/tasks-finish'
                                },
                                {
                                    title: `Histórico Reunião Diária`,
                                    to: '/myday-feedbacks'
                                },
                                {
                                    title: `ATAS de Reuniões`,
                                    to: '/protocols'
                                },
                                {
                                    title: `Timesheet: Relatório`,
                                    to: '/timesheet/report'
                                },
                                {
                                    title: `Timesheet: Relatório da equipe`,
                                    to: '/timesheet/report/all'
                                }
                            ].map((item, index) => <li key={index} className="side-navbar-subitem"><NavLink exact to={item.to}>{item.title}</NavLink></li>)
                        }
                    </ul>
                </div> 
                {
                    checkingPermission('results') ? (
                        <div className="side-navbar-item">
                            <div className="title"><FaChartPie/><span>Resultados</span></div>
                            <ul className="side-navbar-subitens">
                                {
                                    [
                                        {
                                            title: `Dashboard`,
                                            to: '/indicadores'
                                        },
                                        {
                                            title: `Plano de ação`,
                                            to: '/plan-actions'
                                        },
                                        {
                                            title: `Plano de ação: Concluídas `,
                                            to: '/plan-actions/finish'
                                        },
                                    ].map((item, index) => <li key={index} className="side-navbar-subitem"><NavLink exact to={item.to}>{item.title}</NavLink></li>)    
                                }
                            </ul>
                        </div>
                    ) : null
                }
                {
                    checkingPermission('proccess') ? (
                        <div className="side-navbar-item">
                            <div className="title"><FaBezierCurve/><span>Processos</span></div>
                            <ul className="side-navbar-subitens">
                                {
                                    [
                                        {
                                            title: `Jornada do Cliente`,
                                            to: '/proccess-custumer-journey'
                                        },
                                        {
                                            title: `Mapeamento de Processos`,
                                            to: '/proccess'
                                        },
                                        {
                                            title: `Todos os POPs`,
                                            to: '/all-pops'
                                        },
                                        {
                                            title: `Autorizar POP`,
                                            to: '/authorize-pops'
                                        },
                                        {
                                            title: `Relatório de Não Conformidade`,
                                            to: '/rnc'
                                        },
                                        {
                                            title: `Auditoria Interna`,
                                            to: '/auditoria-interna'
                                        },
                                        {
                                            title: `Analise Critica`,
                                            to: '/analise-critica'
                                        },
                                        {
                                            title: `Controle Mudança`,
                                            to: '/controle-mudanca'
                                        },
                                        {
                                            title: `Manual de Qualidade`,
                                            to: '/manual-de-qualidade'
                                        }
                                    ].map((item, index) => <li key={index} className="side-navbar-subitem"><NavLink exact to={item.to}>{item.title}</NavLink></li>)        
                                }
                            </ul>
                        </div>
                    ) : null
                }
                {
                    checkingPermission('peoples') ? (
                        <div className="side-navbar-item">
                            <div className="title"><FaUsers/><span>Pessoas</span></div>
                            <ul className="side-navbar-subitens">
                                {
                                    [
                                        {
                                            title: `Manual do Colaborador`,
                                            to: '/pessoas/manual'
                                        },
                                        // {
                                        //     title: `Organograma`,
                                        //     to: '/pessoas/organograma'
                                        // },
                                        {
                                            title: `Descrição de Cargos`,
                                            to: '/pessoas/cargos'
                                        },
                                        {
                                            title: `Descrição de Cargos: Aprovação`,
                                            to: '/pessoas/cargos/approved'
                                        },
                                        {
                                            title: `Onboarding`,
                                            to: '/pessoas/onboarding'
                                        },
                                        {
                                            title: `Processo de Feedback`,
                                            to: '/pessoas/proccess-feedback'
                                        },
                                        {
                                            title: `Processo de Feedback Personalizado`,
                                            to: '/pessoas/proccess-feedback-custom'
                                        }
                                    ].map((item, index) => <li key={index} className="side-navbar-subitem"><NavLink exact to={item.to}>{item.title}</NavLink></li>)        
                                }
                            </ul>
                        </div>
                    ) : null
                }
                <div className="side-navbar-item">
                    <div className="title"><FaBullseye /><span>Planejamento estratégico</span></div>
                    <ul className="side-navbar-subitens">
                        {
                            [
                                {
                                    title: `Swot`,
                                    to: '/planejamento-estrategico/swot'
                                },
                                {
                                    title: `Canvas`,
                                    to: '/planejamento-estrategico/canvas'
                                },
                                {
                                    title: `Kanban Pessoal`,
                                    to: '/scrum'
                                },
                                {
                                    title: `Kanban Metodologia AG`,
                                    to: '/scrum-company'
                                },
                                {
                                    title: `Kanban ISO 9001`,
                                    to: '/scrum-iso'
                                }
                            ].map((item, index) => <li key={index} className="side-navbar-subitem"><NavLink exact to={item.to}>{item.title}</NavLink></li>)        
                        }
                    </ul>
                </div>
                {/* {
                    checkingPermission('finance') ? (
                        <div className="side-navbar-item">
                            <div className="title"><FaHandHoldingUsd/><span>Financeiro</span></div>
                            <ul className="side-navbar-subitens">
                                {
                                    [
                                        {
                                            title: `Plano de contas`,
                                            to: '/financeiro/plano-de-contas'
                                        },
                                        {
                                            title: `Lançamentos`,
                                            to: '/financeiro/lancamentos'
                                        },
                                        {
                                            title: `Lançamentos: Gráficos`,
                                            to: '/financeiro/graphics'
                                        },
                                        {
                                            title: `Fluxo de caixa`,
                                            to: '/financeiro/fluxo-caixa'
                                        },
                                        {
                                            title: `Previsão orçamentária`,
                                            to: '/financeiro/previsao-orcamentaria'
                                        },
                                        {
                                            title: `DRE`,
                                            to: '/financeiro/dre'
                                        },
                                        {
                                            title: `DRE Consolidada`,
                                            to: '/financeiro/dre/consolidada'
                                        }
                                    ].map((item, index) => <li key={index} className="side-navbar-subitem"><NavLink exact to={item.to}>{item.title}</NavLink></li>)        
                                }
                            </ul>
                        </div>
                    ) : null
                } */}
                <hr />
                <div className="side-navbar-item">
                    <span className="title"><FaUserCog/> <span>Minha conta</span></span>
                    <ul className="side-navbar-subitens">
                        {
                            [
                                {
                                    title: `Meus planos`,
                                    to: '/plans',
                                    permission: 'plans'
                                },
                                {
                                    title: `Contratos`,
                                    to: '/contracts',
                                    permission: 'configs'
                                },
                                {
                                    title: `Configurações`,
                                    to: '/account',
                                    permission: 'configs'
                                },
                                {
                                    title: `Diagnósticos`,
                                    to: '/diagnostics',
                                    permission: 'diagnostics'
                                },
                                {
                                    title: `Usuários`,
                                    to: '/users',
                                    permission: 'users'
                                },
                                {
                                    title: `Contatos`,
                                    to: '/contacts',
                                    permission: 'contacts'
                                },
                                {
                                    title: `Departamentos`,
                                    to: '/departments'
                                },
                                {
                                    title: `Unidades de negócios/Projetos`,
                                    to: '/projects',
                                    permission: 'projects'
                                },
                                {
                                    title: `Arquivos e anexos`,
                                    to: '/attachment-files'
                                },
                                {
                                    title: `Meus arquivos`,
                                    to: '/my-attachments'
                                },
                                {
                                    title: `Empresas`,
                                    to: '/companies',
                                    show_consultor: true
                                }
                                // {
                                //     title: `Google Integração`,
                                //     to: '/account/google',
                                // }
                            ].map((item, index) => {
                                if(item.show_consultor === false && isConsultor === true)
                                    return null;

                                if(item.show_consultor === true && isConsultor !== true)
                                    return null;

                                if(item.permission)
                                {
                                    if(!checkingPermission(item.permission))
                                    {
                                        return null;
                                    }
                                }

                                return <li key={index} className="side-navbar-subitem"><NavLink exact to={item.to}>{item.title}</NavLink></li>
                            })        
                        }
                    </ul>
                </div>
                <div className="side-navbar-item">
                    <NavLink exact className="title" to="/my-attachments"><FaLink/> <span>Arquivos e Anexos</span></NavLink>
                </div>
                <div className="side-navbar-item">
                    <NavLink exact className="title" to="/faq"><FaInfoCircle/> <span>FAQ</span></NavLink>
                </div>
                <hr />
                <div className="side-navbar-item">
                    <NavLink exact className="title" to="/sair"><FaSignOutAlt/> <span>Sair</span></NavLink>
                </div>
            </div>
        </div>
    )
}
