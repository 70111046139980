import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Success from '../../../../functions/Modals/Success'
import { DiagnosticCreate } from '../../../../services/DiagnosticGenerateResult'
import { DiagnosticMounted, DiagnosticStages } from '../../../../services/DiagnosticMounted'
import { ValueQuestion } from './components/ValueQuestion'
import Warning from '../../../../functions/Modals/Warning'

export interface INewDiagnostic {}

export const NewDiagnostic: React.FunctionComponent<INewDiagnostic> = () => 
{
    const [diagnostic, setDiagnostic] = useState<Array<DiagnosticStages>>([])
    const [stageIndex, setStageIndex] = useState<number>(0)
    const history = useHistory();

    useEffect(() => {
        DiagnosticMounted(e => setDiagnostic(e.status))        

    }, [])
    
    return (
        <>
            <div className="diagnostic-form">
                <div className="content">
                    {
                        diagnostic.map((stage, index) => {
                            return (
                                <div id={`tab_${index}`} title={stage.label} key={`${index}`} style={stageIndex === index ? {display: 'block'} : {display: 'none'}}>
                                    <span className="title-stage">{stage.label}</span>
                                    {
                                        stage.questions.map((question, index_question) => {
                                            return (
                                                <div className="question" key={`${stage.id}`}>
                                                    <div className="question-header">
                                                        <span className="title">{question.question.replace("\\", "").replace('\\"', '"')} {
                                                            question.description ? <span title={question.description.replace("\\", " ").replace('/', '')} className="description">?</span> : null
                                                        }</span>
                                                        
                                                    </div>
                                                    <ValueQuestion value={diagnostic[index]['questions'][index_question]['response_value']} onChange={value => {
                                                        let question_stages = diagnostic;
                                                        
                                                        question_stages[index]['questions'][index_question]['response_value'] = value

                                                        setDiagnostic([...question_stages])
                                                    }}/>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        })
                    }
                    {
                        stageIndex !== 0 ?
                        (
                            <button className="btn-question" onClick={() => {
                                let new_stage = stageIndex-1;
                                if(new_stage < 0)
                                {
                                    new_stage = 0;
                                }
                                setStageIndex(new_stage)
                            }}>
                                Anterior
                            </button>
                        ) : null
                    }
                    {
                        stageIndex !== diagnostic.length-1 ? 
                        (
                            <button className="btn-question" onClick={() => {
                                let question_stages = diagnostic;
                                                        
                                let question_opens = question_stages[stageIndex]['questions'].filter(e => e.response_value === "")

                                if(question_opens.length)
                                {
                                    Warning('Responda todas as questões')
                                    return;
                                }

                                let new_stage = stageIndex;

                                new_stage++;

                                setStageIndex(new_stage)

                                window.location.hash = `tab_${new_stage}`
                            }}>
                                Próximo
                            </button>
                        ) : (
                            <button className="btn-question bkg-success" onClick={() => {
                                DiagnosticCreate({status: 3, data: diagnostic}, e => Success('Diagnóstico realizado', () => {
                                    history.push('/new-diagnostic/result', {
                                        points: e.points
                                    })
                                }))
                            }}>
                                Concluir
                            </button>
                        )
                    }
                </div> 
                <div className="sign-out">
                    <Link to="/diagnostics" >Deseja realizar mais tarde? Clique aqui</Link>
                </div>
            </div>
        </>
    )
}
