import { AxiosResponse } from "axios"
import Request from "../../functions/Request"
import { ResponseMyDayFeedback } from "./FeedbackModule";

export const ViewFeedback = async (
    id: string,
    onSuccess: ((e: ResponseMyDayFeedback) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.get(`/my-day/${id}`).then(e => onSuccess(e.data)).catch(onError);

export const CheckMyFeedbackItem = async (
    id: string,
    onSuccess: ((e: AxiosResponse) => void),
    onError?: ((e: AxiosResponse) => void)
) => await Request.post(`/my-day/feedback/checked/${id}`).then(onSuccess).catch(onError);
