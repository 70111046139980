import React, { useEffect, useState } from 'react'
import { formatValue } from 'react-currency-input-field'
import { Link, useParams } from 'react-router-dom'
import { Content } from '../../components/Content'
import { LoadingContent } from '../../components/LoadingContent'
import { Navbar } from '../../components/Navbar'
import { ConfirmDelete } from '../../functions/Modals/ConfirmDelete'
import Success from '../../functions/Modals/Success'
import Warning from '../../functions/Modals/Warning'
import { getMyPaymentsAPI, PaymentProps } from '../../services/Account/getMyPayments'

export interface MyPlanProps {}
export interface MyPlanRouteProps {
    id: string
}

export const MyPlan: React.FC<MyPlanProps> = (props) => 
{
    const [plan, setPlan] = useState<PaymentProps>({
        id: '',
        code_payment: '',
        company_id: '',
        status: '',
        date: '',
        date_started: '',
        date_finish: '',
        plan_code: '',
        plan_name: '',
        plan_price: '',
        date_payment: '',
        method: '',
        payment_link: '',
        payment_status: '',
        status_code: "OPEN",
        payments: []
    })

    const { id } = useParams<MyPlanRouteProps>();

    useEffect(() => {
        getMyPaymentsAPI.payment(id, setPlan);
    }, [id])

    return (
        <>
            <Navbar/>
            {
                plan.id !== '' ? (
                    <Content title={(
                        <>
                            <div className="title-button">
                                <div className="title">
                                    <h1 className="app-content-h1-destaque">Visualizando plano</h1>
                                </div>
                                <div className="options">
                                    <Link to="/plans/plans">Alterar plano</Link>
                                    {
                                        plan.status_code !== "CANCELED" ? (
                                            <button className="bkg-danger clr-white" onClick={() => {
                                                ConfirmDelete(() => {
                                                    if(plan.id)
                                                    {
                                                        getMyPaymentsAPI.cancelPayment(plan.id, response => Success(response.message, () => window.location.href = '/plans'), err => Warning(err.message))
                                                    }
                                                }, () => null,
                                                'Você realmente quer cancelar este plano? Caso tenha tempo em aberto ele será tera seu acesso liberado até lá.', 'Sim, cancele.')
                                            }}>Cancelar plano</button>
                                        ) : null
                                    }
                                </div>
                            </div>
                        </>
                    )}>
                        <div className="content-box">
                            <p className="title-box">Plano</p>
                            <p>{plan.plan_name}</p>
                            <p className="title-box">Valor</p>
                            <p>{formatValue({value: plan.plan_price, intlConfig: {locale: 'pt-BR', currency: 'BRL'}})}</p>
                            <p className="title-box">Metodo de pagamento</p>
                            <p>{plan.method === 'credit_card' ? 'Cartão de crédito' : 'Boleto'}</p>
                            <p className="title-box">Status do plano</p>
                            <p>{plan.payment_status}</p>
                            <p className="title-box">Pagamentos</p>
                            {
                                plan.payments?.map((item, index) => (
                                    <div style={{marginBottom: '20px', borderBottom: '1px solid #eee', padding: '20px 0'}}>
                                        <p>{item.date_payment}</p>
                                        <p><i>{item.status}</i></p>
                                        {
                                            item.boleto && item.boleto.url ?
                                            (
                                                <>
                                                    <button style={{marginTop: '10px'}} className="btn-styled success" onClick={() => {
                                                        window.open(item.boleto?.url, '_blank')
                                                    }}>
                                                        Visualizar boleto
                                                    </button>
                                                </>
                                            ) : null
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    </Content>
                ) : <LoadingContent/>
            }
        </>
    )
}