import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const  route = '/client/indicators'

export interface ControllerIndicatorsFormProps {
    department_id?: string
    title?: string
    type_id?: string
    obs?: string
    formula?: string
    unit_id?: string
    polarity_id?: string
    frequency_id?: string
    id?: string
}

export interface FormSaveMyIndicator {
    indicator_id: string
}

export interface FormSaveData {
    datetime: string
    indicator_id: string
    value: string
}

export interface ControllerIndicatorsStructProps {
    deparments?: Array<{
        id: string
        label: string
    }>,
    types?: Array<{
        id: string
        title: string
    }>,
    units?: Array<{
        id: string
        title: string
    }>,
    polatiries?: Array<{
        id: string
        title: string
    }>,
    frequencies?: Array<{
        id: string
        title: string
    }>
}

export interface ControllerIndicatorsStructureResponse {
    structure: ControllerIndicatorsStructProps,
    message: string
}

export interface ControllerIndicatorsItemProps {
    formula: string
    id: string
    obs: string
    title: string
    edit?: boolean,
    indicator_id?: string
    type: {
        id: string
        title: string
    },
    department: {
        id: string
        label: string
    },
    unit: {
        id: string
        title: string
    },
    frequency: {
        id: string
        title: string
    },
    polarity: {
        id: string
        title: string
    }
}

export interface ControllerIndicatorDataItemHistoryProps extends ControllerIndicatorsItemProps {
    date: string
    value: string
}

export interface ControllerIndicatorsConsultProps {
    indicators?: Array<ControllerIndicatorsItemProps>
    indicator?: ControllerIndicatorsItemProps
    message: string
}

class Indicators 
{
    async create(
        data: ControllerIndicatorsFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async saveMyIndicator(
        data: FormSaveMyIndicator,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}/save-my-indicator`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async saveData(
        data: any,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}/save-data`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        data: ControllerIndicatorsFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((success: ControllerIndicatorsConsultProps) => void),
    )
    {
        return await Request.get(`${route}`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }
    
    async getAllMy(
        onSuccess: ((success: ControllerIndicatorsConsultProps) => void),
    )
    {
        return await Request.get(`${route}/my-indicators`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }

    async getAllHistoryByIndicator(
        indicator: string,
        onSuccess: ((success: Array<ControllerIndicatorDataItemHistoryProps>) => void),
    )
    {
        return await Request.get(`${route}/history/${indicator}`).then(e => onSuccess(e.data.history)).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: ControllerIndicatorsConsultProps) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }

    async getStructure(
        onSuccess: ((success: ControllerIndicatorsStructureResponse) => void),
    )
    {
        return await Request.get(`${route}/structure`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async removeMyIndicator(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/my-indicator/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }
}

export const ControllerIndicators = new Indicators()