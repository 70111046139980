import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/finance/subtopic'

export interface FormTopicItemsProps {
    topic?: string
    topic_id?: string
}

export interface TopicItemProps {
    name?: string
    topic_id?: string
    client_id?: string
    id?: string
}

class TopicItems 
{
    async create(
        data: FormTopicItemsProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        data: FormTopicItemsProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        id: string,
        onSuccess: ((success: Array<TopicItemProps>) => void),
    )
    {
        return await Request.get(`${route}/topic/${id}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: TopicItemProps) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }
}

export const TopicItemsAPI = new TopicItems()