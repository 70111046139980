import React, { useEffect, useState } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'
import { EditorHTML } from '../../../../components/EditorHTML'
import { AnaliseCriticaItemFormProps, AnaliseCriticaItensItemProps, analise_critica_itens_api } from '../services/analise.critica-itens.services'
import Success from '../../../../functions/Modals/Success'
import { formatDate } from '../../../../functions/dataFormat'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../../services/ClientDepartments/ClientDepartments'
import { AnaliseCriticaItemProps } from '../../AnaliseCritica/services/analise.critica.services'
import { useParams } from 'react-router-dom'
import { FaEdit, FaTrash } from 'react-icons/fa'

export interface FormPOPProps {
    id: string,
    particular?: boolean
    onSuccess?: (() => void)
}

export const FormAnaliseCriticaItens: React.FC<FormPOPProps> = (props) => 
{
    const params = useParams<{id: string}>();
    const [createdStatus, setCreatedStatus] = useState(false)

    const handleAdd = () => {
        let tmp_items : AnaliseCriticaItemFormProps[] = items

        if(props.particular)
        {
            tmp_items.push({
                ...form, 
                analise_critica_client_id: params.id,
                assunto_entrada_id: props.id
            })
        }else{
            tmp_items.push({
                ...form,
                analise_critica_id: params.id,
                assunto_entrada_id: props.id
            })
        }
        

        setItems([...tmp_items])
        setForm({
            acao: '',
            resp: '',
            prazo: '',
            custo: '',
            eficacia_saidas: ''
        })
    }

    const onSubmit = () => {
        if(props.particular)
        {
            analise_critica_itens_api.create(items, () => Success('Criado com sucesso!', () => window.location.reload()))
        }else{
            analise_critica_itens_api.create(items, () => Success('Criado com sucesso!', () => window.location.reload()))
        }
    }

    const [form, setForm] = useState<AnaliseCriticaItemFormProps>({})
    const [items, setItems] = useState<AnaliseCriticaItemFormProps[]>([])

    useEffect(() => {
        if(props.id)
        {
            analise_critica_itens_api.getById(params.id, props.id.toString(),  data => {
                setCreatedStatus(true)
                let items = data.map((item) => {
                    return {
                        acao: item.acao,
                        resp: item.resp,
                        eficacia_saidas: item.eficacia_saidas,
                        prazo: item.prazo,
                        custo: item.custo,
                        analise_critica_client_id: item.analise_critica_client_id,
                        analise_critica_id: item.analise_critica_id,
                        assunto_entrada_id: item.assunto_entrada_id?.toString()
                    }
                })

                setItems(items)
            }, props.particular ? 'particular' : undefined)
        }
    }, [props.id])

    const changeEvent = (event: React.ChangeEvent<HTMLSelectElement|HTMLInputElement>) => {
        setForm({
            ...form,
            ...{ [event.target.name] : event.target.value }
        })
    }

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={(event) => {
                event.preventDefault()

            }}>
                <div className="box-input">
                    <p>Ação</p>
                    <input type="text" onChange={changeEvent} value={form.acao} name="acao" />
                </div>
                <div className="box-input">
                    <p>Responsável</p>
                    <input type="text" onChange={changeEvent} value={form.resp} name="resp" />
                </div>
                <div className="box-input">
                    <p>Prazo</p>
                    <input type="text" onChange={changeEvent} value={form.prazo} name="prazo" />
                </div>
                <div className="box-input">
                    <p>Custo</p>
                    <input type="text" onChange={changeEvent} value={form.custo} name="custo" />
                </div>
                <div className="box-input">
                    <p>Eficácia / Saídas</p>
                    <input type="text" onChange={changeEvent} value={form.eficacia_saidas} name="eficacia_saidas" />
                </div>
                <hr />
                <div className='box-items'>
                    <p>Items criados:</p>
                    <div className='box-items-list'>
                        <div className='box-items-list-item'>
                            <div>Ação</div>
                            <div>Resposável</div>
                            <div>Prazo</div>
                            <div>Custo</div>
                            <div>Efic. Saidas</div>
                            <div>Opções</div>
                        </div>
                        {items.map((item, key) => {
                            return (
                                <div className='box-items-list-item'>
                                    <div>{item.acao}</div>
                                    <div>{item.resp}</div>
                                    <div>{item.prazo}</div>
                                    <div>{item.custo}</div>
                                    <div>{item.eficacia_saidas}</div>
                                    <div>
                                        <FaEdit style={{marginRight: '10px', cursor: 'pointer'}} onClick={() => {
                                            let tmp_items = items.filter((item, index) => key != index)

                                            setItems(tmp_items)

                                            setForm({
                                                ...item
                                            })
                                        }} />
                                        <FaTrash style={{cursor: 'pointer'}} onClick={() => {
                                            let tmp_items = items.filter((item, index) => key != index)

                                            setItems(tmp_items)
                                        }} />
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="flex between">
                    <button type="submit" style={{marginRight: '10px'}} onClick={() => handleAdd()}><MdAdd/> Adicionar</button>
                    <button type="submit" style={{marginRight: '10px'}} onClick={() => onSubmit()}><MdAdd/> Salvar</button>
                </div>
            </form>
        </>
    )
}