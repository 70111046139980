import { AxiosResponse } from "axios";
import Request from "../../functions/Request";

export interface IAtividadesItem {
    label: string,
    id: string
}

export interface DataCriarRotina {
    date?: string,
    repeat_day?: any,
    repeat_day_advanced?: any,
    tag_id?: string,
    time?: string,
    time_finish?: string,
    protocol_id?: string,
    label: string,
    contacts?: any
    departments?: any,
    subjects?: any,
    send_notify?: string
}
// '/rotina/macro-atividade/client', data, 
const CriarRotina = async (
    data: FormData,
    success: ((success: AxiosResponse) => void),
    error: ((error: AxiosResponse) => void)
) => {
    return Request.post(`/rotina/macro-atividade/client`, data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }).then(success).catch(error);
}

export {
    CriarRotina
}