import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/my-diagnostics'

export interface DiagnosticsItemProps {
    id?: number
    status?: number
    client_id?: number
    data?: string
    date_create?: string
    date_real_start?: string
    date_real_finish?: string
    days_to_complete?: number
}

class Diagnostics 
{
    async get(
        onSuccess: ((success: DiagnosticsItemProps[]) => void),
    )
    {
        return await Request.get(`${route}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }
}

export const DiagnosticsAPI = new Diagnostics()