import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/indicators-meta'

export interface FormMeta {
    meta?: string
    date?: string
    indicator?: string
    id?: string
}

export interface ListIndicatorsItem {
    id: string,
    title: string
}

export interface MetaItem {
    id: string
    meta: string
    date: string
    indicator_id: string
    client_id: string
    title: string
}

class IndicatorsMeta 
{
    async create(
        data: FormMeta,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        data: FormMeta,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((success: Array<MetaItem>) => void),
    )
    {
        return await Request.get(`${route}`).then(e => onSuccess(e.data.metas)).catch(DefaultErrorResponse);
    }

    async getAllIndicators(
        onSuccess: ((success: Array<ListIndicatorsItem>) => void),
    )
    {
        return await Request.get(`${route}/list-indicators`).then(e => onSuccess(e.data.indicators)).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: MetaItem) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(e => onSuccess(e.data.meta)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }
}

export const ControllerIndicatorsMeta = new IndicatorsMeta()