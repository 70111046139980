import { AxiosResponse } from "axios";
import Request from "../../../../functions/Request";
import DefaultErrorResponse from "../../../../functions/DefaultErrorResponse";

const route = '/client/auditoria-interna'

export interface AuditoriaInternaItemProps {
    id?: number
    title?: string
    auditores?: string
    processos_auditados?: string
    metodologia?: string
    date_next_review?: string
    date_create?: string
}

class AuditoriaInternaService
{
    async create(
        data: AuditoriaInternaItemProps,
        onSuccess: ((success: AuditoriaInternaItemProps) => void),
    )
    {
        return await Request.post(`${route}`, data).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        data: AuditoriaInternaItemProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((proccesses: Array<AuditoriaInternaItemProps>) => void),
    )
    {
        return await Request.get(`${route}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((rnc: AuditoriaInternaItemProps) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }
}

export const auditoria_api = new AuditoriaInternaService()