import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaHistory, FaChartBar, FaChartLine } from 'react-icons/fa'
import { MdArrowBack, MdModeEdit, MdDeleteForever } from 'react-icons/md'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Content } from '../../../../../components/Content'
import { ConfigModal } from '../../../../../components/Modal'
import { ModalRight } from '../../../../../components/ModalRight'
import { Navbar } from '../../../../../components/Navbar'
import { NoWithContent } from '../../../../../components/NoWithContent'
import { formatDate } from '../../../../../functions/dataFormat'
import { ConfirmDelete } from '../../../../../functions/Modals/Confirm'
import { FeedbackAPI, FeedbackItemProps } from '../../../../../services/ProccessFeedback/Feedback'
import { FormFeedback } from './components/FormFeedback'
import { CollaboratorAPI, CollaboratorItemProps } from '../../../../../services/ProccessFeedback/Collaborator'
import { CollaboratorPFProps } from '../CollaboratorPF'

export interface FeedbacksPFProps {}

export interface FeedbacksPFRouteParams {
    collaborator: string
}

export const FeedbacksPF: React.FC<FeedbacksPFProps> = (props) => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [list, setList] = useState<Array<FeedbackItemProps>>([])
    const [colaborador, setColaborador] = useState<CollaboratorItemProps>()
    const history = useHistory()
    const { collaborator } = useParams<FeedbacksPFRouteParams>()
    const loading = useRef(true)

    useEffect(() => {
        FeedbackAPI.getAll(collaborator, setList)
        CollaboratorAPI.getById(collaborator, setColaborador)

        loading.current = false
    }, [collaborator])

    return (
        <>
            <ModalRight {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <button className="history-back" onClick={() => history.push(`/pessoas/proccess-feedback/collaborator/${collaborator}`)}><MdArrowBack /></button>
                            <h1 className="app-content-h1-destaque">Processo de Feedback: {colaborador?.name} - {colaborador?.cargo}</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Lista de Feedbacks</h2>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    content: <FormFeedback onSuccess={() => FeedbackAPI.getAll(collaborator, setList)} />,
                                    onClose: () => setModalConfig({
                                        show: false
                                    }),
                                    title: <h2>Criar feedback</h2>
                                })
                            }}>Criar feedback</button>
                            {
                                list.length > 0 ? <Link to={`/pessoas/proccess-feedback/collaborator/feedback/${collaborator}/graphic`}>Gerar gráfico</Link> : null
                            }
                        </div>
                    </div>
                    <br/>
                    {
                        list.length > 0 ?
                        (
                            list.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title">Feedback feito em: {formatDate(item.date_create)}</div>
                                                    <div className="options">
                                                        <button className="update-button" onClick={() => {
                                                            setModalConfig({
                                                                show: true,
                                                                content: <FormFeedback id={item.id} onSuccess={() => {
                                                                    FeedbackAPI.getAll(collaborator, setList)

                                                                    setModalConfig({
                                                                        show: false
                                                                    })
                                                                }} />,
                                                                onClose: () => setModalConfig({
                                                                    show: false
                                                                }),
                                                                title: <h2>Atualizar feedback</h2>
                                                            })
                                                        }}><MdModeEdit/></button>
                                                        <button title='Gerar gráfico?' className="update-button" onClick={() => {
                                                            history.push(`/pessoas/proccess-feedback/collaborator/feedback/${collaborator}/graphic/${item.id}`)
                                                        }}><FaChartBar/></button>
                                                        <button className="comum-button" title="Verificar o histórico" onClick={() => {
                                                            history.push(`/history-feedbacks/${item.id}`)
                                                        }}><FaHistory /></button>
                                                        <button className="delete-button" onClick={() => {
                                                            ConfirmDelete(() => {
                                                                if(item.id)
                                                                {
                                                                    FeedbackAPI.delete(item.id, () => {
                                                                        setList([...list.filter(i => i.id !== item.id)])
                                                                    })
                                                                }
                                                            }, () => null)
                                                        }}><MdDeleteForever/></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : <NoWithContent/>
                    }
                </div>
            </Content>
        </>
    )
}