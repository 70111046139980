import React, { useEffect, useState } from 'react'
import { ConfigModal, ModalRight } from '../../../components/ModalRight'
import { useHistory } from 'react-router-dom'
import { Navbar } from '../../../components/Navbar'
import { Content } from '../../../components/Content'
import { ButtonBackHistory } from '../../../components/ButtonBackHistory'
import { FormAuditoriaInterna } from './components/FormAuditoriaInterna'
import { FaArrowRight, FaEdit, FaPlus } from 'react-icons/fa'
import { AuditoriaInternaItemProps, auditoria_api } from './services/auditoria.services'

export interface AuditoriaInternaProps {}

export const AuditoriaInterna: React.FC<AuditoriaInternaProps> = (props) => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({
        show: false
    })

    const [rows, setRows] = useState<AuditoriaInternaItemProps[]>([])

    const history = useHistory()

    useEffect(() => {
        auditoria_api.getAll((data) => {
            setRows(data)
        })
    }, [])

    return (
        <>
            <ModalRight {...modalConfig} />
            <Navbar/>
            <Content title={(
                <div className='title-button'>
                    <div className="title-button">
                        <div className="title">
                            <ButtonBackHistory onClick={() => history.goBack() }/>
                            <h1 className="app-content-h1-destaque">Auditoria Interna</h1>
                        </div>
                    </div>
                    <div className="options">
                        <button onClick={() => {
                            setModalConfig({
                                show: true,
                                content: <FormAuditoriaInterna onSuccess={() => window.location.reload()} />,
                                onClose: () => setModalConfig({
                                    show: false
                                }),
                                title: <h2>Nova Auditoria</h2>
                            })
                        }}>Novo <FaPlus /></button>
                    </div>
                </div>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Já criados</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        rows.length > 0 ?
                        (
                            rows.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title">{item.title}</div>
                                                    <div className="options">
                                                        <button className="update-button" onClick={() => {
                                                            setModalConfig({
                                                                show: true,
                                                                content: <FormAuditoriaInterna onSuccess={() => window.location.reload()} id={item.id} />,
                                                                onClose: () => setModalConfig({
                                                                    show: false
                                                                }),
                                                                title: <h2>Atualizar Auditoria Interna</h2>
                                                            })
                                                        }}><FaEdit /></button>
                                                        <button className="update-button" onClick={() => {
                                                            history.push(`/auditoria-interna/${item.id}`)
                                                        }}><FaArrowRight /></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) :
                        (
                            <h3>Sem informações para serem mostradas</h3>
                        )
                    }
                </div>
            </Content>
        </>
    )
}