import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import Success from '../../../../functions/Modals/Success'
import { SwotSubjectFormProps, SwotSubjectsAPI } from '../../../../services/PlanejamentoEstrategico/SwotSubjects'

export interface FormSwotSubjectProps {
    id?: string,
    folder_id: string,
    field_id: string,
    onSuccess?: (() => void)
}

export const FormSwotSubject: React.FC<FormSwotSubjectProps> = (props) => 
{
    const [form, setForm] = useState<SwotSubjectFormProps>({
        title: '',
        field_id: '',
        folder_id: ''
    })
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        if(props.id)
        {
            SwotSubjectsAPI.update(props.id, form, s => Success('Salvo com sucesso!', async () => {
                if(props.onSuccess)
                {
                    props.onSuccess()
                }
            }))
        }else{
            SwotSubjectsAPI.create(form, s => Success('Salvo com sucesso!', async () => {
                if(props.onSuccess)
                {
                    props.onSuccess()
                }
            }))
        }
    }

    useEffect(() => {
        if(props.id)
        {
            SwotSubjectsAPI.getById(props.id, props.folder_id, data => {
                setForm({
                    title: data.title,
                    field_id: props.field_id,
                    folder_id: props.folder_id
                })
            })
        }

        setForm({
            title: '',
            field_id: props.field_id,
            folder_id: props.folder_id
        })
    }, [props.id, props.field_id, props.folder_id])

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                <div className="box-input">
                    <p>Descreva</p>
                    <input type="text" onChange={e => setForm({...form, title: e.target.value})} value={form.title} />
                </div>
                <button type="submit"><MdAdd/> Salvar</button>
            </form>
        </>
    )
}