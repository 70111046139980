import Modal from "../Modal"

const Loading = ($text?: string) => {
    Modal.fire({
        title: 'Carregando...',
        text: $text,
        showConfirmButton: false,
        willOpen: () => {
            Modal.showLoading()
        }
    })
}


export default Loading