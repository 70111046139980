import React, { useEffect, useRef, useState } from 'react'
import { FaArrowLeft, FaArrowRight, FaCalendarAlt, FaGripHorizontal, FaListUl, FaPlus, FaTags } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { Content } from '../../components/Content'
import { LinkBackHistory } from '../../components/LinkBackHistory'
import { ConfigModal, ModalRight } from '../../components/ModalRight'
import { Navbar } from '../../components/Navbar'
import { ControllerCalendarWeek } from '../../services/Calendar/ControllerCalendarWeek'
import { ResponseGenerateMonthItem } from '../../services/Calendar/GenerateMonth'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { TaskForm } from '../../components/TaskForm'
import { MyTags } from '../Tasks/components/MyTags'

export const CalendarWeek = () => 
{
    const [week, setWeek] = useState<Array<ResponseGenerateMonthItem>>([])
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [typeCalendar, setTypeCalendar] = useState('grid')
    const [lastAction, setLastAction] = useState({
        date: "",
        type: ""
    })

    const history = useHistory()
    const loading = useRef(true)

    const render = async (msg?: string) => {
        if(lastAction.date != "" && lastAction.type != "")
        {
            ControllerCalendarWeek.consultPersonalize(lastAction.date, lastAction.type, response => setWeek(response.data.week), err => {})

            if(msg)
            {
                toast.success(msg)
            }

            return;
        }

        await ControllerCalendarWeek.consult(response => {
            setWeek(response.data.week)

            if(msg)
            {
                toast.success(msg)
            }
        },
        err => {
            console.log(err)
        })

    }

    useEffect(() => {
        render()

        loading.current = false
    }, [])

    return (
        <>
            <ModalRight {...modalConfig}/>
            <Navbar/>
            <Content loading={loading.current} title={
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/tasks"/>
                            <h1 className="app-content-h1-destaque">Minha semana</h1>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    title: <h2>Minhas etiquetas</h2>,
                                    content: <MyTags/>,
                                    onClose: () => setModalConfig({show: false})
                                })
                            }}><FaTags/> Etiquetas</button>
                            <button onClick={() => history.push('/myday-feedbacks')}>Histórico de Reunião Diária</button>
                            <button onClick={() => history.push('/protocols')}>ATAS de Reuniões</button>
                            <button title="Vizualizar Mês" onClick={() => history.push('/calendar-month')}><FaCalendarAlt/></button>
                            <button title="Vizualizar em lista" onClick={() => setTypeCalendar('list')}><FaListUl/></button>
                            <button title="Vizualizar em grid" onClick={() => setTypeCalendar('grid')}><FaGripHorizontal/></button>
                            <button className="bkg-theme clr-white" title="Nova tarefa" onClick={() => {
                                setModalConfig({
                                    show: true,
                                    title: (
                                        <>
                                            <h2>Criar tarefa</h2>
                                        </>
                                    ),
                                    content: <TaskForm type="CREATE" onChange={() => {
                                        render("Feito!")

                                        setModalConfig({ show: false })
                                    }} />,
                                    onClose: () => setModalConfig({show: false})
                                })
                            }}><FaPlus/></button>
                            <button className="bkg-theme clr-white" title="Voltar" onClick={() => {
                                if(week.length)
                                {
                                    setLastAction({
                                        date: week[0].date_filter,
                                        type: "last"
                                    });

                                    ControllerCalendarWeek.consultPersonalize(week[0].date_filter, 'last', response => setWeek(response.data.week), err => {})
                                }
                            }}><FaArrowLeft/></button>
                            <button className="bkg-theme clr-white" title="Avançar" onClick={() => {
                                if(week.length)
                                {
                                    setLastAction({
                                        date: week[week.length-1].date_filter,
                                        type: "next"
                                    });

                                    ControllerCalendarWeek.consultPersonalize(week[week.length-1].date_filter, 'next', response => setWeek(response.data.week), err => {})
                                }
                            }}><FaArrowRight/></button>
                        </div>
                    </div>
                </>
            }>
                <div className={typeCalendar === "grid" ? 'calendar calendar-grid' : 'calendar calendar-list'}>
                    {
                        week.length > 0 ?
                        (
                            <>
                                {
                                    week.map((e, index) => {
                                        return (
                                            <div key={`${index}`} className="calendar-day" style={typeCalendar === "list" ? {height: 'auto', maxHeight: '100%'} : {minHeight: '500px', maxHeight: '100%'}}>
                                                <p className="day-name">{`${e.date_formatted}# ${e.name}`}</p>
                                                {
                                                    e.tasks ? (
                                                        <div className="tasks" style={{minHeight: '90%', maxHeight: 'auto'}}>
                                                            {
                                                                <>
                                                                    {
                                                                        e.tasks.map(task => {
                                                                            return (
                                                                                <div title={task.label} onClick={() => {
                                                                                    setModalConfig({
                                                                                        show: true,
                                                                                        title: (
                                                                                            <>
                                                                                                <h2 className="title-modal">{task.label}</h2>
                                                                                            </>
                                                                                        ),
                                                                                        content: <TaskForm 
                                                                                            id={task.id}
                                                                                            type="UPDATE"
                                                                                            onChange={() => {
                                                                                                render('Feito!')
                                                                                                setModalConfig({show: false})
                                                                                            }}
                                                                                        />,
                                                                                        onClose: () => setModalConfig({show: false})
                                                                                    })
                                                                                }} className="calendar-task">
                                                                                    <div className="flex" style={task.tags.length > 1 || task.departments.length > 1 ? {flexDirection: 'row'} : {}}  >
                                                                                        {
                                                                                            task.tags && task.tags.length ? 
                                                                                            (
                                                                                                <>
                                                                                                    {
                                                                                                        task.tags.map((tag, item) => {
                                                                                                            return (
                                                                                                                <div className="tag-task" style={{background: `${tag.color}`}}>
                                                                                                                    {tag.label}
                                                                                                                </div>
                                                                                                            )
                                                                                                        })
                                                                                                    }
                                                                                                </>
                                                                                            ) : null
                                                                                        } 
                                                                                        {
                                                                                            task.departments.length ? (
                                                                                                task.departments.map(e => {
                                                                                                    return (
                                                                                                        <div style={{marginRight: '4px'}} className="tag-task bkg-blue-dark-light">{e.label}</div>
                                                                                                    )
                                                                                                })
                                                                                            ) : null
                                                                                        }
                                                                                    </div>
                                                                                    <>
                                                                                        <span className="time-task">{task.hour} - {task.hour_finish}#</span> {task.label}
                                                                                    </>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </>
                                                            }
                                                        </div>
                                                    ) : null
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </>
                        ) : null
                    }
                </div>
            </Content>   
            <ToastContainer pauseOnFocusLoss={false}  position="bottom-right" pauseOnHover={false} autoClose={1500} />
        </>
    )
}
