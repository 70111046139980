import React, { useEffect, useState } from 'react'
import { Content } from '../../components/Content'
import { Navbar } from '../../components/Navbar'


export const AccountGoogle = (props) => 
{
    const [loading, setLoading] = useState(true)
    const [status, setStatus] = useState(false)
    const [user, setUser] = useState()

    const gapi = window.gapi

    useEffect(() => {
        setLoading(false)

        gapi.load('client:auth2', initClient);
    }, [])

    var CLIENT_ID = '1060989373894-9e5junlk4cund97djrl8bfhhisfmgdmt.apps.googleusercontent.com';
    var API_KEY = 'AIzaSyDa0Gl0lhgnwZ3OyLh-45Cil5YOGMdRIrk';

    // Array of API discovery doc URLs for APIs used by the quickstart
    var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];

    // Authorization scopes required by the API; multiple scopes can be
    // included, separated by spaces.
    var SCOPES = "https://www.googleapis.com/auth/calendar";

    var authorizeButton = document.getElementById('authorize_button');
    var signoutButton = document.getElementById('signout_button');

      /**
       *  Initializes the API client library and sets up sign-in state
       *  listeners.
       */
      function initClient() {
        gapi.client.init({
          apiKey: API_KEY,
          clientId: CLIENT_ID,
          discoveryDocs: DISCOVERY_DOCS,
          scope: SCOPES
        }).then(function () {
          gapi.client.load('calendar', 'v3', () => {
              console.log('Calendário carregado')
          })

          const authInstance = gapi.auth2.getAuthInstance();

          authInstance.grantOfflineAccess().then((res) => {
              console.log(res);
          });

        }, function(error) {
          console.log(error)
        });
      }

      /**
       *  Sign in the user upon button click.
       */
      const handleAuthClick = (event) => {
        gapi.auth2.getAuthInstance().signIn().then((response) => {
            console.log(response)
            setStatus(true)
        });
      }

      /**
       *  Sign out the user upon button click.
       */
      const handleSignoutClick = (event) => {
        gapi.auth2.getAuthInstance().signOut().then((response) => {
            console.log(response)
            setStatus(false)
        });
      }

      const handleGrandPermissionsOffline = () => {
        gapi.auth2.getAuthInstance().grantOfflineAccess({
            scope: SCOPES
        }).then(response => {
            console.log(response)
        })
      }

    return (
        <>
            <Navbar/>
            <Content loading={loading}>
                <div className="content-box">
                    <p>Para fazermos a integração com o Google em especifico a sua agenda, precisamos que nos autorize</p>
                    {/* <GoogleLogin
                      scope='calendar'
                      clientId={CLIENT_ID}
                      buttonText="Login"
                      accessType='offline'
                      prompt="consent"
                      onSuccess={response => {
                        console.log(response)
                      }}
                      onFailure={response => {
                        console.log(response)
                      }}
                    /> */}
                    <div style={{marginTop: '20px'}}>
                        <button className="btn" style={{marginRight: '10px'}} onClick={handleAuthClick} id="authorize-button">Autorizar</button>
                        <button className="btn" style={{marginRight: '10px'}} onClick={handleSignoutClick} id="signout-button">Desconectar</button>
                        <button className="btn" style={{marginRight: '10px'}} onClick={handleGrandPermissionsOffline} id="signout-button">Permitir acessos</button>
                        <button className="btn" style={{marginRight: '10px'}} onClick={() => {
                            console.log(user)
                        }}>Visualizar</button>
                    </div>
                    <div>
                        {status ? 'Logado' : 'Não logado'}
                    </div>
                </div>
            </Content>
        </>
    )
}