import React, { useEffect, useState } from 'react'
import { MdDelete, MdEdit } from "react-icons/md";
import Success from '../../../functions/Modals/Success';
import { ContactForm } from '../../../services/Contacts/ContactModule';
import Error from '../../../functions/Modals/Error';
import { UpdateMyContact } from '../../../services/Contacts/UpdateMyContact';
import { ConsultMyContact } from '../../../services/Contacts/ConsultMyContact';
import { DeleteMyContact } from '../../../services/Contacts/DeleteMyContact';
import { ConfirmDelete } from '../../../functions/Modals/Confirm';
import { useRef } from 'react';
import { LoadingContent } from '../../../components/LoadingContent';

interface IUpdateContact {
    id: string
}

export const UpdateContact: React.FC<IUpdateContact> = (props) => 
{
    const loading = useRef(true)

    const [data, setData] = useState<ContactForm>({
        name: '',
        phone: '',
        email: '',
    })

    useEffect(() => {
        ConsultMyContact(props.id , s => setData({
            id: props.id,
            name: s.contact.name,
            email: s.contact.email,
            phone: s.contact.phone ?? ''
        }) , e => Error(e.data.message))

        loading.current = false
    }, [props.id])

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        UpdateMyContact(data, s => Success('Contato atualizado com sucesso!', () => window.location.reload()) , e => Error(e.data.message))
    }

    return (
        <>
            {
                !loading.current ? 
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="input-box-task">
                            <label htmlFor="!#">Nome*</label>
                            <input className="styled" type="text" onChange={e => setData({...data, name: e.target.value})} value={data.name} placeholder="Digite aqui o nome do contato." required autoComplete="off" autoFocus={true}/>
                        </div>
                        <div className="input-box-task">
                            <label htmlFor="!#">Telefone</label>
                            <input className="styled" type="text" onChange={e => setData({...data, phone: e.target.value})} value={data.phone} placeholder="Digite aqui o telefone." autoComplete="off" autoFocus={true}/>
                        </div>
                        <div className="input-box-task">
                            <label htmlFor="!#">E-mail*</label>
                            <input className="styled" type="email" onChange={e => setData({...data, email: e.target.value})} value={data.email} placeholder="Digite aqui o email." required autoComplete="off" autoFocus={true}/>
                        </div>
                        <div className="options-button">
                            <button type="submit"><MdEdit/> Atualizar Contato</button>
                            <button type="button" onClick={() => {
                                ConfirmDelete(() => DeleteMyContact(props.id, () => Success('Apagado com sucesso!', () => window.location.reload()), () => {}), () => {})
                            }}><MdDelete/> Apagar</button>
                        </div>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}
