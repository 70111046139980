
import { AxiosResponse } from "axios"
import Request from "../../functions/Request"
import { CloseSubject as CL } from "./ProtocolInterfaces";

export const CloseSubject = async (
    id: string,
    data: CL,
    onSuccess: ((e: AxiosResponse) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.post(`protocols/close-subject/${id}`, data).then(onSuccess).catch(onError);
