import React from 'react'

interface IBoxData {
    title?: string,
    body?: string | number | JSX.Element,
    style?: React.CSSProperties
}

export const BoxData: React.FC<IBoxData> = (props) => 
{
    return (
        <>
            <div style={props.style} className="app-body-information">
                <p className="app-body-information-title">{props.title}</p>
                <p className="app-body-information-body">{props.body}</p>
            </div>
        </>
    )
}
