import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/customer-journey/pop'

export interface CustomerJourneyPOPForm {
    id?: string
    client_id: string
    objective: string
    obs: string
    docs_ref: string
    checklist: string
    result: string
    obs_error: string
    elaborate_by: string
    accredited_by: string
    necessary_systems: string
    necessary_materials: string
    date_create?: string
    title?: string
}

class CustomerJourneyPOP
{
    async create(
        data: CustomerJourneyPOPForm,
        onSuccess: ((success: CustomerJourneyPOPForm) => void),
    )
    {
        return await Request.post(`${route}`, data).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async consult(
        onSuccess: ((proccesses: CustomerJourneyPOPForm) => void),
    )
    {
        return await Request.get(`${route}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }
}

export const CustomerJourneyPOPAPI = new CustomerJourneyPOP()