import React, { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { Navbar } from '../../../components/Navbar'
import { ResponsiveBar } from '@nivo/bar'
import { formatValue } from 'react-currency-input-field'
import { LoadingContent } from '../../../components/LoadingContent'

export interface IndicadorGraphicProps {}
export interface GraphicItemProps {
    name: string
    data: any[]
}

export interface IndicadorGraphicPropsRoute {
    department_id: string
}

export const IndicadorGraphic: React.FC<IndicadorGraphicProps> = (props) => 
{
    const location = useLocation<any>()
    const { department_id } = useParams<IndicadorGraphicPropsRoute>()
    const [graphicData, setGraphicData] = useState<any>([])

    const [unit, setUnit] = useState({
        label: '',
        icon: '',
        type: ''
    })

    useEffect(() => {
        let temp_unit = location.state.data.unit

        switch (temp_unit) {
            case 'porcentagem':
                temp_unit = {
                    label: 'Porcentagem',
                    icon: '%',
                    type: 'porcentagem'
                }
                break;
            case 'numero':
                temp_unit = {
                    label: 'Número',
                    icon: '',
                    type: 'number'
                }
                break;
            default:
                temp_unit = {
                    label: 'Reais',
                    icon: 'R$',
                    type: 'reais'
                }
                break;
        }

        setUnit(temp_unit)

        let series = []

        let last_year : GraphicItemProps = {
            name: "Ano anterior",
            data: []
        }

        let now_year : GraphicItemProps = {
            name: "Ano atual",
            data: []
        }

        let meta : GraphicItemProps = {
            name: "Meta",
            data: []
        }

        Object.entries(location.state.data.last_year).sort().forEach((value) => last_year.data.push(value[1]))
        Object.entries(location.state.data.now_year).sort().forEach((value) => now_year.data.push(value[1]))
        Object.entries(location.state.data.meta).sort().forEach((value) => meta.data.push(value[1]))

        let total_last_year = 0;
        let total_now_year = 0;
        let total_meta_year = 0;

        let labels = [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Out',
            'Nov',
            'Dez',
            'Med',
            'Tot'
        ]

        for(let [key, value] of Object.entries(location.state.data.last_year))
        {
            total_last_year += Number(value);
        }

        for(let [key, value] of Object.entries(location.state.data.now_year))
        {
            total_now_year += Number(value);
        }

        for(let [key, value] of Object.entries(location.state.data.meta))
        {
            total_meta_year += Number(value);
        }

        last_year.data.push(total_last_year)
        now_year.data.push(total_now_year)
        meta.data.push(total_meta_year)

        last_year.data.push((total_last_year/12).toFixed(1))
        now_year.data.push((total_now_year/12).toFixed(1))
        meta.data.push((total_meta_year/12).toFixed(1))

        let data : any = []

        last_year.data.forEach((item, index) => {
            data.push({
                month: labels[index],
                'Ano anterior': item,
                'Ano atual': now_year.data[index],
                'Meta': meta.data[index]
            })
        })

        setGraphicData(data)
    }, [location])

    return (
        <>
            <Navbar/>
            <Content title={
                <div className="title-button">
                    <div className="title">
                        <LinkBackHistory to={`/indicadores/dashboard/${department_id}`} />
                        <h1 className="app-content-h1-destaque">Gráfico do indicador</h1>
                    </div>
                </div>
            }>
                <div className="content-box">
                    <h2 className="title-divider" style={{marginTop: '0px'}}>Gráfico</h2>
                    <div style={{height: '450px'}}>
                        {
                            unit.label ? 
                            (
                                <ResponsiveBar 
                                    data={graphicData}
                                    keys={[ 'Ano anterior', 'Meta', 'Ano atual']}
                                    indexBy={"month"}
                                    innerPadding={3}
                                    margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                                    padding={0.1}
                                    tooltipFormat={data => {
                                        if(unit.type === "number")
                                        {
                                            return `${data.toString()}`
                                        }

                                        if(unit.type === "porcentagem")
                                        {
                                            return `${data.toString()} %`
                                        }

                                        return `${formatValue({value: data.toString(), intlConfig: { locale: 'pt-BR',  currency: 'BRL' }})}`
                                    }}
                                    groupMode="grouped"
                                    valueScale={{ type: 'linear' }}
                                    indexScale={{ type: 'band', round: true }}
                                    colors={['#0A1931',  `#185ADB`, '#ec6518',]}
                                    defs={[
                                        {
                                            id: 'dots',
                                            type: 'patternDots',
                                            background: 'inherit',
                                            color: '#38bcb2',
                                            size: 4,
                                            padding: 1,
                                            stagger: true
                                        },
                                        {
                                            id: 'lines',
                                            type: 'patternLines',
                                            background: 'inherit',
                                            color: '#eed312',
                                            rotation: -45,
                                            lineWidth: 6,
                                            spacing: 10
                                        }
                                    ]}
                                    fill={[
                                        {
                                            match: {
                                                id: 'fries'
                                            },
                                            id: 'dots'
                                        },
                                        {
                                            match: {
                                                id: 'sandwich'
                                            },
                                            id: 'lines'
                                        }
                                    ]}
                                    borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: 'Meses',
                                        legendPosition: 'middle',
                                        legendOffset: 32
                                    }}
                                    axisLeft={{
                                        tickSize: 5,
                                        tickPadding: 5,
                                        tickRotation: 0,
                                        legend: `Total ${unit.icon}`,
                                        legendPosition: 'middle',
                                        legendOffset: -40
                                    }}
                                    labelSkipWidth={1}
                                    labelSkipHeight={1}
                                    enableGridX={true}
                                    // labelFormat={label => `${formatValue({value: label.toString(), intlConfig: { locale: 'pt-BR',  currency: 'BRL' }})}`}
                                    // labelFormat={label => `${formatValue({value: label.toString(), intlConfig: { locale: 'pt-BR',  currency: 'BRL' }})}`}
                                    labelFormat={d => {
                                        if(unit.type === "porcentagem")
                                        {
                                            return (<tspan y={-10}>{`${d.toString()}%`}</tspan>) as unknown as string
                                        }

                                        if(unit.type === "number")
                                        {
                                            return (<tspan y={-10}>{`${d.toString()}`}</tspan>) as unknown as string
                                        }
                                        
                                        return (<tspan y={-10}>{`${formatValue({value: d.toString(), intlConfig: { locale: 'pt-BR',  currency: 'BRL' }})}`}</tspan>) as unknown as string
                                    }}
                                    labelTextColor="#333"
                                    legends={[
                                        {
                                            dataFrom: 'keys',
                                            anchor: 'top-right',
                                            direction: 'column',
                                            justify: false,
                                            translateX: 120,
                                            translateY: 0,
                                            itemsSpacing: 2,
                                            itemWidth: 100,
                                            itemHeight: 20,
                                            itemDirection: 'left-to-right',
                                            itemOpacity: 0.85,
                                            symbolSize: 20,
                                            effects: [
                                                {
                                                    on: 'hover',
                                                    style: {
                                                        itemOpacity: 1
                                                    }
                                                }
                                            ]
                                        }
                                    ]}
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                />
                            ) : <LoadingContent />
                        }
                    </div>
                </div>
            </Content>
        </>
    )
}