import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { MdAdd } from 'react-icons/md'
import { LoadingContent } from '../../../../../../components/LoadingContent'
import Success from '../../../../../../functions/Modals/Success'
import { OrganogramaDepartmentAPI, OrganogramaDepartmentForm } from '../../../../../../services/Organograma/OrganogramaDepartment'

export interface FormOrganogramaDepartmentsProps {
    id?: string,
    onSuccess?: (() => void)
}

export const FormOrganogramaDepartments: React.FC<FormOrganogramaDepartmentsProps> = (props) => 
{
    const [form, setForm] = useState<OrganogramaDepartmentForm>({
        title: ''
    })

    const loading = useRef(true)
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        if(props.id)
        {
            OrganogramaDepartmentAPI.update(form, s => Success('Salvo com sucesso!', () => window.location.reload()))
        }else{
            OrganogramaDepartmentAPI.create(form, s => Success('Salvo com sucesso!', async () => {
                window.location.reload()
            }))
        }
    }

    useEffect(() => {
        if(props.id)
        {
            OrganogramaDepartmentAPI.getById(props.id, setForm)
        }else{
            setForm({title: '', id: props.id})
        }

        loading.current = false
    }, [props.id])

    return (
        <>
            {
                !loading.current ?
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p>Título</p>
                            <input type="text" onChange={e => setForm({...form, title: e.target.value})} value={form.title} />
                        </div>
                        <button type="submit"><MdAdd/> Salvar</button>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}