import React, { useState } from 'react'
import Pagination from 'react-js-pagination'
import {
    MdChevronLeft,
    MdChevronRight,
    MdFirstPage,
    MdLastPage
} from 'react-icons/md'

export interface ITheTable {
    columns: IColumnTable,
    data: Array<any>,
    classBodyItem?: string,
    classHeaderItem?: string,
    withSearch?: boolean,
    withPagination?: boolean,
    withFooter?: boolean
    componentWithoutData?: JSX.Element,
    pageSize?: number,
    pagination?: {
        innerClass?: string,  
        activeClass?: string,
        activeLinkClass?: string,
        itemClass?: string,
        itemClassFirst?: string,
        itemClassPrev?: string,
        itemClassNext?: string,
        itemClassLast?: string,
        disabledClass?: string,
        hideDisabled?: boolean,
        hideNavigation?: boolean,
        hideFirstLastPages?: boolean,
        linkClass?: string,
        linkClassFirst?: string,
        linkClassPrev?: string,
        linkClassNext?: string,
        linkClassLast?: string
    }
}

export interface IColumnTable extends Array<IColumnTableItem> {}

export interface IColumnTableItem {
    name: string,
    label: string,
    classHeader?: string,
    classItem?: string,
    render?: (row: any) => JSX.Element,
    headerRender?: (row: any) => JSX.Element,
    isCount?: boolean
}

const TheTable: React.FunctionComponent<ITheTable> = (props) => {
    
    const { columns, data } = props;

    const [filter, setFilter] = useState('')
    const [page, setPage] = useState(1)
    const [pageSize] = useState(props.pageSize ? props.pageSize : 10)

    const filteredItems = data.filter(item => {
        let status = false;

        for(const [key] of Object.entries(item)) 
        {
            if(item[key].toLowerCase().includes(filter.toLowerCase()))
            {
                status = true
                break
            }
        }

        return status
    });

    const paginate = filteredItems.slice((page - 1) * pageSize, page * pageSize);

    const changePage = (pageNumber: number) => 
    {
        setPage(pageNumber)
    }

    return (
        <>
            {
                props.withSearch ? (
                    <div className="thetable__search">
                        <span>Busque o que deseja </span>
                        <input type="search" placeholder="Digite aqui.." onChange={event => setFilter(event.target.value)}/>
                    </div>
                ) : null
            }
            <table className="thetable">
                <thead className="thetable__header">
                    <tr className={props.classHeaderItem ?? 'thetable__header_item'}>
                        {
                            columns.map((item, index ) => {
                                if(item.headerRender)
                                {
                                    return (
                                        <th className={item.classHeader} key={`${index}`}>{item.headerRender(item)}</th>
                                    )
                                }else{
                                    return (
                                        <th className={item.classHeader} key={`${index}`}>{item.name}</th>
                                    )
                                }
                            })
                        }
                    </tr>
                </thead>
                <tbody className="thetable__body">
                    {
                        // Faço um loop da lista de dados.
                        paginate.length > 0 ? 
                        (
                            paginate.map((item_data, index) => {
                                return (
                                    <tr key={`${index}`} className={props.classBodyItem ?? 'thetable__body_item'}>
                                        {
                                            // Crio a tabela conforme as colunas setas.
                                            columns.map((item: IColumnTableItem, index ) => {
                                                if(item_data[item.label])
                                                {
                                                    if(item.render)
                                                    {
                                                        return (
                                                            <td key={`${index}`} className={item.classItem}>{item.render(item_data)}</td>
                                                        )
                                                    }else{
                                                        return (
                                                            <td key={`${index}`} className={item.classItem}>{item_data[item.label]}</td>
                                                        )
                                                    }
                                                }else{
                                                    if(item.label === "options")
                                                    {
                                                        if(item.render)
                                                        {
                                                            return (
                                                                <td key={`${index}`}>{item.render(item_data)}</td>
                                                            )
                                                        }else{
                                                            return (
                                                                <td key={`${index}`}  className={item.classItem}>WITHOUT ITEM RENDER</td>
                                                            )
                                                        }
                                                    }else{
                                                        return (
                                                            <td key={`${index}`}  className={item.classItem}>---</td>
                                                        )
                                                    }
                                                }
                                            })
                                        }
                                    </tr>
                                )
                            })
                        ) : 
                        (
                            props.componentWithoutData
                        )
                    }
                </tbody>
            </table>
            {
                props.withPagination ? (
                    <Pagination
                        activePage={page}
                        itemsCountPerPage={pageSize}
                        totalItemsCount={filteredItems.length}
                        pageRangeDisplayed={5}
                        onChange={changePage}
                        {...props.pagination}
                        firstPageText={<MdFirstPage/>}
                        lastPageText={<MdLastPage/>}
                        prevPageText={<MdChevronLeft/>}
                        nextPageText={<MdChevronRight/>}
                    />
                ) : null
            }
        </>
    )
}

export default TheTable