import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaEdit, FaEye, FaFolder, FaFolderPlus, FaPlus, FaTrash } from 'react-icons/fa'
import { useHistory, useParams } from 'react-router-dom'
import { useToasts } from 'react-toast-notifications'
import { Content } from '../../components/Content'
import { HomeButton } from '../../components/HomeButton'
import { LinkBackHistory } from '../../components/LinkBackHistory'
import { ConfigModal } from '../../components/Modal'
import { ModalRight } from '../../components/ModalRight'
import { Navbar } from '../../components/Navbar'
import { formatDate } from '../../functions/dataFormat'
import { ConfirmDelete } from '../../functions/Modals/ConfirmDelete'
import { ProtocolFormPropsV2, ProtocolItemPropsV2, ProtocolV2API } from '../../services/ProtocolV2/ProtocolV2'
import { ProtocolFolderAPI, ProtocolFolderItemProps } from '../../services/V2/ProtocolFolder'
import { CreateFolder } from './Components/CreateFolder'
import { CreateProtocol } from './Components/CreateProtocol'
import { UpdateProtocol } from './Components/UpdateProtocol'

export interface ProtocolProps {}
export interface ProtocolRouteProps {
    folder_id?: string
}

export const Protocol: React.FC<ProtocolProps> = () => 
{
    const [confModal, setConfModal] = useState<ConfigModal>({show: false})
    const [listProtocols, setListProtocols] = useState<Array<ProtocolItemPropsV2>>([])
    const [listFolders, setListFolders] = useState<Array<ProtocolFolderItemProps>>([])

    const history = useHistory()
    const { addToast } = useToasts()
    const { folder_id } = useParams<ProtocolRouteProps>()
    const loading = useRef(true)

    const [folderData, setFolderData] = useState<ProtocolFolderItemProps>({
        name: ''
    })

    const [folders, setFolders] = useState<Array<ProtocolFolderItemProps>>([])
    
    useEffect(() => {
        if(folder_id)
        {
            ProtocolFolderAPI.getById(folder_id, response => {
                setFolderData({
                    name: response.name,
                })
            })
        }
    }, [folder_id])

    useEffect(() => {
        if(!folder_id)
        {
            ProtocolV2API.getAllWithFolders(setListProtocols)
            ProtocolFolderAPI.getAll(setListFolders)
        }else{
            ProtocolV2API.getAllByFolder(folder_id, setListProtocols)
        }

        loading.current = false
    },[folder_id])
    
    return (
        <>
            <ModalRight {...confModal} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            {
                                folder_id ? <LinkBackHistory to="/protocols"/> :  <HomeButton />
                            }
                            <h1 className="app-content-h1-destaque">Minhas ATAS: {folderData.name}</h1>
                        </div>
                        {
                            !folder_id ? 
                            (
                                <div className="options">
                                    <button title="Ver arquivadas" className="bkg-theme clr-white" onClick={() => {
                                        history.push('/protocols/archiveds')    
                                    }}><FaEye/>
                                    </button>
                                    <button title="Nova pasta" className="bkg-theme clr-white" onClick={() => {
                                        setConfModal({
                                                show: true,
                                                title: <h2 className="title-form-modal">Criar pasta</h2>,
                                                content: <CreateFolder onChange={() => {
                                                    setConfModal({show: false})
                                                    window.location.reload()
                                                }} />,
                                                onClose: () => setConfModal({show: false})
                                            })
                                        }}><FaFolderPlus/>
                                    </button>
                                    <button title="Nova ATA" className="bkg-theme clr-white" onClick={() => {
                                        setConfModal({
                                            show: true,
                                            content: <CreateProtocol onSuccess={() => {
                                                setConfModal({show: false})
                                            }} />,
                                            onClose: () => setConfModal({
                                                show: false
                                            }),
                                            title: <h3 className="title-divider">Criar Pauta</h3>
                                        })
                                    }}><FaPlus/>
                                    </button>
                                </div>
                            ) : (
                                <div className="options">
                                    <button title="Nova ATA" className="bkg-theme clr-white" onClick={() => {
                                        setConfModal({
                                            show: true,
                                            content: <CreateProtocol folder_id={folder_id} onSuccess={() => {
                                                setConfModal({show: false})
                                            }} />,
                                            onClose: () => setConfModal({
                                                show: false
                                            }),
                                            title: <h3 className="title-divider">Criar Pauta</h3>
                                        })
                                    }}><FaPlus/>
                                    </button>
                                </div>
                            )
                        }
                    </div>
                </>
            )}>
                <div className="body-content" style={{marginTop: '20px'}}>
                    <div className="title">
                        <h2>Atas criadas</h2>
                    </div>
                    {
                        !folder_id ?
                        (
                            <>
                                <h3 className="title-divider" style={{marginTop: '0px'}}>Pastas</h3>
                                {
                                    listFolders.length >= 0   ?
                                    (
                                        listFolders.map((folder, i) => (
                                            <div key={`${i}`} className="box-button with-options">
                                                <div className="content"  onClick={() => {
                                                    history.push(`/protocols/${folder.id}`)
                                                }}>
                                                    <FaFolder/> {folder.name}
                                                </div>
                                                <div className="options">
                                                    <FaEdit onClick={() => {
                                                        setConfModal({
                                                            show: true,
                                                            title: <h2 className="title-form-modal">Atualizar pasta</h2>,
                                                            content: <CreateFolder id={folder.id} onChange={() => {
                                                                setConfModal({show: false})
                                                                window.location.reload()
                                                            }} />,
                                                            onClose: () => setConfModal({show: false})
                                                        })
                                                    }}/>
                                                    <FaTrash onClick={() => {
                                                        ConfirmDelete(() => {
                                                            if(folder.id)
                                                            {
                                                                ProtocolFolderAPI.delete(folder.id, () => {
                                                                    addToast('Arquivo apagado com sucesso!', {appearance: "info", autoDismiss: true}, () => {
                                                                        window.location.reload()
                                                                    })
                                                                })
                                                            }
                                                        }, () => {}, 'Pode conter informações de outros usuários. Tem certeza que quer apagar?', "Sim, exclua!")
                                                    }}/>
                                                </div>
                                            </div>
                                        ))
                                    ) :
                                    (
                                        <h3>Sem informações para serem mostradas</h3>
                                    )
                                }
                            </>
                        ) : null
                    }
                    {
                        listProtocols.length >= 0 ?
                        (
                            listProtocols.map((e, i) => { 
                                return (
                                    <div key={`${i}`} className="box-button with-options" style={{marginBottom: "10px"}} onClick={() => {
                                        if(e.id)
                                        {
                                            setConfModal({
                                                show: true,
                                                content: <UpdateProtocol id={e.id} />,
                                                onClose: () => setConfModal({show: false})
                                            })
                                        }
                                    }}>
                                        <p className="title">- {e.name}</p>
                                        <p className="date">{e.user_created_name ? `Criado por ${e.user_created_name}` : null} {e.date ? `em ${formatDate(e.date)}` : null} </p>
                                    </div>
                                )
                            })
                        ) :
                        (
                            <h3>Sem informações para serem mostradas</h3>
                        )
                    }
                </div>
            </Content>
        </>
    )
}
