import React, { useState } from 'react'
import { MdAdd } from 'react-icons/md'

export interface FormGraphicProps {
    onSuccess?: ((dates: {
        date_start: string,
        date_finish: string
    }) => void)
}

export const FormGraphic: React.FC<FormGraphicProps> = (props) => 
{
    const [form, setForm] = useState<{
        date_start: string,
        date_finish: string
    }>({
        date_start: "",
        date_finish: ""
    })

    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        if(props.onSuccess)
        {
            props.onSuccess(form)
        }
    }

    return (
        <>
            {
                <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                    <div className="box-input">
                        <p>Data de início</p>
                        <input type="date" onChange={event => setForm({...form, date_start: event.target.value })} />
                    </div>
                    <div className="box-input">
                        <p>Data de fim</p>
                        <input type="date" onChange={event => setForm({...form, date_finish: event.target.value })} />
                    </div>
                    <button type="submit"><MdAdd/> Salvar</button>
                </form>
            }
        </>
    )
}