import React, { useEffect, useRef, useState } from 'react'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { ConfigModal, Modal } from '../../../components/Modal'
import { Navbar } from '../../../components/Navbar'
import { money } from '../../../functions/Format'
import Success from '../../../functions/Modals/Success'
import { EntriesAPI, EntryProps } from '../../../services/Financeiro/Entries'
import { InitialValueAPI } from '../../../services/Financeiro/InitialValue'
import { TopicAPI, TopicProps } from '../../../services/Financeiro/Topic'
import { FormEntry, months, payments_method } from './components/FormEntry'
import { FormInitialValue } from './components/FormInitialValue'
import MaterialTable from 'material-table';
import { localization } from '../../../functions/localization'
import { FaCheck, FaEdit, FaFileExport } from 'react-icons/fa'
import { formatCurrencyBRL, formatDate } from '../../../functions/dataFormat'
import { APP_CONFIG } from '../../../constants'
import { cookies } from '../../../functions/Session'
import { FormDateEntry } from './components/FormDateEntry'

export interface LancamentosProps {}

export const Lancamentos: React.FC<LancamentosProps> = (props) => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [list, setList] = useState<Array<EntryProps>>([])
    const [topics, setTopics] = useState<Array<TopicProps>>([])
    const loading = useRef(true)
    const [balanceTemp, setBalanceTemp] = useState("")

    useEffect(() => {
        InitialValueAPI.get(data => {
            if(data.value)
            {
                setBalanceTemp(data.value)
            }
        })

        EntriesAPI.getAll(setList)
        TopicAPI.getAllMy(setTopics)

        loading.current = false
    }, [])

    const calculateBalance = () => {
        let total = Number(balanceTemp);

        list.forEach(item => {
            if(item.sum_type === "1")
            {
                if(item.value)
                {
                    total += Number(item.value)
                }
            }

            if(item.sum_type === "2")
            {
                if(item.value)
                {
                    total -= Number(item.value)
                }
            }
        })

        return total;
    }

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/" />
                            <h1 className="app-content-h1-destaque">Financeiro: Lançamentos</h1>
                        </div>
                        <div className="options">
                            <button className="btn" onClick={() => {
                                const token = cookies.get('X-Request-Token')

                                window.open(`${APP_CONFIG.localhost}/client/finance/entries/csv?token=${token}`);
                            }}><FaFileExport /> Exportar</button>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Entradas</h2>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    content: <FormInitialValue onSuccess={() => {
                                        setModalConfig({
                                            show: false
                                        })

                                        InitialValueAPI.get(data => {
                                            if(data.value)
                                            {
                                                setBalanceTemp(data.value)
                                            }
                                        })
                                    }} />,
                                    onClose: () => setModalConfig({
                                        show: false
                                    }),
                                    title: <h2>Caixa inicial</h2>
                                })
                            }} className="bkg-theme clr-white" >Saldo: {list.length > 0 ? money(calculateBalance().toFixed(2)): money(calculateBalance().toFixed(2))} </button>
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    content: <FormEntry onSuccess={() => EntriesAPI.getAll(setList)} />,
                                    onClose: () => setModalConfig({
                                        show: false
                                    }),
                                    title: <h2>Novo item</h2>
                                })
                            }}>Novo item</button>
                        </div>
                    </div>
                    <br/>
                    <MaterialTable 
                        title=""
                        localization={localization}
                        options={{
                            filtering: true,
                            pageSize: 20,
                            rowStyle: rowData => {
                                if(rowData.sum_type === "1")
                                {
                                    return {
                                        background: "#29bb8930",
                                        color: "#29bb89",
                                    }
                                }else{
                                    return {
                                        background: "#ce121230",
                                        color: "#ce1212",
                                    }
                                }
                            }
                        }}
                        data={list}
                        columns={[
                            {
                                title: 'Mês de Competência',
                                field: 'month_competence',
                                render: rowData => {
                                    let month_competence_index = months.findIndex(month => month.value === rowData.month_competence)
                                    let month = months[month_competence_index]

                                    return `${month.name}`
                                },
                                customFilterAndSearch: (filter, rowData, columnRef) => {
                                    let month_competence_index = months.findIndex(month => month.value === rowData.month_competence)
                                    let month = months[month_competence_index]

                                    return month.name.search(filter) >= 0 ? true : false
                                }
                            },
                            {
                                title: 'Classificação',
                                field: 'classification_id',
                                render: rowData => {
                                    let classification_index = topics.findIndex(topic => topic.id === rowData.classification_id)
                                    let classificaton_data;
                                    let scheme_account;
                                    let scheme_account_data;

                                    if(classification_index >= 0)
                                    {
                                        classificaton_data = topics[classification_index];

                                        scheme_account = topics[classification_index].subtopics;

                                        if(scheme_account && scheme_account.length)
                                        {
                                            let scheme_index = scheme_account.findIndex(scheme => scheme.id === rowData.scheme_account_id)

                                            if(scheme_index >= 0)
                                            {   
                                                scheme_account_data = scheme_account[scheme_index]
                                            }
                                        }
                                    }

                                    return <span className="span-link" onClick={() => {
                                        setModalConfig({
                                            show: true,
                                            content: <FormEntry id={rowData.id} onSuccess={() => {
                                                TopicAPI.getAllMy(setList)
                                            }} />,
                                            onClose: () => setModalConfig({
                                                show: false
                                            }),
                                            title: <h2>Atualizar</h2>
                                        })
                                    }}>
                                        {classificaton_data?.name} <FaEdit />
                                    </span>
                                },
                                customFilterAndSearch: (filter, rowData, columnRef) => {
                                    let classification_index = topics.findIndex(topic => topic.id === rowData.classification_id)
                                    let classificaton_data;
                                    let scheme_account;
                                    let scheme_account_data;

                                    if(classification_index >= 0)
                                    {
                                        classificaton_data = topics[classification_index];

                                        scheme_account = topics[classification_index].subtopics;

                                        if(scheme_account && scheme_account.length)
                                        {
                                            let scheme_index = scheme_account.findIndex(scheme => scheme.id === rowData.scheme_account_id)

                                            if(scheme_index >= 0)
                                            {   
                                                scheme_account_data = scheme_account[scheme_index]
                                            }
                                        }
                                    }

                                    if(classificaton_data && classificaton_data.name)
                                    {
                                        return classificaton_data.name.search(filter) >= 0 ? true : false
                                    }else{
                                        return false;
                                    }
                                }
                            },
                            {
                                title: 'Plano de contas',
                                field: 'scheme_account_id',
                                render: rowData => {
                                    let classification_index = topics.findIndex(topic => topic.id === rowData.classification_id)
                                    let classificaton_data;
                                    let scheme_account;
                                    let scheme_account_data;

                                    if(classification_index >= 0)
                                    {
                                        classificaton_data = topics[classification_index];

                                        scheme_account = topics[classification_index].subtopics;

                                        if(scheme_account && scheme_account.length)
                                        {
                                            let scheme_index = scheme_account.findIndex(scheme => scheme.id === rowData.scheme_account_id)

                                            if(scheme_index >= 0)
                                            {   
                                                scheme_account_data = scheme_account[scheme_index]
                                            }
                                        }
                                    }

                                    return `${scheme_account_data?.name}`
                                },
                                customFilterAndSearch: (filter, rowData, columnRef) => {
                                    let classification_index = topics.findIndex(topic => topic.id === rowData.classification_id)
                                    let classificaton_data;
                                    let scheme_account;
                                    let scheme_account_data;

                                    if(classification_index >= 0)
                                    {
                                        classificaton_data = topics[classification_index];

                                        scheme_account = topics[classification_index].subtopics;

                                        if(scheme_account && scheme_account.length)
                                        {
                                            let scheme_index = scheme_account.findIndex(scheme => scheme.id === rowData.scheme_account_id)

                                            if(scheme_index >= 0)
                                            {   
                                                scheme_account_data = scheme_account[scheme_index]
                                            }
                                        }
                                    }

                                    if(scheme_account_data && scheme_account_data.name)
                                    {
                                        return scheme_account_data.name.search(filter) >= 0 ? true : false
                                    }else{
                                        return false;
                                    }
                                }
                            },
                            {
                                title: 'Descrição',
                                field: 'description'
                            },
                            {
                                title: 'Forma de Pagamento',
                                field: 'payment_method_id',
                                render: rowData => {
                                    let payment_method_index = payments_method.findIndex(payment => payment.value === rowData.payment_method_id)
                                    let payment = payments_method[payment_method_index]

                                    return `${payment.name}`
                                }
                                ,
                                customFilterAndSearch: (filter, rowData, columnRef) => {
                                    let payment_method_index = payments_method.findIndex(payment => payment.value === rowData.payment_method_id)
                                    let payment = payments_method[payment_method_index]

                                    return payment.name.search(filter) >= 0 ? true : false
                                }
                            },
                            {
                                title: 'Valor',
                                field: 'value',
                                render: rowData => {
                                    return `${formatCurrencyBRL(rowData.value)}`
                                }
                            },
                            {
                                title: 'Data Venc.',
                                field: 'date_finish',
                                align: 'center',
                            },
                            {
                                title: 'Data Pag.',
                                align: 'center',
                                field: 'date_filter',
                                render: rowData => <FormDateEntry data={rowData} onSuccessUpdate={() => {
                                    Success('Salvo com sucesso!', () => {
                                        EntriesAPI.getAll(setList)
                                    })
                                }} />,
                                customFilterAndSearch: (filter, rowData, columnRef) => {
                                    if(rowData.date_filter)
                                    {
                                        return formatDate(rowData.date_filter, 'dd/MM/yyyy').search(filter) >= 0 ? true : false
                                    }else{
                                        return false
                                    }
                                }
                            },
                            {
                                title: 'Saldo',
                                field: 'balance',
                                align: 'center',
                                filtering: false,
                                render: rowData => {
                                    if(rowData.balance)
                                    {
                                        let balance = Number(rowData.balance)

                                        return <p className={balance < 0 ? "balance-negative" : ""}>{balance < 0 ? "-" : "+"} {formatCurrencyBRL(Math.abs(balance).toString())}</p>
                                    }
                                }
                            }
                        ]}
                    />
                    {/* <div className="finance-entries-header">
                        <p>Mês de Competência</p>
                        <p>Classificação</p>
                        <p>Plano de contas</p>
                        <p>Descrição</p>
                        <p>Forma de Pagamento</p>
                        <p>Valor</p>
                        <p>Data Venc.</p>
                        <p>Data Pag.</p>
                        <p>Saldo</p>
                    </div>
                    {
                        list.length > 0 ?
                        (
                            list.map((item, index) => 
                                {
                                    let classification_index = topics.findIndex(topic => topic.id === item.classification_id)
                                    let scheme_account;
                                    let scheme_account_data;
                                    let classificaton_data;
                                    let month_competence_index = months.findIndex(month => month.value === item.month_competence)
                                    let month = months[month_competence_index]

                                    let payment_method_index = payments_method.findIndex(payment => payment.value === item.payment_method_id)
                                    let payment = payments_method[payment_method_index]

                                    if(classification_index >= 0)
                                    {
                                        classificaton_data = topics[classification_index];

                                        scheme_account = topics[classification_index].subtopics;

                                        if(scheme_account && scheme_account.length)
                                        {
                                            let scheme_index = scheme_account.findIndex(scheme => scheme.id === item.scheme_account_id)

                                            if(scheme_index >= 0)
                                            {   
                                                scheme_account_data = scheme_account[scheme_index]
                                            }
                                        }
                                    }

                                    let balance = Number(item.balance)

                                    if((index+1) === list.length)
                                    {
                                        balanceTotal.current = balance;
                                    }

                                    return (
                                        <>
                                            <div className={item.sum_type === "1" ? "finance-entries-content-item entry" : "finance-entries-content-item output"}>
                                                <p>{month.name}</p>
                                                <p onClick={() => {
                                                    setModalConfig({
                                                        show: true,
                                                        content: <FormEntry id={item.id} onSuccess={() => {
                                                            TopicAPI.getAllMy(setList)
                                                        }} />,
                                                        onClose: () => setModalConfig({
                                                            show: false
                                                        }),
                                                        title: <h2>Atualizar</h2>
                                                    })
                                                }}>{classificaton_data?.name}</p>
                                                <p>{scheme_account_data?.name}</p>
                                                <p>{item.description}</p>
                                                <p>{payment.name}</p>
                                                <p>{money(item.value)}</p>
                                                <p>{item.date_finish}</p>
                                                <div className="finance-date-input"></div>
                                                <p className={balance < 0 ? "balance-negative" : ""}>{balance < 0 ? "-" : "+"} {money(Math.abs(balance).toString())}</p>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : <NoWithContent/>
                    } */}
                </div>
            </Content>
        </>
    )
}