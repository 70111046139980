import React, { useEffect, useState } from 'react'
import { FaFileDownload, FaFilePdf, FaHistory } from 'react-icons/fa'
import { useHistory, useParams } from 'react-router-dom'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { ConfigModal, Modal } from '../../../components/Modal'
import { Navbar } from '../../../components/Navbar'
import { APP_CONFIG } from '../../../constants'
import { formatDate } from '../../../functions/dataFormat'
import { removeDuplicate } from '../../../functions/removeDuplicate'
import { POPController, POPForm, PopHistorySchemeProps, PopsStructureProps } from '../../../services/POP/POPController'
import { FormPOP } from '../POP/components/FormPOP'

export interface HistoryPopsProps {}

export const HistoryPops: React.FC<HistoryPopsProps> = () => 
{
    const [listPops, setListPops] = useState<PopHistorySchemeProps[]>([])
    const [configModal, setConfigModal] = useState<ConfigModal>({show: false})
    const history = useHistory()
    const { pop_id } = useParams<{
        pop_id: string
    }>()

    useEffect(() => {
        POPController.getAllHistoryPops(pop_id, setListPops)
    }, [])

    return (
        <>
            <Navbar/>
            <Modal {...configModal} />
            <Content title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/all-pops" />
                            <h1 className="app-content-h1-destaque">Histórico do POP</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Histórico</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        listPops.length > 0 ?
                        (
                            listPops.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item">
                                                <div className="title-with-button-options">
                                                    <div className="title link" onClick={() => {
                                                        window.open(`${APP_CONFIG.localhost}/client/pop/history-pdf/${item.id}`, '_blank');
                                                    }}>Realizado em {formatDate(item.date_registry)}</div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) :
                        (
                            <h3>Sem informações para serem mostradas</h3>
                        )
                    }
                </div>
            </Content>
        </>
    )
}