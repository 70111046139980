import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { useToasts } from 'react-toast-notifications'
import { LoadingContent } from '../../../../components/LoadingContent'
import { FolderFormProps, FoldersAPI } from '../../../../services/MyAttachments/Folders'

export interface CreateFolderProps {
    id?: string
    onChange?: (() => void)
}

export const CreateFolder: React.FC<CreateFolderProps> = (props) => 
{
    const [form, setForm] = useState<FolderFormProps>({
        name: ''
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(props.id)
        {
            FoldersAPI.get(props.id, data => {
                setForm({
                    name: data.name
                })
            })
        }

        setLoading(false)
    }, [props.id])

    const { addToast } = useToasts()

    const onSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        
        if(props.id)
        {
            FoldersAPI.update(props.id, form, response => {
                addToast('Pasta atualizada com sucesso!', { appearance: 'success', autoDismiss: true }, () => {
                    if(props.onChange)
                    {
                        props.onChange()
                    }
                })
            })
        }else{
            FoldersAPI.insert(form, response => {
                addToast('Pasta criada com sucesso!', { appearance: 'success', autoDismiss: true }, () => {
                    if(props.onChange)
                    {
                        props.onChange()
                    }
                })
            })
        }
    }

    return (
        <>
            {
                !loading ? 
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <p className="title-box">Nome da pasta</p>
                        <div className="input-box-task">
                            <input className="styled" type="text" onChange={e => setForm({...form, name: e.target.value})} value={form.name} placeholder="Descreva a tarefa" required autoComplete="off" autoFocus={true}/>
                        </div>
                        <button style={{marginTop: '20px'}} type="submit"><MdAdd/> Salvar</button>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}