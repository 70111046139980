import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { MdDeleteForever, MdEdit } from 'react-icons/md'
import { useHistory, useParams } from 'react-router'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { Modal } from '../../../components/Modal'
import { ConfigModal } from '../../../components/ModalRight'
import { Navbar } from '../../../components/Navbar'
import { NoWithContent } from '../../../components/NoWithContent'
import { ConfirmDelete } from '../../../functions/Modals/ConfirmDelete'
import { GroupAPI } from '../../../services/Financeiro/Group'
import { TopicAPI, TopicProps } from '../../../services/Financeiro/Topic'
import { FormPlanoDeContas } from './components/FormPlanoDeContas'

export interface PlanoDeContasProps {}
export interface PlanoDeContasRouteProps {
    group_id: string
}

export const PlanoDeContas: React.FC<PlanoDeContasProps> = (props) => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [list, setList] = useState<Array<TopicProps>>([])
    const [listImport, setListImport] = useState<Array<TopicProps>>([])
    const { group_id } = useParams<PlanoDeContasRouteProps>()
    const loading = useRef(true)
    const sync = () => {
        TopicAPI.getAll(group_id, setList)
        TopicAPI.getAllNoSync(group_id, setListImport)
    }

    const history = useHistory()

    const [title, setTitle] = useState('Financeiro')

    useEffect(() => {
        TopicAPI.getAll(group_id, setList)
        TopicAPI.getAllNoSync(group_id, setListImport)

        GroupAPI.getById(group_id, data => {
            setTitle(`${data.name} - Financeiro`)
        })

        loading.current = false
    }, [group_id])

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/financeiro/plano-de-contas" />
                            <h1 className="app-content-h1-destaque">{title}</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Lista de categorias</h2>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    content: <FormPlanoDeContas group_id={group_id} onSuccess={() => TopicAPI.getAll(group_id, setList)} />,
                                    onClose: () => setModalConfig({
                                        show: false
                                    }),
                                    title: <h2>Criar categoria</h2>
                                })
                            }}>Criar categoria</button>
                        </div>
                    </div>
                    <br/>
                    {
                        list.length > 0 ?
                        (
                            list.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link" onClick={() => {
                                                        history.push(`/financeiro/plano-de-contas/${group_id}/${item.id}`)
                                                    }}>
                                                        <p>{item.name}</p>
                                                        
                                                    </div>
                                                    <div className="options">
                                                        <button className="update-button" onClick={() => {
                                                            setModalConfig({
                                                                show: true,
                                                                content: <FormPlanoDeContas group_id={group_id} id={item.id} onSuccess={() => TopicAPI.getAll(group_id, setList)} />,
                                                                onClose: () => setModalConfig({
                                                                    show: false
                                                                }),
                                                                title: <h2>Atualizar</h2>
                                                            })
                                                        }}><MdEdit/></button>
                                                        <button className="delete-button" onClick={() => {
                                                            ConfirmDelete(() => {
                                                                if(item.id)
                                                                {
                                                                    TopicAPI.delete(item.id, () => {
                                                                        setList([...list.filter(i => i.id !== item.id)])
                                                                    })
                                                                }
                                                            }, () => null)
                                                        }}><MdDeleteForever/></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : <NoWithContent/>
                    }
                </div>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Importar categorias</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        listImport.length > 0 ?
                        (
                            listImport.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link no-flex" onClick={() => {
                                                        if(item.id)
                                                        {
                                                            TopicAPI.import(item.id, () => sync())
                                                        }
                                                    }}>
                                                        <b>{item.name}</b>
                                                        {                                                   
                                                            item.subtopics ? (
                                                                <>
                                                                    {
                                                                        item.subtopics.map((item, index) => <p>{item.name}</p>)
                                                                    }
                                                                </>
                                                            ) : null
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : <NoWithContent/>
                    }
                </div>
            </Content>
        </>
    )
}