import React, { useEffect, useRef, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import Select from 'react-select'
import { Content } from '../../components/Content'
import { Form } from '../../components/Form'
import { Button } from '../../components/Form/Button'
import { Input } from '../../components/Form/Input'
import { Navbar } from '../../components/Navbar'
import { Tabs } from '../../components/Tabs'
import { APP_CONFIG } from '../../constants'
import { cnpj, telefone } from '../../functions/Format'
import Error from '../../functions/Modals/Error'
import Success from '../../functions/Modals/Success'
import Warning from '../../functions/Modals/Warning'
import { closeSession } from '../../functions/Session'
import { AccountAPI } from '../../services/Account/Account'
import { AlterPassword } from '../../services/Account/AlterPassword'
import { AccountProps, ConsultarMinhaConta } from '../../services/Account/ConsultarMinhaConta'
import { UploadPhoto } from '../../services/Account/Photo'

export const Account = () => {

    const [account, setAccount] = useState<AccountProps>()
    const loading = useRef(true)
    const [form, setForm] = useState({
        last_password: '',
        new_password: ''
    })

    useEffect(() => {
        ConsultarMinhaConta(e => setAccount(e.account), () => {})

        loading.current = false
    }, [])

    const sendFile = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        let photoForm = new FormData()

        let fileInput: any = document.getElementById('#photo')

        if(fileInput.files && fileInput.files[0])
        {
            photoForm.append('photo', fileInput.files[0])
        }

        UploadPhoto(photoForm, () => Success('Logo salvo com sucesso!', () => window.location.reload()), () => {})
    }

    const [changeCompany, setChangeCompany] = useState<any>()

    const [pathTab, setPathTab] = useState('home')

    return (
        <>
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <h1 className="app-content-h1-destaque">Minha conta</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="content-box">
                    <Tabs 
                        path={pathTab}
                        onChangePath={setPathTab}
                        options={[
                            {
                                title: 'Início',
                                path: 'home',
                                content: (
                                    <>
                                        <h3 className="title-divider">Minha empresa</h3>
                                        <img alt="Logo" src={`${APP_CONFIG.files_root}/${account?.photo_path}/${account?.photo_title}`} className="logo-company"/>
                                        <p className="account-item"><span className="destaque">Razão social</span>: {account?.company_name}</p>
                                        <p className="account-item"><span className="destaque">Fantasia</span>: {telefone(account?.fantasy)}</p>
                                        <p className="account-item"><span className="destaque">CNPJ</span>: {cnpj(account?.cpf_cnpj)}</p>
                                        <h3 className="title-divider">Meus dados</h3>
                                        <p className="account-item"><span className="destaque">Meu e-mail</span>: {account?.email}</p>
                                        <p className="account-item"><span className="destaque">Meu telefone</span>: {telefone(account?.phone)}</p>
                                        <p className="account-item"><span className="destaque">Meu nome</span>: {account?.name}</p>
                                    </>
                                )
                            },
                            {
                                title: 'Alterar minha empresa',
                                path: 'alter-company',
                                actived: account?.is_root === "YES",
                                content: (
                                    <>
                                        {
                                            account?.is_root === "YES" ? 
                                            (
                                                <div style={{height: '450px'}}>
                                                    <h3 className="title-divider">Alterar minha empresa</h3>
                                                    <div className="input-box-v2" style={{marginTop: '20px'}}>
                                                        <label htmlFor="">Trocar de empresa</label>
                                                        <Select placeholder="Selecione uma empresa" value={changeCompany} onChange={value => setChangeCompany(value)} options={account.companies ? account.companies.map((item, index) => {
                                                            return {
                                                                value: item.id,
                                                                label: `${item.fantasy ?? ''} - ${item.name}`
                                                            }
                                                        }) : []} />
                                                        <br />
                                                        <button className="btn-styled nromal" onClick={() => {
                                                            if(changeCompany === "")
                                                            {
                                                                Warning("Escolha uma compania")
                                                            }else{
                                                                AccountAPI.changeCompany({company_id: changeCompany.value}, response => {
                                                                    Success('Empresa alterada faça seu login novamente', () => {
                                                                        closeSession([
                                                                            {
                                                                                label: "user"
                                                                            },
                                                                            {
                                                                                label: "X-Request-Token"
                                                                            },
                                                                            {
                                                                                label: "X-User-Id"
                                                                            },
                                                                            {
                                                                                label: "X-Request-Type"
                                                                            }
                                                                        ])
                                                                        
                                                                        window.location.href = '/'
                                                                    })
                                                                })
                                                            }
                                                        }}>Alterar empresa</button>
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                    </>
                                )
                            },
                            {
                                title: 'Logo da empresa',
                                path: 'company-brand',
                                content: (
                                    <>
                                        <h3 className="title-divider">Cadastrar minha logo, faça sua logo nas dimensões: 250px/250px</h3>
                                        <form className="form-tasks-create" method="post" onSubmit={sendFile}>
                                            <div className="box-input">
                                                <p>Escolha uma foto</p>
                                                <input type="file" id="#photo"/>
                                            </div>
                                            <div className="flex between">
                                                <button type="submit" style={{marginRight: '10px'}}><MdAdd/> Salvar</button>
                                            </div>
                                        </form>
                                    </>
                                )
                            },
                            {
                                title: 'Alterar minha senha',
                                path: 'alter-password',
                                content: (
                                    <>
                                        <h3 className="title-divider">Alterar minha senha</h3>
                                        <Form className="form-task-close" onSubmit={e => {
                                            e.preventDefault()

                                            AlterPassword(form, () => Success('Senha alterada com sucesso!', () => window.location.reload()), err => Error(err.data.message))
                                        }}>
                                            <Input value={form.last_password} onChange={e => setForm({...form, last_password: e.target.value})} type="password" label="Senha antiga"/>
                                            <Input value={form.new_password} onChange={e => setForm({...form, new_password: e.target.value})} type="password" label="Nova senha"/>
                                            <Button>Alterar senha</Button>
                                        </Form>
                                    </>
                                )
                            }
                        ]} 
                    />
                </div>
            </Content>   
        </>
    )
}
