import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/planejamento-estrategico/canvas/folders'

export interface CanvasFolderFormProps {
    title: string,
    date_revision?: string,
    date_created?: string,
    created_by_id?: string
    created_by_name?: string
}

export interface CanvasFolderItemProps extends CanvasFolderFormProps {
    id: string
}

class CanvasFolder
{
    async create(
        data: CanvasFolderFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        data: CanvasFolderFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: CanvasFolderItemProps) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((proccesses: CanvasFolderItemProps[]) => void),
    )
    {
        return await Request.get(`${route}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }
}

export const CanvasFolderAPI = new CanvasFolder()