import React, { useEffect } from 'react'
import { useState } from 'react'
import { FaCheck, FaEye, FaEyeSlash, FaPlus, FaRedo, FaSortAmountDown, FaTimes, FaTrash } from 'react-icons/fa';
import { formatDate } from '../../../functions/dataFormat';
import { ConfirmDelete } from '../../../functions/Modals/ConfirmDelete';
import Warning from '../../../functions/Modals/Warning'
import { ProtocolSubjectItemPropsV2 } from '../../../services/ProtocolV2/ProtocolV2';


export interface newItemTempProps {
    status: boolean,
    title: string
    index_change?: number,
    description?: string,
    responsible?: string,
    time?: string
}

export interface SubjectsProtocolFeedbackProps 
{
    list: Array<ProtocolSubjectItemPropsV2>,
    setList: ((list: Array<ProtocolSubjectItemPropsV2>) => void),
    setNewItemTemp: ((newItemTemp: newItemTempProps) => void)
    newItemTemp: newItemTempProps,
    onLoadingOldSubjects: (() => void)
}

export const SubjectsProtocolFeedback: React.FC<SubjectsProtocolFeedbackProps> = (props) => 
{
    const { 
        list,
        setList,
        setNewItemTemp,
        newItemTemp
    } = props;

    

    const [showClosed, setshowClosed] = useState(false)

    return (
        <div className="sub-form">
            <div className="title-toolkit">
                <div className="title">
                    <span>Assuntos</span>
                </div>
                <div className="toolkit">
                    {
                        list.length > 0 ? (
                            <span title="Limpar todos" className="toolkit-button default" onClick={() => {
                                setList([])
                            }}><FaTimes/> <span className="title">Limpar</span></span>
                        ) : null
                    }
                    <span title="Visualizar finalizados." className="toolkit-button default" onClick={() => {
                        setshowClosed(!showClosed)
                    }}>{showClosed ? <FaEye/> : <FaEyeSlash />} <span className="title">Finalizados</span></span>
                    <span title="Reordenar." className="toolkit-button default" onClick={() => {
                        setList(list.reverse())
                    }}><FaSortAmountDown /> <span className="title">Ordenar</span></span>
                    <span title="Carregar assuntos não concluídos? Clique aqui." className="toolkit-button default" onClick={() => {
                        props.onLoadingOldSubjects()
                    }}><FaRedo/> <span className="title">Assuntos abertos</span></span>
                    <span className="toolkit-button default" onClick={() => {
                        setNewItemTemp({title: '', status: true})
                    }}><FaPlus/> <span className="title">Novo</span></span>
                </div>
            </div>
            <>
                {
                    list.length > 0 ?
                    (
                        <>
                            {
                                list.map((e, i) => (
                                    <div style={{display: e.status === "2" ? showClosed ? 'block' : 'none' : e.status === "1" ? "block" : 'none'}} className={"checklist-item" + (e.status ? ' checked' : '')}>
                                        <input checked={e.status === "1" ? false : true} onChange={() => {
                                            let oldlist = list;
                                           
                                            if(oldlist[i].status === "1")
                                            {
                                                oldlist[i].status = "2"
                                            }else if(oldlist[i].status === "2")
                                            {
                                                oldlist[i].status = "1"
                                            }
            
                                            setList([...oldlist])
                                        }} id={`checkbox_${i}`} type="checkbox" />
                                        <label htmlFor={`checkbox_${i}`} className="checkmark"><FaCheck /></label>
                                        <div className="content" onDoubleClick={() => {
                                            setNewItemTemp({
                                                status: true,
                                                title: e.title,
                                                index_change: i,
                                                description: e.description,
                                                time: e.time,
                                                responsible: e.responsible
                                            })

                                            const formEdit = document.getElementById('form-edit')

                                            if(formEdit)
                                            {
                                                formEdit.focus()
                                            }
                                        }}>
                                            <p><span>Titulo: </span>{e.title}</p>
                                            <p><span>Descrição: </span><div dangerouslySetInnerHTML={{__html: e.description.replaceAll("\n", "<br />")}}></div></p>
                                            <p><span>Prazo: </span>{formatDate(e.time)}</p>
                                            <p><span>Responsável: </span>{e.responsible ? `: ${e.responsible}` : ''}</p>
                                        </div>
                                        <i>Clique duas vezes para editar</i>
                                        <div onClick={() => {
                                            let old_list = list.filter(item => item.id !== e.id);

                                            setList([...old_list])
                                        }} className="remove-item">
                                            <FaTrash />
                                        </div>
                                    </div>
                                ))
                            }
                        </>
                    ) : null
                }
            </>
            {
                newItemTemp.status ? 
                (   
                    <div className="row" style={{marginTop: '20px'}}>
                        <div className="col-12 col-lg-4">
                            <div className="input-box-v2" style={{marginBottom: '10px'}}>
                                <label htmlFor="!#">Assunto</label>
                                <input id="form-edit" autoFocus style={{width: '100%'}} value={newItemTemp.title} onChange={e => setNewItemTemp({...newItemTemp, title: e.target.value})} placeholder="Descreva o assunto" type="text" className="styled"/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="input-box-v2" style={{marginBottom: '10px'}}>
                                <label htmlFor="!#">Responsável</label>
                                <input style={{width: '100%'}} value={newItemTemp.responsible} onChange={e => setNewItemTemp({...newItemTemp, responsible: e.target.value})} placeholder="Quem é o responsável" type="text" className="styled"/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-4">
                            <div className="input-box-v2 m" style={{marginBottom: '10px'}}>
                                <label htmlFor="!#">Prazo</label>
                                <input style={{width: '100%'}} value={newItemTemp.time} onChange={e => setNewItemTemp({...newItemTemp, time: e.target.value})} type="date" className="styled"/>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="input-box-v2 mg-top" style={{marginBottom: '10px'}}>
                                <label htmlFor="!#">Observações</label>
                                <textarea style={{width: '100%'}} value={newItemTemp.description} onChange={e => setNewItemTemp({...newItemTemp, description: e.target.value})} placeholder="Suas observações" className="styled"/>
                            </div>
                        </div>
                    </div>
                ) : null
            }
            <div className="flex" style={{alignItems: 'center', marginTop: '10px'}}>
                {
                    newItemTemp.status ? 
                    (
                        <>
                            <button style={{marginTop: 0, marginRight: '10px'}} type="button" className="btn-styled normal" onClick={() => {
                                if(newItemTemp.status)
                                {
                                    if(newItemTemp.index_change !== undefined)
                                    {
                                        let old_list = list.length ? list : [];

                                        old_list[newItemTemp.index_change].title = newItemTemp.title
                                        old_list[newItemTemp.index_change].description = newItemTemp.description ?? ''
                                        old_list[newItemTemp.index_change].responsible = newItemTemp.responsible ?? ''
                                        old_list[newItemTemp.index_change].time = newItemTemp.time ?? ''

                                        setList([...old_list])
                                        setNewItemTemp({...newItemTemp, title: '', status: false, index_change: undefined})
                                        
                                    }else if(newItemTemp.title)
                                    {
                                        let old_list = list.length ? list : [];

                                        old_list.push({ ...newItemTemp, status: '1', last_subject: 'no', description: newItemTemp.description ?? '', responsible: newItemTemp.responsible ?? '', time: newItemTemp.time ?? ''})
                                    
                                        setList([...old_list])

                                        setNewItemTemp({...newItemTemp, title: '', responsible: '', time: '', description: ''})
                                        
                                    }else{
                                        Warning('Preencha o assunto.')
                                    }
                                }else{
                                    setNewItemTemp({...newItemTemp, status: true})
                                }
                            }}>
                                <FaPlus /> Adicionar assunto
                            </button>
                            <p className="span-link" onClick={() => setNewItemTemp({status: false, title: ''})}>Fechar</p>
                        </>
                    ) : null
                }
            </div>
        </div>
    )
}
