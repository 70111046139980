import { AxiosResponse } from 'axios'
import Error from './Modals/Error'

const DefaultErrorResponse = (error: AxiosResponse) => {
    try {
        console.log(error)
        Error(error.data.message)
    } catch (throws) {
        console.log(throws, error.data)
        Error('Houve um erro ao tentar executar está ação')
    }
}

export default DefaultErrorResponse;