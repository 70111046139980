import Modal from "../Modal";

const ConfirmDelete = (
    $funcSuccess: (() => void),
    $funcError: (() => void),
    $textConfirm: string = "Sim, exclua!",
    $textModal: string = "Você não será capaz de reverter isso!",
    $type: "warning" | "error" | "info" | "question" = "warning"
) => {
    Modal.fire({
        title: 'Você tem certeza?',
        text: $textModal,
        icon: $type,
        showCancelButton: true,
        confirmButtonText: $textConfirm,
        cancelButtonText: 'Não, cancele!',
    }).then((result: any) => {
        if (result.isConfirmed) {
            $funcSuccess()
        }else{
            $funcError()
        }
    })
}

export {
    ConfirmDelete
};