import { AxiosResponse } from "axios"
import RequestWithoutLoading from "../../../functions/RequestWithoutLoading";

export interface ResponseConsultarBacklog {
    macros: Array<BacklogItem>,
    message: String
}

export interface BacklogItem {
    id: string
    label: string
    client_id: string
    atividade_id: string
    date_started: string
    date_completed: string
    date_create: string
    date_preview: string
    date_finish: string
    repeat_day: string
    permission: string
    atividade_name: string
    client_name: string
    date_preview_f: string
    date_finish_f: string
    hour: string,
    type: string,
    backlog_status: string
}

export const ConsultarBacklog = async (
    onSuccess: ((e: ResponseConsultarBacklog) => void),
    onError: ((e: AxiosResponse) => void)
) => await RequestWithoutLoading.get('/rotina/meu-backlog').then(e => onSuccess(e.data)).catch(onError);
