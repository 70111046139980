import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/pop-attachment'

export interface PopFileProps {
    id: string
    title: string
    path: string
    pop_id: string
}

class AttachmentPop
{
    async getAll(
        pop: string,
        onSuccess: ((success: Array<PopFileProps>) => void),
    )
    {
        return await Request.get(`${route}/${pop}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async sendFile(
        form: FormData,
        pop: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}/${pop}`, form, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(onSuccess).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }
}

export const AttachmentPopController = new AttachmentPop()