import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaEnvelope, FaPhone, FaPlus } from 'react-icons/fa'
import { Content } from '../../components/Content'
import { ConfigModal, Modal } from '../../components/Modal'
import { Navbar } from '../../components/Navbar'
import { ConsultMyContacts } from '../../services/Contacts/ConsultMyContacts'
import { ContactItem } from '../../services/Contacts/ContactModule'
import { CreateContact } from './components/CreateContact'
import { UpdateContact } from './components/UpdateContact'

export const Contact = () => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [contacts, setContacts] = useState<Array<ContactItem>>([])
    const loading = useRef(true)

    useEffect(() => {
        ConsultMyContacts(e => setContacts(e.contacts), e => {})        
        loading.current = false
    }, [])

    return (
        <div>
            <Modal {...modalConfig}/>
            <Navbar/>
            <Content loading={loading.current}>
                <div className="my-tasks-backlog">
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Meus contatos</h2>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    title: (
                                        <>
                                            <h2>Criar contato</h2>
                                        </>
                                    ),
                                    content: <CreateContact/>,
                                    onClose: () => setModalConfig({show: false})
                                })
                            }}><FaPlus/> Criar contato</button>
                        </div>
                    </div>
                    <h3 className="title-divider">Meus contatos</h3>
                    {
                        contacts.length > 0 ?
                        (
                            <>
                                {
                                    contacts.map((e, i) => (
                                        <div key={`${i}`} className="task-item-finish">
                                            <p className="label"><span className="span-link" style={{fontSize: '1em'}} onClick={() => {
                                            setModalConfig({
                                                show: true,
                                                title: (
                                                    <>
                                                        <h2>Editar contato</h2>
                                                    </>
                                                ),
                                                content: <UpdateContact id={e.id}/>,
                                                onClose: () => setModalConfig({show: false})
                                            })
                                        }} >{e.name}</span></p>
                                            <p className="feedback">{e.email} <a href={`mailto:+${e.email}`}><FaEnvelope className="clr-theme"/></a></p>
                                            {e.phone ? <p className="date_finish">{e.phone} <a href={`tel:+${e.phone}`}><FaPhone className="clr-theme"/></a></p> : null}
                                        </div>
                                    ))
                                }
                            </>
                        ) : <h4>Você ainda não possui contatos</h4>
                    }
                </div>
            </Content>
        </div>
    )
}
