import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { ConfigModal, ModalRight } from '../../../components/ModalRight'
import { Navbar } from '../../../components/Navbar'
import { FormManual } from './components/FormManual'
import { FormCompanyHistory } from './components/FormCompanyHistory'
import { FormCompanyIntroduction } from './components/FormCompanyIntroduction'
import { FormCompanyFinalConsideration } from './components/FormCompanyFinalConsideration'
import { checkUserData } from '../../../functions/Session'
import { APP_CONFIG } from '../../../constants'

export interface ManualProps {}

export const Manual: React.FC<ManualProps> = (props) => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const history = useHistory()

    return (
        <>
            <ModalRight {...modalConfig} />
            <Navbar/>
            <Content title={
                <div className="title-button">
                    <div className="title">
                        <LinkBackHistory to="/" />
                        <h1 className="app-content-h1-destaque">Manual do Colaborador</h1>
                    </div>
                    <div className="options">
                        <a href={`${APP_CONFIG.localhost}/client/manual-colaborador/pdf/${checkUserData().company_id}`} target="__blank">Gerar PDF</a>
                    </div>
                </div>
            }>
                <div className="content-box">
                    <h2 className="title-page-small" style={{marginBottom: '30px'}}>Escolha uma opção abaixo</h2>
                    <div className="div-button-with-options">
                        <div className="title link" onClick={() => {
                            setModalConfig({
                                show: true,
                                content: <FormCompanyIntroduction onCreate={() => setModalConfig({show: false})} />,
                                onClose: () => setModalConfig({
                                    show: false
                                }),
                                title: <h2>Introdução</h2>
                            })
                        }}><p>Introdução</p></div>
                    </div>
                    <div className="div-button-with-options">
                        <div className="title link" onClick={() => {
                            setModalConfig({
                                show: true,
                                content: <FormManual onCreate={() => setModalConfig({show: false})} />,
                                onClose: () => setModalConfig({
                                    show: false
                                }),
                                title: <h2>Identidade Organizacional</h2>
                            })
                        }}><p>Identidade Organizacional</p></div>
                    </div>
                    <div className="div-button-with-options">
                        <div className="title link" onClick={() => {
                            setModalConfig({
                                show: true,
                                content: <FormCompanyHistory onCreate={() => setModalConfig({show: false})} />,
                                onClose: () => setModalConfig({
                                    show: false
                                }),
                                title: <h2>História da Empresa</h2>
                            })
                        }}><p>História da Empresa</p></div>
                    </div>
                    <div className="div-button-with-options">
                        <div className="title link" onClick={() => {
                            history.push('/pessoas/normas')
                        }}><p>Normas e Procedimentos</p></div>
                    </div>
                    <div className="div-button-with-options">
                        <div className="title link" onClick={() => {
                            setModalConfig({
                                show: true,
                                content: <FormCompanyFinalConsideration onCreate={() => setModalConfig({show: false})} />,
                                onClose: () => setModalConfig({
                                    show: false
                                }),
                                title: <h2>Considerações Finais</h2>
                            })
                        }}><p>Considerações Finais</p></div>
                    </div>
                </div>
            </Content>  
        </>
    )
}