import { BarItemProps, ResponsiveBar } from '@nivo/bar'
import React, { useEffect, useState } from 'react'
import { formatValue } from 'react-currency-input-field'
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'
import { useParams } from 'react-router-dom'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { Navbar } from '../../../components/Navbar'
import { DashboardGraficItemProps, IndicatorsAPI } from '../../../services/Resultado/Indicators/Indicators'

export interface DashboardGraphicProps {
    year: string
}
export interface DashboardGraphicRouteProps {
    department_id: string
}

export const DashboardGraphic: React.FC<DashboardGraphicProps> = (props) => 
{
    const [list, setList] = useState<Array<DashboardGraficItemProps>>([])
    const { department_id } = useParams<DashboardGraphicRouteProps>()
    const [year, setYear] = useState(new Date().getFullYear())

    useEffect(() => {
        IndicatorsAPI.mountGraphicDashboard(year.toString(), department_id, setList)
    }, [year])

    const BarComponent = ({ x, y, width, height, color, label }: BarItemProps) => {
        return (
          <g transform={`translate(${x}, ${y})`}>
            <rect
              width={width}
              height={height}
              fill={color}
              strokeWidth="0"
              stroke="white"
            />
            <text
              x={width / 2}
              y={-15} // the important bit!!
              textAnchor="middle"
            //   dominantBaseline="central"
              transform="rotate(-90)"
              style={{
                fontWeight: 'bold',
                fontSize: 13,
                pointerEvents: 'none',
                fill: '#2C394B',
                opacity: 0.75,
              }}
            >
              {label}
            </text>
          </g>
        );
      };
      

    return (
        <>
            <Navbar/>
            <Content title={
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to={`/indicadores`}/>
                            <h1 className="app-content-h1-destaque">Dashboard Gráfico - ({year})</h1>
                        </div>
                        <div className="options">
                        <button className="bkg-theme clr-white" title="Voltar" onClick={() => {
                                setYear(year-1)
                            }}><FaArrowLeft/>Ano anterior</button>
                            <button className="bkg-theme clr-white" title="Avançar" onClick={() => {
                                setYear(year+1)
                            }}>Próximo ano <FaArrowRight/></button>
                        </div>
                    </div>
                </>
            }>
                <div className="content-box">
                    {
                        list.map((item, index) => {
                            let legend = 'Total R$'

                            if(item.unit === 'numero')
                            {
                                legend = `Total`
                            }

                            if(item.unit === 'porcentagem')
                            {
                                legend = `Total %`
                            }

                            return (
                                <>
                                    <h2 className="title-divider" style={{marginTop: '0px'}}>Gráfico: {item.title}</h2>
                                    <div style={{height: '500px', padding: '30px'}}>
                                        <ResponsiveBar 
                                            barComponent={BarComponent}
                                            data={item.graphic}
                                            keys={[ 'Ano anterior', 'Meta', 'Ano atual']}
                                            indexBy={"month"}
                                            // innerPadding={50}
                                            padding={0.1}
                                            margin={{ top: 50, right: 130, bottom: 50, left: 100 }}
                                            tooltipFormat={data => {
                                                if(item.unit === 'numero')
                                                {
                                                    return `${data.toString()}`
                                                }

                                                if(item.unit === 'porcentagem')
                                                {
                                                    return `${data.toString()}%`
                                                }

                                                return `${formatValue({value: data.toString(), intlConfig: { locale: 'pt-BR',  currency: 'BRL' }})}`
                                            }}
                                            groupMode="grouped"
                                            valueScale={{ type: 'linear' }}
                                            indexScale={{ type: 'band', round: true }}
                                            colors={['#7EB5A6',  `#185ADB`, '#ec6518',]}
                                            defs={[
                                                {
                                                    id: 'dots',
                                                    type: 'patternDots',
                                                    background: 'inherit',
                                                    color: '#38bcb2',
                                                    size: 4,
                                                    padding: 1,
                                                    stagger: true
                                                },
                                                {
                                                    id: 'lines',
                                                    type: 'patternLines',
                                                    background: 'inherit',
                                                    color: '#eed312',
                                                    rotation: -45,
                                                    lineWidth: 6,
                                                    spacing: 10
                                                }
                                            ]}
                                            fill={[
                                                {
                                                    match: {
                                                        id: 'fries'
                                                    },
                                                    id: 'dots'
                                                },
                                                {
                                                    match: {
                                                        id: 'sandwich'
                                                    },
                                                    id: 'lines'
                                                }
                                            ]}
                                            borderColor={{ from: 'color', modifiers: [ [ 'darker', 1.6 ] ] }}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: 'Meses',
                                                legendPosition: 'middle',
                                                legendOffset: 32
                                            }}
                                            axisLeft={{
                                                tickSize: 5,
                                                tickPadding: 5,
                                                tickRotation: 0,
                                                legend: legend,
                                                legendPosition: 'middle',
                                                legendOffset: -60
                                            }}
                                            labelSkipWidth={1}
                                            labelSkipHeight={1}
                                            enableGridX={true}
                                            // labelFormat={label => `${formatValue({value: label.toString(), intlConfig: { locale: 'pt-BR',  currency: 'BRL' }})}`}
                                            // labelFormat={label => `${formatValue({value: label.toString(), intlConfig: { locale: 'pt-BR',  currency: 'BRL' }})}`}
                                            labelFormat={d => {
                                                if(item.unit === "porcentagem")
                                                {
                                                    return (<tspan y={15}>{`${d.toString()}%`}</tspan>) as unknown as string
                                                }

                                                if(item.unit === "numero")
                                                {
                                                    return (<tspan y={15}>{`${d.toString()}`}</tspan>) as unknown as string
                                                }

                                                // return `${formatValue({value: d.toString(), intlConfig: { locale: 'pt-BR',  currency: 'BRL' }})}`
                                                
                                                return (
                                                    <tspan y={15} x={10} > {`${formatValue({value: d.toString(), intlConfig: { locale: 'pt-BR',  currency: 'BRL' }})}`}</tspan>
                                                ) as unknown as string
                                            }}
                                            labelTextColor="#333"
                                            legends={[
                                                {
                                                    dataFrom: 'keys',
                                                    anchor: 'top-right',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: 120,
                                                    translateY: 0,
                                                    itemsSpacing: 2,
                                                    itemWidth: 100,
                                                    itemHeight: 20,
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 0.85,
                                                    symbolSize: 20,
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemOpacity: 1
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                        />
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </Content>
        </>
    )
}