import React, { useEffect, useState } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'
import { EditorHTML } from '../../../../components/EditorHTML'
import { RNCs_api } from '../services/rncs.services'
import Success from '../../../../functions/Modals/Success'
import { formatDate } from '../../../../functions/dataFormat'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../../services/ClientDepartments/ClientDepartments'

export interface FormPOPProps {
    id?: string,
    onSuccess?: (() => void)
}

export const FormRNC: React.FC<FormPOPProps> = (props) => 
{
    const onDelete = () => {
        if(props.id)
        {
            RNCs_api.delete(props.id, () => Success('Apagado com sucesso'))
        }
    }

    const onSubmit = () => {

        let appendedForm = {
            ...form,
            descOccors: description_occors,
            anaCausa: analiseCausa,
            abrang: abrangencia,
            histCorrAcc: histCorrAcc,
            verrEf: verrEf,
            commentsAdd: commentsAdd
        }
        
        if(props.id)
        {
            RNCs_api.update(props.id, appendedForm, () => Success('Atualizado com sucesso', () => window.location.reload()))
        }else{
            RNCs_api.create(appendedForm, () => Success('Criado com sucesso!', () => window.location.reload()))
        }
    }

    const [form, setForm] = useState<any>({
        tipsOccors: '',
        title: '',
        dataRec: '',
        nOccors: '',
        avaliador: '',
        recPor: '',
        descOccors: '',
        resp: '',
        dataAbert: '',
        anaCausa: '',
        abrang: '',
        respAn: '',
        dataAn: '',
        prazoAn: '',
        histCorrAcc: '',
        reclConfStatus: '',
        verrEf: '',
        commentsAdd: '',
        respGer: '',
        dataFe: '',
        department_id: ''
    })

    const [description_occors, setDescription_occors] = useState('')
    const [analiseCausa, setAnaliseCausa] = useState('')
    const [abrangencia, setAbrangencia] = useState('')
    const [histCorrAcc, setHistCorrAcc] = useState('')
    const [verrEf, setverrEf] = useState('')
    const [commentsAdd, setCommentsAdd] = useState('')

    const [listDepartments, setListDepartments] = useState<Array<FormClientDepartment>>([])

    useEffect(() => {
        if(props.id)
        {
            RNCs_api.getById(props.id,  data => {
                setForm({
                    ...data,
                })

                setDescription_occors(data.descOccors ?? '')
                setAnaliseCausa(data.anaCausa ?? '')
                setAbrangencia(data.abrang ?? '')
                setHistCorrAcc(data.histCorrAcc ?? '')
                setverrEf(data.verrEf ?? '')
                setCommentsAdd(data.commentsAdd ?? '')
            })
        }
    }, [props.id])

    useEffect(() => {
        ClientDepartmentsAPI.getAll(setListDepartments)
    }, [])
    

    const changeEvent = (event: React.ChangeEvent<HTMLSelectElement|HTMLInputElement>) => {
        setForm({
            ...form,
            ...{ [event.target.name] : event.target.value }
        })
    }

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={(event) => {
                event.preventDefault()

                onSubmit()
            }}>
                <div className="box-input">
                    <p>Título</p>
                    <input type="text" onChange={changeEvent} value={form.title} name="title" id="" />
                </div>
                <div className="box-input">
                    <p>Departamento</p>
                    <select onChange={e => setForm({...form, department_id: e.target.value})} value={form.department_id}>
                        <option>Selecione</option>
                        {
                            listDepartments.map((item, index) => {
                                return <option value={item.id} key={`${index}`}>{item.name}</option>
                            })
                        }
                    </select>
                </div>
                <div className="box-input">
                    <p>Tipo de ocorrência</p>
                    <select value={form.tipsOccors} onChange={changeEvent} name="tipsOccors" >
                        <option value="">Selecione</option>
                        <option value="1">Reclamação</option>
                        <option value="2">Não Conformidade</option>
                        <option value="3">Observação</option>
                    </select>
                </div>
                <div className="box-input">
                    <p>Data de recebimento</p>
                    <input type="date" onChange={changeEvent} value={formatDate(form.dataRec, 'yyyy-MM-dd')} name="dataRec" id="" />
                </div>
                <div className="box-input">
                    <p>Nº ocorrência</p>
                    <input type="number" onChange={changeEvent} value={form.nOccors} name="nOccors" id="" />
                </div>
                <div className="fluid">
                    <div className="box-input col-6">
                        <p>Avaliador</p>
                        <input type="textq" onChange={changeEvent} value={form.avaliador} name="avaliador" id="" />
                    </div>
                    <div className="box-input  col-6">
                        <p>Recebido por</p>
                        <input type="text" onChange={changeEvent} value={form.recPor} name="recPor" id="" />
                    </div>
                </div>
                <hr />
                <div className="box-input">
                    <p>Descrição da ocorrência</p>
                    <EditorHTML defaultToolbar onChange={value => setDescription_occors(value)} value={description_occors} />
                </div>
                <div className="fluid">
                    <div className="box-input col-6">
                        <p>Responsável</p>
                        <input type="text" onChange={changeEvent} value={form.resp} name="resp" id="" />
                    </div>
                    <div className="box-input  col-6">
                        <p>Data da abertura: </p>
                        <input type="date" onChange={changeEvent} value={formatDate(form.dataAbert, 'yyyy-MM-dd')} name="dataAbert" id="" />
                    </div>
                </div>
                <div className="box-input">
                    <p>Análise da causa</p>
                    <EditorHTML defaultToolbar onChange={value => setAnaliseCausa(value)} value={analiseCausa} />
                </div>
                <div className="box-input">
                    <p>Abrangência</p>
                    <EditorHTML defaultToolbar onChange={value => setAbrangencia(value)} value={abrangencia} />
                </div>
                <div className="fluid">
                    <div className="box-input col-4">
                        <p>Responsável</p>
                        <input type="text" onChange={changeEvent} value={form.respAn} name="respAn" id="" />
                    </div>
                    <div className="box-input  col-4">
                        <p>Data: </p>
                        <input type="date" onChange={changeEvent} value={formatDate(form.dataAn, 'yyyy-MM-dd')} name="dataAn" id="" />
                    </div>
                    <div className="box-input  col-4">
                        <p>Prazo p/ implementação</p>
                        <input type="date" onChange={changeEvent} value={formatDate(form.prazoAn, 'yyyy-MM-dd')} name="prazoAn" id="" />
                    </div>
                </div>
                <div className="box-input">
                    <p>Histórico / Correção / Ação corretiva </p>
                    <EditorHTML defaultToolbar onChange={value => setHistCorrAcc(value)} value={histCorrAcc} />
                </div>
                <hr />
                <p style={{fontWeight: 'bold'}}>CONCLUSÃO SOBRE A IMPLEMENTAÇÃO</p>
                <br />
                <div className="box-input">
                    <p>Reclamação / Não Conformidade Fechada</p>
                    <select onChange={changeEvent} value={form.reclConfStatus} name="reclConfStatus" id="">
                        <option value="">Selecione</option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                    </select>
                </div>
                <div className="box-input">
                    <p>Verificação Da Eficácia</p>
                    <EditorHTML defaultToolbar onChange={value => setverrEf(value)} value={verrEf} />
                </div>
                <div className="box-input">
                    <p>Comentários Adicionais</p>
                    <EditorHTML defaultToolbar onChange={value => setCommentsAdd(value)} value={commentsAdd} />
                </div>
                <div className="fluid">
                    <div className="box-input col-6">
                        <p>Responsável</p>
                        <input type="text" onChange={changeEvent} value={form.respGer} name="respGer" id="" />
                    </div>
                    <div className="box-input  col-6">
                        <p>Data de fechamento: </p>
                        <input type="date" onChange={changeEvent} value={formatDate(form.dataFe, 'yyyy-MM-dd')} name="dataFe" id="" />
                    </div>
                </div>
                {/* <div className="box-input">
                    <p>Siglas e abreviaturas</p>
                    <EditorHTML onChange={value => setobs(value)} value={obs} />
                </div>
                <div className="box-input">
                    <p>Documentos de referência</p>
                    <EditorHTML onChange={value => setdocs_ref(value)} value={docs_ref} />
                </div>
                <div className="box-input">
                    <p>Sistema necessários</p>
                    <EditorHTML onChange={value => setnecessary_systems(value)} value={necessary_systems} />
                </div>
                <div className="box-input">
                    <p>Materiais necessários</p>
                    <EditorHTML onChange={value => setnecessary_materials(value)} value={necessary_materials} />
                </div>
                <div className="box-input">
                    <p>Resultado esperado</p>
                    <EditorHTML onChange={value => setresult(value)} value={result} />
                </div>
                <div className="box-input">
                    <p>Riscos e Cuidados Especiais</p>
                    <EditorHTML onChange={value => setchecklist(value)} value={checklist} />
                </div>
                <div className="box-input">
                    <p>Ações em caso de não conformidade</p>
                    <EditorHTML onChange={value => setobs_error(value)} value={obs_error} />
                </div>
                <div className="box-input">
                    <p>Indicadores do Processo e Polaridade</p>
                    <EditorHTML onChange={value => setprocess_polarity_indicators(value)} value={process_polarity_indicators} />
                </div>
                <div className="box-input">
                    <p>Pontos de melhoria (opcional)</p>
                    <EditorHTML onChange={value => setImprovement_point(value)} value={improvement_point} />
                </div>
                <div className="box-input">
                    <p>Revisar em</p>
                    <input type="date" onChange={e => setForm({...form, date_revision: e.target.value})} value={form.date_revision} />
                </div>
                <hr />
                <div className="box-input">
                    <p>Elaborado por</p>
                    <input type="text" onChange={e => setForm({...form, elaborate_by: e.target.value})} value={form.elaborate_by} />
                </div>
                <div className="box-input">
                    <p>Aprovado Por</p>
                    <select onChange={e => setForm({...form, approved_user_id: e.target.value})} value={form.approved_user_id}>
                        <option value="">Selecione uma opção</option>
                        {
                            users.map((item) => {
                                return <option value={item.id}>{item.name}</option>
                            })
                        }
                    </select>
                </div>
                <hr />
                <div className="title-toolkit">
                    <div className="title">
                        <span>Arquivos</span>
                    </div>
                    <div className="toolkit">
                        <label htmlFor="file" className="toolkit-button default"><FaPlus/></label>
                    </div>
                </div>
                <input  style={{display: 'none'}} type="file" id="file" onChange={event => {
                    let formData = new FormData()

                    if(event.target.files && event.target.files[0])
                    {
                        formData.append('file', event.target.files[0])
        
                        if(props.proccess_id)
                        {
                            AttachmentPopController.sendFile(formData, props.proccess_id, () => {
                                Success('Arquivo enviado com sucesso', () => {
                                    AttachmentPopController.getAll(props.proccess_id, setFiles)
                                })
                            })
                        }
                    }else{
                        Warning('Você não escolheu o nenhum arquivo')
                    }
                }} />
                {files.map((item, index) => {
                    return (
                        <div className="pop-file">
                            <div className="title">{item.title}</div>
                            <div className="options">
                                <span className="clr-success" onClick={() => {
                                    window.open(`${process.env.REACT_APP_URL_ATTACHMENT}/${item.path}`, '_blank')
                                }}><FaDownload /></span>
                                <span className="clr-danger" onClick={() => {
                                    AttachmentPopController.delete(item.id, () => Success('Apagado com sucesso', () => {
                                        AttachmentPopController.getAll(props.proccess_id, setFiles)
                                    }))
                                }} ><FaTrash /></span>
                            </div>
                        </div>
                    )
                })} */}
                <div className="flex between">
                    <button type="submit" style={{marginRight: '10px'}}><MdAdd/> Salvar</button>
                    {
                        props.id ? (
                            <button type="button" onClick={onDelete}><MdDelete/> Apagar</button>
                        ) : null
                    }
                </div>
            </form>
        </>
    )
}