import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import RequestWithoutLoading from "../../functions/RequestWithoutLoading";

const route = '/client/finance'

export interface PingProps {
    status: string
}

class PingPaymentStatus 
{
    async ping(onSuccess: ((success: PingProps) => void),
    )
    {
        return await RequestWithoutLoading.get(`${route}/ping-status`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }
}

export const PingPaymentStatusAPI = new PingPaymentStatus()