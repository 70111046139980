import React, { useEffect, useRef, useState } from 'react'
import { FaSignInAlt } from 'react-icons/fa'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { BoxData } from '../../components/BoxData'
import { Content } from '../../components/Content'
import { LoadingContent } from '../../components/LoadingContent'
import { ConfigModal } from '../../components/Modal'
import { ModalRight } from '../../components/ModalRight'
import { Navbar } from '../../components/Navbar'
import { ConfirmDelete } from '../../functions/Modals/Confirm'
import ServerRequest from '../../functions/ServerRequest'
import { closeSession, cookies } from '../../functions/Session'
import { NormalTask } from '../../services/Calendar/calendarTypes'
import { GenerateDay } from '../../services/Calendar/GenerateDay'
import { GenerateLate } from '../../services/Calendar/GenerateLate'
import { ResponseGenerateMonthItem } from '../../services/Calendar/GenerateMonth'
import { HaveDiagnostic } from '../../services/HaveDiagnostic'
import { DayTasks } from '../Tasks/components/DayTasks'

export const Home = () => 
{
    const history = useHistory()
    const location = useLocation<any>()
    const [countTasks, setCountTasks] = useState(0)
    const [dayProps] = useState<ResponseGenerateMonthItem|any>()
    const loading = useRef(true)

    const [tasksActives, setTasksActives] = useState<Array<NormalTask>>([])
    const [tasksLate, setTasksLate] = useState<Array<NormalTask>>([])

    const [modalConfig, setModalConfig] = useState<ConfigModal>({
        show: false
    })
    
    useEffect(() => {
        
        ServerRequest.get('/').then(response => console.log(response)).catch(err => console.log(err))

        try {
            GenerateDay(e => {
                setTasksActives(e.day.tasks)
    
                if(e.day.tasks)
                {
                    setCountTasks(e.day.tasks.length);
                }
            }, () => {})      
            // Se caso o cliente nao tiver feito o diagnostico
            // faz a consulta no servidor
            // e envia ele para a pagina para realizar o diagnostico.
            HaveDiagnostic(response => {
                if(response.data.status === "consultor")
                {
                    return;
                }
                
                if(response.data.status === "no-responsible")
                {
                    if(cookies.get('user') && cookies.get('user').actived === "1")
                    {
                        window.location.href = '/jornada/10'
                        return;
                    }else{
                        return;
                    }
                }

                if(response.data.status === 'continue') 
                {
                    history.push('/my-diagnostic')
                    return;
                    // if(cookies.get('user') && cookies.get('user').actived === "1")
                    // {
                    //     window.location.href = '/jornada/10'
                    //     return;
                    // }
                }

                if(response.data.status.status !== "3")
                {
                    history.push('/my-diagnostic')
                    return;
                }else{
                    if(cookies.get('user') && cookies.get('user').actived === "1")
                    {
                        window.location.href = '/jornada/10'
                    }
                }
            }, e => {
                ConfirmDelete(() => {
                    history.push('/my-diagnostic')
                }, () => {

                }, 'Ir para o diagnóstico', 'Você ainda não fez seu diagnóstico, deseja realizar?')
            })
    
            if(location.state)
            {
                if(location.state.started_jornada)
                {
                    history.push('/jornada')
                }
            }
    
            GenerateLate(e => {
                if(e.late.tasks)
                {
                    setTasksLate(e.late.tasks);
                }
            }, () => {}) 

            loading.current = false
        } catch (error) {
            closeSession([
                {
                    label: "user"
                },
                {
                    label: "X-Request-Token"
                },
                {
                    label: "X-User-Id"
                }
            ])
            
            window.location.href = '/'
        }
    }, [history, location])


    return (
        <>
            {
                !loading.current ?
                (
                    <>
                        <Navbar/>
                        <ModalRight {...modalConfig} />
                        <Content title={<h1 className="app-content-h1-destaque">Seja bem vindo!</h1>}>
                            <div className="row line-information" style={{marginLeft: 0}}>
                                <BoxData title="Tarefas p/ hoje" body={`${countTasks}`} />
                                <BoxData title="Cronograma" body={<Link className="clr-yellow-dark" style={{fontSize: '0.9em', textDecoration: 'none', display: 'flex', alignItems: 'center'}} to={`/calendar-week`}><FaSignInAlt style={{marginRight: '5px'}} /> Acessar</Link>} />
                            </div>
                            <DayTasks dayProps={dayProps} onChange={async () => {
                                window.location.reload()                                
                            }} setModalConfig={setModalConfig} tasks={tasksActives} tasksLate={tasksLate} />
                        </Content>
                    </>
                ) : <LoadingContent />
            }
        </>
    )
}
