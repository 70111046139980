import React, { useEffect, useState } from 'react'
import { FaArrowRight, FaPlus } from 'react-icons/fa'
import { MdDeleteForever, MdEdit } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { ButtonBackHistory } from '../../../components/ButtonBackHistory'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { ConfigModal, Modal } from '../../../components/Modal'
import { Navbar } from '../../../components/Navbar'
import { NoWithContent } from '../../../components/NoWithContent'
import { ConfirmDelete } from '../../../functions/Modals/ConfirmDelete'
import { CanvasFolderAPI, CanvasFolderItemProps } from '../../../services/PlanejamentoEstrategico/CanvasFolder'
import { FormCanvasFolder } from './components/FormCanvasFolder'
import { formatDate } from '../../../functions/dataFormat'

export interface CanvasProps {}

export const Canvas: React.FC<CanvasProps> = (props) => 
{

    const history = useHistory()
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [folders, setFolders] = useState<CanvasFolderItemProps[]>([])

    useEffect(() => {
        CanvasFolderAPI.getAll(setFolders)
    }, [])

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar /> 
            <Content title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/" />
                            <h1 className="app-content-h1-destaque">Meus Canvas</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Lista de Canvas</h2>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    content: <FormCanvasFolder onSuccess={() => CanvasFolderAPI.getAll(setFolders)} />,
                                    onClose: () => setModalConfig({
                                        show: false
                                    }),
                                    title: <h2>Criar Canvas</h2>
                                })
                            }}>Criar Canvas</button>
                        </div>
                    </div>
                    <br/>
                    {
                        folders.length > 0 ?
                        (
                            folders.map((item) => 
                                {
                                    let other_title = []

                                    if(item.date_created)
                                    {
                                        other_title.push(`Criado em ${formatDate(item.date_created)}`)
                                    }

                                    if(item.date_revision)
                                    {
                                        other_title.push(`Revisar em ${formatDate(item.date_revision)}`)
                                    }

                                    if(item.created_by_name)
                                    {
                                        other_title.push(`Criado por ${item.created_by_name}`)
                                    }

                                    let title = other_title.join(` | `)
                                    
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link" onClick={() => {
                                                        history.push(`/planejamento-estrategico/canvas/${item.id}`)
                                                    }}>{item.title} - {title}</div>
                                                    <div className="options">
                                                        <button className="comum-button" onClick={() => {
                                                            history.push(`/planejamento-estrategico/canvas/${item.id}`)
                                                        }}><FaArrowRight/></button>
                                                        <button className="update-button" onClick={() => {
                                                            setModalConfig({
                                                                show: true,
                                                                content: <FormCanvasFolder id={item.id} onSuccess={() => CanvasFolderAPI.getAll(setFolders)} />,
                                                                onClose: () => setModalConfig({
                                                                    show: false
                                                                }),
                                                                title: <h2>Atualizar</h2>
                                                            })
                                                        }}><MdEdit/></button>
                                                        <button className="delete-button" onClick={() => {
                                                            ConfirmDelete(() => {
                                                                if(item.id)
                                                                {
                                                                    CanvasFolderAPI.delete(item.id, () => {
                                                                        setFolders([...folders.filter(i => i.id !== item.id)])
                                                                    })
                                                                }
                                                            }, () => null)
                                                        }}><MdDeleteForever/></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : <NoWithContent/>
                    }
                </div>
            </Content>
        </>
    )
}