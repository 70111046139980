import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import Success from '../../../../../../functions/Modals/Success'
import { DeparmentAPI, DeparmentFormProps } from '../../../../../../services/ProccessFeedback/Department'

export interface FormDepartmentProps {
    id?: string,
    onSuccess?: (() => void)
}

export const FormDepartment: React.FC<FormDepartmentProps> = (props) => 
{
    const [form, setForm] = useState<DeparmentFormProps>({
        department: '',
    })
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        if(props.id)
        {
            DeparmentAPI.update(props.id, form, s => Success('Salvo com sucesso!', async () => {
                window.location.reload()
            }))
        }else{
            DeparmentAPI.create(form, s => Success('Salvo com sucesso!', () => window.location.reload()))
        }
    }

    useEffect(() => {
        if(props.id)
        {
            DeparmentAPI.getById(props.id, setForm)
        }
    }, [props.id])

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                <div className="box-input">
                    <p>Título</p>
                    <input type="text" onChange={e => setForm({...form, department: e.target.value})} value={form.department} />
                </div>
                <button type="submit"><MdAdd/> Salvar</button>
            </form>
        </>
    )
}