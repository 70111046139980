import React, { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { cookies } from '../../functions/Session'
import { CreateContact } from '../../pages/Contacts/components/CreateContact'
import { FormEntry } from '../../pages/Financeiro/Lancamentos/components/FormEntry'
import { CreateFile } from '../../pages/MyAttachments/Files/components/CreateFIle'
import { CreateProtocol } from '../../pages/Protocol/Components/CreateProtocol'
import { FormUser } from '../../pages/Users/components/FormUser'
import { AccountAPI, PermissionItemProps } from '../../services/Account/Account'
import { ConfigModal, ModalRight } from '../ModalRight'
import { TaskForm } from '../TaskForm'

export interface ButtonFastOptionsProps {
    modalConfig: ((data: ConfigModal) => void)
}

export const ButtonFastOptions: React.FC<ButtonFastOptionsProps> = (props) => 
{
    const [show, setShow] = useState(false)
    const history = useHistory()
    const [permissions, setPermissions] = useState<PermissionItemProps[]>([])
    const checkingPermission = (permission: string) => permissions.findIndex(item => item.permission === permission) >= 0 ? true : false
    const [isConsultor, setIsConsultor] = useState(false)

    useEffect(() => {
        AccountAPI.getPermissions(response => {
            if(response.data)
            {
                setPermissions(response.data)
            }
        })

        let data_user = cookies.get('user')

        if(data_user.consultor)
        {
            setIsConsultor(true)
        }
    }, [])

    const createTask = () => {
        props.modalConfig({
            show: true,
            title: (
                <>
                    <h2>Criar tarefa</h2>
                </>
            ),
            content: <TaskForm type="CREATE" onChange={() => {
                window.location.reload()
            }} />,
            onClose: () => props.modalConfig({show: false})
        })
    }

    const createProtocol = () => {
        props.modalConfig({
            show: true,
            content: <CreateProtocol onSuccess={() => {
                props.modalConfig({show: false})
            }} />,
            onClose: () => props.modalConfig({
                show: false
            }),
            title: <h3 className="title-divider">Criar Pauta</h3>
        })
    }

    const createEntryFinance = () => {
        props.modalConfig({
            show: true,
            content: <FormEntry onSuccess={() => {
                props.modalConfig({
                    show: false
                })
            }} />,
            onClose: () => props.modalConfig({
                show: false
            }),
            title: <h2>Novo item</h2>
        })
    }

    const createContact = () => {
        props.modalConfig({
            show: true,
            content: <CreateContact onSuccess={() => {
                props.modalConfig({
                    show: false
                })
            }}  />,
            onClose: () => props.modalConfig({
                show: false
            }),
            title: <h2 className="title-form-modal">Criar contato</h2>
        })
    }

    const createFile = () => {
        props.modalConfig({
            show: true,
            title: (
                <>
                    <h2>Criar arquivo</h2>
                </>
            ),
            content: <CreateFile onChange={() => {
                props.modalConfig({show: false})
            }} />,
            onClose: () => props.modalConfig({show: false})
        })
    }

    const createUser = () => {
        props.modalConfig({
            show: true,
            title: (
                <>
                    <h2>Criar usuário</h2>
                </>
            ),
            content: <FormUser />,
            onClose: () => props.modalConfig({show: false})
        })
    }

    const moveToFAQ = () => {
        history.push(`/faq`)
    }

    return (
        <>
            <div className="fast-options">
                <div className="btn-fast-options" onClick={() => setShow(!show)}>
                    <FaPlus />
                </div>
                <div className={show ? "fast-options-menu show" : "fast-options-menu"}>
                    <div className="fast-options-menu-item" onClick={() => createTask()}>
                        <span className="label">Nova tarefa</span>
                    </div>
                    <div className="fast-options-menu-item" onClick={() => createProtocol()}> 
                        <span className="label">Nova ATA</span>
                    </div>
                    <div className="fast-options-menu-item" onClick={() => createContact()}> 
                        <span className="label">Novo Contato</span>
                    </div>
                    {
                        checkingPermission('finance') ? (
                            <>
                                <div className="fast-options-menu-item" onClick={() => createEntryFinance()}> 
                                    <span className="label">Novo lançamento (Financeiro)</span>
                                </div>
                            </>
                        ) : null
                    }
                    
                    <div className="fast-options-menu-item" onClick={() => createFile()}> 
                        <span className="label">Novo arquivo</span>
                    </div>
                    {
                        checkingPermission('users') ? (
                            <>
                                <div className="fast-options-menu-item" onClick={() => createUser()}> 
                                    <span className="label">Novo usuário</span>
                                </div>
                            </>
                        ) : null
                    }
                    <hr className='fast-options-separate' />
                    <div className="fast-options-menu-item" onClick={() => moveToFAQ()}> 
                        <span className="label">FAQ</span>
                    </div>
                </div>
            </div>
        </>
    )
}