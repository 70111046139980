import React, { useEffect, useRef, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { LoadingContent } from '../../../../components/LoadingContent'
import Success from '../../../../functions/Modals/Success'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../../services/ClientDepartments/ClientDepartments'
import { ControllerProccessSector, SectorForm } from '../../../../services/Proccess/ControllerProccessSector'

export interface FormSectorProps {
    id?: string,
    onSuccess?: (() => void)
    department: string
}

export const FormSector: React.FC<FormSectorProps> = (props) => 
{
    const [form, setForm] = useState<SectorForm>({
        title: '',
        department_id: props.department
    })

    const loading = useRef(true)

    const [listDepartmentProccess, setListDepartmentProccess] = useState<Array<FormClientDepartment>>([])

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        if(props.id)
        {
            ControllerProccessSector.update(form, () => Success('Salvo com sucesso!', () => {
                if(props.onSuccess)
                {
                    props.onSuccess()
                }
            }))
        }else{
            ControllerProccessSector.create(form, () => Success('Salvo com sucesso!', () => {
                if(props.onSuccess)
                {
                    props.onSuccess()
                }
                
                setForm({title: '', department_id: props.department})
            }))
        }
    }

    useEffect(() => {
        if(props.id)
        {
            ControllerProccessSector.getById(props.id, setForm)
        }else{
            setForm({title: '', id: props.id, department_id: props.department})
        }

        ClientDepartmentsAPI.getAll(setListDepartmentProccess)

        loading.current = false
    }, [props.id, props.department])

    return (
        <>
            {
                !loading.current ?
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p>Título</p>
                            <input type="text" onChange={e => setForm({...form, title: e.target.value})} value={form.title} />
                        </div>
                        {
                            props.id ? (
                                <div className="box-input">
                                    <p>Alterar departamento?</p>
                                    <select onChange={event => {
                                        setForm({
                                            ...form,
                                            new_department_id: event.target.value
                                        })
                                    }}>
                                        <option>Seleciona uma opção</option>
                                        {
                                            listDepartmentProccess.map(item => <option value={item.id}>{item.name} - {item.sigla}</option>)
                                        }
                                    </select>
                                </div>
                            ) : null
                        }
                        <div className="flex between">
                            <button type="submit"><MdAdd/> Salvar</button>
                        </div>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}