import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/contracts'

export interface ContractItemProps {
    id: string
    contract: string
    date_create: string
    date_accept: string
    status: 'open' | 'accept'
}


class Contracts 
{
    async getAll(
        onSuccess: ((success: ContractItemProps[]) => void),
    )
    {
        return await Request.get(`${route}`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }

    async post(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }
}

export const ContractsAPI = new Contracts()