import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import Success from '../../../functions/Modals/Success'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../services/ClientDepartments/ClientDepartments'

export interface ClientDepartmentFormProps {
    id?: string,
    onSuccess?: (() => void)
}

export const ClientDepartmentForm: React.FC<ClientDepartmentFormProps> = (props) => 
{
    const [form, setForm] = useState<FormClientDepartment>({
        name: ''
    })
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        if(props.id)
        {
            ClientDepartmentsAPI.update(form, s => Success('Salvo com sucesso!', async () => {
                window.location.reload()
            }))
        }else{
            ClientDepartmentsAPI.create(form, s => Success('Salvo com sucesso!', async () => {
                window.location.reload()
            }))
        }
    }

    useEffect(() => {
        if(props.id)
        {
            ClientDepartmentsAPI.getById(props.id, setForm)
        }
    }, [props.id])

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                <div className="box-input">
                    <p>Nome</p>
                    <input type="text" onChange={e => setForm({...form, name: e.target.value})} value={form.name} />
                </div>
                <div className="box-input">
                    <p>Sigla</p>
                    <input type="text" onChange={e => setForm({...form, sigla: e.target.value})} value={form.sigla} />
                </div>
                <button type="submit"><MdAdd/> Salvar</button>
            </form>
        </>
    )
}