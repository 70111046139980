import React from 'react'
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export interface EditorHTMLProps {
    value?: string,
    onChange?: ((html: string) => void)
    defaultToolbar?: boolean
}

export const modules = {
    toolbar: [
        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
        [{size: []}],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{'list': 'ordered'}, {'list': 'bullet'}, 
        {'indent': '-1'}, {'indent': '+1'}],
        ['link'],
        [{ 'color': [] }, { 'background': [] }],
        ['clean']
    ]
}

export const EditorHTML: React.FC<EditorHTMLProps> = React.memo((props) => 
{
    return (
        <>
            <ReactQuill
                modules={{
                    toolbar: props.defaultToolbar ? [
                        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                        [{size: []}],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{'list': 'ordered'}, {'list': 'bullet'}, 
                        {'indent': '-1'}, {'indent': '+1'}],
                        ['link'],
                        [{ 'color': [] }, { 'background': [] }],
                        ['clean']
                    ] : [
                        [{ 'header': '1'}, {'header': '2'}, { 'font': [] }],
                        [{size: []}],
                        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                        [{'list': 'ordered'}, {'list': 'bullet'}, 
                        {'indent': '-1'}, {'indent': '+1'}],
                        ['link', 'image', 'video'],
                        [{ 'color': [] }, { 'background': [] }],
                        ['clean']
                    ]
                }}
                value={props.value}
                onChange={props.onChange}
            />
        </>
    )
})