import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { useParams } from 'react-router'
import { LoadingContent } from '../../../../../components/LoadingContent'
import Success from '../../../../../functions/Modals/Success'
import { PDCTemplate_API, PDCTemplate_FormProps } from '../../../../../services/ProccessFeedbackCustom/Template'
import { PDCTemplateQuestion_API, PDCTemplateQuestion_FormProps } from '../../../../../services/ProccessFeedbackCustom/TemplateQuestion'

export interface FormTemplateQuestionProps {
    id?: string,
    template: string
    onSuccess?: (() => void)
}

export interface FormTemplateQuestionRouteParams {
    department: string
}

export const FormTemplateQuestion: React.FC<FormTemplateQuestionProps> = (props) => 
{
    const [form, setForm] = useState<PDCTemplateQuestion_FormProps>({
        question: '',
        type_question: '',
        template_id: ''
    })

    const [loading, setLoading] = useState(true)
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        if(props.id)
        {
            PDCTemplateQuestion_API.update(props.id, {...form}, s => Success('Salvo com sucesso!', async () => {
                window.location.reload()
            }))
        }else{
            PDCTemplateQuestion_API.create({...form}, s => Success('Salvo com sucesso!', () => window.location.reload()))
        }
    }

    useEffect(() => {
        if(props.id)
        {
            PDCTemplateQuestion_API.getById(props.id, props.template, data => {
                setForm({
                    ...data,
                    template_id: props.template
                })
                setLoading(false)
            })
        }else{
            setForm({
                template_id: props.template,
                type_question: '',
                question: ''
            })
        }
    }, [props.id])

    return (
        <>
            {
                !props.id || !loading ?
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p>Questão</p>
                            <input type="text" onChange={e => setForm({...form, question: e.target.value})} value={form.question} />
                        </div>
                        <div className="box-input">
                            <p>Resposta</p>
                            <select onChange={e => setForm({...form, type_question: e.target.value})} value={form.type_question}>
                                <option value="">Selecione uma opção</option>
                                <option value="1">Sim ou não</option>
                                <option value="2">Sempre, Frequentemente, Às vezes, Raramente, Nunca</option>
                                <option value="3">Resposta comum</option>
                                <option value="4">1-10</option>
                            </select>
                        </div>
                        <button type="submit"><MdAdd/> Salvar</button>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}