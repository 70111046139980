import React, { useEffect, useState } from 'react'
import { MdDeleteForever, MdLink, MdModeEdit } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { ButtonBackHistory } from '../../../components/ButtonBackHistory'
import { Content } from '../../../components/Content'
import { ConfigModal, ModalRight } from '../../../components/ModalRight'
import { Navbar } from '../../../components/Navbar'
import { ConfirmDelete } from '../../../functions/Modals/ConfirmDelete'
import { NormasAPI, NormasItemProps } from '../../../services/Normas/Normas'
import { FormNormas } from './components/FormNormas'
import { Markup } from 'interweave';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

export interface NormasProps {}

export const Normas: React.FC<NormasProps> = (props) => 
{
    const [list, setList] = useState<Array<NormasItemProps>>([])
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const history = useHistory()

    useEffect(() => {
        NormasAPI.consult(setList)
    }, [])

    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
          return;
        }
    
        let items: any = reorder(
            list,
            result.source.index,
            result.destination.index
        );
    
        setList([...items]);

        NormasAPI.reorder({list: items}, () => {})
    }

    return (
        <>
            <ModalRight {...modalConfig} />
            <Navbar/>
            <Content title={
                <div className="title-button">
                    <div className="title">
                        <ButtonBackHistory onClick={() => {
                            history.push('/pessoas/manual')
                        }} />
                        <h1 className="app-content-h1-destaque">Normas e Procedimentos</h1>
                    </div>
                    <div className="options">
                        <button onClick={() => {
                            setModalConfig({
                                show: true,
                                content: <FormNormas />,
                                onClose: () => setModalConfig({
                                    show: false
                                }),
                                title: <h2>Nova Norma e Procedimento</h2>
                            })
                        }}>Nova Norma e Procedimento</button>
                    </div>
                </div>
            }>
                <div className="content-box">
                <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="droppable">
                        {(provided, snapshot) => (
                            <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            >
                            {list.map((item, index) => (
                                <Draggable key={item.id} draggableId={item.id} index={index}>
                                {(provided, snapshot) => (
                                    <div className="proccess-item" ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                     onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title">
                                                        <p><b>{item.topic}</b></p>
                                                        <br/>
                                                        <p>{item.description !== "" && item.description !== null ? <Markup  content={item.description.replaceAll('\n', '<br/>').replaceAll('\\"', '"')} /> : <Markup  content={item.suggestions_explanations?.replaceAll('\n', '<br/>').replaceAll('\\"', '"')} />}</p>
                                                    </div>
                                                    <div className="options">
                                                        {
                                                            item.link ?
                                                            (
                                                                <button className="update-button" onClick={() => {
                                                                    window.open(`${item.link}`, '_blank');
                                                                }}><MdLink/></button>
                                                            ) : null
                                                        }
                                                        <button className="update-button" onClick={() => {
                                                            setModalConfig({
                                                                show: true,
                                                                content: <FormNormas id={item.id} onSuccess={() => {
                                                                    NormasAPI.consult(setList)

                                                                    setModalConfig({
                                                                        show: false
                                                                    })
                                                                }} />,
                                                                onClose: () => setModalConfig({
                                                                    show: false
                                                                }),
                                                                title: <h2>Atualizar</h2>
                                                            })
                                                        }}><MdModeEdit/></button>
                                                        <button className="delete-button" onClick={() => {
                                                            ConfirmDelete(() => {
                                                                if(item.id)
                                                                {
                                                                    NormasAPI.delete(item.id, () => {
                                                                        setList([...list.filter(i => i.id !== item.id)])
                                                                    })
                                                                }
                                                            }, () => null)
                                                        }}><MdDeleteForever/></button>
                                                    </div>
                                                </div>
                                            </div>
                                )
                                    }
                                </Draggable>
                            ))}
                            {provided.placeholder}
                            </div>
                        )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </Content>  
        </>
    )
}