import { AxiosResponse } from "axios";
import Request from "../../../../functions/Request";
import DefaultErrorResponse from "../../../../functions/DefaultErrorResponse";

const route = '/client/manual-qualidade'

export interface ManualQualidadeFormProps {
    id?: number
    title?: string
    contexto_org?: string
    esc_sis_ges_quali?: string
    part_inters?: string
    ap_politicos?: string
    ap_economicos?: string
    ap_sociais?: string
    ap_tecnologias?: string
    ap_ambientais?: string
    ap_legais?: string
    camp_abert_novo_topicos?: string
    revision_date?: string
    client_id?: string
    politica_qualidade?: string
    objetivos_qualidade?: string
    company_id?: string
    created_date?: string
    created_user_name?: string
}

class ManualQualidadeService
{
    async create(
        data: ManualQualidadeFormProps,
        onSuccess: ((success: ManualQualidadeFormProps) => void),
    )
    {
        return await Request.post(`${route}`, data).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        data: ManualQualidadeFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((proccesses: Array<ManualQualidadeFormProps>) => void),
    )
    {
        return await Request.get(`${route}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((rnc: ManualQualidadeFormProps) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async pdf(
        id: string,
        onSuccess: ((file: Blob) => void),
        onError: ((err: any) => void)
    )
    {
        return await Request.post(`${route}/pdf/${id}`, {}, { responseType: 'blob'}).then(response => onSuccess(response.data)).catch(onError);
    }
}

export const ManualQualidade_api = new ManualQualidadeService()