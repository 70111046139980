import React, { useEffect, useState } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'
import { EditorHTML } from '../../../../components/EditorHTML'
import { ControleMudancaFormProps, controle_mudanca_api } from '../services/controle.mudanca.services'
import Success from '../../../../functions/Modals/Success'
import { formatDate } from '../../../../functions/dataFormat'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../../services/ClientDepartments/ClientDepartments'
import { FaEdit, FaPlus, FaTrash } from 'react-icons/fa'

export interface FormPOPProps {
    id?: number,
    onSuccess?: (() => void)
}

export interface acoesMudanca {
    acao: string,
    responsavel: string
    prazo: string
}

function removeItemByIndex(array: any[], index: number) {
    if (index >= 0 && index < array.length) {
        array.splice(index, 1);
    }

    return array
}

export const FormControleMudanca: React.FC<FormPOPProps> = (props) => 
{
    const onDelete = () => {
        if(props.id)
        {
            controle_mudanca_api.delete(props.id.toString(), () => Success('Apagado com sucesso', () => window.location.reload()))
        }
    }

    const onSubmit = () => {

        if(props.id)
        {
            controle_mudanca_api.update(props.id.toString(), {
                ...form,
                acoes_impactos: JSON.stringify(acoesImpactos),
                acoes_mudanca: JSON.stringify(acoesMudanca)
            }, () => Success('Atualizado com sucesso', () => window.location.reload()))
        }else{
            controle_mudanca_api.create({
                ...form,
                acoes_impactos: JSON.stringify(acoesImpactos),
                acoes_mudanca: JSON.stringify(acoesMudanca),
            }, () => Success('Criado com sucesso!', () => window.location.reload()))
        }
    }

    const [form, setForm] = useState<ControleMudancaFormProps>({
        title: ''
    })

    useEffect(() => {
        if(props.id)
        {
            controle_mudanca_api.getById(props.id.toString(),  data => {
                setForm({
                    title: '',
                    ...data,
                    date_inicial: data.date_inicial ? formatDate(data.date_inicial, 'yyyy-MM-dd') : "",
                    date_acomp: data.date_acomp ? formatDate(data.date_acomp, 'yyyy-MM-dd') : "",
                    date_solicitacao: data.date_acomp ? formatDate(data.date_solicitacao, 'yyyy-MM-dd') : ""
                })

                if(data.acoes_mudanca)
                    setAcoesMudanca(JSON.parse(data.acoes_mudanca))

                if(data.acoes_impactos)
                    setAcoesImpactos(JSON.parse(data.acoes_impactos))
            })
        }
    }, [props.id])

    const changeEvent = (event: React.ChangeEvent<HTMLSelectElement|HTMLInputElement>) => {
        setForm({
            ...form,
            ...{ [event.target.name] : event.target.value }
        })
    }

    const [acoesMudanca, setAcoesMudanca] = useState<acoesMudanca[]>([])
    const [acoesImpactos, setAcoesImpactos] = useState<acoesMudanca[]>([])

    const [tempAcaoMudanca, setTempAcaoMudanca] = useState({
        acao: '', 
        responsavel: '',
        prazo: ''
    })

    const [tempAcoesImpactos, setTempAcoesImpactos] = useState({
        acao: '', 
        responsavel: '',
        prazo: ''
    })

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={(event) => {
                event.preventDefault()

                onSubmit()
            }}>
                <div className="box-input">
                    <p>Título</p>
                    <input type="text" onChange={changeEvent} value={form.title} name="title" id="" />
                </div>
                <div className="box-input">
                    <p>Motivo</p>
                    <input type="text" onChange={changeEvent} value={form.motivo} name="motivo" id="" />
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="box-input">
                            <p>Data inicial</p>
                            <input type="date" onChange={changeEvent} value={form.date_inicial} name="date_inicial" id="" />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="box-input">
                            <p>Data acompanhamento</p>
                            <input type="date" onChange={changeEvent} value={form.date_acomp} name="date_acomp" id="" />
                        </div>
                    </div>
                </div>
                <p style={{margin: '15px 0px'}}><b>Solicitante</b></p>
                <div className="box-input">
                    <p>Descrição da Mudança</p>
                    <input type="text" onChange={changeEvent} value={form.descricao} name="descricao" id="" />
                </div>
                <div className="box-input">
                    <p>Justificativa da mudança</p>
                    <input type="text" onChange={changeEvent} value={form.justificativa} name="justificativa" id="" />
                </div>
                <div className="row">
                    <div className="col-6">
                        <div className="box-input">
                            <p>Solicitante</p>
                            <input type="text" onChange={changeEvent} value={form.solicitante} name="solicitante" id="" />
                        </div>
                    </div>
                    <div className="col-6">
                        <div className="box-input">
                            <p>Data da solicitação</p>
                            <input type="date" onChange={changeEvent} value={form.date_solicitacao} name="date_solicitacao" id="" />
                        </div>
                    </div>
                </div>
                <div className="title-toolkit fast" style={{margin: '15px 0px'}}>
                    <div className="title" style={{marginRight: '5px'}}> 
                        <span>Ações para realização da mudança </span>
                    </div>
                    <div className="toolkit">
                        <span title="Carregar assuntos não concluídos? Clique aqui." className="toolkit-button success" onClick={() => {
                            let tmp = acoesMudanca
                            tmp.push(tempAcaoMudanca)
                            setAcoesMudanca(tmp)

                            setTempAcaoMudanca({
                                acao: '',
                                responsavel: '',
                                prazo: ''
                            })
                        }}><FaPlus/><span>Adicionar item</span></span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className="box-input">
                            <p>Ação</p>
                            <input type="text" onChange={e => setTempAcaoMudanca({...tempAcaoMudanca, acao: e.target.value})} value={tempAcaoMudanca.acao}  />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="box-input">
                            <p>Responsável</p>
                            <input type="text" onChange={e => setTempAcaoMudanca({...tempAcaoMudanca, responsavel: e.target.value})} value={tempAcaoMudanca.responsavel}  />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="box-input">
                            <p>Prazo</p>
                            <input type="text" onChange={e => setTempAcaoMudanca({...tempAcaoMudanca, prazo: e.target.value})} value={tempAcaoMudanca.prazo}  />
                        </div>
                    </div>
                </div>
                <table style={{width: '100%', borderRadius: '3px'}}>
                    <thead>
                        <th style={{padding: '5px', borderTopLeftRadius: '3px', borderBottomLeftRadius: '3px', border: '1px solid #f1f1f1', marginBottom: '1px'}}>Ação</th>
                        <th style={{padding: '5px', border: '1px solid #f1f1f1', marginBottom: '1px'}}>Responsável</th>
                        <th style={{padding: '5px', borderTopRightRadius: '3px', borderBottomRightRadius: '3px',  border: '1px solid #f1f1f1', marginBottom: '1px'}}>Prazo</th>
                        <th style={{padding: '5px', borderTopRightRadius: '3px', borderBottomRightRadius: '3px',  border: '1px solid #f1f1f1', marginBottom: '1px'}}>Opções</th>
                    </thead>
                    <tbody>
                        {acoesMudanca.map((item, index) => {
                            return (
                                <tr>
                                    <td style={{padding: '5px', textAlign: 'center', border: '1px solid #f1f1f1', borderTop: 'unset'}} className="col-4 text-center">{item.acao}</td>
                                    <td style={{padding: '5px', textAlign: 'center', border: '1px solid #f1f1f1', borderTop: 'unset'}} className="col-4 text-center">{item.responsavel}</td>
                                    <td style={{padding: '5px', textAlign: 'center', border: '1px solid #f1f1f1', borderTop: 'unset'}} className="col-4 text-center">{item.prazo}</td>
                                    <td style={{padding: '5px', textAlign: 'center', border: '1px solid #f1f1f1', borderTop: 'unset'}} className="col-4 text-center"><FaEdit onClick={() => {
                                        setTempAcaoMudanca({...item})

                                        let tmp = acoesMudanca;

                                        tmp = removeItemByIndex(tmp, index)

                                        setAcoesMudanca([...tmp])
                                    }} /> <FaTrash style={{marginLeft: '10px'}} onClick={() => {
                                        console.log(acoesMudanca)
                                        let tmp = acoesMudanca;

                                        tmp = removeItemByIndex(tmp, index)

                                        setAcoesMudanca([...tmp])
                                    }} /></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <p style={{margin: '15px 0px'}}><b>Análise da mudança</b></p>
                <div className="box-input">
                    <p>Resultado da análise crítica da mudança</p>
                    <input type="text" onChange={changeEvent} value={form.resultado_mudanca} name="resultado_mudanca" id="" />
                </div>
                <div className="box-input">
                    <p>Quem deve ser informado</p>
                    <input type="text" onChange={changeEvent} value={form.quem_informado} name="quem_informado" id="" />
                </div>
                <div className="box-input">
                    <p>Documentos a serem alterados</p>
                    <input type="text" onChange={changeEvent} value={form.docs_alterados} name="docs_alterados" id="" />
                </div>
                <div className="title-toolkit fast" style={{margin: '15px 0px'}}>
                    <div className="title" style={{marginRight: '5px'}}>
                        <span>Ações preventivas tomadas para prevenir impactos </span>
                    </div>
                    <div className="toolkit">
                        <span title="Carregar assuntos não concluídos? Clique aqui." className="toolkit-button success" onClick={() => {
                            let tmp = acoesImpactos
                            tmp.push(tempAcoesImpactos)
                            setAcoesImpactos(tmp)

                            setTempAcoesImpactos({
                                acao: '',
                                responsavel: '',
                                prazo: ''
                            })
                        }}><FaPlus/><span>Adicionar item</span></span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className="box-input">
                            <p>Ação</p>
                            <input type="text" onChange={e => setTempAcoesImpactos({...tempAcoesImpactos, acao: e.target.value})} value={tempAcoesImpactos.acao}  />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="box-input">
                            <p>Responsável</p>
                            <input type="text" onChange={e => setTempAcoesImpactos({...tempAcoesImpactos, responsavel: e.target.value})} value={tempAcoesImpactos.responsavel}  />
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="box-input">
                            <p>Prazo</p>
                            <input type="text" onChange={e => setTempAcoesImpactos({...tempAcoesImpactos, prazo: e.target.value})} value={tempAcoesImpactos.prazo}  />
                        </div>
                    </div>
                </div>
                <table style={{width: '100%', borderRadius: '3px'}}>
                    <thead>
                        <th style={{padding: '5px', borderTopLeftRadius: '3px', borderBottomLeftRadius: '3px', border: '1px solid #f1f1f1', marginBottom: '1px'}}>Ação</th>
                        <th style={{padding: '5px', border: '1px solid #f1f1f1', marginBottom: '1px'}}>Responsável</th>
                        <th style={{padding: '5px', borderTopRightRadius: '3px', borderBottomRightRadius: '3px',  border: '1px solid #f1f1f1', marginBottom: '1px'}}>Prazo</th>
                        <th style={{padding: '5px', borderTopRightRadius: '3px', borderBottomRightRadius: '3px',  border: '1px solid #f1f1f1', marginBottom: '1px'}}>Opções</th>
                    </thead>
                    <tbody>
                        {acoesImpactos.map((item, index) => {
                            return (
                                <tr>
                                    <td style={{padding: '5px', textAlign: 'center', border: '1px solid #f1f1f1', borderTop: 'unset'}} className="col-4 text-center">{item.acao}</td>
                                    <td style={{padding: '5px', textAlign: 'center', border: '1px solid #f1f1f1', borderTop: 'unset'}} className="col-4 text-center">{item.responsavel}</td>
                                    <td style={{padding: '5px', textAlign: 'center', border: '1px solid #f1f1f1', borderTop: 'unset'}} className="col-4 text-center">{item.prazo}</td>
                                    <td style={{padding: '5px', textAlign: 'center', border: '1px solid #f1f1f1', borderTop: 'unset'}} className="col-4 text-center"><FaEdit onClick={() => {
                                        setTempAcoesImpactos({...item})

                                        let tmp = acoesImpactos;

                                        tmp = removeItemByIndex(tmp, index)

                                        setAcoesImpactos([...tmp])
                                    }} /> <FaTrash style={{marginLeft: '10px'}} onClick={() => {
                                        let tmp = acoesImpactos;

                                        tmp = removeItemByIndex(tmp, index)

                                        setAcoesImpactos([...tmp])
                                    }} /></td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
                <div className="flex between">
                    <button type="submit" style={{marginRight: '10px'}}><MdAdd/> Salvar</button>
                    {
                        props.id ? (
                            <button type="button" onClick={onDelete}><MdDelete/> Apagar</button>
                        ) : null
                    }
                </div>
            </form>
        </>
    )
}