import { AxiosResponse } from "axios";
import Request from "../../functions/Request";
import ServerRequest from "../../functions/ServerRequest";
import { UserInformations } from "../Account/Account";

const route = '/client/cargos'
const route_descriptions = '/users/jobs-descriptions/descriptions'

export interface DepartmentItemFormProps {
    title: string
    id?: string
}

export interface DescriptionItemFormProps {
    id?: string
    office: string
    immediate_superior?: string
    client_id: string
    department_id: string
    occupational_family: string
    item: string
    cargo_mission: string
    detailed_assignments: string
    basic_requirements_formation: string
    performance_indicators: string
    basic_requirements_experience: string
    basic_requirements_behavioral_skills: string
    approved_user_id?: string
    created_user_id?: string
    elabored_date?: string
    revision_in?: string
    revision_date?: string
    approved_status?: string
    basic_requirements_technical_skills: string,
    user_created?: UserInformations
    user_updated?: UserInformations
}

export interface DescriptionChangeDepartmentFormProps {
    department_id?: string
}

export interface DescriptionApproveFormProps {
    status?: string
}

export interface DescriptionHistoryItemProps {
    id?: string
    client_id?: number
    description_id?: number
    date_create?: string
    date_init?: string
    data_json?: DescriptionItemFormProps
    user_created?: UserInformations
}

class Cargos
{
    async saveDepartment(form: DepartmentItemFormProps, success: ((response: AxiosResponse) => void))
    {
        return await Request.post(`${route}/departments`, form).then(success);
    }

    async updateDepartment(id: string, form: DepartmentItemFormProps, success: ((response: AxiosResponse) => void))
    {
        return await Request.put(`${route}/departments/${id}`, form).then(success);
    }

    async deleteDepartment(id: string, success: ((response: AxiosResponse) => void))
    {
        return await Request.delete(`${route}/departments/${id}`).then(success);
    }
    
    async consultDepartment(success: ((response: Array<DepartmentItemFormProps>) => void))
    {
        return await Request.get(`${route}/departments`).then(response => success(response.data.response));
    }

    async consultOneDepartment(id: string, success: ((response: DepartmentItemFormProps) => void))
    {
        return await Request.get(`${route}/departments/${id}`).then(response => success(response.data.response));
    }

    // Descrição de cargos

    async saveDescription(form: DescriptionItemFormProps, success: ((response: AxiosResponse) => void))
    {
        return await ServerRequest.post(`${route_descriptions}`, form).then(success);
    }

    async updateDescription(id: string, form: DescriptionItemFormProps, success: ((response: AxiosResponse) => void))
    {
        return await ServerRequest.put(`${route_descriptions}/${id}`, form).then(success);
    }

    async updateDescriptionDepartment(id: string, form: DescriptionChangeDepartmentFormProps, success: ((response: AxiosResponse) => void))
    {
        return await ServerRequest.put(`${route_descriptions}/${id}/change-department`, form).then(success);
    }

    async updateApprove(id: string, form: DescriptionApproveFormProps, success: ((response: AxiosResponse) => void))
    {
        return await ServerRequest.post(`${route_descriptions}/to-approve/${id}`, form).then(success);
    }

    async duplicateDescription(id: string, success: ((response: AxiosResponse) => void))
    {
        return await ServerRequest.post(`${route_descriptions}/duplicate/${id}`).then(success);
    }

    async deleteDescription(id: string, success: ((response: AxiosResponse) => void))
    {
        return await ServerRequest.delete(`${route_descriptions}/${id}`).then(success);
    }
    
    async consultDescription(department: string, success: ((response: Array<DescriptionItemFormProps>) => void))
    {
        return await ServerRequest.get(`${route_descriptions}/department/${department}`).then(response => success(response.data));
    }

    async consultDescriptionToApprove(department: string, success: ((response: Array<DescriptionItemFormProps>) => void))
    {
        return await ServerRequest.get(`${route_descriptions}/to-approve`).then(response => success(response.data));
    }

    async consultDescriptionHistory(description_id: string, success: ((response: Array<DescriptionHistoryItemProps>) => void))
    {
        return await ServerRequest.get(`${route_descriptions}/history/list/${description_id}/`).then(response => success(response.data));
    }

    async consultDescriptionHistoryItem(item_id: string, success: ((response: DescriptionHistoryItemProps) => void))
    {
        return await ServerRequest.get(`${route_descriptions}/history/item/${item_id}/`).then(response => success(response.data));
    }

    async consultOneDescription(id: string, success: ((response: DescriptionItemFormProps) => void))
    {
        return await ServerRequest.get(`${route_descriptions}/${id}`).then(response => success(response.data));
    }

}

export const CargosAPI = new Cargos()