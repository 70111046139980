import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { FeedbacksPFRouteParams } from '..'
import { LoadingContent } from '../../../../../../components/LoadingContent'
import Success from '../../../../../../functions/Modals/Success'
import { FeedbackAPI, FeedbackFormProps } from '../../../../../../services/ProccessFeedback/Feedback'

export interface FormDepartmentProps {
    id?: string,
    onSuccess?: (() => void)
}

export const FormFeedback: React.FC<FormDepartmentProps> = (props) => 
{
    const { collaborator } = useParams<FeedbacksPFRouteParams>()
    const [loading, setLoading] = useState(true)
    const [form, setForm] = useState<FeedbackFormProps>({
        ausencias: '',
        atrasos: '',
        compromissos_desempenho: '',
        capacidade_analise: '',
        responsabilidade: '',
        qualidade_produtividade: '',
        habilidade_tecnica: '',
        trabalho_equipe: '',
        capacidade_decisao: '',
        atendimento_ao_client: '',
        atualizacao: '',
        adm_con_trabalho: '',
        comunicao: '',
        melhorias: '',
        collaborator_id: '',
        _3cs_company: '',
        _3cs_my: ''
    })
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        if(props.id)
        {
            FeedbackAPI.update(props.id, collaborator, {...form, collaborator_id: collaborator}, s => Success('Salvo com sucesso!', async () => {
                window.location.reload()
            }))
        }else{
            FeedbackAPI.create(collaborator, {...form, collaborator_id: collaborator}, s => Success('Salvo com sucesso!', () => window.location.reload()))
        }
    }

    const OptionsDefault = (props: React.SelectHTMLAttributes<HTMLSelectElement>) => {
        return (
            <select {...props}>
                <option>Selecione uma opção</option>
                <option value="1">Sempre</option>
                <option value="2">Frequentemente</option>
                <option value="3">Às vezes</option>
                <option value="4">Raramente</option>
                <option value="5">Nunca</option>
            </select>
        )
    }

    useEffect(() => {
        if(props.id)
        {
            FeedbackAPI.getById(props.id, setForm)
            setLoading(false)
        }
    }, [props.id])

    return (
        <>
            {
                !loading || !props.id ?
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p className="italic">ASSIDUIDADE e PONTUALIDADE: Refere-se à frequência do profissional considerando especialmente o número de ausências e a pontualidade do profissional tendo em vista os transtornos gerados em sua área em função da ocorrência.</p><br/>
                            <p>Houve ausências?</p>
                            <select onChange={e => setForm({...form, ausencias: e.target.value})} value={form.ausencias}>
                                <option>Selecione uma opção</option>
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                            </select>
                            <br/><br/>
                            <p>Houve atrasos?</p>
                            <select onChange={e => setForm({...form, atrasos: e.target.value})} value={form.atrasos}>
                                <option>Selecione uma opção</option>
                                <option value="1">Sim</option>
                                <option value="2">Não</option>
                            </select>
                        </div>
                        <div className="box-input">
                            <p className="italic">Concentra-se nos compromissos de desempenho, contribuindo com ideias e sugestões para obtenção de resultados satisfatórios, sempre tendo em vista as metas estabelecidas.</p><br/>
                            <OptionsDefault onChange={e => setForm({...form, compromissos_desempenho: e.target.value})} value={form.compromissos_desempenho}/>
                        </div>
                        <div className="box-input">
                            <p className="title-question">CAPACIDADE DE ANÁLISE/SOLUÇÃO DE PROBLEMAS</p><br/>
                            <p className="italic">Tem capacidade para refletir e compreender assuntos relativos à sua área de atuação, estabelecendo critérios para enfrentar desafios e solucionando os problemas.</p>
                            <OptionsDefault onChange={e => setForm({...form, capacidade_analise: e.target.value})} value={form.capacidade_analise}/>
                        </div>
                        <div className="box-input">
                            <p className="title-question">RESPONSABILIDADE</p><br/>
                            <p className="italic">Percebe a importância de suas funções na estrutura de funcionamento, comprometendo-se com seu trabalho, sendo responsável pelo que faz.</p>
                            <OptionsDefault onChange={e => setForm({...form, responsabilidade: e.target.value})} value={form.responsabilidade}/>
                        </div>
                        <div className="box-input">
                            <p className="title-question">QUALIDADE E PRODUTIVIDADE</p><br/>
                            <p className="italic">Realiza suas tarefas de forma completa, precisa e criteriosa, atendendo aos padrões de qualidade e produtividade esperados.</p>
                            <OptionsDefault onChange={e => setForm({...form, qualidade_produtividade: e.target.value})} value={form.qualidade_produtividade}/>
                        </div>
                        <div className="box-input">
                            <p className="title-question">HABILIDADE TÉCNICA</p><br/>
                            <p className="italic">Conhece os procedimentos, normas e padrões internos necessários para exercer suas atividades.</p>
                            <OptionsDefault onChange={e => setForm({...form, habilidade_tecnica: e.target.value})} value={form.habilidade_tecnica}/>
                        </div>
                        <div className="box-input">
                            <p className="title-question">TRABALHO EM EQUIPE</p><br/>
                            <p className="italic">Interage com os demais membros da equipe e sabe ouvir e respeitar posições contrárias. Busca alternativas e exercício de atitude cooperativa.</p>
                            <OptionsDefault onChange={e => setForm({...form, trabalho_equipe: e.target.value})} value={form.trabalho_equipe}/>
                        </div>
                        <div className="box-input">
                            <p className="title-question">CAPACIDADE DE DECISÃO</p><br/>
                            <p className="italic">Assume decisões dentro de seus limites, não comprometendo o andamento do trabalho, nem gerando constrangimento entre os colegas.</p>
                            <OptionsDefault onChange={e => setForm({...form, capacidade_decisao: e.target.value})} value={form.capacidade_decisao}/>
                        </div>
                        <div className="box-input">
                            <p className="title-question">ATENDIMENTO AO CLIENTE</p><br/>
                            <p className="italic">Estabelece contatos pessoais, buscando atender às expectativas e necessidades dos clientes internos e/ou externos.</p>
                            <OptionsDefault onChange={e => setForm({...form, atendimento_ao_client: e.target.value})} value={form.atendimento_ao_client}/>
                        </div>
                        <div className="box-input">
                            <p className="title-question">ATUALIZAÇÃO</p><br/>
                            <p className="italic">Preocupa-se com seu desenvolvimento profissional, tomando para si a responsabilidade de manter-se atualizado.</p>
                            <OptionsDefault onChange={e => setForm({...form, atualizacao: e.target.value})} value={form.atualizacao}/>
                        </div>
                        <div className="box-input">
                            <p className="title-question">ADMINISTRAÇÃO DE CONDIÇÕES DE TRABALHO</p><br/>
                            <p className="italic">Tem capacidade de organização no trabalho, administrando prazos, rotinas, solicitações e prioridades, mesmo sob pressão ou demanda excessiva de trabalho.</p>
                            <OptionsDefault onChange={e => setForm({...form, adm_con_trabalho: e.target.value})} value={form.adm_con_trabalho}/>
                        </div>
                        <div className="box-input">
                            <p className="title-question">COMUNICAÇÃO</p><br/>
                            <p className="italic">Tem clareza e objetividade na emissão e execução de mensagens, possibilitando perfeito entendimento da informação.</p>
                            <OptionsDefault onChange={e => setForm({...form, comunicao: e.target.value})} value={form.comunicao}/>
                        </div>
                        <div className="box-input">
                            <p className="title-question">3Cs - O que EU devo:</p><br/>
                            <p className="italic">
                                Começar – <br />
                                Continuar – <br />
                                Cessar (Parar) – <br />
                            </p>
                            <textarea rows={15} onChange={e => setForm({...form, _3cs_my: e.target.value})} value={form._3cs_my}/>
                        </div>
                        <div className="box-input">
                            <p className="title-question">3Cs - O que A EMPRESA deve:</p><br/>
                            <p className="italic">
                                Começar – <br />
                                Continuar – <br />
                                Cessar (Parar) – <br />
                            </p>
                            <textarea rows={15} onChange={e => setForm({...form, _3cs_company: e.target.value})} value={form._3cs_company}/>
                        </div>
                        <div className="box-input">
                            <p className="title-question">PRIORIDADES PARA MELHORIA DE DESEMPENHO NA FUNÇÃO ATUAL</p><br/>
                            <p className="italic">Descreva os CONHECIMENTOS e HABILIDADES a serem desenvolvidos e/ou aperfeiçoados. Vale ressaltar que Habilidade é a capacidade de colocar em prática o Conhecimento adquirido, ou seja, é saber fazer. Exemplos de conhecimento: conhecimento das leis, conhecimento de um fato, conhecimento de um documento, informação ou noção adquiridas pelo estudo ou pela experiência.                                Exemplos de habilidade: boa comunicação, liderança, capacidade de análise, gerenciamento do tempo, inteligência emocional, criatividade, resolução de problemas.</p>
                            <textarea rows={15} onChange={e => setForm({...form, melhorias: e.target.value})} value={form.melhorias}/>
                        </div>
                        <button type="submit"><MdAdd/> Salvar</button>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}