import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md';
import Success from '../../../../functions/Modals/Success';
import { ControllerIndicatorsMeta, FormMeta, ListIndicatorsItem } from '../../../../services/IndicatorsMeta/IndicatorsMeta';

interface CreateMetaProps {}

export const CreateMeta: React.FC<CreateMetaProps> = () => {
    
    const [form, setForm] = useState<FormMeta>({})
    const [listIndicators, setListIndicators] = useState<Array<ListIndicatorsItem>>([])

    useEffect(() => {
        ControllerIndicatorsMeta.getAllIndicators(indicators => setListIndicators(indicators))
    }, [])

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={e => {
                e.preventDefault();

                ControllerIndicatorsMeta.create(form, () => Success('Criado com sucesso!', () => window.location.reload()))
            }}>
                
                <div className="box-input">
                    <label htmlFor="!#">Dia</label>
                    <input type="datetime-local" value={form.date}
                    onChange={e => {
                        setForm({...form, date: e.target.value})
                    }} className="styled"/>
                </div>
                <div className="box-input">
                    <label htmlFor="!#">Meta em %</label>
                    <input type="text" value={form.meta}
                    onChange={e => {
                        setForm({...form, meta: e.target.value})
                    }} className="styled"/>
                </div>
                <div className="box-input">
                    <label htmlFor="!#">Indicador</label>
                    <select value={form.indicator}
                        onChange={e => {
                            setForm({...form, indicator: e.target.value})
                        }} className="styled">
                            <option value="">Selecione</option>
                        {
                            listIndicators.map(e => {
                                return <option value={e.id}>{e.title}</option>
                            })
                        }
                    </select>
                </div>
                <button type="submit" className="button-styled bkg-success clr-white hover"><MdAdd/> Salvar</button>
            </form>
        </>
    )
}