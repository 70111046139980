import Modal from "../Modal"

const Warning = ($text: string, onClose?: any) => {
    Modal.fire({
        position: 'center',
        title: 'Aviso!',
        text: $text,
        showConfirmButton: false,
        icon: 'warning',
        onClose: onClose,
        timer: 1500
    })
}


export default Warning