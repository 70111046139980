import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { MdDeleteForever, MdEdit } from 'react-icons/md'
import { Content } from '../../components/Content'
import { LinkBackHistory } from '../../components/LinkBackHistory'
import { ConfigModal, Modal } from '../../components/Modal'
import { Navbar } from '../../components/Navbar'
import { NoWithContent } from '../../components/NoWithContent'
import { ConfirmDelete } from '../../functions/Modals/Confirm'
import { ClientDepartmentsAPI } from '../../services/ClientDepartments/ClientDepartments'
import { ProjectAPI, ProjectProps } from '../../services/V2/Project'
import { ProjectForm } from './components/ProjectForm'

export interface ProjectsProps {}

export const Projects: React.FC<ProjectsProps> = () => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [list, setList] = useState<ProjectProps[]>([])
    const loading = useRef(true)

    const syncData = () => {
        ProjectAPI.getAll(setList)
        loading.current = false
    }

    useEffect(() => {
        syncData()    
    }, [])

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/" />
                            <h1 className="app-content-h1-destaque">Meus projetos / Unidades de negócios</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Lista de projetos</h2>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    content: <ProjectForm onSuccess={() => ClientDepartmentsAPI.getAll(setList)} />,
                                    onClose: () => setModalConfig({
                                        show: false
                                    }),
                                    title: <h2>Criar projeto</h2>
                                })
                            }}>Criar projeto</button>
                        </div>
                    </div>
                    <br/>
                    {
                        list.length > 0 ?
                        (
                            list.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link" onClick={() => {
                                                    }}>{item.name}</div>
                                                    <div className="options">
                                                        <button className="update-button" onClick={() => {
                                                            setModalConfig({
                                                                show: true,
                                                                content: <ProjectForm id={item.id} onSuccess={() => ProjectAPI.getAll(setList)} />,
                                                                onClose: () => setModalConfig({
                                                                    show: false
                                                                }),
                                                                title: <h2>Atualizar departamento</h2>
                                                            })
                                                        }}><MdEdit/></button>
                                                        <button className="delete-button" onClick={() => {
                                                            ConfirmDelete(() => {
                                                                if(item.id)
                                                                {
                                                                    ProjectAPI.delete(item.id, () => {
                                                                        setList([...list.filter(i => i.id !== item.id)])
                                                                    })
                                                                }
                                                            }, () => null)
                                                        }}><MdDeleteForever/></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : <NoWithContent/>
                    }
                </div>
            </Content>
        </>
    )
}