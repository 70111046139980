import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import Success from '../../../../../../functions/Modals/Success'
import { FormTopicItemsProps, TopicItemsAPI } from '../../../../../../services/Financeiro/TopicItems'

export interface FormSubtopicProps {
    id?: string,
    topic_id: string,
    onSuccess?: (() => void)
}

export const FormSubtopic: React.FC<FormSubtopicProps> = (props) => 
{
    const [form, setForm] = useState<FormTopicItemsProps>({
        topic: '',
        topic_id: ''
    })

    useEffect(() => {
        if(props.id)
        {
            TopicItemsAPI.getById(props.id, data => {
                if(data.name && data.topic_id)
                {
                    setForm({topic: data.name, topic_id: data.topic_id})
                }
            })
        }
    }, [props.id])
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        if(props.id)
        {
            TopicItemsAPI.update(props.id, form, s => Success('Salvo com sucesso!', async () => {
                window.location.reload()
            }))
        }else{
            TopicItemsAPI.create({...form, topic_id: props.topic_id}, s => Success('Salvo com sucesso!', async () => {
                window.location.reload()
            }))
        }
    }

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                <div className="box-input">
                    <p>Nome</p>
                    <input type="text" onChange={e => setForm({...form, topic: e.target.value})} value={form.topic} />
                </div>
                <button type="submit"><MdAdd/> Salvar</button>
            </form>
        </>
    )
}