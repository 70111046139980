import MaterialTable from 'material-table'
import React, { useEffect, useState } from 'react'
import { FaFileExport } from 'react-icons/fa'
import { Content } from '../../../components/Content'
import { HomeButton } from '../../../components/HomeButton'
import { ConfigModal, ModalRight } from '../../../components/ModalRight'
import { Navbar } from '../../../components/Navbar'
import { TaskForm } from '../../../components/TaskForm'
import { APP_CONFIG } from '../../../constants'
import { formatDate } from '../../../functions/dataFormat'
import { localization } from '../../../functions/localization'
import { cookies } from '../../../functions/Session'
import { TimesheetAPI, TimesheetReportItemProps } from '../../../services/V2/Timesheet'

export interface TimesheetReportProps {}

export const TimesheetReport: React.FC<TimesheetReportProps> = (props) => 
{
    const [rows, setRows] = useState<TimesheetReportItemProps[]>([])

    useEffect(() => {
        TimesheetAPI.report(setRows)
    }, [])

    const secondsToTime = (secs: number) => 
    {
        secs = Math.round(secs);
        var hours = Math.floor(secs / (60 * 60));

        var divisor_for_minutes = secs % (60 * 60);
        var minutes = Math.floor(divisor_for_minutes / 60);

        var divisor_for_seconds = divisor_for_minutes % 60;
        var seconds = Math.ceil(divisor_for_seconds);

        let string_hours = hours < 10 ? `0${hours}` : hours;
        let string_minutes = minutes < 10 ? `0${minutes}` : minutes;
        let string_seconds = seconds < 10 ? `0${seconds}` : seconds;

        return `${string_hours}:${string_minutes}:${string_seconds}`;
    }

    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})

    return (
        <>
            <ModalRight {...modalConfig}/>
            <Navbar/>
            <Content title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <HomeButton />
                            <h1 className="app-content-h1-destaque">Timesheet: Relatório</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="body-content" style={{marginTop: '20px'}}>
                    <div className="title">
                        <h2>Relatório</h2>
                    </div>
                    <MaterialTable 
                        title=""
                        localization={localization}
                        options={{
                            filtering: true,
                            pageSize: 20
                        }}
                        components={{
                            Toolbar: props => (
                                <div>
                                  <div className='table-toolbar'>
                                    <div className="btn-table" onClick={() => {
                                        const token = cookies.get('X-Request-Token')

                                        window.open(`${APP_CONFIG.localhost}/calendar/timesheet-report/csv?token=${token}`);
                                    }}>
                                        <FaFileExport /> Exportar p/ Excel
                                    </div>
                                  </div>
                                </div>
                              ),
                        }}
                        detailPanel={[
                            {
                                tooltip: 'Timesheets',
                                render: (row) => {
                                    return (
                                        <MaterialTable 
                                            title=""
                                            components={{
                                                Toolbar: props => (
                                                    <div>
                                                      <div className='table-toolbar'>
                                                        <div className="btn-table" onClick={() => {
                                                            const token = cookies.get('X-Request-Token')
                    
                                                            window.open(`${APP_CONFIG.localhost}/calendar/timesheet-report-month/csv?token=${token}&month=${row.month.replace('/', '-')}`);
                                                        }}>
                                                            <FaFileExport /> Exportar p/ Excel
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ),
                                            }}
                                            localization={localization}
                                            onRowClick={(event, rowData) => {
                                                if(rowData?.calendar_id)
                                                {
                                                    setModalConfig({
                                                        show: true,
                                                        title: (
                                                            <>
                                                                <h2 className="title-modal">{rowData.label}</h2>
                                                            </>
                                                        ),
                                                        content: <TaskForm 
                                                            id={rowData.calendar_id}
                                                            type="UPDATE"
                                                            onChange={() => {
                                                                setModalConfig({show: false})
                                                            }}
                                                        />,
                                                        onClose: () => setModalConfig({show: false})
                                                    })
                                                }
                                            }}
                                            options={{
                                                filtering: true,
                                            }}
                                            data={row.timesheets.filter((item => item.label ))}
                                            columns={[
                                                {
                                                    title: 'Tarefa',
                                                    field: 'label',
                                                },
                                                {
                                                    title: 'Etiquetas',
                                                    field: 'tags_labels',
                                                    render: rowData => rowData.tags?.map((item, index) => {
                                                        return <span style={{backgroundColor: item.color, color: '#fff', padding: '2px 4px', borderRadius: '2px', margin: '1px', fontWeight: 600, marginRight: '5px', display: 'inline-block'}}>{item.label}</span>
                                                    }),
                                                },
                                                {
                                                    title: 'Data',
                                                    field: 'date',
                                                    render: rowData => formatDate(rowData.date)
                                                },
                                                {
                                                    title: 'Horário de início',
                                                    field: 'hour',
                                                },
                                                {
                                                    title: 'Horário de fim',
                                                    field: 'hour_finish'
                                                },
                                                {
                                                    title: 'Início Real',
                                                    field: 'real_started',
                                                    render: rowData => formatDate(rowData.real_started, 'dd/MM/yyyy HH:mm:ss')
                                                },
                                                {
                                                    title: 'Fim Real',
                                                    field: 'real_ended',
                                                    render: rowData => rowData.real_ended ? formatDate(rowData.real_ended, 'dd/MM/yyyy HH:mm:ss') : 'Em andamento'
                                                },
                                                {
                                                    title: 'Timesheet',
                                                    field: 'total',
                                                    render: rowData => {
                                                        if(rowData.total)
                                                        {
                                                            return secondsToTime(Number(rowData.total))
                                                        }else{
                                                            return '00:00'
                                                        }
                                                    }
                                                },
                                            ]}
                                        />
                                    )
                                }
                            }
                        ]}
                        data={rows}
                        columns={[
                            {
                                title: 'Mês',
                                field: 'month',
                            }
                        ]}
                    />
                </div>
            </Content>
        </>
    )
}