import { AxiosResponse } from "axios";
import ServerRequest from "../../../../functions/ServerRequest";
import DefaultErrorResponse from "../../../../functions/DefaultErrorResponse";

const route = '/users/analise-critica/analise'


export interface AnaliseCriticaItemProps {
    id?: number
    title?: string,
    reuniao_id?: string,
    tipo?: string,
    elabored_date?: string,
    local?: string,
    created_date?: string
    user_created?: {
        name?: string
    }
}

export interface AnaliseCriticaTemplateItemProps {
    assunto_entrada?: string
    cod_order?: string
    id?: string    
    status?: boolean
}

class AnaliseCriticaService
{
    async create(
        data: AnaliseCriticaItemProps,
        onSuccess: ((success: AnaliseCriticaItemProps) => void),
    )
    {
        return await ServerRequest.post(`${route}`, data).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async template(id: string, onSuccess: ((success: AnaliseCriticaTemplateItemProps[]) => void))
    {
        return await ServerRequest.get(`/users/analise-critica/template/${id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        data: AnaliseCriticaItemProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.put(`${route}/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((proccesses: Array<AnaliseCriticaItemProps>) => void),
    )
    {
        return await ServerRequest.get(`${route}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((rnc: AnaliseCriticaItemProps) => void),
    )
    {
        return await ServerRequest.get(`${route}/${id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async pdf(
        id: string,
        onSuccess: ((file: Blob) => void),
        onError: ((err: any) => void)
    )
    {
        return await ServerRequest.post(`${route}/pdf/${id}`, {}, { responseType: 'blob'}).then(response => onSuccess(response.data)).catch(onError);
    }
}

export const analise_critica_api = new AnaliseCriticaService()