import React, { useEffect, useState } from 'react'
import { Content } from '../../../../components/Content'
import { LinkBackHistory } from '../../../../components/LinkBackHistory'
import { Navbar } from '../../../../components/Navbar'
import Chart from 'react-apexcharts'
import { useParams } from 'react-router-dom'
import { FeedbackAPI } from '../../../../services/ProccessFeedback/Feedback'
import { RandomColor } from '../../../../functions/RandomColor'
import { CollaboratorItemProps, PDCCollaborator_API } from '../../../../services/ProccessFeedbackCustom/Collaborator'
import { PDCFeedbackAPI } from '../../../../services/ProccessFeedbackCustom/Feedback'

export interface PFC_FeedbackGraphicsProps {}

export interface FeedbacksPFGraphicsRouteParams {
    collaborator: string
}

export const PFC_FeedbackGraphics: React.FC<PFC_FeedbackGraphicsProps> = (props) => 
{
    const { collaborator } = useParams<FeedbacksPFGraphicsRouteParams>()
    const [colaborador, setColaborador] = useState<CollaboratorItemProps>()

    const [graphic, setGraphic] = useState<any>({
        series: [],
        options: {
            colors: ["#D47222", "#A7CAD6", "#32B811", "#9CA3DD", "#CCA7B6", "#8B4E09", "#0054B2", "#3AF1F0", "#512C8A", "#BACA24", "#653753", "#18EC01", "#D8F6DF"],
            fill: {
                colors: ["#D47222", "#A7CAD6", "#32B811", "#9CA3DD", "#CCA7B6", "#8B4E09", "#0054B2", "#3AF1F0", "#512C8A", "#BACA24", "#653753", "#18EC01", "#D8F6DF"]
            },
            chart: {
                height: 350,
                type: 'bar',
                stackType: '100%',
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    dataLabels: {
                        position: 'top', // top, center, bottom,
                    },
                },
                column: {
                    colorByPoint: true
                }
            },
            dataLabels: {
                enabled: true,
                formatter: (val: string) => {
                    return `${val}%`;
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            xaxis: {
                categories: ['Média'],
                position: 'top',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                }
            },
            yaxis: {
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: false,
                    formatter: (val: string) => {
                        return val;
                    }
                }
            },
        }
    }) 

    useEffect(() => {
        PDCFeedbackAPI.getAllGraphic(collaborator, data => {
            setGraphic(
                {
                    ...graphic, 
                    series: [
                        ...data.map(item => {   
                            return {
                                name: item.label,
                                data: [ item.value ]
                            }
                        })
                    ],
                    colors: [
                        ...data.map(item => {   
                            return RandomColor()
                        })
                    ]
                }
            )

            console.log([...data.map(item => {   
                return RandomColor()
            })])
        })

        PDCCollaborator_API.getById(collaborator, setColaborador)
    }, [])

    return (
        <>
            <Navbar/>
            <Content title={
                <div className="title-button">
                    <div className="title">
                        <LinkBackHistory to={`/pessoas/proccess-feedback-custom/collaborator/feedback/${collaborator}`} />
                        <h1 className="app-content-h1-destaque">Gráfico de feedbacks  ({colaborador?.name} {colaborador?.cargo}) :Média</h1>
                    </div>
                </div>
            }>
                <div className="content-box">
                    <h2 className="title-divider" style={{marginTop: '0px'}}>Gráfico</h2>
                    <div id="chart">
                        <Chart options={graphic.options} series={graphic.series} type="bar" height={350} />
                    </div>
                </div>
            </Content>
        </>
    )
}