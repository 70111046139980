import React from 'react'
import { useState } from 'react'
import { FaLink, FaPlus, FaTimes } from 'react-icons/fa';
import Warning from '../../../functions/Modals/Warning'

export interface ListLinksProps 
{
    list: LinkItem[],
    setList: ((list: LinkItem[]) => void)
}

export interface LinkItem {
    link: string,
    calendar_id?: string
}

export const ListLinks: React.FC<ListLinksProps> = (props) => 
{
    const { list, setList } = props;

    const [newItemTemp, setNewItemTemp] = useState({
        status: false,
        link: ''
    })

    return (
        <div>
            <div className="title-toolkit">
                <div className="title">
                    <span>Links</span>
                </div>
                <div className="toolkit">
                    {
                        list.length > 0 ? (
                            <span title="Limpar todos" className="toolkit-button default" onClick={() => {
                                setList([])
                            }}><FaTimes/></span>
                        ) : null
                    }
                    <span className="toolkit-button default" onClick={() => {
                        setNewItemTemp({link: '', status: true})
                    }}><FaPlus/> <span className="title">Novo link</span></span>
                </div>
            </div>
            <>
                {
                    list.map((item, index) => (
                        <div key={`${index}`} className="link-item">
                            <a href={item.link} target="_blank" rel="noopener noreferrer"><FaLink /> {item.link}</a>
                        </div>
                    ))
                }
            </>
            {
                newItemTemp.status ? 
                (   
                    <div className="input-box-v2" style={{marginTop: '20px'}}>
                        <label htmlFor="!#">Novo link</label>
                        <input style={{width: '100%'}} value={newItemTemp.link} onChange={e => setNewItemTemp({...newItemTemp, link: e.target.value})} placeholder="Escreva aqui" type="text" className="styled"/>
                    </div>
                ) : null
            }
            <div className="flex" style={{alignItems: 'center', marginTop: '10px'}}>
                
                {
                    newItemTemp.status ? 
                    (
                        <>
                            <button className="btn-styled default" style={{marginTop: 0, marginRight: '10px'}} type="button" onClick={() => {
                                if(newItemTemp.status)
                                {
                                    if(newItemTemp.link)
                                    {
                                        setList([...list, { link: newItemTemp.link}])
                                        setNewItemTemp({...newItemTemp, link: ''})
                                    }else{
                                        Warning('Preencha o assunto.')
                                    }
                                }else{
                                    setNewItemTemp({...newItemTemp, status: true})
                                }
                            }}>
                                Adicionar
                            </button>
                            <p className="span-link" onClick={() => setNewItemTemp({status: false, link: ''})}>Fechar</p>
                        </>
                    ) : null
                }
            </div>
        </div>
    )
}
