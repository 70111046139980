import React, { useEffect, useState } from 'react'
import { FaCommentDots, FaEdit } from 'react-icons/fa';
import Select from 'react-select';
import Success from '../../../../../functions/Modals/Success';
import { ConsultMyContacts } from '../../../../../services/Contacts/ConsultMyContacts';
import { ContactItem } from '../../../../../services/Contacts/ContactModule';
import { CloseSubject } from '../../../../../services/Protocol/CloseSubject';
import { CreateUpdateSubject } from '../../../../../services/Protocol/ProtocolInterfaces';
import { UpdateSubject as US } from '../../../../../services/Protocol/UpdateSubject';
import { ViewSubject } from '../../../../../services/Protocol/ViewSubject';

interface IUpdateSubject {
    id: string
}

export const UpdateSubject: React.FC<IUpdateSubject> = (props) => 
{
    const [data, setData] = useState<CreateUpdateSubject>({
        title: '',
        date_estimate_finish: '',
    })

    const [dataTime, setDataTime] = useState({
        date: '',
        time: ''
    })

    const [listContacts, setListContacts] = useState<Array<ContactItem>>([])
    const [contactsSelected, setcontactsSelected] = useState<Array<any>>([])

    const [feedback, setFeedback] = useState('')

    useEffect(() => {
        ConsultMyContacts(s => setListContacts(s.contacts), () => {})

        ViewSubject(props.id, s => {
            if(s.subject)
            {
                setData({date_estimate_finish: '', title: s.subject.title})

                let date = s.subject.date_estimate_finish.split(' ');

                setDataTime({
                    date: date[0],
                    time: date[1]
                })

                if(s.subject.contacts)
                {
                    setcontactsSelected([
                        ...s.subject.contacts.map(e => {
                            return {
                                label: `${e.name} (${e.email})`,
                                value: e.contact_id
                            }
                        })
                    ])
                }
            }
        }, () => {})
    }, [props.id])

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        US(
            props.id,
            {
                date_estimate_finish: `${dataTime.date} ${dataTime.time}`,
                title: data.title,
                contacts: contactsSelected.map(e => {
                    return {
                        id: e.value
                    }
                })
            }, 
            () => Success('Atualizado com sucesso', () => window.location.reload()), 
            () => Error('Houve um erro ao tentar atualizar')
        )
    }

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                <div className="input-box-task">
                    <label htmlFor="!#">Nome</label>
                    <input className="styled" type="text" onChange={e => setData({...data, title: e.target.value})} value={data.title} placeholder="Descreva a tarefa." required autoComplete="off" autoFocus={true}/>
                </div>
                <h3 className="title-divider">Data de encerrar o assunto?</h3>
                <div className="input-box">
                    <p>Data</p>
                    <input type="date" value={dataTime.date}  onChange={e => setDataTime({...dataTime, date: e.target.value})} max="1979-12-31 " className="styled"/>
                    <p>Hora</p>
                    <input type="time" value={dataTime.time}  onChange={e => setDataTime({...dataTime, time: e.target.value})} className="styled"/>
                </div>
                <h3 className="title-divider">Configure responsáveis pela tarefa</h3>
                <div className="input-box">
                    <p>Escolha abaixo</p>
                    <Select value={contactsSelected} onChange={(e: any) => setcontactsSelected(e)} placeholder="Selecione..." isMulti options={listContacts.map(e => { return {
                        value: e.id,
                        label: `${e.name} (${e.email})`
                    }})}/>
                </div>
                <h3 className="title-divider">Concluir assunto?</h3>
                <div className="input-box">
                    <p>Feedback</p>
                    <textarea style={{width: '100%', padding: '10px'}} value={feedback} onChange={e => setFeedback(e.target.value)} className="styled"></textarea>
                </div>
                <button type="submit"><FaEdit/> Atualizar</button>
                <button type="button" onClick={() => {
                    CloseSubject(props.id, {feedback: feedback} , () => Success('Concluido com sucesso!', () => window.location.reload()), () => Error('Houve um erro ao tentar concluir este assunto'))
                }} style={{marginRight: '20px', marginLeft: '20px'}}><FaCommentDots/> Concluir assunto</button>
                {/* <button onClick={() => {
                    DeleteSubject(props.id, () => Success('Apagado com sucesso!', () => window.location.reload()), () => Error('Houve um erro ao tentar apagar este assunto'))
                }} type="button"><FaTrash/> Apagar assunto</button> */}
            </form>
        </>
    )
}
