import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { LoadingContent } from '../../../../components/LoadingContent'
import Success from '../../../../functions/Modals/Success'
import { TopicAPI, TopicFormProps } from '../../../../services/Financeiro/Topic'

export interface FormPlanoDeContasProps {
    id?: string,
    group_id: string
    onSuccess?: (() => void)
}

export const FormPlanoDeContas: React.FC<FormPlanoDeContasProps> = (props) => 
{
    const [form, setForm] = useState<TopicFormProps>({
        topic: '',
        type: '',
        group_id: ''
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if(props.id)
        {
            TopicAPI.getById(props.id, data => {
                if(data.name && data.type)
                {
                    setForm({topic: data.name, type: data.type, group_id: props.group_id})
                    setLoading(false)
                }
            })
        }else{
            setForm({
                topic: '',
                type: '',
                group_id: props.group_id
            })
        }

    }, [props.id, props.group_id])
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        if(props.id)
        {
            TopicAPI.update(props.id, form, s => Success('Salvo com sucesso!', async () => {
                window.location.reload()
            }))
        }else{
            TopicAPI.create(form, s => Success('Salvo com sucesso!', async () => {
                window.location.reload()
            }))
        }
    }

    return (
        <>
            {
                !loading || !props.id ?
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p>Nome</p>
                            <input type="text" onChange={e => setForm({...form, topic: e.target.value})} value={form.topic} />
                        </div>
                        <div className="box-input">
                            <p>Tipo de cálculo</p>
                            <select onChange={e => {
                                setForm({...form, type: e.target.value})
                            }} value={form.type}>
                                <option>Selecione uma opção</option>
                                <option value="1">Entrada</option>
                                <option value="2">Saída</option>
                            </select>
                        </div>
                        <button type="submit"><MdAdd/> Salvar</button>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}