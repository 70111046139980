import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/departments'

export interface FormClientDepartment {
    name?: string
    sigla?: string
    id?: string
}

export interface FormClientDepartmentImportProps {
    label?: string
    sigla?: string
    id?: string
}

class ClientDepartments 
{
    async create(
        data: FormClientDepartment,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        data: FormClientDepartment,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((success: Array<FormClientDepartment>) => void),
    )
    {
        return await Request.get(`${route}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }

    async getAllNoSync(
        onSuccess: ((success: Array<FormClientDepartment>) => void),
    )
    {
        return await Request.get(`${route}/sync`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: FormClientDepartment) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }
}

export const ClientDepartmentsAPI = new ClientDepartments()