import React from 'react'
import { Content } from '../../../components/Content'
import { Navbar } from '../../../components/Navbar'

export interface AnexosProps {}

export const Anexos: React.FC<AnexosProps> = (props) => 
{
    return (
        <>
            <Navbar/>
            <Content title={
                <div className="title-button">
                    <div className="title">
                        <h1 className="app-content-h1-destaque">Arquivos anexados</h1>
                    </div>
                    <div className="options"></div>
                </div>
            }>
                <div className="content-box">
                    
                </div>
            </Content>  
        </>
    )
}