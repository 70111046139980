import React from 'react';
import ReactDOM from 'react-dom';
import { Layout } from './layout';

ReactDOM.render(
  <React.StrictMode>
      <Layout />
  </React.StrictMode>,
  document.getElementById('root')
);

