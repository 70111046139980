import React from 'react'
import { MdArrowBack } from 'react-icons/md'
import { useHistory } from 'react-router-dom'

export interface ButtonBackHistoryProps {
    onClick: (() => void),
    default_back?: boolean
}

export const ButtonBackHistory: React.FC<ButtonBackHistoryProps> = (props) => 
{
    const history = useHistory()

    return (
        <>
            <button className="history-back" onClick={() => {
                if(props.default_back)
                {
                    history.goBack()
                }else{
                    props.onClick()
                }
            }}><MdArrowBack /></button>
        </>
    )
}