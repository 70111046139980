import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Chart from 'react-apexcharts'
import { ResponsiveRadar } from '@nivo/radar'

export interface index {}

export const NewDiagnosticResult: React.FC = () => 
{
    const location = useLocation<any>()
    const history = useHistory()

    const [state] = useState<any>({
        
        series: location.state.points.map((e: {label: string, value: string}) => {
            return {
                name: e.label,
                data: [e.value]
            }
        }),
        // colors: location.state.map((e: {label: string, value: string}) => RandomColor()),
        options: {
            chart: {
                height: 350,
                type: 'bar',
                stackType: '100%'
            },
            plotOptions: {
                bar: {
                    dataLabels: {
                        position: 'top', // top, center, bottom,
                    },
                },
                column: {
                    colorByPoint: true
                }
            },
            dataLabels: {
                enabled: true,
                formatter: (val: string) => {
                    return val + "%";
                },
                offsetY: -20,
                style: {
                    fontSize: '12px',
                    colors: ["#304758"]
                }
            },
            
            xaxis: {
                categories: ['Diagnóstico realizado'],
                position: 'top',
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                crosshairs: {
                    fill: {
                        type: 'gradient',
                        gradient: {
                            colorFrom: '#D8E3F0',
                            colorTo: '#BED1E6',
                            stops: [0, 100],
                            opacityFrom: 0.4,
                            opacityTo: 0.5,
                        }
                    }
                },
                tooltip: {
                    enabled: true,
                }
            },
            yaxis: {
                max: 100,
                axisBorder: {
                    show: false
                },
                axisTicks: {
                    show: false
                },
                labels: {
                    show: false,
                    formatter: (val: string) => {
                        return val;
                    }
                }
            },
        }
    })  

    const questions_response : any = {
        100: 'Sempre',
        75: 'Com frequência',
        50: 'De vez em quando',
        25: 'Raramente',
        0: 'Nunca',
        remove: 'Não se aplica'
    };

    useEffect(() => {
        
    }, [])

    return (
        <div className="my-diagnostic-result">
            <div className="result">
                <div className="congratulation" style={{textAlign: 'center'}}>
                    <h2>Diagnóstico realizado!</h2>
                    <br/>
                    <p>Como parâmetro, seguem os valores de referência de mercado para você já compreender os percentuais que almejamos que sua empresa alcance ao final da sua jornada no MODUM:</p>
                    <br/>
                    <p>Está no caminho certo: entre 70% e 80%</p>
                    <p>O ideal desejado: entre 80% e 90%</p>
                    <p>Sonho de vida de todos os empresários: entre 90% e 100%</p>
                    <br />
                    <h4>Veja o resultado</h4>
                </div>
                <div id="chart">
                    <Chart options={state.options} series={state.series} type="bar" height={350} />
                </div>
                <div className="row" style={{marginBottom: '40px'}}>
                    <div style={{height: '260px', width: '250px', textAlign: 'center'}} className="col-4">
                        <h4>Realizado</h4>
                        <ResponsiveRadar
                            data={[
                                {
                                    label: 'Rotina',
                                    total: location.state.points.filter((e: any) => e.label === "Rotina")[0].value
                                },
                                {
                                    label: 'Pessoas',
                                    total: location.state.points.filter((e: any) => e.label === "Pessoas")[0].value
                                },
                                {
                                    label: 'Processos',
                                    total: location.state.points.filter((e: any) => e.label === "Processos")[0].value
                                },
                                {
                                    label: 'Resultados',
                                    total: location.state.points.filter((e: any) => e.label === "Resultados")[0].value
                                }
                            ]}
                            keys={["total"]}
                            indexBy="label"
                            maxValue="auto"
                            margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
                            curve="linearClosed"
                            borderWidth={2}
                            borderColor={{ from: 'color' }}
                            gridLevels={5}
                            gridShape="linear"
                            gridLabelOffset={36}
                            enableDots={true}
                            dotSize={10}
                            dotColor={{ theme: 'background' }}
                            dotBorderWidth={2}
                            dotBorderColor={{ from: 'color' }}
                            enableDotLabel={true}
                            dotLabel="value"
                            dotLabelYOffset={-12}
                            colors={{ scheme: 'nivo' }}
                            fillOpacity={0.25}
                            blendMode="multiply"
                            animate={true}
                            isInteractive={true}
                            legends={[
                                {
                                    anchor: 'top-left',
                                    direction: 'column',
                                    translateX: -50,
                                    translateY: -40,
                                    itemWidth: 80,
                                    itemHeight: 20,
                                    itemTextColor: '#999',
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                    <div style={{height: '250px', width: '250px', textAlign: 'center'}} className="col-4">
                        <h4>Desejado</h4>
                        <ResponsiveRadar
                            data={[
                                {
                                    label: 'Rotina',
                                    total: 80,
                                },
                                {
                                    label: 'Pessoas',
                                    total: 90
                                },
                                {
                                    label: 'Processos',
                                    total: 80
                                },
                                {
                                    label: 'Resultados',
                                    total: 90
                                },
                            ]}
                            keys={[ 'total' ]}
                            indexBy="label"
                            maxValue="auto"
                            margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
                            curve="linearClosed"
                            borderWidth={2}
                            borderColor={{ from: 'color' }}
                            gridLevels={5}
                            gridShape="linear"
                            gridLabelOffset={36}
                            enableDots={true}
                            dotSize={10}
                            dotColor={{ theme: 'background' }}
                            dotBorderWidth={2}
                            dotBorderColor={{ from: 'color' }}
                            enableDotLabel={true}
                            dotLabel="value"
                            dotLabelYOffset={-12}
                            colors={{ scheme: 'nivo' }}
                            fillOpacity={0.25}
                            blendMode="multiply"
                            animate={true}
                            isInteractive={true}
                            legends={[
                                {
                                    anchor: 'top-left',
                                    direction: 'column',
                                    translateX: -50,
                                    translateY: -40,
                                    itemWidth: 80,
                                    itemHeight: 20,
                                    itemTextColor: '#999',
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                    <div style={{height: '250px', width: '250px', textAlign: 'center'}} className="col-4">
                        <h4>Resultado</h4>
                        <ResponsiveRadar
                            data={[
                                {
                                    label: 'Rotina',
                                    desejado: 80,
                                    realizado: location.state.points.filter((e: any) => e.label === "Rotina")[0].value
                                },
                                {
                                    label: 'Pessoas',
                                    desejado: 90,
                                    realizado: location.state.points.filter((e: any) => e.label === "Pessoas")[0].value
                                },
                                {
                                    label: 'Processos',
                                    desejado: 80,
                                    realizado: location.state.points.filter((e: any) => e.label === "Processos")[0].value
                                },
                                {
                                    label: 'Resultados',
                                    desejado: 90,
                                    realizado: location.state.points.filter((e: any) => e.label === "Resultados")[0].value
                                }
                            ]}
                            keys={[ 'desejado', 'realizado' ]}
                            indexBy="label"
                            maxValue="auto"
                            margin={{ top: 70, right: 80, bottom: 40, left: 80 }}
                            curve="linearClosed"
                            borderWidth={2}
                            borderColor={{ from: 'color' }}
                            gridLevels={5}
                            gridShape="linear"
                            gridLabelOffset={36}
                            enableDots={true}
                            dotSize={10}
                            dotColor={{ theme: 'background' }}
                            dotBorderWidth={2}
                            dotBorderColor={{ from: 'color' }}
                            enableDotLabel={true}
                            dotLabel="value"
                            dotLabelYOffset={-12}
                            colors={{ scheme: 'nivo' }}
                            fillOpacity={0.25}
                            blendMode="multiply"
                            animate={true}
                            isInteractive={true}
                            legends={[
                                {
                                    anchor: 'top-left',
                                    direction: 'column',
                                    translateX: -50,
                                    translateY: -40,
                                    itemWidth: 80,
                                    itemHeight: 20,
                                    itemTextColor: '#999',
                                    symbolSize: 12,
                                    symbolShape: 'circle',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ]
                                }
                            ]}
                        />
                    </div>
                </div>
                <div className="congratulation" style={{textAlign: 'center'}}>
                    <button className="btn-link-solid" style={{display: `inline-block`, marginLeft: 0, marginTop: '20px', padding: '10px', textTransform: 'uppercase'}} onClick={() => history.push('/diagnostics')}>Voltar ao sistema</button>
                </div>
            </div>
            <div className="my-diagnostic-result">
                <div className="result">
                    <div className="congratulation" style={{textAlign: 'center'}}>
                        <h2>Veja suas respostas</h2>
                    </div>
                    <br/>
                    {
                        location.state.questions.map(((item: any) => {
                            return (
                                <div className='diagnostic-md-question'>
                                    <h3>{item.label}</h3>
                                    {
                                        item.questions.map((item: any) => {
                                            return (
                                                <div className='diagnostic-md-response'>
                                                    <p className='dignostic-md-response-label'><b>Pergunta</b>: {item.question}</p>
                                                    <p className='dignostic-md-response-response'><b>Resposta</b>: {questions_response[item.response_value]}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            )
                        }))
                    }
                </div>
            </div>
        </div>
    )
}
