import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/users'

export interface UserForm {
    id?: string
    name: string,
    email: string,
    phone: string,
    permissions: {
        label: string
        value: string
    }[]
    is_disabled?: number
}

class Users
{
    async create(
        data: UserForm,
        onSuccess: ((success: UserForm) => void),
        onError?: (() => void)
    )
    {
        return await Request.post(`${route}`, data).then(response => onSuccess(response.data.response)).catch(err => {
            DefaultErrorResponse(err)

            if(onError)
            {
                onError()
            }
        });
    }

    async update(
        data: UserForm,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async disabledClient(
        data: {
            client_id: string
        },
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/disabled`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: {
            user: UserForm,
            permissions: {
                id: string
                client_id: string
                permission: string
            }[],
        }) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async resetPasswordById(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}/${id}/reset`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((proccesses: Array<UserForm>) => void),
    )
    {
        return await Request.get(`${route}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }
}

export const UsersController = new Users()