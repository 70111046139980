import React, { useEffect, useState } from 'react'
import Success from '../../../../../functions/Modals/Success';
import { CreateUpdateSubject } from '../../../../../services/Protocol/ProtocolInterfaces';
import { SaveSubject } from '../../../../../services/Protocol/SaveSubject';
import { ViewSubject } from '../../../../../services/Protocol/ViewSubject';

interface IDuplicateSubject {
    id: string
}

export const DuplicateSubject: React.FC<IDuplicateSubject> = (props) => 
{
    const [data, setData] = useState<CreateUpdateSubject>({
        title: '',
        date_estimate_finish: '',
    })

    const [dataTime, setDataTime] = useState({
        date: '',
        time: ''
    })

    const [feedback, setFeedback] = useState('')
    const [dateFinish, setDateFinish] = useState('')

    useEffect(() => {
        ViewSubject(props.id, s => {
            if(s.subject)
            {
                setData({date_estimate_finish: '', title: s.subject.title})

                let date = s.subject.date_estimate_finish.split(' ');

                setFeedback(s.subject.feedback)
                setDateFinish(s.subject.dateFinishFormatted)

                setDataTime({
                    date: date[0],
                    time: date[1]
                })
            }
        }, () => {})
    }, [props.id])

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        SaveSubject(
            {
                date_estimate_finish: `${dataTime.date} ${dataTime.time}`,
                title: data.title,
            }, 
            () => Success('Assunto duplicado com sucesso', () => window.location.reload()), 
            () => Error('Houve um erro ao tentar duplicar este assunto')
        )
    }

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                <h3 className="title-divider">Assunto</h3>
                <p className="p-title">Nome</p>
                <p className="p-data">{data.title}</p>
                <p className="p-title">Concluido em</p>
                <p className="p-data">{dateFinish}</p>
                <p className="p-title">Feedback</p>
                <p className="p-data">{feedback}</p>
                <button type="submit">Duplicar</button>
            </form>
        </>
    )
}
