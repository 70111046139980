import React, { useEffect, useState, useRef } from 'react'
import { MdAdd } from 'react-icons/md'
import { LoadingContent } from '../../../../components/LoadingContent'
import Success from '../../../../functions/Modals/Success'
import { CargosAPI, DescriptionChangeDepartmentFormProps } from '../../../../services/Cargos/Cargos'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../../services/ClientDepartments/ClientDepartments'
import Select, { ValueType } from 'react-select'
import Warning from '../../../../functions/Modals/Warning'

export interface FormDescriptionChangeDepartmentProps {
    id?: string,
    onSuccess?: (() => void)
}

export const FormDescriptionChangeDepartment: React.FC<FormDescriptionChangeDepartmentProps> = (props) => 
{
    const [form, setForm] = useState<DescriptionChangeDepartmentFormProps>({
        department_id: ''
    })

    const [list, setList] = useState<Array<FormClientDepartment>>([])
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        if(props.id)
        {
            if(department?.value)
            {
                CargosAPI.updateDescriptionDepartment(props.id,{
                    department_id:  department.value
                }, () => Success('Salvo com sucesso', () => window.location.reload()))
            }else{
                Warning('Preencha o departamento.')
            }
        }
    }

    const loading = useRef(true)
    
    const [department, setDepartment] = useState<ValueType<{
        value?: string,
        label?: string
    }, false>>({})

    useEffect(() => {
        loading.current = false
    })

    useEffect(() => {
        ClientDepartmentsAPI.getAll(setList)
    }, [props.id])

    return (
        <>
            {
                !loading.current || !props.id ? 
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p>Novo departamento</p>
                            <Select classNamePrefix='fast_select' placeholder='Escolha o novo departamento' onChange={setDepartment} options={list.map(item => {
                                return {
                                    value: item.id,
                                    label: item.name
                                }
                            })} />
                        </div>
                        <button type="submit"><MdAdd/> Alterar</button>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}