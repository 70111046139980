import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { ButtonBackHistory } from '../../components/ButtonBackHistory'
import { Content } from '../../components/Content'
import { LoadingContent } from '../../components/LoadingContent'
import { Navbar } from '../../components/Navbar'
import TheTable from '../../components/TheTable'
import Success from '../../functions/Modals/Success'
import { FeedbackMydayItem } from '../../services/MyDay/FeedbackModule'
import { CheckMyFeedbackItem } from '../../services/MyDay/ViewFeedback'
import { ViewFeedbacks } from '../../services/MyDay/ViewFeedbacks'

export const MyDayFeedbacks = () => 
{
    const [feedbacks, setFeedbacks] = useState<Array<FeedbackMydayItem>>([])
    const [feedbacksFiltred, setFeedbacksFiltred] = useState<Array<FeedbackMydayItem>>([])
    const [filterSuccessOpened, setFilterSuccessOpened] = useState(false)
    const [filterSuccessClosed, setFilterSuccessClosed] = useState(false)
    const history = useHistory()
    const loading = useRef(true)

    useEffect(() => {
        ViewFeedbacks(e => {
            if(e.feedbacks)
            {
                setFeedbacks(e.feedbacks)
                setFeedbacksFiltred(e.feedbacks)
            }
        }, e => {})     
        
        loading.current = false
    }, [])

    return (
        <div>
            <Navbar/>
            {
                !loading.current ?
                (
                    <Content title={
                        <div className="title-button" style={{marginBottom: '20px'}}>
                            <div className="title">
                                <ButtonBackHistory onClick={() => history.push('/tasks')}/>
                                <h2 onClick={() => {}}>Histórico de reunião diária</h2>
                            </div>
                        </div>
                    }>
                        <div className="my-tasks-backlog">
                            <div className="title-button" style={{marginBottom: '20px'}}>
                                <div className="title">
                                    <h2 onClick={() => {}}>Histórico</h2>
                                </div>
                                <div className="options">
                                    <button onClick={() => {
                                        if(!filterSuccessClosed)
                                        {
                                            setFilterSuccessClosed(true)
                                            setFeedbacksFiltred([...feedbacks.filter(item => item.status === "2")])
                                        }else{
                                            setFilterSuccessClosed(false)
                                            setFeedbacksFiltred([...feedbacks])
                                        }
                                    }}>{!filterSuccessClosed  ? `Filtrar por concluídos` : 'Mostrar todos'}</button>
                                    <button onClick={() => {
                                        if(!filterSuccessOpened)
                                        {
                                            setFilterSuccessOpened(true)
                                            setFeedbacksFiltred([...feedbacks.filter(item => item.status === "1")])
                                        }else{
                                            setFilterSuccessOpened(false)
                                            setFeedbacksFiltred([...feedbacks])
                                        }
                                    }}>{!filterSuccessOpened  ? `Filtrar por abertos` : 'Mostrar todos'}</button>
                                    <select className="select-filter" onChange={select => {
                                           
                                        if(select.target.value !== "")
                                        {
                                            setFeedbacksFiltred([...feedbacks.filter(item => {
                                                return item.question_who === select.target.value
                                            })])
                                        }else{
                                            setFeedbacksFiltred([...feedbacks])
                                        }
                                    }}>
                                        <option value="">Filtrar por quem?</option>
                                        {
                                            feedbacks.map((e, i) => {
                                                return <option value={e.question_who}>{e.question_who}</option>
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                            {
                                feedbacks.length > 0 ?
                                (
                                    <>
                                        <TheTable classBodyItem="item-reuniao-diaria" classHeaderItem="header-reuniao-diaria" withSearch withPagination columns={[
                                            {
                                                name: 'O quê',
                                                label: 'question_what',
                                            },
                                            {
                                                name: 'Quando',
                                                label: 'question_when',
                                            },
                                            {
                                                name: 'Quem',
                                                label: 'question_who',
                                            },
                                            {
                                                name: 'Data',
                                                label: 'dateFormatted',
                                            },
                                            {
                                                name: 'Status',
                                                label: 'status',
                                                render: (row) => <div style={row.status === "2" ? {color: "#00917c", fontWeight: 600} : {color: "#ce1212", fontWeight: 600}}>{row.status === "2" ? 'Concluído' : 'Em aberto'}</div>
                                            },
                                            {
                                                name: 'Concluiu?',
                                                label: 'status',
                                                render: (row) => <div><span className="span-link" onClick={() => CheckMyFeedbackItem(row.id, () => Success('Salvo!', () => window.location.reload()))}>Sim, clique aqui!</span></div>
                                            }
                                        ]} data={feedbacksFiltred} />
                                    </>
                                ) : <h4>Você ainda não possui contatos</h4>
                            }
                        </div>
                    </Content>
                ) : <LoadingContent />
            }
        </div>
    )
}
