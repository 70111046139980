import React, { useEffect, useState } from 'react'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { Navbar } from '../../../components/Navbar'
import { formatCurrencyBRL, formatDate } from '../../../functions/dataFormat'
import { TopicAPI, TopicGraphicProps } from '../../../services/Financeiro/Topic'
import { ResponsivePie } from '@nivo/pie'
import { ConfigModal, Modal } from '../../../components/Modal'
import { FormGraphic } from './components/FormGraphic'

export interface GraphicsProps {}

export const Graphics: React.FC<GraphicsProps> = (props) => 
{
    const [graphics, setGraphics] = useState<TopicGraphicProps[]>([])
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})

    useEffect(() => {
        let year = formatDate(new Date().toISOString(), 'yyyy');

        TopicAPI.getGraphics(`${year}-01-01`, `${year}-12-31`, setGraphics)
    }, [])

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/" />
                            <h1 className="app-content-h1-destaque">Financeiro: Gráficos</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Entradas / Saída</h2>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                let year = formatDate(new Date().toISOString(), 'yyyy');

                                TopicAPI.getGraphics(`${year}-01-01`, `${year}-12-31`, setGraphics)
                            }} className="bkg-theme clr-white">Limpar o filtro</button>
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    content: <FormGraphic onSuccess={(date) => {
                                        setModalConfig({
                                            show: false
                                        })

                                        TopicAPI.getGraphics(`${date.date_start}`, `${date.date_finish}`, setGraphics)
                                    }} />,
                                    onClose: () => setModalConfig({
                                        show: false
                                    }),
                                    title: <h2>Configurar parametros</h2>
                                })
                            }} className="bkg-theme clr-white">Buscar</button>
                        </div>
                    </div>
                    <br/>
                    {
                        graphics.length > 0 ? (
                            <>
                                <div className="row">
                                    <div className="col-6" style={{height: '500px'}}>
                                        <ResponsivePie
                                            data={graphics.filter(item => item.sum_type === "1")}
                                            id="scheme_account_name"
                                            value="total"
                                            margin={{ top: 100, right: 100, bottom: 100, left: 100 }}
                                            innerRadius={0.4}
                                            padAngle={2}
                                            colors={{scheme: 'category10'}}
                                            cornerRadius={2}
                                            activeOuterRadiusOffset={8}
                                            borderWidth={1}
                                            borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                                            arcLinkLabelsSkipAngle={10}
                                            arcLinkLabelsTextColor="#333333"
                                            arcLinkLabelsThickness={2}
                                            arcLinkLabelsColor={{ from: 'color' }}
                                            arcLabelsSkipAngle={10}
                                            arcLabel={data => `${formatCurrencyBRL(data.value.toString())}`}
                                            arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
                                            legends={[
                                                {
                                                    anchor: 'bottom',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: 0,
                                                    translateY: 56,
                                                    itemsSpacing: 0,
                                                    itemWidth: 100,
                                                    itemHeight: 18,
                                                    itemTextColor: '#999',
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 1,
                                                    symbolSize: 18,
                                                    symbolShape: 'circle',
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemTextColor: '#000'
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                        />
                                    </div>
                                    <div className="col-6" style={{height: '500px'}}>
                                    <ResponsivePie
                                            data={graphics.filter(item => item.sum_type === "2")}
                                            id="scheme_account_name"
                                            value="total"
                                            margin={{ top: 100, right: 100, bottom: 100, left: 100 }}
                                            innerRadius={0.4}
                                            padAngle={0.7}
                                            cornerRadius={2}
                                            activeOuterRadiusOffset={8}
                                            borderWidth={1}
                                            borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
                                            arcLinkLabelsSkipAngle={10}
                                            arcLinkLabelsTextColor="#333333"
                                            arcLinkLabelsThickness={2}
                                            arcLinkLabelsColor={{ from: 'color' }}
                                            arcLabelsSkipAngle={10}
                                            arcLabel={data => `${formatCurrencyBRL(data.value.toString())}`}
                                            arcLabelsTextColor={{ from: 'color', modifiers: [ [ 'darker', 2 ] ] }}
                                            legends={[
                                                {
                                                    anchor: 'bottom',
                                                    direction: 'column',
                                                    justify: false,
                                                    translateX: 0,
                                                    translateY: 56,
                                                    itemsSpacing: 0,
                                                    itemWidth: 100,
                                                    itemHeight: 18,
                                                    itemTextColor: '#999',
                                                    itemDirection: 'left-to-right',
                                                    itemOpacity: 1,
                                                    symbolSize: 18,
                                                    symbolShape: 'circle',
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemTextColor: '#000'
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                        />
                                    </div>
                                </div>
                            </>
                        ) : <h3>Sem informações encontradas</h3>
                    }
                </div>
            </Content>
        </>
    )
}