import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import ServerRequest from "../../functions/ServerRequest";

const route = '/users/proccess-feedback-custom/templates'

export interface PDCTemplate_FormProps {
    name: string
}

export interface PDCTemplate_ItemProps {
    name: string
    client_id: string
    company_id: string
    date_created: string
    id: string
}

class PDCTemplate
{
    async create(
        data: PDCTemplate_FormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.post(`${route}`, data).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        data: PDCTemplate_FormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.put(`${route}/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: PDCTemplate_ItemProps) => void),
    )
    {
        return await ServerRequest.get(`${route}/${id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((proccesses: Array<PDCTemplate_ItemProps>) => void),
    )
    {
        return await ServerRequest.get(`${route}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }
}

export const PDCTemplate_API = new PDCTemplate()