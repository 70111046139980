import React, { useEffect, useRef, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { EditorHTML } from '../../../../components/EditorHTML'
import { LoadingContent } from '../../../../components/LoadingContent'
import Success from '../../../../functions/Modals/Success'
import { ManualHistoryAPI, ManualHistoryFormProps } from '../../../../services/ManualColaborador/ManualHistory'

export interface FormCompanyHistoryProps {
    onCreate?: (() => void),
    onUpdate?: (() => void)
}

export const FormCompanyHistory: React.FC<FormCompanyHistoryProps> = (props) => 
{
    const [form, setForm] = useState<ManualHistoryFormProps>({
        history: ''
    })

    const loading = useRef(true)

    useEffect(() => {
        ManualHistoryAPI.consult(setForm)

        let auto_save = setInterval(() => {
            ManualHistoryAPI.save(form, () => {})
        }, 30000)

        loading.current = false

        return () => {
            clearInterval(auto_save)
        }
    }, [])

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        ManualHistoryAPI.save(form, () => Success('Salvo com sucesso.', () => {
            if(props.onCreate)
            {
                props.onCreate()
            }
        }))
    }

    return (
        <>
            {
                !loading.current ?
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p>Descreva</p>
                            <EditorHTML defaultToolbar={true} value={form.history} onChange={data => setForm({...form, history: data})} />
                        </div>
                        <div className="flex between">
                            <button type="submit" style={{marginRight: '10px'}}><MdAdd/> Salvar</button>
                        </div>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}