import React, { useEffect, useRef, useState } from 'react'
import { Content } from '../../components/Content'
import { ConfigModal, Modal } from '../../components/Modal'
import { Navbar } from '../../components/Navbar'
import { formatDate } from '../../functions/dataFormat'
import { ContractItemProps, ContractsAPI } from '../../services/Contracts/Contracts'
import { FormContract } from './components/FormContract'

export const Contracts = () => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [contracts, setContracts] = useState<Array<ContractItemProps>>([])
    const loading = useRef(true)

    useEffect(() => {
        ContractsAPI.getAll(setContracts)
        loading.current = false
    }, [])

    return (
        <div>
            <Modal {...modalConfig}/>
            <Navbar/>
            <Content loading={loading.current}>
                <div className="my-tasks-backlog">
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Contratos</h2>
                        </div>
                    </div>
                    <h3 className="title-divider">Lista de Contratos</h3>
                    {
                        contracts.length > 0 ?
                        (
                            <>
                                {
                                    contracts.map(item => {
                                        return (
                                            <>
                                                <div className="contract-item" onClick={() => {
                                                    setModalConfig({
                                                        show: true,
                                                        title: (
                                                            <>
                                                                <h2>Contrato</h2>
                                                            </>
                                                        ),
                                                        content: <FormContract onSuccess={() => {
                                                            ContractsAPI.getAll(setContracts)
                                                            setModalConfig({show: false})
                                                        }} contract={item} id={item.id} />,
                                                        onClose: () => setModalConfig({show: false})
                                                    })
                                                }}>
                                                    <p>Contrato: #{item.id}</p>
                                                    <p>Status: {item.status === "open" ? "Aberto" : "Aceito"}</p>
                                                    <p>Emitido em: {formatDate(item.date_create, 'dd/MM/yyyy')}</p>    
                                                    {item.date_accept ? <p>Aceito em: {formatDate(item.date_create, 'dd/MM/yyyy')}</p> : null}
                                                </div>
                                            </>
                                        )
                                    })
                                }
                            </>
                        ) : <h4>Você ainda não possui contratos</h4>
                    }
                </div>
            </Content>
        </div>
    )
}
