import { AxiosResponse } from "axios"
import Request from "../../functions/Request"

export interface ConsultarMinhaContaResponse {
    account: AccountProps
    message: string
}

export interface AccountProps {
    id: string
    company_name: string
    fantasy: string
    name: string
    cpf_cnpj: string
    email: string
    phone: string
    plan: string
    photo_path: string
    photo_title: string
    is_root?: 'YES' | 'NO',
    companies?: {
        company_name: string;
        fantasy: string;
        name: string
        id: string;
    }[]
}

export const ConsultarMinhaConta = async (
    onSuccess: ((e: ConsultarMinhaContaResponse) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.get(`/account`).then(s => onSuccess(s.data)).catch(onError);
