import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { MdAdd, MdDelete } from 'react-icons/md'
import { LoadingContent } from '../../../../components/LoadingContent'
import { ConfirmDelete } from '../../../../functions/Modals/Confirm'
import Success from '../../../../functions/Modals/Success'
import { EntriesAPI, EntryFormProps } from '../../../../services/Financeiro/Entries'
import { TopicAPI, TopicProps } from '../../../../services/Financeiro/Topic'
import { TopicItemProps, TopicItemsAPI } from '../../../../services/Financeiro/TopicItems'
import { ProjectAPI, ProjectProps } from '../../../../services/V2/Project'

export interface FormEntryProps {
    id?: string,
    onSuccess?: (() => void)
}

export const payments_method = [
    {
        name: 'Boleto bancário',
        value: '1'
    },
    {
        name: 'PIX',
        value: '2'
    },
    {
        name: 'Cartão de crédito à vista',
        value: '3'
    },
    {
        name: 'Cartão de crédito parcelado',
        value: '4'
    },
    {
        name: 'Cartão de débito',
        value: '5'
    },
    {
        name: 'Cheque',
        value: '6'
    },
    {
        name: 'Devolução de venda',
        value: '7'
    },
    {
        name: 'Dinheiro',
        value: '8'
    },
    {
        name: 'Duplicata',
        value: '9'
    },
    {
        name: 'Transferência bancária',
        value: '10'
    },
]

export const months = [
    {
        name: 'Janeiro',
        value: '1'
    },
    {
        name: 'Fevereiro',
        value: '2'
    },
    {
        name: 'Março',
        value: '3'
    },
    {
        name: 'Abril',
        value: '4'
    },
    {
        name: 'Maio',
        value: '5'
    },
    {
        name: 'Junho',
        value: '6'
    },
    {
        name: 'Julho',
        value: '7'
    },
    {
        name: 'Agosto',
        value: '8'
    },
    {
        name: 'Setembro',
        value: '9'
    },
    {
        name: 'Outubro',
        value: '10'
    },
    {
        name: 'Novembro',
        value: '11'
    },
    {
        name: 'Dezembro',
        value: '12'
    },
]

export const FormEntry: React.FC<FormEntryProps> = (props) => 
{
    const [form, setForm] = useState<EntryFormProps>({
        classification_id: "",
        scheme_account_id: "",
        description: "",
        payment_method_id: "",
        value: "",
        date: "",
        month_competence: "",
        date_finish: "",
        project_id: ""
    })

    const [topics, setTopics] = useState<Array<TopicProps>>([])
    const [subtopics, setSubtopics] = useState<Array<TopicItemProps>>([])
    const [loading, setLoading] = useState(true)
    const [projects, setProjects] = useState<ProjectProps[]>([])

    useEffect(() => {
        if(props.id)
        {
            EntriesAPI.getById(props.id, data => {
                if(
                    data.classification_id &&
                    data.scheme_account_id &&
                    data.description &&
                    data.payment_method_id &&
                    data.value &&
                    data.month_competence && 
                    data.date_finish
                )
                {
                    TopicItemsAPI.getAll(data.classification_id, setSubtopics)
                    
                    setForm({
                        month_competence: data.month_competence,
                        classification_id: data.classification_id,
                        scheme_account_id: data.scheme_account_id,
                        description: data.description,
                        payment_method_id: data.payment_method_id,
                        value: data.value,
                        date: data.date ? data.date : '',
                        date_finish: data.date_finish,
                        project_id: data.project_id ?? ''
                    })

                    setLoading(false)
                }
            })
        }

        TopicAPI.getAllMy(setTopics)
        ProjectAPI.getAll(setProjects)
    }, [props.id])
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        if(props.id)
        {
            EntriesAPI.update(props.id, form, s => Success('Salvo com sucesso!', async () => {
                window.location.reload()
            }))
        }else{
            EntriesAPI.create(form, s => Success('Salvo com sucesso!', async () => {
                window.location.reload()
            }))
        }
    }

    

    return (
        <>
            {
                !loading || !props.id ?
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p>Mês de Competência</p>
                            <select onChange={e => {
                                setForm({...form, month_competence: e.target.value})
                            }} value={form.month_competence}>
                                <option>Selecione uma opção</option>
                                {
                                    months.map((item, index) => <option value={item.value}>{item.name}</option>)
                                }
                            </select>
                        </div>
                        <div className="box-input">
                            <p>Classificação</p>
                            <select onChange={e => {
                                setForm({...form, classification_id: e.target.value})

                                TopicItemsAPI.getAll(e.target.value, setSubtopics)
                            }} value={form.classification_id}>
                                <option>Selecione uma opção</option>
                                {
                                    topics.map((item, index) => <option value={item.id}>{item.name}</option>)
                                }
                            </select>
                        </div>
                        <div className="box-input">
                            <p>Plano de contas</p>
                            <select onChange={e => {
                                setForm({...form, scheme_account_id: e.target.value})
                            }} value={form.scheme_account_id}>
                                <option>Selecione uma opção</option>
                                {
                                    subtopics.map((item, index) => <option value={item.id}>{item.name}</option>)
                                }
                            </select>
                        </div>
                        <div className="box-input">
                            <p>Projeto/Unidades de negócio</p>
                            <select onChange={e => {
                                setForm({...form, project_id: e.target.value})
                            }} value={form.project_id}>
                                <option>Selecione uma opção</option>
                                {
                                    projects.map((item, index) => <option value={item.id}>{item.name}</option>)
                                }
                            </select>
                        </div>
                        <div className="box-input">
                            <p>Descrição</p>
                            <input type="text" onChange={e => {
                                setForm({...form, description: e.target.value})
                            }} value={form.description} />
                        </div>
                        <div className="box-input">
                            <p>Forma de Pagamento</p>
                            <select onChange={e => {
                                setForm({...form, payment_method_id: e.target.value})
                            }} value={form.payment_method_id}>
                                <option>Selecione uma opção</option>
                                {
                                    payments_method.map((item, index) => <option value={item.value}>{item.name}</option>)
                                }
                            </select>
                        </div>
                        <div className="box-input">
                            <p>Valor</p>
                            <CurrencyInput intlConfig={{locale: 'pt-BR', currency: 'BRL'}} onValueChange={value => {
                                if(value)
                                {
                                    setForm({...form, value: value})
                                }else{
                                    setForm({...form, value: ''})
                                }
                            }} value={form.value} />
                        </div>
                        <div className="box-input">
                            <p>Data de vencimento</p>
                            <input type="date" onChange={e => {
                                setForm({...form, date_finish: e.target.value})
                            }} value={form.date_finish} />
                        </div>
                        {/* <div className="box-input">
                            <p>Data de pagamento</p>
                            <input type="date" onChange={e => {
                                setForm({...form, date: e.target.value})
                            }} value={form.date} />
                        </div> */}
                        <div className="flex between">
                            <button type="submit"><MdAdd/> Salvar</button>
                            {
                                props.id ? 
                                (
                                    <button type="button" onClick={() => {
                                        ConfirmDelete(() => {
                                            if(props.id)
                                            {
                                                EntriesAPI.delete(props.id, () => Success('Apagado com sucesso', () => window.location.reload()))
                                            }
                                        }, () => {})
                                    }}><MdDelete/> Apagar</button>
                                ) : null
                            }
                        </div>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}