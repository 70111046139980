import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";
import RequestWithoutLoading from "../../functions/RequestWithoutLoading";

const route = '/client/proccess-sectors'

export interface SectorForm {
    id?: string,
    title: string,
    department_id: string
    new_department_id?: string
}

export interface SectorItem {
    id?: string,
    title: string,
    client_id: string,
    department_id: string
    item_number?: string
}

export interface SectorSymbolItem {
    id: string
    title: string
    description: string
}

class Sector 
{
    async create(
        data: SectorForm,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        data: SectorForm,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: SectorItem) => void),
    )
    {
        return await Request.get(`${route}/sector/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        department_id: string,
        onSuccess: ((sectors: Array<SectorItem>) => void),
    )
    {
        return await Request.get(`${route}/${department_id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async reorder(
        data: {
            list: Array<SectorItem>
        },
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await RequestWithoutLoading.post(`${route}/reorder`, data).then(onSuccess).catch(DefaultErrorResponse);
    }
}

export const ControllerProccessSector = new Sector()