import { AxiosResponse } from "axios"
import Request from "../../functions/Request"

export interface ResponseConsultDepartments {
    departments: Array<ConsultDepartmentsItem>,
    message: string,
}

export interface ConsultDepartmentsItem {
    id: string,
    label: string
}

export const ConsultDepartments = async (
    onSuccess: ((e: ResponseConsultDepartments) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.get('/departments').then(e => onSuccess(e.data)).catch(onError);
