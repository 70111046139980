import React, { useEffect, useState } from 'react'
import { MdArrowUpward, MdEdit } from 'react-icons/md'
import { ControllerIndicadorDepartment } from '../../../../services/Indicators/ControllerIndicadorDepartment'
import { ControllerIndicatorsItemProps } from '../../../../services/Indicators/Indicators'

export interface ListIndicatorsProps {
    onClickItem?: ((e: ControllerIndicatorsItemProps) => void)
    onClickEditItem?: ((e: ControllerIndicatorsItemProps) => void)
    department?: string
}

export const ListIndicators: React.FC<ListIndicatorsProps> = (props) => 
{
    const [list, setList] = useState<Array<ControllerIndicatorsItemProps>>([])

    useEffect(() => {
        if(props.department)
        {
            ControllerIndicadorDepartment.getAllListIndicadorsByDepartment(props.department, setList)
        }
    }, [props.department])

    return (
        <div className="list-indicators">
            {
                list.map(item => {
                    try {
                        return (
                            <>
                                <div className="indicator-item">
                                    <p className="title">{item.title} - {item.department.label}</p>    
                                    <p className="item">Departamento: <span className="value">{item.department.label}</span></p>
                                    <p className="item">Tipo de indicador: <span className="value">{item.type.title}</span></p>
                                    <p className="item">Para que serve: <span className="value">{item.obs}</span></p>
                                    <p className="item">Fórmula: <span className="value">{item.formula}</span></p>
                                    <p className="item">Unidade de medida: <span className="value">{item.unit.title}</span></p>
                                    <p className="item">Polaridade: <span className="value">{item.polarity.title}</span></p>
                                    <p className="item">Periodicidade: <span className="value">{item.frequency.title}</span></p>
                                    <button className="action" onClick={() => {
                                        if(props.onClickItem)
                                        {
                                            props.onClickItem(item)
                                        }
                                    }}><MdArrowUpward/> Importar</button>
                                    {
                                        item.edit ? (
                                            <>
                                                <button className="action" onClick={() => {
                                                    if(props.onClickEditItem)
                                                    {
                                                        props.onClickEditItem(item)
                                                    }
                                                }}><MdEdit />Editar</button>
                                            </>
                                        ) : null
                                    }
                                </div> 
                            </>
                        )
                    } catch (error) {
                        console.log(item)
                        return null;
                    }
                })
            }
        </div>
    )
}