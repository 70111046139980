import React, {  } from 'react'
import { FaFile, FaTrash } from 'react-icons/fa';
import { useToasts } from 'react-toast-notifications';
import { APP_CONFIG } from '../../../../constants';
import { ConfirmDelete } from '../../../../functions/Modals/Confirm';
import { FilesAPI, FilesItemProps } from '../../../../services/MyAttachments/Files'
import { formatDate } from '../../../../functions/dataFormat';

export interface FileItemProps {
    file: FilesItemProps
}

export const FileItem: React.FC<FileItemProps> = (props) => 
{
    const { file } = props;

    const { addToast } = useToasts()

    return (
        <>
            <div className="box-button with-options">
                <div className="content"  onClick={() => {
                    window.open(`${APP_CONFIG.files_root}/${file.path_file}`, '_blank')
                }}>
                    <FaFile/> {file.file_name} - {file.responsavel ? `Criado por: ${file.responsavel} em ${formatDate(file.date_created)}` : `Criado por: Não informado em ${formatDate(file.date_created)}`}
                </div>
                <div className="options">
                    <FaTrash onClick={() => {
                        ConfirmDelete(() => {
                            FilesAPI.delete(file.id, () => {
                                addToast('Arquivo apagado com sucesso!', {appearance: "info", autoDismiss: true}, () => {
                                    window.location.reload()
                                })
                            })
                        }, () => {})
                    }}/>
                </div>
            </div>
        </>
    )
}