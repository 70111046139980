import React, { useEffect, useState } from 'react'
import { FaRegFolder } from 'react-icons/fa'
import { Content } from '../../components/Content'
import { Navbar } from '../../components/Navbar'
import { useHistory } from 'react-router-dom'
import { AttachmentController, AttachmentFolderProps } from '../../services/Attachment/AttachmentController'
import { LinkBackHistory } from '../../components/LinkBackHistory'
import { useRef } from 'react'

export const AttachmentFolders = () => 
{
    const [folders, setFolders] = useState<Array<AttachmentFolderProps>>([])
    const loading = useRef(true)
    useEffect(() => {
        AttachmentController.getFolders(setFolders)
        loading.current = false
    }, [])

    const history = useHistory()

    return (
        <>
            <Navbar/>
            <Content loading={loading.current} title={
                <div className="title-button">
                    <div className="title">
                        <LinkBackHistory to="/" />
                        <h1 className="app-content-h1-destaque">Pastas</h1>
                    </div>
                </div>
            }>
                <div className="content-box">
                    {
                        folders.map(e => {
                            return (
                                <div className="box-module" onClick={() => {
                                    history.push(`/attachment-files/${e.id}`, {module: e})
                                }}>
                                    <p className="title"><FaRegFolder/> {e.title}</p>
                                </div>
                            )
                        })
                    }
                </div>
            </Content>   
        </>
    )
}
