import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Form } from '../../components/Form'
import { Button } from '../../components/Form/Button'
import { Input } from '../../components/Form/Input'
import { FaAngleLeft } from 'react-icons/fa'
import { RememberPassword, RememberPasswordForm } from '../../services/Account/RememberPassword'
import Success from '../../functions/Modals/Success'
import Error from '../../functions/Modals/Error'

export const RecoverPassword: React.FunctionComponent = (props) => 
{
    const [data, setData] = useState<RememberPasswordForm>({
        email: '',
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => 
    {
        setData({
            ...data,
            [e.target.id]: e.target.value
        })
    }

    const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => 
    {
        e.preventDefault()

        RememberPassword(data, () => Success('Se estiver tudo certo, a nova senha estará em seu e-mail em alguns instantes.'), () => Error('Houve um erro'))
    }

    
    return (
        <div className="body-sign">
            <Form onSubmit={handleSubmit} method="POST"  className="form-sign">
                <Input onChange={handleChange} value={data.email} id="email" label="Seu e-mail"/>
                <Link className="link-right-form" to="/">
                    <p><FaAngleLeft/>Voltar</p>
                </Link>
                <Button>Recuperar senha</Button>
            </Form>
        </div>
    )
}
