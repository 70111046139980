import React, { useEffect, useState } from 'react'
import { ListItemProps } from '../../../services/Scrum/ScrumLists'
import { ScrumTask, ScrumTaskProps } from './ScrumTask'
import { Droppable } from 'react-beautiful-dnd'

export interface ScrumTabProps {
    title: string,
    tab_id: string
    tasks?: ListItemProps[]
    onCreate?: (() => void)
    onUpdate?: (() => void)
    onDelete?: (() => void)
    onClick?: ((task: ListItemProps) => void)
    onMove?: ((task: ListItemProps) => void)
}

export const ScrumTab: React.FC<ScrumTabProps> = (props) => 
{
    return (
        <>
            <div className="scrum-tab">
                <div className="scrum-tab-title">
                    <p>{props.title}</p>    
                </div>
                <Droppable key={props.tab_id} type='COLUMN' ignoreContainerClipping={true} isCombineEnabled={true} direction='vertical' droppableId={props.tab_id}>
                    {(provided, snapshot) => (
                        <div 
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                            className={snapshot.isDraggingOver ? "scrum-tasks drop" :  "scrum-tasks"}
                        >
                            {props.tasks?.map((item, index) => {
                                if(!item.id)
                                    return;

                                return (
                                    <ScrumTask 
                                        key={item.id} 
                                        {...item} 
                                        onClick={props.onClick}
                                        onMove={props.onMove}
                                        onUpdate={props.onUpdate}
                                        task_id={item.id}
                                        index={index}
                                    />
                                )
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </div>
        </>
    )
}