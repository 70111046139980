import { AxiosResponse } from "axios"
import RequestWithoutLoading from "../../functions/RequestWithoutLoading";

export interface formSaveHistory {
    type: string,
    data_id: string,
    quizz_answer_id?: string
}

export const SaveHistory = async (
    data: formSaveHistory,
    onSuccess: ((e: AxiosResponse) => void),
    onError: ((e: AxiosResponse) => void)
) => await RequestWithoutLoading.post('/client/tutorial-stage/save-history', data).then(onSuccess).catch(onError);

export const NextModule = async (
    id: string,
    onSuccess: ((e: {
        module_id?: string
    }) => void),
    onError: ((e: AxiosResponse) => void)
) => await RequestWithoutLoading.post(`/client/tutorial-stage/next-module/${id}`).then(response => onSuccess(response.data.response)).catch(onError);

export const StartedHistory = async (
    form: {
        module_id?: string
        practice_id?: string
        type: 'module' | 'practice'
    },
    onSuccess: ((e: AxiosResponse) => void),
    onError: ((e: AxiosResponse) => void)
) => await RequestWithoutLoading.post(`/client/tutorial-stage/started-registry/${form.type}`, form).then(onSuccess).catch(onError);
