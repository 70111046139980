import { AxiosResponse } from "axios";
import Request from "../../functions/Request";

export const UploadPhoto = async (
    data: FormData,
    onSuccess: ((e: AxiosResponse) => void),
    onError: ((e: AxiosResponse) => void)
) => {
    await Request.post('/client/upload-logo', data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }).then(onSuccess).catch(onError);
} 