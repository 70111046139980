import React, { useEffect, useState } from 'react'
import { Content } from '../../components/Content'
import { Navbar } from '../../components/Navbar'
import { MountTutorial } from '../../services/Tutorial/MountTutorial'
import { ModuleStageProps } from '../../services/Tutorial/TutorialModule'
import { useHistory } from 'react-router-dom'
import { NotifySystemAPI } from '../../services/NotifySystem/NotifySystem'
import { LinkBackHistory } from '../../components/LinkBackHistory'
import { useRef } from 'react'
import { StartedHistory } from '../../services/Tutorial/SaveHistory'

export const Tutorial = () => 
{
    const [tutorial, setTutorial] = useState<Array<ModuleStageProps>>([])
    const loading = useRef(true)

    useEffect(() => {
        MountTutorial(e => {
            setTutorial(e.modules)
        }, () => {})

        loading.current = false
    }, [])

    const history = useHistory()

    return (
        <>
            <Navbar/>
            <Content loading={loading.current} title={
                <div className="title-button">
                    <div className="title">
                        <LinkBackHistory to="/" />
                        <h1 className="app-content-h1-destaque">Minha Jornada</h1>
                    </div>
                    {/* <div className="options">
                        <button onClick={() => history.push('/attachment-files')}><FaLink/> Arquivos</button>
                    </div> */}
                </div>
            }>
                <div className="content-box">
                    {
                        tutorial.map(e => {
                            let progress = e.stages.filter(e => e.realized).length / e.stages.length * 100;

                            return (
                                <div className="box-module" onClick={async () => {
                                        await StartedHistory({
                                            module_id: e.id,
                                            type: 'module'
                                        }, response => {}, error => {})
                                        await NotifySystemAPI.save({information: `Acabou de acessar sua Jornada em ${e.title}`})
                                        history.push(`/jornada/${e.id}`, {module: e})
                                }}>
                                    <p className="title">{e.title}</p>
                                    <p className="stages-quantity">Seu progresso: {!isNaN(progress) ? Math.round(progress) : 0}%</p>
                                </div>
                            )
                        })
                    }
                </div>
            </Content>   
        </>
    )
}
