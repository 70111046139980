import { AxiosResponse } from "axios"
import Request from "../../functions/Request"

export interface DataAlterPassowrd {
    last_password?: string,
    new_password?: string,
}

export const AlterPassword = async (
    data: DataAlterPassowrd,
    onSuccess: ((e: AxiosResponse) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.put('/alter-password', data).then(onSuccess).catch(onError);
