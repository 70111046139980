import { AxiosResponse } from "axios"
import Request from "../../functions/Request"

export const removeTaskCalendar = async (
    id: string,
    onSuccess: ((e: AxiosResponse) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.delete(`/calendar/task/${id}`).then(onSuccess).catch(onError);

export const removeAllTaskCalendar = async (
    id: string,
    onSuccess: ((e: AxiosResponse) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.delete(`/calendar/all-task/${id}`).then(onSuccess).catch(onError);
