import React, { useEffect, useRef, useState } from 'react'
import { FaCalendarWeek, FaGripHorizontal, FaListUl, FaPlus } from 'react-icons/fa'
import { Link, useHistory } from 'react-router-dom'
import { Content } from '../../components/Content'
import { LinkBackHistory } from '../../components/LinkBackHistory'
import { ConfigModal } from '../../components/Modal'
import { ModalRight } from '../../components/ModalRight'
import { Navbar } from '../../components/Navbar'
import { GenerateMonth, ResponseGenerateMonthItem } from '../../services/Calendar/GenerateMonth'
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { TaskForm } from '../../components/TaskForm'

export const CalendarMonth = () => {

    const [calendarMonth, setCalendarMonth] = useState<Array<ResponseGenerateMonthItem>>([])

    const history = useHistory()
    const loading = useRef(true)

    const render = async (msg?: string) => {
        GenerateMonth(e => {
            setCalendarMonth([...e.month])

            if(msg)
            {
                toast.success(msg)
            }
        }, e => {}) 
    }

    useEffect(() => {
        render()

        loading.current = false
    }, [])

    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [typeCalendar, setTypeCalendar] = useState('grid')

    return (
        <>
            <ModalRight {...modalConfig}/>
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/calendar-week"/>
                            <h1 className="app-content-h1-destaque">Meu mês</h1>
                        </div>
                        <div className="options">
                            <Link to='/myday-feedbacks'>Histórico de Reunião Diária</Link>
                            <Link to='/protocols'>ATAS de Reuniões</Link>
                            <button title="Vizualizar Mês" onClick={() => history.push('/calendar-week')}><FaCalendarWeek/></button>
                            <button onClick={() => setTypeCalendar('list')}><FaListUl/></button>
                            <button onClick={() => setTypeCalendar('grid')}><FaGripHorizontal/></button>
                            <button className="bkg-theme clr-white" title="Nova tarefa" onClick={() => {
                                setModalConfig({
                                    show: true,
                                    title: (
                                        <>
                                            <h2>Criar tarefa</h2>
                                        </>
                                    ),
                                    content: <TaskForm type="CREATE" onChange={() => {
                                        window.location.reload()
                                    }} />,
                                    onClose: () => setModalConfig({show: false})
                                })
                            }}><FaPlus/></button>
                        </div>
                    </div>
                </>
            )}>
                <div className={typeCalendar === "grid" ? 'calendar calendar-grid' : 'calendar calendar-list'}>
                    {
                        calendarMonth.map((e, i) => {
                            let month = new Date(e.date_filter).getUTCMonth()+1;

                            return (
                                <div className={month.toString() !== e.month.replace('0', '') ? "calendar-day old" : "calendar-day"}  key={`${i}`}>
                                    <p className="day-name">{`${e.date_formatted}# ${e.name}`}</p>
                                    {
                                        e.tasks ? (

                                            <div className="tasks">
                                                {
                                                    <>
                                                        {
                                                            e.tasks.map((task, i) => {
                                                                return (
                                                                    <div key={`${i}`} title={task.label} onClick={() => {
                                                                        setModalConfig({
                                                                            show: true,
                                                                            title: (
                                                                                <>
                                                                                    <h2 className="title-modal">Tarefa</h2>
                                                                                </>
                                                                            ),
                                                                            content: <TaskForm 
                                                                                type="UPDATE"
                                                                                id={task.id}
                                                                                onChange={() => {
                                                                                    render('Feito!')
                                                                                    setModalConfig({show: false})
                                                                                }}
                                                                            />,
                                                                            onClose: () => setModalConfig({show: false})
                                                                        })
                                                                    }} className="calendar-task">
                                                                        <div className="flex" style={task.tags.length > 1 || task.departments.length > 1 ? {flexDirection: 'column'} : {}}>
                                                                            {
                                                                                task.tags && task.tags.length ? 
                                                                                (
                                                                                    <>
                                                                                        {
                                                                                            task.tags.map((tag, item) => {
                                                                                                return (
                                                                                                    <div className="tag-task" style={{background: `${tag.color}`}}>
                                                                                                        {tag.label}
                                                                                                    </div>
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                ) : null
                                                                            } 
                                                                            {
                                                                                task.departments ? (
                                                                                    task.departments.map((e, i) => {
                                                                                        return (
                                                                                            <div key={`${i}`} style={{marginRight:'3px'}} className="tag-task bkg-blue-dark-light">{e.label}</div>
                                                                                        )
                                                                                    })
                                                                                ) : null
                                                                            }
                                                                        </div>
                                                                        {typeCalendar === "grid" ? (
                                                                            <>
                                                                                <span className="time-task">{task.hour}#</span> {task.label.substr(0, 20)} {task.label.length > 20 ? '...' : null}
                                                                            </>
                                                                        ) : task.label}
                                                                    </div>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                }
                                            </div>
                                        ) : null
                                    }
                                </div>
                            )
                        })
                    }
                </div>
            </Content>   
            <ToastContainer pauseOnFocusLoss={false}  position="bottom-right" pauseOnHover={false} autoClose={1500} />
        </>
    )
}
