import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import { routes } from '../routes'
import '../assets/style.min.css'
import ModalStore from '../stores/ModalStore'
import { ModalJornada } from '../components/ModalJornada'
import 'react-toastify/dist/ReactToastify.css';
import { Chatbot } from '../components/Chatbot'
import { cookies, getSession } from '../functions/Session'
import { APP_CONFIG } from '../constants'
import { WarningPayment } from '../components/WarningPayment/WarningPayment'
import { PingPaymentStatusAPI } from '../services/Account/PingPaymentStatus'
import { ToastProvider } from 'react-toast-notifications'
import { Notification } from '../components/Notification'
import { ButtonFastOptions } from '../components/ButtonFastOptions'
import { ConfigModal, ModalRight } from '../components/ModalRight'

export const Layout: React.FC = (props) => {
    
    const [paymentStatus, setPaymentStatus] = useState('')
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})

    useEffect(() => {
        if(cookies.get('user'))
        {
            PingPaymentStatusAPI.ping(response => {
                setPaymentStatus(response.status)
    
                if(cookies.get('user'))
                {
                    let old_user = cookies.get('user')
    
                    old_user.payment_status = response.status
    
                    cookies.set('user', old_user)
                }
            })
        }
    }, [])

    if(getSession(APP_CONFIG.sessionArgs))
    {
        return (
            <div className="app-layout">
                <Notification />
                <WarningPayment status={paymentStatus} />
                <Chatbot />
                <ModalJornada store={ModalStore} />
                <ToastProvider placement="bottom-right">
                    <ModalRight {...modalConfig}/>
                    <BrowserRouter>
                        <Switch>
                            {
                                routes().map((e, i) => <Route key={`${i}`} {...e} />)
                            }
                        </Switch>
                    </BrowserRouter>
                    <ButtonFastOptions modalConfig={(configs) => setModalConfig(configs)} />
                </ToastProvider>
            </div>
        )
    }else{
        return (
            <div className="app-layout">
                <BrowserRouter>
                    <Switch>
                        {
                            routes().map((e, i) => <Route key={`${i}`} {...e} />)
                        }
                    </Switch>
                </BrowserRouter>
            </div>
        )
    }
}
