import Error from "../../functions/Modals/Error";
import Request from "../../functions/Request"

export interface DiagnosticMountedReturn {
    status: Array<DiagnosticStages>,
    message: string
}

export interface DiagnosticStages {
    id: string
    label: string
    sequence: string
    reference: string
    questions: Array<DiagnosticQuestions>
}

export interface DiagnosticQuestions {
    id: string
    question: string
    description: string
    etapa: string
    sequence: string
    response_value: string | number
}

export const DiagnosticMounted = async (
    success: ((data: DiagnosticMountedReturn) => void)
) => {
    return Request.get('/diagnostic/mounted').then(response => {
        success(response.data)
    }).catch(err => Error('Houve um erro ao importar um diagnostico novo.'));
}