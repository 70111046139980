import React, { useEffect, useState } from 'react'
import { FaSignInAlt } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { Modal } from '../../../components/Modal'
import { ConfigModal } from '../../../components/ModalRight'
import { Navbar } from '../../../components/Navbar'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../services/ClientDepartments/ClientDepartments'

export interface CargosProps {}

export const Cargos: React.FC<CargosProps> = (props) => 
{
    const [list, setList] = useState<Array<FormClientDepartment>>([])
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const history = useHistory()

    useEffect(() => {
        ClientDepartmentsAPI.getAll(setList)
    }, [])

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content title={
                <div className="title-button">
                    <div className="title">
                        <LinkBackHistory to="/" />
                        <h1 className="app-content-h1-destaque">Descrição de cargos: Departamentos</h1>
                    </div>
                </div>
            }>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2>Acesse por departamento</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        list.length > 0 ?
                        (
                            list.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link" onClick={() => {
                                                        history.push(`/pessoas/cargos/${item.id}`, {
                                                            department: item.name
                                                        })
                                                    }}><FaSignInAlt/> {item.name}</div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : (
                            <>
                                <h3>Comece agora mesmo clicando no menu, em Minha conta&gt;Departamentos assim que criar seus departamentos venha novamente a essa tela e comece a configurar</h3>
                            </>
                        )
                    }
                </div>
            </Content>  
        </>
    )
}