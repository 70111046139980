import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const routeFile = '/client/attachment-files'
const routeFolder = '/client/attachment-folders'

export interface AttachmentFolderProps {
    id: string
    title: string
}

export interface AttachmentFileProps {
    id: string
    title: string
    path: string
    folder_id: string
}

class Attachment
{
    async getFolders(
        onSuccess: ((success: Array<AttachmentFolderProps>) => void),
    )
    {
        return await Request.get(`${routeFolder}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }
    
    async getFiles(
        folder: string,
        onSuccess: ((success: Array<AttachmentFileProps>) => void),
    )
    {
        return await Request.get(`${routeFile}/${folder}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }
}

export const AttachmentController = new Attachment()