import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/planejamento-estrategico/swot/subjects'
const route_static = '/client/planejamento-estrategico/swot/pdf'

export interface SwotSubjectFormProps {
    title: string,
    field_id: string,
    folder_id: string,
}

export interface SwotSubjectItemProps extends SwotSubjectFormProps {
    id: string
    date_create: string,
    client_id: string
}

class SwotSubjects
{
    async create(
        data: SwotSubjectFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        data: SwotSubjectFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        folder_id: string,
        onSuccess: ((success: SwotSubjectItemProps) => void),
    )
    {
        return await Request.get(`${route}/${folder_id}/${id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        folder_id: string,
        onSuccess: ((proccesses: SwotSubjectItemProps[]) => void),
    )
    {
        return await Request.get(`${route}/${folder_id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async pdf(
        folder_id: string,
        onSuccess: ((file: Blob) => void),
        onError: ((err: any) => void)
    )
    {
        return await Request.post(`${route_static}/${folder_id}`, {}, { responseType: 'blob'}).then(response => onSuccess(response.data)).catch(onError);
    }
}

export const SwotSubjectsAPI = new SwotSubjects()