import React, { useEffect, useState } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'
import { EditorHTML } from '../../../../components/EditorHTML'
import { AnaliseCriticaItemProps, analise_critica_api } from '../services/analise.critica.services'
import Success from '../../../../functions/Modals/Success'
import { formatDate } from '../../../../functions/dataFormat'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../../services/ClientDepartments/ClientDepartments'

export interface FormPOPProps {
    id?: number,
    onSuccess?: (() => void)
}

export const FormAnaliseCritica: React.FC<FormPOPProps> = (props) => 
{
    const onDelete = () => {
        if(props.id)
        {
            analise_critica_api.delete(props.id.toString(), () => Success('Apagado com sucesso', () => window.location.reload()))
        }
    }

    const onSubmit = () => {

        if(props.id)
        {
            analise_critica_api.update(props.id.toString(), form, () => Success('Atualizado com sucesso', () => window.location.reload()))
        }else{
            analise_critica_api.create(form, () => Success('Criado com sucesso!', () => window.location.reload()))
        }
    }

    const [form, setForm] = useState<AnaliseCriticaItemProps>({})

    useEffect(() => {
        if(props.id)
        {
            analise_critica_api.getById(props.id.toString(),  data => {
                setForm({
                    ...data,
                })
            })
        }
    }, [props.id])

    const changeEvent = (event: React.ChangeEvent<HTMLSelectElement|HTMLInputElement>) => {
        setForm({
            ...form,
            ...{ [event.target.name] : event.target.value }
        })
    }

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={(event) => {
                event.preventDefault()

                onSubmit()
            }}>
                <div className="box-input">
                    <p>Título</p>
                    <input type="text" onChange={changeEvent} value={form.title} name="title" id="" />
                </div>
                <div className="box-input">
                    <p>Reunião</p>
                    <input type="text" onChange={changeEvent} value={form.reuniao_id} name="reuniao_id" id="" />
                </div>
                <div className="box-input">
                    <p>Tipo</p>
                    <input type="text" onChange={changeEvent} value={form.tipo} name="tipo" id="" />
                </div>
                <div className="box-input">
                    <p>Local</p>
                    <input type="text" onChange={changeEvent} value={form.local} name="local" id="" />
                </div>
                <div className="box-input">
                    <p>Elaborado em</p>
                    <input type="date" onChange={changeEvent} value={formatDate(form.elabored_date, 'yyyy-MM-dd')} name="elabored_date" id="" />
                </div>
                <div className="flex between">
                    <button type="submit" style={{marginRight: '10px'}}><MdAdd/> Salvar</button>
                    {
                        props.id ? (
                            <button type="button" onClick={onDelete}><MdDelete/> Apagar</button>
                        ) : null
                    }
                </div>
            </form>
        </>
    )
}