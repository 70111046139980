import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Content } from '../../../components/Content'
import { ConfigModal, Modal } from '../../../components/Modal'
import { Navbar } from '../../../components/Navbar'
import { ControllerIndicatorsMeta, MetaItem } from '../../../services/IndicatorsMeta/IndicatorsMeta'
import { CreateMeta } from './components/CreateMeta'
import { UpdateMeta } from './components/UpdateMeta'

export interface MetasProps {}

export const Metas: React.FC<MetasProps> = (props) => 
{
    const history = useHistory()

    const [modalConfig, setModalConfig] = useState<ConfigModal>({
        show: false
    })

    const [metas, setMetas] = useState<Array<MetaItem>>([])

    useEffect(() => {
        ControllerIndicatorsMeta.getAll(setMetas);
    }, [])

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content title={
                <div className="title-button">
                    <div className="title">
                        <h1 className="app-content-h1-destaque">Minhas Metas</h1>
                    </div>
                    <div className="options">
                        <button onClick={() => {
                            history.push('/indicadores')
                        }}>Voltar</button>
                        <button onClick={() => {
                            setModalConfig({
                                show: true,
                                content: <CreateMeta />,
                                title: <h2>Criar meta</h2>,
                                onClose: () => setModalConfig({show: false})
                            })
                        }}>Cadastrar meta</button>
                    </div>
                </div>
            }>
                <div className="content-box">
                    <div className="table-divs-header">
                        <div>Indicador</div>
                        <div>Data</div>
                        <div>Meta</div>
                    </div>
                    {
                        metas.map((e, i) => {
                            return (
                                <>
                                    <div className="table-divs-content" onClick={() => {
                                        setModalConfig({
                                            show: true,
                                            content: <UpdateMeta id={e.id} />,
                                            title: <h2>Atualizar meta</h2>,
                                            onClose: () => setModalConfig({show: false})
                                        })
                                    }}>
                                        <div>{e.title}</div>
                                        <div>{new Date(e.date).toLocaleString('pt-BR')}</div>
                                        <div>{e.meta}%</div>
                                    </div>
                                </>
                            )
                        })
                    }
                </div>
            </Content> 
        </>
    )
}