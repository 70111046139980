import React, { useEffect, useState } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'
import { EditorHTML } from '../../../../components/EditorHTML'
import Success from '../../../../functions/Modals/Success'
import { formatDate } from '../../../../functions/dataFormat'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../../services/ClientDepartments/ClientDepartments'
import { AnaliseCriticaItemProps } from '../../AnaliseCritica/services/analise.critica.services'
import { useParams } from 'react-router-dom'
import { analise_critica_template_api, AnaliseCriticaTemplateClientFormProps } from '../services/analise.critica-templates.services'

export interface FormPOPProps {
    id?: string,
    onSuccess?: (() => void)
}

export const AnaliseCriticaTemplateForm: React.FC<FormPOPProps> = (props) => 
{
    const params = useParams<{id: string}>();
    const [createdStatus, setCreatedStatus] = useState(false)

    const onSubmit = () => {

        if(props.id && createdStatus)
        {
            analise_critica_template_api.update(props.id.toString(), params.id, form, () => Success('Atualizado com sucesso', () => window.location.reload()))
        }else{
            analise_critica_template_api.create(form, () => Success('Criado com sucesso!', () => window.location.reload()))
        }
    }

    const [form, setForm] = useState<AnaliseCriticaTemplateClientFormProps>({})

    useEffect(() => {
        if(props.id)
        {
            analise_critica_template_api.getById(params.id, props.id.toString(),  data => {
                setCreatedStatus(true)
                setForm({
                    ...data as AnaliseCriticaTemplateClientFormProps // forced type to join object
                })
            })
        }
    }, [props.id])

    const changeEvent = (event: React.ChangeEvent<HTMLSelectElement|HTMLInputElement>) => {
        setForm({
            ...form,
            ...{ [event.target.name] : event.target.value }
        })
    }

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={(event) => {
                event.preventDefault()

                onSubmit()
            }}>
                <div className="box-input">
                    <p>Assunto</p>
                    <input type="text" onChange={changeEvent} value={form.assunto_entrada} name="assunto_entrada" />
                </div>
                <div className="flex between">
                    <button type="submit" style={{marginRight: '10px'}}><MdAdd/> Salvar</button>
                </div>
            </form>
        </>
    )
}