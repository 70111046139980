import React, { useEffect, useRef, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import Success from '../../../../functions/Modals/Success'
import { OnboardingAPI, OnboardingItemFormProps } from '../../../../services/Onboarding/Onboarding'
import { Markup } from 'interweave';
import { LoadingContent } from '../../../../components/LoadingContent';
import { EditorHTML } from '../../../../components/EditorHTML';


export interface FormOnboardingProps {
    id?: string,
    onSuccess?: (() => void)
}

export const FormOnboarding: React.FC<FormOnboardingProps> = (props) => 
{
    const [form, setForm] = useState<OnboardingItemFormProps>({
        topic: '',
        suggestions_explanations: '',
        description: '',
        link_ref: ''
    })

    const loading = useRef(true)
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        if(props.id)
        {
            OnboardingAPI.update(props.id, form, () => Success('Salvo com sucesso', () => window.location.reload()))
        }else{
            OnboardingAPI.save(form, () => Success('Salvo com sucesso', () => window.location.reload()))
        }
    }


    useEffect(() => {
        if(props.id)
        {
            OnboardingAPI.consultOne(props.id, setForm)

            loading.current = false
        }
    }, [props.id])

    return (
        <>
            {
                !loading.current || !props.id ?
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p>Tópico</p>
                            <input type="text" onChange={e => setForm({...form, topic: e.target.value})} value={form.topic} />
                        </div>
                        {
                            form.suggestions_explanations !== "" ?
                            (
                                <div className="box-input">
                                    <p><Markup content={form.suggestions_explanations.replaceAll('\n', '<br/>')} /></p>
                                </div>
                            ) : null
                        }
                        <div className="box-input">
                            <p>Descrição</p>
                            <EditorHTML defaultToolbar={true} value={form.description} onChange={data => {
                                console.log(data)

                                setForm({...form, description: data})
                            }} />
                        </div>
                        <div className="box-input">
                            <p>Link de referência</p>
                            <input type="text" onChange={e => setForm({...form, link_ref: e.target.value})} value={form.link_ref} />
                        </div>
                        <button type="submit"><MdAdd/> Salvar</button>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}