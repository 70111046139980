import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Content } from '../../components/Content'
import { ConfigModal } from '../../components/Modal'
import { ModalRight } from '../../components/ModalRight'
import { Navbar } from '../../components/Navbar'
import { NormalTask } from '../../services/Calendar/calendarTypes'
import { GenerateDay } from '../../services/Calendar/GenerateDay'
import { ResponseGenerateMonthItem } from '../../services/Calendar/GenerateMonth'
import { BacklogItem, ConsultarBacklog } from '../../services/Rotina/Macro/ConsultarBacklog'
import { Backlog } from './components/Backlog'
import { DayTasks } from './components/DayTasks'

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { LinkBackHistory } from '../../components/LinkBackHistory'
import { FaPlus, FaTags } from 'react-icons/fa'
import { LoadingContent } from '../../components/LoadingContent'
import { TaskForm } from '../../components/TaskForm'
import { MyTags } from './components/MyTags'

export interface ItemTask {
    id?: string | number
    label?: string,
    status?: string | number,
    client_id?: string | number,
    atividade_id?: string | number,
    atividade_label?: string
    date_started?: string,
    date_completed?: string,
    date_create?: string,
    priorit?: string | number,
    permission?: string
    tag_id?: string
    tag_color?: string
}

export const Tasks = () => 
{
    const [tasksBacklog, setTasksBacklog] = useState<Array<BacklogItem>>([])
    const [tasksActives, setTasksActives] = useState<Array<NormalTask>>([])
    const loading = useRef(true)
    
    const [dayProps] = useState<ResponseGenerateMonthItem|any>()

    const renderBacklog = async () => {
        await ConsultarBacklog(e => setTasksBacklog(e.macros), () => {})
    }

    const renderDay = async () => {
        await GenerateDay(e => {
            setTasksActives(e.day.tasks)
        }, () => {})
    }

    useEffect(() => {
        renderBacklog()
        renderDay()

        loading.current = false
    }, [])

    const [modalConfig, setModalConfig] = useState<ConfigModal>({
        show: false
    })

    const reRenderToast = async (msg: string) => {
        await renderBacklog()
        await renderDay()

        toast.success(msg)
    }

    return (
        <div>
            <ModalRight {...modalConfig} />
            <Navbar/>
            {
                !loading.current ?
                (
                    <Content title={
                        <div className="title-button">
                            <div className="title">
                                <LinkBackHistory to="/" />
                                <h1 className="app-content-h1-destaque">Minhas tarefas</h1>
                            </div>
                            <div className="options">
                                <Link to="/calendar-week">Cronograma</Link>
                                <button onClick={() => {
                                    setModalConfig({
                                        show: true,
                                        title: <h2>Minhas etiquetas</h2>,
                                        content: <MyTags/>,
                                        onClose: () => setModalConfig({show: false})
                                    })
                                }}><FaTags/> Etiquetas</button>
                                <button className="bkg-theme clr-white" onClick={() => {
                                    setModalConfig({
                                        show: true,
                                        title: (
                                            <>
                                                <h2>Criar tarefa</h2>
                                            </>
                                        ),
                                        content: <TaskForm type="CREATE" onChange={() => {
                                            reRenderToast('Criado com sucesso!')
                                            setModalConfig({show: false})
                                        }} />,
                                        onClose: () => setModalConfig({show: false})
                                    })
                                }}><FaPlus/> Nova tarefa</button>
                            </div>
                        </div>
                    }>
                        <DayTasks 
                            dayProps={dayProps} 
                            setModalConfig={setModalConfig} 
                            tasks={tasksActives} 
                            onRemove={() => {
                                reRenderToast('Apagado com sucesso')
                                setModalConfig({show: false})
                            }}
                            onRemoveAll={() => {
                                reRenderToast('Apagado com sucesso')
                                setModalConfig({show: false})
                            }}
                            onConcluded={() => {
                                reRenderToast('Tarefa concluida!')
                                setModalConfig({show: false})
                            }}
                            onChange={() => {
                                reRenderToast('Salvo com sucesso')
                                setModalConfig({show: false})
                            }}
                            onMeetingDayChange={() => {
                                toast.success('Alterações salvas')
                            }}
                        />
                        <Backlog 
                            onSendTask={() => {
                                reRenderToast('Tarefa salva em seu *Cronograma.')
                                setModalConfig({show: false})
                            }} 
                            onCreateTask={() => {
                                reRenderToast('Criado com sucesso!')
                                setModalConfig({show: false})
                            }} 
                            onDeleteTask={() => {
                                reRenderToast('Apagado com sucesso')
                                setModalConfig({show: false})
                            }} 
                            setModalConfig={setModalConfig} 
                            tasks={tasksBacklog} 
                        />
                    </Content>
                ) : <LoadingContent />
            }
            
            <ToastContainer pauseOnFocusLoss={false}  position="bottom-right" pauseOnHover={false} autoClose={1500} />
        </div>
    )
}
