import React from 'react'
import { MdHome } from 'react-icons/md'
import { Link } from 'react-router-dom'

export interface HomeButtonProps {}

export const HomeButton: React.FC<HomeButtonProps> = (props) => 
{
    return (
        <>
            <Link className="history-back" to="/"><MdHome /></Link>
        </>
    )
}