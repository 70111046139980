import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/companies'

export interface CompaniesItemProps {
    id?: string
    name: string,
    email: string,
    phone: string,
    company_name?: string
}

class Companies
{
    async create(
        data: CompaniesItemProps,
        onSuccess: ((success: CompaniesItemProps) => void),
        onError?: (() => void)
    )
    {
        return await Request.post(`${route}`, data).then(response => onSuccess(response.data)).catch(err => {
            DefaultErrorResponse(err)

            if(onError)
            {
                onError()
            }
        });
    }

    async update(
        id: string,
        data: CompaniesItemProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async changeCompany(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/${id}/change-company`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: CompaniesItemProps) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((proccesses: Array<CompaniesItemProps>) => void),
    )
    {
        return await Request.get(`${route}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }
}

export const CompaniesAPI = new Companies()