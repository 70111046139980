import React from 'react'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

export interface LoadingContentProps {}

export const LoadingContent: React.FC<LoadingContentProps> = (props) => 
{
    return (
        <>
            <div className="loading-content">
                <Loader
                    type="Audio"
                    color="#ec6518"
                    height={100}
                    width={100}
                />
            </div>
        </>
    )
}