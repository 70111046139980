import React from 'react'
import { Link } from 'react-router-dom'

interface IRecoverPassword {
    to: string
    text?: string
}

export const RecoverPassword: React.FunctionComponent<IRecoverPassword> = (props) => {
    return (
        <Link className="link-right-form" to={props.to}>
            <p>{props.text ? props.text : `Esqueceu sua senha?`}</p>
        </Link>
    )
}
