import React from 'react'
import { FaFrownOpen } from 'react-icons/fa'

export interface NoWithContentProps {}

export const NoWithContent: React.FC<NoWithContentProps> = (props) => 
{
    return (
        <>
            <div className="no-with-content">
                {
                    props.children ? props.children : <p>Nenhuma informação encontrada <FaFrownOpen/></p>
                } 
            </div>
        </>
    )
}