import { Markup } from 'interweave';
import React from 'react'
import { FaCheck } from 'react-icons/fa';
import { TutorialStageItemProps, TutorialInformationItemProps } from '../../../services/Tutorial/TutorialModule';

export const StageInformation = (props: {
    stage: TutorialStageItemProps
}) => {
    let data: TutorialInformationItemProps = props.stage.stage;

    return (
        <div className="box-tutorial">
            <h2>{data.title} {props.stage.realized ? <FaCheck/> : null} </h2>
            <p className="information-box">{data.data}</p>
            {props.stage.practice === "2" ? (
                <div className="resume-practice">
                    {
                        props.stage.practice_information ?
                        (
                            <Markup content={props.stage.practice_information}/>
                        ): <p></p>
                    }
                </div>
            ): null}
        </div>
    )
}