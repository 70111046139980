import React from 'react'
import { LoadingContent } from '../LoadingContent'

interface IContent {
    title?: JSX.Element
    loading?: boolean
}

export const Content: React.FC<IContent> = (props) => {
    return (
        <>
            {
                props.loading ? <LoadingContent /> : (
                    <div className="app-content">
                        {props.title}
                        {props.children}
                    </div>
                )
            }
        </>
    )
}
