import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import { LoadingContent } from '../../../../../components/LoadingContent'
import Success from '../../../../../functions/Modals/Success'
import { FeedbackFormProps, PDCFeedbackAPI } from '../../../../../services/ProccessFeedbackCustom/Feedback'
import { PDCTemplateQuestion_ItemProps } from '../../../../../services/ProccessFeedbackCustom/TemplateQuestion'
import { PDCTemplate_ItemProps } from '../../../../../services/ProccessFeedbackCustom/Template'
import { PFC_FeedbacksRouteParams } from '..'
import { useToast } from 'react-toastify'
import Warning from '../../../../../functions/Modals/Warning'

export interface FormDepartmentProps {
    id?: string,
    onSuccess?: (() => void)
}

export interface QuestionResponse extends PDCTemplateQuestion_ItemProps { value?: string }

export const FormFeedback: React.FC<FormDepartmentProps> = (props) => 
{
    const { collaborator } = useParams<PFC_FeedbacksRouteParams>()

    const [loading, setLoading] = useState(true)
    const [templates, setTemplates] = useState<PDCTemplate_ItemProps[]>([])
    const [questions, setQuestions] = useState<QuestionResponse[]>([])
    
    const [form, setForm] = useState<FeedbackFormProps>({
        questions: "",
        collaborator_id:  "",
        template_id: ""
    })
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        if(form.template_id == "")
        {
            Warning('Escolha um modelo')
            return;
        }

        let all_checked = questions.filter(item => item.value == undefined)

        if(all_checked.length)
        {
            Warning('Responda as questões a baixo')
            return;
        }

        if(props.id)
        {
            PDCFeedbackAPI.update(props.id, collaborator, {...form, collaborator_id: collaborator, questions: JSON.stringify(questions)}, s => Success('Salvo com sucesso!', async () => {
                window.location.reload()
            }))
        }else{
            PDCFeedbackAPI.create(collaborator, {...form, collaborator_id: collaborator, questions: JSON.stringify(questions)}, s => Success('Salvo com sucesso!', () => window.location.reload()))
        }
    }

    const OptionsComplete = (props: React.SelectHTMLAttributes<HTMLSelectElement>) => {
        return (
            <select {...props}>
                <option>Selecione uma opção</option>
                <option value="1">Sempre</option>
                <option value="2">Frequentemente</option>
                <option value="3">Às vezes</option>
                <option value="4">Raramente</option>
                <option value="5">Nunca</option>
            </select>
        )
    }

    const OptionsNumbers = (props: React.SelectHTMLAttributes<HTMLSelectElement>) => {
        return (
            <select {...props}>
                <option>Selecione uma opção</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
            </select>
        )
    }

    const OptionsYesNo = (props: React.SelectHTMLAttributes<HTMLSelectElement>) => {
        return (
             <select {...props}>
                <option>Selecione uma opção</option>
                <option value="1">Sim</option>
                <option value="2">Não</option>
            </select>
        )
    }

    const OptionsResponse = (props: React.TextareaHTMLAttributes<HTMLTextAreaElement>) => {
        const [value, setValue] = useState(props.value)

        return <textarea  {...props}  value={value} onChange={event => setValue(event.target.value)} rows={15}/>
    }


    useEffect(() => {
        if(props.id)
        {
            PDCFeedbackAPI.getById(props.id, async dados => {
                setForm({
                    ...dados
                })

                if(dados.template_id)
                {
                    await PDCFeedbackAPI.getQuestions(dados.template_id, questions => {
                        if(dados.questions)
                        {
                            let old_questions : QuestionResponse[] = JSON.parse(dados.questions);
                            let temp_questions = questions.map((question) => {
                                let response_index = old_questions.findIndex(old_question => old_question.id == question.id)

                                if(response_index >= 0)
                                {
                                    return {
                                        ...question,
                                        value: old_questions[response_index].value
                                    }
                                }else{
                                    return question
                                }
                            })

                            setQuestions(temp_questions)
                            
                        }else{
                            setQuestions(questions)
                        }
                    })
                }
            })
            
        }

        PDCFeedbackAPI.getTemplates(setTemplates)

        setLoading(false)
    }, [props.id])

    return (
        <>
            {
                !loading || !props.id ?
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p>Qual modelo você ira usar?</p><br/>
                            <select onChange={async e => {
                                setForm({...form, template_id: e.target.value})

                                await PDCFeedbackAPI.getQuestions(e.target.value, setQuestions)
                            }} value={form.template_id}>
                                <option>Selecione uma opção</option>
                                {
                                    templates.map((item) => {
                                        return <option value={item.id}>{item.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        {
                            questions.map((item, index) => {
                                if(item.type_question == "2"){
                                    return (
                                        <div className="box-input">
                                            <p>{item.question}</p>
                                            <OptionsComplete value={item.value} onChange={event => {
                                                let temp_questions = questions;

                                                temp_questions[index].value = event.target.value

                                                setQuestions([...temp_questions])
                                            }} />
                                        </div>
                                    )
                                }else if (item.type_question == "1")
                                {
                                    return (
                                        <div className="box-input">
                                            <p>{item.question}</p>
                                            <OptionsYesNo value={item.value} onChange={event => {
                                                let temp_questions = questions;

                                                temp_questions[index].value = event.target.value

                                                setQuestions([...temp_questions])
                                            }} />
                                        </div>
                                    )
                                }else if(item.type_question == "3") {
                                    return (
                                        <div className="box-input">
                                            <p>{item.question}</p>
                                            <OptionsResponse value={item.value} onBlur={event => {
                                                let temp_questions = questions;

                                                temp_questions[index].value = event.target.value

                                                setQuestions([...temp_questions])
                                            }} />
                                        </div>
                                    )
                                }else if(item.type_question == "4") {
                                    return (
                                        <div className="box-input">
                                            <p>{item.question}</p>
                                            <OptionsNumbers value={item.value} onChange={event => {
                                                let temp_questions = questions;

                                                temp_questions[index].value = event.target.value

                                                setQuestions([...temp_questions])
                                            }} />
                                        </div>
                                    )
                                }
                            })
                        }
                        <button type="submit"><MdAdd/> Salvar</button>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}