import React, { useEffect, useState } from 'react'
import { MdAdd } from "react-icons/md";
import Success from '../../../functions/Modals/Success';
import { ContactForm } from '../../../services/Contacts/ContactModule';
import { CreateMyContact } from '../../../services/Contacts/CreateMyContact';
import Error from '../../../functions/Modals/Error';
import { NotifySystemAPI } from '../../../services/NotifySystem/NotifySystem';

interface ICreateContact {
    onSuccess?: (() => void)
}

export const CreateContact: React.FC<ICreateContact> = (props) => 
{
    const [data, setData] = useState<ContactForm>({
        name: '',
        phone: '',
        email: '',
    })

    useEffect(() => {

    }, [])

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        CreateMyContact(data, s => Success('Contato criado com sucesso!', () => {
            NotifySystemAPI.save({information: 'Criou um novo contato'})
            
            if(props.onSuccess)
            {
                props.onSuccess()
            }else{
                window.location.reload()
            }
        }) , e => Error(e.data.message))
    }

    return (
        <>
            <form method="post" onSubmit={onSubmit} style={{marginTop: '30px'}}>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Nome*</label>
                    <input type="text" onChange={e => setData({...data, name: e.target.value})} value={data.name} placeholder="Digite aqui o nome do contato." required autoComplete="off" autoFocus={true}/>
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Telefone</label>
                    <input type="text" onChange={e => setData({...data, phone: e.target.value})} value={data.phone} placeholder="Digite aqui o telefone." autoComplete="off" autoFocus={true}/>
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">E-mail*</label>
                    <input type="email" onChange={e => setData({...data, email: e.target.value})} value={data.email} placeholder="Digite aqui o email." required autoComplete="off" autoFocus={true}/>
                </div>
                <br /><br />
                <button className="btn-styled success" type="submit"><MdAdd/> Criar Contato</button>
            </form>
        </>
    )
}
