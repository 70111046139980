import React, { useEffect, useState } from 'react'
import { ConfigModal, ModalRight } from '../../../components/ModalRight'
import { useHistory } from 'react-router-dom'
import { Navbar } from '../../../components/Navbar'
import { Content } from '../../../components/Content'
import { ButtonBackHistory } from '../../../components/ButtonBackHistory'
import { FormControleMudanca } from './components/ControleMudancaForm'
import { FaArrowRight, FaEdit, FaFilePdf, FaPlus } from 'react-icons/fa'
import { ControleMudancaItemProps, controle_mudanca_api } from './services/controle.mudanca.services'
import { APP_CONFIG } from '../../../constants'
import { formatDate } from '../../../functions/dataFormat'
import Loading from '../../../functions/Modals/Loading'
import Success from '../../../functions/Modals/Success'

export interface ControleMudancaProps {}

export const ControleMudanca: React.FC<ControleMudancaProps> = (props) => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({
        show: false
    })

    const [rows, setRows] = useState<ControleMudancaItemProps[]>([])

    const history = useHistory()

    useEffect(() => {
        controle_mudanca_api.getAll((data) => {
            setRows(data)
        })
    }, [])

    return (
        <>
            <ModalRight {...modalConfig} />
            <Navbar/>
            <Content title={(
                <div className='title-button'>
                    <div className="title-button">
                        <div className="title">
                            <ButtonBackHistory onClick={() => history.goBack()}/>
                            <h1 className="app-content-h1-destaque">Controle Mudança</h1>
                        </div>
                    </div>
                    <div className="options">
                        <button onClick={() => {
                            setModalConfig({
                                show: true,
                                content: <FormControleMudanca onSuccess={() => window.location.reload()} />,
                                onClose: () => setModalConfig({
                                    show: false
                                }),
                                title: <h2>Novo: Controle de Mudança</h2>
                            })
                        }}>Novo <FaPlus /></button>
                    </div>
                </div>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Já criados</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        rows.length > 0 ?
                        (
                            rows.map((item, index) => 
                                {
                                    return (
                                        <>
                                            <div key={`${index}`} className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title"><b>{item.title}</b> - Criado por <b>{item.user_created?.name}</b> em <b>{formatDate(item.created_date)}</b></div>
                                                    <div className="options">
                                                        <button className="update-button" onClick={() => {
                                                            setModalConfig({
                                                                show: true,
                                                                content: <FormControleMudanca onSuccess={() => window.location.reload()} id={item.id} />,
                                                                onClose: () => setModalConfig({
                                                                    show: false
                                                                }),
                                                                title: <h2>Atualizar: Controle de mudança</h2>
                                                            })
                                                        }}><FaEdit /></button>
                                                        <button className="pdf-button" onClick={() => {
                                                            if(item.id)
                                                            {
                                                                Loading()

                                                                controle_mudanca_api.pdf(item.id.toString(), file => {
                                                                    const href = URL.createObjectURL(file);
    
                                                                    // create "a" HTML element with href to file & click
                                                                    const link = document.createElement('a');
                                                                    link.href = href;
                                                                    link.setAttribute('download', 'controle_mudanca.pdf'); //or any other extension
                                                                    document.body.appendChild(link);
                                                                    link.click();
                                                                
                                                                    // clean up "a" element & remove ObjectURL
                                                                    document.body.removeChild(link);
                                                                    URL.revokeObjectURL(href);
                                                                }, console.log).finally(() => Success('Download realizado com sucesso!'))
                                                            }
                                                        }}><FaFilePdf/></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) :
                        (
                            <h3>Sem informações para serem mostradas</h3>
                        )
                    }
                </div>
            </Content>
        </>
    )
}