import React, { useEffect, useState } from 'react'
import { FaCheck } from 'react-icons/fa'
import Success from '../../../../functions/Modals/Success'
import { EntriesAPI, EntryProps } from '../../../../services/Financeiro/Entries'

export interface FormDateEntryProps {
    data: EntryProps,
    onSuccessUpdate: (() => void)
}

export const FormDateEntry: React.FC<FormDateEntryProps> = (props) => 
{
    const [dateEntry, setDateEntry] = useState('')

    const [haveChange, setHaveChange] = useState(false)

    useEffect(() => {
        setDateEntry(props.data.date_filter ?? '')
    }, [props.data])

    return (
        <>
            <div className={haveChange ? "finance-date-input changed" : "finance-date-input"}>
                <input type="date" value={dateEntry} onChange={event => {
                    setDateEntry(event.target.value)

                    if(event.target.value !== props.data.date_filter)
                    {
                        setHaveChange(true)
                    }else{
                        setHaveChange(false)
                    }
                }} />
                <button type="button" onClick={() => {
                    if(props.data.id)
                    {
                        EntriesAPI.updateDate(props.data.id, { date: dateEntry}, () => {
                            Success('Salvo com sucesso!', () => {
                                props.onSuccessUpdate()
                            })
                        })
                    }
                }}><FaCheck /></button>
            </div>
        </>
    )
}