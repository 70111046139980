import React, { useEffect, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ButtonBackHistory } from '../../../../../components/ButtonBackHistory'
import { Content } from '../../../../../components/Content'
import { ConfigModal } from '../../../../../components/Modal'
import { ModalRight } from '../../../../../components/ModalRight'
import { Navbar } from '../../../../../components/Navbar'
import { ControllerProccessStage, ProccessStageItem } from '../../../../../services/Proccess/ControllerProccessStage'
import { FormStageProccess } from './components/FormStageProccess'
import { FormPOP } from '../../../POP/components/FormPOP'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useRef } from 'react'
import { ControllerProccess, ProccessItem } from '../../../../../services/Proccess/ControllerProccess'

export interface ProccessStagesProps {}

export const ProccessStages: React.FC<ProccessStagesProps> = () => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    
    const [listStages, setListStages] = useState<Array<ProccessStageItem>>([])
    const [proccess, setProccess] = useState<ProccessItem>({
        title: '',
        client_id: '',
        macroproccess_id: '',
        department_id: '',
    })
    const history = useHistory()
    const loading = useRef(true)
    const { id } = useParams<{id: string}>()
    const { state } = useLocation<any>()

    useEffect(() => {
        ControllerProccessStage.getAll(id, setListStages)
        ControllerProccess.getById(id, setProccess)

        loading.current = false
    }, [id])

    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
          return;
        }
    
        let items: any = reorder(
            listStages,
            result.source.index,
            result.destination.index
        );
    
        setListStages([...items]);

        ControllerProccessStage.reorder({list: items}, () => {
            ControllerProccessStage.getAll(id, setListStages)
        })
      }

    return (
        <>
            <ModalRight {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <ButtonBackHistory onClick={() => history.push(`/macroproccess/${state.macro_proccess_id}`, state)}/>
                            <h1 className="app-content-h1-destaque">Microprocesso: {proccess.title}</h1>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    content: <FormStageProccess proccess_id={id} onSuccess={item => {
                                        ControllerProccessStage.getAll(id, setListStages)
                                    }} />,
                                    onClose: () => setModalConfig({
                                        show: false
                                    }),
                                    title: <h2>Criar etapas</h2>
                                })
                            }}>Criar etapas</button>
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    content: <FormPOP onSuccess={() => window.location.reload()} proccess_id={id} />,
                                    onClose: () => setModalConfig({
                                        show: false
                                    }),
                                    title: <h2>Novo Procedimento Operacional</h2>
                                })
                            }}>Criar Procedimento Operacional Padrão</button>
                        </div>
                    </div>
                    <p className="path-page">{state.department_title} / {state.macro_proccess_title} / {proccess.title}</p>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Lista de etapas</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        listStages.length > 0 ?
                        (
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    >
                                    {listStages.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id} index={index}>
                                        {(provided, snapshot) => (
                                            <div className="stage-item" ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps} onClick={() => {
                                                setModalConfig({
                                                    show: true,
                                                    content: <FormStageProccess onSuccess={() => { 
                                                        ControllerProccessStage.getAll(id, setListStages)
                                                        setModalConfig({show: false}) 
                                                    }}  id={item.id} proccess_id={id} />,
                                                    onClose: () => setModalConfig({
                                                        show: false
                                                    }),
                                                    title: <h2>Atualizar etapa</h2>
                                                })
                                            }}>
                                                <p className="p-item"><span>O que fazer?:</span> {state.macro_number}.{state.micro_number}.{item.item_number}</p>
                                                <div dangerouslySetInnerHTML={{__html: item.description}}></div> 
                                            </div>
                                        )
                                            }
                                        </Draggable>
                                    ))}
                                    {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>
                            </DragDropContext>
                        ) :
                        (
                            <h3>Sem informações para serem mostradas</h3>
                        )
                    }
                </div>
            </Content>
        </>
    )
}