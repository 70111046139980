import React, { useEffect, useRef, useState } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'
import { LoadingContent } from '../../../../components/LoadingContent'
import Success from '../../../../functions/Modals/Success'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../../services/ClientDepartments/ClientDepartments'
import { NotifySystemAPI } from '../../../../services/NotifySystem/NotifySystem'
import { CustomerJourneyStageForm, ControllerCustomerJourneyStage } from '../../../../services/Proccess/ControllerCustomerJourneyStage'

export interface FormCustomerJourneyStageProps {
    onSuccess?: (() => void)
    id?: string
}

export const FormCustomerJourneyStage: React.FC<FormCustomerJourneyStageProps> = (props) => 
{
    const [form, setForm] = useState<CustomerJourneyStageForm>({
        description: '',
        department_id: '',
        document: '',
        sla: '',
    })

    const loading = useRef(true)


    const [listDepartments, setListDepartments] = useState<Array<FormClientDepartment>>([])
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        if(props.id)
        {
            ControllerCustomerJourneyStage.update(form, s => Success('Salvo com sucesso!', () => {
                if(props.onSuccess)
                {
                    props.onSuccess()
                }
            }))
        }else{
            ControllerCustomerJourneyStage.create(form, s => Success('Salvo com sucesso!', async () => {
                await NotifySystemAPI.save({information: 'Criou uma nova etapa em: Processos>Jornada do cliente'})

                if(props.onSuccess)
                {
                    props.onSuccess()
                }

                setForm({
                    description: '',
                    department_id: '',
                    document: '',
                    sla: '',
                })
            }))
        }

    }

    const onDelete = (e: React.MouseEvent<HTMLButtonElement>) => {
        if(props.id)
        {
            ControllerCustomerJourneyStage.delete(props.id, s => Success('Apagado com sucesso!', () => {
                if(props.onSuccess)
                {
                    props.onSuccess()
                }
            }))
        }
    }

    useEffect(() => {
        if(props.id)
        {
            ControllerCustomerJourneyStage.getById(props.id, setForm)
        }

        ClientDepartmentsAPI.getAll(setListDepartments)

        loading.current = false
    }, [props.id])

    return (
        <>
            {
                !loading.current ?
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="input-box-v2 mg-top">
                            <label htmlFor="!#">O que fazer?</label>
                            <textarea onChange={e => setForm({...form, description: e.target.value})} value={form.description} />
                        </div>
                        <div className="input-box-v2 mg-top">
                            <label htmlFor="!#">Qual departamento?</label>
                            <select onChange={e => setForm({...form, department_id: e.target.value})} value={form.department_id}>
                                <option>Selecione</option>
                                {
                                    listDepartments.map((item, index) => {
                                        return <option value={item.id} key={`${index}`}>{item.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="input-box-v2 mg-top">
                            <label htmlFor="!#">Documentos/Sistemas necessários</label>
                            <input type="text" onChange={e => setForm({...form, document: e.target.value})} value={form.document} />
                        </div>
                        <div className="input-box-v2 mg-top">
                            <label htmlFor="!#">Tempo/Prazo para ação (SLA)</label>
                            <input type="text" onChange={e => setForm({...form, sla: e.target.value})} value={form.sla} />
                        </div>
                        <div className="flex between">
                            <button type="submit" className="btn-styled success" style={{marginRight: '10px'}}><MdAdd/> Salvar</button>
                            {
                                props.id ? (
                                    <button type="button" onClick={onDelete}><MdDelete/> Apagar</button>
                                ) : null
                            }
                        </div>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}