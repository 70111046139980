import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/manager-notification'

export interface NotifyMessageProps {
    id: string
    title: string
    message: string
    date_started: string
    date_create: string
    date_finish: string
}


class ManagerNotify 
{
    async getAll(
        onSuccess: ((success: NotifyMessageProps[]) => void),
    )
    {
        return await Request.get(`${route}`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }

    async getAllNotifications(
        onSuccess: ((success: NotifyMessageProps[]) => void),
    )
    {
        return await Request.get(`${route}/all`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }

    async post(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }
}

export const ManagerNotifyAPI = new ManagerNotify()