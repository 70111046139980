import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/organograma-departments'

export interface OrganogramaDepartmentForm {
    id?: string
    title: string
}

export interface OrganogramaDepartmentItem {
    id?: string
    title: string
    client_id: string
}


class OrganogramaDepartment 
{
    async create(
        data: OrganogramaDepartmentForm,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        data: OrganogramaDepartmentForm,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: OrganogramaDepartmentItem) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async getName(
        id: string,
        onSuccess: ((success: {
            id?: string
            name: string
            client_id: string
        }) => void),
    )
    {
        return await Request.get(`/client/organograma/department/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((proccesses: Array<OrganogramaDepartmentItem>) => void),
    )
    {
        return await Request.get(`${route}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }
}

export const OrganogramaDepartmentAPI = new OrganogramaDepartment()