import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { MdAdd } from 'react-icons/md'
import { EditorHTML } from '../../../../components/EditorHTML'
import { LoadingContent } from '../../../../components/LoadingContent'
import Success from '../../../../functions/Modals/Success'
import { ManualColaboradorAPI } from '../../../../services/ManualColaborador/ManualColaborador'

export interface FormManualProps {
    onCreate?: (() => void),
    onUpdate?: (() => void)
}

export interface FormProps {
    purpose: string
    mission: string
    vision: string
    values_company: string
    standards: string
    text_initial: string
    text_finish: string
}

export const FormManual: React.FC<FormManualProps> = (props) => 
{
    const [form, setForm] = useState<FormProps>({
        purpose: '',
        mission: '',
        vision: '',
        values_company: '',
        standards: '',
        text_initial: '',
        text_finish: ''
    })

    const [purpose, setPurpose] = useState('')
    const [mission, setMission] = useState('')
    const [vision, setVision] = useState('')
    const [values_company, setValues_company] = useState('')

    const loading = useRef(true)

    useEffect(() => {
        ManualColaboradorAPI.consult(data => {
            setForm({
                ...data
            })

            setPurpose(data.purpose)
            setMission(data.mission)
            setVision(data.vision)
            setValues_company(data.values_company)
        })

        let auto_save = setInterval(() => {
            ManualColaboradorAPI.save(form, () => {})
        }, 30000)

        loading.current = false

        return () => {
            clearInterval(auto_save)
        }
    }, [])

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        ManualColaboradorAPI.save({
            ...form,
            purpose,
            mission,
            vision,
            values_company
        }, () => Success('Enviado com sucesso!', () => {
            if(props.onCreate)
            {
                props.onCreate()
            }
        }))
    }

    return (
        <>
            {
                !loading.current ?
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p>Propósito</p>
                            <EditorHTML defaultToolbar={true} onChange={value => setPurpose(value)} value={purpose} />
                        </div>
                        <div className="box-input">
                            <p>Missão</p>
                            <EditorHTML defaultToolbar={true} onChange={value => setMission(value)} value={mission} />
                        </div>
                        <div className="box-input">
                            <p>Visão</p>
                            <EditorHTML defaultToolbar={true} onChange={value => setVision(value)} value={vision} />
                        </div>
                        <div className="box-input">
                            <p>Valores</p>
                            <EditorHTML defaultToolbar={true} onChange={value => setValues_company(value)} value={values_company} />
                        </div>
                        <div className="flex between">
                            <button type="submit" style={{marginRight: '10px'}}><MdAdd/> Salvar</button>
                        </div>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}