import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/v2/protocols-folders'

export interface ProtocolFolderFormProps {
    name: string
}

export interface ProtocolFolderItemProps {
    name: string
    company_id?: string
    client_id?: string
    id?: string
}


class ProtocolFolder 
{
    async create(
        data: ProtocolFolderItemProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        protocol_id: string,
        data: ProtocolFolderItemProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/${protocol_id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((success: Array<ProtocolFolderItemProps>) => void),
    )
    {
        return await Request.get(`${route}`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: ProtocolFolderItemProps) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }
}

export const ProtocolFolderAPI = new ProtocolFolder()