import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/nps'

export interface NPSForm {
    nps_points: string,
    description: string
    module: string,
}


class NPS 
{
    async create(
        data: NPSForm,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }
}

export const NPSAPI = new NPS()