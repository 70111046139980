import React, { useRef } from 'react'
import { FaTimes } from 'react-icons/fa'

export interface ConfigModal 
{
    title?: JSX.Element,
    content?: JSX.Element,
    footer?: JSX.Element,
    show: boolean,
    onClose?: (() => void)
}

export const ModalRight: React.FC<ConfigModal> = (props) => {
    const modal = useRef(null)

    return (
        <div ref={modal} className="modal-right" id="modal-right" style={{display: props.show ? 'block' : 'none'}}>
        {/* <div ref={modal} className="modal-right" id="modal-right" style={{display: props.show ? 'block' : 'none'}} onClick={event => {
            if (event.target === modal.current && props.onClose)
            {
                props.onClose()
            }
        }}> */}
            <div className="modal-right-modal">
                <div className="modal-close" >
                    <FaTimes onClick={() => {
                        if(props.onClose)
                        {
                            props.onClose()
                        }
                    }}/>
                </div>
                <>
                    {props.title}
                </>
                <>
                    {props.content}
                </>
                <>
                    {props.footer}
                </>
            </div>
        </div>
    )
}
