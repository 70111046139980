import React, { useEffect, useRef, useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { FaSignInAlt } from 'react-icons/fa'
import { MdArrowBack, MdDeleteForever, MdModeEdit } from 'react-icons/md'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Content } from '../../../components/Content'
import { ConfigModal, Modal } from '../../../components/Modal'
import { Navbar } from '../../../components/Navbar'
import { NoWithContent } from '../../../components/NoWithContent'
import { ConfirmDelete } from '../../../functions/Modals/Confirm'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../services/ClientDepartments/ClientDepartments'
import { ControllerProccessSector, SectorItem } from '../../../services/Proccess/ControllerProccessSector'
import { FormSector } from '../Proccess/components/FormSector'

export interface ProccessProps {}

export const DepartmentProccess: React.FC<ProccessProps> = () => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [list, setList] = useState<Array<SectorItem>>([])
    const [department, setDepartment] = useState<FormClientDepartment>({});

    const history = useHistory()
    const loading = useRef(true)
    const { state } = useLocation<any>()
    const { id } = useParams<{id: string}>()

    useEffect(() => {
        ControllerProccessSector.getAll(id, setList)
        ClientDepartmentsAPI.getById(id, setDepartment)

        loading.current = false
    }, [id])

    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
          return;
        }
    
        let items: any = reorder(
            list,
            result.source.index,
            result.destination.index
        );
    
        setList([...items]);

        ControllerProccessSector.reorder({list: items}, () => {
            ControllerProccessSector.getAll(id, setList)
        })

    }

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <button className="history-back" onClick={() => history.push('/proccess')}><MdArrowBack /></button>
                            <h1 className="app-content-h1-destaque">Departamento: {department.name}</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>MacroProcessos</h2>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    content: <FormSector department={id} onSuccess={() => {
                                        ControllerProccessSector.getAll(id, setList)

                                        setModalConfig({
                                            show: false
                                        })
                                    }} />,
                                    onClose: () => setModalConfig({
                                        show: false
                                    }),
                                    title: <h2>Criar Macroprocesso</h2>
                                })
                            }}>Criar Macroprocesso</button>
                        </div>
                    </div>
                    <br/>
                    {
                        list.length > 0 ?
                        (
                            <>
                                <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="droppable">
                                    {(provided, snapshot) => (
                                        <div
                                        {...provided.droppableProps}
                                        ref={provided.innerRef}
                                        >
                                        {list.map((item, index) => (
                                            <Draggable key={item.id} draggableId={item.id ? item.id : `${index}`} index={index}>
                                                {(provided, snapshot) => (
                                                    <div className="proccess-item" ref={provided.innerRef}
                                                                        {...provided.draggableProps}
                                                                        {...provided.dragHandleProps} onClick={() => {}}>
                                                        <div className="title-with-button-options">
                                                            <div className="title link" onClick={() => {
                                                                history.push(`/macroproccess/${item.id}`, {department_id: id,  macro_number: item.item_number, macro_proccess_title: item.title, ...state})
                                                            }}><FaSignInAlt/> {item.item_number}.{item.title}</div>
                                                            <div className="options">
                                                                <button className="update-button" onClick={() => {
                                                                    setModalConfig({
                                                                        show: true,
                                                                        content: <FormSector id={item.id} department={id} onSuccess={() => {
                                                                            ControllerProccessSector.getAll(id, setList)

                                                                            setModalConfig({
                                                                                show: false
                                                                            })
                                                                        }} />,
                                                                        onClose: () => setModalConfig({
                                                                            show: false
                                                                        }),
                                                                        title: <h2>Atualizar MacroProcesso</h2>
                                                                    })
                                                                }}><MdModeEdit/></button>
                                                                <button className="delete-button" onClick={() => {
                                                                    ConfirmDelete(() => {
                                                                        if(item.id)
                                                                        {
                                                                            ControllerProccessSector.delete(item.id, () => {
                                                                                setList([...list.filter(i => i.id !== item.id)])
                                                                            })
                                                                        }
                                                                    }, () => null)
                                                                }}><MdDeleteForever/></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                                </Draggable>
                                            ))}
                                        {provided.placeholder}
                                        </div>
                                    )}
                                    </Droppable>
                                </DragDropContext>
                            </>
                        ) : <NoWithContent/>
                    }
                </div>
            </Content>
        </>
    )
}