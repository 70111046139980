import { AxiosResponse } from "axios"
import Request from "../../functions/Request"

export interface RememberPasswordForm {
    email: string
}

export const RememberPassword = async (
    data: RememberPasswordForm,
    onSuccess: ((e: AxiosResponse) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.post('/sign/client/remember', data).then(onSuccess).catch(onError);
