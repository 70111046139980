import { AxiosResponse } from "axios";
import ServerRequest from "../../../../functions/ServerRequest";
import DefaultErrorResponse from "../../../../functions/DefaultErrorResponse";

const route = '/users/analise-critica/analise-template'

export interface AnaliseCriticaTemplateClientItemProps extends AnaliseCriticaTemplateClientFormProps {
    id?: number
}

export interface AnaliseCriticaTemplateClientFormProps {
    assunto_entrada?: string
    status?: boolean
}

class AnaliseCriticaTemplateClientService
{
    async create(
        data: AnaliseCriticaTemplateClientFormProps,
        onSuccess: ((success: AnaliseCriticaTemplateClientItemProps) => void),
    )
    {
        return await ServerRequest.post(`${route}`, data).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        analise_critica_id: string,
        data: AnaliseCriticaTemplateClientFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.put(`${route}${analise_critica_id}/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        analise_critica_id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.delete(`${route}/${analise_critica_id}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        analise_critica_id: string,
        onSuccess: ((proccesses: Array<AnaliseCriticaTemplateClientItemProps>) => void),
    )
    {
        return await ServerRequest.get(`${route}/${analise_critica_id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async getById(
        analise_critica_id: string,
        assunto_entrada_id: string,
        onSuccess: ((rnc: AnaliseCriticaTemplateClientItemProps) => void),
    )
    {
        return await ServerRequest.get(`${route}/${analise_critica_id}/${assunto_entrada_id}`).then(response => onSuccess(response.data)).catch(err => {
            if(err.status == 400)
            {
                return;    
            }else{
                return DefaultErrorResponse(err)
            }
        });
    }
}

export const analise_critica_template_api = new AnaliseCriticaTemplateClientService()