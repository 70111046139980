import React, { useEffect, useRef, useState } from 'react'
import { formatValue } from 'react-currency-input-field'
import { FaArrowLeft, FaArrowRight, FaFileExport } from 'react-icons/fa'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { Navbar } from '../../../components/Navbar'
import { NoWithContent } from '../../../components/NoWithContent'
import { APP_CONFIG } from '../../../constants'
import { money } from '../../../functions/Format'
import { cookies } from '../../../functions/Session'
import { CashFlowAPI, CashFlowItemProps } from '../../../services/Financeiro/CashFlow'
import { EntriesAPI, EntryProps } from '../../../services/Financeiro/Entries'
import { TopicAPI, TopicProps } from '../../../services/Financeiro/Topic'
import { payments_method } from '../Lancamentos/components/FormEntry'

export interface FluxoCaixaProps {}

export const FluxoCaixa: React.FC<FluxoCaixaProps> = (props) => 
{
    const [months, setMonths] = useState<Array<CashFlowItemProps>>([])
    const [month_competence, setMonthSelected] = useState("")
    const [list, setList] = useState<Array<EntryProps>>([])
    const [topics, setTopics] = useState<Array<TopicProps>>([])
    const loading = useRef(true)

    const [year, setYear] = useState(new Date().getFullYear())

    useEffect(() => {
        CashFlowAPI.getAll(year.toString(), setMonths)
        EntriesAPI.getAll(setList)
        TopicAPI.getAllMy(setTopics)

        loading.current = false
    }, [])

    return (
        <>
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/" />
                            <h1 className="app-content-h1-destaque">Financeiro: Fluxo de Caixa ({year})</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    {
                        month_competence === "" ?
                        (
                            <>
                                <div className="title-button">
                                    <div className="title">
                                        <h2 onClick={() => {}}>Relatório rápido: Fluxo de caixa</h2>
                                    </div>
                                    <div className="options">
                                    <div className="options">
                                        <button className="btn" onClick={() => {
                                            setTopics([])

                                            CashFlowAPI.getAll((year-1).toString(), setMonths)

                                            setYear(year-1)
                                        }}><FaArrowLeft /> Ano anterior</button>
                                        <button className="btn" onClick={() => {
                                            setTopics([])

                                            CashFlowAPI.getAll((year+1).toString(), setMonths)

                                            setYear(year+1)
                                        }}>Próximo ano <FaArrowRight /> </button>
                                        <button className="btn" onClick={() => {
                                            const token = cookies.get('X-Request-Token')

                                            window.open(`${APP_CONFIG.localhost}/client/finance/cash-flow/csv?token=${token}&year=${year}`);
                                        }}><FaFileExport /> Exportar</button>
                                    </div>
                                    
                                    </div>
                                </div>
                                <br/>
                                {
                                    months.length > 0 ?
                                    (
                                        months.map((item) => 
                                            {
                                                return (
                                                    <>
                                                        <div className="proccess-item" onClick={() => {
                                                            setMonthSelected(item.mes_number)
                                                        }}>
                                                            <div className="title-with-button-options">
                                                                <div className="title">
                                                                    <p><b>{item.mes}</b></p>
                                                                    <p>{formatValue({value: Number(item.balance).toFixed(2), intlConfig: {locale: 'pt-BR', currency: 'BRL'}})}</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        )
                                    ) : <NoWithContent/>
                                }
                            </>
                        ) : (
                            <>
                                <div className="title-button">
                                    <div className="title">
                                        <h2>Relatório rápido: Detalhamento</h2>
                                    </div>
                                    <div className="options">
                                        <button onClick={() => setMonthSelected("")}>Alterar mês</button>
                                    </div>
                                </div>
                                <div className="finance-entries-header">
                                    <p>Classificação</p>
                                    <p>Plano de contas</p>
                                    <p>Descrição</p>
                                    <p>Forma de Pagamento</p>
                                    <p>Valor</p>
                                    <p>Data Venc.</p>
                                    <p>Data Pag.</p>
                                </div>
                                {
                                    list.length > 0 ?
                                    (
                                        list.filter(item => item.month_competence === month_competence).map((item, index) => 
                                            {
                                                let classification_index = topics.findIndex(topic => topic.id === item.classification_id)
                                                let scheme_account;
                                                let scheme_account_data;
                                                let classificaton_data;

                                                let payment_method_index = payments_method.findIndex(payment => payment.value === item.payment_method_id)
                                                let payment = payments_method[payment_method_index]

                                                if(classification_index >= 0)
                                                {
                                                    classificaton_data = topics[classification_index];

                                                    scheme_account = topics[classification_index].subtopics;

                                                    if(scheme_account && scheme_account.length)
                                                    {
                                                        let scheme_index = scheme_account.findIndex(scheme => scheme.id === item.scheme_account_id)

                                                        if(scheme_index >= 0)
                                                        {   
                                                            scheme_account_data = scheme_account[scheme_index]
                                                        }
                                                    }
                                                }


                                                return (
                                                    <>
                                                        <div className={item.sum_type === "1" ? "finance-entries-content-item entry" : "finance-entries-content-item output"} >
                                                            <p>{classificaton_data?.name}</p>
                                                            <p>{scheme_account_data?.name}</p>
                                                            <p>{item.description}</p>
                                                            <p>{payment.name}</p>
                                                            <p>{money(item.value)}</p>
                                                            <p>{item.date_finish}</p>
                                                            <p>{item.date}</p>
                                                        </div>
                                                    </>
                                                )
                                            }
                                        )
                                    ) : <NoWithContent/>
                                }
                            </>
                        )
                    }
                </div>
            </Content>
        </>
    )
}