import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/finance/topic'

export interface TopicFormProps {
    topic: string
    type: string
    group_id: string
}

export interface TopicProps {
    name?: string
    type?: string
    client_id?: string
    subtopics?: Array<{
        id: string
        name: string
        topic_id: string
    }>
    id?: string
}

export interface TopicGraphicProps {
    scheme_account_id: string,
    scheme_account_name: string,
    total: number,
    sum_type: "1" | "2"
}

class Topic 
{
    async create(
        data: TopicFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async import(
        topic_id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}/import/${topic_id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        data: TopicFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        group_id: string,
        onSuccess: ((success: Array<TopicProps>) => void),
    )
    {
        return await Request.get(`${route}/group/${group_id}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }

    async getAllMy(
        onSuccess: ((success: Array<TopicProps>) => void),
    )
    {
        return await Request.get(`${route}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }

    async getAllNoSync(
        group_id: string,
        onSuccess: ((success: Array<TopicProps>) => void),
    )
    {
        return await Request.get(`${route}/sync/group/${group_id}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }

    async getGraphics(
        date_started: string,
        date_finish: string,
        onSuccess: ((success: Array<TopicGraphicProps>) => void),
    )
    {
        return await Request.get(`/client/finance/graphics/start/${date_started}/finish/${date_finish}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: TopicProps) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }
}

export const TopicAPI = new Topic()