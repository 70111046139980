import React, { useEffect, useState } from 'react'
import { formatValue } from 'react-currency-input-field'
import { Form } from '../../components/Form'
import Loading from '../../functions/Modals/Loading'
import Success from '../../functions/Modals/Success'
import Warning from '../../functions/Modals/Warning'
import { PaymentAPI } from '../../services/Payment/Payment'
import { CardProps, PlansProps, RegisterAPI, RegisteredFormProps } from '../../services/Register/Register'
import InputMask from 'react-input-mask';
import { FaArrowLeft } from 'react-icons/fa'
import { Navbar } from '../../components/Navbar'
import { Content } from '../../components/Content'
import { LoadingContent } from '../../components/LoadingContent'
import { cookies } from '../../functions/Session'

export const Plans = () => 
{
    const [form, setForm] = useState<RegisteredFormProps>({
        cpf: '',
        birthday: ''
    })

    const [planSelected, setPlanSelected] = useState<PlansProps>({
        id: '',
        name: '',
        limit_people: '',
        limit_gb: '',
        price_with_discount: '',
        price: ''
    })

    const [codeTypePayment, setCodeTypePayment] = useState('')

    const [plans, setPlans] = useState<Array<PlansProps>>([])

    const [statusPayment, setStatusPayment] = useState({
        methods: false,
        finish_boleto: false,
        finish_credit_card: false,
        boleto_link: ''
    })

    const [userHash, setUserHash] = useState('')

    const [cardConfigs, setCardConfigs] = useState<CardProps>({
        card_number: '',
        validate_month: '',
        validate_year: '',
        card_name: '',
        card_security: ''
    })
    
    const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => 
    {
        e.preventDefault()

        if(codeTypePayment !== "2" && codeTypePayment !== "1")
        {
            Warning('Escolha uma forma de pagamento')
        }
        
        if(codeTypePayment === "2")
        {
            Loading()

            await PaymentAPI.boletoRegistered({plans_id: planSelected.id, cpf: form.cpf}, response => {
                if(cookies.get('user'))
                {
                    let old_user = cookies.get('user')
    
                    old_user.payment_status = 'OPEN'
    
                    cookies.set('user', old_user)
                }

                setStatusPayment({...statusPayment, finish_boleto: true, boleto_link: response.paymentLink})

                Success('Boleto gerado com sucesso! Enquanto não detectamos seu pagamento iremos liberar a plataforma para que possa já ir usando.')
            }, err => {
                if(err.status === 400)
                {
                    Warning(err.data.message)
                }else{
                    Error('Erro se persistir contacte um administrador.')
                }
            })
        }

        if(codeTypePayment === "1")
        {
            Loading()

            if(cardConfigs.card_number.length < 16 || cardConfigs.card_number.length > 19)
            {
                Warning('Numero do cartão inválido')
            }

            if(cardConfigs.card_security.length < 3 || cardConfigs.card_security.length > 3)
            {
                Warning('CVV do cartão inválido')
            }

            if(cardConfigs.validate_month.length < 2 || cardConfigs.validate_month.length > 2)
            {
                Warning('Mês da expiração do cartão inválido')
            }

            if(cardConfigs.validate_year.length < 4 || cardConfigs.validate_year.length > 4)
            {
                Warning('Ano da expiração do cartão inválido')
            }

            PaymentAPI.creditCardRegistered({plans_id: planSelected.id, cpf: form.cpf, card: cardConfigs, birthday: form.birthday}, response => {
                if(cookies.get('user'))
                {
                    let old_user = cookies.get('user')
    
                    old_user.payment_status = 'OPEN'
    
                    cookies.set('user', old_user)
                }
                
                Success('Pagamento em processamento enquanto processamos seu pagamento...Fique a vontade na plataforma!', () => window.location.href = '/plans')
            }, err => {
                if(err.status === 400)
                {
                    Warning(err.data.message)
                }else{
                    Error('Erro se persistir contacte um administrador.')
                }
            })
        }
    }
    
    const [stage, setStage] = useState('1')
    const [isConsultor, setIsConsultor] = useState(false)
    const [cupom, setCupom] = useState('')

    useEffect(() => {

        let data_user = cookies.get('user')

        if(data_user.consultor)
        {
            RegisterAPI.plans(setPlans, 'consultor')

            setIsConsultor(true)
        }else{
            RegisterAPI.plans(setPlans)
        }
        
    }, [])

    return (
        <>
            <Navbar/>
            <Content >
                <div className="content-box">
                <Form method="POST" className="" onSubmit={handleSubmit}>
                    {
                        stage === "2" ? (
                                <>
                                    <div style={{textAlign: 'center', marginBottom: '20px'}}>
                                        <h2>Para finalizarmos escolha o método de pagamento</h2>
                                    </div>
                                    <div className="grid-col-fixed-plans">
                                        <div className="plans my-plans">
                                            <p>Plano escolhido</p>
                                            <div className={"plan_box"}>
                                                <p className="title">{planSelected.name}</p>
                                                <hr/>
                                                <ul>
                                                    <li>Diagnóstico de maturidade de gestão</li>
                                                    <li>Treinamento 100% prático</li>
                                                    <li>Plataforma de gestão</li>
                                                    <li>Suporte personalizado</li>
                                                </ul>
                                                <hr/>
                                                <p className="obs">*Quantidade de usuários: <b>{planSelected.limit_people}</b></p>
                                                <br/>
                                                {
                                                    planSelected.price_with_discount === "0" ? (
                                                        <p className="price-with-promotion">{formatValue({value: planSelected.price, decimalScale: 2, intlConfig: { currency: 'BRL', locale: 'pt-BR'}})}</p>
                                                    ) : (
                                                        <>
                                                            <p className="price">de <span className="value">{formatValue({value: planSelected.price, decimalScale: 2, intlConfig: { currency: 'BRL', locale: 'pt-BR'}})}</span> por</p>
                                                            <p className="price-with-promotion"><span className="value">{formatValue({value: planSelected.price_with_discount, decimalScale: 2, intlConfig: { currency: 'BRL', locale: 'pt-BR'}})}</span> /mês</p>
                                                        </>
                                                    )
                                                }
                                                <button type="button" className="btn-next" onClick={async () => {
                                                    setStage('1')
                                                    setPlanSelected({
                                                        id: '',
                                                        name: '',
                                                        limit_people: '',
                                                        limit_gb: '',
                                                        price_with_discount: '',
                                                        price: ''
                                                    })
                    
                                                    setStatusPayment({
                                                        ...statusPayment,
                                                        finish_boleto: false
                                                    })
                                                }}>
                                                    Alterar
                                                </button>
                                            </div>
                                        </div>
                                        <div>
                                            {
                                                planSelected.id !== "" ? (
                                                    <>
                                                        <div className="box-input">
                                                            <p>Método de pagamento</p>
                                                            <select value={codeTypePayment} onChange={event => setCodeTypePayment(event.target.value)} required autoComplete="off" autoFocus={true}>
                                                                <option>Selecione</option>
                                                                <option value="2">Boleto</option>
                                                                <option value="1">Cartão de crédito</option>
                                                            </select>
                                                        </div>
                                                        {
                                                            codeTypePayment === "2" ?
                                                            (
                                                                <>
                                                                    <div className="box-input">
                                                                        <p>Digite seu CPF</p>
                                                                        <InputMask required mask={"999.999.999-999"} maskPlaceholder="" value={form.cpf || ""} onChange={value => setForm({...form, cpf: value.target.value}) }  />
                                                                    </div>  
                                                                    {
                                                                        statusPayment.finish_boleto ?
                                                                        (
                                                                            <div className="box-boleto">
                                                                                <p>Boleto gerado com sucesso</p>
                                                                                <a href={statusPayment.boleto_link} target="_blank" rel="noopener noreferrer">Clique aqui para pagar.</a>
                                                                            </div>
                                                                        ) : null
                                                                    }
                                                                </>
                                                                
                                                            ) : null
                                                        }
                                                        {
                                                            codeTypePayment === "1" ?
                                                            (
                                                                <>
                                                                    <div className="box-input">
                                                                        <p>Número do cartão</p>
                                                                        <InputMask placeholder="0000 0000 0000 0000" required mask={"9999 9999 9999 9999"} maskPlaceholder="" value={cardConfigs.card_number} onChange={async (event) => setCardConfigs({...cardConfigs, card_number: event.target.value})}  autoComplete="off" />
                                                                    </div>
                                                                    <div className="box-input">
                                                                        <p>Nome no cartão</p>
                                                                        <input id="card_name" type="text" value={cardConfigs.card_name} onChange={event => setCardConfigs({...cardConfigs, card_name: event.target.value})} required autoComplete="off" />
                                                                    </div>
                                                                    <div className="box-input">
                                                                        <p>Validate</p>
                                                                        <div className="row" style={{margin: 0}}>
                                                                            <InputMask style={{width: '100px', marginRight: '5px'}} placeholder="Mês" required mask={"99"} maskPlaceholder="" value={cardConfigs.validate_month} onChange={event => setCardConfigs({...cardConfigs, validate_month: event.target.value})}   />
                                                                            <InputMask style={{width: '100px', marginRight: '5px'}} placeholder="Ano" required mask={"9999"} maskPlaceholder="" value={cardConfigs.validate_year} onChange={event => setCardConfigs({...cardConfigs, validate_year: event.target.value})}  />
                                                                            <InputMask style={{width: '100px', marginRight: '5px'}} placeholder="CVV" required mask={"999"} maskPlaceholder="" value={cardConfigs.card_security} onChange={event => setCardConfigs({...cardConfigs, card_security: event.target.value})}  />
                                                                        </div>
                                                                    </div>
                                                                    <div className="box-input">
                                                                        <p>Digite seu CPF</p>
                                                                        <InputMask required mask={"999.999.999-999"} maskPlaceholder="" value={form.cpf || ""} onChange={value => setForm({...form, cpf: value.target.value}) }  />
                                                                    </div>
                                                                    <div className="box-input">
                                                                        <p>Digite sua data de nascimento</p>
                                                                        <input id="birthday" type="date" value={form.birthday}  onChange={event => setForm({...form, birthday: event.target.value})} required autoComplete="off" />
                                                                    </div>
                                                                </>
                                                            ) : null
                                                        }
                                                    </>
                                                ) : <LoadingContent />
                                            }
                                        </div>
                                    </div>
                                    <hr />
                                    <div className="flex between buttons">
                                        <button  type="button" onClick={() => {
                                            setStage('1')
                                        }}>
                                            <FaArrowLeft /> Voltar
                                        </button>
                                        <button>
                                            Finalizar
                                        </button>
                                    </div>
                                </>
                    ) : null }
                    {
                        stage === "1" ? (
                                <>
                                    <div style={{textAlign: 'center'}}>
                                        <h2>Escolha seu plano</h2>
                                        <p>
                                            Nosso plano foi criado pensando na praticidade que a Modum vai trazer para o dia a dia do seu negócio. Ao assinar, você terá acesso total dentro da plataforma e todas as suas funcionalidades, como documentos, treinamentos e suporte de um consultor por meio do nosso chat.
                
                                            Assine agora mesmo e garanta o acesso a métodos e técnicas que ajudarão sua empresa a crescer!
                                        </p>
                                        {
                                            isConsultor ? (
                                                <div className="plans-cupom">
                                                    <p>Cupom</p>
                                                    <div className="input">
                                                        <input  value={cupom} onChange={event => setCupom(event.target.value)} type="text" />
                                                        <button onClick={() => {
                                                            RegisterAPI.plans(setPlans, 'consultor', cupom);
                                                        }}>Aplicar cupum</button>
                                                    </div>
                                                </div>
                                            ) : null
                                        }
                                    </div>
                                    <div className="plans my-plans">
                                        {
                                            plans.map((item, index) => {
                                                let price = Number(item.price);
                
                                                                                return (
                                                    <div key={`${index}`} className="plan_box col-12 col-mg-3 col-lg-3">
                                                        <p className="title">{item.name}</p>
                                                        <hr/>
                                                        <ul>
                                                            <li>Diagnóstico de maturidade de gestão</li>
                                                            <li>Treinamento 100% prático</li>
                                                            <li>Plataforma de gestão</li>
                                                            <li>Suporte personalizado</li>
                                                        </ul>
                                                        <hr/>
                                                        <p className="obs">*Quantidade de {isConsultor ? 'empresas' : 'usuários'}: <b>{item.limit_people === "9999" ? 'Ilimitado' : item.limit_people}</b></p>
                                                        {!isConsultor ? <p className="obs">*7 dias gratuitos</p> : null} 
                                                        <br/>
                                                        {
                                                            item.price_with_discount === "0" ? (
                                                                <p className="price-with-promotion" style={{fontWeight: 600}}>{formatValue({value: price.toString(), decimalScale: 2, intlConfig: { currency: 'BRL', locale: 'pt-BR'}})}</p>
                                                            ) : (
                                                                <>
                                                                    <p className="price">de <span className="value">{formatValue({value: item.price, decimalScale: 2, intlConfig: { currency: 'BRL', locale: 'pt-BR'}})}</span> por</p>
                                                                    <p className="price-with-promotion"><span className="value">{formatValue({value: item.price_with_discount, decimalScale: 2, intlConfig: { currency: 'BRL', locale: 'pt-BR'}})}</span> /mês</p>
                                                                </>
                                                            )
                                                        }
                                                        <button type="button" className="btn-next" onClick={async () => {
                                                            setPlanSelected({...item})
                                                            setStage('2')
                                                        }}>
                                                            Contratar
                                                        </button>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                        ) : null
                    }
                </Form>
                </div>
            </Content>
        </>
    )
}