import React, { useEffect, useRef, useState } from 'react'
import { FaEnvelope, FaEye, FaEyeSlash, FaPhone, FaPlus } from 'react-icons/fa'
import { Content } from '../../components/Content'
import { ConfigModal, Modal } from '../../components/Modal'
import { Navbar } from '../../components/Navbar'
import Warning from '../../functions/Modals/Warning'
import { userId } from '../../functions/Session'
import { UserForm, UsersController } from '../../services/Users/UsersController'
import { FormUser } from './components/FormUser'

export const Users = () => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [users, setUsers] = useState<Array<UserForm>>([])
    const loading = useRef(true)
    const [viewDisabled, setViewDisabled] = useState(false)

    useEffect(() => {
        UsersController.getAll(setUsers)
        loading.current = false
    }, [])

    return (
        <div>
            <Modal {...modalConfig}/>
            <Navbar/>
            <Content loading={loading.current}>
                <div className="my-tasks-backlog">
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Meus usuários</h2>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                setViewDisabled(!viewDisabled)
                            }}>{viewDisabled ? <FaEyeSlash /> : <FaEye />} Usuários desabilitados</button>
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    title: (
                                        <>
                                            <h2>Criar usuário</h2>
                                        </>
                                    ),
                                    content: <FormUser />,
                                    onClose: () => setModalConfig({show: false})
                                })
                            }}><FaPlus/> Criar usuário</button>
                        </div>
                    </div>
                    <h3 className="title-divider">Lista de usuários</h3>
                    {
                        users.length > 0 ?
                        (
                            <>
                                {
                                    users.filter((user => user.id !== userId())).filter((user => viewDisabled ? true : user.is_disabled?.toString() ===  "1" ? true : false)).map((e, i) => (
                                        <div key={`${i}`} className="task-item-finish">
                                            <p className="label"><span className="span-link" style={{fontSize: '1em'}} onClick={() => {
                                            if(e.is_disabled?.toString() === "1")
                                            {
                                                setModalConfig({
                                                    show: true,
                                                    title: (
                                                        <>
                                                            <h2>Editar usuário</h2>
                                                        </>
                                                    ),
                                                    content: <FormUser id={e.id}/>,
                                                    onClose: () => setModalConfig({show: false})
                                                })
                                            }else{
                                                Warning('Usuário desativado.')
                                            } 
                                        }} >{e.name}</span></p>
                                            <p className="feedback">{e.email} <a href={`mailto:+${e.email}`}><FaEnvelope className="clr-theme"/></a></p>
                                            {e.phone ? <p className="date_finish">{e.phone} <a href={`tel:+${e.phone}`}><FaPhone className="clr-theme"/></a></p> : null}
                                        </div>
                                    ))
                                }
                            </>
                        ) : <h4>Você ainda não possui contatos</h4>
                    }
                </div>
            </Content>
        </div>
    )
}
