import React, { useEffect, useState } from 'react'
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import { formatMoneyString } from '../../../../functions/formatMoney';
import { DREDataTopicProps, DREYearDataExtends } from '../../../../services/Financeiro/DRE';

export interface PrevisaoOrcamentariaItemProps {
    budget_forecast: DREDataTopicProps,
    is_subtopic?: boolean
    is_topic?: boolean
    block_input?: boolean
    onChange: ((dre: DREDataTopicProps) => void)
}

export const PrevisaoOrcamentariaItem: React.FC<PrevisaoOrcamentariaItemProps> = (props) => 
{
    const { budget_forecast } = props;
    const [totalPreview, setTotalPreview] = useState(0)
    const [form, setForm] = useState<DREDataTopicProps>(budget_forecast)

    useEffect(() => {
        let calc_total_preview = 0;

        for (var [key, value] of Object.entries(budget_forecast.year)) {
            calc_total_preview = Number(calc_total_preview) + Number(value.preview);
        }

        setTotalPreview(calc_total_preview);
    }, [budget_forecast])

    const inputsConfigs : CurrencyInputProps = {
        decimalsLimit: 2,
        decimalSeparator: ",",
        decimalScale: 2,
        allowNegativeValue: true,
        intlConfig: {
            locale: 'pt-BR', 
            currency: 'BRL'
        }
    }

    const renderMonth = (index: number) => {
        switch (index) {
            case 0:
                return 'JANEIRO'
            case 1:
                return 'FEVEREIRO'
            case 2:
                return 'MARÇO'
            case 3:
                return 'ABRIL'
            case 4:
                return 'MAIO'
            case 5:
                return 'JUNHO'
            case 6:
                return 'JULHO'
            case 7:
                return 'AGOSTO'
            case 8:
                return 'SETEMBRO'
            case 9:
                return 'OUTUBRO'
            case 10:
                return 'NOVEMBRO'
            case 11:
                return 'DEZEMBRO'
        }
    }

    const renderTopicDetail = (item: DREYearDataExtends, index: number) => {
        return (
            <div className="topic-detail">
                <p>{renderMonth(index)}</p>
                <div className="values">
                    <div className="preview">
                        <CurrencyInput readOnly={props.block_input} {...inputsConfigs}  onValueChange={value => {
                            let old_year = form.year;

                            old_year[index] = {
                                preview: value ?? '',
                                realized: "0"
                            }

                            setForm({...form, year: old_year})
                        }} value={form.year[index].preview ?? form.year[index]} onKeyDown={event => {
                            if(event.key === "Enter" || event.key === "Tab")
                            {
                                let old_year = form.year;
                                let value = formatMoneyString(old_year[index].preview)

                                old_year[index].preview = value

                                props.onChange(form)
                            }
                        }} />
                        {
                            form.show_percentage ?
                            (
                                <div className="percentage">{form.year && form.year[index].preview_percentage ? `${form.year[index].preview_percentage}%` : "0 %"} </div>
                            ) : null
                        }
                    </div>
                </div>
            </div>
        )
    }

    return (
        <div className="topic">
            <div className={props.is_topic ? "topic-title is-topic" : "topic-title"}><span>{form.name}</span></div>
            {
                form.year.map((item, index) => {
                    return renderTopicDetail(item, index)
                })
            }
            <div className="topic-detail">
                <p>MÉDIA ACUMULADA MÊS</p>
                <div className="values">
                    <div className="preview">
                        <CurrencyInput disabled {...inputsConfigs} value={(totalPreview/12).toFixed(2)} onKeyDown={event => {
                            if(event.key === "Enter")
                            {
                                props.onChange(form)
                            }
                        }} />
                    </div>
                </div>
            </div>
            <div className="topic-detail">
                <p>TOTAL ACUMULADO</p>
                <div className="values">
                    <div className="preview">
                        <CurrencyInput disabled {...inputsConfigs} value={totalPreview} onKeyDown={event => {
                            if(event.key === "Enter")
                            {
                                props.onChange(form)
                            }
                        }} />
                    </div>
                </div>
            </div>
        </div>
    )
}