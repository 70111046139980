import React, { useEffect, useState } from 'react'
import { MdDelete, MdEdit } from "react-icons/md";
import Success from '../../../functions/Modals/Success';
import Error from '../../../functions/Modals/Error';
import { ControllerMyFeedbacks } from '../../../services/MyFeedbacks/ControllerMyFeedbacks';
import { ConfirmDelete } from '../../../functions/Modals/Confirm';
import { APP_CONFIG } from '../../../constants';

interface ViewFeedbackProps { 
    id: string
}

export const ViewFeedback: React.FC<ViewFeedbackProps> = (props) => 
{
    const [data, setData] = useState({
        feedback: '',
        id: '',
        path_attachment: ''
    })

    const [resposta, setResposta] = useState({
        content: '',
        status: false
    })

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => 
    {
        e.preventDefault()

        ControllerMyFeedbacks.update(data, s => Success('Feedback atualizado com sucesso! Nossa equipe logo enviara uma resposta.', () => window.location.reload()) , e => Error('Houve um erro se persistir contacte o administrador.'))
    }

    useEffect(() => {
        ControllerMyFeedbacks.getById(props.id, item => {
            setData({feedback: item.feedback, id: props.id, path_attachment: item.path_attachment})
            setResposta({content: item.response_feedback, status: item.status === "1" ? false : true})
        }, err => {})        
    }, [props.id])

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                <div className="box-input">
                    <label htmlFor="!#">Escreva abaixo</label>
                    <textarea style={{width: '100%', padding: '10px'}} className="styled" onChange={e => setData({...data, feedback: e.target.value})} value={data.feedback} required autoComplete="off" autoFocus={true}/>
                </div>
                <div className="box-input">
                    <p>Imagem anexada?</p>
                    <br/>
                    <img width="200" style={{borderRadius: '6px'}} src={`${APP_CONFIG.localhost}/attachments/${data.path_attachment}`} alt=""/>
                </div>
                <br/>
                {
                    resposta.status ? (
                        <>
                            <h2 style={{marginTop: '10px'}} className="title-divider">Resposta</h2>
                            <p>{resposta.content}</p>
                        </>
                    ) : null
                }
                <div className="flex">
                    <button type="submit"><MdEdit/> Atualizar feedback</button>
                    <button style={{marginLeft: '10px'}} type="button" onClick={e => {
                        ConfirmDelete(() => ControllerMyFeedbacks.delete(props.id, () => Success('Apagado com sucesso', () => window.location.reload()), err => {}), () => {})
                    }}><MdDelete/> Apagar</button>
                </div>
            </form>
        </>
    )
}
