import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { MdSubdirectoryArrowRight } from 'react-icons/md'
import { Link, useHistory, useParams } from 'react-router-dom'
import { Content } from '../../components/Content'
import { LinkBackHistory } from '../../components/LinkBackHistory'
import { Navbar } from '../../components/Navbar'
import { ControllerFAQ, FAQItem, FAQModule } from '../../services/FAQ/ControllerFAQ'
import { ListFaqs } from './components/ListFaqs'

export interface FAQRouteParams {
    module_id?: string
}

export const FAQ = () => 
{
    const [modules, setModules] = useState<Array<FAQModule>>([])
    const [faqs, setFaqs] = useState<Array<FAQItem>>([])
    
    const history = useHistory()
    const loading = useRef(true)
    const { module_id } = useParams<FAQRouteParams>()

    const [moduleName, setModuleName] = useState('')
    
    useEffect(() => {
        if(!module_id)
        {
            ControllerFAQ.getAllModules(setModules, err => {})
        }else{
            ControllerFAQ.getAll(module_id, setFaqs, err => {})
            ControllerFAQ.getModuleById(module_id, data => {
                setModuleName(data.name)
            }, err => {})
        }

        loading.current = false
    }, [module_id])

    return (
        <>
            <Navbar/>
            <Content loading={loading.current} title={
                <div className="title-button">
                    <div className="title">
                        {
                            !module_id ? <LinkBackHistory to="/" /> : <LinkBackHistory to="/faq" />
                        }
                        <h1 className="app-content-h1-destaque">FAQ {moduleName ? `: ${moduleName}` : null}</h1>
                    </div>
                    <div className="options">
                        <button onClick={() => {
                            history.push('/meus-feedbacks')
                        }}>Sugestões & Feedbacks</button>
                    </div>
                </div>
            }>
                <div className="content-box">
                    {
                        !module_id ? <p>Escolha por módulo</p> : <p>Acesse por tópico</p>
                    }
                    <br/>
                    {
                        !module_id ? (
                            modules.map((item, index) => {
                                return (
                                    <Link className="folder-item" to={`/faq/${item.id}`}>
                                        <MdSubdirectoryArrowRight/>
                                        <p>{item.name}</p>
                                    </Link>
                                )
                            })
                        ) : null
                    }
                    {
                        module_id ? <ListFaqs module_id={module_id} list={faqs} /> : null
                    }
                </div>
            </Content>  
        </>
    )
}
