import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'
import { useParams } from 'react-router-dom'
import Select from 'react-select'
import { LoadingContent } from '../../../../components/LoadingContent'
import { ConfirmDelete } from '../../../../functions/Modals/ConfirmDelete'
import { OrganogramaAPI, OrganogramaFormItemProps } from '../../../../services/Organograma/Organograma'

export interface FormOrganogramaProps {
    id?: string,
    onSuccess: (() => void)
}

export interface OrganogramaRouteProps {
    department: string
}

export const FormOrganograma: React.FC<FormOrganogramaProps> = (props) => 
{
    const [form, setForm] = useState<OrganogramaFormItemProps>({
        cargo: '',
        position: '',
        ref_id: ''
    })

    const { department } = useParams<OrganogramaRouteProps>()

    const [ref, setRef] = useState<any>({
        value: '',
        label: ''
    })

    const loading = useRef(true)

    const [list, setList] = useState<Array<OrganogramaFormItemProps>>([])
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        if(props.id)
        {
            OrganogramaAPI.update(props.id, {...form, ref_id: ref.value}, () => {
                setForm({
                    cargo: '',
                    position: '',
                    ref_id: ''
                })

                setRef({
                    value: '',
                    label: ''
                })

                OrganogramaAPI.consult(department, setList)

                props.onSuccess()
            })
        }else{
            OrganogramaAPI.save({...form, ref_id: ref.value, department_id: department}, () => {
                setForm({
                    cargo: '',
                    position: '',
                    ref_id: ''
                })

                setRef({
                    value: '',
                    label: ''
                })

                OrganogramaAPI.consult(department, setList)

                props.onSuccess()
            })
        }
    }

    useEffect(() => {
        let listTemp: Array<OrganogramaFormItemProps> = []

        OrganogramaAPI.consult(department, data => {
            setList(data)
            listTemp = data
        })

        if(props.id)
        {
            OrganogramaAPI.consultOne(props.id, data => {
                setForm(data)
                
                if(data.ref_id !== "0")
                {
                    let index = listTemp.filter(e => e.id === data.ref_id)[0]
                    
                    if(index)
                    {
                        setRef({value: data.ref_id, label: index.cargo})
                    }
                }
            })
        }

        loading.current = false
    }, [props.id, department])

    return (
        <>
            {
                !loading.current ?
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p>Cargo</p>
                            <input type="text" onChange={e => setForm({...form, cargo: e.target.value})} value={form.cargo} />
                        </div>
                        <div className="box-input">
                            <p>Posição</p>
                            <select onChange={e => setForm({...form, position: e.target.value})} value={form.position}>
                                <option>Selecione uma opção</option>
                                <option value="Estratégica">Estratégica</option>
                                <option value="Tática">Tática</option>
                                <option value="Operacional">Operacional</option>
                            </select>
                        </div>
                        <div className="box-input">
                            <p>Conecta com</p>
                            <Select value={ref} onChange={data => setRef(data)} options={list.map((e, i) => {
                                return {
                                    value: e.id,
                                    label: e.cargo
                                }
                            })} />
                        </div>
                        <div className="flex between">
                            <button type="submit"><MdAdd/> Salvar</button>
                            <button type="button" onClick={() => {
                                ConfirmDelete(() => {
                                    if(props.id)
                                    {
                                        OrganogramaAPI.delete(props.id, () => {
                                            window.location.reload()
                                        })
                                    }
                                }, () => null)
                            }}><MdDelete/> Apagar</button>
                        </div>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}