import React from 'react'

interface IValueQuestion {
    onChange: ((e: string) => void),
    value: any
}

export const ValueQuestion: React.FunctionComponent<IValueQuestion> = (props) => 
{
    return (
        <div className="select-value-question">
            <button className={`diagnostic-btn-option${props.value === "100" ? " active" : ""}`} onClick={e => props.onChange('100')} value="100">Sempre</button>
            <button className={`diagnostic-btn-option${props.value === "75" ? " active" : ""}`} onClick={e => props.onChange('75')} value="75">Com frequência</button>
            <button className={`diagnostic-btn-option${props.value === "50" ? " active" : ""}`} onClick={e => props.onChange('50')} value="50">De vez em quando</button>
            <button className={`diagnostic-btn-option${props.value === "25" ? " active" : ""}`} onClick={e => props.onChange('25')} value="25">Raramente</button>
            <button className={`diagnostic-btn-option${props.value === "0" ? " active" : ""}`} onClick={e => props.onChange('0')} value="0">Nunca</button>
            <button className={`diagnostic-btn-option${props.value === "remove" ? " active" : ""}`} onClick={e => props.onChange('remove')} value="remove">Não se aplica</button>
        </div>
    )
}
