import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { useParams } from 'react-router'
import { LoadingContent } from '../../../../../components/LoadingContent'
import Success from '../../../../../functions/Modals/Success'
import { PDCTemplate_API, PDCTemplate_FormProps } from '../../../../../services/ProccessFeedbackCustom/Template'

export interface FormTemplateProps {
    id?: string,
    onSuccess: (() => void)
}

export interface FormTemplateRouteParams {
    department: string
}

export const FormTemplate: React.FC<FormTemplateProps> = (props) => 
{
    const [form, setForm] = useState<PDCTemplate_FormProps>({
        name: '',
    })

    const [loading, setLoading] = useState(true)
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        if(props.id)
        {
            PDCTemplate_API.update(props.id, {...form}, s => Success('Salvo com sucesso!', async () => {
                props.onSuccess()
            }))
        }else{
            PDCTemplate_API.create({...form}, s => Success('Salvo com sucesso!', () => window.location.reload()))
        }
    }

    useEffect(() => {
        if(props.id)
        {
            PDCTemplate_API.getById(props.id, data => {
                setForm(data)
                setLoading(false)
            })
        }
    }, [props.id])

    return (
        <>
            {
                !props.id || !loading ?
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p>Nome</p>
                            <input type="text" onChange={e => setForm({...form, name: e.target.value})} value={form.name} />
                        </div>
                        <button type="submit"><MdAdd/> Salvar</button>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}