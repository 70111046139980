import React, { useEffect, useState } from 'react'
import { ConfigModal, ModalRight } from '../../../components/ModalRight'
import { useHistory, useParams } from 'react-router-dom'
import { Navbar } from '../../../components/Navbar'
import { Content } from '../../../components/Content'
import { ButtonBackHistory } from '../../../components/ButtonBackHistory'
import { StructureProps, auditoriaQuestions_api } from './services/auditoriaQuestions.services'
import { SubitemForm } from './components/SubitemForm'

export interface AuditoriaInternaRespostasProps {}

export const AuditoriaInternaRespostas: React.FC<AuditoriaInternaRespostasProps> = (props) => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({
        show: false
    })

    const [rows, setRows] = useState<StructureProps[]>([])
    const { id } = useParams<{ id: string }>()

    const history = useHistory()

    useEffect(() => {
        auditoriaQuestions_api.mount(id, (data) => {
            setRows(data)
        })
    }, [id])

    const Points = () => {
        let conforme = 0
        let nao_conforme = 0
        let opt_melhoria = 0
        let n_aplica = 0

        rows.forEach((item) => {
            item.subitens?.forEach((question) => {
                if(question.status == "1")
                {
                    conforme += 1
                }else if(question.status == "2")
                {
                    nao_conforme += 1
                }else if(question.status == "3") 
                {
                    opt_melhoria += 1
                }else if(question.status == "4") 
                {
                    n_aplica += 1
                }
            })
        })

        return (
            <div style={{display: 'inline-flex', color: '#fff', padding: '20px', borderRadius: '10px', marginTop: '10px', backgroundColor: '#124076'}}>
                <p style={{marginRight: '10px'}}>Conforme:  <b>{conforme}</b>,</p>
                <p style={{marginRight: '10px'}}>Não conforme: <b>{nao_conforme}</b>,</p>
                <p style={{marginRight: '10px'}}>Oport. melhoria: <b>{opt_melhoria}</b>,</p>                
                <p style={{marginRight: '10px'}}>N. Aplicável: <b>{n_aplica}</b></p>                
            </div>
        )
    }

    return (
        <>
            <ModalRight {...modalConfig} />
            <Navbar/>
            <Content title={(
                <div className='title-button'>
                    <div className="title-button">
                        <div className="title">
                            <ButtonBackHistory onClick={() => history.goBack() }/>
                            <h1 className="app-content-h1-destaque">Auditoria Interna: Respostas</h1>
                        </div>
                    </div>
                    <div className="options">
                        <button onClick={() => {
                            auditoriaQuestions_api.pdf(id, (file) => {
                                const href = URL.createObjectURL(file);

                                // create "a" HTML element with href to file & click
                                const link = document.createElement('a');
                                link.href = href;
                                link.setAttribute('download', 'auditoria_interna.pdf'); //or any other extension
                                document.body.appendChild(link);
                                link.click();
                            
                                // clean up "a" element & remove ObjectURL
                                document.body.removeChild(link);
                                URL.revokeObjectURL(href);
                            }, err => {
                                console.log(err)
                            })
                        }}>Visualizar em PDF</button>
                    </div>
                </div>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Responda os itens abaixo</h2>
                        </div>
                    </div>
                    <div style={{marginTop: '15px', position: 'relative'}}>
                        <div style={{position: 'absolute', background: '#387ADF',color: '#fff', fontWeight: 'bold', bottom: '70%', left: '10px', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1);', padding: '5px', borderRadius: '10px'}}>
                            <p>Pontuação Geral:</p>
                        </div>
                        <Points />
                    </div>
                    <br/>
                    {
                        rows.length > 0 ? rows.map((item) => {
                            return (
                                <div style={{marginBottom: '1rem'}}>
                                    <p><strong>{item.codigo}:</strong> {item.description}</p>
                                    <p><i>{item.obs}</i></p>
                                    <br />
                                    {
                                        item.subitens?.map((subitem) => {
                                            if(subitem.id)
                                                return <SubitemForm aiId={id} subItemId={subitem.id.toString()} item={subitem} onRefresh={() => {
                                                    auditoriaQuestions_api.mount(id, (data) => {
                                                        setRows(data)
                                                    })
                                                }} />

                                            return null;
                                        })
                                    }
                                </div>
                            )
                        }) : null
                    }
                </div>
            </Content>
        </>
    )
}