import { AxiosResponse } from "axios";
import Request from "../../functions/Request";

class Calendar
{
    async SaveFiles(
        id: string,
        files: FormData,
        onSuccess: ((e: AxiosResponse) => void),
        onError: ((e: AxiosResponse) => void)
    ) {
        return await Request.post(`/calendar/save-files/${id}`, files, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(onSuccess).catch(onError);
    } 

    async ConsultFiles(
        id: string,
        onSuccess: ((e: AxiosResponse) => void),
        onError: ((e: AxiosResponse) => void)
    ) {
        return await Request.get(`/calendar/get-files/${id}`).then(onSuccess).catch(onError);
    } 

    async DeleteFile(
        id: string,
        onSuccess: ((e: AxiosResponse) => void),
        onError: ((e: AxiosResponse) => void)
    ) {
        return await Request.delete(`/calendar/delete-file/${id}`).then(onSuccess).catch(onError);
    } 

}

export const ControllerCalendar = new Calendar()