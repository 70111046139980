import React, { useEffect, useState } from 'react'
import { MdAdd, MdDelete } from "react-icons/md";
import Select from 'react-select';
import { LoadingContent } from '../../../components/LoadingContent';
import Success from '../../../functions/Modals/Success';
import { AccountAPI } from '../../../services/Account/Account';
import { AccountProps, ConsultarMinhaConta } from '../../../services/Account/ConsultarMinhaConta';
import { UserForm, UsersController } from '../../../services/Users/UsersController';
import Loading from '../../../functions/Modals/Loading';

interface FormUserProps {
    id?: string
}

const permissions = [
    {
        value: 'results',
        label: 'Resultados'
    },
    {
        value: 'proccess',
        label: 'Processos'
    },
    {
        value: 'peoples',
        label: 'Pessoas'
    },
    {
        value: 'finance',
        label: 'Financeiro'
    },
    {
        value: 'users',
        label: 'Visualizar usuários'
    },
    {
        value: 'diagnostics',
        label: 'Diagnósticos'
    },
    {
        value: 'projects',
        label: 'Projetos/Unidades de negócios'
    },
    {
        value: 'departments',
        label: 'Departamentos'
    },
    {
        value: 'plans',
        label: 'Meus planos'
    },
    {
        value: 'contacts',
        label: 'Contatos'
    },
    {
        value: 'configs',
        label: 'Configurações'
    }
]

export const FormUser: React.FC<FormUserProps> = (props) => 
{
    const [data, setData] = useState<UserForm>({
        name: '',
        email: '',
        phone: '',
        permissions: []
    })

    const [isResponsible, setIsResponsible] = useState(false)
    const [account, setAccount] = useState<AccountProps>()
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if(props.id)
        {
            UsersController.getById(props.id, data => {

                let tmp_permissions : any = []

                data.permissions.forEach((item) => {
                    let find_label = permissions.findIndex((permission => permission.value === item.permission))
                    
                    tmp_permissions.push({
                        value: item.permission,
                        label: permissions[find_label].label
                    })
                    
                })

                setData({
                    ...data.user,
                    permissions: tmp_permissions
                })
            })
        }

        AccountAPI.isResponsible(data => {
            setIsResponsible(data.is_responsible)
        })

        ConsultarMinhaConta(e => setAccount(e.account), () => {})
    }, [props.id])

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        setLoading(true)
        
        if(props.id)
        {
            await UsersController.update({
                ...data,
                id: props.id
            }, () => Success('Usuário atualizado com sucesso.', () => {
                window.location.reload()
            }))
        }else{
            await UsersController.create(data, () => Success('Usuário criado com sucesso, senha enviada por e-mail ao mesmo.', () => {
                window.location.reload()
            }))
        }

        setLoading(false)
    }

    return (
        <>
            {
                !loading ? 
                (
                    <form className="form-styleds-create" style={{marginTop: '40px'}} method="post" onSubmit={onSubmit}>
                        <div className="input-box-v2 mg-top">
                            <label htmlFor="!#">Nome</label>
                            <input className="styled" type="text" onChange={e => setData({...data, name: e.target.value})} value={data.name} placeholder="Digite aqui o nome." required autoComplete="off" autoFocus={true}/>
                        </div>
                        <div className="input-box-v2 mg-top">
                            <label htmlFor="!#">Telefone</label>
                            <input className="styled" type="text" onChange={e => setData({...data, phone: e.target.value})} value={data.phone} placeholder="Digite aqui o telefone." required autoComplete="off" autoFocus={true}/>
                        </div>
                        <div className="input-box-v2 mg-top">
                            <label htmlFor="!#">E-mail</label>
                            <input className="styled" type="email" onChange={e => setData({...data, email: e.target.value})} value={data.email} placeholder="Digite aqui o email." required autoComplete="off" autoFocus={true}/>
                        </div>
                        {
                            isResponsible || account?.is_root === "YES" ? (
                                <div className="input-box-v2 mg-top">
                                    <label htmlFor="!#">Permissões de usuário</label>
                                    <Select placeholder="Escolha o que este usuário pode ver" options={permissions} isMulti value={data.permissions} onChange={(e: any) => setData({...data, permissions: e})} />
                                    <div style={{cursor: 'pointer', marginTop: '.5rem', fontSize: '0.8rem', fontWeight: 'bold'}} onClick={() => {
                                        setData({
                                            ...data,
                                            permissions: permissions.map(item => {
                                                return item
                                            })
                                        })
                                    }}>Configurar todas as opções, clique aqui!</div>
                                </div>
                            ) : null
                        }
                        {
                            isResponsible || account?.is_root === "YES" && props.id ? (
                                <div style={{cursor: 'pointer', marginTop: '.5rem', fontSize: '0.8rem', fontWeight: 'bold'}} onClick={() => {
                                    if(props.id)
                                    {   
                                        Loading()
                                        
                                        UsersController.resetPasswordById(props.id, data => {
                                            Success("Senha resetada com sucesso! Enviado no e-mail do cliente.")
                                        })
                                    }
                                }}>Resetar a senha, clique aqui!</div>
                            ) : null
                        }
                        <br /><br />
                        <div className="flex between">
                            <div>
                                <button className="btn-styled success" type="submit"><MdAdd/> Salvar</button>
                            </div>
                            {
                                props.id ? 
                                ( 
                                    <button className="btn-styled danger" type="button" onClick={() => {
                                        if(props.id)
                                        {
                                            UsersController.disabledClient({client_id: props.id}, response => {
                                                window.location.reload()
                                            })
                                        }
                                    }}><MdDelete/> Desativar</button>
                                ) : null
                            }
                        </div>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}
