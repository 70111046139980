import React, { useEffect, useState } from 'react'
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field';
import { calculatemeta } from '../../../../functions/calculateMeta';
import {  Topic } from '../../../../services/Financeiro/DRE'

export interface DREItemProps {
    dre: Topic
}

export const DREItem: React.FC<DREItemProps> = (props) => 
{
    const { dre } = props;
    const [totalPreview, setTotalPreview] = useState(0)
    const [totalRealized, setTotalRealized] = useState(0)

    useEffect(() => {
        let calc_total_preview = 0;
        let calc_total_realized = 0;

        dre.projects.map(item => {
            calc_total_preview = Number(calc_total_preview) + Number(item.entries.preview);
            calc_total_realized = Number(calc_total_realized) + Number(item.entries.realized);
        })

        setTotalPreview(calc_total_preview);
        setTotalRealized(calc_total_realized);
    }, [dre])

    const inputsConfigs : CurrencyInputProps = {
        decimalsLimit: 2,
        decimalSeparator: ",",
        decimalScale: 2,
        allowNegativeValue: false,
        intlConfig: {
            locale: 'pt-BR', 
            currency: 'BRL'
        }
    }

    return (
        <div className="topic">
            <div className="topic-title"><span>{dre.name}</span></div>
            {
                dre.projects.map((item, index) => {
                    return (
                        <>
                            <div className="topic-detail">
                                <p>{item.name} <span className="percentage">{calculatemeta(item.entries.preview, item.entries.realized)}%</span></p>
                                <div className="values">
                                    <div className="preview">
                                        <p>Previsto</p>
                                        <CurrencyInput {...inputsConfigs} value={item.entries.preview} />
                                        {
                                            dre.show_percentage === true ? (
                                                <div className="percentage">{item.entries.preview_percentage ? `${item.entries.preview_percentage}%` : "0 %"} </div>
                                            ) : null
                                        }
                                    </div>
                                    <div className="preview">
                                        <p>Realizado</p>
                                        <CurrencyInput {...inputsConfigs} value={item.entries.realized} />
                                        {
                                            dre.show_percentage === true ? (
                                                <div className="percentage">{item.entries.realized_percentage ? `${item.entries.realized_percentage}%` : "0 %"} </div>
                                            ) : null
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    )
                })
            }
            <div className="topic-detail">
                <p>TOTAL MÉDIA <span className="percentage">{calculatemeta((totalPreview/dre.projects.length), (totalRealized/dre.projects.length))}%</span></p>
                <div className="values">
                    <div className="preview">
                        <p>Previsto</p>
                        <CurrencyInput {...inputsConfigs} value={(totalPreview/12).toFixed(2)} />
                    </div>
                    <div className="preview">
                        <p>Realizado</p>
                        <CurrencyInput {...inputsConfigs} value={(totalRealized/12).toFixed(2)} />
                    </div>
                </div>
            </div>
            <div className="topic-detail">
                <p>TOTAL <span className="percentage">{calculatemeta(totalPreview, totalRealized)}%</span></p>
                <div className="values">
                    <div className="preview">
                        <p>Previsto</p>
                        <CurrencyInput {...inputsConfigs} value={totalPreview} />
                    </div>
                    <div className="preview">
                        <p>Realizado</p>
                        <CurrencyInput {...inputsConfigs} value={totalRealized} />
                    </div>
                </div>
            </div>
        </div>
    )
}