import React, { useEffect, useState } from 'react'
import { MdDeleteForever, MdEdit } from 'react-icons/md'
import { CanvasSubjectItemProps } from '../../../../services/PlanejamentoEstrategico/CanvasSubjects'

export interface CanvasSubjectProps extends CanvasSubjectItemProps {
    onClickEdit: (() => void),
    onClickDelete: (() => void)
}

export const CanvasSubject: React.FC<CanvasSubjectProps> = (props) => 
{

    useEffect(() => {

    }, [])

    return (
        <div className="canvas-subject">
            <div className="title">
                {props.title}
            </div>
            <div className="actions">
                <button className="update-button" onClick={props.onClickEdit}><MdEdit/></button>
                <button className="delete-button" onClick={props.onClickDelete}><MdDeleteForever/></button>
            </div>
        </div>
    )
}