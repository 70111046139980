import React from 'react'

interface IInput extends React.InputHTMLAttributes<HTMLInputElement> {
    label: string
}

export const Input: React.FunctionComponent<IInput> = (props) => 
{
    return (
        <div className="input-form">
            <label htmlFor={props.id}>{props.label}</label>
            <input {...props}/>
        </div>
    )
}
