import React from 'react'
import { useEffect } from 'react'
import { closeSession } from '../../functions/Session'

export const SignUp = () => {
    
    useEffect(() => {
        closeSession([
            {
                label: "user"
            },
            {
                label: "X-Request-Token"
            },
            {
                label: "X-User-Id"
            },
            {
                label: "X-Request-Type"
            }
        ])
        
        window.location.href = '/'
        
        return () => {
            
        }
    }, [])

    return (
        <>
            
        </>
    )
}
