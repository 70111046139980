import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";
import RequestWithoutLoading from "../../functions/RequestWithoutLoading";

const route = '/client/proccess'

export interface ProccessStageForm {
    id?: string
    description: string
    responsible: string
    sla: string
    proccess_id: string,
    micro_proccess_id: string,
    item_number?: string
}


export interface ProccessStageItem extends ProccessStageForm {
    id: string
}

class ProccessStage
{
    async create(
        data: ProccessStageForm,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}/stage`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async reorder(
        data: {
            list: Array<ProccessStageForm>
        },
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await RequestWithoutLoading.post(`${route}/stages/reorder`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        data: ProccessStageForm,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/stage`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: ProccessStageItem) => void),
    )
    {
        return await Request.get(`${route}/stage/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/stage/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        proccess_id: string,
        onSuccess: ((proccesses: Array<ProccessStageItem>) => void),
    )
    {
        return await Request.get(`${route}/stages/${proccess_id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }
}

export const ControllerProccessStage = new ProccessStage()