import { AxiosResponse } from "axios";
import Request from "../../functions/Request";

const route = '/client/manual-colaborador/final-consideration'

export interface ManualFinalConsiderationFormProps {
    final_consideration: string
    client_id?: string
    id?: string
}

class ManualFinalConsideration
{
    async save(form: ManualFinalConsiderationFormProps, success: ((response: AxiosResponse) => void))
    {
        return await Request.post(`${route}`, form).then(success);
    }

    async consult(success: ((response: ManualFinalConsiderationFormProps) => void))
    {
        return await Request.get(`${route}`).then(resp => success(resp.data.response));
    }
}

export const ManualFinalConsiderationAPI = new ManualFinalConsideration()