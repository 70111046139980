import React, { useEffect, useState } from 'react'
import { FaInfo, FaQuestion, FaVideo } from 'react-icons/fa'
import { MdArrowBack, MdArrowForward, MdCheck } from 'react-icons/md'
import { Content } from '../../components/Content'
import { Navbar } from '../../components/Navbar'
import { NextModule, SaveHistory, StartedHistory } from '../../services/Tutorial/SaveHistory'
import { TutorialInformationItemProps, TutorialQuizzItemProps, TutorialStageItemProps, TutorialVideoItemProps } from '../../services/Tutorial/TutorialModule'
import { Markup } from 'interweave';
import { useHistory, useParams } from 'react-router-dom'
import { StageQuizz } from './components/StageQuizz'
import { StageInformation } from './components/StageInformation'
import { StageVideo } from './components/StageVideo'
import { MountTutorialByModule } from '../../services/Tutorial/MountTutorialByModule'
import { NotifySystemAPI } from '../../services/NotifySystem/NotifySystem'
import { PracticeNotifyAPI } from '../../services/Notify/PracticeNotify'
import { LinkBackHistory } from '../../components/LinkBackHistory'
import ModalStore from '../../stores/ModalStore'
import { ConfigModal, Modal } from '../../components/Modal'
import { NPS } from './components/NPS'
import { LoadingContent } from '../../components/LoadingContent'
import Success from '../../functions/Modals/Success'
import { cookies } from '../../functions/Session'
import Warning from '../../functions/Modals/Warning'

export interface ModuleStagesRouteParams {
    id: string
    task?: string
}

export const ModuleStages = () => 
{
    const [, setStagesData] = useState<Array<TutorialStageItemProps>>([])
    const [stages, setStages] = useState<Array<TutorialStageItemProps>>([])
    const [finalizeModule, setFinalizeModule] = useState('')
    const [stageUser, setStageUser] = useState(0)
    const [loadingContent, setLoadingContent] = useState(true)

    const [quizzResponse, setQuizzResponse] = useState('')
    const [quizzIsCorrect, setquizzIsCorrect] = useState({
        isCorrect: '',
        answerId: ''
    })


    const { id, task } = useParams<ModuleStagesRouteParams>()

    useEffect(() => {
        MountTutorialByModule(id, module => {
            setFinalizeModule(module.description_finish)

            setStages(module.stages)
            setStagesData(module.stages)

            let stagesVerify = module.stages;

            for (var i = 0; i < stagesVerify.length; i++) 
            {
                if (!stagesVerify[i].realized && !task) {
                    setStageUser(i)
                    break;
                }
            }

            setLoadingContent(false)

            let user = cookies.get('user');

            user.actived = "2"

            cookies.set('user', user)
        }, () => {})

        if(task)
        {
            setStageUser(Number(task))
        }
    }, [id, task])

    

    const StageComponent = (props: {
        stage: TutorialStageItemProps, 
        index: number
    }) => {
        if(props.index === stageUser)
        {
            switch (props.stage.type) {
                case '1':
                    return <StageQuizz quizzResponse={quizzIsCorrect} onChange={e => {
                        setquizzIsCorrect(e)
                        
                        if(e.answerId === props.stage.quizz_response_id)
                        {
                            setQuizzResponse(e.answerId)
                        }
                    }} key={`${props.index}`} stage={props.stage}  />
                case '2':
                    return <StageInformation key={`${props.index}`} stage={props.stage}  />
                case '3':
                    let task_module = task ? task : stageUser.toString()

                    return <StageVideo historyJornada={{id, task: task_module}} store={ModalStore} key={`${props.index}`} stage={props.stage} />
            }
        }

        return <></>
    }

    const history = useHistory();

    const [modalConfig, setModalConfig] = useState<ConfigModal>({
        show: false
    })

    return (
        <>
            <Navbar/>
            <Modal {...modalConfig} />
            {
                loadingContent ?
                (
                    <LoadingContent />
                ) : (
                    <Content title={
                        <div className="title-button">
                            <div className="title">
                                <LinkBackHistory to='/jornada'/>
                                <h1 className="app-content-h1-destaque">Minha Jornada</h1>
                            </div>
                        </div>
                    }>
                        <div className="content-box black">
                            {
                                stages.map((e,i) => {
                                    return <StageComponent key={i} index={i} stage={e}/>
                                })
                            }
                            {
                                stages.length === 0 || stageUser >= stages.length ? (
                                    <Markup  content={finalizeModule} />
                                ) : null
                            }
                            <div className="central-controll">
                                {
                                    stages.length > 0 && stageUser < stages.length ?
                                    (
                                        <>
                                            {
                                                stageUser > 0 ?
                                                (
                                                    <>
                                                        <button onClick={() => {
                                                            
                                                            setStageUser(stageUser-1)
                                                        }} style={{marginLeft: 0, marginTop: '20px'}} className="button-controll"><MdArrowBack/> <span>Anterior</span></button>
                                                    </>
                                                ) : null
                                            }
                                        </>
                                    ) : null
                                }
                                {
                                    stages.length > 0 && stageUser !== stages.length-1 ?
                                    (
                                        <>
                                            <button onClick={async () => {
                                                let data: TutorialStageItemProps = stages[stageUser];
        
                                                if(data.realized !== true)
                                                {
                                                    await NotifySystemAPI.save({information: `Concluiu na Jornada: ${data.stage.title}`})
                                                }else{
                                                    await NotifySystemAPI.save({information: `Revendo na Jornada: ${data.stage.title}`})
                                                }

                                                let next_temp = stageUser+1;

                                                if(next_temp < stages.length)
                                                {
                                                    let next_data: TutorialStageItemProps = stages[stageUser];

                                                    if(next_data.practice === "2")
                                                    {
                                                        await StartedHistory({
                                                            practice_id: next_data.id,
                                                            type: 'practice'
                                                        }, response => {
                                                            
                                                        }, error => {
                
                                                        })
                                                    }
                                                }
        
                                                if(data.practice === "2")
                                                {
                                                    await PracticeNotifyAPI.save({practice_id: data.id})

                                                    
                                                }
        
                                                switch (data.type) 
                                                {
                                                    case '1':
                                                        let quizz_data: TutorialQuizzItemProps = data.stage;
        
                                                        SaveHistory({
                                                            data_id: quizz_data.id,
                                                            type: "1",
                                                            quizz_answer_id: quizzResponse
                                                        }, () => {
                                                            let old_stages = stages;
        
                                                            old_stages[stageUser].realized = true;
        
                                                            setStages([...old_stages])
        
                                                            setStageUser(stageUser+1)
                                                            setquizzIsCorrect({
                                                                isCorrect: '',
                                                                answerId: ''
                                                            })
                                                        }, () => {})
                                                        break;
                                                    case '2':
                                                        let information: TutorialInformationItemProps = data.stage;
        
                                                        SaveHistory({
                                                            data_id: information.id,
                                                            type: "2",
                                                        }, () => {
                                                            let old_stages = stages;
        
                                                            old_stages[stageUser].realized = true;
        
                                                            setStages([...old_stages])
        
                                                            setStageUser(stageUser+1)
                                                            setquizzIsCorrect({
                                                                isCorrect: '',
                                                                answerId: ''
                                                            })
                                                        }, () => {})
                                                        break;
                                                    case '3':
                                                        let video_data: TutorialVideoItemProps = data.stage;
        
                                                        SaveHistory({
                                                            data_id: video_data.id,
                                                            type: "3"
                                                        }, () => {
                                                            let old_stages = stages;
        
                                                            old_stages[stageUser].realized = true;
        
                                                            setStages([...old_stages])
        
                                                            setStageUser(stageUser+1)
                                                            setquizzIsCorrect({
                                                                isCorrect: '',
                                                                answerId: ''
                                                            })
                                                        }, () => {})
                                                        break;
                                                }
        
                                                
                                            }} style={{marginLeft: 0, marginTop: '20px'}} className="button-controll"><span>Próximo</span> <MdArrowForward/></button>
                                        </>
                                    ) : (
                                        <>
                                            <button onClick={() => {
                                                let data: TutorialStageItemProps = stages[stageUser];
            
                                                switch (data.type) 
                                                {
                                                    case '1':
                                                        let quizz_data: TutorialQuizzItemProps = data.stage;
        
                                                        SaveHistory({
                                                            data_id: quizz_data.id,
                                                            type: "1",
                                                            quizz_answer_id: quizzResponse
                                                        }, () => {}, () => {})
                                                        break;
                                                    case '2':
                                                        let information: TutorialInformationItemProps = data.stage;
        
                                                        SaveHistory({
                                                            data_id: information.id,
                                                            type: "2",
                                                        }, () => {}, () => {})
                                                        break;
                                                    case '3':
                                                        let video_data: TutorialVideoItemProps = data.stage;
        
                                                        SaveHistory({
                                                            data_id: video_data.id,
                                                            type: "3"
                                                        }, () => {}, () => {})
                                                        break;
                                                }

                                                let old_stages = stages;
        
                                                old_stages[stageUser].realized = true;

                                                setStages([...old_stages])

                                                for (var i = 0; i < old_stages.length; i++) 
                                                {
                                                    if (!old_stages[i].realized) {
                                                        Warning('Termine este modulo primeiro para depois avançar para o próximo')
                                                        return;
                                                    }
                                                }

                                                NextModule(id, next_module => {
                                                    if(next_module.module_id)
                                                    {
                                                        Success('Parabéns vamos ao próximo Módulo.', () => {
                                                            history.push(`/jornada/${next_module.module_id}`)
                                                        })
                                                    }else{
                                                        Warning('Termine este modulo primeiro.')
                                                    }
                                                }, err => {})
                                            }} style={{marginRight: '20px', marginTop: '20px'}} className="button-controll"><span><MdCheck/> Concluir</span></button>
                                            <button onClick={() => {
                                                let data: TutorialStageItemProps = stages[stageUser];
            
                                                setModalConfig({
                                                    content: <NPS module={id} onSuccess={() => setModalConfig({show: false})} />,
                                                    title: <h2>NPS</h2>,
                                                    onClose: () => setModalConfig({show: false}),
                                                    show: true
                                                })
                                            }} style={{marginLeft: 0, marginTop: '20px'}} className="button-controll bkg-theme clr-white"><span>Iniciar Pesquisa</span></button>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                        <div className="content-box">
                            <div className="title-button" style={{marginBottom: '20px'}}>
                                <div className="title">
                                    <h1 className="app-content-h1-destaque">Tarefas</h1>
                                </div>
                            </div>
                            {
                                stages.map((item,index) => {
                                    if(item.type !== "1")
                                    {
                                        return (
                                            <div className="tutorial-item" key={index}>
                                                <div className="title-with-button-options">
                                                    <div className="title link" onClick={() => {
                                                        if(item.realized)
                                                        {
                                                            setStageUser(index)

                                                            // document.querySelector('.app-content')?.scrollTo(0,0)
                                                        }
                                                    }}>
                                                        {
                                                            item.type === '3' ?
                                                            (
                                                                <FaVideo/>
                                                            ) : null
                                                        }
                                                        {
                                                            item.type === '2' ?
                                                            (
                                                                <FaInfo/>
                                                            ) : null
                                                        }
                                                        {
                                                            item.type === '1' ?
                                                            (
                                                                <FaQuestion/>
                                                            ) : null
                                                        }
                                                        {item.stage.title}
                                                    </div>
                                                    <div className="options">
                                                        {
                                                            item.realized ? <MdCheck color={"green"}/> : null
                                                        }
                                                    </div>
                                                </div>
                                                
                                            </div>
                                        )
                                    }else{
                                        return null;
                                    }
                                })
                            }
                        </div>
                    </Content> 
                )
            }  
        </>
    )
}
