import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { useToasts } from 'react-toast-notifications'
import { ProtocolFolderAPI, ProtocolFolderFormProps } from '../../../services/V2/ProtocolFolder'

export interface CreateFolderProps {
    id?: string
    onChange?: (() => void)
}

export const CreateFolder: React.FC<CreateFolderProps> = (props) => 
{
    const [form, setForm] = useState<ProtocolFolderFormProps>({
        name: ''
    })

    useEffect(() => {
        if(props.id)
        {
            ProtocolFolderAPI.getById(props.id, data => {
                setForm({
                    name: data.name
                })
            })
        }
    }, [props.id])

    const { addToast } = useToasts()

    const onSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        
        if(props.id)
        {
            ProtocolFolderAPI.update(props.id, form, response => {
                addToast('Pasta atualizada com sucesso!', { appearance: 'success', autoDismiss: true }, () => {
                    if(props.onChange)
                    {
                        props.onChange()
                    }
                })
            })
        }else{
            ProtocolFolderAPI.create(form, response => {
                addToast('Pasta criada com sucesso!', { appearance: 'success', autoDismiss: true }, () => {
                    if(props.onChange)
                    {
                        props.onChange()
                    }
                })
            })
        }
    }

    return (
        <>
            <form method="post" style={{marginTop: '30px'}} onSubmit={onSubmit}>
                <div className="input-box-v2">
                    <label htmlFor="!#">Nome da pasta</label>
                    <input className="styled" type="text" onChange={e => setForm({...form, name: e.target.value})} value={form.name} placeholder="Descreva a tarefa" required autoComplete="off" autoFocus={true}/>
                </div>
                <button className="btn-styled success" style={{marginTop: '30px'}} type="submit"><MdAdd/> Salvar</button>
            </form>
        </>
    )
}