import React, { useEffect, useState, useRef } from 'react'
import { MdAdd } from 'react-icons/md'
import { EditorHTML } from '../../../../components/EditorHTML'
import { LoadingContent } from '../../../../components/LoadingContent'
import { RevisionDocuments } from '../../../../components/RevisionDocuments'
import Success from '../../../../functions/Modals/Success'
import { CargosAPI, DescriptionItemFormProps } from '../../../../services/Cargos/Cargos'
import { UserForm, UsersController } from '../../../../services/Users/UsersController'
import { format, parseISO } from 'date-fns'

export interface FormDescriptionDescriptionProps {
    id?: string,
    department: string
    onSuccess?: (() => void)
}

export const FormDescriptionDescription: React.FC<FormDescriptionDescriptionProps> = (props) => 
{
    const [form, setForm] = useState<DescriptionItemFormProps>({
        office: '',
        client_id: '',
        department_id: props.department,
        occupational_family: '',
        item: '',
        cargo_mission: '',
        detailed_assignments: '',
        basic_requirements_formation: '',
        performance_indicators: '',
        basic_requirements_experience: '',
        basic_requirements_behavioral_skills: '',
        basic_requirements_technical_skills: ''
    })

    const [occupational_family, setoccupational_family] = useState('')
    const [item, setitem] = useState('')
    const [cargo_mission, setcargo_mission] = useState('')
    const [detailed_assignments, setdetailed_assignments] = useState('')
    const [basic_requirements_formation, setbasic_requirements_formation] = useState('')
    const [performance_indicators, setperformance_indicators] = useState('')
    const [basic_requirements_experience, setbasic_requirements_experience] = useState('')
    const [basic_requirements_behavioral_skills, setbasic_requirements_behavioral_skills] = useState('')
    const [basic_requirements_technical_skills, setbasic_requirements_technical_skills] = useState('')
    const [users, setUsers] = useState<UserForm[]>([])
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()
        
        if(props.id)
        {
            CargosAPI.updateDescription(props.id, {
                ...form,
                occupational_family,
                item,
                cargo_mission,
                detailed_assignments,
                basic_requirements_formation,
                performance_indicators,
                basic_requirements_experience,
                basic_requirements_behavioral_skills,
                basic_requirements_technical_skills
            }, () => Success('Salvo com sucesso', () => window.location.reload()))
        }else{
            CargosAPI.saveDescription({
                ...form,
                occupational_family,
                item,
                cargo_mission,
                detailed_assignments,
                basic_requirements_formation,
                performance_indicators,
                basic_requirements_experience,
                basic_requirements_behavioral_skills,
                basic_requirements_technical_skills
            }, () => Success('Salvo com sucesso', () => window.location.reload()))
        }
    }

    const loading = useRef(true)
    
    useEffect(() => {
        loading.current = false
    })

    useEffect(() => {
        if(props.id)
        {
            CargosAPI.consultOneDescription(props.id, data => {
                console.log(data)
                setForm({
                    ...data,
                    elabored_date: data.elabored_date ? format(parseISO(data.elabored_date), 'yyyy-MM-dd') : "",
                    approved_user_id: data.approved_user_id?.toString()
                })
                
                setoccupational_family(data.occupational_family)
                setitem(data.item)
                setcargo_mission(data.cargo_mission)
                setdetailed_assignments(data.detailed_assignments)
                setbasic_requirements_formation(data.basic_requirements_formation)
                setperformance_indicators(data.performance_indicators)
                setbasic_requirements_experience(data.basic_requirements_experience)
                setbasic_requirements_behavioral_skills(data.basic_requirements_behavioral_skills)
                setbasic_requirements_technical_skills(data.basic_requirements_technical_skills)
                loading.current = false
            })
        }

        UsersController.getAll(setUsers)
    }, [props.id])

    return (
        <>
            {
                !loading.current || !props.id ? 
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p>Cargo</p>
                            <input type="text" onChange={e => setForm({...form, office: e.target.value})} value={form.office} />
                        </div>
                        <div className="box-input">
                            <p>Superior Imediato</p>
                            <input type="text" onChange={e => setForm({...form, immediate_superior: e.target.value})} value={form.immediate_superior} />
                        </div>
                        <div className="box-input">
                            <p>Família Ocupacional (CBO - Código Brasileiro de Ocupações)</p>
                            <EditorHTML defaultToolbar onChange={setoccupational_family} value={occupational_family} />
                        </div>
                        <div className="box-input">
                            <p>Item (CBO - Código Brasileiro de Ocupações)</p>
                            <EditorHTML defaultToolbar onChange={setitem} value={item} />
                        </div>
                        <div className="box-input">
                            <p>Missão do cargo</p>
                            <EditorHTML defaultToolbar onChange={setcargo_mission} value={cargo_mission} />
                        </div>
                        <div className="box-input">
                            <p>Atribuições detalhadas</p>
                            <EditorHTML defaultToolbar onChange={setdetailed_assignments} value={detailed_assignments} />
                        </div>
                        <h2 className="h2-separate">Requisitos básicos</h2>
                        <div className="box-input">
                            <p>Formação</p>
                            <EditorHTML defaultToolbar onChange={setbasic_requirements_formation} value={basic_requirements_formation} />
                        </div>
                        <div className="box-input">
                            <p>Experiência</p>
                            <EditorHTML defaultToolbar onChange={setbasic_requirements_experience} value={basic_requirements_experience} />
                        </div>
                        <div className="box-input">
                            <p>Competências Comportamentais</p>
                            <EditorHTML defaultToolbar onChange={setbasic_requirements_behavioral_skills} value={basic_requirements_behavioral_skills} />
                        </div>
                        <div className="box-input">
                            <p>Competências Técnicas</p>
                            <EditorHTML defaultToolbar onChange={setbasic_requirements_technical_skills} value={basic_requirements_technical_skills} />
                        </div>
                        <div className="box-input">
                            <p>Indicadores de desempenho</p>
                            <EditorHTML defaultToolbar onChange={setperformance_indicators} value={performance_indicators} />
                        </div>
                        <RevisionDocuments onChange={event => setForm({
                            ...form, 
                            revision_in: event.target.value
                        })} value={form.revision_in} />
                        <div className="box-input">
                            <p>Elaborado em</p>
                            <input type="date" value={form.elabored_date} onChange={event => setForm({...form, elabored_date: event.target.value})} />
                        </div>
                        <div className="box-input">
                            <p>Criado Por</p>
                            <select onChange={e => setForm({...form, created_user_id: e.target.value})} value={form.created_user_id}>
                                <option value="">Selecione uma opção</option>
                                {
                                    users.map((item) => {
                                        return <option value={item.id}>{item.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        <div className="box-input">
                            <p>Aprovado Por</p>
                            <select onChange={e => setForm({...form, approved_user_id: e.target.value})} value={form.approved_user_id}>
                                <option value="">Selecione uma opção</option>
                                {
                                    users.map((item) => {
                                        return <option value={item.id}>{item.name}</option>
                                    })
                                }
                            </select>
                        </div>
                        <button type="submit"><MdAdd/> Salvar</button>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}