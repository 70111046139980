import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/calendar/timesheet'

export interface getTimesheetProps {
    status?: false | string
}

export interface setTimesheetProps {
    status?: 'stopped' | 'started'
}

export interface TimesheetReportItemProps {
    month: string,
    timesheets: {
        total?: string
        label?: string
        hour?: string
        hour_finish?: string
        date?: string
        real_started?: string
        real_ended?: string
        tags_labels?: string
        client_id?: string
        client_name?: string
        calendar_id?: string
        tags?: {
            id: number,
            label: string,
            tag_id: number,
            client_id: number,
            color: string
        }[]
    }[]
}

export interface TimesheetFormProps {
    time_from?: string
    time_to?: string
    client_id?: string
    calendar_id?: string
    id?: string
}

class Timesheet 
{
    async change(
        calendar_id: string,
        onSuccess: ((success: setTimesheetProps) => void),
    )
    {
        return await Request.post(`${route}/${calendar_id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async get(
        calendar_id: string,
        onSuccess: ((success: getTimesheetProps) => void),
    )
    {
        return await Request.get(`${route}/${calendar_id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async report(
        onSuccess: ((success: TimesheetReportItemProps[]) => void),
    )
    {
        return await Request.get(`${route}-report`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async reportAll(
        onSuccess: ((success: {
            data: TimesheetReportItemProps[],
            users: any
        }) => void),
    )
    {
        return await Request.get(`${route}-report/all`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async getByTaskId(
        calendar_id: string,
        onSuccess: ((success: TimesheetFormProps[]) => void),
    )
    {
        return await Request.get(`${route}s/${calendar_id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async saveTimesheet(
        calendar_id: string,
        form: TimesheetFormProps,
        onSuccess: ((success: TimesheetFormProps[]) => void),
    )
    {
        return await Request.post(`/calendar/create-timesheet/${calendar_id}`, form).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async deleteTimesheet(
        timesheet_id: string,
        onSuccess: ((success: TimesheetFormProps[]) => void),
    )
    {
        return await Request.delete(`/calendar/timesheet/${timesheet_id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }
}

export const TimesheetAPI = new Timesheet()