import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaFilePdf, FaHistory } from 'react-icons/fa'
import { MdModeEdit, MdDeleteForever, MdPageview } from 'react-icons/md'
import { useHistory, useParams } from 'react-router-dom'
import { ButtonBackHistory } from '../../../components/ButtonBackHistory'
import { Content } from '../../../components/Content'
import { ConfigModal } from '../../../components/Modal'
import { ModalRight } from '../../../components/ModalRight'
import { Navbar } from '../../../components/Navbar'
import { NoWithContent } from '../../../components/NoWithContent'
import { APP_CONFIG } from '../../../constants'
import { formatDate } from '../../../functions/dataFormat'
import { ConfirmDelete } from '../../../functions/Modals/Confirm'
import { getUserToken } from '../../../functions/Session'
import { CargosAPI, DescriptionHistoryItemProps, DescriptionItemFormProps } from '../../../services/Cargos/Cargos'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../services/ClientDepartments/ClientDepartments'
import { FormDescriptionDescription } from './components/FormDescriptionDescription'

export interface DescriptionProps {}
export interface DescriptionRouteProps {
    id: string
}

export const DescriptionHistory: React.FC<DescriptionProps> = (props) => 
{
    const [list, setList] = useState<Array<DescriptionHistoryItemProps>>([])
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const { id } = useParams<DescriptionRouteProps>()
    const history = useHistory()
    const loading = useRef(true)
    const [department, setDepartment] = useState<FormClientDepartment>({})

    useEffect(() => {
        CargosAPI.consultDescriptionHistory(id, setList)
        ClientDepartmentsAPI.getById(id, setDepartment)

        loading.current = false
    }, [id])

    return (
        <>
            <ModalRight {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={
                <div className="title-button">
                    <div className="title">
                        <ButtonBackHistory onClick={() => {
                            history.push('/pessoas/cargos')
                        }} />
                        <h1 className="app-content-h1-destaque">Histórico</h1>
                    </div>
                </div>
            }>
                <div className="content-box">
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Histórico de atualizações</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        list.length > 0 ?
                        (
                            list.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title">#{item.id} Descrição atualizada em {formatDate(item.date_init)} - Atualizado por {item.user_created?.name}</div>
                                                    <div className="options">
                                                        <button className="update-button" onClick={() => {
                                                            setModalConfig({
                                                                show: true,
                                                                content: <FormDescriptionDescription id={item.id} />,
                                                                onClose: () => setModalConfig({
                                                                    show: false
                                                                }),
                                                                title: <h2>Visualizando histórico</h2>
                                                            })
                                                        }}><MdPageview /></button>
                                                        <button className="pdf-button" onClick={() => {
                                                            let token = getUserToken()

                                                            window.open(`${APP_CONFIG.localhost_v2}/users/statics/jobs-descriptions/descriptions/pdf-history/${item.id}?token=${token}`, '_blank');
                                                        }}><FaFilePdf/></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : <NoWithContent/>
                    }
                </div>
            </Content>  
        </>
    )
}