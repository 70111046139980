import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { ConfigModal, Modal } from '../../../components/Modal'
import { Navbar } from '../../../components/Navbar'
import { DashboardItem } from '../../../services/Indicators/Dashboard'
import { IndicatorsAPI } from '../../../services/Resultado/Indicators/Indicators'
import CurrencyInput, { formatValue } from 'react-currency-input-field';
import { ControllerIndicators } from '../../../services/Indicators/Indicators'
import { FaArrowLeft, FaArrowRight, FaChartLine } from 'react-icons/fa'
import { useToasts } from 'react-toast-notifications'
import Success from '../../../functions/Modals/Success'
import { formatCurrency, formatCurrencyBRL, formatCurrencyPreffix } from '../../../functions/dataFormat'
import { formatMoneyString } from '../../../functions/formatMoney'

export interface DashboardProps {}
export interface DashboardRouteProps {
    department_id: string
}

export const EasyDashboard: React.FC<DashboardProps> = (props) => 
{
    const [list, setList] = useState<Array<DashboardItem>>([])
    const { department_id } = useParams<DashboardRouteProps>()
    const [details, setDetails] = useState({ title: '', mission: ''})
    const [year, setYear] = useState(new Date().getFullYear())
    const toasts = useToasts()

    const [modalConfig] = useState<ConfigModal>({
        show: false
    })
    
    useEffect(() => {
        IndicatorsAPI.mountDashboard(year.toString(), department_id, setList)

        IndicatorsAPI.departemntDetails(department_id, data => {
            setDetails({
                title: data.department_title,
                mission: data.department_mission
            })
        })
    }, [department_id, year])

    const history = useHistory()
    const { addToast } = useToasts()

    const formatting = (value: string, unit: string, values?: string) =>
    {
        if(unit === "moeda")
        {
            return `${formatCurrencyBRL(value)}`
        }else if(unit === "porcentagem") {
            return `% ${formatCurrency(value)}`
        }

        return `${formatCurrency(value)}`
    }

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content title={
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to={`/indicadores/department/${department_id}`}/>
                            <h1 className="app-content-h1-destaque">Dashboard de Indicadores: {details.title} - ({year})</h1>
                        </div>
                        <div className="options">
                            <button className="bkg-theme clr-white" title="Voltar" onClick={() => {
                                setYear(year-1)
                            }}><FaArrowLeft/>Ano anterior</button>
                            <button className="bkg-theme clr-white" title="Avançar" onClick={() => {
                                setYear(year+1)
                            }}>Próximo ano <FaArrowRight/></button>
                        </div>
                    </div>
                </>
            }>
                <div className="content-box">
                    <h2 className="title-divider" style={{marginTop: '0px'}}>Missão</h2>
                    <p>{details.mission}</p>
                    <h2 className="title-divider">Dashboard</h2>
                    <div className="dashboard">
                        <div className="dashboard-content">
                            {
                                list.map((item,i) => {

                                    const onChangeListOfDate = (month: "01" | "02" | "03" | "04" | "05" | "06" | "07" | "08" | "09" | "10" | "11" | "12", value: string | undefined, index: number, type: 'last_year' | 'now_year' | 'meta') => {
                                        if(value)
                                        {
                                            let old_list = list;
                                
                                            old_list[index][type][month] = value
                                    
                                            setList([...old_list])
                                        }else{
                                            let old_list = list;
                                
                                            old_list[index][type][month] = "0"
                                    
                                            setList([...old_list])
                                        }
                                    }

                                    if(item.unit === "moeda" || item.unit === "porcentagem")
                                    {
                                        let total_last_year = 0;
                                        let total_now_year = 0;
                                        let total_meta_year = 0;

                                        for(let [key, value] of Object.entries(list[i]['last_year']))
                                        {
                                            total_last_year += Number(formatMoneyString(value));
                                        }

                                        for(let [key, value] of Object.entries(list[i]['now_year']))
                                        {
                                            total_now_year += Number(formatMoneyString(value));
                                        }

                                        for(let [key, value] of Object.entries(list[i]['meta']))
                                        {
                                            total_meta_year += Number(formatMoneyString(value));
                                        }

                                        let total_last_year_formatted = '';
                                        let total_now_year_formatted = '';

                                        if(item.unit === "moeda")
                                        {
                                            total_last_year_formatted = formatCurrencyBRL(total_last_year.toString())
                                            total_now_year_formatted = formatCurrencyBRL(total_now_year.toString())
                                        }

                                        const saveData = () => {
                                            ControllerIndicators.saveData({
                                                ...item,
                                                year: year.toString()
                                            }, () => {})
                                        }

                                        return (
                                            <div className="dashboard-group" key={`${item.id}`}>
                                                <div className="dashboard-item">
                                                    <div className="item-col destaque">
                                                        <span className="title">{item.title}</span>
                                                        <span className="btn-pop-up" onClick={() => {
                                                            ControllerIndicators.saveData({
                                                                ...item,
                                                                year: year.toString()
                                                            }, () => Success('Salvo com sucesso!'))
                                                        }}>Salvar</span>
                                                        <span className="btn-pop-up" onClick={() => {
                                                            history.push(`/indicadores/dashboard/${department_id}/graphic`, {
                                                                data: item,
                                                            })
                                                        }}>
                                                            Gerar gráfico
                                                        </span>
                                                    </div>
                                                    <div className="tags">
                                                        <div>Mês</div>
                                                        <div className="height-fixed">Ano anterior</div>
                                                        <div className="height-fixed">Ano atual</div>
                                                        <div>Lacuna</div>
                                                        <div className="height-fixed">Meta</div>
                                                    </div>
                                                    <div className="dashboard-item-data">
                                                        <div className="data-item-easy">
                                                            <div>Janeiro</div>
                                                            <div>Fevereiro</div>
                                                            <div>Março</div>
                                                            <div>Abril</div>
                                                            <div>Maio</div>
                                                            <div>Junho</div>
                                                            <div>Julho</div>
                                                            <div>Agosto</div>
                                                            <div>Setembro</div>
                                                            <div>Outubro</div>
                                                            <div>Novembro</div>
                                                            <div>Dezembro</div>
                                                            <div>Total</div>
                                                            <div>Média</div>
                                                        </div>
                                                        <div className="data-item-easy">
                                                            <div><CurrencyInput value={list[i]['last_year']["01"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("01", value, i, 'last_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['last_year']["02"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("02", value, i, 'last_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['last_year']["03"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("03", value, i, 'last_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['last_year']["04"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("04", value, i, 'last_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['last_year']["05"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("05", value, i, 'last_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['last_year']["06"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("06", value, i, 'last_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['last_year']["07"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("07", value, i, 'last_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['last_year']["08"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("08", value, i, 'last_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['last_year']["09"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("09", value, i, 'last_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['last_year']["10"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("10", value, i, 'last_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['last_year']["11"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("11", value, i, 'last_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['last_year']["12"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("12", value, i, 'last_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div>
                                                                {
                                                                    item.unit === "moeda" ? total_last_year_formatted : formatValue({
                                                                        value: Number(total_last_year/12).toFixed(2).toString(), 
                                                                        intlConfig: { locale: 'pt-BR'},
                                                                        suffix: " %"
                                                                    })
                                                                }
                                                            </div> { /** total */ }
                                                            <div>{
                                                                item.unit === "moeda" ? formatValue({
                                                                    value: Number(total_last_year/12).toFixed(2).toString(), 
                                                                    intlConfig: { locale: 'pt-BR', currency: 'BRL' },
                                                                }) : formatValue({
                                                                    value: Number(total_last_year/12).toFixed(2).toString(), 
                                                                    intlConfig: { locale: 'pt-BR'},
                                                                    suffix: " %"
                                                                })
                                                            }</div> { /** media  */}
                                                        </div>
                                                        <div className="data-item-easy">
                                                            <div><CurrencyInput value={list[i]['now_year']["01"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("01", value, i, 'now_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['now_year']["02"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("02", value, i, 'now_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['now_year']["03"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("03", value, i, 'now_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['now_year']["04"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("04", value, i, 'now_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['now_year']["05"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("05", value, i, 'now_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['now_year']["06"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("06", value, i, 'now_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['now_year']["07"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("07", value, i, 'now_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['now_year']["08"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("08", value, i, 'now_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['now_year']["09"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("09", value, i, 'now_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['now_year']["10"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("10", value, i, 'now_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['now_year']["11"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("11", value, i, 'now_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['now_year']["12"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("12", value, i, 'now_year')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div>
                                                                {
                                                                    item.unit === "moeda" ? total_now_year_formatted : formatValue({
                                                                        value: Number(total_now_year/12).toFixed(2).toString(), 
                                                                        intlConfig: { locale: 'pt-BR'},
                                                                        suffix: " %"
                                                                    })
                                                                }
                                                            </div> { /** total */ }
                                                            <div>{
                                                                item.unit === "moeda" ? formatValue({
                                                                    value: Number(total_now_year/12).toFixed(2).toString(), 
                                                                    intlConfig: { locale: 'pt-BR', currency: 'BRL' },
                                                                }) : formatValue({
                                                                    value: Number(total_now_year/12).toFixed(2).toString(), 
                                                                    intlConfig: { locale: 'pt-BR'},
                                                                    suffix: " %"
                                                                })
                                                            }</div> { /** media  */}
                                                        </div>
                                                        <div className="data-item-easy">
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["01"])) - Number(formatMoneyString(list[i]['meta']["01"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["02"])) - Number(formatMoneyString(list[i]['meta']["02"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["03"])) - Number(formatMoneyString(list[i]['meta']["03"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["04"])) - Number(formatMoneyString(list[i]['meta']["04"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["05"])) - Number(formatMoneyString(list[i]['meta']["05"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["06"])) - Number(formatMoneyString(list[i]['meta']["06"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["07"])) - Number(formatMoneyString(list[i]['meta']["07"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["08"])) - Number(formatMoneyString(list[i]['meta']["08"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["09"])) - Number(formatMoneyString(list[i]['meta']["09"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["10"])) - Number(formatMoneyString(list[i]['meta']["10"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["11"])) - Number(formatMoneyString(list[i]['meta']["11"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["12"])) - Number(formatMoneyString(list[i]['meta']["12"]))).toString(), item.unit)}</div>
                                                            <div>{formatCurrencyPreffix((total_now_year - total_meta_year).toString(), item.unit === "moeda" ? 'R$' : '%')}</div>
                                                            <div>{formatCurrencyPreffix((total_now_year - total_meta_year/12).toFixed(2).toString(), item.unit === "moeda" ? 'R$' : '%')}</div>
                                                        </div>
                                                        <div className="data-item-easy">
                                                            <div><CurrencyInput value={list[i]['meta']["01"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("01", value, i, 'meta')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['meta']["02"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("02", value, i, 'meta')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['meta']["03"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("03", value, i, 'meta')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['meta']["04"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("04", value, i, 'meta')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['meta']["05"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("05", value, i, 'meta')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['meta']["06"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("06", value, i, 'meta')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['meta']["07"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("07", value, i, 'meta')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['meta']["08"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("08", value, i, 'meta')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['meta']["09"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("09", value, i, 'meta')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['meta']["10"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("10", value, i, 'meta')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['meta']["11"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("11", value, i, 'meta')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div><CurrencyInput value={list[i]['meta']["12"]} {...item.unit === "moeda" ? {prefix: "R$ "} : {}} {...item.unit === "porcentagem" ? {suffix: " %"} : {}}  onValueChange={(value, name) => {
                                                                onChangeListOfDate("12", value, i, 'meta')
                                                                setTimeout(saveData, 5000)
                                                            }} /></div>
                                                            <div>
                                                                {
                                                                    item.unit === "moeda" ?
                                                                    formatValue({
                                                                        value: (total_meta_year).toString(),
                                                                        intlConfig: { locale: 'pt-BR'},
                                                                        prefix: item.unit ==="moeda" ? "R$ " : ""
                                                                    }) : formatValue({
                                                                        value: (total_meta_year/12).toString(),
                                                                        intlConfig: { locale: 'pt-BR'},
                                                                        suffix: item.unit === "porcentagem" ? " %" : ""
                                                                    })
                                                                }
                                                            </div>
                                                            <div>
                                                                {
                                                                    formatValue({
                                                                        value: (total_meta_year/12).toString(),
                                                                        intlConfig: { locale: 'pt-BR'},
                                                                        prefix: item.unit ==="moeda" ? "R$ " : "",
                                                                        suffix: item.unit === "porcentagem" ? " %" : ""
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }else{

                                        let total_last_year = 0;
                                        let total_now_year = 0;
                                        let total_meta_year = 0;

                                        for(let [key, value] of Object.entries(list[i]['last_year']))
                                        {
                                            total_last_year += Number(value);
                                        }

                                        for(let [key, value] of Object.entries(list[i]['now_year']))
                                        {
                                            total_now_year += Number(value);
                                        }

                                        for(let [key, value] of Object.entries(list[i]['meta']))
                                        {
                                            total_meta_year += Number(value);
                                        }

                                        let total_last_year_formatted = '';
                                        let total_now_year_formatted = '';

                                        total_last_year_formatted = formatValue({
                                            value: total_last_year.toString(),
                                            intlConfig: { locale: 'pt-BR'},
                                        })

                                        total_now_year_formatted = formatValue({
                                            value: total_now_year.toString(),
                                            intlConfig: { locale: 'pt-BR'},
                                        })

                                        let unit_label = 'Reais'

                                        if(item.unit === "numero")
                                            unit_label = 'Número'
                                        if(item.unit === "porcentagem")
                                            unit_label = 'Porcentagem'

                                        const saveData = () => {
                                            ControllerIndicators.saveData({
                                                ...item,
                                                year: year.toString()
                                            }, () => {
                                                toasts.addToast('Auto salvamento efetuado', {
                                                    autoDismiss: true,
                                                })
                                            })
                                        }

                                        return (
                                            <div className="dashboard-group" key={`${item.id}`}>
                                                <div className="dashboard-item">
                                                    <div className="item-col destaque">
                                                        <span className="title">{item.title} - ({unit_label})</span> 
                                                        <span className="btn-pop-up" onClick={() => {
                                                            ControllerIndicators.saveData({
                                                                ...item,
                                                                year: year.toString()
                                                            }, () => Success('Salvo com sucesso!'))
                                                        }}>Salvar</span>
                                                        <span className="btn-pop-up" onClick={() => {
                                                            history.push(`/indicadores/dashboard/${department_id}/graphic`, {
                                                                data: item,
                                                            })
                                                        }}>
                                                            Gerar gráfico
                                                        </span>
                                                    </div>
                                                    <div className="tags">
                                                        <div>Mês</div>
                                                        <div className="height-fixed">Ano anterior</div>
                                                        <div className="height-fixed">Ano atual</div>
                                                        <div>Lacuna</div>
                                                        <div className="height-fixed">Meta</div>
                                                    </div>
                                                    <div className="dashboard-item-data">
                                                        <div className="data-item-easy">
                                                            <div>Janeiro</div>
                                                            <div>Fevereiro</div>
                                                            <div>Março</div>
                                                            <div>Abril</div>
                                                            <div>Maio</div>
                                                            <div>Junho</div>
                                                            <div>Julho</div>
                                                            <div>Agosto</div>
                                                            <div>Setembro</div>
                                                            <div>Outubro</div>
                                                            <div>Novembro</div>
                                                            <div>Dezembro</div>
                                                            <div>Total</div>
                                                            <div>Média</div>
                                                        </div>
                                                        <div className="data-item-easy">
                                                            <div><input type="text" onChange={e => onChangeListOfDate("01", e.target.value, i, 'last_year')} value={list[i]['last_year']["01"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("02", e.target.value, i, 'last_year')} value={list[i]['last_year']["02"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("03", e.target.value, i, 'last_year')} value={list[i]['last_year']["03"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("04", e.target.value, i, 'last_year')} value={list[i]['last_year']["04"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("05", e.target.value, i, 'last_year')} value={list[i]['last_year']["05"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("06", e.target.value, i, 'last_year')} value={list[i]['last_year']["06"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("07", e.target.value, i, 'last_year')} value={list[i]['last_year']["07"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("08", e.target.value, i, 'last_year')} value={list[i]['last_year']["08"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("09", e.target.value, i, 'last_year')} value={list[i]['last_year']["09"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("10", e.target.value, i, 'last_year')} value={list[i]['last_year']["10"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("11", e.target.value, i, 'last_year')} value={list[i]['last_year']["11"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("12", e.target.value, i, 'last_year')} value={list[i]['last_year']["12"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div>{total_last_year_formatted}</div> { /** total */ }
                                                            <div>
                                                                {
                                                                    formatValue({
                                                                        value: Number(total_last_year/12).toFixed(2).toString(),
                                                                        intlConfig: { locale: 'pt-BR'},
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="data-item-easy">
                                                            <div><input type="text" onChange={e => onChangeListOfDate("01", e.target.value, i, 'now_year')} value={list[i]['now_year']["01"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("02", e.target.value, i, 'now_year')} value={list[i]['now_year']["02"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("03", e.target.value, i, 'now_year')} value={list[i]['now_year']["03"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("04", e.target.value, i, 'now_year')} value={list[i]['now_year']["04"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("05", e.target.value, i, 'now_year')} value={list[i]['now_year']["05"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("06", e.target.value, i, 'now_year')} value={list[i]['now_year']["06"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("07", e.target.value, i, 'now_year')} value={list[i]['now_year']["07"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("08", e.target.value, i, 'now_year')} value={list[i]['now_year']["08"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("09", e.target.value, i, 'now_year')} value={list[i]['now_year']["09"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("10", e.target.value, i, 'now_year')} value={list[i]['now_year']["10"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("11", e.target.value, i, 'now_year')} value={list[i]['now_year']["11"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("12", e.target.value, i, 'now_year')} value={list[i]['now_year']["12"]} onBlur={() => setTimeout(saveData, 5000)} /></div>
                                                            <div>{total_now_year_formatted}</div> { /** total */ }
                                                            <div>
                                                                {
                                                                    formatValue({
                                                                        value: Number(total_last_year/12).toFixed(2).toString(),
                                                                        intlConfig: { locale: 'pt-BR'},
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="data-item-easy">
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["01"])) - Number(formatMoneyString(list[i]['meta']["01"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["02"])) - Number(formatMoneyString(list[i]['meta']["02"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["03"])) - Number(formatMoneyString(list[i]['meta']["03"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["04"])) - Number(formatMoneyString(list[i]['meta']["04"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["05"])) - Number(formatMoneyString(list[i]['meta']["05"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["06"])) - Number(formatMoneyString(list[i]['meta']["06"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["07"])) - Number(formatMoneyString(list[i]['meta']["07"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["08"])) - Number(formatMoneyString(list[i]['meta']["08"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["09"])) - Number(formatMoneyString(list[i]['meta']["09"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["10"])) - Number(formatMoneyString(list[i]['meta']["10"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["11"])) - Number(formatMoneyString(list[i]['meta']["11"]))).toString(), item.unit)}</div>
                                                            <div>{formatting((Number(formatMoneyString(list[i]['now_year']["12"])) - Number(formatMoneyString(list[i]['meta']["12"]))).toString(), item.unit)}</div>
                                                            <div>
                                                                {
                                                                    formatValue({
                                                                        value: (total_now_year - total_meta_year).toString(),
                                                                        intlConfig: { locale: 'pt-BR'},
                                                                    })
                                                                }
                                                            </div>
                                                            <div>
                                                                {
                                                                    formatValue({
                                                                        value: (total_now_year - total_meta_year/12).toFixed(2).toString(),
                                                                        intlConfig: { locale: 'pt-BR'},
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="data-item-easy">
                                                            <div><input type="text" onChange={e => onChangeListOfDate("01", e.target.value, i, 'meta')} value={list[i]['meta']["01"]} onBlur={() => setTimeout(() => saveData(), 5000)}/></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("02", e.target.value, i, 'meta')} value={list[i]['meta']["02"]} onBlur={() => setTimeout(() => saveData(), 5000)}/></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("03", e.target.value, i, 'meta')} value={list[i]['meta']["03"]} onBlur={() => setTimeout(() => saveData(), 5000)}/></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("04", e.target.value, i, 'meta')} value={list[i]['meta']["04"]} onBlur={() => setTimeout(() => saveData(), 5000)}/></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("05", e.target.value, i, 'meta')} value={list[i]['meta']["05"]} onBlur={() => setTimeout(() => saveData(), 5000)}/></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("06", e.target.value, i, 'meta')} value={list[i]['meta']["06"]} onBlur={() => setTimeout(() => saveData(), 5000)}/></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("07", e.target.value, i, 'meta')} value={list[i]['meta']["07"]} onBlur={() => setTimeout(() => saveData(), 5000)}/></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("08", e.target.value, i, 'meta')} value={list[i]['meta']["08"]} onBlur={() => setTimeout(() => saveData(), 5000)}/></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("09", e.target.value, i, 'meta')} value={list[i]['meta']["09"]} onBlur={() => setTimeout(() => saveData(), 5000)}/></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("10", e.target.value, i, 'meta')} value={list[i]['meta']["10"]} onBlur={() => setTimeout(() => saveData(), 5000)}/></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("11", e.target.value, i, 'meta')} value={list[i]['meta']["11"]} onBlur={() => setTimeout(() => saveData(), 5000)}/></div>
                                                            <div><input type="text" onChange={e => onChangeListOfDate("12", e.target.value, i, 'meta')} value={list[i]['meta']["12"]} onBlur={() => setTimeout(() => saveData(), 5000)}/></div>
                                                            <div>
                                                                {
                                                                    formatValue({
                                                                        value: (total_meta_year).toString(),
                                                                        intlConfig: { locale: 'pt-BR'},
                                                                    })
                                                                }
                                                            </div>
                                                            <div>
                                                                {
                                                                    formatValue({
                                                                        value: (total_meta_year/12).toString(),
                                                                        intlConfig: { locale: 'pt-BR'},
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </div>
                    </div>
                    
                </div>
            </Content> 
        </>
    )
}