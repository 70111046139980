export interface ISessionArgs {
    label: string
}

export const isPRODUCTION = false;

export const APP_CONFIG = {
    localhost_v2: process.env.REACT_APP_URL_SERVER,
    localhost: process.env.REACT_APP_localhost,
    files_root: process.env.REACT_APP_files_root,
    files_attachment_root: process.env.REACT_APP_files_root,
    files_attachment_micro_proccess: process.env.REACT_APP_files_attachment_micro_proccess,
    files_attachment_pop: process.env.REACT_APP_files_attachment_pop,
    server_chat: process.env.REACT_APP_server_chat,
    server: '',
    onServer: false,
    tokenHeaderName: 'X-Request-Token',
    sessionArgs: [
        {
            label: 'X-Request-Token'
        },
        {
            label: 'X-Request-Type'
        }
    ]
}
