import React, { useState } from 'react'
import { Form } from '../../components/Form'
import { RegisteredFormProps } from '../../services/Register/Register'
import InputMask from 'react-input-mask';
import { FaArrowLeft } from 'react-icons/fa'
import { Navbar } from '../../components/Navbar'
import { Content } from '../../components/Content'
import { useHistory, useParams } from 'react-router'

export interface PlanRenewRouteProps {
    plan_id: string
}

export const PlanRenew = () => 
{
    const [form, setForm] = useState<RegisteredFormProps>({
        cpf: '',
        birthday: ''
    })

    const [statusPayment, setStatusPayment] = useState({
        methods: false,
        finish_boleto: false,
        finish_credit_card: false,
        boleto_link: ''
    })

    const { plan_id } = useParams<PlanRenewRouteProps>()
    
    const handleSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => 
    {
        e.preventDefault()
    }

    const history = useHistory()

    return (
        <>
            <Navbar/>
            <Content >
                <div className="content-box">
                    <Form method="POST" className="" onSubmit={handleSubmit}>
                        <>
                            <div style={{textAlign: 'center', marginBottom: '20px'}}>
                                <h2>Preencha os dados abaixo para gerar o novo boleto</h2>
                            </div>
                            <div className="grid-col-fixed-plans">
                                
                                <div>
                                    {
                                        plan_id !== "" ? (
                                            <>
                                                <div className="box-input">
                                                    <p>Digite seu CPF</p>
                                                    <InputMask required mask={"999.999.999-999"} maskPlaceholder="" value={form.cpf || ""} onChange={value => setForm({...form, cpf: value.target.value}) }  />
                                                </div>  
                                                {
                                                    statusPayment.finish_boleto ?
                                                    (
                                                        <div className="box-boleto">
                                                            <p>Boleto gerado com sucesso</p>
                                                            <a href={statusPayment.boleto_link} target="_blank" rel="noopener noreferrer">Clique aqui para pagar.</a>
                                                        </div>
                                                    ) : null
                                                }
                                            </>
                                        ) : null
                                    }
                                </div>
                            </div>
                            <hr />
                            <div className="flex between buttons">
                                <button  type="button" onClick={() => {
                                    history.push('/plans')
                                }}>
                                    <FaArrowLeft /> Voltar
                                </button>
                                <button>
                                    Finalizar
                                </button>
                            </div>
                        </>
                    </Form>
                </div>
            </Content>
        </>
    )
}