import React, { useEffect, useState } from 'react'
import Success from '../../../functions/Modals/Success'
import { NPSAPI } from '../../../services/Tutorial/ControllerDepartmentProccess'

export interface NPSProps {
    module: string,
    onSuccess?: (() => void)
}

export const NPS: React.FC<NPSProps> = (props) => 
{
    const [form, setForm] = useState({
        nota: "",
        description: ""
    })

    const [points, setPoints] = useState<Array<string>>([])

    useEffect(() => {
        let generate = []

        for(let x = 0; x<11; x++)
        {
            generate.push(x.toString())
        }

        setPoints(generate)
    }, [])

    return (
        <>
            <form onSubmit={event => {
                event.preventDefault()

                NPSAPI.create({nps_points: form.nota, description: form.description, module: props.module}, () => Success('Muito obrigado pela sua avaliação!', () => {
                    if(props.onSuccess)
                    {
                        props.onSuccess()
                    }
                }))
            }} method="post" style={{marginTop: '20px'}}>
                <p>Em uma escala de 0 a 10, o quanto você recomendaria a um amigo ou colega de trabalho?</p>
                <br/>
                {
                    points.map((item, i) => <button type="button" className={form.nota === item ? "btn-nps-point checked" : "btn-nps-point"} onClick={() => setForm({...form, nota: item})}> {item} </button>)
                }
                <div className="box-input" style={{marginTop: '20px'}}>
                    <p>O que te motivou nesta nota?</p>
                    <textarea value={form.description} onChange={event => setForm({...form, description: event.target.value})}></textarea>
                </div>
                <button className="btn-submit">
                    Salvar
                </button>
            </form>
        </>
    )
}