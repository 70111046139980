import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";
import RequestWithoutLoading from "../../functions/RequestWithoutLoading";

const route = '/client/proccess'

export interface ProccessForm {
    id?: string,
    title: string,
    macroproccess_id: string,
}

export interface ProccessItem {
    id?: string,
    title: string,
    client_id: string,
    macroproccess_id: string,
    title_macroproccess?: string,
    department_id: string,
    item_number?: string
}

export interface ProccessSymbolItem {
    id: string
    title: string
    description: string
}



class Proccess 
{
    async create(
        data: ProccessForm,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async reorder(
        data: {
            list: Array<ProccessForm>
        },
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await RequestWithoutLoading.post(`${route}/reorder`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        data: ProccessForm,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: ProccessItem) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: ProccessItem) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async getAll(
        id: string,
        onSuccess: ((proccesses: Array<ProccessItem>) => void),
    )
    {
        return await Request.get(`${route}/macroproccess/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async getAllWithoutProccess(
        onSuccess: ((proccesses: Array<ProccessItem>) => void),
    )
    {
        return await Request.get(`${route}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async getSymbols(
        onSuccess: ((proccesses: Array<ProccessSymbolItem>) => void),
    )
    {
        return await Request.get(`${route}/symbols`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async getPopName(
        id: string,
        onSuccess: ((title: string) => void),
    )
    {
        return await Request.get(`${route}/pop-name/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }
}

export const ControllerProccess = new Proccess()