import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import CurrencyInput, { CurrencyInputProps } from 'react-currency-input-field'
import { FaArrowLeft, FaArrowRight, FaFileExport } from 'react-icons/fa'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { ConfigModal, Modal } from '../../../components/Modal'
import { Navbar } from '../../../components/Navbar'
import { APP_CONFIG } from '../../../constants'
import { cookies } from '../../../functions/Session'
import { DREAPI, DREConsolidadeItemProps } from '../../../services/Financeiro/DRE'
import { DREItem } from './components/DREItem'

export interface ConsolidadoProps {}

export const Consolidado: React.FC<ConsolidadoProps> = (props) => 
{
    const [modalConfig] = useState<ConfigModal>({show: false})
    const [topics, setTopics] = useState<DREConsolidadeItemProps[]>([])
    
    const loading = useRef(true)

    const [year, setYear] = useState(new Date().getFullYear())

    useEffect(() => {
        DREAPI.getAllConsolidada(year.toString(), setTopics)
        loading.current = false
    }, [])

    const onScrollCategoryItem = (event: any) => {
        let category_item = event.target;

        category_item.addEventListener('scroll', function() {

            let all_category_items = document.querySelectorAll('.category-item')

            if(all_category_items.length)
            {
                for (let index = 0; index < all_category_items.length; index++) {
                    const element = all_category_items[index];

                    element.scrollTo(category_item.scrollLeft, 0)
                }
            }
          
        });
    }

    const inputsConfigs : CurrencyInputProps = {
        decimalsLimit: 2,
        decimalSeparator: ",",
        decimalScale: 2,
        allowNegativeValue: false,
        intlConfig: {
            locale: 'pt-BR', 
            currency: 'BRL'
        }
    }

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/" />
                            <h1 className="app-content-h1-destaque">Financeiro: DRE (Consolidada) ({year})</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" onLoad={() => {
                    
                }} style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Entradas</h2>
                        </div>
                        <div className="options">
                            <button className="btn" onClick={() => {
                                setTopics([])

                                DREAPI.getAllConsolidada((year-1).toString(), setTopics)

                                setYear(year-1)
                            }}><FaArrowLeft /> Ano anterior</button>
                            <button className="btn" onClick={() => {
                                setTopics([])

                                DREAPI.getAllConsolidada((year+1).toString(), setTopics)

                                setYear(year+1)
                            }}>Próximo ano <FaArrowRight /></button>
                            <button className="btn" onClick={() => {
                                const token = cookies.get('X-Request-Token')

                                window.open(`${APP_CONFIG.localhost}/client/finance/dre/dre/consolidado/csv?token=${token}&year=${year}`);
                            }}><FaFileExport /> Exportar</button>
                        </div>
                    </div>
                    <br/>
                    {
                        topics.length > 0 ? (
                            <>
                                {
                                    topics.map((topic, topic_index) => {

                                        return (
                                            <div className="topic-group">
                                                <div className="topic-titles">
                                                    {/* <div className="topic-title category">{topic.name}</div> */}
                                                    {/* {
                                                        topic.topics.length > 0 ?
                                                        (
                                                            <>
                                                                {
                                                                    topic.topics.map((item, i) => {
                                                                        return <div className="topic-title" key={`${i}`}>{item.name}</div>
                                                                    })
                                                                }
                                                            </>
                                                        ) : null
                                                    }
                                                    {
                                                    topic.label_response && topic.group_id ? (
                                                    <div className="topic-title response">{topic.label_response}</div>
                                                    ) : null} */}
                                                </div>
                                                <div className="category-item" onScrollCapture={event => onScrollCategoryItem(event)}>
                                                    <div className="topic">
                                                        <div className="topic-title category">{topic.name}</div>

                                                        {
                                                            topic.project_result.map((project, project_index) => {
                                                                return (
                                                                    <>
                                                                        <div className="topic-detail">
                                                                            <p>{project.name}</p>
                                                                            <div className="values">
                                                                                <div className="preview">
                                                                                    <p>Previsto</p>
                                                                                    <CurrencyInput {...inputsConfigs} value={topic.project_result ? topic.project_result[project_index].entries.preview : 0} />
                                                                                    {
                                                                                        topic.show_percentage === true ? (
                                                                                            <div className="percentage">{topic.project_result && topic.project_result[project_index].entries.preview_percentage ? `${topic.project_result[project_index].entries.preview_percentage}%` : "0 %"} </div>
                                                                                        ) : null
                                                                                    }
                                                                                </div>
                                                                                <div className="preview">
                                                                                    <p>Realizado</p>
                                                                                    <CurrencyInput {...inputsConfigs} value={topic.project_result ? topic.project_result[project_index].entries.realized : 0} />
                                                                                    {
                                                                                        topic.show_percentage === true ? (
                                                                                            <div className="percentage">{topic.project_result && topic.project_result[project_index].entries.realized_percentage ? `${topic.project_result[project_index].entries.realized_percentage}%` : "0 %"} </div>
                                                                                        ) : null
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                )
                                                            }) 
                                                        }
                                                        <div className="topic-detail">
                                                            <p>TOTAL MÉDIA</p>
                                                            <div className="values">
                                                                <div className="preview">
                                                                    <p>Previsto</p>
                                                                    <CurrencyInput {...inputsConfigs} value={topic.total_group ? topic.total_group.media_preview : 0} />
                                                                </div>
                                                                <div className="preview">
                                                                    <p>Realizado</p>
                                                                    <CurrencyInput {...inputsConfigs} value={topic.total_group ? topic.total_group.realized : 0} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="topic-detail">
                                                            <p>TOTAL</p>
                                                            <div className="values">
                                                                <div className="preview">
                                                                    <p>Previsto</p>
                                                                    <CurrencyInput {...inputsConfigs} value={topic.total_group ? topic.total_group.preview : 0} />
                                                                </div>
                                                                <div className="preview">
                                                                    <p>Realizado</p>
                                                                    <CurrencyInput {...inputsConfigs} value={topic.total_group ? topic.total_group.realized : 0} />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        topic.topics.length > 0 ?
                                                        (
                                                            <>
                                                                {
                                                                    topic.topics.map((item, i) => {
                                                                        return <DREItem dre={item} key={`${i}`}  />
                                                                    })
                                                                }
                                                            </>
                                                        ) : null
                                                    }
                                                    {
                                                        topic.label_response && topic.group_id ? (
                                                            <div className="topic">
                                                                <div className="topic-title response">{topic.label_response}</div>
                                                                {
                                                                    topic.projects_result_final.map((project, project_index) => {
                                                                        return (
                                                                            <>
                                                                                <div className="topic-detail">
                                                                                    <p>{project.name}</p>
                                                                                    <div className="values">
                                                                                        <div className="preview">
                                                                                            <p>Previsto</p>
                                                                                            <CurrencyInput {...inputsConfigs} value={topic.project_result ? topic.project_result[project_index].entries.preview : 0} />
                                                                                            {
                                                                                                topic.show_percentage === true ? (
                                                                                                    <div className="percentage">{topic.project_result && topic.project_result[project_index].entries.preview_percentage ? `${topic.project_result[project_index].entries.preview_percentage}%` : "0 %"} </div>
                                                                                                ) : null
                                                                                            }
                                                                                        </div>
                                                                                        <div className="preview">
                                                                                            <p>Realizado</p>
                                                                                            <CurrencyInput {...inputsConfigs} value={topic.project_result ? topic.project_result[project_index].entries.realized : 0} />
                                                                                            {
                                                                                                topic.show_percentage === true ? (
                                                                                                    <div className="percentage">{topic.project_result && topic.project_result[project_index].entries.realized_percentage ? `${topic.project_result[project_index].entries.realized_percentage}%` : "0 %"} </div>
                                                                                                ) : null
                                                                                            }
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                                <div className="topic-detail">
                                                                    <p>TOTAL MÉDIA</p>
                                                                    <div className="values">
                                                                        <div className="preview">
                                                                            <p>Previsto</p>
                                                                            <CurrencyInput {...inputsConfigs} value={topic.total ? topic.total.media_preview : 0} />
                                                                        </div>
                                                                        <div className="preview">
                                                                            <p>Realizado</p>
                                                                            <CurrencyInput {...inputsConfigs} value={topic.total ? topic.total.media_realized : 0} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="topic-detail">
                                                                    <p>TOTAL</p>
                                                                    <div className="values">
                                                                        <div className="preview">
                                                                            <p>Previsto</p>
                                                                            <CurrencyInput {...inputsConfigs} value={topic.total ? topic.total.preview : 0} />
                                                                        </div>
                                                                        <div className="preview">
                                                                            <p>Realizado</p>
                                                                            <CurrencyInput {...inputsConfigs} value={topic.total ? topic.total.realized : 0} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </>
                        ) : null
                    }
                </div>
            </Content>
        </>
    )
}