import { AxiosResponse } from "axios";
import Request from "../../functions/Request";
import { FormProps } from "../../pages/Pessoas/Manual/components/FormManual";

const route = '/client/manual-colaborador'


class ManualColaborador
{
    async save(form: FormProps, success: ((response: AxiosResponse) => void))
    {
        return await Request.post(`${route}`, form).then(success);
    }

    async consult(success: ((response: FormProps) => void))
    {
        return await Request.get(`${route}`).then(resp => success(resp.data.response));
    }
}

export const ManualColaboradorAPI = new ManualColaborador()