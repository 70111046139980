import React, { useEffect, useState } from 'react'
import { MdAdd, MdDelete } from "react-icons/md";
import Select from 'react-select';
import { LoadingContent } from '../../../components/LoadingContent';
import Success from '../../../functions/Modals/Success';
import { AccountAPI } from '../../../services/Account/Account';
import { ContractItemProps, ContractsAPI } from '../../../services/Contracts/Contracts';
import { UserForm, UsersController } from '../../../services/Users/UsersController';

interface FormContractProps {
    id?: string
    contract?: ContractItemProps,
    onSuccess?: (() => void)
}

export const FormContract: React.FC<FormContractProps> = (props) => 
{
    const {
        contract
    } = props

    const [loading, setLoading] = useState(false)

    useEffect(() => {}, [])

    const onSubmit = async (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        if(props.id)
        {
            ContractsAPI.post(props.id, () => {
                Success('Aprovado')

                if(props.onSuccess)
                {
                    props.onSuccess()
                }
            })
        }
    }

    return (
        <>
            {
                !loading ? 
                (
                    <form className="form-styleds-create" style={{marginTop: '40px'}} method="post" onSubmit={onSubmit}>
                        <div className="contract" dangerouslySetInnerHTML={{__html: contract?.contract ?? ''}}></div>
                        <br />
                        {
                            props.contract?.status === "open" ? (
                                <div className="flex between">
                                    <button className="btn-styled success" type="submit">Aceitar</button>
                                </div>
                            ) : null
                        }
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}
