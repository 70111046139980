import { action, makeObservable, observable } from "mobx"

class ModalStore
{
    id = '';
    show = false;
    jornadaModule = '';
    jornadaTask = ''

    constructor() {
        makeObservable(this, {
            id: observable,
            show: observable,
            jornadaModule: observable,
            jornadaTask: observable,
            setShow: action,
            setId: action,
            setHistoryJornada: action
        })
    }

    setShow(show: boolean)
    {
        this.show = show;
    }

    setId(id: string)
    {
        this.id = id
    }

    setHistoryJornada(history: {
        id: string, task: string
    })
    {
        this.jornadaModule = history.id
        this.jornadaTask = history.task
    }
}

export default new ModalStore()