import React, { useEffect, useState } from 'react'
import { FaSignInAlt } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { Content } from '../../../components/Content'
import { ConfigModal, Modal } from '../../../components/Modal'
import { Navbar } from '../../../components/Navbar'
import { NoWithContent } from '../../../components/NoWithContent'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../services/ClientDepartments/ClientDepartments'
import { useRef } from 'react'

export interface ProccessProps {}

export const Proccess: React.FC<ProccessProps> = () => 
{
    const [modalConfig] = useState<ConfigModal>({show: false})
    const [listDepartmentProccess, setListDepartmentProccess] = useState<Array<FormClientDepartment>>([])

    const history = useHistory()
    const loading = useRef(true)

    useEffect(() => {
        ClientDepartmentsAPI.getAll(setListDepartmentProccess)

        loading.current = false
    }, [])
    
    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/" />
                            <h1 className="app-content-h1-destaque">Processos: Departamentos</h1>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                history.push('/all-pops')
                            }}>Todos os POP's</button>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2>Acesse por departamento</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        listDepartmentProccess.length > 0 ?
                        (
                            listDepartmentProccess.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link" onClick={() => {
                                                        history.push(`/department-proccess/${item.id}`, { department_title: item.name})
                                                    }}><FaSignInAlt/> {item.name}</div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : <NoWithContent/>
                    }
                </div>
            </Content>
        </>
    )
}