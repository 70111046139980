import Modal from "../Modal"

const Success = ($text: string, onClose?: any) => {
    Modal.fire({
        position: 'center',
        title: 'Feito!',
        text: $text,
        icon: 'success',
        showConfirmButton: false,
        onClose: onClose,
        timer: 1000
    })
}

export default Success