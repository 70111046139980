import React, { useEffect, useState } from 'react'
import { MdAdd, MdDelete } from 'react-icons/md'
import { EditorHTML } from '../../../../components/EditorHTML'
import { AuditoriaInternaItemProps, auditoria_api } from '../services/auditoria.services'
import Success from '../../../../functions/Modals/Success'
import { formatDate } from '../../../../functions/dataFormat'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../../services/ClientDepartments/ClientDepartments'

export interface FormPOPProps {
    id?: number,
    onSuccess?: (() => void)
}

export const FormAuditoriaInterna: React.FC<FormPOPProps> = (props) => 
{
    const onDelete = () => {
        if(props.id)
        {
            auditoria_api.delete(props.id.toString(), () => Success('Apagado com sucesso'))
        }
    }

    const onSubmit = () => {

        if(props.id)
        {
            auditoria_api.update(props.id.toString(), form, () => Success('Atualizado com sucesso', () => window.location.reload()))
        }else{
            auditoria_api.create(form, () => Success('Criado com sucesso!', () => window.location.reload()))
        }
    }

    const [form, setForm] = useState<AuditoriaInternaItemProps>({})

    useEffect(() => {
        if(props.id)
        {
            auditoria_api.getById(props.id.toString(),  data => {
                setForm({
                    ...data,
                })
            })
        }
    }, [props.id])

    const changeEvent = (event: React.ChangeEvent<HTMLSelectElement|HTMLInputElement>) => {
        setForm({
            ...form,
            ...{ [event.target.name] : event.target.value }
        })
    }

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={(event) => {
                event.preventDefault()

                onSubmit()
            }}>
                <div className="box-input">
                    <p>Título</p>
                    <input type="text" onChange={changeEvent} value={form.title} name="title" id="" />
                </div>
                <div className="box-input">
                    <p>Auditores</p>
                    <input type="text" onChange={changeEvent} value={form.auditores} name="auditores" id="" />
                </div>
                <div className="box-input">
                    <p>Processos auditados</p>
                    <input type="text" onChange={changeEvent} value={form.processos_auditados} name="processos_auditados" id="" />
                </div>
                <div className="box-input">
                    <p>Qual foi a Metodologia?</p>
                    <input type="text" onChange={changeEvent} value={form.metodologia} name="metodologia" id="" />
                </div>
                <div className="box-input">
                    <p>Próxima auditoria em?</p>
                    <input type="date" onChange={changeEvent} value={form.date_next_review} name="date_next_review" id="" />
                </div>
                <div className="flex between">
                    <button type="submit" style={{marginRight: '10px'}}><MdAdd/> Salvar</button>
                    {
                        props.id ? (
                            <button type="button" onClick={onDelete}><MdDelete/> Apagar</button>
                        ) : null
                    }
                </div>
            </form>
        </>
    )
}