import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../../functions/DefaultErrorResponse";
import Request from "../../../functions/Request";

const route = '/client/action-plan'

export interface PlanActionItemProps {
    id?: string
    problem?: string
    to_do?: string
    to_make?: string
    to_who?: string
    to_where?: string
    to_why_do?: string
    to_much?: string
    espected_start?: string
    expected_end?: string
    real_start?: string
    real_end?: string
    observation?: string
    client_id?: string
    company_id?: string
    department?: string
    department_id?: string
    status?: string
}

class PlanAction 
{
    async create(
        data: PlanActionItemProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        data: PlanActionItemProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/${id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((success: PlanActionItemProps[]) => void),
    )
    {
        return await Request.get(`${route}`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }

    async getAllFinish(
        onSuccess: ((success: PlanActionItemProps[]) => void),
    )
    {
        return await Request.get(`${route}/search/finish`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: PlanActionItemProps) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(e => onSuccess(e.data)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }
}

export const PlanActionAPI = new PlanAction()