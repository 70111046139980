import React, { useEffect, useState } from 'react'
import { FaCheck } from 'react-icons/fa'
import { Form } from '../../../components/Form'
import Warning from '../../../functions/Modals/Warning'
import { ConsultTagsColors, ConsultTagsColorsItem } from '../../../services/Tags/ConsultTagsColors'
import { CreateTag } from '../../../services/Tags/CreateTag'
import { ListTags } from './ListTags'

import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

export const MyTags = () => {
    
    const [listColors, setListColors] = useState<Array<ConsultTagsColorsItem>>([])
    const [showTags, setShowTags] = useState(false)

    useEffect(() => {
        ConsultTagsColors(s => setListColors(s.tags_colors), () => {})
    }, [])

    const [form, setForm] = useState({
        color_id: '',
        label: ''
    })
    

    return (
        <Form onSubmit={e => {
            e.preventDefault()

            if(!form.color_id || !form.label)
            {
                Warning('Preencha os campos acima.')
            }else{
                CreateTag(form, () => {
                    toast.success('Etiqueta criada com sucesso!')
                    setListColors([...listColors.filter(color => color.id !== form.color_id)])

                    setForm({
                        color_id: '',
                        label: ''
                    })
                }, () => {})
            }
        }}>
                {
                    listColors.length > 0 && showTags === false ?
                    (
                        <>
                            <h3 className="title-divider">Criar etiqueta</h3>
                            <div className="input-box">
                                <p>Título</p>
                                <input onChange={e => setForm({...form, label: e.target.value})} value={form.label} className="styled" style={{width: '100%'}} type="text" />
                            </div>
                            <div className="input-box">
                                <p>Escolha uma cor para representar?</p>
                                <div className="tags-colors">
                                    {
                                        listColors.map(e => {
                                            return (
                                                <div onClick={() => setForm({...form, color_id: e.id})} className="tag-color" style={{background: `${e.color}`}}>
                                                    {
                                                        e.id === form.color_id ? <FaCheck/> : null
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </>
                    ) : <ListTags onBack={() => {
                        setShowTags(false)
                        ConsultTagsColors(s => setListColors(s.tags_colors), () => {})
                    }}/>
                }
            <div className="options-button">
                {
                    listColors.length > 0 && showTags === false ?
                    (
                        <>
                            <button type="submit" className="input-box-button">
                                Criar etiqueta
                            </button>
                            <button onClick={() => setShowTags(true)} type="button" className="input-box-button">
                                Minhas etiquetas
                            </button>
                        </>
                    ) : null
                }
                
            </div>
            <ToastContainer pauseOnFocusLoss={false}  position="bottom-right" />
        </Form>
    )
}
