import React, { useEffect, useState } from 'react'
import CurrencyInput from 'react-currency-input-field'
import { MdAdd } from 'react-icons/md'
import { LoadingContent } from '../../../../components/LoadingContent'
import Success from '../../../../functions/Modals/Success'
import { InitialValueAPI, InitialValueFormProps } from '../../../../services/Financeiro/InitialValue'

export interface FormInitialValueProps {
    id?: string,
    onSuccess?: (() => void)
}

export const FormInitialValue: React.FC<FormInitialValueProps> = (props) => 
{
    const [form, setForm] = useState<InitialValueFormProps>({
        value: ""
    })

    const [loading, setLoading] = useState(true)

    useEffect(() => {
        InitialValueAPI.get(data => {
            if(data.value)
            {
                setForm({value: data.value})
            }
        })
    }, [props.id])
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        InitialValueAPI.update(form, () => Success('Definido com sucesso!', () => {
            if(props.onSuccess)
            {
                props.onSuccess()
            }
        }))
    }

    return (
        <>
            {
                !loading || !props.id ? 
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p>Valor</p>
                            <CurrencyInput intlConfig={{locale: 'pt-BR', currency: 'BRL'}} onValueChange={value => {
                                if(value)
                                {
                                    setForm({...form, value: value})
                                }
                            }} value={form.value} />
                        </div>
                        <button type="submit"><MdAdd/> Salvar</button>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}