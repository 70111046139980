import { AxiosResponse } from "axios"
import Request from "../../functions/Request"

export interface DataCloseTask {
    feedback: string
    label?: string
    calendar_id?: string
}

export const CloseTask = async (
    data: DataCloseTask,
    onSuccess: ((e: AxiosResponse) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.post('/calendar/close-task', data).then(onSuccess).catch(onError);
