import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";
import { getUserToken } from "../../functions/Session";

const route = '/client/pop'

export interface POPForm {
    id?: string
    client_id: string
    objective: string
    obs: string
    docs_ref: string
    checklist: string
    process_polarity_indicators: string
    result: string
    obs_error: string
    macro_title?: string
    macroproccess_id?: string
    macroproccess_title?: string
    microproccess_id?: string
    micro_title?: string
    elaborate_by: string
    date_revision?: string
    improvement_point?: string
    approved_user_id: string
    necessary_systems: string
    necessary_materials: string
    proccess_id: string
    date_create?: string
    index_item?: number
    title?: string
    files?: {
        id: number
        pop_id: number
        title: string
        path: string
    }[]
    department_id?: string
    department_title?: string
    url_attachment?: string
    not_created?: boolean
    approved_status?: '' | "open" | "approved" |  "refused"
}

export interface POPProccessProps {
    id: string
    title: string
    department_id: string
    client_id: string
}

export interface PopsStructureProps {
    id: string
    name: string
    sigla: string
    company_id: string
    pops: POPForm[]
}

export interface PopHistorySchemeProps {
    id: string
    pop_id: string
    data: POPForm
    date_registry: string
}

export interface PopListPdfProps {
    department: string,
    pops: string[]
}

class POP
{
    async create(
        data: POPForm,
        onSuccess: ((success: POPForm) => void),
    )
    {
        return await Request.post(`${route}`, data).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async update(
        data: POPForm,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getByProccess(
        id: string,
        onSuccess: ((success: POPForm) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        onSuccess: ((proccesses: Array<POPForm>) => void),
    )
    {
        return await Request.get(`${route}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async getAllPopsStructure(
        onSuccess: ((proccesses: PopsStructureProps[]) => void),
    )
    {
        return await Request.get(`${route}/pops-department`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async getAllHistoryPops(
        id: string,
        onSuccess: ((proccesses: PopHistorySchemeProps[]) => void),
    )
    {
        return await Request.get(`${route}/pops-history/${id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async getAllByProccess(
        proccess_id: string,
        onSuccess: ((proccesses: Array<POPForm>) => void),
    )
    {
        return await Request.get(`${route}/proccess/${proccess_id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }
    
    async getAllProccess(
        onSuccess: ((proccesses: Array<POPProccessProps>) => void),
    )
    {
        return await Request.get(`${route}/proccess`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async getAllPopsOpened(
        onSuccess: ((proccesses: Array<POPForm>) => void),
    )
    {
        return await Request.get(`${route}/authorize-pops`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async changeStatusPOP(
        form: {
            status: string,
            proccess_id: string
        },
        onSuccess: ((proccesses: Array<POPForm>) => void),
    )
    {
        return await Request.put(`${route}/authorize-pops`, form).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async generatePdfListPops(
        data: {
            list: PopListPdfProps[]
        },
        onSuccess: ((file: Blob) => void),
        onError: ((err: any) => void)
    )
    {
        return await Request.post(`${route}/generate-list-pops-pdf`, data, { responseType: 'blob'}).then(response => onSuccess(response.data)).catch(onError);
    }
}

export const POPController = new POP()