import { Markup } from 'interweave';
import { observer } from 'mobx-react';
import React from 'react'
import { FaCheck, FaWindowRestore } from 'react-icons/fa';
import { TutorialStageItemProps, TutorialVideoItemProps } from '../../../services/Tutorial/TutorialModule';
import ModalStore from '../../../stores/ModalStore';

export const StageVideo = observer((props: {
    stage: TutorialStageItemProps,
    store: typeof ModalStore,
    historyJornada: {
        id: string,
        task?: string
    }
}) => {

    let data: TutorialVideoItemProps = props.stage.stage;

    return (
        <div className="box-tutorial">
            <h2>{data.title} {props.stage.realized ? <FaCheck/> : null} </h2>
            <iframe title="Tutorial" id="ytplayer" typeof="text/html" width="100%" height="460" src={data.url} frameBorder="0"/>
            {props.stage.practice === "2" ? (
                <div className="resume-practice">
                <span className="btn-pop-up" onClick={() => { 
                    props.store.setId(props.stage.id)
                    props.store.setShow(true)

                    console.log(props.historyJornada)

                    if(props.historyJornada.task)
                    {
                        props.store.setHistoryJornada({
                            id: props.historyJornada.id,
                            task: props.historyJornada.task
                        })
                    }
                }}><FaWindowRestore/> Vizualizar em pop-up</span>
                {
                        props.stage.practice_information ?
                        (
                            <Markup content={props.stage.practice_information ? props.stage.practice_information : ''}/>
                        ) : null
                    }
                </div>
            ) : null}
        </div>
    )
})
