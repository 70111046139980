import { AxiosResponse } from "axios"
import Request from "../../functions/Request"

export interface FormCreateTag {
    label: string,
    color_id: string
}

export const CreateTag = async (
    data: FormCreateTag,
    onSuccess: ((e: AxiosResponse) => void),
    onError: ((e: AxiosResponse) => void)
) => await Request.post('/tags', data).then(onSuccess).catch(onError);
