import { AxiosResponse } from "axios";
import Request from "../../functions/Request";

const route = '/root/change-company'

export interface PermissionItemProps {
    id: string
    client_id: string
    permission: string
}

export interface UserInformations {
    id: string
    name: string
    phone: string
    cell_phone: string
}

class Account 
{
    async changeCompany(
        form: {
            company_id: string
        },
        onSuccess: ((success: AxiosResponse) => void),
        onError?: ((err: AxiosResponse) => void)
    )
    {
        return await Request.post(`${route}`, form).then(onSuccess).catch(err => onError ? onError(err) : null);
    }

    async getPermissions(
        onSuccess: ((success: AxiosResponse) => void),
        onError?: ((err: AxiosResponse) => void)
    )
    {
        return await Request.get(`/account/permission`).then(onSuccess).catch(err => onError ? onError(err) : null);
    }

    async isResponsible(
        onSuccess: ((success: {
            is_responsible: boolean
        }) => void),
        onError?: ((err: AxiosResponse) => void)
    )
    {
        return await Request.get(`/account/responsible`).then(response => onSuccess(response.data)).catch(err => onError ? onError(err) : null);
    }
}

export const AccountAPI = new Account()