import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../../functions/DefaultErrorResponse";
import Request from "../../../functions/Request";
import RequestWithoutLoading from "../../../functions/RequestWithoutLoading";

const route = '/client/indicators'
const routeDashboard = '/client/dashboard-indicators'

export interface IndicatorsItemProps {
    id: string
    title: string
    obs: string
    formula: string
    edit?: boolean
    unit: itemProps,
    polarity: itemProps,
    type: itemProps,
    indicator_id?: string
    department: {
        id: string,
        label: string
    },
    frequency: itemProps
    sequence?: number
}

interface itemProps {
    title: string
    id: string
}

export interface ImportIndicatorForm {
    indicator_id: string
    department_id: string
}

export interface DepartmentMissionFormProps {
    id?: string
    client_id?: string
    department_id: string
    mission: string
}

export interface DashboardItemProps {
    title: string,
    id: string,
    last_year: {
        "01": string,
        "02": string,
        "03": string,
        "04": string,
        "05": string,
        "06": string,
        "07": string,
        "08": string,
        "09": string,
        "10": string,
        "11": string,
        "12": string,
    },
    now_year: {
        "01": string,
        "02": string,
        "03": string,
        "04": string,
        "05": string,
        "06": string,
        "07": string,
        "08": string,
        "09": string,
        "10": string,
        "11": string,
        "12": string,
    },
    meta: {
        "01": string,
        "02": string,
        "03": string,
        "04": string,
        "05": string,
        "06": string,
        "07": string,
        "08": string,
        "09": string,
        "10": string,
        "11": string,
        "12": string,
    }
    unit: string
    polarity: string
}


export interface DashboardGraficItemProps {
    title: string,
    id: string,
    graphic: {
        month: string,
        last_year: string,
        now_year: string,
        meta: string
    }[] 
    unit: string
    polarity: string
}

class Indicators
{
    async getIndicatorsByDepartment(
        department_id: string,
        onSuccess: ((success: Array<IndicatorsItemProps>) => void),
    )
    {
        return await Request.get(`${route}/department/${department_id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async getMyIndicatorsByDepartment(
        department_id: string,
        onSuccess: ((success: Array<IndicatorsItemProps>) => void),
    )
    {
        return await Request.get(`${route}/my-indicators/deparment/${department_id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async importIndicator(
        data: ImportIndicatorForm,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}/save-my-indicator`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async deleteMyIndicator(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/my-indicator/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async mountDashboard(
        year: string,
        department_id: string,
        onSuccess: ((success: Array<DashboardItemProps>) => void),
    )
    {
        return await Request.get(`${routeDashboard}/department/${department_id}?year=${year}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async mountGraphicDashboard(
        year: string,
        department_id: string,
        onSuccess: ((success: Array<DashboardGraficItemProps>) => void),
    )
    {
        return await Request.get(`${routeDashboard}/department/graphic/${department_id}?year=${year}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }

    async reorder(form: {
        list: Array<IndicatorsItemProps>
    }, success: ((response: AxiosResponse) => void))
    {
        return await RequestWithoutLoading.put(`${route}/reorder`, form).then(success);
    }

    async departemntDetails(
        department_id: string,
        onSuccess: ((success: {
            department_title: string
            department_mission: string
        }) => void),
    )
    {
        return await Request.get(`${routeDashboard}/department-details/${department_id}`).then(response => onSuccess(response.data.response)).catch(DefaultErrorResponse);
    }
}

export const IndicatorsAPI = new Indicators()