import React, { useEffect, useState } from 'react'
import { FaFilePdf } from 'react-icons/fa'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { ButtonBackHistory } from '../../../components/ButtonBackHistory'
import { Content } from '../../../components/Content'
import { ConfigModal } from '../../../components/Modal'
import { ModalRight } from '../../../components/ModalRight'
import { Navbar } from '../../../components/Navbar'
import { APP_CONFIG } from '../../../constants'
import { POPController, POPForm } from '../../../services/POP/POPController'
import { ChangeStatusPOPForm } from './components/ChangeStatusPOPForm'

export interface AuthorizePopsProps {}

export const AuthorizePops: React.FC<AuthorizePopsProps> = () => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    
    const [listPops, setListPops] = useState<Array<POPForm>>([])

    useEffect(() => {
        POPController.getAllPopsOpened(setListPops)
    }, [])

    return (
        <>
            <ModalRight {...modalConfig} />
            <Navbar/>
            <Content title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <h1 className="app-content-h1-destaque">Procedimentos Operacionais Padrão em Aberto</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>POP's para serem aprovados</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        listPops.length > 0 ?
                        (
                            listPops.map((item) => 
                                {
                                    let nameLabel = null;

                                    if(item.date_create && item.title)
                                    {
                                        nameLabel = new Date(item.date_create).toLocaleDateString('pt-BR') + `# ${item.title} - ${item.macro_title} - ${item.micro_title}`;
                                    }else{
                                        nameLabel = `POP #${item.id}`
                                    }

                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link" onClick={() => {
                                                        setModalConfig({
                                                            show: true,
                                                            content: <ChangeStatusPOPForm onSuccess={() => window.location.reload()} id={item.id} proccess_id={item.proccess_id} />,
                                                            onClose: () => setModalConfig({
                                                                show: false
                                                            }),
                                                            title: <h2>Atualizar POP</h2>
                                                        })
                                                    }}>{nameLabel}</div>
                                                    <div className="options">
                                                        <button className="update-button" onClick={() => {
                                                            window.open(`${APP_CONFIG.localhost}/client/pop/pdf/${item.id}`, '_blank');
                                                        }}><FaFilePdf/></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) :
                        (
                            <h3>Sem informações para serem mostradas</h3>
                        )
                    }
                </div>
            </Content>
        </>
    )
}