import { AxiosResponse } from "axios";
import Request from "../../functions/Request";

const route = '/client-feedbacks'

export interface MyFeedbackItem {
    id: string
    feedback: string
    response_feedback: string
    client_id: string
    status: string
    date_create: string
    path_attachment: string
}

class MyFeedbacks
{
    async save(
        data: FormData,
        onSuccess: ((e: AxiosResponse) => void),
        onError: ((e: AxiosResponse) => void)
    )
    {
        await Request.post(route, data, {
            headers: {
                'content-type': 'multipart/form-data'
            }
        }).then(onSuccess).catch(onError);
    }

    async update(
        data: {
            feedback: string,
            id: string
        },
        onSuccess: ((e: AxiosResponse) => void),
        onError: ((e: AxiosResponse) => void)
    )
    {
        return await Request.put(`${route}`, data).then(onSuccess).catch(onError);
    }

    async getAll(
        onSuccess: ((e: Array<MyFeedbackItem>) => void),
        onError: ((e: AxiosResponse) => void)
    )
    {
      return await Request.get(`${route}`).then(e => onSuccess(e.data.feedbacks)).catch(onError);
    }

    async getById(
        id: string,
        onSuccess: ((e: MyFeedbackItem) => void),
        onError: ((e: AxiosResponse) => void)
    )
    {
        return await Request.get(`${route}/${id}`).then(e => onSuccess(e.data.feedback)).catch(onError);
    }

    async delete(
        id: string,
        onSuccess: ((e: AxiosResponse) => void),
        onError: ((e: AxiosResponse) => void)
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(onError);
    }
}

export const ControllerMyFeedbacks = new MyFeedbacks()