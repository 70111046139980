import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/finance/group'

export interface TopicFormProps {
    topic: string
    type: string
}

export interface GroupItemProps {
    id: string
    name: string
    type: string
    group_id: string
    sequence: string
}

class Group 
{
    async getAll(
        onSuccess: ((success: Array<GroupItemProps>) => void),
    )
    {
        return await Request.get(`${route}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: GroupItemProps) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }
}

export const GroupAPI = new Group()