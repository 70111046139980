import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import ServerRequest from "../../functions/ServerRequest";

const route = '/users/process-feedback/feedbacks'

export interface FeedbackFormProps {
    ausencias: string
    atrasos: string
    compromissos_desempenho: string
    capacidade_analise: string
    responsabilidade: string
    qualidade_produtividade: string
    habilidade_tecnica: string
    trabalho_equipe: string
    capacidade_decisao: string
    atendimento_ao_client: string
    atualizacao: string
    adm_con_trabalho: string
    comunicao: string
    melhorias: string
    _3cs_my: string
    _3cs_company: string
    collaborator_id: string
}

export interface FeedbackItemProps {
    ausencias: string
    atrasos: string
    compromissos_desempenho: string
    capacidade_analise: string
    responsabilidade: string
    qualidade_produtividade: string
    habilidade_tecnica: string
    trabalho_equipe: string
    capacidade_decisao: string
    atendimento_ao_client: string
    atualizacao: string
    adm_con_trabalho: string
    comunicao: string
    melhorias: string
    _3cs_my: string
    _3cs_company: string
    collaborator_id: string
    client_id: string
    date_create: string
    id: string
}

export interface FeedbackHistoryItemProps {
    id: number
    client_id: number,
    feedback_id: number,
    date_create: string,
    data_json: FeedbackFormProps 
}

class Feedback
{
    async create(
        collaborator_id: string,
        data: FeedbackFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.post(`${route}/${collaborator_id}`, data).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        collaborator_id: string,
        data: FeedbackFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.put(`${route}/${id}/${collaborator_id}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getById(
        id: string,
        onSuccess: ((success: FeedbackItemProps) => void),
    )
    {
        return await ServerRequest.get(`${route}/${id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await ServerRequest.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async getAll(
        collaborator_id: string,
        onSuccess: ((proccesses: Array<FeedbackItemProps>) => void),
    )
    {
        return await ServerRequest.get(`${route}/by-collaborator/${collaborator_id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async getHistory(
        id: string,
        onSuccess: ((proccesses: FeedbackHistoryItemProps[]) => void),
    )
    {
        return await ServerRequest.get(`${route}/history/${id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async getAllGraphic(
        collaborator_id: string,
        onSuccess: ((proccesses: Array<{
            label: string
            value: number
        }>) => void),
    )
    {
        return await ServerRequest.get(`${route}/by-collaborator/${collaborator_id}/graphic`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async getGraphic(
        collaborator_id: string,
        feedback_id: string,
        onSuccess: ((proccesses: Array<{
            label: string
            value: number
        }>) => void),
    )
    {
        return await ServerRequest.get(`${route}/by-collaborator/${collaborator_id}/graphic/${feedback_id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }
}

export const FeedbackAPI = new Feedback()