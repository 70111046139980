import { formatValue } from "react-currency-input-field";

const cnpj = (value: any) => {
    if(!value)
        return ''
    
    return value.replace(/([0-9]{2,3})([0-9]{3})([0-9]{3})([0-9]{4})([0-9]{2})/, '$1.$2.$3/$4-$5');
}

const celular = (value: any) => {
    if(!value)
        return ''
    
    return value.replace(/([0-9]{2})([0-9]{1})([0-9]{4})([0-9]{4})/, '($1) $2 $3-$4');
}

const telefone = (value: any) => {
    if(!value)
        return ''
        
    return value.replace(/([0-9]{2})([0-9]{4})([0-9]{4})/, '($1) $2-$3');
}

const cep = (value: any) => {
    if(!value)
        return ''
    
    return value.replace(/([0-9]{2})([0-9]{3})([0-9]{3})/, '$1$2-$3');
}

const cpf = (value: any) => {
    if(!value)
        return ''
    
    return value.replace(/([0-9]{3})([0-9]{3})([0-9]{3})([0-9]{2})/, '$1.$2.$3-$4');
}

const money = (money?: string) => {
    if(money)
    {
        return formatValue({
            intlConfig: {
                locale: 'pt-BR',
                currency: 'BRL'
            },
            value: money
        })
    }else{
        return;
    }
}

export {
    cep,
    celular,
    telefone,
    cnpj,
    cpf,
    money
}