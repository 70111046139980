import React, { useEffect, useState } from 'react'
import { MdAdd } from 'react-icons/md';
import { ControllerIndicators, ControllerIndicatorsFormProps, ControllerIndicatorsStructProps } from '../../../../services/Indicators/Indicators';
import Success from '../../../../functions/Modals/Success';

interface CreateIndicatorsProps {
    department_id: string
}

export const CreateIndicators: React.FC<CreateIndicatorsProps> = (props) => {
    
    const [form, setForm] = useState<ControllerIndicatorsFormProps>({})
    const [structure, setStructure] = useState<ControllerIndicatorsStructProps>({})

    useEffect(() => {
        ControllerIndicators.getStructure(e => setStructure(e.structure))

        setForm({
            department_id: props.department_id
        })
    }, [props.department_id])

    return (
        <>
            <form className="form-tasks-create" method="post" onSubmit={e => {
                e.preventDefault();

                ControllerIndicators.create(form, e => {
                    Success('Criado com sucesso!', () => window.location.reload())
                })
            }}>
                
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Titulo</label>
                    <input type="text" value={form.title}
                    onChange={e => {
                        setForm({...form, title: e.target.value})
                    }} className="styled"/>
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Para que serve</label>
                    <input type="text" value={form.obs}
                    onChange={e => {
                        setForm({...form, obs: e.target.value})
                    }} className="styled"/>
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Fórmula</label>
                    <input type="text" value={form.formula}
                    onChange={e => {
                        setForm({...form, formula: e.target.value})
                    }} className="styled"/>
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Tipo do indicador</label>
                    <select value={form.type_id}
                    onChange={e => {
                        setForm({...form, type_id: e.target.value})
                    }} className="styled">
                        <option value="">Selecione uma opção</option>
                        {
                            structure.types?.map(e => <option value={e.id}>{e.title}</option>)
                        }
                    </select>
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Medida</label>
                    <select value={form.unit_id}
                    onChange={e => {
                        setForm({...form, unit_id: e.target.value})
                    }} className="styled">
                        <option value="">Selecione uma opção</option>
                        {
                            structure.units?.map(e => <option value={e.id}>{e.title}</option>)
                        }
                    </select>
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Polaridade</label>
                    <select value={form.polarity_id}
                    onChange={e => {
                        setForm({...form, polarity_id: e.target.value})
                    }} className="styled">
                        <option value="">Selecione uma opção</option>
                        {
                            structure.polatiries?.map(e => <option value={e.id}>{e.title}</option>)
                        }
                    </select>
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Frequência</label>
                    <select value={form.frequency_id}
                    onChange={e => {
                        setForm({...form, frequency_id: e.target.value})
                    }} className="styled">
                        <option value="">Selecione uma opção</option>
                        {
                            structure.frequencies?.map(e => <option value={e.id}>{e.title}</option>)
                        }
                    </select>
                </div>
                <button type="submit" className="btn-styled success"><MdAdd/> Salvar</button>
            </form>
        </>
    )
}