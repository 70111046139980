import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaSignInAlt } from 'react-icons/fa'
import { useHistory } from 'react-router-dom'
import { Content } from '../../../../components/Content'
import { LinkBackHistory } from '../../../../components/LinkBackHistory'
import { ConfigModal, Modal } from '../../../../components/Modal'
import { Navbar } from '../../../../components/Navbar'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../../services/ClientDepartments/ClientDepartments'

export interface PFC_DepartmentsProps {}

export const PFC_Departments: React.FC<PFC_DepartmentsProps> = (props) => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [list, setList] = useState<Array<FormClientDepartment>>([])
    const history = useHistory()
    const loading = useRef(true)

    useEffect(() => {
        ClientDepartmentsAPI.getAll(setList)

        loading.current = false
    }, [])

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/" />
                            <h1 className="app-content-h1-destaque">Processo de Feedback: Modelos</h1>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                history.push('/pessoas/proccess-feedback-custom/templates')
                            }}>Modelos</button>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2>Acesse por departamento</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        list.length > 0 ?
                        (
                            list.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link" onClick={() => {
                                                        history.push(`/pessoas/proccess-feedback-custom/collaborator/${item.id}`)
                                                    }}><FaSignInAlt/> {item.name}</div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : (
                            <>
                                <h3>Comece agora mesmo clicando no menu, em Minha conta&gt;Departamentos assim que criar seus departamentos venha novamente a essa tela e comece a configurar</h3>
                            </>
                        )
                    }
                </div>
            </Content>
        </>
    )
}