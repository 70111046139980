import React, { useEffect, useState } from 'react'
import { MdAdd } from "react-icons/md";
import Success from '../../../functions/Modals/Success';
import { SubjectsProtocol } from './SubjectsProtocol';
import { ProtocolFormPropsV2, ProtocolItemPropsV2, ProtocolV2API } from '../../../services/ProtocolV2/ProtocolV2';
import { ProtocolFolderAPI, ProtocolFolderItemProps } from '../../../services/V2/ProtocolFolder';
import { newItemTempProps } from './SubjectsProtocolFeedback';
import Warning from '../../../functions/Modals/Warning';

interface ICreateProtocol {
    onSuccess?: ((data: ProtocolItemPropsV2) => void)
    folder_id?: string
}


export const CreateProtocol: React.FC<ICreateProtocol> = (props) => 
{
    const [data, setData] = useState<ProtocolFormPropsV2>({
        date: '',
        name: '',
        subjects: []
    })

    const [folders, setFolders] = useState<Array<ProtocolFolderItemProps>>([])

    useEffect(() => {
        ProtocolFolderAPI.getAll(setFolders)
    }, [])

    const [newItemTemp, setNewItemTemp] = useState<newItemTempProps>({
        status: false,
        title: '',
    })

    useEffect(() => {
        if(props.folder_id)
        {
            setData({
                ...data,
                folder_id: props.folder_id,
            })
        }
    }, [props.folder_id])
    

    const [searchSubjectsOpened, setsearchSubjectsOpened] = useState<Array<ProtocolItemPropsV2>>([])
    const [viewProtocolsWithSubjectsOpened, setviewProtocolsWithSubjectsOpened] = useState(false)

    const prevSaveSubjects = () => {
        let tmp_data = data;

        if(newItemTemp.status)
        {
            if(newItemTemp.index_change !== undefined)
            {
                let old_list = data.subjects;

                old_list[newItemTemp.index_change].title = newItemTemp.title
                old_list[newItemTemp.index_change].description = newItemTemp.description ?? ''
                old_list[newItemTemp.index_change].responsible = newItemTemp.responsible ?? ''
                old_list[newItemTemp.index_change].time = newItemTemp.time ?? ''

                tmp_data = {...data, subjects: [...old_list]}
                setNewItemTemp({...newItemTemp, title: '', description: '', responsible: '', time: '', status: false, index_change: undefined})
                
            }else if(newItemTemp.title)
            {
                tmp_data = {...data, subjects: [...data.subjects, { title: newItemTemp.title, status: "1", description: newItemTemp.description ?? '', responsible: newItemTemp.responsible ?? '', time: newItemTemp.time ?? '', last_subject: "no"}]}
                setNewItemTemp({...newItemTemp, title: '', description: '', responsible: '', time: ''})
            }else{
                Warning('Preencha o assunto.')
            }
        }else{
            setNewItemTemp({...newItemTemp, status: true})
        }

        return tmp_data
    }

    const onSubmit = (data: ProtocolFormPropsV2) => {
        ProtocolV2API.create(data, response => Success('Pauta criada com sucesso', () => props.onSuccess ? props.onSuccess(response.data) : null))
    }

    return (
        <>
            <form method="post" onSubmit={(event) => {
                event.preventDefault();

                let data = prevSaveSubjects()

                onSubmit(data)
            }}>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Título</label>
                    <input className="styled" type="text" onChange={e => setData({...data, name: e.target.value})} value={data.name} placeholder="Descreva o nome da pauta" required autoComplete="off" autoFocus={true}/>
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Data</label>
                    <input className="styled" type="date" onChange={e => setData({...data, date: e.target.value})} value={data.date} required autoComplete="off" autoFocus={true}/>
                </div>
                <div className="input-box-v2 mg-top">
                    <label htmlFor="!#">Pasta</label>
                    <select onChange={e => setData({...data, folder_id: e.target.value})} value={data.folder_id} >
                        <option value="">Selecione uma opção</option>
                        {
                            folders.map((item, index) => <option value={item.id}>{item.name}</option> )
                        }
                    </select>
                </div>
                {
                    setsearchSubjectsOpened.length > 0 && viewProtocolsWithSubjectsOpened ? 
                    (
                        <div className="col-12" style={{marginTop: '20px'}}>
                            <div className="input-box-v2">
                                <label>Selecione a pauta anterior</label>
                                <select onChange={e => {
                                    if(e.target.value !== "")
                                    {
                                        ProtocolV2API.getSubjectsOpened(e.target.value, response => setData({...data, subjects: [...data.subjects, ...response.map((item, index) => {
                                            return {
                                                ...item,
                                                last_subject: 'yes'
                                            }
                                        })]}))
                                    }
                                }}>
                                    <option value="">Selecione uma opção</option>
                                    {
                                        searchSubjectsOpened.map((e, i) => <option key={`${i}`} value={e.id}>{e.name}</option>)
                                    }
                                </select>
                            </div>
                        </div>
                    ) : null
                }
                <SubjectsProtocol newItemTemp={newItemTemp} setNewItemTemp={setNewItemTemp} onLoadingOldSubjects={() => {
                   setviewProtocolsWithSubjectsOpened(true)
                   ProtocolV2API.getAll(setsearchSubjectsOpened)
                }} list={data.subjects} setList={list => setData({...data, subjects: list})} />          
                <button style={{marginTop: '25px'}} type="submit" className="btn-styled success"><MdAdd/> Criar</button>
            </form>
        </>
    )
}
