import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaFile, FaFolder } from 'react-icons/fa'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { ConfigModal, Modal } from '../../../components/Modal'
import { Navbar } from '../../../components/Navbar'
import { FilesAPI, FilesItemProps } from '../../../services/MyAttachments/Files'
import { FoldersAPI, FoldersItemProps } from '../../../services/MyAttachments/Folders'
import { CreateFile } from '../Files/components/CreateFIle'
import { FileItem } from '../Files/components/FileItem'
import { CreateFolder } from './components/CreateFolder'
import { FolderItem } from './components/FolderItem'

export interface FoldersProps {}

export const Folders: React.FC<FoldersProps> = (props) => 
{
    const [folders, setFolders] = useState<Array<FoldersItemProps>>([])
    const [files, setFiles] = useState<Array<FilesItemProps>>([])
    const loading = useRef(true)
    const [modalConfig, setModalConfig] = useState<ConfigModal>({
        show: false
    })

    useEffect(() => {
        FoldersAPI.getAll(setFolders)
        FilesAPI.getAll(setFiles)

        loading.current = false
    }, [])

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={
                <div className="title-button">
                    <div className="title">
                        <LinkBackHistory to="/" />
                        <h1 className="app-content-h1-destaque">Meus arquivos</h1>
                    </div>
                    <div className="options">
                        <button className="bkg-theme clr-white" onClick={() => {
                            setModalConfig({
                                show: true,
                                title: (
                                    <>
                                        <h2>Criar pasta</h2>
                                    </>
                                ),
                                content: <CreateFolder onChange={() => {
                                    setModalConfig({show: false})
                                    FoldersAPI.getAll(setFolders)
                                }} />,
                                onClose: () => setModalConfig({show: false})
                            })
                        }}><FaFolder/></button>
                        <button className="bkg-theme clr-white" onClick={() => {
                            setModalConfig({
                                show: true,
                                title: (
                                    <>
                                        <h2>Criar arquivo</h2>
                                    </>
                                ),
                                content: <CreateFile onChange={() => {
                                    setModalConfig({show: false})
                                }} />,
                                onClose: () => setModalConfig({show: false})
                            })
                        }}><FaFile/></button>
                    </div>
                </div>
            }>
                <div className="content-box">
                    <h3 className="title-divider" style={{marginTop: '0px'}}>Pastas</h3>
                    {
                        folders.map((item, index) => {
                            return <FolderItem onClickEdit={folder => {
                                setModalConfig({
                                    show: true,
                                    title: (
                                        <>
                                            <h2>Editar pasta</h2>
                                        </>
                                    ),
                                    content: <CreateFolder id={folder.id} onChange={() => {
                                        setModalConfig({show: false})
                                        FoldersAPI.getAll(setFolders)
                                    }} />,
                                    onClose: () => setModalConfig({show: false})
                                })
                            }} folder={item} /> 
                        })
                    }
                    <h3 className="title-divider">Arquivos</h3>
                    {
                        files.map((item, index) => {
                            return <FileItem file={item} /> 
                        })
                    }
                </div>
            </Content>
        </>
    )
}