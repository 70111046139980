import { AxiosResponse } from "axios"
import Request from "../../functions/Request"

export interface DataSaveTask {
    label?: string,
    date?: string,
    repeat_day?: any,
    repeat_day_advanced?: any,
    time?: any,
    date_finish?: any,
    protocol_id?: any
    tag_id?: any
    contacts?: any
    departments?: any,
    subjects?: any,
    calendar_id?: any
    send_notify?: string
}

export const SaveTask = async (
    data: FormData,
    onSuccess: ((e: AxiosResponse) => void),
    onError: ((e: AxiosResponse) => void)
) => {
    await Request.post('/calendar/save-task', data, {
        headers: {
            'content-type': 'multipart/form-data'
        }
    }).then(onSuccess).catch(onError);
} 
