import { format, parseISO } from "date-fns";

export const dataFormat = (format: string) => {
    var data = new Date(),
        dia  = data.getDate().toString().padStart(2, '0'),
        mes  = (data.getMonth()+1).toString().padStart(2, '0'),
        ano  = data.getFullYear();

    format = format.replace('Y', `${ano}`);
    format = format.replace('m', `${mes}`);
    format = format.replace('d', `${dia}`);

    return format;
}

export const formatDate = (date?: string, regex: string = 'dd/MM/yyyy') => {
    if(!date)
        return ''
        
    return format(new Date(parseISO(date)), regex)
}


export const formatCurrencyBRL = (value?: string) => {
    if(!value)
        return ''

    return parseFloat(value).toLocaleString('pt-BR',{ style: 'currency', currency: 'BRL' })
}

export const formatCurrency = (value?: string) => {
    if(!value)
        return ''

    return parseFloat(value).toLocaleString('pt-BR')
}

export const formatCurrencyPreffix = (value?: string, preffix?: string) => {
    if(!value)
        return ''

    return `${preffix} ${parseFloat(value).toLocaleString('pt-BR')}`
}

// export const formatDatetime = (date: string) => {
//     return format(new Date(parseISO(date)), 'dd/MM/yyyy')
// }