import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { MdDeleteForever, MdEdit } from 'react-icons/md'
import { Content } from '../../components/Content'
import { LinkBackHistory } from '../../components/LinkBackHistory'
import { ConfigModal, Modal } from '../../components/Modal'
import { Navbar } from '../../components/Navbar'
import { NoWithContent } from '../../components/NoWithContent'
import { ConfirmDelete } from '../../functions/Modals/Confirm'
import { ClientDepartmentsAPI, FormClientDepartment, FormClientDepartmentImportProps } from '../../services/ClientDepartments/ClientDepartments'
import { ClientDepartmentForm } from './components/ClientDepartmentForm'

export interface DepartmentsProps {}

export const Departments: React.FC<DepartmentsProps> = () => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [list, setList] = useState<Array<FormClientDepartment>>([])
    const [listDepartments, setListDepartments] = useState<Array<FormClientDepartmentImportProps>>([])
    const loading = useRef(true)

    const syncData = () => {
        ClientDepartmentsAPI.getAll(setList)
        ClientDepartmentsAPI.getAllNoSync(setListDepartments)
        loading.current = false
    }

    useEffect(() => {
        syncData()    
    }, [])

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/" />
                            <h1 className="app-content-h1-destaque">Meus departamentos</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Lista de departamento</h2>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    content: <ClientDepartmentForm onSuccess={() => ClientDepartmentsAPI.getAll(setList)} />,
                                    onClose: () => setModalConfig({
                                        show: false
                                    }),
                                    title: <h2>Criar departamento</h2>
                                })
                            }}>Criar departamento</button>
                        </div>
                    </div>
                    <br/>
                    {
                        list.length > 0 ?
                        (
                            list.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link" onClick={() => {
                                                    }}>{item.name}</div>
                                                    <div className="options">
                                                        <button className="update-button" onClick={() => {
                                                            setModalConfig({
                                                                show: true,
                                                                content: <ClientDepartmentForm id={item.id} onSuccess={() => ClientDepartmentsAPI.getAll(setList)} />,
                                                                onClose: () => setModalConfig({
                                                                    show: false
                                                                }),
                                                                title: <h2>Atualizar departamento</h2>
                                                            })
                                                        }}><MdEdit/></button>
                                                        <button className="delete-button" onClick={() => {
                                                            ConfirmDelete(() => {
                                                                if(item.id)
                                                                {
                                                                    ClientDepartmentsAPI.delete(item.id, () => {
                                                                        setList([...list.filter(i => i.id !== item.id)])
                                                                    })
                                                                }
                                                            }, () => null)
                                                        }}><MdDeleteForever/></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : <NoWithContent/>
                    }
                </div>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Importar departamentos</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        listDepartments.length > 0 ?
                        (
                            listDepartments.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link" onClick={() => {
                                                        ClientDepartmentsAPI.create({name: item.label, sigla: item.sigla}, () => syncData())
                                                    }}>{item.label}</div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : <NoWithContent/>
                    }
                </div>
            </Content>
        </>
    )
}