import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { ConfigModal, Modal } from '../../../components/Modal'
import { Navbar } from '../../../components/Navbar'
import { ScrumFormTask } from './components/ScrumFormTask'
import { ListItemProps, ScrumListAPI } from '../../../services/Scrum/ScrumLists'
import { ScrumTasksArchived } from './components/ScrumTasksArchived'
import { FaArchive, FaPlus } from 'react-icons/fa'
import { DragDropContext, DropResult } from 'react-beautiful-dnd'
import { ScrumTab } from '../ScrumComponents/ScrumTab'

export interface ScrumProps {}


export const Scrum: React.FC<ScrumProps> = (props) => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})

    const loading = useRef(true)

    const [tasks, setTasks] = useState<ListItemProps[]>([])
    const [tasksArchivedView, setTasksArchivedView] = useState(false)

    const [scrum, setScrum] = useState<{
        columns: {
          id: string,
          title: string,
          tasks: ListItemProps[]  
        }[]
    }>({
        columns: [
            {
                id: 'backlog',
                title: 'Backlog',
                tasks: [],
            },
            {
                id: 'to_realize',
                title: 'A fazer',
                tasks: [],
            },
            {
                id: 'in_progress',
                title: 'Em andamento',
                tasks: [],
            },
            {
                id: 'concluded',
                title: 'Concluídos',
                tasks: [],
            },
        ],
    })

    const reloadKanban = useCallback(() => {
        ScrumListAPI.find(tasks => {
            let tmp = scrum.columns

            tmp = scrum.columns.map(item => {
                return {
                    ...item,
                    tasks: tasks.filter(task => task.tab_id === item.id)
                }
            })

            setScrum({
                columns: tmp
            })
        })
    }, [])
    

    useEffect(() => {
        loading.current = false;

        ScrumListAPI.find(tasks => {
            let tmp = scrum.columns

            tmp = scrum.columns.map(item => {
                return {
                    ...item,
                    tasks: tasks.filter(task => task.tab_id === item.id)
                }
            })

            setScrum({
                columns: tmp
            })
        })
    }, [])


    const onDragEnd = async (result: DropResult) => {
        const { destination, source, draggableId,  } = result;
        
        if (!destination) {
          return;
        }
    
        if (
          destination.droppableId === source.droppableId &&
          destination.index === source.index
        ) {
            console.log('Não alterou a posição')
          return;
        }

        if(source.droppableId === destination.droppableId)
        {
            console.log('Está movimento dentro da propria coluna');

            // Movimento dentro da propria coluna
            let tmp_columns = scrum.columns

            let index_column = scrum.columns.findIndex(item => item.id === source.droppableId)
            let index_task = scrum.columns[index_column].tasks.findIndex(item => item.id === draggableId)

            let item = tmp_columns[index_column].tasks.splice(index_task, 1)[0]

            tmp_columns[index_column].tasks.splice(destination.index, 0, item)

            setScrum({
                columns: tmp_columns
            })

            // salva
            await ScrumListAPI.moveTab(draggableId, {
                tab: destination.droppableId,
                order: scrum.columns[index_column].tasks.map((item, position) => {
                    return {
                        task_id: item.id,
                        index: position
                    }
                })
            }, () => {})

            return;
        }else{
            console.log('Está movimento entre colunas');

            // Movimento entre colunas
            let tmp_columns = scrum.columns
            
            let index_column = scrum.columns.findIndex(item => item.id === source.droppableId)
            let index_new_column = scrum.columns.findIndex(item => item.id === destination.droppableId)
            let index_task = scrum.columns[index_column].tasks.findIndex(item => item.id === draggableId)

            // Remove da coluna antiga
            let item = tmp_columns[index_column].tasks.splice(index_task, 1)[0]

            tmp_columns[index_new_column].tasks.splice(destination.index, 0, item)

            setScrum({
                columns: tmp_columns
            })
            
            // salva
            await ScrumListAPI.moveTab(draggableId, {
                tab: destination.droppableId,
                order: scrum.columns[index_new_column].tasks.map((item, position) => {
                    return {
                        task_id: item.id,
                        index: position
                    }
                })
            }, () => {})

            return;
        }
    };

    return (
        <>
            <Modal {...modalConfig} />
            <ScrumTasksArchived 
                onClose={() => setTasksArchivedView(false)} 
                show={tasksArchivedView} 
                onChange={() => {
                    ScrumListAPI.find(setTasks)
                }} 
            />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to="/" />
                            <h1 className="app-content-h1-destaque">Kanban: Pessoal</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="scrum">
                    <div className="scrum-actions">
                        <button onClick={() => {
                            setModalConfig({
                                show: true,
                                title: (
                                    <>
                                        <h2>Criar tarefa</h2>
                                    </>
                                ),
                                content: <ScrumFormTask 
                                    onChange={type => {
                                        if(type === "CREATE")
                                        {
                                            reloadKanban()
                                        }

                                        setModalConfig({show: false})
                                    }}
                                />,
                                onClose: () => setModalConfig({show: false})
                            })
                        }}>
                            <FaPlus /> Criar tarefa 
                        </button> 
                        <button 
                            style={{
                                background: "#EBD671",
                                borderBottomColor: "#a28a17",
                                color: "#333"
                            }}
                            
                            onClick={() => {
                                setTasksArchivedView(true)
                            }}
                        >
                            <FaArchive /> Arquivadas    
                        </button>  
                    </div>
                    <div className="scrum-tabs">
                        <DragDropContext onDragEnd={onDragEnd}>
                            {
                                scrum.columns.map((column, index) => {
                                    return <ScrumTab onClick={(task) => {
                                        setModalConfig({
                                            show: true,
                                            title: (
                                                <>
                                                    <h2>Atualizar tarefa</h2>
                                                </>
                                            ),
                                            content: <ScrumFormTask 
                                                id={task.id}
                                                onChange={type => {
                                                    if(type === "UPDATE")
                                                    {
                                                        reloadKanban()
                                                    }
            
                                                    setModalConfig({show: false})
                                                }}
                                            />,
                                            onClose: () => setModalConfig({show: false})
                                        })
                                    }} key={index} title={column.title} tab_id={column.id} tasks={column.tasks} />;
                                })
                            }
                        </DragDropContext>
                    </div>
                </div>
            </Content>
        </>
    )
}
