import Modal from "../Modal";

const ConfirmDelete = (
    $funcSuccess: (() => void),
    $funcError: (() => void),
    $textMessage: string = 'Você não será capaz de reverter isso!',
    $textConfirm: string = "Sim, exclua!"
) => {
    Modal.fire({
        title: 'Você tem certeza?',
        text: $textMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: $textConfirm,
        cancelButtonText: 'Não, cancele!',
    }).then((result: any) => {
        if (result.isConfirmed) {
            return $funcSuccess()
        }else{
            return $funcError()
        }
    })
}

export {
    ConfirmDelete
};