import React from 'react'
import { FaCheck, FaCheckCircle } from 'react-icons/fa';
import { TutorialStageItemProps, TutorialQuizzItemProps } from '../../../services/Tutorial/TutorialModule';

export const StageQuizz = (props: {
    stage: TutorialStageItemProps,
    onChange: ((data: {
        isCorrect: any,
        answerId: any
    }) => void)
    quizzResponse: {
        isCorrect: any,
        answerId: any
    }
}) => {
    let data: TutorialQuizzItemProps = props.stage.stage;

    return (
        <div className="box-tutorial">
            <h2>{data.title} {props.stage.realized ? <FaCheck/> : null} </h2>
            {
                data.answers.map((e, i) => {
                    let classes = 'checklist-item'

                    if(props.quizzResponse.isCorrect === "1" && props.quizzResponse.answerId === e.id)
                    {
                        classes += ' incorrect'
                    }

                    if(props.quizzResponse.answerId === e.id)
                    {
                        classes += ' correct'
                    }

                    return (
                        <div>
                            <div className={classes}>
                                <input onChange={() => {
                                    props.onChange({
                                        isCorrect: e.is_correct,
                                        answerId: e.id
                                    })
                                }} checked={props.quizzResponse.answerId === e.id || data.quizz_response_id === e.id ? true : false} id={`checkbox_${i}`} name={`${e.quiz_id}_quizz`} type="radio" />
                                <label htmlFor={`checkbox_${i}`} className="checkmark"><FaCheckCircle /></label>
                                <p>{e.answer} {props.quizzResponse.isCorrect === "1" && props.quizzResponse.answerId === e.id ? ' (Incorreto)' : ''}</p>
                            </div>
                        </div>
                    )
                })
            }
            {props.quizzResponse.answerId !== '' || props.stage.realized ? (
                <div className="information-box" style={{marginTop: '20px'}}>
                    <p className="title-box">Explicação</p>
                    <span>
                        {
                            data.question_response
                        }
                    </span>
                </div>
            ): null}
            <br/><br/>
        </div>
    )
}