import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";
import { DREDataProps, DREDataTopicProps } from "./DRE";

const route = '/client/finance/budget-forecast'

export interface BudgetForecastProps {
    id: string
    name: string
    type: string
    client_id: string
    year: {
        "01": number,
        "02": number,
        "03": number,
        "04": number,
        "05": number,
        "06": number,
        "07": number,
        "08": number,
        "09": number,
        "10": number,
        "11": number,
        "12": number
    }
}


class BudgetForecast 
{
    async getAll(
        year: string,
        onSuccess: ((success: Array<DREDataProps>) => void),
    )
    {
        return await Request.get(`${route}?year=${year}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }

    async update(
        year: string,
        project_id: string,
        data: DREDataTopicProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.put(`${route}/${project_id}?year=${year}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }
}

export const BudgetForecastAPI = new BudgetForecast()