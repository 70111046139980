import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { MdAdd } from 'react-icons/md'
import { EditorHTML } from '../../../../components/EditorHTML'
import { LoadingContent } from '../../../../components/LoadingContent'
import Success from '../../../../functions/Modals/Success'
import { ManualFinalConsiderationAPI, ManualFinalConsiderationFormProps } from '../../../../services/ManualColaborador/ManualFinalConsideration'

export interface FormCompanyFinalConsiderationProps {
    onCreate?: (() => void),
    onUpdate?: (() => void)
}

export const FormCompanyFinalConsideration: React.FC<FormCompanyFinalConsiderationProps> = (props) => 
{
    const [form, setForm] = useState<ManualFinalConsiderationFormProps>({
        final_consideration: ''
    })

    const loading = useRef(true)

    useEffect(() => {
        ManualFinalConsiderationAPI.consult(setForm)

        let auto_save : any = setInterval(() => {
            ManualFinalConsiderationAPI.save(form, () => {})
        }, 30000)

        loading.current = false

        return () => {
            clearInterval(auto_save)
        }
    }, [])

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        ManualFinalConsiderationAPI.save(form, () => Success('Salvo com sucesso.', () => {
            if(props.onCreate)
            {
                props.onCreate()
            }
        }))
    }

    return (
        <>
            {
                !loading.current ?
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p>Descreva</p>
                            <EditorHTML defaultToolbar={true} value={form.final_consideration} onChange={data => setForm({...form, final_consideration: data})} />
                        </div>
                        <div className="flex between">
                            <button type="submit" style={{marginRight: '10px'}}><MdAdd/> Salvar</button>
                        </div>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}