import RequestWithoutLoading from "../../functions/RequestWithoutLoading";

const route = '/client/notify-system'

export interface FormNotifySystem {
    information: string
}

class NotifySystem
{
    async save(form: FormNotifySystem)
    {
        return await RequestWithoutLoading.post(`${route}`, form);
    }
}

export const NotifySystemAPI = new NotifySystem()