import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { MdAdd } from 'react-icons/md'
import { LoadingContent } from '../../../../components/LoadingContent'
import Success from '../../../../functions/Modals/Success'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../../services/ClientDepartments/ClientDepartments'
import { ConsultDepartments, ConsultDepartmentsItem } from '../../../../services/Departments/ConsultDepartments'
import { NotifySystemAPI } from '../../../../services/NotifySystem/NotifySystem'
import { ControllerProccess, ProccessForm } from '../../../../services/Proccess/ControllerProccess'
import { ControllerProccessSector, SectorItem } from '../../../../services/Proccess/ControllerProccessSector'

export interface FormProccessProps {
    id?: string,
    macroproccess: string,
    oncreated: (() => void)
}

export const FormProccess: React.FC<FormProccessProps> = (props) => 
{
    const [form, setForm] = useState<ProccessForm>({
        title: '',
        macroproccess_id: props.macroproccess
    })

    const loading = useRef(true)
    
    const [, setListDeparments] = useState<Array<ConsultDepartmentsItem>>([])

    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        if(props.id)
        {
            ControllerProccess.update(form, () => Success('Salvo com sucesso!', async () => {
                await NotifySystemAPI.save({information: 'Criou um novo MacroProcesso em: Processos>MacroProcesso'})

                props.oncreated()

                setForm({...form, title: ''})
            }))
        }else{
            ControllerProccess.create(form, () => Success('Salvo com sucesso!', () => {
                props.oncreated()

                setForm({...form, title: ''})
            }))
        }
    }

    const [listDepartmentProccess, setListDepartmentProccess] = useState<Array<FormClientDepartment>>([])
    const [listMacroProccess, setListMacroProccess] = useState<SectorItem[]>([])

    useEffect(() => {
        ConsultDepartments(s => setListDeparments(s.departments), () => {})

        if(props.id)
        {
            ControllerProccess.getById(props.id, setForm)
        }else{
            setForm({title: '', id: props.id, macroproccess_id: props.macroproccess})
        }

        loading.current = false

        ClientDepartmentsAPI.getAll(setListDepartmentProccess)
    }, [props.id, props.macroproccess])

    return (
        <>
            {
                !loading.current ?
                (
                    <form className="form-tasks-create" method="post" onSubmit={onSubmit}>
                        <div className="box-input">
                            <p>Título</p>
                            <input type="text" onChange={e => setForm({...form, title: e.target.value})} value={form.title} />
                        </div>
                        {
                            props.id ? (
                                <div className="box-input">
                                    <p>Alterar macro processo?</p>
                                    <br />
                                    <p>Escolha um departamento:</p>
                                    <select onChange={event => {
                                        if(event.target.value !== "")
                                        {
                                            ControllerProccessSector.getAll(event.target.value, setListMacroProccess)
                                        }
                                    }}>
                                        <option value="">Seleciona uma opção</option>
                                        {
                                            listDepartmentProccess.map(item => <option value={item.id}>{item.name} - {item.sigla}</option>)
                                        }
                                    </select>
                                    <br /><br />
                                    <p>Macro processo:</p>
                                    <select onChange={event => {
                                        if(event.target.value !== "")
                                        {
                                            setForm({...form, macroproccess_id: event.target.value})
                                        }
                                    }}>
                                        <option value="">Seleciona uma opção</option>
                                        {
                                            listMacroProccess.map(item => <option value={item.id}>{item.item_number}.{item.title}</option>)
                                        }
                                    </select>
                                </div>
                            ) : null
                        }
                        <button type="submit"><MdAdd/> Salvar</button>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}