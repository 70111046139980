import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { FaSignInAlt } from 'react-icons/fa'
import { MdArrowBack, MdDeleteForever, MdModeEdit } from 'react-icons/md'
import { useHistory, useParams } from 'react-router-dom'
import { Content } from '../../../../../components/Content'
import { ConfigModal, Modal } from '../../../../../components/Modal'
import { Navbar } from '../../../../../components/Navbar'
import { NoWithContent } from '../../../../../components/NoWithContent'
import { ConfirmDelete } from '../../../../../functions/Modals/ConfirmDelete'
import { ClientDepartmentsAPI, FormClientDepartment } from '../../../../../services/ClientDepartments/ClientDepartments'
import { OrganogramaDepartmentAPI } from '../../../../../services/Organograma/OrganogramaDepartment'
import { FormOrganogramaDepartments } from './components/FormOrganogramaDepartments'

export interface OrganogramaDepartmentsProps {}

export const OrganogramaDepartments: React.FC<OrganogramaDepartmentsProps> = () => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [list, setList] = useState<Array<FormClientDepartment>>([])

    const history = useHistory()
    const loading = useRef(true)
    const { id } = useParams<{id: string}>()

    useEffect(() => {
        ClientDepartmentsAPI.getAll(setList)

        loading.current = false
    }, [id])

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <button className="history-back" onClick={() => history.push('/pessoas')}><MdArrowBack /></button>
                            <h1 className="app-content-h1-destaque">Seus departamentos</h1>
                        </div>
                    </div>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>Acesse por departamento seus organogramas</h2>
                        </div>
                    </div>
                    <br/>
                    {
                        list.length > 0 ?
                        (
                            list.map((item) => 
                                {
                                    return (
                                        <>
                                            <div className="proccess-item" onClick={() => {}}>
                                                <div className="title-with-button-options">
                                                    <div className="title link" onClick={() => {
                                                        history.push(`/organograma/${item.id}`)
                                                    }}><FaSignInAlt/> {item.name}</div>
                                                    <div className="options">
                                                        <button className="update-button" onClick={() => {
                                                            setModalConfig({
                                                                show: true,
                                                                content: <FormOrganogramaDepartments id={item.id} onSuccess={() => {
                                                                    OrganogramaDepartmentAPI.getAll(setList)

                                                                    setModalConfig({
                                                                        show: false
                                                                    })
                                                                }} />,
                                                                onClose: () => setModalConfig({
                                                                    show: false
                                                                }),
                                                                title: <h2>Atualizar departamento</h2>
                                                            })
                                                        }}><MdModeEdit/></button>
                                                        <button className="delete-button" onClick={() => {
                                                            ConfirmDelete(() => {
                                                                if(item.id)
                                                                {
                                                                    OrganogramaDepartmentAPI.delete(item.id, () => {
                                                                        setList([...list.filter(i => i.id !== item.id)])
                                                                    })
                                                                }
                                                            }, () => null)
                                                        }}><MdDeleteForever/></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                }
                            )
                        ) : <NoWithContent/>
                    }
                </div>
            </Content>
        </>
    )
}