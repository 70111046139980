import React, { useEffect, useState } from 'react'
import { FaPlus } from 'react-icons/fa'
import { useHistory, useParams } from 'react-router-dom'
import { ButtonBackHistory } from '../../../../../components/ButtonBackHistory'
import { Content } from '../../../../../components/Content'
import { ConfigModal, Modal } from '../../../../../components/Modal'
import { Navbar } from '../../../../../components/Navbar'
import { ConfirmDelete } from '../../../../../functions/Modals/ConfirmDelete'
import { SwotSubjectItemProps, SwotSubjectsAPI } from '../../../../../services/PlanejamentoEstrategico/SwotSubjects'
import { FormSwotSubject } from '../../components/FormSwotSubject'
import { SwotSubject } from '../../components/SwotSubject'

export interface SwotViewProps {}

export const SwotView: React.FC<SwotViewProps> = (props) => 
{
    const { folder_id } = useParams<{ folder_id: string }>()    

    const [subjects, setSubjects] = useState<SwotSubjectItemProps[]>([])
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})

    useEffect(() => {
        SwotSubjectsAPI.getAll(folder_id, setSubjects)
    }, [folder_id])

    const history = useHistory()

    const onDelete = (item: SwotSubjectItemProps) => {
        ConfirmDelete(() => {
            if(item.id)
            {
                SwotSubjectsAPI.delete(item.id, () => {
                    setSubjects([...subjects.filter(i => i.id !== item.id)])
                })
            }
        }, () => null)
    }

    const onEdit = (item: SwotSubjectItemProps, field_id: number) => {
        setModalConfig({
            show: true,
            content: <FormSwotSubject field_id={field_id.toString()} id={item.id} folder_id={folder_id} onSuccess={() => {
                SwotSubjectsAPI.getAll(folder_id, setSubjects)
                setModalConfig({show: false})
            }} />,
            onClose: () => setModalConfig({
                show: false
            }),
            title: <h2>Assunto</h2>
        })
    }

    const onCreate = (field_id: number) => {
        setModalConfig({
            show: true,
            content: <FormSwotSubject field_id={field_id.toString()} folder_id={folder_id} onSuccess={() => {
                SwotSubjectsAPI.getAll(folder_id, setSubjects)
                setModalConfig({show: false})
            }} />,
            onClose: () => setModalConfig({
                show: false
            }),
            title: <h2>Assunto</h2>
        })
    }

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar /> 
            <Content title={(
                <div className="title-button">
                    <div className="title">
                        <ButtonBackHistory onClick={() => history.goBack() }/>
                        <h1 className="app-content-h1-destaque">Swot</h1>
                    </div>
                    <div className="options">
                        <button onClick={() => {
                            SwotSubjectsAPI.pdf(folder_id, (file) => {
                                const href = URL.createObjectURL(file);

                                // create "a" HTML element with href to file & click
                                const link = document.createElement('a');
                                link.href = href;
                                link.setAttribute('download', 'swot.pdf'); //or any other extension
                                document.body.appendChild(link);
                                link.click();
                            
                                // clean up "a" element & remove ObjectURL
                                document.body.removeChild(link);
                                URL.revokeObjectURL(href);
                            }, err => {
                                console.log(err)
                            })
                        }}>Visualizar em PDF</button>
                    </div>
                </div>
            )}>
                <div className="grid-swot">
                    <div>
                        <div className="swot-container">
                            <div className="swot-title">Forças <span className='btn-plus' onClick={() => onCreate(1)}><FaPlus /></span></div>    
                            <div className="subjects">
                                {subjects.filter(item => Number(item.field_id) === 1).map(item => <SwotSubject onClickDelete={() => onDelete(item)} onClickEdit={() => onEdit(item, 1)} {...item} />)}
                            </div>
                        </div>    
                        <div className="swot-container">
                            <div className="swot-title">Fraquezas <span className='btn-plus' onClick={() => onCreate(2)}><FaPlus /></span></div> 
                            <div className="subjects">
                                {subjects.filter(item => Number(item.field_id) === 2).map(item => <SwotSubject onClickDelete={() => onDelete(item)} onClickEdit={() => onEdit(item, 2)} {...item} />)}
                            </div>
                        </div>    
                    </div>
                    <div>
                        <div className="swot-container">
                            <div className="swot-title">Oportunidades <span className='btn-plus' onClick={() => onCreate(3)}><FaPlus /></span></div>    
                            <div className="subjects">
                                {subjects.filter(item => Number(item.field_id) === 3).map(item => <SwotSubject onClickDelete={() => onDelete(item)} onClickEdit={() => onEdit(item, 3)} {...item} />)}
                            </div>
                        </div>    
                        <div className="swot-container">
                            <div className="swot-title">Ameaças <span className='btn-plus' onClick={() => onCreate(4)}><FaPlus /></span></div>    
                            <div className="subjects">
                                {subjects.filter(item => Number(item.field_id) === 4).map(item => <SwotSubject onClickDelete={() => onDelete(item)} onClickEdit={() => onEdit(item, 4)} {...item} />)}
                            </div>
                        </div>    
                    </div>
                </div>
            </Content>
        </>
    )
}