import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";
import RequestWithoutLoading from "../../functions/RequestWithoutLoading";

const route = '/v2/client/scrum-iso/task'

export interface ListItemProps {
    title?: string,
    date?: string,
    description?: string,
    checklist?: string[]
    date_finish?: string,
    index?: number
    tags?: {
        id?: string
        tag_id?: string
        task_id?: string,
        tag?: {
            id: string
            label: string
            tag_id: string
            client_id: string
            color: string
        }
    }[]
    departments?: {
        id?: string
        tag_id?: string
        department_id?: string,
        department?: {
            id: string
            name: string
            sigla: string
            company_id: string
        }
    }[]
    tab_id?: string
    comments?: []
    id?: string
}

class ScrumListISO
{
    async create(
        form: ListItemProps,
        onSuccess: ((success: ListItemProps) => void),
    )
    {
        return await Request.post(`${route}`, form).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async update(
        id: string,
        form: ListItemProps,
        onSuccess: ((success: ListItemProps) => void),
    )
    {
        return await Request.put(`${route}/${id}`, form).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async moveTab(
        id: string,
        form: {
            tab: string
            order?: {
                task_id?: string,
                index?: number
            }[]
        },
        onSuccess: ((success: ListItemProps) => void),
    )
    {
        return await RequestWithoutLoading.put(`${route}/move/${id}`, form).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async unarchiveTask(
        id: string,
        form: ListItemProps,
        onSuccess: ((success: ListItemProps) => void),
    )
    {
        return await Request.put(`${route}/unarchive/${id}`, form).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async archiveTask(
        id: string,
        onSuccess: ((success: ListItemProps) => void),
    )
    {
        return await Request.put(`${route}/archive/${id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async delete(
        id: string,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.delete(`${route}/${id}`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async findOne(
        id: string,
        onSuccess: ((success: ListItemProps) => void),
    )
    {
        return await Request.get(`${route}/${id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async find(
        onSuccess: ((success: ListItemProps[]) => void),
    )
    {
        return await Request.get(`${route}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async start(
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`/v2/client/scrum-iso/kanban/start`).then(onSuccess).catch(DefaultErrorResponse);
    }

    async findArchiveds(
        onSuccess: ((success: ListItemProps[]) => void),
    )
    {
        return await Request.get(`${route}/archiveds`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }
}

export const ScrumISOListAPI = new ScrumListISO()