import React, { useEffect, useState } from 'react'
import { useRef } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { FaSignInAlt } from 'react-icons/fa'
import { MdDeleteForever, MdModeEdit } from 'react-icons/md'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { Content } from '../../../components/Content'
import { LinkBackHistory } from '../../../components/LinkBackHistory'
import { ConfigModal, Modal } from '../../../components/Modal'
import { Navbar } from '../../../components/Navbar'
import { ConfirmDelete } from '../../../functions/Modals/Confirm'
import { ControllerProccess, ProccessItem } from '../../../services/Proccess/ControllerProccess'
import { FormProccess } from '../Proccess/components/FormProccess'

export interface ProccessProps {}

export const MacroProccess: React.FC<ProccessProps> = () => 
{
    const [modalConfig, setModalConfig] = useState<ConfigModal>({show: false})
    const [listProccess, setListProccess] = useState<Array<ProccessItem>>([])

    const history = useHistory()
    const { state } = useLocation<any>()
    const loading = useRef(true)
    const { macroproccess_id } = useParams<{macroproccess_id: string}>()

    useEffect(() => {
        ControllerProccess.getAll(macroproccess_id, setListProccess)

        loading.current = false
    }, [macroproccess_id])

    const reorder = (list: any, startIndex: any, endIndex: any) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
      
        return result;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination) {
          return;
        }
    
        let items: any = reorder(
            listProccess,
            result.source.index,
            result.destination.index
        );
    
        setListProccess([...items]);

        ControllerProccess.reorder({list: items}, () => {
            ControllerProccess.getAll(macroproccess_id, setListProccess)
        })
      }

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={(
                <>
                    <div className="title-button">
                        <div className="title">
                            <LinkBackHistory to={state.department_id ? `/department-proccess/${state.department_id}` : '/proccess'} />
                            <h1 className="app-content-h1-destaque">Macroprocesso: {state.macro_proccess_title}</h1>
                        </div>
                    </div>
                    <p className="path-page">{state.department_title} / {state.macro_proccess_title}</p>
                </>
            )}>
                <div className="my-tasks-backlog" style={{marginTop: '20px'}}>
                    <div className="title-button">
                        <div className="title">
                            <h2 onClick={() => {}}>MicroProcessos</h2>
                        </div>
                        <div className="options">
                            <button onClick={() => {
                                setModalConfig({
                                    show: true,
                                    content: <FormProccess oncreated={() => {
                                        ControllerProccess.getAll(macroproccess_id, setListProccess)
                                    }} macroproccess={macroproccess_id} />,
                                    onClose: () => setModalConfig({
                                        show: false
                                    }),
                                    title: <h2>Criar Microprocesso</h2>
                                })
                            }}>Criar Microprocesso</button>
                        </div>
                    </div>
                    <br/>
                    {
                        listProccess.length > 0 ?
                        (
                            <DragDropContext onDragEnd={onDragEnd}>
                                <Droppable droppableId="droppable">
                                {(provided, snapshot) => (
                                    <div
                                    {...provided.droppableProps}
                                    ref={provided.innerRef}
                                    >
                                    {listProccess.map((item, index) => (
                                        <Draggable key={item.id} draggableId={item.id ? item.id : `${index}`} index={index}>
                                            {(provided, snapshot) => (
                                                <div className="proccess-item" ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}>
                                                        <div className="title-with-button-options">
                                                            <div className="title link" onClick={() => {
                                                                history.push(`/proccess/${item.id}`, { 
                                                                    micro_proccess_title: item.title, 
                                                                    micro_proccess_id: item.id, 
                                                                    macro_proccess_id: macroproccess_id, 
                                                                    macro_proccess_title: state.macro_proccess_title,
                                                                    micro_number: item.item_number,
                                                                    ...state
                                                                })
                                                            }}><FaSignInAlt/> {state.macro_number}.{item.item_number} {item.title}</div>
                                                            <div className="options">
                                                                <button className="update-button" onClick={() => {
                                                                    setModalConfig({
                                                                        show: true,
                                                                        content: <FormProccess oncreated={() => {
                                                                            ControllerProccess.getAll(macroproccess_id, setListProccess)
                                                                        }} id={item.id} macroproccess={macroproccess_id} />,
                                                                        onClose: () => setModalConfig({
                                                                            show: false
                                                                        }),
                                                                        title: <h2>Criar Microprocesso</h2>
                                                                    })
                                                                }}><MdModeEdit/></button>
                                                                <button className="delete-button" onClick={() => {
                                                                    ConfirmDelete(() => {
                                                                        if(item.id)
                                                                        {
                                                                            ControllerProccess.delete(item.id, () => {
                                                                                let lastListMicroProccess = listProccess

                                                                                setListProccess([...lastListMicroProccess.filter(i => i.id !== item.id)])
                                                                            })
                                                                        }
                                                                    }, () => null)
                                                                }}><MdDeleteForever/></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                            )}
                                            </Draggable>
                                        ))}
                                    {provided.placeholder}
                                    </div>
                                )}
                                </Droppable>
                            </DragDropContext>
                            // listProccess.map((item) => 
                            //     {
                            //         return (
                            //             <>
                                            
                            //             </>
                            //         )
                            //     }
                            // )
                        ) :
                        (
                            <h3>Sem informações para serem mostradas</h3>
                        )
                    }
                </div>
            </Content>
        </>
    )
}