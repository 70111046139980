import { AxiosResponse } from "axios";
import Request from "../../../../functions/Request";
import DefaultErrorResponse from "../../../../functions/DefaultErrorResponse";

const route = '/client/auditoria-interna/questions'

export interface AuditoriaItemFormProps {
    status: string
    evi_obj: string
}

export interface StructureItemProps {
    id?: number
    codigo?: string
    description?: string
    obs?: string
    id_item?: string
    status?: string
    evi_obj?: string
}

export interface StructureProps {
    id?: number
    codigo?: string
    description?: string
    obs?: string
    subitens?: StructureItemProps[]
}

class AuditoriaInternaService
{
    async save(
        id: string,
        data: AuditoriaItemFormProps,
        onSuccess: ((success: StructureProps) => void),
    )
    {
        return await Request.post(`${route}/${id}`, data).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async mount(
        id: string,
        onSuccess: ((proccesses: StructureProps[]) => void),
    )
    {
        return await Request.get(`${route}/mount/${id}`).then(response => onSuccess(response.data)).catch(DefaultErrorResponse);
    }

    async pdf(
        id: string,
        onSuccess: ((file: Blob) => void),
        onError: ((err: any) => void)
    )
    {
        return await Request.post(`${route}/pdf/${id}`, {}, { responseType: 'blob'}).then(response => onSuccess(response.data)).catch(onError);
    }
}

export const auditoriaQuestions_api = new AuditoriaInternaService()