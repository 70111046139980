import { AxiosResponse } from "axios";
import Request from "../../functions/Request";
import RequestWithoutLoading from "../../functions/RequestWithoutLoading";

const route = '/client/normas'


export interface NormasItemFormProps {
    id?: string
    topic: string
    client_id?: string
    suggestions_explanations: any
    link?: string
    description: any
}

export interface NormasItemProps extends NormasItemFormProps {
    id: string
}

class Normas
{
    async reorder(form: {
        list: Array<NormasItemFormProps>
    }, success: ((response: AxiosResponse) => void))
    {
        return await RequestWithoutLoading.put(`${route}/reorder`, form).then(success);
    }

    async save(form: NormasItemFormProps, success: ((response: AxiosResponse) => void))
    {
        return await Request.post(`${route}`, form).then(success);
    }

    async update(id: string, form: NormasItemFormProps, success: ((response: AxiosResponse) => void))
    {
        return await Request.put(`${route}/${id}`, form).then(success);
    }

    async delete(id: string, success: ((response: AxiosResponse) => void))
    {
        return await Request.delete(`${route}/${id}`).then(success);
    }
    
    async consult(success: ((response: Array<NormasItemProps>) => void))
    {
        return await Request.get(`${route}`).then(response => success(response.data.response));
    }

    async consultOne(id: string, success: ((response: NormasItemProps) => void))
    {
        return await Request.get(`${route}/${id}`).then(response => success(response.data.response));
    }
}

export const NormasAPI = new Normas()