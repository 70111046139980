import React, { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import Success from '../../functions/Modals/Success'
import { DiagnosticUpdateData } from '../../services/DiagnosticGenerateResult'
import { DiagnosticMounted, DiagnosticStages } from '../../services/DiagnosticMounted'
import { NotifySystemAPI } from '../../services/NotifySystem/NotifySystem'
import { ValueQuestion } from './components/ValueQuestion'
import Logo from '../../components/Navbar/assets/logo-preto-2.png'
import Warning from '../../functions/Modals/Warning'

export interface IMyDiagnostic {}

export const MyDiagnostic: React.FunctionComponent<IMyDiagnostic> = () => 
{
    const [diagnostic, setDiagnostic] = useState<Array<DiagnosticStages>>([])
    const [stageIndex, setStageIndex] = useState<number>(0)
    const [started, setStarted] = useState(false)
    const history = useHistory();

    useEffect(() => {
        DiagnosticMounted(e => setDiagnostic(e.status))        

    }, [])
    
    return (
        <>
            <div className="diagnostic-form">
                <div className="content">
                    {
                        started ? 
                        (
                            <>
                                {
                                    diagnostic.map((stage, index) => {
                                        return (
                                            <div id={`tab_${index}`} title={stage.label} key={`${index}`} style={stageIndex === index ? {display: 'block'} : {display: 'none'}}>
                                                <span className="title-stage">{stage.label}</span>
                                                {
                                                    stage.questions.map((question, index_question) => {
                                                        return (
                                                            <div className="question" key={`${stage.id}`}>
                                                                <div className="question-header">
                                                                    <span className="title">{question.question.replace("\\", "").replace('\\"', '"')} {
                                                                        question.description ? <span title={question.description.replace("\\", " ").replace('/', '')} className="description">?</span> : null
                                                                    }</span>
                                                                    
                                                                </div>
                                                                <ValueQuestion value={diagnostic[index]['questions'][index_question]['response_value']} onChange={value => {
                                                                    let question_stages = diagnostic;
                                                                    
                                                                    question_stages[index]['questions'][index_question]['response_value'] = value

                                                                    setDiagnostic([...question_stages])
                                                                }}/>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        )
                                    })
                                }
                                {
                                    stageIndex !== 0 ?
                                    (
                                        <button className="btn-question" onClick={() => {
                                            let new_stage = stageIndex-1;
                                            if(new_stage < 0)
                                            {
                                                new_stage = 0;
                                            }
                                            setStageIndex(new_stage)
                                        }}>
                                            Anterior
                                        </button>
                                    ) : null
                                }
                                {
                                    stageIndex !== diagnostic.length-1 ? 
                                    (
                                        <button className="btn-question" onClick={() => {
                                            let question_stages = diagnostic;
                                                                    
                                            let question_opens = question_stages[stageIndex]['questions'].filter(e => e.response_value === "")

                                            if(question_opens.length)
                                            {
                                                Warning('Responda todas as questões')
                                                return;
                                            }

                                            DiagnosticUpdateData({status: 2, data: diagnostic}, () => {})

                                            let new_stage = stageIndex;

                                            new_stage++;

                                            setStageIndex(new_stage)

                                            window.location.hash = `tab_${new_stage}`
                                        }}>
                                            Próximo
                                        </button>
                                    ) : (
                                        <button className="btn-question bkg-success" onClick={() => {
                                            NotifySystemAPI.save({information: 'Finalizou o diagnóstico'})

                                            DiagnosticUpdateData({status: 3, data: diagnostic}, e => Success('Diagnóstico Inicial realizado com sucesso!', () => history.push('/my-diagnostic/result', e.status)))
                                        }}>
                                            Concluir
                                        </button>
                                    )
                                }
                            </>
                        ) : (
                            <>
                                <div className="diagnostic-apresentation">
                                    <iframe title="Tutorial" id="ytplayer" typeof="text/html" width="100%" height="350" src="https://www.youtube.com/embed/BjwVtN2Y9Sk" frameBorder="0"/>
                                    <img src={Logo} alt=""/>
                                    <h2>VAMOS COMEÇAR O SEU DIAGNÓSTICO INICIAL?</h2>
                                    <br/>
                                    <p>Essa é a etapa mais importante do nosso trabalho: é com base nessas respostas que mediremos a maturidade que sua empresa possui em gestão.</p>
                                    <br/>
                                    <p>Juntos vamos criar uma nova realidade para seu negócio. Para isso, é preciso que você responda de acordo com o seu cenário atual, e não como gostaria que fosse. </p>
                                    <br/>
                                    <p>Pedimos que separe 20 minutinhos, focando no diagnóstico e sem interrupções, pois a partir dessas informações, direcionaremos toda a sua jornada a partir de hoje!</p>
                                    <button className="btn-link-solid" style={{display: `inline-block`, marginLeft: 0, marginTop: '20px', padding: '10px'}} onClick={() => {
                                        DiagnosticUpdateData({status: 1, data: diagnostic}, () => {})
                                        NotifySystemAPI.save({information: 'Iniciou o diagnóstico'})
                                        setStarted(true)
                                    }}>Vamos lá?</button>
                                </div>
                            </>
                        )
                    }
                </div> 
                <div className="sign-out">
                    <Link to="/jornada/10" >Deseja realizar mais tarde? Clique aqui</Link>
                </div>
            </div>
        </>
    )
}
