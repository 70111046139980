import React, { useEffect, useRef, useState } from 'react'
import { MdAdd } from 'react-icons/md'
import { LoadingContent } from '../../../../components/LoadingContent'
import Success from '../../../../functions/Modals/Success'
import { DepartmentMissionAPI, DepartmentMissionFormProps } from '../../../../services/Resultado/DepartmentMission/DepartmentMission'

export interface DepartmentMissionProps {
    department_id: string,
    onSuccess?: (() => void)
}

export const DepartmentMission: React.FC<DepartmentMissionProps> = (props) => 
{
    const baseForm = {
        id: '',
        department_id: '',
        mission: ''
    };

    const [form, setForm] = useState<DepartmentMissionFormProps>(baseForm)
    
    const onSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault()

        DepartmentMissionAPI.insertOrUpdate(form, () => Success('Salvo com sucesso!', () => {
            if(props.onSuccess)
            {
                props.onSuccess()
            }
        }))
    }

    const loading = useRef(true)

    useEffect(() => {
        if(props.department_id)
        {
            DepartmentMissionAPI.get(props.department_id, data => {
                if(data.id)
                {
                    setForm(data)
                }else{
                    setForm({id: '', mission: '', department_id: props.department_id})
                }
            })
        }else{
            setForm({id: '', mission: '', department_id: props.department_id})
        }

        loading.current = false
    }, [props.department_id])

    return (
        <>
            {
                !loading.current ? 
                (
                    <form  method="post" onSubmit={onSubmit}>
                        <div className="input-box-v2 mg-top">
                            <p>Descreva</p>
                            <textarea onChange={e => setForm({...form, mission: e.target.value})} value={form.mission} />
                        </div>
                        <div className="flex between" style={{marginTop: '25px'}}>
                            <button type="submit" className="btn-styled success" style={{marginRight: '10px'}}><MdAdd/> Salvar</button>
                        </div>
                    </form>
                ) : <LoadingContent />
            }
        </>
    )
}