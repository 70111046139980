import React from 'react'
import { ConfigModal } from '../../../components/ModalRight'
import { TaskForm } from '../../../components/TaskForm';
import { NormalTask } from '../../../services/Calendar/calendarTypes'
import { TaskBox } from './TaskBox';

export interface DayTasksProps {
    setModalConfig: ((data: ConfigModal) => void),
    tasks: Array<NormalTask>,
    tasksLate?: Array<NormalTask>,
    dayProps: any
    onConcluded?: (() => void)
    onChange?: (() => void)
    onRemove?: (() => void)
    onRemoveAll?: (() => void)
    onMeetingDayChange?: (() => void)
}

export const DayTasks: React.FC<DayTasksProps> = (props) => 
{
    const {
        setModalConfig,
        tasks,
        // dayProps,
        // onRemoveAll,
        onChange,
        // onConcluded,
        // onMeetingDayChange,
        // onRemove
     } = props;

    const TaskItem = (props: {
        item: NormalTask
    }) => {
        return <TaskBox 
            haveTags={props.item.tags.length ? true : false}
            haveTime 
            haveDateFinish 
            taskProps={props.item} 
            onClick={() => {
                setModalConfig({
                    show: true,
                    title: <h2 className="title-modal-form">{props.item.label}</h2>,
                    content: <TaskForm 
                        type="UPDATE"
                        id={props.item.id} 
                        onChange={() => {
                            if(onChange)
                            {
                                onChange()
                            }
                        }} 
                    />,
                    onClose: () => setModalConfig({show: false})
                })
            }} 
        />
    }

    return (
        <div>
            <div className="body-content" style={{marginTop: '20px'}}>
                <div className="title">
                    <h2>Tarefas de hoje</h2>
                </div>
                <h3 className="title-divider">Prioridades</h3>
                {
                    tasks ? tasks.filter(e => e.type === "3").map((item, i) => <TaskItem item={item} key={`${i}`} />) : null
                }
                <h3 className="title-divider">Tarefas</h3>
                {
                    tasks ? tasks.filter(e => e.type === "1" || e.type === "2").map((item, i) => <TaskItem item={item} key={`${i}`} /> ) : null
                }
            </div>
            {
                props.tasksLate && props.tasksLate.length > 0 ? 
                (
                    <>
                        <div className="body-content">
                            <div className="title">
                                <h2>Tarefas atrasadas</h2>
                            </div>
                            {
                                props.tasksLate ? (
                                    <>
                                        {
                                            props.tasksLate.map((item, i) => <TaskItem item={item} key={`${i}`} />)
                                        }
                                    </>
                                ) : null
                            }
                        </div>
                    </>
                ) : null
            }
            
        </div>
    )
}
