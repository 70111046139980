import { AxiosResponse } from "axios";
import DefaultErrorResponse from "../../functions/DefaultErrorResponse";
import Request from "../../functions/Request";

const route = '/client/finance/initial-value'

export interface InitialValueFormProps {
    value: string
}

export interface EntryProps {
    value?: string
    company_id?: string
    id?: string
}

class InitialValue 
{
    async update(
        data: InitialValueFormProps,
        onSuccess: ((success: AxiosResponse) => void),
    )
    {
        return await Request.post(`${route}`, data).then(onSuccess).catch(DefaultErrorResponse);
    }

    async get(
        onSuccess: ((success: EntryProps) => void),
    )
    {
        return await Request.get(`${route}`).then(e => onSuccess(e.data.response)).catch(DefaultErrorResponse);
    }
}

export const InitialValueAPI = new InitialValue()