import React, { useEffect, useRef, useState } from 'react'
import { Link, useHistory, useParams } from 'react-router-dom'
import { ButtonBackHistory } from '../../../components/ButtonBackHistory'
import { Content } from '../../../components/Content'
import { ConfigModal, Modal } from '../../../components/Modal'
import { Navbar } from '../../../components/Navbar'
import { IndicatorsAPI } from '../../../services/Resultado/Indicators/Indicators'

export interface DashboardProps {}
export interface DashboardRouteProps {
    department_id: string
}

export const Dashboard: React.FC<DashboardProps> = (props) => 
{
    const history = useHistory()
    // const [list, setList] = useState<Array<DashboardItem>>([])
    const { department_id } = useParams<DashboardRouteProps>()
    const loading = useRef(true)

    const [modalConfig] = useState<ConfigModal>({
        show: false
    })
    
    useEffect(() => {
        // IndicatorsAPI.mountDashboard(department_id, setList)
        IndicatorsAPI.departemntDetails(department_id, data => console.log(data))

        loading.current = false
    }, [department_id])

    return (
        <>
            <Modal {...modalConfig} />
            <Navbar/>
            <Content loading={loading.current} title={
                <div className="title-button">
                    <div className="title">
                        <ButtonBackHistory onClick={() => {
                            history.push('/indicadores')
                        }} />
                        <h1 className="app-content-h1-destaque">Painel de indicadores</h1>
                    </div>
                    <div className="options">
                        <Link to={`/indicadores/easy-dashboard/${department_id}`}>Configuração rápida</Link>
                    </div>
                </div>
            }>
                <div className="content-box">
                    <div className="dashboard">
                        <div className="dashboard-header">
                            <div>Indicador</div>
                            <div>Etiquetas</div>
                            <div>Janeiro</div>
                            <div>Fevereiro</div>
                            <div>Março</div>
                            <div>Abril</div>
                            <div>Maio</div>
                            <div>Junho</div>
                            <div>Julho</div>
                            <div>Agosto</div>
                            <div>Setembro</div>
                            <div>Outubro</div>
                            <div>Novembro</div>
                            <div>Dezembro</div>
                            <div>Total</div>
                            <div>Média</div>
                        </div>
                        <div className="dashboard-content">
                            {
                                // list.map((item,i) => {
                                //     // return <DashboardIndicatorItem last_year={item.last_year} now_year={item.now_year} title={item.title} />
                                // })
                            }
                        </div>
                    </div>
                    
                </div>
            </Content> 
        </>
    )
}